import {
  Button,
  Modal,
  SlideOver,
  SlideOverOnCloseProps,
  Card,
  AddSmallIcon,
  PencilIcon,
  LoadingIndicator,
} from '@client/shared/toolkit';
import {
  ExternalApiReadModel,
  ApiAccessReadModel,
  useApiPostActiveApiAccessMutation,
  useApiPostTestApiAccessMutation,
  useApiDatevTenantTokenRevokeMutation,
  useApiUpdateApiAccessMutation,
  useApiCreateDatevCacheMutation,
} from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { AddAccessItemModal } from './AddAccessItemModal';
import { safeMutation } from '@client/shared/utilities';
import classNames from 'classnames';
import toast from 'react-hot-toast';
import { DatevWizard, DocuwareWizard, YardiClientSlideOver } from '.';
import { useNavigate } from 'react-router-dom';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { DocuwareClientSlideOver } from './Docuware/DocuwareClientSlideOver';
import { updateDatevAuthCookie } from '@client/project/store';
import { API_MAPPING } from '@client/project/shared';
import { useUi } from '@client/shared/store';

interface ApiSlideOverProps extends SlideOverOnCloseProps {
  api: ExternalApiReadModel | null;
}

export const ApiSlideOver = ({ api, onClose }: ApiSlideOverProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ui = useUi();
  const subdomain = ui.appUser.tenant?.subdomain;

  const [isAddAccessItemModalOpen, setIsAddAccessItemModalOpen] = useState(false);
  const [selectedApiAcessItem, setSelectedApiAcessItem] = useState<ApiAccessReadModel | undefined>(undefined);
  const [postActiveAccessItem, { isLoading: isActivating }] = useApiPostActiveApiAccessMutation();
  const [postTestApiAccess, { isLoading: isTesting }] = useApiPostTestApiAccessMutation();
  const [postRevokeDatevTenantToken, { isLoading: isRevoking }] = useApiDatevTenantTokenRevokeMutation();
  const [updateAccessItem, { isLoading: isUpdating }] = useApiUpdateApiAccessMutation();
  const [postCreateDatevCache, { isLoading: isCreatingDatevCache }] = useApiCreateDatevCacheMutation();

  const isDatev = api?.api?.apiType === API_MAPPING.DATEV;
  const isDocuWare = api?.api?.apiType === API_MAPPING.DOCUWARE;
  const isYardi = api?.api?.apiType === API_MAPPING.YARDI;
  const [isDatevWizardOpen, setIsDatevWizardOpen] = useState(false);
  const [isDocuwareWizardOpen, setIsDocuwareWizardOpen] = useState(false);
  const [isDocuwareClientSlideOverOpen, setIsDocuwareClientSlideOverOpen] = useState(false);
  const [isYardiSlideOverOpen, setIsYardiSlideOverOpen] = useState(false);

  const editAcessItem = (apiAccessItem: ApiAccessReadModel) => {
    setSelectedApiAcessItem(apiAccessItem);
    if (isDatev) {
      navigate(ROUTES_CONFIG.SETTINGS_CONNECT_DATEV_EDIT.name.replace(':id', apiAccessItem.id));
    } else if (isDocuWare) {
      setIsDocuwareClientSlideOverOpen(true);
    } else if (isYardi) {
      setIsYardiSlideOverOpen(true);
    } else {
      setIsAddAccessItemModalOpen(true);
    }
  };

  const activateAcessItem = async (apiAccessItem: ApiAccessReadModel) => {
    try {
      await safeMutation(
        postActiveAccessItem,
        {
          apiAccessId: apiAccessItem.id ?? '',
        },
        isActivating,
      );
    } catch (e) {
      console.log(e);
    }
  };

  const deactivateAcessItem = async (apiAccessItem: ApiAccessReadModel) => {
    try {
      await safeMutation(
        updateAccessItem,
        {
          apiAccessId: apiAccessItem.id ?? '',
          body: {
            isActive: false,
          },
        },
        isActivating,
      );
    } catch (e) {
      console.log(e);
    }
  }

  const testAccessItem = async (apiAccessItem: ApiAccessReadModel) => {
    try {
      await safeMutation(
        postTestApiAccess,
        {
          accessId: apiAccessItem.id,
        },
        isTesting,
      );
      toast.success(t('app.ConnectTestSuccess'));
    } catch (e) {
      console.log(e);
    }
  };

  const revokeDatevTenantToken = async (apiAccessItem: ApiAccessReadModel) => {
    try {
      await safeMutation(
        postRevokeDatevTenantToken,
        {
          datevApiAccessId: apiAccessItem.id,
        },
        isRevoking,
      );
      toast.success(t('app.ConnectRevokeTokenSuccess'));
    } catch (e) {
      console.log(e);
    }
  };

  const openModal = async () => {
    switch (api?.api?.apiType) {
      case API_MAPPING.DATEV: {
        try {
          const response = await safeMutation(
            postCreateDatevCache,
            {
              body: {
                datevTenantSubdomain: subdomain ?? '',
                datevTenantAuthData: undefined,
                datevClientId: undefined,
                datevClientAuthData: undefined,
                datevUpdateData: undefined,
                datevWizardType: 'Add',
                datevApiAccessId: undefined,
              },
            },
            isCreatingDatevCache,
          );
          if (response?.key) {
            updateDatevAuthCookie(response?.key, subdomain);
            setIsDatevWizardOpen(true);
          }
        } catch (e) {
          console.log(e);
        }
        break;
      }
      case API_MAPPING.DOCUWARE:
        setIsDocuwareWizardOpen(true);
        break;
      default:
        setIsAddAccessItemModalOpen(true);
        break;
    }
  };

  return (
    <>
      {(isActivating || isTesting || isRevoking || isUpdating) && <LoadingIndicator text={t('common.loading')} mode="overlay" />}
      <SlideOver.Header title={`${isDatev ? t('api.datev') : api?.api.name} API`} backgroundClassName="bg-sky-900" onClose={onClose} />
      <SlideOver.Content className="p-8 overflow-y-scroll">
        <div className="mb-5 text-3xl font-bold text-slate-500 w-full text-center"></div>

        {api?.accessItems && api?.accessItems.length > 0 ? (
          <>
            {api?.accessItems
              .slice()
              .sort((a, b) => (b.isActive ? 1 : -1))
              .map((item, i) => (
                <div key={i}>
                  <Card className={classNames('relative my-2')}>
                    <div className="pr-2">
                    {item.isActive && (
                      <div className="right-5 absolute">
                        <PencilIcon
                          className="hover:opacity-70 cursor-pointer w-5"
                          onClick={() => editAcessItem(item)}
                        />
                      </div>
                    )}
                      <div className="pr-4 break-words">
                        {item.isActive && (
                          <div className="mb-2 font-bold text-center text-green-500">{t('app.ConnectActive')}</div>
                        )}
                        {isYardi && !item.isActive && (
                          <div className="mb-2 font-bold text-center text-red-500">{t('app.ConnectInactive')}</div>
                        )}
                        {api?.api?.needsApiUrl && (
                          <div className="flex">
                            <div className="w-1/3 font-bold">{t('app.ConnectApiUrl')}:</div>
                            <div className="w-2/3 pl-1">{item.apiUrl}</div>
                          </div>
                        )}
                        {api?.api?.needsApiKey && (
                          <div className="flex">
                            <div className="w-1/3 font-bold">{t('app.ConnectApiKey')}:</div>
                            <div className="w-2/3 pl-1">{item.apiKey}</div>
                          </div>
                        )}
                        {api?.api?.needsUserName && (
                          <div className="flex">
                            <div className="w-1/3 font-bold">{t('app.ConnectUserName')}:</div>
                            <div className="w-2/3 pl-1">{item.userName}</div>
                          </div>
                        )}
                        {api?.api?.needsCustomConfig && (
                          <div className="flex">
                            <div className="w-1/3 font-bold">{t('app.ConnectCustomConfig')}:</div>
                            <div className="w-2/3 pl-1">{item.customConfig}</div>
                          </div>
                        )}
                      </div>
                      {!item.isActive && (
                        <div className="w-full flex justify-center mt-4">
                          <Button variant="primary" onClick={() => activateAcessItem(item)} className="mx-2">
                            {t('app.ConnectActivateItem')}
                          </Button>
                        </div>
                      )}
                      {isYardi && item.isActive && (
                        <div className="w-full flex justify-center mt-4">
                          <Button variant="primary" onClick={() => deactivateAcessItem(item)} className="mx-2">
                            {t('app.ConnectDeactivateItem')}
                          </Button>
                        </div>
                      )}
                      {/* TODO do we need this?? TBD */}
                      {item.isActive && isDatev && false && (
                        <div className="w-full flex justify-center mt-4">
                          <Button variant="primary" onClick={() => revokeDatevTenantToken(item)} className="mx-2">
                            {t('app.ConnectRevokeToken')}
                          </Button>
                        </div>
                      )}
                      <div>
                        {api.api.apiIsTestable && (
                          <Button variant="secondary" onClick={() => testAccessItem(item)} className="mt-2">
                            {t('app.ConnectTest')}
                          </Button>
                        )}
                      </div>
                    </div>
                  </Card>
                </div>
              ))}
            {!isYardi && (
              <div className="flex justify-end h-32 -mt-6 pr-2 relative z-50" onClick={() => openModal()}>
                <AddSmallIcon className="bg-gray-700 text-white rounded-full w-10 h-10 p-3 hover:scale-110 transition-transform duration-200 cursor-pointer" />
              </div>
            )}
          </>
        ) : (
          (
            <div className="mb-10 w-full flex justify-center">
              <Button variant="primary" onClick={() => openModal()}>
                {t('app.ConnectAddAccessItem')}
              </Button>
            </div>
          )
        )}
      </SlideOver.Content>
      <SlideOver.Controls>
        <Button variant="secondary" onClick={() => onClose(false)}>
          {t('common.close')}
        </Button>
      </SlideOver.Controls>
      <Modal
        isOpen={isAddAccessItemModalOpen}
        onClose={() => {
          setSelectedApiAcessItem(undefined);
          setIsAddAccessItemModalOpen(false);
        }}
      >
        <AddAccessItemModal
          api={api?.api}
          apiAccessItem={selectedApiAcessItem}
          onClose={() => {
            setSelectedApiAcessItem(undefined);
            setIsAddAccessItemModalOpen(false);
          }}
        />
      </Modal>
      {isDatevWizardOpen && (
        <DatevWizard
          isOpen={isDatevWizardOpen}
          onClose={() => setIsDatevWizardOpen(false)}
          apiId={api?.api.id ?? ''}
          isReset={false}
        />
      )}
      {isDocuwareWizardOpen && (
        <DocuwareWizard
          isOpen={isDocuwareWizardOpen}
          onClose={() => setIsDocuwareWizardOpen(false)}
          apiId={api?.api.id ?? ''}
        />
      )}
      {isDocuwareClientSlideOverOpen && (
        <DocuwareClientSlideOver
          apiId={api?.api.id ?? ''}
          apiAccessId={selectedApiAcessItem?.id ?? ''}
          isOpen={isDocuwareClientSlideOverOpen}
          onClose={() => setIsDocuwareClientSlideOverOpen(false)}
        />
      )}
      {isYardiSlideOverOpen && (
        <YardiClientSlideOver
          apiId={api?.api.id ?? ''}
          apiAccessId={selectedApiAcessItem?.id ?? ''}
          isOpen={isYardiSlideOverOpen}
          onClose={() => setIsYardiSlideOverOpen(false)}
        />
      )}
    </>
  );
};
