import {
  Collapsible,
  DecoratedCard,
  LevelToggle,
  LoadingIndicator,
  ToggleButton,
  useComponentDimensions,
} from '@client/shared/toolkit';
import React, { PropsWithChildren, useContext, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  BudgetReportCostRowReadModel,
  BudgetReportEarningsRowReadModel,
  BudgetReportFinancingRowReadModel,
  BudgetReportRiskRowReadModel,
  useApiGetBugetReportQuery,
} from '@client/shared/api';
import { setExpandedBudgetReportIds, useExpandedReportingIds, useLoadedProjectId } from '@client/project/store';
import { formatNumber, formatUnit } from '@client/shared/utilities';
import { CalculationModelSelect } from './CalculationModelSelect';
import { ReportCollapsingRow } from './ReportCollapsingRow';
import { FormattedCurrency } from '@client/project/shared';
import { ReportingContext } from './ReportingContextProvider';
import { useDispatch } from 'react-redux';
import { getLevelledElements } from '../utils';

export const ReportBudget = () => {
  const { t } = useTranslation();

  const projectId = useLoadedProjectId();
  const { sourceCalculationModelId, setSourceCalculationModelId } = useContext(ReportingContext);

  const { data, isFetching } = useApiGetBugetReportQuery(
    {
      projectId: projectId ?? '',
      calculationModelId: sourceCalculationModelId ?? '',
    },
    { skip: sourceCalculationModelId === undefined || !projectId },
  );

  const levelledElements = useMemo(() => {
    const allElements = [
      ...(data?.report?.costs || []),
      ...(data?.report?.risks || []),
      ...(data?.report?.earnings || []),
      ...(data?.report?.financing || []),
    ];

    return getLevelledElements(allElements, ['children'], 'rowIdentifier');
  }, [data]);

  const dispatch = useDispatch();

  const { Budget: expandedElements } = useExpandedReportingIds();

  const handleOnCollapse = (level: number) => {
    const elements = levelledElements.filter((x) => x.level <= level - 2);
    const expanded = elements.map((x) => x.elementId);
    dispatch(setExpandedBudgetReportIds(expanded));
  };

  const maxLevel = useMemo(() => {
    return (
      levelledElements.reduce((value, element) => {
        return Math.max(value, element.level);
      }, 3) + 1
    );
  }, [levelledElements]);

  return (
    <DecoratedCard>
      <DecoratedCard.Header showActionButton={false}>
        <div className="flex flex-row justify-between items-center w-full">
          <div className="flex">
            <div className="truncate">{t('reporting.reportBudget.title')}</div>
            <div className="pdf-export-hidden">
              <LevelToggle
                handleOnCollapse={handleOnCollapse}
                showLevels={Array.from({ length: maxLevel }, (_, i) => i + 1)}
              />
            </div>
          </div>
          <div className="flex items-center">
            <div className="font-bold text-[15px] pr-6">{t('reporting.dataSource')}</div>
            <div className="flex space-x-0.5 items-center">
              <CalculationModelSelect
                selectedCalculationModelId={sourceCalculationModelId}
                className="w-52"
                onChange={(id) => setSourceCalculationModelId(id)}
              />
            </div>
          </div>
          {/*<div>
            <Button
              variant="secondary"
              className="inline-flex items-center cursor-pointer font-normal text-base"
              onClick={onDownload}
            >
              <DownloadingUpdatesIcon className="h-5 w-5 mr-2" />
              {t('reporting.actionExportToExcel')}
            </Button>
          </div>*/}
        </div>
      </DecoratedCard.Header>
      <DecoratedCard.Content className="w-full h-full flex relative">
        {isFetching && <LoadingIndicator mode="overlay" />}
        <BudgetGroup
          title={t('reporting.reportBudget.groupCosts')}
          accentColor="bg-red-700"
          titleColor="text-red-700"
          total={data?.report.totalCosts}
          columns={[t('reporting.tableUnitPrice'), t('reporting.tableAmount'), t('reporting.tableSum')]}
        >
          {data?.report.costs?.map((cost, i) => (
            <BudgetCostRow rowData={cost} key={`report-cost-${cost.code}-${i}`} expandedElements={expandedElements} />
          ))}
        </BudgetGroup>

        <BudgetGroup
          title={t('reporting.reportBudget.groupRisks')}
          accentColor="bg-sky-700"
          titleColor="text-sky-700"
          total={data?.report.totalRisks}
          columns={[t('reporting.tableRiskValue'), t('reporting.tableProbability'), t('reporting.tableSum')]}
        >
          {data?.report.risks?.map((risk, i) => (
            <BudgetRiskRow rowData={risk} key={`report-risk-${risk.code}-${i}`} expandedElements={expandedElements} />
          ))}
        </BudgetGroup>

        <BudgetGroup
          title={t('reporting.reportBudget.groupEarnings')}
          accentColor="bg-lime-600"
          titleColor="text-lime-600"
          total={data?.report.totalEarnings}
          columns={[t('reporting.tableUnitPrice'), t('reporting.tableAmount'), t('reporting.tableSum')]}
        >
          {data?.report.earnings?.map((earning, i) => (
            <BudgetEarningRow
              rowData={earning}
              key={`report-earning-${earning.code}-${i}`}
              expandedElements={expandedElements}
            />
          ))}
        </BudgetGroup>

        <BudgetGroup
          title={t('reporting.reportBudget.groupFinancing')}
          accentColor="bg-slate-600"
          titleColor="text-slate-600"
          total={data?.report.totalFinancing}
          columns={[t('reporting.tableTotalCost'), t('reporting.tableTotalValue')]}
        >
          {data?.report.financing?.map((financing, i) => (
            <BudgetFinancingRow
              rowData={financing}
              key={`report-financing-${financing.code}-${i}`}
              expandedElements={expandedElements}
            />
          ))}
        </BudgetGroup>
      </DecoratedCard.Content>
    </DecoratedCard>
  );
};

interface BudgetGroupProps extends PropsWithChildren {
  accentColor?: string;
  titleColor?: string;
  title?: string;
  total?: number;
  columns?: string[];
}

const BudgetGroup = ({ accentColor, children, title, titleColor, total, columns = [] }: BudgetGroupProps) => {
  const { t } = useTranslation();

  const wrapper = useRef<HTMLDivElement>(null);
  const dimensions = useComponentDimensions(wrapper);

  const [open, setOpen] = React.useState(true);

  return (
    <div className="relative w-full" ref={wrapper}>
      <div
        className={classNames('w-2 my-4 bg-cyan-700 rounded -ml-1 left-0 absolute', accentColor)}
        style={{ height: (dimensions.height ?? 15) - 32 }}
      />
      <div className="h-[70px] bg-white border-b border-slate-300">
        <div className="flex pl-4 pr-4 w-full h-full">
          <div className="flex-grow flex items-end">
            <div>
              <div className="text-[22px] font-bold flex truncate">
                {children && React.Children.count(children) > 0 ? (
                  <ToggleButton open={open} onClick={() => setOpen(!open)} />
                ) : (
                  <div className="w-8 flex-none">&nbsp;</div>
                )}
                <div className="w-10 flex-none">&nbsp;</div>
                <div className={titleColor}>{title}</div>
              </div>
              <div className="flex text-[11px] text-slate-500 gap-2">
                <div className="w-6">&nbsp;</div>
                <div className="flex w-[422px] gap-2">
                  <div className="flex-none truncate">{t('reporting.tableColumnId')}</div>
                  <div className="truncate">{t('reporting.tableColumnDescription')}</div>
                </div>
                <div className="truncate">{t('reporting.tableColumnDetails')}</div>
              </div>
            </div>
          </div>
          <div className="flex items-end">
            {columns?.map((column, index) => (
              <div
                className="w-48 text-right text-[11px] text-slate-500 px-4 truncate"
                key={`report-budget-column-${index}`}
              >
                {column}
              </div>
            ))}
          </div>
        </div>
      </div>
      <Collapsible open={open}>{children}</Collapsible>
      <div className="h-[70px] bg-white">
        <div className="flex pl-4 pr-4 w-full h-full items-center">
          <div className="flex-grow flex items-end">
            <div>
              <div className="text-[22px] font-bold flex truncate">
                <div className="h-6 w-8 flex-none" />
                <div className="w-10 flex-none">&nbsp;</div>
                <div className={classNames('text-[15px]', titleColor)}>{t('reporting.reportBudget.groupTotalSum')}</div>
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <div className="w-48 text-right font-bold text-[15px] px-4 border-b-4 border-double">
              <FormattedCurrency amount={total} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface BudgetCostRowProps {
  rowData?: BudgetReportCostRowReadModel;
  level?: number;
  columns?: React.ReactElement[];
  expandedElements: string[];
}

const BudgetCostRow = ({ columns, level = 0, rowData, expandedElements }: BudgetCostRowProps) => {
  if (rowData && !columns) {
    columns = [
      <>{rowData.code}</>,
      <>{rowData.description}</>,
      <>-</>,
      <FormattedCurrency amount={rowData.unitPrice} />,
      <>{formatUnit(rowData.amount, rowData.unit ?? '')}</>,
      <FormattedCurrency amount={rowData.total} />,
    ];
  }

  return (
    <ReportCollapsingRow<BudgetReportCostRowReadModel>
      rowData={rowData}
      columns={columns}
      level={level}
      RowComponent={BudgetCostRow}
      expandedElements={expandedElements}
      report="Budget"
    />
  );
};

interface BudgetRiskRowProps {
  rowData?: BudgetReportRiskRowReadModel;
  level?: number;
  columns?: React.ReactElement[];
  expandedElements: string[];
}

const BudgetRiskRow = ({ columns, level = 0, rowData, expandedElements }: BudgetRiskRowProps) => {
  if (rowData && !columns) {
    columns = [
      <>{rowData.code}</>,
      <>{rowData.description}</>,
      <>-</>,
      <FormattedCurrency amount={rowData.value} />,
      <>{formatUnit(rowData.probability, '%')}</>,
      <FormattedCurrency amount={rowData.total} />,
    ];
  }

  return (
    <ReportCollapsingRow<BudgetReportRiskRowReadModel>
      rowData={rowData}
      columns={columns}
      level={level}
      RowComponent={BudgetRiskRow}
      expandedElements={expandedElements}
      report="Budget"
    />
  );
};

interface BudgetEarningRowProps {
  rowData?: BudgetReportEarningsRowReadModel;
  level?: number;
  columns?: React.ReactElement[];
  expandedElements: string[];
}

const BudgetEarningRow = ({ columns, level = 0, rowData, expandedElements }: BudgetEarningRowProps) => {
  if (rowData && !columns) {
    columns = [
      <>{rowData.code}</>,
      <>{rowData.description}</>,
      <>-</>,
      <FormattedCurrency amount={rowData.unitPrice} />,
      <>{formatUnit(rowData.amount, rowData.unit ?? '')}</>,
      <FormattedCurrency amount={rowData.total} />,
    ];
  }

  return (
    <ReportCollapsingRow<BudgetReportEarningsRowReadModel>
      rowData={rowData}
      columns={columns}
      level={level}
      RowComponent={BudgetEarningRow}
      expandedElements={expandedElements}
      report="Budget"
    />
  );
};

interface BudgetFinancingRowProps {
  rowData?: BudgetReportFinancingRowReadModel;
  level?: number;
  columns?: React.ReactElement[];
  expandedElements: string[];
}

const BudgetFinancingRow = ({ columns, level = 0, rowData, expandedElements }: BudgetFinancingRowProps) => {
  if (rowData && !columns) {
    columns = [
      <>{rowData.code}</>,
      <>{rowData.description}</>,
      <>-</>,
      <>{formatNumber(rowData.totalCost)}</>,
      <FormattedCurrency amount={rowData.totalValue} />,
    ];
  }

  return (
    <ReportCollapsingRow<BudgetReportFinancingRowReadModel>
      rowData={rowData}
      columns={columns}
      level={level}
      RowComponent={BudgetFinancingRow}
      expandedElements={expandedElements}
      report="Budget"
    />
  );
};
