import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import {
  YardiInvoicePayload,
  ShortInvoiceReadModel,
  useApiGetProjectYardiConnectionQuery,
  useApiPostYardiInvoiceExportFileMutation,
} from '@client/shared/api';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { Button, CheckBox, LoadingIndicator, Modal, ModalOnCloseProps } from '@client/shared/toolkit';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate } from 'react-router-dom';

interface YardiExportModalProps extends ModalOnCloseProps {
  selectedInvoices: ShortInvoiceReadModel[];
  invoices: ShortInvoiceReadModel[];
  isOpen: boolean;
  type: 'yardiExport' | 'yardiLog';
}

export const YardiExportModal = ({ onClose, isOpen, selectedInvoices, invoices, type }: YardiExportModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const loadedProjectId = useLoadedProjectId();
  const loadedCalculationModelId = useLoadedVariantId();

  const { data: datevData, isFetching: isFetchingDatev } = useApiGetProjectYardiConnectionQuery({
    projectId: loadedProjectId ?? '',
  });

  const [invoicePayloads, setInvoicePayloads] = useState<
    {
      invoice: ShortInvoiceReadModel;
      documentIds: string[];
      documentType: string;
      documentNote: string;
      selected: boolean;
    }[]
  >([]);

  useEffect(() => {
    if (invoicePayloads.length === 0 && datevData) {
      setInvoicePayloads(
        selectedInvoices.map((invoice: ShortInvoiceReadModel) => ({
          invoice: invoice,
          documentIds:
            invoice.invoiceDocuments.find((doc) => doc.isInvoiceSource)?.id || invoice.invoiceDocuments[0]?.id
              ? [
                  invoice.invoiceDocuments.find((doc) => doc.isInvoiceSource)?.id ??
                    invoice.invoiceDocuments[0]?.id ??
                    '',
                ]
              : [],
          documentType: '', //datevData?.exportPreChecks?.documentTypes.includes('Rechnungseingang')
          documentNote: '',
          selected: true,
        })),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInvoices, datevData]);

  const [exportInvoices, { isLoading: isExporting }] = useApiPostYardiInvoiceExportFileMutation(); // useApiGetYardiExportInvoiceEndpointMutation();

  const handleSubmit = async () => {
    try {
      await exportInvoices({
        projectId: loadedProjectId ?? '',
        calculationModelId: loadedCalculationModelId ?? '',
        body: {
          invoices: invoicePayloads
            .filter((invoicePayload) => invoicePayload.selected)
            .map(
              (invoicePayload) =>
                ({
                  invoiceId: invoicePayload.invoice.id,
                  documentIds: invoicePayload.documentIds ? invoicePayload.documentIds : [],
                  documentType: undefined, //!invoicePayload.documentType ? undefined : invoicePayload.documentType,
                  note:
                    !invoicePayload.documentIds.length || invoicePayload.documentNote === ''
                      ? undefined
                      : invoicePayload.documentNote,
                }) as YardiInvoicePayload,
            ),
        },
      });
    } catch (e) {
      console.log('Error downloading file:', e);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} variant="max">
      <Modal.Header
        title={type === 'yardiLog' ? t('projectControl.yardiLogTitle') : t('projectControl.yardiExportTitle')}
        description={
          type === 'yardiLog'
            ? t('projectControl.yardiExportLogDescription')
            : t('projectControl.yardiExportDescription')
        }
      />
      <Modal.Content className="w-full flex justify-start items-center">
        {(isExporting || isFetchingDatev) && (
          <LoadingIndicator
            text={t(isFetchingDatev ? 'common.loading' : 'projectControl.datevExporting')}
            mode="overlay"
          />
        )}
        {invoices
          .filter((invoice) => selectedInvoices.find((selectedInvoice) => selectedInvoice.id === invoice.id))
          .some((invoice) => invoice.datevStatus?.status === 'InProcess') && (
          <div className="pb-2 gap-2 flex flex-col">
            <LoadingIndicator />
            <span>{t('projectControl.datevExportInProcess')}</span>
          </div>
        )}
        {type !== 'yardiLog' ? (
          <>
            {isFetchingDatev ? null : !datevData?.mappings ? (
              <div className="h-full flex flex-col justify-center items-center">
                <div className="p-2 mb-2 text-center">{t('projectControl.yardiExportNoClientConfigured')}</div>
                <Button
                  variant="primary"
                  onClick={() =>
                    navigate({
                      pathname: ROUTES_CONFIG.PROJECT_DASHBOARD_SETTINGS.path.replace(':id', loadedProjectId ?? ''),
                      search: createSearchParams({ tab: 'connections' }).toString(),
                    })
                  }
                >
                  {t('app.projectSettings')}
                </Button>
              </div>
            ) : (
              <div className="w-full">
                <div className="flex flex-col items-center">
                  <div className={classNames('pt-5 pb-1 font-bold text-xl text-center', 'pb-5')}>
                    {t('projectControl.datevExportSelectedInvoices')}
                  </div>
                  <div className="w-full flex items-center justify-between pb-2 font-bold">
                    <span className="w-2/12 pl-2">{t('projectControl.invoice')}</span>
                    <span className="w-1/12">{t('common.selected')}</span>
                  </div>
                  <div className="w-full flex flex-col items-center h-80 overflow-auto bg-white divide-y">
                    {invoicePayloads.map((invoicePayload) => (
                      <div
                        key={invoicePayload.invoice.id}
                        className={classNames('w-full flex items-center justify-between', {
                          'divide-x': true,
                        })}
                      >
                        <span className="w-2/12 pl-3 font-bold py-2">{invoicePayload.invoice.code}</span>
                        <span className="w-1/12 h-full flex justify-center items-center">
                          <CheckBox
                            className="cursor-pointer"
                            checked={invoicePayload.selected}
                            onChange={() => {
                              setInvoicePayloads(
                                invoicePayloads.map((payload) =>
                                  payload.invoice.id === invoicePayload.invoice.id
                                    ? { ...payload, selected: invoicePayload.selected ? false : true }
                                    : payload,
                                ),
                              );
                            }}
                          />
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <span></span>
        )}
      </Modal.Content>
      <Modal.Controls className="bg-white">
        <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
          {t('common.cancel')}
        </Button>
        {type === 'yardiExport' && (
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={isExporting || !invoicePayloads.filter((invoicePayload) => invoicePayload.selected).length}
          >
            {t('projectControl.datevExportSend')}
          </Button>
        )}
      </Modal.Controls>
    </Modal>
  );
};

