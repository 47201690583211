import { PlotTable, BuildingsTable, PlotElementNewModal, TaxonomyPlotView, TaxonomyView } from '../components';
import { useLoadedVariant, useLoadedVariantId, useLoadedProjectId, useLoadedProject } from '@client/project/store';
import {
  useDocumentTitle,
  FloatingActionButton,
  ContextMenuItem,
  Modal,
  TitleBar, MapMarkerDottedIcon, LoadingIndicator
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import React, { useMemo, useState } from 'react';
import { UserDefinedFieldsSearch, VariantDropDown } from '@client/project/shared';
import { ProtectedRoute, ROUTES_CONFIG, useValidateProjectPermission } from '@client/shared/permissions';
import { Navigate, Route, Routes } from 'react-router-dom';

export const TaxonomyRoute = () => {
  const { t } = useTranslation();
  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const { data: loadedProject } = useLoadedProject();
  
  const loadedVariant = useLoadedVariant();
  const isReadOnly = loadedVariant.currentData?.calculationModel.modelMetadata.readOnly;

  const canWrite = useValidateProjectPermission(['PLOT_AND_TAXONOMY_WRITE'], loadedProject?.project.payload.id ?? '') && !isReadOnly;
  const canDelete = useValidateProjectPermission(['PLOT_AND_TAXONOMY_DELETE'], loadedProject?.project.payload.id ?? '') && !isReadOnly;

  
  // loadedVariant.currentData?.calculationModel.modelMetadata.readOnly
  // const isReadOnly = !(
  //   (loadedVariant.currentData?.calculationModel.modelMetadata.type === 'Version' ||
  //   loadedVariant.currentData?.calculationModel.modelMetadata.type === 'Variant') &&
  //   canWrite
  // );

  useDocumentTitle({ title: t('project.menuTaxonomy') });

  const [isPlotElementNewModalOpen, setIsPlotElementNewModalOpen] = useState(false);

  const [searchValue, setSearchValue] = useState('')
  const [searchResults, setSearchResults] = useState<string[]>([])
  const [openDashboard, setOpenDashboard] = useState('');
  const [isLoadingBuildings, setIsLoadingBuildings] = useState(false);
  const [isLoadingPlots, setIsLoadingPlots] = useState(false);
  const isLoading = useMemo(() => {
    return isLoadingBuildings || isLoadingPlots;
  }, [isLoadingBuildings, isLoadingPlots]);

  const contextItems: ContextMenuItem[] = [
    {
      label: t('projectTaxonomy.newPlot'),
      subtitle: t('projectTaxonomy.plotNewMenuDescription'),
      icon: <MapMarkerDottedIcon />,
      isDisabled: isReadOnly,
      onClick: () => setIsPlotElementNewModalOpen(true),
    },
  ];

  const handleClosePlotElementNewModal = () => {
    setIsPlotElementNewModalOpen(false);
  };

  return (
    <>
      {isLoading && <LoadingIndicator text={isLoadingBuildings ? t('projectTaxonomy.loadBuilding') : t('projectTaxonomy.loadingPlots')}  mode="overlay-window" />}
      <TitleBar
        title={loadedProject?.project?.payload?.name ?? t('projectTaxonomy.routeTitle')}
        subTitle={t('projectTaxonomy.routeSubTitle')}
      >
        <div className="flex justify-between items-center w-full h-10 gap-2 xl:gap-5">
          <div className="flex gap-2 xl:gap-5 items-center h-full">
            <UserDefinedFieldsSearch
              searchValue={searchValue}
              handleSearchValueUpdate={setSearchValue}
              updateSearchResults={setSearchResults}
              udfElementTypes={['Taxonomy']}
              className="h-10"
              filterStore="Taxonomy"
            />
          </div>
          <VariantDropDown />
        </div>
      </TitleBar>
      <div className="flex-grow flex flex-col overflow-hidden">
        <div className="p-8 flex flex-col flex-grow overflow-y-auto">
          <BuildingsTable
            loadedVariantId={loadedVariantId}
            isReadOnly={!canWrite}
            canDelete={canDelete}
            searchValue={searchValue}
            searchResults={searchResults}
            openDashboard={openDashboard}
            setIsLoading={setIsLoadingBuildings}
          />
          <div className="mt-12" />
          <PlotTable
            loadedVariantId={loadedVariantId}
            isReadOnly={!canWrite}
            canDelete={canDelete}
            openDashboard={openDashboard}
            setIsLoading={setIsLoadingPlots}
          />
          <div className="mt-12" />
          <FloatingActionButton menuItems={contextItems} />
        </div>
      </div>
      <Modal isOpen={isPlotElementNewModalOpen} onClose={handleClosePlotElementNewModal}>
        <PlotElementNewModal onClose={handleClosePlotElementNewModal} />
      </Modal>

      <Routes>
        <Route
          path={ROUTES_CONFIG.PROJECT_TAXONOMY_VIEW.name}
          element={
            <ProtectedRoute
              routeConfig={ROUTES_CONFIG.PROJECT_TAXONOMY_VIEW}
              projectId={loadedProjectId}
            >
              <TaxonomyView
                isReadOnly={!canWrite}
                setOpenDashboard={setOpenDashboard}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES_CONFIG.PROJECT_TAXONOMY_PLOT_VIEW.name}
          element={
            <ProtectedRoute
              routeConfig={ROUTES_CONFIG.PROJECT_TAXONOMY_PLOT_VIEW}
              projectId={loadedProjectId}
            >
              <TaxonomyPlotView
                isReadOnly={!canWrite}
                canDelete={canDelete}
                setOpenDashboard={setOpenDashboard}
              />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to="" />} />
      </Routes>
    </>
  );
};
