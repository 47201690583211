import { useApiGetProjectPermissionGroupsQuery } from '@client/shared/api';

export const useLoadedProjectPermissionGroups = (skip = false, projectId: string | undefined) => {
  return useApiGetProjectPermissionGroupsQuery(
    {
      projectId: projectId ?? '',
    },
    {
      skip: !projectId || skip,
    },
  );
};
