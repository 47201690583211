import {
  CalculationCostElement,
  CostCatalogElementDto
} from '@client/shared/api';
import { useMemo } from 'react';
import { ElementMultiSelect, GenericElementMultiSelectProps, MultiSelectGroup } from '.';

interface CostElementMultiSelectProps extends GenericElementMultiSelectProps {
  costData: CostCatalogElementDto[];
}

export const CostElementMultiSelect = ({
  costData,
  selectedElements,
  updateSelectedElements,
  onClose,
  showControls
}: CostElementMultiSelectProps) => {
  // map the data to MultiSelectGroup[]
  const mappedElements = useMemo(() => {
    const mapElementToMultiSelectGroup = (
      elements: CalculationCostElement[],
      ids: string[],
    ): {
      elements: MultiSelectGroup[];
      ids: string[];
    } => {
      const mapped = elements.map((item) => {
        ids.push(item.costElement.elementId);
        return {
          id: item.costElement.elementId,
          elementId: item.costElement.elementId, // for udf search
          code: item.costElement.code,
          description: item.costElement.description,
          vat: item.costElement.vat,
          hasFormula: item.costElement.hasFormula,
          children: [],
          elements: [],
        } as MultiSelectGroup;
      });
      return {
        elements: mapped,
        ids: ids,
      };
    };

    const mapToMultiSelectGroup = (
      elements: CostCatalogElementDto[],
      ids: string[],
    ): {
      elements: MultiSelectGroup[];
      ids: string[];
    } => {
      const mapped = elements
        .filter((item) => item.id !== '00000000-0000-0000-0000-000000000000')
        .map((item) => {
          ids.push(item.id);

          const onlyCostElements = item.costElements.filter((item) => item.costElement);
          const mappedCostElements = onlyCostElements.length
            ? mapElementToMultiSelectGroup(onlyCostElements, ids)
            : undefined;

          if (mappedCostElements?.ids) {
            ids = mappedCostElements.ids;
          }

          return {
            id: item.id,
            elementId: item.costElementId ?? item.id, // for udf search
            code: item.code,
            description: item.description,
            vat: item.vat,
            children: item.children?.length ? mapToMultiSelectGroup(item.children, ids).elements : [],
            elements: mappedCostElements?.elements ?? [],
          } as MultiSelectGroup;
        });
      return {
        elements: mapped,
        ids: ids,
      };
    };
    return mapToMultiSelectGroup(costData, []);
  }, [costData]);

  return (
    <ElementMultiSelect
      elements={mappedElements.elements}
      elementIds={mappedElements.ids}
      selected={selectedElements}
      type="Costs"
      onClose={onClose}
      updateSelectedElements={updateSelectedElements}
      showControls={showControls}
      showVat
      showFx
    />
  );
};
