import { CSSProperties } from 'react';
import { PropsWithChildren } from 'react';
import cn from 'classnames';

interface ReportCashflowCellProps extends PropsWithChildren {
  header?: boolean;
  footer?: boolean;
  active?: boolean;
  styles?: CSSProperties;
  className?: string;
}

export const ReportCashflowCell = (props: ReportCashflowCellProps) => {
  const { header = false, footer = false, children, active = true, styles, className } = props;
  return (
    <div
      className={cn(
        'text-right text-[13px] relative',
        {
          'font-bold text-[15px] [&:not(:last-child)]:border-r border-slate-300': header,
          'text-[14px] font-semibold border-b border-slate-300': !header && !footer,
          'text-[14px] font-semibold pr-2': footer,
          'text-slate-300': !active,
        },
        className,
      )}
      style={styles}
    >
      {children}
    </div>
  );
};
