import { ProjectTemplate, ProjectTemplateRoutes, TemplateNavItem } from '@client/templates/project';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import {
  CalculateMenuIcon,
  DashboardMenuIcon,
  ManageMenuIcon,
  ProjectMenuIcon,
  ReportingMenuIcon,
} from '@client/shared/toolkit';
import React, { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Portal, Transition, TransitionChild } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';

export const SettingsProjectTemplate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(true);

  const backUrl = `${ROUTES_CONFIG.SETTINGS_TEMPLATES.path}?tab=project`;

  const navItems: TemplateNavItem[] = useMemo(() => {
    return [
      {
        label: t('project.menuDashboard'),
        icon: <DashboardMenuIcon />,
        path: '',
        disabled: true,
      },
      {
        label: t('project.menuProject'),
        icon: <ProjectMenuIcon />,
        path: '',
        disabled: true,
      },
      {
        label: t('project.menuCalculate'),
        icon: <CalculateMenuIcon />,
        path: ROUTES_CONFIG.SETTINGS_TEMPLATES_PROJECT_CALCULATE.name,
        disabled: false,
      },
      {
        label: t('project.menuReporting'),
        icon: <ReportingMenuIcon />,
        path: ROUTES_CONFIG.SETTINGS_TEMPLATES_PROJECT_REPORTS.name,
        disabled: false,
      },
      {
        label: t('project.menuManage'),
        icon: <ManageMenuIcon />,
        path: '',
        disabled: true,
      },
    ];
  }, [t]);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Portal>
      <Transition show={isOpen} as={Fragment} unmount={true} appear={true}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => {
            navigate(backUrl);
          }}
        >
          <div className="fixed w-screen h-screen top-0 left-0 z-30 bg-white">
            <ProjectTemplate
              navItems={navItems}
              backUrl={backUrl}
              handleClose={handleClose}
            >
              <ProjectTemplateRoutes />
            </ProjectTemplate>
          </div>
        </TransitionChild>
      </Transition>
    </Portal>
  );
};
