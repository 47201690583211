import { CalculationModelEarningsGroupElement } from '@client/shared/api';
import { isChildEarningElement } from '.';
import { findEarningsElement } from '@client/project/shared';

export const getFilteredEarningsTotal = (
  flattenedEarningsElements: CalculationModelEarningsGroupElement[],
  currentSearchResult: string[] | null,
): number => {
  if (!currentSearchResult) return 0;
    const filteredEarningElements = currentSearchResult?.map((id) =>
      findEarningsElement(id, flattenedEarningsElements),
    ).filter((element) => element);

    const topLevelElements = filteredEarningElements?.filter((possibleChildElement) => {
      return !filteredEarningElements?.some((parentElement) => {
        if (!parentElement || !possibleChildElement) return false;
        const isGroup = 'groupId' in parentElement || 'earningsElementId' in parentElement;
        const isEarningsElement = 'id' in parentElement || 'earningsCatalogElementId' in parentElement;
        if (isGroup) return isChildEarningElement(parentElement, undefined, possibleChildElement);
        if (isEarningsElement) return isChildEarningElement(undefined, parentElement, possibleChildElement);
        return false;
      });
    });

    return (
      topLevelElements.reduce((acc, element) => {
        if (element && 'totalValue' in element) {
          return acc + (element.totalValue ?? 0);
        } else if (element && 'effectiveValue' in element) {
          return acc + (element.effectiveValue ?? 0);
        }
        return acc;
      }, 0) ?? 0
    );
};
