import { ConfirmModal, HintBox, ModalOnCloseProps } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useApiDeleteProjectPermissionGroupMutation } from '@client/shared/api';
import { safeMutation } from '@client/shared/utilities';

interface ProjectGroupDeleteModalProps extends ModalOnCloseProps {
  groupId: string;
  groupName: string;
  projectId: string;
}

export const ProjectGroupDeleteModal = (props: ProjectGroupDeleteModalProps) => {
  const { onClose, groupId, groupName, projectId } = props;
  const { t } = useTranslation();

  const [deleteGroup, { isLoading }] = useApiDeleteProjectPermissionGroupMutation();

  const handleDelete = async () => {
    try {
      await safeMutation(
        deleteGroup,
        {
          projectId: projectId,
          permissionGroupId: groupId,
        },
        isLoading,
      );
      onClose(true);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <ConfirmModal
      title={t('projectSetting.userManagement.deleteProjectGroup.title', { name: groupName })}
      onConfirm={handleDelete}
      onClose={() => onClose(false)}
      confirmVariant="danger"
      confirmText={t('common.delete')}
    >
      <HintBox hintType="warning">{t('projectSetting.userManagement.deleteProjectGroup.warning')}</HintBox>
    </ConfirmModal>
  );
};
