import { BaseSelect, CurrencyExchangeIcon, FormField } from '../..';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { Currency } from '@client/shared/api';

export const CURRENCIES: Currency[] = ['Usd', 'Eur', 'Gbp']; // Currency

export const getCurrencyLabelForCurrency = (currency: Currency) => {
  switch (currency) {
    case 'Eur':
      return '€ - Euro';
    case 'Usd':
      return '$ - US Dollar';
    case 'Gbp':
      return '£ - British Pound';
  }
}

export const CurrencySelect = ({
  formFieldName = 'currency',
  disabled = false,
  className,
  onChange,
  disabledOptions = []
}: {
  formFieldName?: string;
  disabled?: boolean;
  className?: string;
  onChange?: (val: string) => void;
  disabledOptions?: string[];
}) => {
  const { t } = useTranslation();
  const currencyOptions = useMemo(() => {
    return [
      { label: getCurrencyLabelForCurrency('Eur'), value: 'Eur', disabled: disabledOptions.includes('Eur') },
      { label: getCurrencyLabelForCurrency('Usd'), value: 'Usd', disabled: disabledOptions.includes('Usd') },
      { label: getCurrencyLabelForCurrency('Gbp'), value: 'Gbp', disabled: disabledOptions.includes('Gbp') },
    ];
  }, [disabledOptions]);
  return (
    <FormField name={formFieldName}>
      {(control) => (
        <BaseSelect
          className={className}
          icon={<CurrencyExchangeIcon className="h-6 w-6" />}
          label={t('common.currency')}
          options={currencyOptions}
          disabled={disabled}
          {...control}
          onChange={(val) => {
            if (onChange) {
              onChange(val);
            }
            control.onChange(val);
          }}
        />
      )}
    </FormField>
  );
};
