import { SelectProjectReadModel } from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { ContextMenuItem, TrashIcon } from '@client/shared/toolkit';
import React, { useMemo } from 'react';
import { BenchmarkingColumnHeader, BenchmarkingRowHeader } from '.';
import { BENCHMARK_DRAG_TYPE } from './BenchmarkingDragDropItem';

export interface BenchmarkingHeaderData {
  index: number;
  handleDelete: () => void;
  type: BENCHMARK_DRAG_TYPE;
  dropItem?: (id: string, toIndex: number, type: BENCHMARK_DRAG_TYPE) => void;
  isDragDisabled?: boolean;
  isLast?: boolean;
  hoverItem?: (index: number | null, type: BENCHMARK_DRAG_TYPE) => void;
  isHovered?: boolean;
}

export interface BenchmarkingProjectHeaderProps extends BenchmarkingHeaderData {
  project: SelectProjectReadModel;
  handleDelete: () => void;
  isRemoveDisabled?: boolean;
}

export const BenchmarkingProjectHeader = (props: BenchmarkingProjectHeaderProps) => {
  const {
    project,
    handleDelete,
    isRemoveDisabled = false,
    index,
    dropItem,
    isDragDisabled = true,
    type,
    isLast = false,
    hoverItem,
    isHovered
  } = props;
  const { t } = useTranslation();
  const contextMenuItems: ContextMenuItem[] = useMemo(() => {
    return [
      {
        label: t('reporting.benchmarking.projects.menu.remove.title'),
        subtitle: t('reporting.benchmarking.projects.menu.remove.subtitle'),
        icon: <TrashIcon />,
        onClick: handleDelete,
        isDisabled: isRemoveDisabled,
      },
    ];
  }, [t, handleDelete, isRemoveDisabled]);

  const headerProps = useMemo(() => {
    return {
      id: project.id,
      index: index,
      title: project.name,
      contextMenuItems: contextMenuItems,
      subtitle: (
        <>
          {project.city}&nbsp;&#x2022;&nbsp;{project.projectId}
        </>
      ),
      dropItem: dropItem,
      isDragDisabled: isDragDisabled,
      isLast: isLast,
      type: type,
      hoverItem: hoverItem,
      isHovered: isHovered
    };
  }, [
    project.id,
    index,
    project.name,
    contextMenuItems,
    project.city,
    project.projectId,
    dropItem,
    isDragDisabled,
    isLast,
    type,
    hoverItem,
    isHovered
  ]);

  if (type === BENCHMARK_DRAG_TYPE.ROW) {
    return <BenchmarkingRowHeader {...headerProps} />;
  }

  return <BenchmarkingColumnHeader {...headerProps} />;
};
