import { useMemo } from 'react';
import { DecoratedElement, filterElementBySearchValue, FinancingElement, isChildFinancingElement } from '..';

function searchFinanceElements(
  elements: DecoratedElement<FinancingElement>[],
  currentSearchValue: string,
): DecoratedElement<FinancingElement>[] {
  return elements.reduce<DecoratedElement<FinancingElement>[]>((acc, element) => {
    if (filterElementBySearchValue(element, currentSearchValue, false)) {
      acc.push(element);
    }

    if (element.children && element.children.length > 0) {
      acc.push(...searchFinanceElements(element.children, currentSearchValue));
    }

    return acc;
  }, []);
}

const useFinancingSearchData = (
  financingElementsFiltered: DecoratedElement<FinancingElement>[],
  currentSearchValue: string,
) => {
  const [financeSearchResult, filteredFinanceTotalSum] = useMemo(() => {
    if (!currentSearchValue?.length) {
      return [[], 0];
    }

    const rawSearchResults = searchFinanceElements(financingElementsFiltered, currentSearchValue);

    const financeSearchResults = rawSearchResults.map((result) => {
      return result?.element?.group ?? result?.element?.financingElement;
    });

    const financeSearchResultIds = financeSearchResults
      .map((element) => {
        if (!element) return '';
        if ('id' in element && element.id) return element.id;
        if ('groupId' in element && element.groupId) return element.groupId;
        if ('financingCatalogElementId' in element && element.financingCatalogElementId) {
          return element.financingCatalogElementId;
        }
        if ('catalogId' in element && element.catalogId) {
          return element.catalogId;
        }
        return '';
      })
      .filter((id) => id !== '');

    const topLevelElements = financeSearchResults.filter((possibleChildElement) => {
      return !financeSearchResults.some((parentElement) => {
        return parentElement && possibleChildElement
          ? isChildFinancingElement(parentElement, possibleChildElement)
          : false;
      });
    });

    const filteredFinanceTotalSum = topLevelElements.reduce((acc, element) => {
      return acc + (element?.totalValue ?? 0);
    }, 0);

    return [financeSearchResultIds, filteredFinanceTotalSum];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSearchValue]);
  return { financeSearchResult, filteredFinanceTotalSum };
};

export default useFinancingSearchData;
