import { InflationCalcRow } from '.';
import { FormattedCurrency } from '@client/project/shared';
import { InflationReturnResult } from '@client/shared/api';
import { useTranslation } from 'react-i18next';

export const InflationCalcDistribution = ({ distribution }: { distribution: InflationReturnResult | null }) => {
  const { t } = useTranslation();

  if (!distribution) return null;

  return (
    <div className="h-full flex flex-col justify-between">
      {distribution?.returnSuggestion.length ? (
        <div className="flex flex-col h-full overflow-auto">
          {distribution.returnSuggestion.map((element, index) => (
            <InflationCalcRow key={`inflation-calc-${index}`} element={element} />
          ))}
        </div>
      ) : (
        distribution && <span className="italic mt-6">{t('projectCalculate.inflationCalcModal.noDistribution')}</span>
      )}
      <div className="justify-end">
        <div className="flex items-center gap-2 justify-end mt-2">
          <div className="font-medium text-gray-900">{t('projectCalculate.inflationCalcModal.total')}:</div>
          <div className="font-medium">
            <FormattedCurrency amount={distribution.total} options={{ maxDigits: 2, minDigits: 2 }} />
          </div>
        </div>
        <div className="flex items-center gap-2 justify-end mb-2">
          <div className="font-medium text-gray-900">
            {t('projectCalculate.inflationCalcModal.totalInflation')}:
          </div>
          <div className="font-medium">
            <FormattedCurrency amount={distribution.totalInflation} options={{ maxDigits: 2, minDigits: 2 }} />
          </div>
        </div>
      </div>
    </div>
  )
}
