import { useApiGetDefaultValuesQuery } from '@client/shared/api';
import React, { useState } from 'react';
import { EditButton, getCurrencyLabelForCurrency, LoadingIndicator, SlideOver } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { DefaultValuesSlideOver } from './DefaultValuesEditSlideOver';
import { SettingsHeader } from '../SettingsHeader';
import { DefaultValuesBenchmarking } from './benchmarking';
import { getUnitSystemLabel } from '@client/shared/utilities';
import { DefaultValuesCustomLists, DefaultValuesReferenceUnits } from '.';

export const DefaultValues = () => {
  const { t } = useTranslation();
  const { data: response, isFetching } = useApiGetDefaultValuesQuery();

  const [isSlideOverOpen, setIsSlideOverOpen] = useState<boolean>(false);

  return (
    <div>
      <SettingsHeader>{t('app.masterDataDefaultValues.title')}</SettingsHeader>
      <div className="relative h-full">
        {isFetching === true && <LoadingIndicator text={t('app.masterDataDefaultValues.Loading')} mode="overlay-window" />}
      </div>
      <div className="flex flex-col gap-8 divide-y">
        <div className="relative mt-4 mr-10">
          <div className="text-xl mt-8 pb-2">{t('app.masterDataDefaultValue.general')}</div>
          {response && (
            <div className="pl-10">
              <div className="flex flex-row items-center py-1 gap-2">
                <span className="font-bold">{t('common.currency')}:</span>
                <span>{getCurrencyLabelForCurrency(response.currency)}</span>
              </div>
              <div className="flex flex-row items-center py-1 gap-2">
                <span className="font-bold">{t('common.unitSystem')}:</span>
                <span>{getUnitSystemLabel(response.unitSystem)}</span>
              </div>
              <div className="flex flex-row items-center py-1 gap-2">
                <span className="font-bold">{t('projectCalculate.valueType')}:</span>
                <span>{response.valueType === 'Net' ? t('common.net') : t('common.gross')}</span>
              </div>
            </div>
          )}
        </div>
        <div className="relative mr-10">
          <div className="absolute top-5 right-10">
            <EditButton className="ml-auto" onClick={() => setIsSlideOverOpen(true)} />
          </div>
          <div className="text-xl mt-8 pb-2 mr-10">{t('app.masterDataDefaultValues.contractAndControl')}</div>
          <div className="pl-10">
            <div className="flex flex-row items-center py-1 gap-2">
              <span className="font-bold">{t('app.masterDataDefaultValues.dueDateDeadline')}:</span>
              <span>{response?.dueDateDeadline ? t('common.countDay', { count: response.dueDateDeadline }) : '-'}</span>
            </div>
            <div className="flex flex-row items-center py-1 gap-2">
              <span className="font-bold">{t('app.masterDataDefaultValues.cashDiscountDeadline')}:</span>
              <span>
                {response?.cashDiscountDeadline ? t('common.countDay', { count: response.cashDiscountDeadline }) : '-'}
              </span>
            </div>
          </div>
        </div>
        <div className="relative mr-10">
          <div className="text-xl mt-8 pb-2 leading-tight">{t('projectSetting.benchmarking.title')}</div>
          {response && <DefaultValuesBenchmarking defaultValues={response} />}
        </div>
        <div className="relative mr-10">
          <div className="text-xl mt-8 leading-tight">{t('projectSetting.referenceUnits.title')}</div>
          {response && <DefaultValuesReferenceUnits defaultValues={response} />}
        </div>
        <div className="relative mr-10">
          <div className="text-xl mt-8 pb-2">{t('projectSetting.customLists.title')}</div>
          {response && <DefaultValuesCustomLists defaultValues={response} isFetching={isFetching} />}
        </div>
      </div>
      {response &&
        <SlideOver isOpen={isSlideOverOpen} onClose={() => setIsSlideOverOpen(false)}>
          <DefaultValuesSlideOver defaultValues={response} onClose={() => setIsSlideOverOpen(false)} />
        </SlideOver>
      }
    </div>
  );
};
