import { InflationDistribution } from '@client/shared/api';
import { MonthPicker, NumberInput } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';

export const InflationCalcRow = ({ element }: { element: InflationDistribution }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row items-center bg-white divide-x">
      <div className="w-5/12">
        <MonthPicker
          label={t('projectCalculate.vatReturnDate')}
          value={new Date(element.date)}
          onChange={() => {
            // empty
          }}
          disabled
        />
      </div>
      <div className="w-7/12">
        <NumberInput
          label={t('projectCalculate.vatReturnAmount')}
          value={element.amount}
          onChange={() => {
            // empty
          }}
          disabled
        />
      </div>
    </div>
  );
};
