import { CalculationModelEarningsGroupElement, CalculationModelEarningsElement } from '@client/shared/api';

export function isChildEarningElement(
  parentGroupElement: CalculationModelEarningsGroupElement | undefined,
  parentEarningElement: CalculationModelEarningsElement | undefined,
  childElement: CalculationModelEarningsGroupElement | CalculationModelEarningsElement,
): boolean {
  if (
    parentGroupElement === childElement ||
    parentEarningElement === childElement ||
    (parentGroupElement?.children?.length === 0 && parentEarningElement?.children?.length === 0)
  ) {
    return false;
  }

  if (
    parentGroupElement?.children?.some((referenceElement) => {
      const matchesGroupId =
        referenceElement.group && 'groupId' in childElement && referenceElement.group.groupId === childElement.groupId;

      const matchesEarningsElementId =
        referenceElement.group &&
        'earningsElementId' in childElement &&
        referenceElement.group.earningsElementId === childElement.earningsElementId;

      if (matchesGroupId || matchesEarningsElementId) {
        return true;
      }

      return isChildEarningElement(
        referenceElement.group ?? undefined,
        referenceElement.earningsElement ?? undefined,
        childElement,
      );
    })
  ) {
    return true;
  }

  if (
    parentEarningElement?.children?.some((referenceElement) => {
      const matchesId = 'id' in childElement && referenceElement.id === childElement.id;

      const matchesCatalogElementId =
        'earningsCatalogElementId' in childElement &&
        referenceElement.earningsCatalogElementId === childElement.earningsCatalogElementId;

      if (matchesId || matchesCatalogElementId) {
        return true;
      }

      return isChildEarningElement(undefined, referenceElement, childElement);
    })
  ) {
    return true;
  }

  return false;
}
