import { DefaultValuesReadModel, useApiGetDefaultValuesQuery } from '@client/shared/api';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditButton, LoadingIndicator, SlideOver } from '@client/shared/toolkit';
import { DefaultValuesReferenceUnitsSlideOver } from './DefaultValuesReferenceUnitsSlideOver';
import { settings } from '@client/shared/store';
import { ReferenceUnitsList } from './ReferenceUnitsList';

export const DefaultValuesReferenceUnits = ({ defaultValues }: { defaultValues?: DefaultValuesReadModel }) => {
  const { t } = useTranslation();
  const [isSlideOverOpen, setIsSlideOverOpen] = useState<boolean>(false);
  const { data: response, isFetching } = useApiGetDefaultValuesQuery(undefined, {
    skip: typeof defaultValues !== 'undefined',
  });
  const defaultReferenceUnitValues = useMemo(() => {
    return defaultValues ?? response;
  }, [defaultValues, response]);

  if (!defaultReferenceUnitValues) {
    return null;
  }
  return (
    <div className="mt-10 pl-10">
      <ReferenceUnitsList defaultValues={defaultReferenceUnitValues} hasPadding={false} />
      {settings.devMode && (
        <div className="absolute top-5 right-10">
          <EditButton className="ml-auto" onClick={() => setIsSlideOverOpen(true)} />
        </div>
      )}
      {isFetching && <LoadingIndicator text={t('app.masterDataDefaultValues.Loading')} />}
      <SlideOver isOpen={isSlideOverOpen} onClose={() => setIsSlideOverOpen(false)} variant="x-large">
        <DefaultValuesReferenceUnitsSlideOver
          defaultValues={defaultReferenceUnitValues}
          onClose={() => setIsSlideOverOpen(false)}
        />
      </SlideOver>
    </div>
  );
};
