import {
  CalculationModelRiskGroupReadModel,
  CalculationModelRisksQueryResponse,
  useApiGetCalculationModelRisksQuery,
  CalculationModelRiskElementReadModel,
} from '@client/shared/api';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { useMemo } from 'react';
import { flattenRisksElements } from '../utils/flattenRisksElements';

export const useFlattenRisksElements = (
  data?: CalculationModelRisksQueryResponse,
  skipFetch = false,
): (CalculationModelRiskGroupReadModel | CalculationModelRiskElementReadModel)[] => {
  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const { data: risksData } = useApiGetCalculationModelRisksQuery(
    {
      projectId: loadedProjectId ?? 'unset',
      calculationModelId: loadedVariantId ?? '',
    },
    { skip: loadedVariantId == null || !!data || skipFetch },
  );

  return useMemo(() => {
    if (data) {
      return data.payload.riskElements ? flattenRisksElements(data.payload.riskElements) : [];
    } else if (risksData?.payload.riskElements) {
      return flattenRisksElements(risksData?.payload.riskElements);
    }
    return [];
  }, [risksData?.payload.riskElements, data]);
};
