import * as yup from 'yup';
import { InferType } from 'yup';
import { BaseSelectOption, Form, FormField, SlideOver, TextInput } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { ReactNode, useMemo } from 'react';
import { Widget } from '../WidgetDashboard';
import { WidgetDashboardEditFormValues } from '../WidgetDashboardEdit';
import { ProjectReadModel } from '@client/shared/api';
import { DashboardConfigKeys } from '../utils';

export const WidgetDashboardEditFormValidationSchema = yup.object({
  title: yup.string().required('validation.required'),
  size: yup.string().required('validation.required'),
});

export type WidgetDashboardEditFormValidationValues = InferType<typeof WidgetDashboardEditFormValidationSchema>;

export interface WidgetDashboardEditFormProps {
  buttons: ReactNode | string;
  title?: string;
  widget: Widget | null;
  handleSubmit: (data: WidgetDashboardEditFormValues) => void;
  sizeOptions: BaseSelectOption[];
  selectedSize: string;
  project: ProjectReadModel;
  dashboardType?: DashboardConfigKeys
}

export const WidgetEditFormDefault = (props: WidgetDashboardEditFormProps) => {
  const { buttons, widget, title, handleSubmit } = props;
  const { t } = useTranslation();

  const defaultFormValues = useMemo(() => {
    return {
      title: widget?.widget.title ?? title ?? '',
    };
  }, [title, widget?.widget.title]);

  return (
    <Form<WidgetDashboardEditFormValidationValues>
      onSubmit={handleSubmit}
      validationSchema={WidgetDashboardEditFormValidationSchema}
      defaultValues={defaultFormValues}
      className="flex flex-col flex-grow min-h-0"
    >
      <SlideOver.Content className="p-8">
        <FormField name="title">
          {(control) => <TextInput label={t('dashboard.widget.edit.title')} {...control} />}
        </FormField>
      </SlideOver.Content>
      {buttons}
    </Form>
  );
};
