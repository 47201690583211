import { CellType, FactorValueModel, FormulaElementType, FormulaStrategyValueResponseModel } from '@client/shared/api';
import classNames from 'classnames';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { BaseSelect, NumberInput, BaseSelectOption, FormulaFxIcon, ICON_FORMULA_IMAGE } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { FormulaBuilder, FormulaBuilderConstructorArgs } from '../utils';
import { FormulaEditorModal } from './FormulaEditorModal';
import { produce } from 'immer';
import { ReactSVG } from 'react-svg';
import { getSizes, useFormulaEditorDataset } from '../hooks';
import { NumericFormat } from 'react-number-format';
import { getNumberSeparators } from '@client/shared/utilities';
import { useLoadedProjectId, useLoadedProjectUnitSystem, useLoadedVariantId } from '@client/project/store';
import { getUnitLabel, IMPERIAL_COST_UNITS, METRIC_COST_UNITS } from '@client/project/shared';

interface AmountFactorInputProps {
  label: string;
  icon: ReactNode;
  unitOptions?: BaseSelectOption[];
  factor: FactorValueModel;
  disabled?: boolean;
  onChange: (factor: FactorValueModel) => void;
  showFX?: boolean;
  catalogElementId?: string;
  catalogElementType?: FormulaElementType;
  setIsLoading: (isLoading: boolean) => void;
}

type FormulaViewerProps = {
  icon: ReactNode;
  calculatedValue: number;
  visualFormula: string;
  toggleFormulaEditor: () => void;
};

type LocaleSettings = {
  groupSeparator: string | undefined;
  decimalSeparator: string | undefined;
};

export const FormulaViewer = (props: FormulaViewerProps) => {
  const [showActualFormula, setShowActualFormula] = useState(false);

  const onHover = () => {
    setShowActualFormula(true);
  };

  const onLeave = () => {
    setShowActualFormula(false);
  };

  const [localeSettings, setLocaleSettings] = useState<LocaleSettings>(() => {
    return {
      groupSeparator: undefined,
      decimalSeparator: undefined,
    };
  });

  useEffect(() => {
    const { decimalSeparator, groupSeparator } = getNumberSeparators();
    setLocaleSettings({
      decimalSeparator,
      groupSeparator,
    });
  }, []);

  return (
    <div
      className="h-14 flex px-3 items-center w-4/6"
      onClick={props.toggleFormulaEditor}
      onMouseOver={onHover}
      onMouseLeave={onLeave}
    >
      <div className="h-14 w-7 flex items-center">{props.icon}</div>
      <div
        className="fake-mt text-lg appearance-none focus:outline-none bg-transparent font-medium px-2 overflow-x-hidden text-ellipsis flex items-center"
        title={props.visualFormula}
      >
        <span className="text-ellipsis overflow-hidden whitespace-nowrap">
          {showActualFormula ? (
            props.visualFormula ?? ''
          ) : (
            <NumericFormat
              className={classNames('w-full appearance-none focus:outline-none bg-transparent ')}
              value={showActualFormula ? props.visualFormula ?? '' : props.calculatedValue}
              placeholder={''}
              disabled={true}
              decimalSeparator={localeSettings.decimalSeparator}
              thousandsGroupStyle={'thousand'}
              thousandSeparator={localeSettings.groupSeparator}
              decimalScale={2}
              allowNegative={true}
            />
          )}
        </span>
        {!showActualFormula && <FormulaFxIcon className="inline-block ml-2 w-5 h-full align-middle" />}
      </div>
    </div>
  );
};

export const AmountFactorInput = ({
  factor,
  icon,
  label,
  onChange,
  disabled,
  unitOptions,
  showFX = false,
  catalogElementId,
  catalogElementType,
  setIsLoading,
}: AmountFactorInputProps) => {
  const { t } = useTranslation();
  const projectUnitSystem = useLoadedProjectUnitSystem();
  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const formulaEditorData = useFormulaEditorDataset({
    projectId: loadedProjectId ?? 'unset',
    calculationModelId: loadedVariantId ?? '',
  });

  const OPTIONS: FormulaBuilderConstructorArgs = useMemo(() => {
    return {
      ...formulaEditorData.values,
      sizes: getSizes(),
    };
  }, [formulaEditorData]);

  useEffect(() => {
    setIsLoading(formulaEditorData.isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formulaEditorData.isLoading]);

  const formulaBuilder = useMemo(() => {
    return new FormulaBuilder(OPTIONS);
  }, [OPTIONS]);

  const [showFormulaIcon, setShowFormulaIcon] = useState(false);

  const [showFormulaEditor, setShowFormulaEditor] = useState(false);

  const costUnits = projectUnitSystem === 'Metric' ? METRIC_COST_UNITS : IMPERIAL_COST_UNITS;

  const providedUnitOptions =
    unitOptions ||
    costUnits.map((unit) => ({
      label: getUnitLabel(unit),
      value: unit,
    }));

  const BASE_UNIT = providedUnitOptions.at(0)?.value ?? 'm²';

  const [updatedFactor, setUpdatedFactor] = useState<FactorValueModel | null>(null);

  const toggleFormulaEditor = () => {
    setShowFormulaEditor(!showFormulaEditor);
  };

  const focusEventHandlers = useMemo(() => {
    if (!showFX) return {};
    return {
      onFocus: () => setShowFormulaIcon(true),
      onBlur: () => {
        setTimeout(() => setShowFormulaIcon(false), 200);
      },
    };
  }, [showFX]);

  const visualFormula = formulaBuilder.parseFormula(
    factor?.formulaValue?.expression ?? '',
    factor.formulaValue?.expressionParameters ?? [],
  );

  const onChangeFactor = (
    type: CellType,
    value: string | string[] | number | null | FormulaStrategyValueResponseModel,
    save = false,
  ) => {
    let updated: FactorValueModel | null = null;
    switch (type) {
      case 'Static':
        if (typeof value === 'number' || value === null) {
          updated = produce(factor, (draft) => {
            draft.staticValue = {
              value: value,
              unit: factor.staticValue?.unit ?? BASE_UNIT,
            };
            draft.type = 'Static';
          });
        } else if (typeof value === 'string') {
          updated = produce(factor, (draft) => {
            draft.staticValue = {
              value: factor.staticValue?.value ?? 0,
              unit: value,
            };
            // draft.type = 'Static';
          });
        }
        break;

      case 'Formula':
        updated = produce(factor, (draft) => {
          if (value === null) {
            draft.formulaValue = value;
            draft.staticValue = {
              value: value,
              unit: factor.staticValue?.unit ?? BASE_UNIT,
            };
            draft.type = 'Static';
          } else {
            draft.formulaValue = value as FormulaStrategyValueResponseModel;
            draft.type = 'Formula';
          }
        });
    }
    setUpdatedFactor(updated);
    if (save && updated) {
      onChange(updated);
    }
  };

  const saveFormula = (arg: FormulaStrategyValueResponseModel | null) => {
    onChangeFactor('Formula', arg, true);
  };

  return (
    <div>
      <div className="flex grow text-gray-600 text-base font-semibold pl-3 pt-1">{label}</div>
      <div className="flex divide-x">
        {showFX && factor.formulaValue ? (
          <FormulaViewer
            visualFormula={visualFormula}
            calculatedValue={factor?.formulaValue?.value}
            toggleFormulaEditor={toggleFormulaEditor}
            icon={icon}
          />
        ) : (
          <div className="flex flex-row flex-nowrap items-center w-4/6">
            <NumberInput
              label={t('projectCalculate.formulaLabelStaticValue')}
              icon={icon}
              className={classNames('flex-grow')}
              value={factor.staticValue?.value ?? undefined}
              disabled={disabled}
              onChange={(v) => onChangeFactor('Static', v)}
              {...focusEventHandlers}
              onBlur={() => {
                if (updatedFactor !== null) {
                  onChange(updatedFactor);
                }
              }}
            />
            {showFormulaIcon && (
              <div onClick={toggleFormulaEditor}>
                <ReactSVG src={ICON_FORMULA_IMAGE} className="formula-icon__highlight w-16 pr-1" />
              </div>
            )}
          </div>
        )}
        <BaseSelect
          label={t('projectCalculate.formulaLabelUnit')}
          options={providedUnitOptions}
          value={!factor.staticValue?.unit || factor.staticValue?.unit === '' ? BASE_UNIT : factor.staticValue?.unit}
          disabled={disabled}
          onChange={(v) => onChangeFactor('Static', v)}
          className="w-2/6"
          onBlur={() => {
            if (updatedFactor !== null) {
              onChange(updatedFactor);
            }
          }}
        />
      </div>
      {showFormulaEditor && (
        <FormulaEditorModal
          defaultFormula={factor.staticValue?.value ? factor.staticValue?.value.toString() : ''}
          saveFormula={saveFormula}
          factor={factor.formulaValue}
          onClose={toggleFormulaEditor}
          catalogElementId={catalogElementId}
          catalogElementType={catalogElementType || 'CostElement'}
          disabled={disabled}
        />
      )}
    </div>
  );
};
