import { settings } from '@client/shared/store';
import * as Sentry from '@sentry/react';

export const initSentry = () => {
  if (settings.enableSentry) {
    Sentry.init({
      dsn: 'https://6f8e0901e1487155c84cd61a95b7f2fe@o1330619.ingest.us.sentry.io/4508040909422592',
      environment: settings.sentryEnvironment,
      beforeSend(event, hint) {
        if (event.exception) {
          Sentry.showReportDialog({ eventId: event.event_id });
        }
        return event;
      },
    });
  }
};
