import { fork, put } from 'redux-saga/effects';
import { setExpandedBudgetReportIds, setExpandedCashflowCostsReportIds, setExpandedCashflowEarningReportIds, setExpandedCashflowFinancingReportIds, setExpandedCashflowRiskReportIds, setExpandedComparisonReportIds, setExpandedCostBreakdownReportIds, setExpandedDetailReportIds, setExpandedForecastReportIds, setExpandedVariationReportIds, TOGGLED_EXPANDED_IDS } from '@client/project/store';

function* loadReportingExpandedIds() {
  const serializedExpandedIds = localStorage.getItem(TOGGLED_EXPANDED_IDS);
  if (serializedExpandedIds) {
    const preselectedExpandedIds = JSON.parse(serializedExpandedIds)
    if (preselectedExpandedIds.Reporting && typeof preselectedExpandedIds.Reporting !== 'undefined') {
      if (preselectedExpandedIds.Reporting.Detail) {
        yield put(setExpandedDetailReportIds(preselectedExpandedIds.Reporting.Detail))
      }
      if (preselectedExpandedIds.Reporting.Budget) {
        yield put(setExpandedBudgetReportIds(preselectedExpandedIds.Reporting.Budget))
      }
      if (preselectedExpandedIds.Reporting.Comparison) {
        yield put(setExpandedComparisonReportIds(preselectedExpandedIds.Reporting.Comparison))
      }
      if (preselectedExpandedIds.Reporting.Forecast) {
        yield put(setExpandedForecastReportIds(preselectedExpandedIds.Reporting.Forecast))
      }
      if (preselectedExpandedIds.Reporting.CostBreakdown) {
        yield put(setExpandedCostBreakdownReportIds(preselectedExpandedIds.Reporting.CostBreakdown))
      }
      if(preselectedExpandedIds.Reporting.Variation) {
        yield put(setExpandedVariationReportIds(preselectedExpandedIds.Reporting.Variation))
      }
      if(preselectedExpandedIds.Reporting.Cashflow.Costs) {
        yield put(setExpandedCashflowCostsReportIds(preselectedExpandedIds.Reporting.Cashflow.Costs))
      }
      if(preselectedExpandedIds.Reporting.Cashflow.Risk) {
        yield put(setExpandedCashflowRiskReportIds(preselectedExpandedIds.Reporting.Cashflow.Risk))
      }
      if(preselectedExpandedIds.Reporting.Cashflow.Earning) {
        yield put(setExpandedCashflowEarningReportIds(preselectedExpandedIds.Reporting.Cashflow.Earning))
      }
      if(preselectedExpandedIds.Reporting.Cashflow.Financing) {
        yield put(setExpandedCashflowFinancingReportIds(preselectedExpandedIds.Reporting.Cashflow.Financing))
      }
    }
  }
}

export const reportingSaga = function* () {
  yield fork(loadReportingExpandedIds);
};
