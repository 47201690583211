import React, { RefObject, useEffect, useMemo, useState } from 'react';
import {
  AiEvalValuesReadModel,
  SelectCompanyBranchReadModel,
  ShortContractReadModel,
  useApiGetInvoiceRecipientSelectCompanyBranchesQuery,
  useApiGetProjectSelectCompanyBranchesQuery,
} from '@client/shared/api';
import {
  ComboSelect,
  ComboSelectAdditionalOption,
  ContactIcon,
  DocumentViewerFileDataGroup,
  DocumentViewerFileDataInlineEdit,
  DocumentViewerFileDataSet,
  FormField,
  FormRefHandle,
  LoadingIndicator,
} from '@client/shared/toolkit';
import { InvoiceCreateFormValidationValues } from '..';
import { BranchData } from '../InvoiceDocumentReview';
import { useValidateProjectPermission } from '@client/shared/permissions';
import { getCompanyBranches, useLoadedProjectId } from '@client/project/store';
import { useTranslation } from 'react-i18next';
import { AddCompanyModal } from '../../AddCompanyModal';
import { SpvModal } from '../..';

interface InvoiceContractorAndClientEditProps {
  labelContractor: string;
  labelClient: string;
  formRef?: RefObject<FormRefHandle<InvoiceCreateFormValidationValues>> | null;
  originalContractor: SelectCompanyBranchReadModel | null;
  originalClient: SelectCompanyBranchReadModel | null;
  branches?: SelectCompanyBranchReadModel[];
  selectedContract?: ShortContractReadModel | null;
  canEdit: boolean;
  updateEditMode?: (isEditMode: boolean) => void;
  updateUnsavedData?: (formField: string, unsaved: boolean) => void;
  invoiceDocumentFileData?: AiEvalValuesReadModel | null;
  showContractor?: boolean;
}

export const InvoiceContractorAndClientEdit = (props: InvoiceContractorAndClientEditProps) => {
  const {
    labelContractor,
    labelClient,
    formRef,
    originalContractor,
    originalClient,
    branches,
    selectedContract,
    canEdit,
    updateEditMode,
    updateUnsavedData,
    invoiceDocumentFileData,
    showContractor = true,
  } = props;

  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();
  const canWrite = useValidateProjectPermission(['INVOICE_WRITE'], loadedProjectId ?? '');

  // selected contractor from branches list by user
  const [selectedContractor, setSelectedContractor] = useState<SelectCompanyBranchReadModel | null>(null);
  // selected client from branches list by user
  const [selectedClient, setSelectedClient] = useState<SelectCompanyBranchReadModel | null>(null);

  const [createCompanyField, setCreateCompanyField] = useState<'contractor' | 'client'>('contractor');
  const [createdCompany, setCreatedCompany] = useState<string | undefined>(undefined);
  const [isOpenCreateCompanyModal, setIsOpenCreateCompanyModal] = useState(false);
  const [isOpenAddSpvModal, setIsOpenAddSpvModal] = useState(false);
  const [allowChangeMode, setAllowChangeMode] = useState(true);

  const { data: loadedBranches, isFetching: isLoadingBranches } = useApiGetProjectSelectCompanyBranchesQuery(
    {
      projectId: loadedProjectId ?? '',
    },
    {
      skip: !loadedProjectId || !!branches,
    },
  );

  const { data: invoiceRecipientBranches, isFetching: isLoadingInvoiceRecipientBranches } =
    useApiGetInvoiceRecipientSelectCompanyBranchesQuery(
      {
        projectId: loadedProjectId ?? '',
      },
      {
        skip: !loadedProjectId,
      },
    );

  const companiesOptions = useMemo(() => {
    const currentBranches = branches ? branches : loadedBranches;
    return getCompanyBranches(currentBranches);
  }, [branches, loadedBranches]);

  const selectableInvoiceRecipientBranches = useMemo(() => {
    // we keep the already selected client in the list, even though the permissions might have changed
    if (invoiceRecipientBranches?.branches && originalClient) {
      const selectableBranches = [...invoiceRecipientBranches?.branches];
      const foundBranch = invoiceRecipientBranches.branches.find((branch) => branch.id === originalClient.id);
      if (!foundBranch) {
        selectableBranches.push(originalClient);
      }
      return selectableBranches;
    }
    return invoiceRecipientBranches?.branches;
  }, [invoiceRecipientBranches?.branches, originalClient]);

  const invoiceRecipientCompaniesOptions = useMemo(() => {
    return getCompanyBranches(selectableInvoiceRecipientBranches);
  }, [selectableInvoiceRecipientBranches]);

  useEffect(() => {
    setSelectedContractor(originalContractor);
  }, [originalContractor]);

  useEffect(() => {
    setSelectedClient(originalClient);
  }, [originalClient]);

  useEffect(() => {
    if (createdCompany && formRef?.current && (branches?.length || loadedBranches?.length)) {
      const foundBranch =
        branches?.find((branch) => branch.id === createdCompany || branch.companyId === createdCompany) ??
        loadedBranches?.find((branch) => branch.id === createdCompany || branch.companyId === createdCompany);
      if (foundBranch) {
        if (createCompanyField === 'contractor') {
          setSelectedContractor(foundBranch);
          formRef?.current?.setValue('invoicingPartyId', foundBranch.id);
          if (updateUnsavedData) {
            updateUnsavedData('invoicingPartyId', originalContractor?.id === foundBranch?.id);
          }
        } else {
          setSelectedClient(foundBranch);
          formRef?.current?.setValue('invoiceRecipientId', foundBranch.id);
          if (updateUnsavedData) {
            updateUnsavedData('invoiceRecipientId', originalClient?.id === foundBranch?.id);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdCompany, loadedBranches, branches]);

  // contractor and client are coming from contract if fileData
  useEffect(() => {
    if (selectedContract) {
      if (selectedContract.contractorId) {
        const foundContractor = branches?.find((branch) => branch.id === selectedContract.contractorId);
        setSelectedContractor(foundContractor ?? null);
      } else {
        setSelectedContractor(null);
      }
      if (selectedContract.clientId) {
        const foundClient = branches?.find((branch) => branch.id === selectedContract.clientId);
        setSelectedClient(foundClient ?? null);
      } else {
        setSelectedClient(null);
      }
    }
  }, [selectedContract, branches]);

  // const { setUnsavedData } = useContext(InvoiceEditContext);

  return (
    <>
      {(isLoadingBranches || isLoadingInvoiceRecipientBranches) && (
        <LoadingIndicator text={t('projectControl.companiesLoading') ?? ''} mode="overlay" />
      )}

      {/*--------- CONTRACTOR -------*/}
      {showContractor && (
        <DocumentViewerFileDataGroup className="relative" divider={false}>
          <DocumentViewerFileDataInlineEdit
            updateEditMode={updateEditMode}
            allowChangeMode={allowChangeMode}
            toggleContent={
              canEdit ? (
                <FormField name="invoicingPartyId">
                  {(control) => (
                    <ComboSelect
                      label={labelContractor}
                      icon={<ContactIcon className="h-6 w-6" />}
                      pageOptions={true}
                      options={companiesOptions}
                      additionalOption={
                        <ComboSelectAdditionalOption
                          label={t('projectControl.createNewContractor')}
                          disabled={!canWrite}
                          onClick={() => {
                            setIsOpenCreateCompanyModal(true);
                            setCreateCompanyField('contractor');
                          }}
                        />
                      }
                      disabled={!canWrite}
                      {...control}
                      nullable
                      handlePopoverVisibility={(isOpen) => setAllowChangeMode(!isOpen)}
                      onChange={(branchId) => {
                        const foundContractor = branches?.find((branch) => branch.id === branchId) ?? loadedBranches?.find((branch) => branch.id === branchId);
                        setSelectedContractor(foundContractor ?? null);
                        control.onChange(branchId);
                        formRef?.current?.setValue('contractId', null);
                        if (updateUnsavedData) {
                          updateUnsavedData('invoicingPartyId', originalContractor?.id === foundContractor?.id);
                        }
                      }}
                    />
                  )}
                </FormField>
              ) : undefined
            }
          >
            <div className="divide-y-2">
              {selectedContractor ? (
                <BranchData
                  branch={selectedContractor}
                  label={labelContractor}
                  branchNameClassName={originalContractor?.id !== selectedContractor?.id ? 'text-secondary' : undefined}
                />
              ) : (
                <DocumentViewerFileDataSet label={labelContractor}>
                  <span className="italic text-sm text-gray-500">-</span>
                </DocumentViewerFileDataSet>
              )}
            </div>
          </DocumentViewerFileDataInlineEdit>
        </DocumentViewerFileDataGroup>
      )}

      {/*--------- CLIENT -------*/}
      <DocumentViewerFileDataGroup className="relative" divider={false}>
        <DocumentViewerFileDataInlineEdit
          updateEditMode={updateEditMode}
          allowChangeMode={allowChangeMode}
          toggleContent={
            canEdit ? (
              <FormField name="invoiceRecipientId">
                {(control) => (
                  <ComboSelect
                    label={labelClient}
                    icon={<ContactIcon className="h-6 w-6" />}
                    pageOptions={true}
                    options={invoiceRecipientCompaniesOptions}
                    additionalOption={
                      <div className="flex flex-col divide-y sticky bottom-0">
                        {!invoiceRecipientBranches?.isSpvCompanies && (
                          <ComboSelectAdditionalOption
                            label={t('projectControl.createNewContractor')}
                            disabled={!canWrite}
                            onClick={() => {
                              setIsOpenCreateCompanyModal(true);
                              setCreateCompanyField('client');
                            }}
                          />
                        )}
                        <ComboSelectAdditionalOption
                          label={t('projectControl.createNewSpv')}
                          disabled={!canWrite}
                          onClick={() => {
                            setCreateCompanyField('client');
                            setIsOpenAddSpvModal(true);
                          }}
                        />
                      </div>
                    }
                    disabled={!canWrite}
                    {...control}
                    nullable
                    handlePopoverVisibility={(isOpen) => setAllowChangeMode(!isOpen)}
                    onChange={(branchId) => {
                      const foundClient = selectableInvoiceRecipientBranches?.find((branch) => branch.id === branchId) ?? loadedBranches?.find((branch) => branch.id === branchId);
                      setSelectedClient(foundClient ?? null);
                      control.onChange(branchId);
                      formRef?.current?.setValue('contractId', null);
                      if (updateUnsavedData) {
                        updateUnsavedData('invoiceRecipientId', originalClient?.id === foundClient?.id);
                      }
                    }}
                  />
                )}
              </FormField>
            ) : undefined
          }
        >
          <div className="divide-y-2">
            {selectedClient ? (
              <BranchData
                branch={selectedClient}
                label={labelClient}
                branchNameClassName={originalClient?.id !== selectedClient?.id ? 'text-secondary' : undefined}
              />
            ) : (
              <DocumentViewerFileDataSet label={labelClient}>
                <span className="italic text-sm text-gray-500">-</span>
              </DocumentViewerFileDataSet>
            )}
          </div>
        </DocumentViewerFileDataInlineEdit>
      </DocumentViewerFileDataGroup>
      <AddCompanyModal
        isOpen={isOpenCreateCompanyModal}
        onClose={(branchId) => {
          setCreatedCompany(branchId);
          setIsOpenCreateCompanyModal(false);
        }}
        field={createCompanyField}
        invoiceDocumentFileData={invoiceDocumentFileData}
      />
      <SpvModal
        isOpen={isOpenAddSpvModal}
        onClose={(spvId) => {
          if(spvId) {
            setCreatedCompany(spvId);
          }
          setIsOpenAddSpvModal(false);
        }}
        projectId={loadedProjectId}
      />
    </>
  );
};
