import { CalculationCostElement, CostCatalogElementDto, CostElementDto } from '@client/shared/api';

export const isChildCostElement = (
  parentElement: CalculationCostElement | CostCatalogElementDto | CostElementDto,
  childElement: CalculationCostElement | CostCatalogElementDto | CostElementDto,
): boolean => {
  const costElements = 'costElements' in parentElement ? parentElement.costElements : [];
  const costGroups = 'children' in parentElement ? parentElement.children : [];

  if (parentElement === childElement || (costElements.length === 0 && costGroups.length === 0)) {
    return false;
  }

  const foundInCostElements = costElements.some((referenceElement) => {
    if (
      'elementId' in childElement &&
      'elementId' in referenceElement.costElement &&
      referenceElement.costElement.elementId === childElement.elementId
    ) {
      return true;
    }
    return isChildCostElement(referenceElement, childElement);
  });

  if (foundInCostElements) {
    return true;
  }

  const foundInCostGroups = costGroups.some((referenceElement) => {
    if ('id' in childElement && 'id' in referenceElement && referenceElement.id === childElement.id) {
      return true;
    }
    return isChildCostElement(referenceElement, childElement);
  });

  return foundInCostGroups;
};