import {
  BillIcon,
  ComboSelect,
  ComboSelectAdditionalOption,
  ComboSelectOption,
  FormField, PencilIcon,
  SlideOverTitle,
} from '@client/shared/toolkit';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import {
  CalculationRuleGroupReadModel,
  CalculationRuleValueOverridePayload,
  CalculationRuleValueOverrideReadModel,
  InvoiceCalculationSchemeReadModel,
  InvoiceCalculationSchemeType,
  InvoiceReadModel,
} from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useLoadedProjectId } from '@client/project/store';
import { getInvoiceCoverSheetDeductionGroupLabel } from '../../../utils';
import cn from 'classnames';
import { ContractIcsRules } from './ContractIcsRuleEditForm';

export const EDITABLE_RULE_TYPES = ['Deduction', 'Retention', 'Discount', 'CashDiscount'];

type SelectedIcsSchemesType = {
  Single: InvoiceCalculationSchemeReadModel | null;
  Cumulated: InvoiceCalculationSchemeReadModel | null;
  AdvancePayment: InvoiceCalculationSchemeReadModel | null;
};

interface ContractIcsProps {
  canWrite: boolean;
  invoices: InvoiceReadModel[];
  overrideRules: {
    Single: CalculationRuleValueOverridePayload[];
    Cumulated: CalculationRuleValueOverridePayload[];
    AdvancePayment: CalculationRuleValueOverridePayload[];
  };
  originalOverrideValues: {
    Single: CalculationRuleValueOverrideReadModel[];
    Cumulated: CalculationRuleValueOverrideReadModel[];
    AdvancePayment: CalculationRuleValueOverrideReadModel[];
  };
  schemes: {
    Single: InvoiceCalculationSchemeReadModel | null;
    Cumulated: InvoiceCalculationSchemeReadModel | null;
    AdvancePayment: InvoiceCalculationSchemeReadModel | null;
  };
  updateOverrideValues: (type: InvoiceCalculationSchemeType, values: CalculationRuleValueOverridePayload[]) => void;
  sheets?: InvoiceCalculationSchemeReadModel[];
}

export const ContractIcs = (props: ContractIcsProps) => {
  const { canWrite, invoices, sheets, overrideRules, updateOverrideValues, schemes, originalOverrideValues } = props;
  const loadedProjectId = useLoadedProjectId();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [selectedIcsSchemes, setSelectedIcsSchemes] = useState<SelectedIcsSchemesType>({
    Single: schemes.Single,
    Cumulated: schemes.Cumulated,
    AdvancePayment: schemes.AdvancePayment,
  });

  const singleSheetOptions = useMemo(() => {
    return sheets && sheets.length
      ? sheets
          .filter((sheet) => sheet.type === 'Single')
          .map((sheet) => {
            return {
              label: sheet.name,
              value: sheet.invoiceCalculationSchemeId,
            };
          })
      : [];
  }, [sheets]);

  const cumulatedSheetOptions = useMemo(() => {
    return sheets && sheets.length
      ? sheets
          .filter((sheet) => sheet.type === 'Cumulated')
          .map((sheet) => {
            return {
              label: sheet.name,
              value: sheet.invoiceCalculationSchemeId,
            };
          })
      : [];
  }, [sheets]);

  const advancePaymentSheetOptions = useMemo(() => {
    return sheets && sheets.length
      ? sheets
          .filter((sheet) => sheet.type === 'AdvancePayment')
          .map((sheet) => {
            return {
              label: sheet.name,
              value: sheet.invoiceCalculationSchemeId,
            };
          })
      : [];
  }, [sheets]);

  const goToIcsEditView = () => {
    navigate({
      pathname: ROUTES_CONFIG.PROJECT_DASHBOARD_SETTINGS.path.replace(':id', loadedProjectId ?? ''),
      search: createSearchParams({ tab: 'invoiceCoverSheets' }).toString(),
    });
  };

  const updateSelectedIcs = useCallback(
    (type: InvoiceCalculationSchemeType, id?: string | null) => {
      const copy = { ...selectedIcsSchemes };
      copy[type] = id ? sheets?.find((sheet) => sheet.invoiceCalculationSchemeId === id) ?? null : null;
      setSelectedIcsSchemes(copy);
    },
    [sheets, selectedIcsSchemes],
  );

  const cumulatedInvoicesExist = useMemo(() => {
    return invoices.some((x) => (x.type === 'Partial' || x.type === 'PartialFinal' || x.type === 'Final') && x.state !== 'Pending')
  }, [invoices])

  return (
    <>
      <SlideOverTitle title={t('app.settingsInvoiceCoverSheets')} />
      <ContractIcsFormField
        name="singleInvoiceCalculationScheme"
        selectedScheme={selectedIcsSchemes.Single}
        type="Single"
        label={`${t('ics.invoiceCoverSheet')} - ${t('app.settingsIcsTypeNotCumulated')}`}
        options={singleSheetOptions}
        onClick={goToIcsEditView}
        updateSelectedIcs={(value) => updateSelectedIcs('Single', value)}
        canWrite={canWrite}
        overrideRules={overrideRules.Single}
        setOverrideRules={(rules) => updateOverrideValues('Single', rules)}
        originalOverrideValues={originalOverrideValues.Single}
        title={t('ics.defaultValuesNewSingleInvoicesTitle')}
      />
      <ContractIcsFormField
        name="cumulatedInvoiceCalculationScheme"
        selectedScheme={selectedIcsSchemes.Cumulated}
        type="Cumulated"
        label={`${t('ics.invoiceCoverSheet')} - ${t('app.settingsIcsTypeCumulated')}`}
        options={cumulatedSheetOptions}
        onClick={goToIcsEditView}
        updateSelectedIcs={(value) => updateSelectedIcs('Cumulated', value)}
        canWrite={canWrite && !cumulatedInvoicesExist}
        overrideRules={overrideRules.Cumulated}
        setOverrideRules={(rules) => updateOverrideValues('Cumulated', rules)}
        originalOverrideValues={originalOverrideValues.Cumulated}
        title={t('ics.defaultValuesNewCumulatedInvoicesTitle')}
      />
      <ContractIcsFormField
        name="advancePaymentInvoiceCalculationScheme"
        selectedScheme={selectedIcsSchemes.AdvancePayment}
        type="AdvancePayment"
        label={`${t('ics.invoiceCoverSheet')} - ${t('app.settingsIcsTypeAdvancePayment')}`}
        options={advancePaymentSheetOptions}
        onClick={goToIcsEditView}
        updateSelectedIcs={(value) => updateSelectedIcs('AdvancePayment', value)}
        canWrite={canWrite}
        overrideRules={overrideRules.AdvancePayment}
        setOverrideRules={(rules) => updateOverrideValues('AdvancePayment', rules)}
        originalOverrideValues={originalOverrideValues.AdvancePayment}
        title={t('ics.defaultValuesNewAdvancePaymentInvoicesTitle')}
      />
    </>
  );
};

interface ContractIcsFormFieldProps {
  selectedScheme: InvoiceCalculationSchemeReadModel | null;
  type: InvoiceCalculationSchemeType;
  label: string;
  options: ComboSelectOption[];
  onClick: () => void;
  updateSelectedIcs: (id?: string | null) => void;
  canWrite: boolean;
  name: string;
  overrideRules: CalculationRuleValueOverridePayload[];
  setOverrideRules: (overrideRules: CalculationRuleValueOverridePayload[]) => void;
  originalOverrideValues: CalculationRuleValueOverrideReadModel[];
  title?: string;
}

export const ContractIcsFormField = (props: ContractIcsFormFieldProps) => {
  const {
    selectedScheme,
    type,
    title,
    label,
    options,
    onClick,
    updateSelectedIcs,
    canWrite,
    name,
    overrideRules,
    setOverrideRules,
    originalOverrideValues,
  } = props;

  const { t } = useTranslation();

  return (
    <>
      <FormField name={name}>
        {(control) => (
          <ComboSelect
            className="mt-0.5"
            icon={<BillIcon className="h-6 w-6" />}
            options={options}
            additionalOption={<ComboSelectAdditionalOption label={t('app.settingsCreateInvoiceCoverSheetSubtitle')} disabled={!canWrite} onClick={onClick} />}
            label={label}
            onChange={(value) => {
              updateSelectedIcs(value);
              setOverrideRules([]);
              control.onChange(value);
            }}
            value={control.value}
            disabled={!canWrite}
            nullable
          />
        )}
      </FormField>
      {selectedScheme && (
        <Disclosure as="div" className="divide-y-2 flex flex-col mb-2 border-t-2" defaultOpen={false}>
          {({ open: singleOpen }) => (
            <>
              <DisclosureButton as="div" className="bg-white pl-3 pr-2">
                <SlideOverTitle
                  title=""
                  className="flex-1 flex justify-between hover:text-gray-800 duration-300 transition-colors cursor-pointer mt-3 gap-2"
                  marginTop={false}
                >
                  <span className="text-sm flex gap-2 items-start">
                    <PencilIcon className="w-4 mt-0.5 ml-1" />
                    {title ? title : t('ics.defaultValuesNewInvoicesTitle')}
                  </span>
                  <ChevronDownIcon
                    className={classNames(
                      'transition-transform will-change-transform duration-100 transform -rotate-90 h5 w-5 flex-none',
                      {
                        'rotate-0': singleOpen,
                      },
                    )}
                  />
                </SlideOverTitle>
              </DisclosureButton>
              <DisclosurePanel className="bg-white">
                <div className={cn('w-full flex flex-col overflow-hidden py-4 border-1 divide-y-2')}>
                  {selectedScheme.calculationRuleGroups.length > 0 ? (
                    <>
                      {selectedScheme.calculationRuleGroups.map((group) => (
                        <ContractIcsGroupEditForm
                          key={`scheme-${type}-${group.calculationRuleGroupId}`}
                          group={group}
                          type={type}
                          overrideRules={overrideRules}
                          setOverrideRules={setOverrideRules}
                          originalOverrideValues={originalOverrideValues}
                        />
                      ))}
                    </>
                  ) : <>{t('ics.noRulesAvailable')}</>}
                </div>
              </DisclosurePanel>
            </>
          )}
        </Disclosure>
      )}
    </>
  );
};

interface ContractIcsGroupEditFormProps {
  group: CalculationRuleGroupReadModel;
  type: InvoiceCalculationSchemeType;
  overrideRules: CalculationRuleValueOverridePayload[];
  setOverrideRules: (overrideRules: CalculationRuleValueOverridePayload[]) => void;
  originalOverrideValues: CalculationRuleValueOverrideReadModel[];
}

const ContractIcsGroupEditForm = (props: ContractIcsGroupEditFormProps) => {
  const { group, overrideRules, setOverrideRules, originalOverrideValues } = props;

  const { t } = useTranslation();

  const editableRules = useMemo(() => {
    return group.calculationRules.filter((rule) => EDITABLE_RULE_TYPES.includes(rule.type));
  }, [group.calculationRules]);

  return (
    <div className="[&:not(:last-child)]:pb-4 [&:not(:first-child)]:pt-4 px-3">
      <div className="grid grid-cols-5 gap-2 text-right mb-1 font-medium">
        <span className="col-span-2 font-bold text-left">{getInvoiceCoverSheetDeductionGroupLabel(group.name)}</span>
        {editableRules.length > 0 && (
          <>
            <span>{t('ics.deductionFactor')}</span>
            <span>{t('ics.deductionValueAmount')}</span>
            <span>{t('ics.deductionNameVat')}</span>
          </>
        )}
      </div>
      {editableRules.length > 0 ? (
        <ContractIcsRules
          rules={editableRules}
          overrideValues={overrideRules}
          updateOverrideValues={setOverrideRules}
          originalOverrideValues={originalOverrideValues}
        />
      ) : (
        <span className="text-gray-500 italic text-sm font-light">{t('ics.noRulesAvailable')}</span>
      )}
    </div>
  );
};
