import { SlideOverTitle } from '@client/shared/toolkit';
import { FeatureElement, FeatureElementEditProps, FeatureElementRef } from '.';
import { useTranslation } from 'react-i18next';
import { InflationCalcEdit } from '../Distribution';
import {
  InflationElementReadModel,
  useApiPostUpdateInflationCostElementMutation,
  useApiPostUpdateInflationEarningElementMutation,
} from '@client/shared/api';
import React, { forwardRef, Ref, useEffect, useImperativeHandle } from 'react';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { safeMutation } from '@client/shared/utilities';

interface InflationElementEditSectionProps extends FeatureElementEditProps {
  element: InflationElementReadModel;
  handleChangeElement: (element: InflationElementReadModel) => void;
}

export const InflationElementEditSection = forwardRef(
  (props: InflationElementEditSectionProps, ref: Ref<FeatureElementRef>) => {
    const { handleChangeElement, elementId, element, setElement, section, setIsUpdating } = props;

    const { t } = useTranslation();
    const loadedProjectId = useLoadedProjectId();
    const loadedVariantId = useLoadedVariantId();

    const [updateCostInflationElement, { isLoading: isUpdatingCost }] = useApiPostUpdateInflationCostElementMutation();
    const [updateEarningsInflationElement, { isLoading: isUpdatingEarnings }] =
      useApiPostUpdateInflationEarningElementMutation();

    useImperativeHandle(ref, () => ({ updateData }));

    useEffect(() => {
      setIsUpdating(isUpdatingEarnings || isUpdatingCost);
    }, [isUpdatingEarnings, isUpdatingCost, setIsUpdating]);

    const updateData = (checkElement: FeatureElement) => {
      return handleUpdateElement(checkElement as InflationElementReadModel);
    };

    const handleUpdateElement = async (updatedElement: InflationElementReadModel) => {
      if (elementId && loadedProjectId && loadedVariantId) {
        if (section === 'costs') {
          try {
            const result = await safeMutation(
              updateCostInflationElement,
              {
                projectId: loadedProjectId,
                calculationModelId: loadedVariantId,
                id: elementId,
                body: updatedElement,
              },
              isUpdatingCost,
            );
            if (result) {
              return result;
            }
          } catch (e) {
            console.log(e);
          }
        } else {
          try {
            const result = await safeMutation(
              updateEarningsInflationElement,
              {
                projectId: loadedProjectId,
                calculationModelId: loadedVariantId,
                id: elementId,
                body: updatedElement,
              },
              isUpdatingEarnings,
            );
            if (result) {
              return result;
            }
          } catch (e) {
            console.log(e);
          }
        }
      }
      return undefined;
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const updateProp = async (prop: keyof InflationElementReadModel, value: any) => {
      if (element[prop] !== value) {
        const updatedElement = { ...element, [prop]: value };
        setElement(updatedElement);
        handleChangeElement(updatedElement);
      }
    };

    return (
      <>
        <SlideOverTitle title={t('projectCalculate.inflationCalcModal.title')} className="px-8" />
        <div className="mb-4 mx-8 ">
          <InflationCalcEdit
            id={elementId}
            type={section}
            inflationRate={element.inflationRate}
            setInflationRate={(value) => updateProp('inflationRate', value)}
            startDate={element.startDate}
            setStartDate={(value) => updateProp('startDate', value)}
            endDate={element.endDate}
            setEndDate={() => {
              // empty
            }}
            selectedElementIds={element.groupIds ?? []}
            setSelectedElementIds={(value) => updateProp('groupIds', value)}
            selectedUDF={element.userDefinedFieldId ?? ''}
            setSelectedUDF={(value) => updateProp('userDefinedFieldId', value)}
            selectedUDFLabels={element.userDefinedFieldLabelIds ?? []}
            setSelectedUDFLabels={(value) => updateProp('userDefinedFieldLabelIds', value)}
            className="w-full"
          />
        </div>
      </>
    );
  },
);
