import { WidgetContainer, WidgetContainerContent, WidgetContainerTitle } from '@client/shared/toolkit';
import { DashboardWidgetProps } from '../WidgetDashboard';
import { useTranslation } from 'react-i18next';
import { CashOutChart } from '../../Charts';
import { useEffect, useRef, useState } from 'react';

export const DashboardWidgetCashOutPlan = (props: DashboardWidgetProps) => {
  const { widget } = props;
  const { t } = useTranslation();
  const chartContainer = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (chartContainer.current) {
      setHeight(chartContainer.current.clientHeight);
      setWidth(chartContainer.current.clientWidth);
    }
  }, []);
 
  return (
    <WidgetContainer className="flex flex-col">
      <WidgetContainerTitle>
        {widget.title ? widget.title : t('dashboard.widget.cashOutPlan.title')}
      </WidgetContainerTitle>
      <WidgetContainerContent className="flex-1">
        <div ref={chartContainer} className='h-full'>
          <CashOutChart  variant="compact" height={height} width={width} maintainAspectRatio={true} />
        </div>
      </WidgetContainerContent>
    </WidgetContainer>
  )
}
