import { FinancingGroupReadModel, FinancingElementShortReadModel } from '@client/shared/api';

export function isChildFinancingElement(
  parentElement: FinancingGroupReadModel | FinancingElementShortReadModel,
  childElement: FinancingGroupReadModel | FinancingElementShortReadModel,
): boolean {
  const financingElements = 'financingElements' in parentElement ? parentElement.financingElements : [];
  const financingGroups = 'groups' in parentElement ? parentElement.groups : [];
  if (parentElement === childElement || (financingElements.length === 0 && financingGroups.length === 0) ) {
    return false;
  }

  const foundInFinancingElements = financingElements.some((referenceElement) => {
    if (
      ('financingCatalogElementId' in childElement &&
        'financingCatalogElementId' in referenceElement &&
        referenceElement.financingCatalogElementId === childElement.financingCatalogElementId) ||
      ('id' in childElement && 'id' in referenceElement && referenceElement.id === childElement.id)
    ) {
      return true;
    }
    return isChildFinancingElement(referenceElement, childElement);
  });

  if (foundInFinancingElements) {
    return true;
  }

  const foundInFinancingGroups = financingGroups.some((referenceElement) => {
    if (
      ('groupId' in childElement &&
        'groupId' in referenceElement &&
        referenceElement.groupId === childElement.groupId) ||
      ('catalogId' in childElement &&
        'catalogId' in referenceElement &&
        referenceElement.catalogId === childElement.catalogId)
    ) {
      return true;
    }
    return isChildFinancingElement(referenceElement, childElement);
  });

  return foundInFinancingGroups;
}
