import * as yup from 'yup';
import { InferType } from 'yup';
import { WidgetDashboardEditFormProps } from './WidgetEditFormDefault';
import { useTranslation } from 'react-i18next';
import { useMemo, useRef } from 'react';
import {
  BaseSelect,
  BaseSelectOption,
  Form,
  FormField,
  FormRefHandle,
  SlideOver,
  TextInput,
} from '@client/shared/toolkit';

export const WidgetDashboardEditFormWaterfallValidationSchema = yup.object({
  title: yup.string().required('validation.required'),
  size: yup.string().required('validation.required'),
  calculationModelId: yup.string().optional().nullable()
});

export type WidgetDashboardEditFormWaterfallValidationValues = InferType<
  typeof WidgetDashboardEditFormWaterfallValidationSchema
>;

export const WidgetEditFormWaterfall = (props: WidgetDashboardEditFormProps) => {
  const { buttons, widget, handleSubmit, sizeOptions, selectedSize, project } = props;
  const { t } = useTranslation();

  const formRef = useRef<FormRefHandle<WidgetDashboardEditFormWaterfallValidationValues>>();

   const initialBudgetOptions: BaseSelectOption[] = useMemo(() => {
      return project.calculationModels.map((model) => {
        return {
          label: model.name,
          value: model.id
        };
      });
    }, [project.calculationModels]);

  const defaultFormValues = useMemo(() => {
    return {
      title: widget?.widget.title ?? t('dashboard.widget.waterfall.title'),
      size: selectedSize,
      calculationModelId: widget?.widget.additionalConfig?.Waterfall?.calculationModelId || initialBudgetOptions[0].value 
    };
  }, [t, widget?.widget.title, widget?.widget.additionalConfig?.Waterfall?.calculationModelId, selectedSize, initialBudgetOptions]);

  return (
    <Form<WidgetDashboardEditFormWaterfallValidationValues>
      onSubmit={handleSubmit}
      validationSchema={WidgetDashboardEditFormWaterfallValidationSchema}
      defaultValues={defaultFormValues}
      className="flex flex-col flex-grow min-h-0"
      ref={formRef}
    >
      <SlideOver.Content className="p-8">
        <FormField name="title">
          {(control) => <TextInput label={t('dashboard.widget.edit.title')} {...control} />}
        </FormField>
        <FormField name="size">
          {(control) => <BaseSelect label={t('dashboard.widget.size')} options={sizeOptions} {...control} />}
        </FormField>
        <FormField name="calculationModelId">
          {(control) => <BaseSelect label={t('reporting.tableInitialBudget')} options={initialBudgetOptions} {...control} />}
        </FormField>
      </SlideOver.Content>
      {buttons}
    </Form>
  );
};
