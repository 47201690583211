import { Widget } from '../WidgetDashboard';

/**
 * The JLL custom dashboard reports are hardcoded for now.
 * Goal is that those reports can be created and edited in a template of a project.
 */

export enum JLL_CUSTOM_REPORT_DASHBOARD_NAME {
  'RisksMitigation' = 'jll-monthly-report-risks-and-mitigation',
  'ProgressQuality' = 'jll-monthly-report-progress-and-quality',
  'CostAndContract' = 'jll-monthly-report-cost-and-contract',
  'WeeklyReport' = 'jll-weekly-report',
}

export const JLL_CUSTOM_REPORT_DASHBOARD_IDS: string[] = Object.values(JLL_CUSTOM_REPORT_DASHBOARD_NAME);

export type JLLCustomReportDashboard = {
  id: string;
  name: string;
  layout: Widget[];
};

export type JLLCustomReportDashboardConfigsType = {
  [key in JLL_CUSTOM_REPORT_DASHBOARD_NAME]: JLLCustomReportDashboard;
};

// TODO hardcode the widgets for each report
export const JLL_CUSTOM_REPORT_DASHBOARDS_CONFIG: JLLCustomReportDashboardConfigsType = {
  [JLL_CUSTOM_REPORT_DASHBOARD_NAME.RisksMitigation]: {
    id: JLL_CUSTOM_REPORT_DASHBOARD_NAME.RisksMitigation,
    name: 'Monthly Report - Risk & Mitigation',
    layout: [],
  },
  [JLL_CUSTOM_REPORT_DASHBOARD_NAME.ProgressQuality]: {
    id: JLL_CUSTOM_REPORT_DASHBOARD_NAME.ProgressQuality,
    name: 'Monthly Report - Progress & Quality',
    layout: [],
  },
  [JLL_CUSTOM_REPORT_DASHBOARD_NAME.CostAndContract]: {
    id: JLL_CUSTOM_REPORT_DASHBOARD_NAME.CostAndContract,
    name: 'Monthly Report - Cost & Contract',
    layout: [],
  },
  [JLL_CUSTOM_REPORT_DASHBOARD_NAME.WeeklyReport]: {
    id: JLL_CUSTOM_REPORT_DASHBOARD_NAME.WeeklyReport,
    name: 'Weekly Report',
    layout: [],
  },
};
