import { DashboardWidgetType, WidgetDashboardEditFormProps } from '..';
import { useMemo } from 'react';
import { BaseSelect, Form, FormField, SlideOver, TextInput } from '@client/shared/toolkit';
import * as yup from 'yup';
import { InferType } from 'yup';
import { useTranslation } from 'react-i18next';

export const WidgetDashboardEditFormTextBoxValidationSchema = yup.object({
  title: yup.string().required('validation.required'),
  text: yup.string().required('validation.required'),
  size: yup.string().required('validation.required'),
});

export type WidgetDashboardEditFormTextBoxValidationValues = InferType<
  typeof WidgetDashboardEditFormTextBoxValidationSchema
>;

export const WidgetEditFormTextBox = (props: WidgetDashboardEditFormProps) => {
  const { buttons, widget, handleSubmit, sizeOptions, selectedSize } = props;
  const { t } = useTranslation();

  const defaultFormValues = useMemo(() => {
    return {
      title: widget?.widget.title ?? t('dashboard.widget.textBox.title'),
      text: widget?.widget.additionalConfig?.[DashboardWidgetType.TextBox]?.text ?? '',
      size: selectedSize,
    };
  }, [t, selectedSize, widget?.widget.title, widget?.widget.additionalConfig]);

  return (
    <Form<WidgetDashboardEditFormTextBoxValidationValues>
      onSubmit={handleSubmit}
      validationSchema={WidgetDashboardEditFormTextBoxValidationSchema}
      defaultValues={defaultFormValues}
      className="flex flex-col flex-grow min-h-0"
    >
      <SlideOver.Content className="p-8">
        <FormField name="title">
          {(control) => <TextInput label={t('dashboard.widget.edit.title')} {...control} />}
        </FormField>
        <FormField name="text">
          {(control) => (
            <TextInput
              inputType="textarea"
              label={t('dashboard.widget.textBox.edit.text')}
              className="h-64"
              {...control}
            />
          )}
        </FormField>
        <FormField name="size">
          {(control) => <BaseSelect label={t('dashboard.widget.size')} options={sizeOptions} {...control} />}
        </FormField>
      </SlideOver.Content>
      {buttons}
    </Form>
  );
};
