import { useTranslation } from 'react-i18next';
import { AddIcon, Badge, ContextMenu, ContextMenuItem } from '@client/shared/toolkit';
import React, { useMemo, useState } from 'react';
import { SelectProjectReadModel } from '@client/shared/api';
import {
  BenchmarkingEmptyRows,
  BenchmarkingParameterHeader,
  BenchmarkingProjectHeader,
  BenchmarkingReportLayout,
  BenchmarkingSectionProps,
} from '..';
import cn from 'classnames';
import { BENCHMARK_DRAG_TYPE } from '../BenchmarkingHeader';

export const BenchmarkingFixedSection = ({
  layout,
  projects,
  removeProject,
  setIsAddWizardOpen,
  addProjectsEnabled,
  columns,
  parameters,
  removeParameter,
  dropItem
}: BenchmarkingSectionProps) => {
  const { t } = useTranslation();
  const contextMenuItems: ContextMenuItem[] = useMemo(() => {
    return [
      {
        label:
          layout === BenchmarkingReportLayout.BENCHMARK_COLUMNS
            ? t('reporting.benchmarking.projects.menu.add.title')
            : t('reporting.benchmarking.addColumn.message'),
        subtitle:
          layout === BenchmarkingReportLayout.BENCHMARK_COLUMNS
            ? t('reporting.benchmarking.projects.menu.add.subtitle')
            : t('reporting.benchmarking.addColumn.message'),
        icon: <AddIcon />,
        onClick: setIsAddWizardOpen,
        isDisabled: layout === BenchmarkingReportLayout.PROJECT_COLUMNS && !projects.length
      },
    ];
  }, [t, setIsAddWizardOpen, layout, projects.length]);

  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const hoverItem = (index: number | null) => {
    setHoveredIndex(index);
  }

  return (
    <div className="w-full max-w-[365px] flex-none flex flex-col pr-2">
      <div className="h-10 flex-none font-bold text-lg flex items-center leading-none gap-2">
        <ContextMenu items={contextMenuItems} className="flex-none text-gray-600" />
        {layout === BenchmarkingReportLayout.BENCHMARK_COLUMNS
          ? t('reporting.benchmarking.projects')
          : t('reporting.benchmarking.benchmarks')}
        {layout === BenchmarkingReportLayout.BENCHMARK_COLUMNS && (
          <Badge
            text={projects?.length.toString() ?? '0'}
            variant="custom"
            className="flex-none bg-slate-300 text-white"
          />
        )}
      </div>
      <div className={
        cn(
          'shadow bg-white px-2.5 flex-none',
          {
            'pt-2.5': !projects.length || !parameters.length,
            'pb-2.5': !projects.length || !parameters.length,
            'flex-grow': parameters.length
          }
        )
      }>
        {layout === BenchmarkingReportLayout.BENCHMARK_COLUMNS ? (
          <>
            {projects && projects.length > 0 ? (
              <div className="flex flex-col">
                {projects.map((project, i) => (
                  <BenchmarkingProjectHeader
                    key={`benchmark-project-${project.id}-${i}`}
                    project={project as SelectProjectReadModel}
                    handleDelete={() => removeProject(project.id)}
                    // at least one project needs to be selected for the report
                    isRemoveDisabled={projects.length === 1 && columns.length > 0}
                    index={i}
                    type={BENCHMARK_DRAG_TYPE.ROW}
                    isLast={i === projects.length - 1}
                    dropItem={dropItem}
                    isDragDisabled={false}
                    hoverItem={hoverItem}
                    isHovered={hoveredIndex === i}
                  />
                ))}
              </div>
            ) : (
              <BenchmarkingEmptyRows
                isAddEnabled={addProjectsEnabled}
                setIsAddWizardOpen={setIsAddWizardOpen}
                title={t('reporting.benchmarking.noProjects')}
              />
            )}
          </>
        ) : (
          <>
            {parameters.length > 0 ? (
              parameters.map((parameter, i) => (
                <BenchmarkingParameterHeader
                  key={`benchmark-parameter-header-${parameter.id}`}
                  column={parameter}
                  handleDelete={() => removeParameter(parameter.id)}
                  isLast={i === columns.length - 1}
                  index={i}
                  type={BENCHMARK_DRAG_TYPE.ROW}
                  dropItem={dropItem}
                  isDragDisabled={false}
                  hoverItem={hoverItem}
                  isHovered={hoveredIndex === i}
                />
              ))
            ) : (
              <BenchmarkingEmptyRows
                isAddEnabled={projects?.length > 0}
                setIsAddWizardOpen={setIsAddWizardOpen}
                title={t('reporting.benchmarking.addColumn.message')}
              />
            )}
          </>
        )}
      </div>
      <div className="flex-none h-14 w-auto">
        {layout === BenchmarkingReportLayout.BENCHMARK_COLUMNS && columns.length > 0 && (
          <div className="flex h-full items-center border-b-4 border-double mx-2 border-transparent justify-end">
            <span className="font-bold truncate">{t('common.total')}</span>
          </div>
        )}
      </div>
    </div>
  );
};
