import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import {
  ApiDeleteCommitmentApiArg,
  ApiDeleteCommitmentApiResponse,
  ApiDeleteCommitmentDocumentApiArg,
  ApiDeleteCommitmentDocumentApiResponse,
  ApiGetCommitmentApiArg,
  ApiGetCommitmentApiResponse,
  ApiGetCommitmentDocumentApiArg,
  ApiGetCommitmentDocumentApiResponse,
  ApiGetCommitmentsApiArg,
  ApiGetCommitmentsApiResponse,
  ApiPostCreateCommitmentApiArg,
  ApiPostCreateCommitmentApiResponse,
  ApiPostCreateCommitmentDocumentsApiArg,
  ApiPostCreateCommitmentDocumentsApiResponse,
  ApiPostCreateCommitmentEmbedLinkDocumentsApiArg,
  ApiPostCreateCommitmentEmbedLinkDocumentsApiResponse,
  ApiPostUpdateCommitmentApiArg,
  ApiPostUpdateCommitmentApiResponse,
} from '../apiGenerated';
import { ApiTagTypes } from '../apiTagTypes';

export const enhancedApiCommitmentEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  apiGetCommitments: {
    providesTags: (_result: ApiGetCommitmentsApiResponse, _error: FetchBaseQueryError, args: ApiGetCommitmentsApiArg) => [
      { type: ApiTagTypes.Commitments, id: args.calculationModelId },
    ],
  },
  apiGetCommitment: {
    providesTags: (_result: ApiGetCommitmentApiResponse, _error: FetchBaseQueryError, args: ApiGetCommitmentApiArg) => [
      { type: ApiTagTypes.Contract, id: args.commitmentId },
      { type: ApiTagTypes.Contracts, id: args.calculationModelId},
    ],
  },
  apiGetCommitmentDocument: {
    providesTags: (_result: ApiGetCommitmentDocumentApiResponse, _error: FetchBaseQueryError, args: ApiGetCommitmentDocumentApiArg) => [
      { type: ApiTagTypes.Contract, id: args.commitmentId },
      { type: ApiTagTypes.Contracts, id: args.calculationModelId},
    ],
  },
  /*------------------ INVALIDATES TAGS --------------------*/
  apiPostCreateCommitment: {
    invalidatesTags: (
      _result: ApiPostCreateCommitmentApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateCommitmentApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.CostsCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.Contracts, id: args.calculationModelId },
            { type: ApiTagTypes.Commitments, id: args.calculationModelId },
          ]
        : [],
  },
  apiDeleteCommitment: {
    invalidatesTags: (
      _result: ApiDeleteCommitmentApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteCommitmentApiArg,
    ) =>
      error == null
        ? [
            {
              type: ApiTagTypes.CostsCalculationModel,
              id: args.calculationModelId,
            },
            { type: ApiTagTypes.Contracts, id: args.calculationModelId },
            { type: ApiTagTypes.Report, id: args.calculationModelId },
            { type: ApiTagTypes.Commitments, id: args.calculationModelId },
          ]
        : [],
  },
  apiPostUpdateCommitment: {
    invalidatesTags: (
      _result: ApiPostUpdateCommitmentApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateCommitmentApiArg,
    ) =>
      error == null
        ? [
            {
              type: ApiTagTypes.CostsCalculationModel,
              id: args.calculationModelId,
            },
            { type: ApiTagTypes.Contract, id: args.commitmentId },
            { type: ApiTagTypes.Contract, id: args.calculationModelId },
            { type: ApiTagTypes.Contracts, id: args.calculationModelId },
            { type: ApiTagTypes.Invoices, id: args.calculationModelId },
            { type: ApiTagTypes.Report, id: args.calculationModelId },
            { type: ApiTagTypes.Commitments, id: args.calculationModelId },
          ]
        : [],
  },
  apiPostCreateCommitmentDocuments: {
    invalidatesTags: (
      _result: ApiPostCreateCommitmentDocumentsApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateCommitmentDocumentsApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.CostsCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.Contracts, id: args.calculationModelId },
            { type: ApiTagTypes.Commitments, id: args.calculationModelId },
          ]
        : [],
  },
  apiPostCreateCommitmentEmbedLinkDocuments: {
    invalidatesTags: (
      _result: ApiPostCreateCommitmentEmbedLinkDocumentsApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateCommitmentEmbedLinkDocumentsApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.CostsCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.Contracts, id: args.calculationModelId },
            { type: ApiTagTypes.Commitments, id: args.calculationModelId },
          ]
        : [],
  },
  apiDeleteCommitmentDocument: {
    invalidatesTags: (
      _result: ApiDeleteCommitmentDocumentApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteCommitmentDocumentApiArg,
    ) =>
      error == null
        ? [
            {
              type: ApiTagTypes.CostsCalculationModel,
              id: args.calculationModelId,
            },
            { type: ApiTagTypes.Contracts, id: args.calculationModelId },
            { type: ApiTagTypes.Report, id: args.calculationModelId },
            { type: ApiTagTypes.Commitments, id: args.calculationModelId },
          ]
        : [],
  },
};
