import { apiGenerated } from './apiGenerated';
import { API_TAG_TYPES } from './apiTagTypes';
import { API_ADMIN_TAG_TYPES } from './apiAdminTagTypes';
import { enhancedApiAdminEndpoints } from './admin';
import {
  enhancedApiPortfolioEndpoints
} from './portfolio';
import {
  enhancedApiDashboardEndpoints,
  enhancedApiAiEvalEndpoints,
  enhancedApiAuditLogEndpoints,
  enhancedApiCalculateCostEndpoints,
  enhancedApiCalculateEarningEndpoints,
  enhancedApiCalculateEndpoints,
  enhancedApiCalculateFinanceEndpoints,
  enhancedApiCalculateRiskEndpoints,
  enhancedApiCommitmentEndpoints,
  enhancedApiContractEndpoints,
  enhancedApiContractTitleEndpoints,
  enhancedApiControlEndpoints,
  enhancedApiInvoiceCalculationEndpoints,
  enhancedApiLocalLawEndpoints,
  enhancedApiPlotEndpoints,
  enhancedApiProjectEndpoints,
  enhancedApiProjectGroupEndpoints,
  enhancedApiProjectInvoiceCalculationEndpoints,
  enhancedApiProjectsEndpoints,
  enhancedApiRentEndpoints,
  enhancedApiReportingEndpoints,
  enhancedApiSellEndpoints,
  enhancedApiTaxonomyEndpoints,
  enhancedApiVariantsEndpoints,
  enhancedApiWorkflowEndpoints
} from './project';
import {
  enhancedApiCatalogEndpoints,
  enhancedApiCompanyEndpoints,
  enhancedApiDefaultValuesEndpoints,
  enhancedApiEuriborRatesApiEndpoints,
  enhancedApiExternalApiEndpoints,
  enhancedApiInvoiceCalculationSchemeEndpoints,
  enhancedApiSettingsEndpoints,
  enhancedApiTenantTrustEndpoints,
  enhancedApiProjectTemplatesEndpoints,
  enhancedApiUDFEndpoints,
  enhancedApiUserEndpoints
} from './settings';
import {
  enhancedApiBenchmarkingEndpoints
} from './reports';

const enhancedEndpoints = {
  ...enhancedApiAuditLogEndpoints,
  ...enhancedApiAdminEndpoints,
  ...enhancedApiAiEvalEndpoints,
  ...enhancedApiBenchmarkingEndpoints,
  ...enhancedApiCalculateCostEndpoints,
  ...enhancedApiCalculateEarningEndpoints,
  ...enhancedApiCalculateEndpoints,
  ...enhancedApiCalculateFinanceEndpoints,
  ...enhancedApiCalculateRiskEndpoints,
  ...enhancedApiCommitmentEndpoints,
  ...enhancedApiContractEndpoints,
  ...enhancedApiContractTitleEndpoints,
  ...enhancedApiControlEndpoints,
  ...enhancedApiDashboardEndpoints,
  ...enhancedApiInvoiceCalculationEndpoints,
  ...enhancedApiLocalLawEndpoints,
  ...enhancedApiPlotEndpoints,
  ...enhancedApiPortfolioEndpoints,
  ...enhancedApiProjectEndpoints,
  ...enhancedApiProjectGroupEndpoints,
  ...enhancedApiProjectInvoiceCalculationEndpoints,
  ...enhancedApiProjectsEndpoints,
  ...enhancedApiProjectTemplatesEndpoints,
  ...enhancedApiRentEndpoints,
  ...enhancedApiReportingEndpoints,
  ...enhancedApiSellEndpoints,
  ...enhancedApiTaxonomyEndpoints,
  ...enhancedApiVariantsEndpoints,
  ...enhancedApiWorkflowEndpoints,
  ...enhancedApiCatalogEndpoints,
  ...enhancedApiCompanyEndpoints,
  ...enhancedApiDefaultValuesEndpoints,
  ...enhancedApiEuriborRatesApiEndpoints,
  ...enhancedApiExternalApiEndpoints,
  ...enhancedApiInvoiceCalculationSchemeEndpoints,
  ...enhancedApiSettingsEndpoints,
  ...enhancedApiTenantTrustEndpoints,
  ...enhancedApiUDFEndpoints,
  ...enhancedApiUserEndpoints
};

export const api = apiGenerated.enhanceEndpoints({
  addTagTypes: [...API_TAG_TYPES, ...API_ADMIN_TAG_TYPES],
  // @ts-expect-error found no fix for ts error so far
  endpoints: enhancedEndpoints,
});
