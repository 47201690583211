import { CustomListItemReadModel, DefaultValuesReadModel } from '@client/shared/api';
import { useState } from 'react';
import { EditButton, SlideOver } from '@client/shared/toolkit';
import { DefaultValuesCustomListEditSlideOver } from '.';
import { getCustomListItemLabel, getCustomListLabel } from '../../../utils';
import cn from 'classnames';

export const DefaultValuesCustomLists = ({
  defaultValues,
  isFetching,
}: {
  defaultValues?: DefaultValuesReadModel;
  isFetching?: boolean;
}) => {
  const [isCustomListSlideOverOpen, setIsCustomListSlideOverOpen] = useState<boolean>(false);

  const sortedCustomList = defaultValues?.customLists.map((customList) => ({
    ...customList,
    items: [...customList.items].sort((a, b) =>
      getCustomListItemLabel(customList.target, a).localeCompare(getCustomListItemLabel(customList.target, b)),
    ),
  }));

  return (
    <div className="pl-10">
      <div className="absolute top-4 right-10">
        <EditButton className="ml-auto" onClick={() => setIsCustomListSlideOverOpen(true)} />
      </div>
      <div className="flex gap-2 mt-5 w-full">
        {sortedCustomList?.map((customList, i) => (
          <div className="py-1 w-1/2 xl:w-1/6 flex-none" key={`custom-list-${customList.id}`}>
            <span className="font-bold">{getCustomListLabel(customList.target)}</span>
            {customList.items.map((item) => (
              <CustomListItem item={item} customListTarget={customList.target} key={`custom-list-item-${item.id}`} />
            ))}
          </div>
        ))}
      </div>
      <SlideOver
        isOpen={isCustomListSlideOverOpen}
        onClose={() => setIsCustomListSlideOverOpen(false)}
        variant="custom"
        className='w-[700px]'
      >
        <DefaultValuesCustomListEditSlideOver
          defaultValues={defaultValues}
          onClose={() => setIsCustomListSlideOverOpen(false)}
          isFetching={isFetching}
        />
      </SlideOver>
    </div>
  );
};

const CustomListItem = ({ item, customListTarget, level = 0 }: { item: CustomListItemReadModel; customListTarget: string; level?: number }) => {
  return (
    <div className={cn('flex flex-col', level > 0 ? 'ml-2' : '')}>
      <span className="py-1">{getCustomListItemLabel(customListTarget, item)}</span>
      {item.children.map((child) => (
        <CustomListItem item={child} customListTarget={customListTarget} key={`custom-list-item-${child.id}`} level={level + 1} />
      ))}
    </div>
  );
};
