import { formatNumber, formatPercentage, isCurrencySymbol } from '@client/shared/utilities';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CostElement } from '../hooks';
import { Square2StackIcon } from '@heroicons/react/24/outline';
import { ElementRowMenu } from './ElementRowMenu';
import {
  ContextMenuItem,
  TransactionIcon,
  MoveGrabberIcon,
  UncheckedCheckboxIcon,
  PencilIcon,
  TrashIcon,
  EyeIcon,
} from '@client/shared/toolkit';
import { useLoadedProjectId } from '@client/project/store';
import { useValidateProjectPermission } from '@client/shared/permissions';
import { TimeLineView } from './Timeline';
import { Column } from './CalculateContainer';
import { OptionalColumn } from './CalculateSectionHeader';
import { CalculateColumns } from './CalculateColumns';
import { CalculateInformationColumnContent } from './CalculateGroupElementInformationColumn';
import { CalculateElementRowInformationColumn } from './CalculateElementRowInformationColumn';
import { CostsFinanceDetailsPopover } from './Costs';
import { FormattedCurrency, getUnitLabel } from '@client/project/shared';
import { CalculateElementRowFinanceColumn } from './CalculateElementRowFinanceColumn';
import { CalculateSelectableColumn, getUnderBudget } from './CalculateSelectableColumn';

interface CostElementProps {
  item: CostElement;
  selectedVersionId: string;
  onClick: () => void;
  onCodeClick?: () => void;
  isSelected?: boolean;
  optionalColumn: OptionalColumn;
  obligoColumn: OptionalColumn;
  onRemove?: () => void;
  column?: Column;
  view: TimeLineView;
  onTakeOver?: () => void;
  onMove?: () => void;
  onDistribute?: () => void;
  isReadOnly: boolean;
  searchValue?: string;
  searchResults: string[] | null;
  onCopy?: () => void;
}

export const CostElementRow = ({
  item,
  onClick,
  onRemove,
  optionalColumn,
  obligoColumn,
  view,
  onTakeOver,
  onMove,
  onDistribute,
  isReadOnly,
  searchValue,
  searchResults,
  onCopy,
}: CostElementProps) => {
  const { t } = useTranslation();
  const {
    costElement,
    showError,
    catalogElement: { level },
  } = item;

  const loadedProjectId = useLoadedProjectId();
  const canDeleteCosts = useValidateProjectPermission(['COSTS_DELETE'], loadedProjectId ?? '');

  const handleOnClick = (evt: React.MouseEvent<HTMLDivElement>) => {
    evt.stopPropagation();
    onClick?.();
  };

  const groupMenuItemsTimelineView = useMemo<ContextMenuItem[]>(() => {
    const items: ContextMenuItem[] = [];

    if (costElement?.readOnly) {
      items.push({
        label: t('projectCalculate.rowMenu.viewCostElementTitle'),
        subtitle: t('projectCalculate.rowMenu.viewCostElementSubTitle'),
        onClick: onClick,
        icon: <EyeIcon />,
      });
    } else {
      items.push({
        label: t('projectCalculate.rowMenu.updateCostElementTitle'),
        subtitle: t('projectCalculate.rowMenu.updateCostElementSubTitle'),
        onClick: onClick,
        icon: <PencilIcon />,
      });
      if (onCopy) {
        items.push({
          label: t('projectCalculate.rowMenu.copyCostElementTitle'),
          subtitle: t('projectCalculate.rowMenu.copyCostElementSubTitle'),
          onClick: onCopy,
          icon: <Square2StackIcon />,
        });
      }
      if (onRemove) {
        items.push({
          isDisabled: !canDeleteCosts,
          label: t('projectCalculate.rowMenu.removeCostElementTitle'),
          subtitle: t('projectCalculate.rowMenu.removeCostElementSubTitle'),
          onClick: onRemove,
          icon: <TrashIcon />,
        });
      }
    }

    return items;
  }, [costElement?.readOnly, t, onClick, onRemove, canDeleteCosts, onCopy]);

  const groupMenuItemsFinanceView = useMemo<ContextMenuItem[]>(() => {
    const items: ContextMenuItem[] = [];

    if (!costElement?.readOnly) {
      if (onMove) {
        items.push({
          label: t('projectCalculate.rowMenu.moveElementTitle'),
          subtitle: t('projectCalculate.rowMenu.moveElementSubTitle'),
          onClick: onMove,
          icon: <MoveGrabberIcon />,
        });
      }
      if (onRemove) {
        items.push({
          isDisabled: !canDeleteCosts,
          label: t('projectCalculate.rowMenu.removeCostElementTitle'),
          subtitle: t('projectCalculate.rowMenu.removeCostElementSubTitle'),
          onClick: onRemove,
          icon: <TrashIcon />,
        });
      }
      if (onTakeOver) {
        items.push({
          label: t('projectCalculate.rowMenu.takeoverForecastTitle'),
          subtitle: t('projectCalculate.rowMenu.takeoverForecastSubTitle'),
          onClick: onTakeOver,
          icon: <UncheckedCheckboxIcon />,
        });
      }
      if (onDistribute) {
        items.push({
          label: t('projectCalculate.rowMenu.distributeRestObligoTitle'),
          subtitle: t('projectCalculate.rowMenu.takeoverForecastSubTitle'),
          onClick: onDistribute,
          icon: <TransactionIcon />,
        });
      }
    }
    return items;
  }, [costElement?.readOnly, onMove, t, onRemove, canDeleteCosts, onTakeOver, onDistribute]);

  const detailValues = useMemo(() => {
    return {
      // Hauptauftrag
      mainContract: costElement?.contractValue,
      // Nachtrag
      totalSupplement: costElement?.supplementValue,
      // Gesamtauftrag
      totalContract:
        costElement?.contractValue && costElement.supplementValue
          ? costElement?.contractValue + costElement.supplementValue
          : costElement?.contractValue,
      // Externe Prognose
      effectiveForecast: costElement?.totalForecastValue,
      // Aktuelles Budget
      totalBudget: costElement?.totalValue,
    };
  }, [costElement]);

  const underBudgetInPercent = useMemo(() => {
    return getUnderBudget(costElement?.totalForecastValue, costElement?.totalValue);
  }, [costElement?.totalForecastValue, costElement?.totalValue]);

  const showValues = searchResults ? searchResults.includes(costElement?.elementId ?? '') : true;

  return (
    <CalculateColumns
      view={view}
      informationColumnContent={
        <CalculateElementRowInformationColumn
          level={level + 1}
          view={view}
          handleOnClick={handleOnClick}
          contentType="row"
          leftColumn={
            <CalculateInformationColumnContent
              showCode={!!costElement?.code}
              code={costElement?.code}
              level={level + 1}
              showError={showError}
              title={costElement?.description ? costElement?.description : t('projectCalculate.unnamedElement')}
              contentType="row"
              showExpanded
              searchValue={searchValue}
            >
              {!!costElement?.vat && <span> ({costElement?.vat}%)</span>}
              {costElement?.hasFormula && <sup className="font-normal italic text-gray-400">&nbsp;fx</sup>}
            </CalculateInformationColumnContent>
          }
          rightColumn={
            showValues && (
              <>
                <div className="pl-2 hidden md:flex w-2/12 text-sm text-right flex-row justify-end items-center leading-tight">
                  <div>
                    {isCurrencySymbol(costElement?.unit) ? (
                      <FormattedCurrency amount={costElement?.amount} />
                    ) : (
                      formatNumber(costElement?.amount)
                    )}
                    {costElement?.unit && !isCurrencySymbol(costElement?.unit) && (
                      <div className="text-xs font-light">{getUnitLabel(costElement?.unit)}</div>
                    )}
                  </div>
                </div>

                <div className="hidden w-4/12 md:flex xl:w-5/12 text-sm text-right flex-row justify-end items-center leading-tight">
                  <div>
                    <FormattedCurrency amount={costElement?.unitPrice} />
                    {costElement?.unit && costElement.unit !== '%' && (
                      <div className="text-xs font-light">
                        {t('projectCalculate.earningsElementLabelPer')} {getUnitLabel(costElement?.unit)}
                      </div>
                    )}
                  </div>
                </div>

                <div className="w-5/12 text-right flex flex-row justify-end items-center leading-tight">
                  <div className="text-sm font-bold">
                    <FormattedCurrency amount={costElement?.totalValue} />
                  </div>
                </div>
                {/*
                <ElementRowMenu items={elementMenuItems} />
              */}
              </>
            )
          }
          menu={
            view === TimeLineView.FINANCE ? (
              <ElementRowMenu
                items={groupMenuItemsFinanceView}
                details={<CostsFinanceDetailsPopover detailValues={detailValues} />}
              />
            ) : (
              !isReadOnly && <ElementRowMenu items={groupMenuItemsTimelineView} />
            )
          }
        />
      }
      currentColumnContent={
        <CalculateElementRowFinanceColumn
          level={level + 1}
          handleOnClick={handleOnClick}
          columnType={Column.CURRENT}
          view={view}
        >
          {!!costElement?.totalValue && (
            <>
              {underBudgetInPercent ? (
                <div className="flex gap-1 items-center">
                  <span className="text-[9px] text-red-800">
                    {formatPercentage(underBudgetInPercent, { maxDigits: 0 })}
                  </span>
                  <FormattedCurrency amount={costElement.totalValue} />
                </div>
              ) : (
                <FormattedCurrency amount={costElement.totalValue} />
              )}
            </>
          )}
        </CalculateElementRowFinanceColumn>
      }
      obligoColumnContent={
        <CalculateSelectableColumn
          column={obligoColumn}
          level={level + 1}
          handleOnClick={handleOnClick}
          columnType={Column.OBLIGO}
          view={view}
          values={costElement}
          type="costElement"
        />
      }
      optionalColumnContent={
        <CalculateSelectableColumn
          column={optionalColumn}
          level={level + 1}
          handleOnClick={handleOnClick}
          columnType={Column.OPTIONAL_COLUMN}
          view={view}
          values={costElement}
          type="costElement"
        />
      }
    />
  );
};
