import { BenchmarkReportCellReadModel } from '@client/shared/api';
import { formatCurrency } from '@client/shared/utilities';

interface BenchmarkingRowProps {
  cells: BenchmarkReportCellReadModel[];
}

export const BenchmarkingRow = (props: BenchmarkingRowProps) => {
  const { cells } = props;
  return (
    <div className="flex w-full h-[58px] items-center border-b border-slate-300">
      {cells.map((cell, i) => (
        <BenchmarkingCell cell={cell} key={`benchmarking-cell-${cell.rowId}-${cell.columnId}`} />
      ))}
    </div>
  );
};

interface BenchmarkingCellProps {
  cell: BenchmarkReportCellReadModel;
}

export const BenchmarkingCell = (props: BenchmarkingCellProps) => {
  const { cell } = props;
  return (
    <div className="w-[200px] px-5 text-right text-[13px] font-bold h-full flex items-center justify-end border-r border-slate-300 border-dotted flex-none">
      {formatCurrency(cell.value, { currency: cell.currency })}
    </div>
  );
};
