import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { ApiTagTypes } from '../apiTagTypes';
import {
  ApiDeleteTemplateScenarioApiArg,
  ApiDeleteTemplateScenarioApiResponse,
  ApiGetTemplateScenariosApiArg,
  ApiPostTemplateScenarioApiArg,
  ApiPutTemplateScenarioApiArg,
  ApiPutTemplateScenarioApiResponse,
  CreateTemplateScenarioResponse,
  GetTemplateScenariosResponse,
} from '../apiGenerated';

export const enhancedApiProjectTemplatesEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  apiGetProjectTemplates: {
    providesTags: [ApiTagTypes.ProjectTemplates],
  },
  apiGetTemplateScenarios: {
    providesTags: (
      _result: GetTemplateScenariosResponse,
      _error: FetchBaseQueryError,
      _args: ApiGetTemplateScenariosApiArg,
    ) => [{ type: ApiTagTypes.ProjectTemplateScenarios, id: _args.templateId }],
  },
  /*------------------ INVALIDATES TAGS --------------------*/
  apiPostProjectTemplate: {
    invalidatesTags: (error: FetchBaseQueryError) => (error == null ? [ApiTagTypes.ProjectTemplates] : []),
  },
  apiPutProjectTemplate: {
    invalidatesTags: (error: FetchBaseQueryError) => (error == null ? [ApiTagTypes.ProjectTemplates] : []),
  },
  apiDeleteProjectTemplate: {
    invalidatesTags: (error: FetchBaseQueryError) => (error == null ? [ApiTagTypes.ProjectTemplates] : []),
  },

  apiPostTemplateScenario: {
    invalidatesTags: (
      _result: CreateTemplateScenarioResponse,
      _error: FetchBaseQueryError,
      _args: ApiPostTemplateScenarioApiArg,
    ) => (_error == null ? [{ type: ApiTagTypes.ProjectTemplateScenarios, id: _args.templateId }] : []),
  },
  apiPutTemplateScenario: {
    invalidatesTags: (
      _result: ApiPutTemplateScenarioApiResponse,
      _error: FetchBaseQueryError,
      _args: ApiPutTemplateScenarioApiArg,
    ) => (_error == null ? [{ type: ApiTagTypes.ProjectTemplateScenarios, id: _args.templateId }] : []),
  },
  apiDeleteTemplateScenario: {
    invalidatesTags: (
      _result: ApiDeleteTemplateScenarioApiResponse,
      _error: FetchBaseQueryError,
      _args: ApiDeleteTemplateScenarioApiArg,
    ) => (_error == null ? [{ type: ApiTagTypes.ProjectTemplateScenarios, id: _args.templateId }] : []),
  },
};
