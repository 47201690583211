import { ContractReadModel, ContractTitlePositionReadModel, ContractTitleReadModel } from '@client/shared/api';
import { Highlighted, SlideOver } from '@client/shared/toolkit';
import { FormattedCurrency } from '../FormattedCurrency';
import { useTranslation } from 'react-i18next';
import { useRef, useState, useEffect } from 'react';
import { ContractEditPositionSlideOver } from '../ContractTitle/ContractEditPositionSlideOver';

const POSITION_ROW_HEIGHT = 56;

export const ContractTitlePositionsList = ({
  contract,
  contractTitle,
  positions,
  searchValue = '',
}: {
  contract: ContractReadModel;
  contractTitle: ContractTitleReadModel;
  positions: ContractTitlePositionReadModel[];
  searchValue?: string;
}) => {
  const { t } = useTranslation();

  const containerRef = useRef<HTMLDivElement>(null);
  const [visibleRows, setVisibleRows] = useState<string[]>([]);

  const [selectedPosition, setSelectedPosition] = useState<ContractTitlePositionReadModel | undefined>();
  const [showEdit, setShowEdit] = useState<boolean>(false);

  useEffect(() => {
    const parentContainer = containerRef.current?.closest('.contract-slide-over');

    const updateVisibility = () => {
      if (containerRef.current && parentContainer) {
        const rect = containerRef.current.getBoundingClientRect();
        const newVisibleRows: string[] = [];
        positions.forEach((pos, i) => {
          const topStyle = i * POSITION_ROW_HEIGHT;
          const rowTop = rect.top + topStyle;
          const rowBottom = rowTop + POSITION_ROW_HEIGHT;
          if (
            (rowTop >= 0 && rowTop < window.innerHeight) ||
            (rowBottom > 0 && rowBottom <= window.innerHeight) ||
            (rowTop <= 0 && rowBottom >= window.innerHeight)
          ) {
            newVisibleRows.push(pos.id);
          }
        });
        setVisibleRows(newVisibleRows);
      }
    };

    const observer = new MutationObserver(() => {
      updateVisibility();
    });
    if (parentContainer) {
      observer.observe(parentContainer, { childList: true, subtree: true });
      parentContainer.addEventListener('scroll', updateVisibility);
    }
    updateVisibility();
    return () => {
      if (parentContainer) {
        parentContainer.removeEventListener('scroll', updateVisibility);
      }
      observer.disconnect();
    };
  }, [positions, containerRef]);

  const handlePositionClick = (position: ContractTitlePositionReadModel) => {
    setSelectedPosition(position);
    setShowEdit(true);
  };

  const handleSlideOverClose = () => {
    setSelectedPosition(undefined);
    setShowEdit(false);
  };

  return (
    <div className="pb-3 relative" style={{ height: positions.length * 56 }} ref={containerRef}>
      {positions.map((position, i) => {
        if (!visibleRows.includes(position.id)) return null;
        return (
          <div
            className="absolute w-full flex flex-col items-center px-6 py-3 gap-3 truncate hover:bg-slate-50 transition-colors duration-300 lg:flex-row lg:gap-0"
            key={`contract-title-position-list-item-${position.id}`}
            style={{ top: i * 56, height: POSITION_ROW_HEIGHT }}
            data-position-id={position.id}
            onClick={() => handlePositionClick(position)}
          >
            <div className="w-full flex gap-3 whitespace-nowrap lg:w-1/3 lg:flex-none lg:pr-3 lg:gap-6 h-full">
              <div className="hidden w-8 justify-center items-center flex-none lg:flex" />
              <div className="w-full pr-10 overflow-hidden lg:pr-0 flex gap-3 leading-tight text-[13px] font-bold text-slate-600 whitespace-break-spaces h-full">
                <div className="font-light line-clamp-2 flex-none" title={position.code}>
                  <Highlighted text={position.code} highlight={searchValue} className="text-ellipsis" />
                </div>
                <div className="line-clamp-2" title={position.name}>
                  <Highlighted text={position.name} highlight={searchValue} className="text-ellipsis" />
                </div>
              </div>
            </div>
            <div
              className="w-full grid gap-3 whitespace-nowrap lg:flex-1 lg:justify-between lg:pl-3 grid-cols-2 lg:grid-cols-4 text-[13px] leading-tight text-slate-600"
              key={`contract-title-position-${position.id}`}
            >
              <div className="hidden lg:block" />
              <div className="flex flex-col gap-0.5 justify-center leading-tight text-[13px] text-gray-400 whitespace-break-spaces lg:hidden">
                {t('projectContract.contractTitle.position.unitPrice')}
              </div>
              <div className="flex flex-col justify-center gap-0.5 leading-none text-[15px] lg:text-[13px] text-right">
                <span className="truncate">
                  <FormattedCurrency amount={position.unitPrice} options={{ maxDigits: 2, minDigits: 2 }} />
                </span>
                <span className="text-[10px] text-gray-400 font-light truncate">
                  {' '}
                  {t('projectContract.contractTitle.position.unitPrice')}
                </span>
              </div>
              <div className="flex flex-col gap-0.5 justify-center leading-tight text-[13px] text-gray-400 whitespace-break-spaces lg:hidden">
                {t('projectContract.contractTitle.position.amount')}
              </div>
              <div className="flex flex-col justify-center gap-0.5 overflow-hidden leading-none text-[15px] lg:text-[13px] text-right">
                <span className="truncate">
                  {position.amount} {position.unit}
                </span>
                <span className="text-[10px] text-gray-400 font-light truncate">
                  {t('projectContract.contractTitle.position.amount')}
                </span>
              </div>
              <div className="flex flex-col gap-0.5 justify-center leading-tight text-[13px] text-gray-400 whitespace-break-spaces lg:hidden">
                {t('projectContract.contractTitle.position.total')}
              </div>
              <div className="flex flex-col justify-center gap-0.5 overflow-hidden leading-none text-[15px] lg:text-[13px] text-right">
                <span className="truncate font-bold">
                  <FormattedCurrency amount={position.totalValue} options={{ maxDigits: 2, minDigits: 2 }} />
                </span>
                <span className="text-[10px] text-gray-400 truncate">
                  {t('projectContract.contractTitle.position.total')}
                </span>
              </div>
            </div>
            <div className="rotate-90 transform absolute -right-1 top-2 w-10 flex-none whitespace-nowrap flex items-center justify-end lg:rotate-0 lg:transform-none lg:right-auto lg:top-auto lg:relative" />
          </div>
        );
      })}
      {showEdit && (
          <SlideOver
          isOpen={showEdit}
          onClose={() => handleSlideOverClose()}
        >
          <ContractEditPositionSlideOver
            contract={contract}
            contractTitle={contractTitle}
            position={selectedPosition}
            onClose={() => handleSlideOverClose()}
          />
        </SlideOver>
      )}
    </div>
  );
};
