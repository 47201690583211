import React, { useState, useRef } from 'react';
import { useApiPostTemplateScenarioMutation } from '@client/shared/api';
import {
  Wizard,
  Button,
  Form,
  FormField,
  TextInput,
  Modal,
  FormRefHandle,
  CheckmarkDottedIcon,
  TagWindowIcon,
  WizardSlideHeader,
  WizardSlideContent,
  LoadingIndicator
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  CalculationModelNewFormValidationSchema,
  CalculationModelNewFormValidationValues,
} from './CalculationModelNewFormValidationSchema';
import * as yup from 'yup';
import { useLoadedProjectId, setVariantId } from '@client/project/store';
import { safeMutation } from '@client/shared/utilities';
import { useGenericDispatch } from '@client/shared/store';
import { InferType } from 'yup';

const ScenarioNewFormValidationSchema = yup.object({
  name: yup.string().required('validation.required'),
  description: yup.string().optional(),
});

type ScenarioNewFormValidationValues = InferType<typeof ScenarioNewFormValidationSchema>;

interface ScenarioNewWizardProps {
  onClose: () => void;
}

export const ScenarioNewWizard = ({ onClose }: ScenarioNewWizardProps) => {
  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();

  const formRef = useRef<FormRefHandle<ScenarioNewFormValidationValues>>();
  const [currentStep, setCurrentStep] = useState(0);

  const dispatch = useGenericDispatch();
  const [postNewScenario, { isLoading }] = useApiPostTemplateScenarioMutation();

  const handleCloseModal = () => {
    onClose();
  };

  const handleOnSlideChange = async (index: number) => {
    setCurrentStep(index);
  };

  const handleOnCreateClick = async () => {
    if (formRef.current) {
      await formRef.current.submitForm();
    }
  };

  const handleOnSubmit = async (data: CalculationModelNewFormValidationValues) => {
    if (loadedProjectId == null) {
      return;
    }

    try {
      const result = await safeMutation(
        postNewScenario,
        {
          templateId: loadedProjectId,
          body: {
            name: data.name,
            //description: data.description,
          },
        },
        isLoading
      );

      if (result) {
        dispatch(setVariantId(result.scenarioId));
      }

      setCurrentStep(currentStep + 1);
    } catch (e) {
      console.log(e);
    }
  };

  const defaultFormValues = {
    name: '',
    description: '',
  };

  return (
    <Form<CalculationModelNewFormValidationValues>
      validationSchema={CalculationModelNewFormValidationSchema}
      defaultValues={defaultFormValues}
      onSubmit={handleOnSubmit}
      ref={formRef}
      className="h-full"
    >
      {isLoading && <LoadingIndicator text={t('common.loading')} mode="overlay-window" />}
      <Wizard
        currentStep={currentStep}
        onSlideChange={(step) => handleOnSlideChange(step)}
        className="w-[740px] h-[560px]"
      >
        <Wizard.Slides>
          <Wizard.Slide>
            <WizardSlideHeader
              title={t('projectVariants.actionCreateCopy')}
            />
            <WizardSlideContent>
              <FormField name="name">
                {(control) => (
                  <TextInput
                    label={t('projectVariants.wizardNewVersionLabelName')}
                    icon={<TagWindowIcon />}
                    {...control}
                  />
                )}
              </FormField>
              <FormField name="description">
                {(control) => (
                  <TextInput
                    label={t('common.description')}
                    icon={<TagWindowIcon />}
                    {...control}
                  />
                )}
              </FormField>
            </WizardSlideContent>
          </Wizard.Slide>
          <Wizard.Slide>
            <WizardSlideHeader
              icon={<CheckmarkDottedIcon />}
              title={t('projectVariants.wizardNewVersionStep3Title')}
              subTitle={t('projectVariants.wizardNewVersionStep3Description')}
              fullHeight
            />
          </Wizard.Slide>
        </Wizard.Slides>
        <Wizard.Navigation>
          {({ count, isLast, canGoNext, next }) => (
            <Modal.Controls
              className={classNames({
                'bg-slate-100': currentStep !== 1,
                'bg-white': currentStep === 1,
              })}
            >
              <Button variant="text" onClick={handleCloseModal}>
                {t('common.cancel')}
              </Button>
              {(() => {
                if (isLast) {
                  return (
                    <Button variant="primary" onClick={handleCloseModal}>
                      {t('common.continue')}
                    </Button>
                  );
                } else if (currentStep === count - 2) {
                  return (
                    <Button variant="primary" onClick={handleOnCreateClick}>
                      {t('common.create')}
                    </Button>
                  );
                } else {
                  return (
                    <Button variant="primary" disabled={!canGoNext} onClick={next}>
                      {t('common.next')}
                    </Button>
                  );
                }
              })()}
            </Modal.Controls>
          )}
        </Wizard.Navigation>
      </Wizard>
    </Form>
  );
};
