import { CalculationModelRiskElementReadModel, CalculationModelRiskGroupReadModel } from '@client/shared/api';

export const findRisksElement = (
  elementId: string,
  flattenedElements: (CalculationModelRiskGroupReadModel | CalculationModelRiskElementReadModel)[],
): CalculationModelRiskGroupReadModel | CalculationModelRiskElementReadModel | undefined => {
  const foundElement = flattenedElements.find((x) => x.riskElementId === elementId || x.groupId === elementId);
  if (foundElement) return foundElement;
  else {
    let foundElement: CalculationModelRiskGroupReadModel | CalculationModelRiskElementReadModel | undefined = undefined;
    flattenedElements.forEach((element) => {
      if (!foundElement && 'children' in element && element.children.length) {
        element.children.forEach((risksElement) => {
          if (risksElement.riskElement && risksElement.riskElement.groupId === elementId) {
            foundElement = risksElement.riskElement;
          }
        });
      }
    });
    return foundElement;
  }
};
