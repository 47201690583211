import { useTranslation } from 'react-i18next';
import { WidgetContainer, WidgetContainerContent, WidgetContainerTitle } from '@client/shared/toolkit';
import { DashboardWidgetProps } from '../WidgetDashboard';
import { DashboardWidgetType } from '../utils';
import { useMemo } from 'react';

export const DashboardWidgetTextBox = (props: DashboardWidgetProps) => {
  const { widget } = props;
  const { t } = useTranslation();

  const textBoxText = useMemo(() => {
    const text= widget?.additionalConfig?.[DashboardWidgetType.TextBox]?.text ?? '';
    return text ? text.replace(/\n/g, '<br />') : '';
  }, [widget?.additionalConfig]);

  return (
    <WidgetContainer>
      <WidgetContainerTitle>{widget.title ? widget.title : t('dashboard.widget.textBox.title')}</WidgetContainerTitle>
      <WidgetContainerContent className="flex-1 overflow-y-auto">
        <div
          className="text-xs text-gray-500"
          dangerouslySetInnerHTML={{ __html: textBoxText }}
        />
      </WidgetContainerContent>
    </WidgetContainer>
  );
};
