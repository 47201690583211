import { getBenchmarkPropertyCode, getBenchmarkPropertyDescription } from '../../catalogs';
import { Badge } from '@client/shared/toolkit';
import React, { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomBenchmarkPropertyReadModel, DefaultValuesReadModel } from '@client/shared/api';
import cn from 'classnames';

export const ReferenceUnitsList = ({
  defaultValues,
  handleClick,
  hasPadding = true
}: {
  defaultValues?: DefaultValuesReadModel;
  handleClick?: (refUnit: CustomBenchmarkPropertyReadModel) => void;
  hasPadding?: boolean;
}) => {
  const { t } = useTranslation();

  const defaultBenchmarkProperties = useMemo(() => {
    if (defaultValues?.defaultBenchmarkProperties?.length) {
      const sortedValues = [...defaultValues.defaultBenchmarkProperties];
      return sortedValues.sort((a, b) => {
        const aCode = getBenchmarkPropertyCode(a);
        const bCode = getBenchmarkPropertyCode(b);
        return aCode.localeCompare(bCode);
      });
    }
    return [];
  }, [defaultValues?.defaultBenchmarkProperties]);

  const customBenchmarkProperties = useMemo(() => {
    if (defaultValues?.customBenchmarkProperties?.length) {
      const sortedValues = [...defaultValues.customBenchmarkProperties];
      return sortedValues.sort((a, b) => {
        const aCode = getBenchmarkPropertyCode(a);
        const bCode = getBenchmarkPropertyCode(b);
        return aCode.localeCompare(bCode);
      });
    }
    return [];
  }, [defaultValues?.customBenchmarkProperties]);
  return (
    <>
      <div className="flex w-full text-xs text-gray-500 mb-2 gap-0.5">
        <ReferenceUnitRow
          code={t('projectSetting.referenceUnits.code')}
          name={t('projectSetting.referenceUnits.name')}
          unitMetric={t('projectSetting.referenceUnits.unitMetric')}
          unitImperial={t('projectSetting.referenceUnits.unitImperial')}
          type={t('projectSettings.referenceUnits.type')}
          header
          hasPadding={false}
        />
      </div>
      <ul className="flex flex-col gap-0.5">
        {defaultBenchmarkProperties.map((refUnit) => (
          <li className="flex gap-0.5 w-full font-bold" key={`default-reference-unit-${refUnit.id}`}>
            <ReferenceUnitRow
              code={getBenchmarkPropertyCode(refUnit)}
              name={getBenchmarkPropertyDescription(refUnit)}
              unitMetric={refUnit.metricUnit}
              unitImperial={refUnit.imperialUnit}
              type={
                <Badge
                  text={t('projectSetting.referenceUnits.default')}
                  variant="custom"
                  className="bg-slate-300 text-white truncate"
                />
              }
              hasPadding={hasPadding}
            />
          </li>
        ))}
        {customBenchmarkProperties.map((refUnit) => (
          <li
            key={`custom-reference-unit-${refUnit.id}`}
            className={cn('flex gap-0.5 w-full font-bold', handleClick ? 'cursor-pointer' : '')}
            onClick={handleClick ? () => handleClick(refUnit) : undefined}
          >
            <ReferenceUnitRow
              code={getBenchmarkPropertyCode(refUnit)}
              name={getBenchmarkPropertyDescription(refUnit)}
              unitMetric={refUnit.metricUnit}
              unitImperial={refUnit.imperialUnit}
              type={<Badge text={t('projectSetting.referenceUnits.custom')} className="truncate" />}
              hasPadding={hasPadding}
            />
          </li>
        ))}
      </ul>
    </>
  );
};

interface DefaultValuesReferenceUnitRowProps {
  code: string;
  name: string;
  unitMetric: string;
  unitImperial: string;
  type: string | ReactNode;
  hasPadding?: boolean;
  header?: boolean;
}

export const ReferenceUnitRow = (props: DefaultValuesReferenceUnitRowProps) => {
  const classNames = !props.header && props.hasPadding ? 'bg-white p-4' : '';

  return (
    <>
      <div className={cn('w-1/6 flex-none', classNames)}>{props.code}</div>
      <div className={cn('flex-1', classNames)}>{props.name}</div>
      <div className={cn('w-1/6 flex-none', classNames)}>{props.unitMetric}</div>
      <div className={cn('w-1/6 flex-none', classNames)}>{props.unitImperial}</div>
      <div className={cn('w-1/6 flex-none', classNames)}>{props.type}</div>
    </>
  );
};
