import { useApiPostUpdateUserLanguageMutation } from '@client/shared/api';
import { setLanguage, useGenericDispatch, useUi } from '@client/shared/store';
import { BaseSelect, BaseSelectOption, LoadingIndicator } from '@client/shared/toolkit';
import { getLanguageAndLocale, safeMutation, setLanguageAndLocale, supportedLanguages } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';

export const LanguageSelector = () => {
  const { t } = useTranslation();

  const langOptions = supportedLanguages.map((x) => {
    return { label: t(`languages.${x === 'en' ? 'en-us' : x}`), value: x } as BaseSelectOption;
  });

  const [postLanguage, { isLoading }] = useApiPostUpdateUserLanguageMutation();
  const dispatch = useGenericDispatch();
  const ui = useUi();
  const currentLang = getLanguageAndLocale().language;

  const onLangChange = async (language: string) => {
    try {
      await safeMutation(
        postLanguage,
        {
          body: { language: language },
        },
        isLoading
      );

      dispatch(setLanguage(language));
      await setLanguageAndLocale(language, undefined, ui.appUser?.userId);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="text-sm w-6/12 border rounded">
      {isLoading && <LoadingIndicator text={t('common.updating')} />}
      <BaseSelect
        label={t('common.language')}
        value={currentLang}
        options={langOptions}
        onChange={(selected) => onLangChange(selected)}
      />
    </div>
  );
};
