import {
  BaseSelectOption,
  Button,
  MultiSelect,
  Form,
  FormField,
  LoadingIndicator,
  SlideOver,
  SlideOverOnCloseProps,
  SlideOverTitle,
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { WorkflowTaskEditValidationSchema, WorkflowTaskEditValidationValues } from './WorkflowTaskEditValidationSchema';
import React, { useEffect, useMemo, useState } from 'react';
import { UserGroupIcon } from '@heroicons/react/24/outline';
import {
  useApiGetAuthGroupsQuery,
  AssignableActivityReadModel,
  useApiPostUpdateAssignableWorkflowActivitiesMutation,
  useApiPostAssignableWorkflowActivitiesMutation,
  WorkflowAssignableActivityReadModel,
  WorkflowAssignableActivityConfigurationOptions,
  UpdateWorkflowAssignableActivityConfigurationPayload,
  WorkflowTaskConfigurationReadModel,
  EmailAttachmentOption,
  WorkflowAssignableActivityConfigurationType,
} from '@client/shared/api';
import { safeMutation, translateTaskName } from '@client/shared/utilities';

interface WorkflowEditVariablesSlideOverProps extends SlideOverOnCloseProps {
  isOpen: boolean;
  workflow: WorkflowAssignableActivityReadModel;
  task: AssignableActivityReadModel;
  projectId?: string;
  onAfterLeave: () => void;
  configurationOptions: WorkflowAssignableActivityConfigurationOptions[];
}

export const WorkflowEditVariablesSlideOver = (props: WorkflowEditVariablesSlideOverProps) => {
  const { isOpen, onClose, onAfterLeave, task, projectId, workflow, configurationOptions } = props;

  const { t } = useTranslation();

  const [postCreate, { isLoading: isCreating }] = useApiPostAssignableWorkflowActivitiesMutation();
  const [postUpdate, { isLoading: isUpdating }] = useApiPostUpdateAssignableWorkflowActivitiesMutation();

  const [configurations, setConfigurations] = useState<WorkflowTaskConfigurationReadModel[]>([]);

  const { data: groupData, isFetching } = useApiGetAuthGroupsQuery(undefined, {
    skip: !task,
  });

  useEffect(() => {
    if (task && task.optionalConfigurations) {
      setConfigurations(task.optionalConfigurations);
    }
  }, [task]);

  const filteredConfigurations = useMemo(() => {
    if (workflow.workflowType === 'Supplement' || workflow.workflowType === 'Contract') {
      return [];
    }
    return configurationOptions.filter(
      (config) => task.type === 'SendEmailAction' && config.type === 'EmailAttachment',
    );
  }, [configurationOptions, task.type, workflow.workflowType]);

  const groupOptions: BaseSelectOption[] = useMemo(() => {
    if (groupData && groupData.length) {
      return groupData.map((group) => {
        return {
          label: group.name,
          value: group.id,
        };
      });
    }

    return [];
  }, [groupData]);

  const configurationOptionLabelDict: Record<string, string> = {
    EmailAttachment: t('app.settings.workflowConfigurationOption.emailAttachment'),
  };

  const handleSubmit = async (data: WorkflowTaskEditValidationValues) => {
    if (!task.authorizedUsers && !task.authorizedGroups) {
      try {
        await safeMutation(
          postCreate,
          {
            projectId: projectId ?? 'unset',
            body: {
              workflowDefinitionId: workflow.definitionId,
              workflowName: workflow.name,
              workflowType: workflow.workflowType,
              activityId: task.activityId,
              activityName: task.name,
              activityType: task.type,
              authorizedUsers: [],
              authorizedGroups: data.teamId.filter((id) => typeof id === 'string') as string[],
              configurations: [
                {
                  configurationType: 'EmailAttachment' as WorkflowAssignableActivityConfigurationType,
                  values: configurations.filter((x) => x.type === 'EmailAttachment').map((c) => c.value),
                },
              ] as UpdateWorkflowAssignableActivityConfigurationPayload[],
            },
          },
          isCreating,
        );

        onClose(true);
      } catch (e) {
        console.log(e);
      }
    } else {
      try {
        await safeMutation(
          postUpdate,
          {
            assignableActivityId: task.activityId,
            projectId: projectId ?? 'unset',
            body: {
              workflowDefinitionId: workflow.definitionId,
              authorizedUsers: [],
              authorizedGroups: data.teamId.filter((id) => typeof id === 'string') as string[],
              configurations: [
                {
                  configurationType: 'EmailAttachment' as WorkflowAssignableActivityConfigurationType,
                  values: configurations.filter((x) => x.type === 'EmailAttachment').map((c) => c.value),
                },
              ] as UpdateWorkflowAssignableActivityConfigurationPayload[],
            },
          },
          isUpdating,
        );

        onClose(true);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const defaultFormValues = {
    teamId: task.authorizedGroups?.map((group) => group.id) ?? [],
  };

  return (
    <SlideOver isOpen={isOpen} onClose={onClose} onAfterLeave={onAfterLeave}>
      {(isCreating || isUpdating) && (
        <LoadingIndicator text={t('app.settings.workflows.loadingWorkflowLoadingIndicator')} mode="overlay-window" />
      )}
      <Form<WorkflowTaskEditValidationValues>
        validationSchema={WorkflowTaskEditValidationSchema}
        defaultValues={defaultFormValues}
        onSubmit={handleSubmit}
        className="w-full flex flex-col justify-between h-full"
      >
        <SlideOver.Header
          title={translateTaskName(task.name)}
          subTitle={t('app.settings.workflows.editTask')}
          backgroundClassName="bg-slate-900"
          onClose={onClose}
        />
        <SlideOver.Content className="p-8">
          {isFetching === true ? (
            <LoadingIndicator text={t('app.settings.workflows.loadingWorkflowLoadingIndicator')} />
          ) : (
            <>
              <SlideOverTitle title={t('app.settings.workflows.teams')} marginTop={false} />
              <FormField name="teamId">
                {(control) => (
                  <MultiSelect
                    icon={<UserGroupIcon />}
                    label={t('app.settings.workflows.whoLabel')}
                    options={groupOptions}
                    nullable
                    {...control}
                  />
                )}
              </FormField>

              {!!filteredConfigurations.length && (
                <>
                  <SlideOverTitle
                    title={t(
                      filteredConfigurations.length > 1
                        ? 'app.settings.workflowConfigurationOptions.title'
                        : 'app.settings.workflowConfigurationOption.title',
                    )}
                    className="mt-8"
                  />
                  {filteredConfigurations.map((config: WorkflowAssignableActivityConfigurationOptions, i: number) => (
                    <MultiSelect
                      nullable
                      key={i}
                      label={configurationOptionLabelDict[config.type] ?? config.type}
                      options={
                        config.options
                          .filter((option) =>
                            workflow.workflowType === 'Invoice'
                              ? option === 'Invoice' || option === 'InvoiceCoverSheet'
                              : workflow.workflowType === 'Document'
                                ? option === 'Document'
                                : true,
                          )
                          .map((option) => ({
                            label: option,
                            value: option,
                          })) as BaseSelectOption[]
                      }
                      value={configurations.filter((item) => item.type === config.type)?.map((x) => x.value) ?? []}
                      onChange={(values) => {
                        setConfigurations((prev: WorkflowTaskConfigurationReadModel[]) => {
                          const newConfig = new Array<WorkflowTaskConfigurationReadModel>();
                          values.forEach((v) => {
                            newConfig.push({
                              id: '', //Not needed anymore
                              type: config.type as WorkflowAssignableActivityConfigurationType,
                              value: v as EmailAttachmentOption,
                            });
                          });
                          return newConfig;
                        });
                      }}
                    />
                  ))}
                </>
              )}
            </>
          )}
        </SlideOver.Content>
        <SlideOver.Controls>
          <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
            {t('common.cancel')}
          </Button>
          <Button variant="primary" formSubmit>
            {t('common.save')}
          </Button>
        </SlideOver.Controls>
      </Form>
    </SlideOver>
  );
};
