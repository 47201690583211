import {
  AiEvalValuesReadModel,
  apiEndpointUrls,
  InvoiceReadModel,
  useApiGetJobRouterPdfFileQuery,
  useApiPostCreateInvoiceSourceDocumentMutation
} from '@client/shared/api';
import {
  DocumentViewer,
  FileInput,
  ListTitle,
  UploadToCloudDottedIcon
} from '@client/shared/toolkit';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { safeMutation } from '@client/shared/utilities';
import { useValidateProjectPermission } from '@client/shared/permissions';

interface InvoiceUploadProps {
  invoice: InvoiceReadModel,
  isJobRouter?: boolean
  setIsLoading?: (isLoading: boolean) => void
  authorizedToReview: boolean
}
export const InvoiceUpload = (props: InvoiceUploadProps) => {
  const {
    invoice,
    setIsLoading,
    authorizedToReview,
  } = props

  const { t } = useTranslation()
  const loadedProjectId = useLoadedProjectId();
  const loadedCalculationModelId = useLoadedVariantId();

  const [file] = useState<File | null>(null);
  const [pdfFile, setPdfFile] = useState<string | null>(null);
  const [fileData] = useState<AiEvalValuesReadModel | null | undefined>(null);
  const [fileError, setFileError] = useState(false);
  
  const canWrite = useValidateProjectPermission(['INVOICE_WRITE'], loadedProjectId ?? '');

  const canEdit = useMemo(() => {
    return (
      canWrite &&
      (invoice.hasWorkflow ? authorizedToReview : true) &&
      !invoice.isWaitingForNextTask &&
      invoice.state === 'Pending'
    );
  }, [canWrite, invoice, authorizedToReview]);
  
  const sourceDocument = useMemo(() => {
    if (invoice.documents) {
      return invoice.documents.find((document) => document.isInvoiceSource)
    }
    return null
  }, [invoice.documents])

  const { data: fetchedJobRouterFile, isFetching } = useApiGetJobRouterPdfFileQuery(
    {
      invoiceId: invoice?.id ?? '',
      calculationModelId: loadedCalculationModelId ?? 'unset',
      projectId: loadedProjectId ?? 'unset',
    },
    {
      skip: true //!isJobRouter || !loadedProjectId || !loadedCalculationModelId || !invoice
    })

  const [uploadDocument, { isLoading }] = useApiPostCreateInvoiceSourceDocumentMutation()

  useEffect(() => {
    if (fetchedJobRouterFile) {
      setPdfFile(fetchedJobRouterFile)
    }
  }, [fetchedJobRouterFile]);

  useEffect(() => {
    if(setIsLoading) {
      setIsLoading(isLoading || isFetching)
    }
  }, [isLoading, isFetching, setIsLoading])

  useEffect(() => {
    if (sourceDocument) {
      setPdfFile(apiEndpointUrls.apiGetInvoiceDocument.replace(':projectId', loadedProjectId as string).replace(':calculationModelId', loadedCalculationModelId as string).replace(':invoiceId', invoice.id).replace(':documentId', sourceDocument.id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceDocument]);

  const handleFileChange = async (files: FileList | null) => {
    if (files?.length && loadedProjectId && loadedCalculationModelId && !fileError) {
      const formData = new FormData();
      const file = files[0];
      if (file && file.type === 'application/pdf') {
        formData.append('file', file);
        try {
          await safeMutation(
            uploadDocument,
            {
              invoiceId: invoice.id,
              projectId: loadedProjectId,
              calculationModelId: loadedCalculationModelId,
              body: formData as unknown as { file: Blob },
            },
            isLoading
          )
        } catch (e) {
          console.error(e)
        }
      }
    }
  };

  return (
    <>
      {!pdfFile && !fileData ? (
        <div className="rounded-md w-full shadow">
          <ListTitle title={t('projectControl.invoice')} />
          <div className="p-8">
            <FileInput
              className="w-full rounded-md shadow bg-white hover:bg-slate-50 focus-within:border-secondary focus-within:border [&:has(:focus-visible)]:border"
              icon={<UploadToCloudDottedIcon className="w-16 flex-1" />}
              acceptedFileTypes={['application/pdf']}
              multiple={false}
              selectedFiles={null}
              onChange={(fileList) => {
                if (!fileError) {
                  handleFileChange(fileList);
                }
              }}
              uploadDescription={t('projectControl.invoiceUploadMessage')}
              setError={setFileError}
              disabled={!canEdit}
            />
          </div>
        </div>
      ) : (
        <DocumentViewer
          menuOptions={{
            fullscreen: false,
          }}
          title={t('projectControl.invoice')}
          file={file}
          pdfFile={pdfFile}
          fileLoaded={fileData !== null}
          loadingMessage={t('projectControl.fetchingInvoiceLoadingIndicator')}
          uploadDescription={t('projectControl.invoiceUploadMessage')}
        />
      )}
    </>
  )
}
