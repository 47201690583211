import { TagIcon } from '@heroicons/react/24/outline';
import { PlotType, useApiPostCreatePlotMutation, PlotReadModel } from '@client/shared/api';
import {
  BaseSelect,
  Button,
  NumberInput,
  TextInput,
  Form,
  FormField,
  EditNodeIcon,
  ModalOnCloseProps,
  Modal,
  FormWatch, MapMarkerDottedIcon, LoadingIndicator,
  DocumentIcon,
  ComboSelect,
} from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';
import { PlotAddFormValidationSchema, PlotAddFormValidationValues } from './PlotAddFormValidationSchema';
import { useLoadedProjectUnitSystemSymbol, useLoadedProjectId, useLoadedVariantId, getAssetClasses, useLoadedAssetClasses } from '@client/project/store';
import { useMemo } from 'react';

interface PlotAddChildModalProps extends ModalOnCloseProps {
  parent: PlotReadModel;
}

export const PlotAddChildModal = ({ onClose, parent }: PlotAddChildModalProps) => {
  const { t } = useTranslation();

  const loadedVariantId = useLoadedVariantId();

  const unitSystem = (useLoadedProjectUnitSystemSymbol);

  const availableAssetClasses = useLoadedAssetClasses();
  const assetClassOptions = useMemo(() => getAssetClasses(availableAssetClasses),[availableAssetClasses]);    

  const projectId = useLoadedProjectId();

  const [postPlotPiece, { isLoading: isCreating }] = useApiPostCreatePlotMutation();

  const plotTypeOptions = [
    {
      label: t('projectTaxonomy.plotType.projectRoom'),
      value: 'Plot',
    },
    {
      label: t('projectTaxonomy.plotType.Plot'),
      value: 'SpecialItem',
    },
    {
      label: t('projectTaxonomy.plotType.Infrastructure'),
      value: 'Infrastructure',
    },
  ];

  const handleSubmit = async (data: PlotAddFormValidationValues) => {
    if (loadedVariantId == null) return;

    try {
      await safeMutation(
        postPlotPiece,
        {
          projectId: projectId ?? 'unset',
          calculationModelId: loadedVariantId,
          body: {
            name: data.name,
            size: Number(data.size) ?? 0,
            constructionSize: data.constructionSize,
            type: data.type as PlotType,
            parentId: parent.plotId,
            grossVolume: data.grossVolume,
            cost: data.cost,
            selectedAssetClassId: data.assetClass,
            description: data.description,
            street: data.street,
            parcelNumber: data.parcelNumber,
          },
        },
        isCreating
      );

      onClose(true);
    } catch (e) {
      console.log(e);
    }
  };

  const defaultFormValues = {
    name: '',
    size: 0,
    constructionSize: 0,
    type: '',
  };

  return (
    <Form<PlotAddFormValidationValues>
      onSubmit={handleSubmit}
      validationSchema={PlotAddFormValidationSchema}
      defaultValues={defaultFormValues}
      className="flex flex-col flex-grow min-h-0"
    >
      {isCreating && <LoadingIndicator text={t('projectTaxonomy.creatingTaxonomyLoadingIndicator')} mode="overlay-window" />}
      <Modal.Header title={t('projectTaxonomy.addPlotTitle', { element: parent.plotName })} />
      <Modal.Content className="overflow-y-auto divide-gray-100 divide-y my-6">
        <FormField name="type">
          {(control) => (
            <BaseSelect
              label={t('projectTaxonomy.newPlotLabelPlotType')}
              options={plotTypeOptions}
              icon={<MapMarkerDottedIcon />}
              {...control}
            />
          )}
        </FormField>
        <FormField name="name">
          {(control) => (
            <TextInput
              label={t('projectTaxonomy.newPlotLabelName')}
              icon={<EditNodeIcon />}
              {...control}
            />
          )}
        </FormField>
        <FormWatch<PlotAddFormValidationValues> fieldNames={['type']}>
          {({ type }) => (
            <FormField name="assetClass">
              {(control) => (
                <ComboSelect
                  label={t('projectTaxonomy.addBuildingElementLabelUsageType')}
                  options={assetClassOptions}
                  icon={<DocumentIcon />}
                  disabled={type !== 'SpecialItem'}
                  {...control}
                />
              )}
            </FormField>
          )}
        </FormWatch>
        <FormField name="street">
          {(control) => <TextInput label={t('projectTaxonomy.newPlotLabelStreet')} icon={<TagIcon />} {...control} />}
        </FormField>
        <FormField name="parcelNumber">
          {(control) => <TextInput label={t('projectTaxonomy.newPlotLabelParcelNr')} icon={<TagIcon />} {...control} />}
        </FormField>
        <FormField name="description">
          {(control) => (
            <TextInput label={t('projectTaxonomy.newPlotLabelDescription')} icon={<TagIcon />} {...control} />
          )}
        </FormField>
        <FormField name="size">
          {(control) => (
            <NumberInput
              label={`${t('projectTaxonomy.newPlotLabelPlotSize')} (${
                parent?.totalPlotSize.unit ?? (unitSystem)
              })`}
              icon={<TagIcon />}
              {...control}
            />
          )}
        </FormField>
        <FormField name="constructionSize">
          {(control) => (
            <NumberInput
              label={`${t('projectTaxonomy.newPlotLabelConstructionSize')} (${
                parent?.constructionSize.unit ?? (unitSystem)
              })`}
              icon={<TagIcon />}
              {...control}
            />
          )}
        </FormField>{' '}
        <FormField name="grossVolume">
          {(control) => (
            <NumberInput
              label={`${t('projectTaxonomy.newPlotLabelGrossVolume')} (${
                parent?.grossVolume.unit ?? (unitSystem)
              })`}
              icon={<TagIcon />}
              {...control}
            />
          )}
        </FormField>
        <FormField name="cost">
          {(control) => <NumberInput label={t('projectTaxonomy.newPlotLabelCost')} icon={<TagIcon />} {...control} />}
        </FormField>
      </Modal.Content>
      <Modal.Controls className="bg-white">
        <Button variant="secondary" onClick={() => onClose(false)} className="mr-2">
          {t('common.cancel')}
        </Button>
        <Button variant="primary" formSubmit={true}>
          {t('projectTaxonomy.addBuildingElementAction')}
        </Button>
      </Modal.Controls>
    </Form>
  );
};
