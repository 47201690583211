import {
  CalculationModelEarningsElement,
  CalculationModelEarningsGroupElement,
  EarningsElementShortReadDto,
} from '@client/shared/api';

// const flattenSingleEarningsElement = (element: CalculationModelEarningsElement): CalculationModelEarningsElement[] => {
//   return [element, ...element.children.flatMap(flattenSingleEarningsElement)];
// };

// TODO not sure why it returns (CalculationModelEarningsGroupElement | CalculationModelEarningsElement)[]
export const flattenEarningsElements = (
  earningElements: EarningsElementShortReadDto[],
): (CalculationModelEarningsGroupElement | CalculationModelEarningsElement)[] => {
  return earningElements.flatMap((earningElement) => {
    if (earningElement.group) {
      return [earningElement.group, ...flattenEarningsElements(earningElement.group.children)];
    } else {
      return [];
    }
  });
};

/**
 * Flattens all nested earning groups to one level.
 * @param earningElements
 */
export const flattenEarningGroups = (
  earningElements: EarningsElementShortReadDto[],
): CalculationModelEarningsGroupElement[] => {
  return earningElements.flatMap((earningElement) => {
    if (earningElement.group) {
      return [earningElement.group, ...flattenEarningGroups(earningElement.group.children)];
    } else {
      return [];
    }
  });
};
