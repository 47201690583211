import { AddOutlinedIcon, Up3OutlinedIcon } from '@client/shared/toolkit';
import cn from 'classnames';

export const BenchmarkingEmptyColumns = ({
  isAddDisabled,
  setIsAddWizardOpen,
  title,
  message,
}: {
  title: string;
  message: string;
  setIsAddWizardOpen: () => void;
  isAddDisabled: boolean;
}) => {
  return (
    <div className="relative text-slate-300 flex-1 bg-slate-50 rounded flex flex-col justify-center items-center p-8 gap-2 m-2 h-[calc(100%-1.25rem)]">
      <AddOutlinedIcon
        onClick={!isAddDisabled ? setIsAddWizardOpen : undefined}
        className={cn('w-14 flex-none', {
          'text-slate-300 hover:text-slate-400 hover:scale-110 transition-all duration-200 cursor-pointer':
            !isAddDisabled,
          'text-slate-100': isAddDisabled,
        })}
      />
      <span className="text-2xl font-medium opacity-0">{title}</span>
      <div className="absolute top-2 left-2 text-slate-300 flex gap-2 text-2xl font-medium max-w-[213px]">
        <Up3OutlinedIcon className="w-8 flex-none self-start" />
        {message}
      </div>
    </div>
  );
};
