import cn from 'classnames';
import { DocumentViewerFileDataInlineEdit, NumberInput, TrashIcon } from '../..';
import { ReactNode } from 'react';

interface SettingsInlineEditFieldProps {
  value: string;
  label: string;
  valueInput: number | null;
  onChange?: (value: number | null) => void;
  disabled?: boolean;
  updateEditMode?: (isEditMode: boolean) => void;
  onDelete?: () => void;
  icon?: ReactNode;
  isHighlighted?: boolean;
  min?: number;
  max?: number;
}

export const SettingsInlineEditField = (props: SettingsInlineEditFieldProps) => {
  const { value, valueInput, label, onChange, disabled, updateEditMode, onDelete, icon, isHighlighted = false, min, max } = props;
  return (
    <div
      className={cn('relative bg-gray-100 p-2 w-[130px] flex-none flex-col border-2 rounded overflow-hidden', {
        'hover:border-2 transition-colors duration-300': onChange && !disabled,
        'border-gray-100 hover:border-gray-200': !isHighlighted,
        'border-secondary': isHighlighted,
      })}
    >
      <DocumentViewerFileDataInlineEdit
        marginX=""
        marginY="my-0"
        closeOnBlur={false}
        className="h-full"
        toggleContentClassName="w-full overflow-hidden"
        toggleContent={
          onChange && !disabled ? (
            <NumberInput label={label} onChange={onChange} value={valueInput} min={min} max={max} />
          ) : undefined
        }
        updateEditMode={updateEditMode}
      >
        <div className="w-full bg-gray-100 relative h-full flex flex-col gap-0.5">
          <div className="flex gap-2 justify-between">
            <div title={label} className={cn('text-xs text-slate-500 font-medium overflow-hidden text-ellipsis')}>{label}</div>
            {onDelete && (
              <TrashIcon
                className="text-slate-500 w-4 h-4 flex-none hover:text-slate-700 s"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onDelete();
                }}
              />
            )}
            {icon && <div className="text-slate-500 w-4 h-4 flex-none leading-tight text-right">{icon}</div>}
          </div>
          <div className="font-bold flex-1 content-end">{value}</div>
        </div>
      </DocumentViewerFileDataInlineEdit>
    </div>
  );
};
