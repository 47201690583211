import { InvoiceYardiDataRecord } from '@client/shared/api';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { LabeledValue, ToggleButton } from '@client/shared/toolkit';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { formatCurrency, formatDate } from '@client/shared/utilities';

interface InvoiceYardiImportRecordProps {
  element: InvoiceYardiDataRecord;
}

export const InvoiceYardiImportRecord = ({ element }: InvoiceYardiImportRecordProps) => {
  const { t } = useTranslation();

  return (
    <Disclosure as="div" className="w-full" defaultOpen={true}>
      {({ open }) => (
        <>
          <DisclosureButton as="div">
            <div className="flex items-center h-[38px] hover:text-black hover:bg-gray-50 relative border-b font-bold text-sm text-gray-900">
              <ToggleButton className="absolute z-[5] -left-6 top-0 h-full" open={open} />
              <div className="pl-2 w-full h-full flex flex-row cursor-pointer items-center">
                <div className="flex flex-grow">
                  <LabeledValue value={element.probisInvoiceID ?? '-'}>
                    {t('projectControl.auditInvoiceNumber')}
                  </LabeledValue>
                </div>
                {element.errors && element.errors.length > 0 && (
                  <div className="mr-2 flex justify-end text-red-600">
                    <ExclamationCircleIcon className="w-6" />
                  </div>
                )}
              </div>
            </div>
          </DisclosureButton>
          <DisclosurePanel>
            <div className="text-xs mb-2 ml-2">
              {element.errors && element.errors.length > 0 && (
                <div className="text-center mb-2">
                  {element.errors.map((err, index) => (
                    <div key={index} className="text-red-500">
                      {t(err)}
                    </div>
                  ))}
                </div>
              )}
              <div className="ml-2">
                <div className="flex flex-row">
                  <div className="w-1/3">
                    <LabeledValue value={formatCurrency(element.paidInvoiceAmount)}>{t('project.amount')}</LabeledValue>
                  </div>
                  <div className="w-1/3">
                    <LabeledValue value={formatDate(element.paymentDate)}>
                      {t('projectControl.paymentDate')}
                    </LabeledValue>
                  </div>
                  <div className="w-1/3">
                    <LabeledValue value={element.paymentStatusFlag ?? '-'}>
                      {t('projectControl.ics.paymentStatusNet')}
                    </LabeledValue>
                  </div>
                </div>
              </div>
            </div>
          </DisclosurePanel>
        </>
      )}
    </Disclosure>
  );
};
