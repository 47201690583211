import { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import { WarningIconOutlined, InfoIcon } from '../icons';
import { Button } from '.';
import classNames from 'classnames';
import { Portal } from '@headlessui/react';
import { ArrowLeftIcon, ArrowRightIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';

interface GuideDialogPanelProps {
  header?: React.ReactNode;
  title: string | React.ReactNode;
  description: string | React.ReactNode;
  controls?: React.ReactNode;
  watermark?: 'info' | 'warning';
  customWatermark?: React.ReactNode;
  showCloseButton?: boolean;
  placement?: 'top-start' | 'top-end' | 'bottom-start' | 'bottom-end';
  show?: boolean;
  onClose?: () => void;
  showCount?: boolean;
  guideDialogData: GuideDialogData;
  setGuideDialogData: (data: GuideDialogData) => void;
  handlePopoverVisibility?: (isOpen: boolean) => void;
  targetElement: HTMLElement | null;
}

export const GuideDialogPanel = ({
  header,
  title,
  description,
  controls,
  watermark,
  customWatermark,
  showCloseButton,
  placement = 'bottom-end',
  show,
  onClose,
  showCount,
  guideDialogData,
  setGuideDialogData,
  handlePopoverVisibility,
  targetElement,
}: GuideDialogPanelProps) => {
  const [isOpen, setIsOpen] = useState(false);
  
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [mounted, setMounted] = useState(false);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [currentPlacement, setCurrentPlacement] = useState<string>(placement);

  const { styles, attributes, update } = usePopper(targetElement, popperElement, {
    placement,
    strategy: 'fixed',
    modifiers: [
      { name: 'offset', options: { offset: [0, 15] } },
      { name: 'preventOverflow', options: { padding: 8 } },
      { name: 'flip', options: { padding: 8 } },
      {
        name: 'computeStyles',
        options: {
          adaptive: true,
          gpuAcceleration: true,
        },
      },
    ],
  });

  useEffect(() => {
    if (attributes?.popper && attributes?.popper?.['data-popper-placement'] !== currentPlacement) {
      setCurrentPlacement(attributes?.popper?.['data-popper-placement'] ?? currentPlacement);
    }
  }, [attributes, currentPlacement]);

  useEffect(() => {
    setMounted(true);
    const timer = setTimeout(() => {
      setIsInitialRender(false);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (show !== undefined && mounted) {
      if (show) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, mounted, update]);

  useEffect(() => {
    if (!update ) return;

    update();
    setTimeout(() => {
      requestAnimationFrame(() => {
        update();
      });
    }, 700);
  }, [update, targetElement, popperElement]);

  useEffect(() => {
    if (handlePopoverVisibility) {
      if (isOpen) {
        handlePopoverVisibility(isOpen);
      } else {
        setTimeout(() => {
          handlePopoverVisibility(isOpen);
        }, 150);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const watermarks = {
    info: <InfoIcon className="opacity-10 h-[60px] w-[60px]" />,
    warning: <WarningIconOutlined className="opacity-10 h-[60px] w-[60px]" />,
  };

  const handleClose = () => {
    setGuideDialogData({
      ...guideDialogData,
      showDialog: false,
    });
    setIsOpen(false);
    onClose?.();
  };
  
  if (!targetElement) {
    return null;
  }

  return (
    <Portal>
      <div
        ref={setPopperElement}
        style={{
          ...styles.popper,
        }}
        {...attributes.popper}
        className="w-[305px]"
      >
        {isOpen && (
          <div
            className={classNames('w-full h-full relative transition-opacity z-30', {
              'opacity-100': isOpen,
              'opacity-0 pointer-events-none': !isOpen,
              visible: isOpen,
              'duration-500 delay-1000': isInitialRender,
              'duration-400': !isInitialRender,
            })}
          >
            <div className="w-full h-full bg-white p-2 shadow-xl rounded-lg">
              {showCount && (
                <div className="flex justify-center absolute w-full top-0 left-0 h-1 overflow-hidden">
                  {Array.from({ length: guideDialogData?.elements.length }).map((_, index) => (
                    <div
                      key={index}
                      className={classNames('w-full h-2', {
                        'bg-emerald-400': index === guideDialogData?.currentId,
                        'bg-gray-100': index !== guideDialogData?.currentId,
                        'rounded-tl-xl': index === 0,
                        'rounded-tr-xl': index === guideDialogData?.elements.length - 1,
                      })}
                    />
                  ))}
                </div>
              )}
              {showCloseButton && isOpen && (
                <button className="absolute top-1.5 right-1.5 transition-opacity" onClick={handleClose}>
                  <XMarkIcon className="w-[22px] h-[22px] text-black hover:text-gray-600 transition-color duration-200 cursor-pointer" />
                </button>
              )}
              {header}
              <div className="p-2 mt-2 text-gray-800">
                <div className="text-[15px] font-bold mb-3">{title}</div>
                <div className="text-[13px]">{description}</div>
              </div>
              {watermark && <div className="ml-5">{watermarks[watermark]}</div>}
              {customWatermark && <div className="ml-5">{customWatermark}</div>}
              {controls}
            </div>
            <div
              className={classNames('absolute w-5 h-5 bg-white rotate-45 ', {
                'bottom-[-10px] left-5 z-1': currentPlacement === 'top-start',
                'bottom-[-10px] right-5 z-1': currentPlacement === 'top-end',
                'top-[-10px] left-5 shadow z-[-1]': currentPlacement === 'bottom-start',
                'top-[-10px] right-5 shadow z-[-1]': currentPlacement === 'bottom-end',
              })}
            />
          </div>
        )}
      </div>
    </Portal>
  );
};
export interface GuideDialogData {
  showDialog: boolean;
  elements: Array<string>;
  currentId: number;
}

interface GuideDialogControlsProps {
  guideDialogData: GuideDialogData;
  setGuideDialogData: (data: GuideDialogData) => void;
}

export const GuideDialogControls = ({ guideDialogData, setGuideDialogData }: GuideDialogControlsProps) => {
  const { t } = useTranslation();
  return (
    <div
      className={classNames(
        'p-1 mb-1 mt-5 w-full flex',
        guideDialogData.elements.length > 1 ? 'justify-between' : 'justify-end',
      )}
    >
      {guideDialogData.elements.length > 1 &&
        (guideDialogData?.currentId === 0 ||
          guideDialogData?.currentId === guideDialogData.elements.length - 1 ||
          (guideDialogData?.currentId !== guideDialogData.elements.length - 1 && guideDialogData?.currentId > 0)) && (
          <Button
            variant="secondary"
            className="mr-2"
            onClick={() => {
              if (guideDialogData?.currentId === 0) {
                setGuideDialogData({
                  ...guideDialogData,
                  showDialog: false,
                });
              }
              if (guideDialogData?.currentId > 0) {
                setGuideDialogData({
                  ...guideDialogData,
                  currentId: (guideDialogData?.currentId ?? 0) - 1,
                });
              }
            }}
          >
            {guideDialogData?.currentId === 0 ? (
              t('common.close')
            ) : (
              <span className="flex items-center">
                <ArrowLeftIcon className="h-5 w-5 mr-2" />
                {t('common.back')}
              </span>
            )}
          </Button>
        )}

      <Button
        variant="primary"
        onClick={() => {
          guideDialogData?.currentId !== guideDialogData.elements.length - 1
            ? setGuideDialogData({
                ...guideDialogData,
                currentId: (guideDialogData?.currentId ?? 0) + 1,
              })
            : setGuideDialogData({
                ...guideDialogData,
                showDialog: false,
              });
        }}
      >
        {guideDialogData?.currentId !== guideDialogData.elements.length - 1 ? (
          <span className="flex items-center">
            {t('common.next')} <ArrowRightIcon className="h-5 w-5 ml-2" />
          </span>
        ) : (
          t('app.gotIt')
        )}
      </Button>
    </div>
  );
};

export const GuideDialog = Object.assign(GuideDialogPanel, {
  Controls: GuideDialogControls,
});
