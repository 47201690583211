import { NavLink, useLocation } from 'react-router-dom';
import {
  CalculateMenuIcon,
  ContractMenuIcon,
  ControlMenuIcon,
  DashboardMenuIcon,
  DealTasksIcon,
  GraphReportDottedIcon,
  HistoryMenuIcon, LOGO_MENU_IMAGE,
  ManageMenuIcon,
  PlanMenuIcon,
  ProjectMenuIcon,
  RentMenuIcon,
  ReportingMenuIcon,
  SellMenuIcon,
  useComponentDimensions,
} from '@client/shared/toolkit';
import React, { createRef, ReactElement, useCallback, useMemo, useRef, useState } from 'react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { usePopper } from 'react-popper';
import { isEmpty } from '@client/shared/utilities';
import { useLoadedProject, useLoadedVariantId } from '@client/project/store';
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { SideNavigationItem, RouteNavType, ROUTES_CONFIG } from '@client/shared/permissions';

const constructPath = (slug: string, projectId?: string, variantId?: string, group?: boolean) => {
  if (!projectId) {
    return '';
  }
  let path = `${group ? ROUTES_CONFIG.MULTI_PROJECT.path : ROUTES_CONFIG.PROJECT.path}/${projectId}/${slug}`

  if (!isEmpty(variantId)) {
    path += `?vid=${variantId}`;
  }

  return path;
};

const groupNavigationItems: RouteNavType[] = [
  {
    label: 'project.menuDashboard',
    icon: <DashboardMenuIcon />,
    routeConfig: ROUTES_CONFIG.MULTI_PROJECT_DASHBOARD
  },
  {
    label: 'project.menuReporting',
    icon: <GraphReportDottedIcon />,
    routeConfig: ROUTES_CONFIG.MULTI_PROJECT_REPORTING
  }
];

export const SideNavigation = () => {
  const { data: loadedProject } = useLoadedProject();
  const loadedProjectId = loadedProject?.project.payload.id;
  const loadedVariantId = useLoadedVariantId();
  const hasVersionCreated = loadedProject?.project.payload.versionCreated;

  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>();

  const location = useLocation();
  const group = location.pathname.includes('/multi-project/');

  const menuListElementRef = createRef<HTMLDivElement>();
  const dimensions = useComponentDimensions(menuListElementRef);

  const [targetElement, setTargetElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: 'right',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements:  ['right-start', 'bottom'],
          rootBoundary: 'viewport',
        },
      }
    ]
  });

  let navigationItems: RouteNavType[];
  if (loadedProject?.project?.payload?.isTemplate) {
    navigationItems = [
      {
        label: 'project.menuDashboard',
        icon: <DashboardMenuIcon />,
        routeConfig: ROUTES_CONFIG.PROJECT_DASHBOARD,
        disabled: !loadedProjectId
      },
      {
        label: 'project.menuProject',
        icon: <ProjectMenuIcon />,
        routeConfig: ROUTES_CONFIG.PROJECT_TAXONOMY,
        disabled: !loadedProjectId
      },
      {
        label: 'project.menuCalculate',
        icon: <CalculateMenuIcon />,
        routeConfig: ROUTES_CONFIG.PROJECT_CALCULATE,
        disabled: !loadedProjectId
      },
      {
        label: 'project.menuReporting',
        icon: <ReportingMenuIcon />,
        routeConfig: ROUTES_CONFIG.PROJECT_REPORTING,
        disabled: !loadedProjectId
      },
      {
        label: 'project.menuVariants',
        icon: <HistoryMenuIcon />,
        routeConfig: ROUTES_CONFIG.PROJECT_VARIANTS,
        disabled: !loadedProjectId
      }
    ];
  } else {
    const isDisabledForScenarios = !loadedProjectId || !hasVersionCreated;

    navigationItems = [
      {
        label: 'project.menuDashboard',
        icon: <DashboardMenuIcon />,
        routeConfig: ROUTES_CONFIG.PROJECT_DASHBOARD,
        disabled: !loadedProjectId
      },
      {
        label: 'project.menuProject',
        icon: <ProjectMenuIcon />,
        routeConfig: ROUTES_CONFIG.PROJECT_TAXONOMY,
        disabled: !loadedProjectId
      },
      {
        label: 'project.menuCalculate',
        icon: <CalculateMenuIcon />,
        routeConfig: ROUTES_CONFIG.PROJECT_CALCULATE,
        disabled: !loadedProjectId
      },
      {
        label: 'project.menuContract',
        icon: <ContractMenuIcon />,
        routeConfig: ROUTES_CONFIG.PROJECT_CONTRACT,
        disabled: isDisabledForScenarios
      },
      {
        label: 'project.menuControl',
        icon: <ControlMenuIcon />,
        routeConfig: ROUTES_CONFIG.PROJECT_CONTROL,
        disabled: isDisabledForScenarios
      },
      {
        label: 'project.menuReporting',
        icon: <ReportingMenuIcon />,
        routeConfig: ROUTES_CONFIG.PROJECT_REPORTING,
        disabled: isDisabledForScenarios
      },
      {
        label: 'project.menuVariants',
        icon: <HistoryMenuIcon />,
        routeConfig: ROUTES_CONFIG.PROJECT_VARIANTS,
        disabled: !loadedProjectId
      },
      {
        label: 'project.menuRent',
        icon: <RentMenuIcon />,
        routeConfig: ROUTES_CONFIG.PROJECT_RENT,
        disabled: isDisabledForScenarios
      },
      {
        label: 'project.menuSell',
        icon: <SellMenuIcon />,
        routeConfig: ROUTES_CONFIG.PROJECT_SELL,
        disabled: isDisabledForScenarios
      },
      {
        label: 'project.menuMyTasks',
        icon: <DealTasksIcon />,
        routeConfig: ROUTES_CONFIG.PROJECT_TASKS,
        disabled: isDisabledForScenarios
      },
      {
        label: 'project.menuPlan',
        icon: <PlanMenuIcon />,
        routeConfig: ROUTES_CONFIG.PROJECT_PLAN,
        disabled: true
      },
      {
        label: 'project.menuManage',
        icon: <ManageMenuIcon />,
        routeConfig: ROUTES_CONFIG.PROJECT_MANAGE,
        disabled: true
      },
    ];
  }


  // FP: tailwind height class h-22 is 4rem, multiplied by 16 to get px
  const menuItemHeight = Math.round(4 * 16);

  const maxItems = Math.max(0, Math.floor((dimensions?.height ?? 0) / menuItemHeight) - 1);

  const visibleItems = navigationItems.slice(0, maxItems);
  const hiddenItems = navigationItems.slice(maxItems);

  const showMore = hiddenItems.length > 0 && !group;

  const popoverButtonRef = useRef<HTMLDivElement>(null)
  const closePopper = useCallback(() => {
    if (popperElement) {
      popoverButtonRef?.current?.click()
    }
  }, [popperElement])

  const subTitle = useMemo(() => {
    if (location.pathname.includes('taxonomy')) {
      return t('projectTaxonomy.routeSubTitle')
    } else if (location.pathname.includes('contract')) {
      return t('projectContract.routeSubTitle')
    } else if (location.pathname.includes('rent')) {
      return t('projectRent.rentalRouteSubTitle')
    } else if (location.pathname.includes('sell')) {
      return t('projectRent.sellRouteSubTitle')
    } else if (location.pathname.includes('variants')) {
      return t('projectVariants.routeSubTitle')
    } else if (location.pathname.includes('control')) {
      return t('projectControl.routeSubTitle')
    } else if (location.pathname.includes('calculate')) {
      return t('projectCalculate.routeSubTitle')
    }
    return t('projectDashboard.routeSubTitle')
  }, [location.pathname, t])

  return (
    <nav className="pdf-export-hidden flex flex-wrap lg:flex-nowrap lg:flex-col items-center bg-white text-gray-700 shadow-lg justify-between lg:justify-start lg:h-full w-full lg:w-16 z-10 border-b lg:border-b-0">
      <div className="h-16 w-16 lg:w-full flex justify-center items-center lg:border-b border-r flex-shrink-0">
        <NavLink className="mx-4" to="/">
          <img className="mx-auto" src={LOGO_MENU_IMAGE} alt="Logo Menu" />
        </NavLink>
      </div>
      <div className="lg:hidden min-w-0 flex-1 truncate px-4 text-black">
        <h1 className="text-xl font-bold truncate">
          {loadedProject?.project?.payload?.name ?? t('projectCalculate.routeTitle')}
        </h1>
        <h2 className="text-xs text-gray-500">{subTitle}</h2>
      </div>
      <div className="lg:flex-none overflow-hidden lg:flex-grow lg:w-full" ref={menuListElementRef}>
        <ul className="w-full flex flex-wrap lg:flex-nowrap lg:flex-col justify-end lg:justify-start">
          {!group &&
            visibleItems.map((item) => (
              <li key={item.routeConfig.name}>
                <SideNavigationItem
                  passDataCy={`project-navigation-${item.routeConfig.name}`}
                  icon={item.icon as ReactElement}
                  path={constructPath(item.routeConfig.name, loadedProjectId, loadedVariantId)}
                  disabled={item.disabled}//{!loadedProjectId}
                  label={item.label}
                  onNavItemClick={closePopper}
                  routeConfig={item.routeConfig}
                  projectId={loadedProjectId}
                />
              </li>
            ))}
          {group &&
            groupNavigationItems.map((item) => (
              <li key={item.routeConfig.name}>
                <SideNavigationItem
                  passDataCy={`multi-project-navigation-${item.routeConfig.name}`}
                  icon={item.icon as ReactElement}
                  path={constructPath(item.routeConfig.name, id, loadedVariantId, true)}
                  disabled={item.disabled}//{!loadedProjectId}
                  label={item.label}
                  onNavItemClick={closePopper}
                  routeConfig={item.routeConfig}
                  projectId={loadedProjectId}
                />
              </li>
            ))}
          {showMore && (
            <Popover as="li">
              <div className="w-full" ref={setTargetElement}>
                <PopoverButton className="w-full">
                  <div
                    ref={popoverButtonRef}
                    className="h-16 px-2 flex flex-col justify-center items-center w-full text-xs font-medium border-r-4 border-l-4 border-l-transparent border-r-transparent hover:bg-secondary/10 transition-colors duration-200 lg:border-b cursor-pointer"
                  >
                    <EllipsisVerticalIcon className="w-8" />
                  </div>
                </PopoverButton>
              </div>
              <PopoverPanel
                portal
                ref={setPopperElement}
                style={{ ...styles.popper }}
                {...attributes.popper}
                className="z-20 flex bg-white outline-none shadow"
              >
                <ul className="flex flex-row flex-wrap lg:flex-nowrap w-screen lg:w-auto border-t lg:border-t-0">
                  {hiddenItems.map((item) => (
                    <li
                      key={item.routeConfig.name}
                      className="w-1/4 lg:w-auto border-b lg:border-b-0 border-r lg:border-r-0"
                    >
                      <SideNavigationItem
                        icon={item.icon as ReactElement}
                        path={constructPath(item.routeConfig.name, loadedProjectId, loadedVariantId)}
                        disabled={!loadedProjectId}
                        label={item.label}
                        onNavItemClick={closePopper}
                        routeConfig={item.routeConfig}
                        projectId={loadedProjectId}
                      />
                    </li>
                  ))}
                </ul>
              </PopoverPanel>
            </Popover>
          )}
        </ul>
      </div>
    </nav>
  );
};
