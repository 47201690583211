import { lazy, useEffect } from 'react';
import { LazyComponent } from '@client/shared/toolkit';
import { useNavigate, useParams } from 'react-router-dom';
import { useApiGetReportGenericDataSourceQuery, useApiGetReportQuery } from '@client/shared/api';
import { ROUTES_CONFIG } from '@client/shared/permissions';

const ReportViewerComponent = lazy(() => import('../../../lazy/src/components/ReportViewer').catch((e) => {
  console.error('Component Failed Loading:', e);
  return { default: () => <div>Error loading the component.</div> };
}));

const LazyReportViewer = (props: { projectId?: string; calculationModelId?: string }) => {
  const { projectId, calculationModelId } = props;
  const { reportId } = useParams<{ reportId: string }>();
  const navigate = useNavigate();
  const {
    data: reportData,
    isFetching,
    isError,
  } = useApiGetReportQuery(
    {
      reportId: reportId ?? '',
    },
    {
      skip: !reportId,
    },
  );

  // This loads the datasource with correct data, as report response only uses datasource dummy data
  const { data: dataSource, isLoading: isDataSourceLoading } = useApiGetReportGenericDataSourceQuery(
    {
      dataSourceId: reportData?.dataSourceModel.id ?? '',
      projectId: projectId ?? '',
      calculationModelId: calculationModelId ?? '',
    },
    {
      skip: !reportData?.dataSourceModel.id || !projectId || !calculationModelId,
    },
  );

  if (!reportId) {
    navigate(ROUTES_CONFIG.REPORTING_BASE.path.replace(':id', projectId ?? ''));
  }

  useEffect(() => {
    if (isError && projectId) {
      navigate(ROUTES_CONFIG.REPORTING_BASE.path.replace(':id', projectId));
    }
  }, [isError, navigate, projectId]);

  return (
    <LazyComponent>
      <ReportViewerComponent
        className="min-h-[568px] flex-1 rounded-b-md shadow-lg overflow-hidden"
        isLoading={isFetching || isDataSourceLoading}
        reportData={reportData}
        dataSource={dataSource}
      />
    </LazyComponent>
  );
};

export default LazyReportViewer;
