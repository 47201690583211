import React from 'react';
import { recursivelyApplyDisabled } from './recursivelyApplyDisabled';

interface DisableProps {
  children: React.ReactElement[] | React.ReactElement;
  disabled?: boolean;
}

export const DisableInput = ({ disabled = false, children }: DisableProps) => {
  if (!Array.isArray(children)) {
    children = [children];
  }

  if (Array.isArray(children) && children.length === 0) {
    return null;
  }

  return <>{recursivelyApplyDisabled(children, disabled)}</>;
};
