import { GlobeIcon, ProjectSettingsEditSection, SettingsInlineEditField } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import {
  BenchmarkSettingsPayload,
  CustomBenchmarkPropertyReadModel,
  useApiGetCustomBenchmarkPropertiesQuery,
} from '@client/shared/api';
import { useLoadedProject, useUnitSystem } from '@client/project/store';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { getBenchmarkPropertyCode, getBenchmarkPropertyDescription } from '@client/main/settings';
import { getUnitLabel } from '@client/project/shared';

export const ProjectBenchmarkSettingsReferenceUnits = ({
  benchmarkSettings,
  setBenchmarkSettings,
}: {
  benchmarkSettings: BenchmarkSettingsPayload | null;
  setBenchmarkSettings: (payload: BenchmarkSettingsPayload | null) => void;
}) => {
  const { t } = useTranslation();
  const unitSystem = useUnitSystem();
  const { data: benchmarkProperties, isFetching } = useApiGetCustomBenchmarkPropertiesQuery();
  const { data: loadedProject, isFetching: isLoading } = useLoadedProject();

  const originalProjectBenchmarkProperties = useMemo(() => {
    return loadedProject?.project?.payload?.benchmarkSettings?.customPropertyValues ?? [];
  }, [loadedProject?.project?.payload?.benchmarkSettings?.customPropertyValues]);

  const getDataForRefUnit = useCallback(
    (propertyReadModel: CustomBenchmarkPropertyReadModel) => {
      let originalFactor = originalProjectBenchmarkProperties.find((refUnit) => refUnit.customPropertyId === propertyReadModel.id)
        ?.value;
      let overwrittenFactor = benchmarkSettings?.customPropertyValues?.find(
        (refUnit) => refUnit.customPropertyId === propertyReadModel.id,
      )?.value;

      const unit =
        unitSystem === 'Metric'
          ? getUnitLabel(propertyReadModel.metricUnit)
          : getUnitLabel(propertyReadModel.imperialUnit);

      let settingsFactor = overwrittenFactor;
      let icon: ReactNode | undefined;
      if (!originalFactor && !overwrittenFactor) {
        icon = <GlobeIcon />;
      }

      return {
        value: settingsFactor ?? 1,
        icon: icon,
        isOverwritten: !icon,
        isUpdated: overwrittenFactor !== originalFactor,
        unit: unit,
        label: `${getBenchmarkPropertyDescription(propertyReadModel)} (${getBenchmarkPropertyCode(propertyReadModel)})`,
      };
    },
    [originalProjectBenchmarkProperties, unitSystem, benchmarkSettings?.customPropertyValues],
  );

  const updateValue = useCallback(
    (value: number | null, id: string) => {
      if (benchmarkSettings) {
        const settingsCopy = { ...benchmarkSettings };
        const entries = [...settingsCopy.customPropertyValues];

        const foundIndex = entries.findIndex((entry) => entry.customPropertyId === id);
        if (foundIndex >= 0) {
          const entryCopy = { ...entries[foundIndex] };
          if (value === null) {
            entries.splice(foundIndex, 1);
          } else {
            entryCopy.value = value;
            entries[foundIndex] = entryCopy;
          }
        } else if (value !== null) {
          entries.push({
            value: value,
            customPropertyId: id,
          });
        }
        settingsCopy.customPropertyValues = entries;
        setBenchmarkSettings(settingsCopy);
      }
    },
    [benchmarkSettings, setBenchmarkSettings],
  );

  return (
    <ProjectSettingsEditSection title={t('projectSetting.referenceUnits.title')}>
      <div className="mt-3 flex gap-1 flex-none w-auto overflow-x-auto pb-2">
        {benchmarkProperties
          ? benchmarkProperties.map((refUnit) => {
              const dataForRefUnit = getDataForRefUnit(refUnit);
              return (
                <SettingsInlineEditField
                  key={`benchmarking-reference-unit-${refUnit.id}`}
                  value={`${dataForRefUnit.value} ${dataForRefUnit.unit}`}
                  valueInput={dataForRefUnit.value}
                  label={dataForRefUnit.label}
                  onChange={(val) => updateValue(val, refUnit.id)}
                  onDelete={dataForRefUnit.isOverwritten ? () => updateValue(null, refUnit.id) : undefined}
                  icon={dataForRefUnit.icon}
                  isHighlighted={dataForRefUnit.isUpdated}
                  min={1}
                />
              );
            })
          : !isFetching && !isLoading && t('no dta')}
      </div>
    </ProjectSettingsEditSection>
  );
};
