import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { AclTenant, api } from '@client/shared/api';
import { useUi } from '@client/shared/store';
import { ProtectedNavLink, ROUTES_CONFIG } from '@client/shared/permissions';
import { useTranslation } from 'react-i18next';
import { createTenantLink } from '@client/shared/utilities';
import { Highlighted, SearchInput, UserAvatar } from '@client/shared/toolkit';
import { useGenericDispatch } from '@client/shared/store';

export const MainNavigationUserMenu = () => {
  const { t } = useTranslation();

  const dispatch = useGenericDispatch();

  const ui = useUi();
  const user = ui.appUser;
  const tenant = user.tenant;
  const sortedTenants = useMemo(() => {
    const tenants = [...(user.tenants ?? [])];
    return tenants.sort((a, b) => (a.name?.toLowerCase() ?? '').localeCompare(b.name?.toLowerCase() ?? ''));
  }, [user]);

  const switchTenant = useCallback((tenant: AclTenant) => {
    window.location.href = createTenantLink(tenant.subdomain);
  }, []);

  const [searchValue, setSearchValue] = useState('');

  return (
    <Menu as="div" className="relative inline-block text-left z-20">
      <MenuButton>
        <div className="flex space-x-3 items-center">
          <UserAvatar
            userId={user?.userId ?? ''}
            userName={user?.fullName ?? ''}
            lastUpdate={user?.lastUpdate ?? ''}
            hasAvatar={user?.hasAvatar ?? false}
            classNames="w-10 h-10"
          />
          <div className="text-left">
            <div className="font-bold text-base">{user?.fullName}</div>
            <div className="-mt-1 text-sm font-light">{tenant?.name}</div>
          </div>
        </div>
      </MenuButton>
      <MenuItems className="origin-top-right absolute right-0 mt-2 w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden">
        <>
          <div className="p-3">
            <div className="flex space-x-3 items-center text-black">
              <div className="flex-shrink-0">
                <UserAvatar
                  userId={user?.userId ?? ''}
                  userName={user?.fullName ?? ''}
                  lastUpdate={user?.lastUpdate ?? ''}
                  hasAvatar={user?.hasAvatar ?? false}
                  classNames="w-12 h-12 "
                />
              </div>
              <div className="text-left truncate">
                <div className="font-bold text-lg truncate">{user?.fullName}</div>
                <div className="-mt-1 text-sm font-light truncate">{user?.email}</div>
              </div>
            </div>
          </div>
          <div className="bg-gray-200 h-[1px]" />
          <ProtectedNavLink
            routeConfig={ROUTES_CONFIG.SETTINGS}
            className="text-black w-full px-4 py-2 h-14 text-base flex items-center border-b hover:bg-blue-200"
            activeClassName="bg-blue-200"
            disabledClassName="text-black w-full px-4 py-2 h-14 text-base flex items-center border-b"
          >
            {t('app.menuSettings')}
          </ProtectedNavLink>
          <ProtectedNavLink
            routeConfig={ROUTES_CONFIG.HELP}
            className="text-black w-full px-4 py-2 h-14 text-base flex items-center border-b hover:bg-blue-200"
            activeClassName="bg-blue-200"
            disabledClassName="text-black w-full px-4 py-2 h-14 text-base flex items-center border-b"
          >
            {t('app.menuHelp')}
          </ProtectedNavLink>
          <div className="bg-gray-200 h-[1px]" />
          <div className="p-3 text-gray-600 bg-gray-200 items-center">
            <div className="text-xs font-bold mb-2">{t('app.menuSectionCompany', { count: sortedTenants.length })}</div>
            <SearchInput
              placeholder={t('app.menuSectionCompany')}
              value={searchValue}
              handleSearch={(val) => setSearchValue(val)}
              className="max-w-[428px] text-gray-400"
              bgColor="bg-white"
            />
          </div>
          <div className="max-h-[300px] overflow-y-auto">
            {sortedTenants
              .filter((tenant) => tenant.name?.toLowerCase().includes(searchValue.toLowerCase()))
              .map((tenant) => (
                <MenuItem key={tenant.tenantId}>
                  {({ focus }) => {
                    const activeTenant = user?.tenant?.tenantId === tenant.tenantId;
                    return (
                      <button
                        onClick={() => switchTenant(tenant)}
                        className={classNames(
                          'text-black w-full px-4 py-2 h-14 text-base flex items-center border-b justify-between',
                          { 'font-bold': activeTenant, 'bg-blue-200': focus },
                        )}
                      >
                        <span>
                          {searchValue ? <Highlighted text={tenant.name} highlight={searchValue} /> : tenant.name}
                        </span>
                        <div className="h-4 w-4 border-2 rounded-full border-neutral-500 focus:ring-2 focus:ring-blue-300 flex items-center justify-center">
                          <div className={classNames('w-2 h-2 rounded-full', { 'bg-black': activeTenant })}></div>
                        </div>
                      </button>
                    );
                  }}
                </MenuItem>
              ))}
          </div>
          <div className="bg-gray-200 h-2" />
          <MenuItem>
            {({ focus }) => (
              <NavLink
                to="/logout"
                className={classNames('text-black w-full px-4 py-2 h-14 text-base flex items-center', {
                  'bg-blue-200': focus,
                })}
                onClick={() => dispatch(api.endpoints.apiPostLogout.initiate())}
              >
                {t('common.logout')}
              </NavLink>
            )}
          </MenuItem>
        </>
      </MenuItems>
    </Menu>
  );
};
