import { setProjectId } from '@client/project/store';
import { getTypedChild } from '@client/shared/utilities';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { MainNavigation } from './MainNavigation';
import cn from 'classnames';
import { BG_PROJECTS_IMAGE, useScrollPosition } from '@client/shared/toolkit';
import { setPortfolioId } from '@client/portfolio/store';


interface AppLayoutProps extends PropsWithChildren {
  updateHeaderCollapsed?: (collapsed: boolean) => void
  scrollOffset?: number
}
export const AppLayout = ({ children, updateHeaderCollapsed, scrollOffset = 20 }: AppLayoutProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setProjectId());
    dispatch(setPortfolioId());
  }, [dispatch]);

  const contentElement = getTypedChild(children, AppLayoutContent);
  const menuElement = getTypedChild(children, AppLayoutMenu);
  const titleElement = getTypedChild(children, AppLayoutTitle);

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const scrollPosition = useScrollPosition(scrollContainerRef);

  const [isHeaderCollapsed, setIsHeaderCollapsed] = useState(false)

  useEffect(() => {
    if (scrollContainerRef.current) {
      if (scrollPosition.y > scrollOffset) {
        setIsHeaderCollapsed(true)
        if (updateHeaderCollapsed) {
          updateHeaderCollapsed(true)
        }
      } else if (scrollPosition.y === 0) {
        setIsHeaderCollapsed(false)
        if (updateHeaderCollapsed) {
          updateHeaderCollapsed(false)
        }
      }
    }
  }, [scrollPosition.y, updateHeaderCollapsed, scrollContainerRef, scrollOffset]);

  return (
    <div className="flex-grow flex bg-gray-200 relative overflow-hidden">
      {/* Background image + opacity overlay */}
      {!isMenuOpen && (
        <div className="absolute left-0 right-0 bg-cover bg-center h-128"
          style={{ backgroundImage: `url('${BG_PROJECTS_IMAGE}')` }} />
      )}
      <div className={cn('absolute top-0 left-0 right-0 h-128', {
        'bg-black opacity-50': !isMenuOpen,
        'bg-[#021A25]': isMenuOpen
      })} />

      {/* Content */}
      <div className="relative flex-grow flex flex-col">
        <MainNavigation setIsMenuOpen={setIsMenuOpen} />
        <div className="flex-grow flex flex-col min-h-0 mt-6 mb-10 mx-4 lg:mx-10 rounded-lg overflow-hidden">
          <div className={cn(
            'flex flex-row justify-between px-8 items-center transition-all duration-300 z-10',
            {
              'bg-white shadow-lg mb-0 mt-0 py-5': isHeaderCollapsed,
              'mb-2 mt-20 py-0': !isHeaderCollapsed
            }
          )}>
            <div
              className={cn('transition-all duration-300 font-medium', {
                'text-4xl text-slate-300 mb-0': isHeaderCollapsed,
                'text-5xl lg:text-7xl text-white opacity-40 -mb-2': !isHeaderCollapsed
              })}
            >
              {titleElement}
            </div>
            <div className="flex text-white mb-2 xl:mb-0">{menuElement}</div>
          </div>
          <div
            data-cy="app-scroll-container"
            id="app-scroll-container"
            className="z-0 -mt-[7px] max-w-[100vw] flex-grow rounded-lg bg-white shadow py-10 px-4 xl:px-10 text-black overflow-y-auto relative"
            ref={scrollContainerRef}
          >
            {contentElement}
          </div>
        </div>
      </div>
    </div>
  );
};

export const AppLayoutTitle = ({ children }: PropsWithChildren) => <>{children}</>;
export const AppLayoutMenu = ({ children }: PropsWithChildren) => <>{children}</>;
export const AppLayoutContent = ({ children }: PropsWithChildren) => <>{children}</>;
