import { AddOutlinedIcon } from '@client/shared/toolkit';
import React from 'react';
import cn from 'classnames';

export const BenchmarkingEmptyRows = ({
  title,
  isAddEnabled,
  setIsAddWizardOpen,
}: {
  title: string;
  isAddEnabled: boolean;
  setIsAddWizardOpen: () => void;
}) => {
  return (
    <div className="text-slate-300 flex-1 w-full bg-slate-50 rounded h-full flex flex-col justify-center items-center p-8 text-center gap-2">
      <AddOutlinedIcon
        className={cn('w-14 transition-all duration-200', {
          'text-slate-100': !isAddEnabled,
          'text-slate-300 hover:text-slate-400 hover:scale-110 cursor-pointer': isAddEnabled,
        })}
        onClick={isAddEnabled ? setIsAddWizardOpen : undefined}
      />
      <span className="text-2xl font-medium">{title}</span>
    </div>
  );
};
