import React, { useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import classNames from 'classnames';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { SelectedCustomIcon, UnselectedCustomIcon } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { useApiGetInvoicesReportQuery } from '@client/shared/api';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';

interface ReportInvoicesFilterProps {
  handleSelectInvoiceRecipient: (val: string) => void;
  handleSelectInvoiceStatus: (val: string) => void;
}

export const ReportInvoicesFilter = (props: ReportInvoicesFilterProps) => {
  const { handleSelectInvoiceRecipient, handleSelectInvoiceStatus } = props;

  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const { data, isFetching } = useApiGetInvoicesReportQuery(
    {
      projectId: loadedProjectId ?? '',
      calculationModelId: loadedVariantId ?? '',
    },
    {
      skip: !loadedProjectId || !loadedVariantId,
    },
  );

  const [selectedRecipient, setSelectedRecipient] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');

  const [targetElement, setTargetElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top-end'],
          rootBoundary: 'viewport',
        },
      },
    ],
  });

  const [targetElement2, setTargetElement2] = useState<HTMLDivElement | null>(null);
  const [popperElement2, setPopperElement2] = useState<HTMLElement | null>(null);
  const { styles: styles2, attributes: attributes2 } = usePopper(targetElement2, popperElement2, {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top-end'],
          rootBoundary: 'viewport',
        },
      },
    ],
  });

  const recipientOptions = useMemo(() => {
    if (data?.length) {
      const uniqueNames = [...new Set(data.map((invoice) => invoice.recipientName ?? ''))];
      return ['', ...uniqueNames.filter((name) => name !== '')];
    }
    return [''];
  }, [data]);

  const statusOptions = useMemo(() => {
    if (data?.length) {
      const uniqueStates = [...new Set(data.map((invoice) => invoice.state ?? ''))];
      return ['', ...uniqueStates, 'Overdue'];
    }
    return [''];
  }, [data]);

  const handleSelectRecipient = (option: string) => {
    setSelectedRecipient(option);
    handleSelectInvoiceRecipient(option);
  };

  const handleSelectStatus = (status: string) => {
    setSelectedStatus(status);
    handleSelectInvoiceStatus(status);
  };

  return (
    <>
      <Listbox
        as="div"
        className={classNames(
          'w-60 h-10 flex items-center text-sm font-medium text-gray-600 bg-gray-400 bg-opacity-10 rounded-full hover:bg-opacity-20 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75',
        )}
      >
        <div ref={setTargetElement} className="w-full px-6">
          <ListboxButton className="flex w-full">
            <div className="flex-grow truncate">
              <div className="text-xxs text-left text-gray-400">{t('projectControl.invoiceRecipient')}</div>
              <div className="text-left font-bold pr-3 text-[15px] truncate">
                {selectedRecipient ? selectedRecipient : '-'}
              </div>
            </div>
            <ChevronDownIcon className="w-5 h-5 my-auto text-gray-600 flex-shrink-0" />
          </ListboxButton>
        </div>
        <ListboxOptions
          portal
          ref={setPopperElement}
          style={{ ...styles.popper }}
          {...attributes.popper}
          className="absolute z-50 right-0 mt-4 w-96 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <div className="divide-y divide-gray-100">
            {recipientOptions.map((option, i) => (
              <ListboxOption key={`invoice-recipient-option-${i}`} value={option}>
                {({ focus }) => (
                  <div
                    onClick={() => (isFetching ? undefined : handleSelectRecipient(option))}
                    className={classNames(
                      'text-gray-900 group flex rounded-none w-full py-3 px-4 text-sm cursor-pointer',
                      {
                        'bg-gray-100': focus,
                      },
                    )}
                  >
                    <div className="grow text-left w-7/12 pr-3 my-auto text-[15px] font-bold">
                      {option ? option : '-'}
                    </div>
                    <div className="w-12 text-right my-auto">
                      {selectedRecipient === option ? (
                        <SelectedCustomIcon className="w-5 h-5 ml-auto" />
                      ) : (
                        <UnselectedCustomIcon className="w-5 h-5 ml-auto" />
                      )}
                    </div>
                  </div>
                )}
              </ListboxOption>
            ))}
          </div>
        </ListboxOptions>
      </Listbox>
      <Listbox
        as="div"
        className={classNames(
          'w-60 h-10 flex items-center text-sm font-medium text-gray-600 bg-gray-400 bg-opacity-10 rounded-full hover:bg-opacity-20 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75',
        )}
      >
        <div ref={setTargetElement2} className="w-full px-6">
          <ListboxButton className="flex w-full">
            <div className="flex-grow truncate">
              <div className="text-xxs text-left text-gray-400">{t('projectControl.state')}</div>
              <div className="text-left font-bold pr-3 text-[15px] truncate">
                {selectedStatus ? selectedStatus : '-'}
              </div>
            </div>
            <ChevronDownIcon className="w-5 h-5 my-auto text-gray-600 flex-shrink-0" />
          </ListboxButton>
        </div>
        <ListboxOptions
          portal
          ref={setPopperElement2}
          style={{ ...styles2.popper }}
          {...attributes2.popper}
          className="absolute z-50 right-0 mt-4 w-96 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <div className="divide-y divide-gray-100">
            {statusOptions.map((option, i) => (
              <ListboxOption key={`invoice-state-option-${i}`} value={option}>
                {({ focus }) => (
                  <div
                    onClick={() => (isFetching ? undefined : handleSelectStatus(option))}
                    className={classNames(
                      'text-gray-900 group flex rounded-none w-full py-3 px-4 text-sm cursor-pointer',
                      {
                        'bg-gray-100': focus,
                      },
                    )}
                  >
                    <div className="grow text-left w-7/12 pr-3 my-auto text-[15px] font-bold">
                      {option ? option : '-'}
                    </div>
                    <div className="w-12 text-right my-auto">
                      {selectedStatus === option ? (
                        <SelectedCustomIcon className="w-5 h-5 ml-auto" />
                      ) : (
                        <UnselectedCustomIcon className="w-5 h-5 ml-auto" />
                      )}
                    </div>
                  </div>
                )}
              </ListboxOption>
            ))}
          </div>
        </ListboxOptions>
      </Listbox>
    </>
  );
};
