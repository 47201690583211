import {
  Button,
  HintBox, ICON_MAP_MARKER_IMAGE,
  LocationIcon,
  WidgetContainer,
  WidgetContainerContent,
  WidgetContainerTitle,
} from '@client/shared/toolkit';
import { WidgetConfig } from '../WidgetDashboard';
import { useTranslation } from 'react-i18next';
import { AdvancedMarker, APIProvider, Map, MapCameraChangedEvent, useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ProjectReadModel } from '@client/shared/api';
import { settings } from '@client/shared/store';
import { MapMarketPricesCard, PlaceFieldType, Poi, PoiRow, RenderPois } from './components';
import { XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { Layout } from 'react-grid-layout';

export interface DashboardWidgetMapViewProps {
  widget: WidgetConfig;
  projectData: ProjectReadModel;
  multiProject: boolean;
  layout?: Layout;
}

interface MapContainerProps {
  widget: WidgetConfig;
  mapId: string;
  projectData: ProjectReadModel;
  multiProject: boolean;
  layout?: Layout;
}

export interface PoisList {
  subwayStations: Poi[];
  busStops: Poi[];
  trainStations: Poi[];
  airports: Poi[];
  evChargingStations: Poi[];
  groceryStores: Poi[];
  coffeeShops: Poi[];
  restaurants: Poi[];
  touristAttractions: Poi[];
}

interface CachedPoiItem {
  expires: number;
  pois: PoisList;
}

export const MAP_POIS_STORAGE_KEY = "map_pois";

const MapContainer = (props: MapContainerProps) => {
  const { widget, mapId, projectData, multiProject, layout } = props;

  const map = useMap();
  const { t } = useTranslation();
  const placesLibrary = useMapsLibrary('places');
  const geometryLibrary = useMapsLibrary('geometry');
  const geocodingLibrary = useMapsLibrary('geocoding');
  const [pois, setPois] = useState<PoisList>({
    subwayStations: [],
    busStops: [],
    trainStations: [],
    airports: [],
    evChargingStations: [],
    groceryStores: [],
    coffeeShops: [],
    restaurants: [],
    touristAttractions: [],
  });

  const [mapAddress, setMapAddress] = useState("");
  const [showLocationDetails, setShowLocationDetails] = useState(true);
  const [mapShift, setMapShift] = useState(layout && layout.w > 1 ? 0.005 : 0); // shift map center by 500m

  const locationCoordinates = useMemo(() => {
    return {
      lat: widget.additionalConfig?.MapView?.lat ? parseFloat(widget.additionalConfig.MapView.lat) : projectData.payload.latitude || 0,
      lng: widget.additionalConfig?.MapView?.lng ? parseFloat(widget.additionalConfig.MapView.lng) : projectData.payload.longitude || 0,
    };
  }, [projectData.payload.latitude, projectData.payload.longitude, widget.additionalConfig?.MapView?.lat, widget.additionalConfig?.MapView?.lng]);

  const center = useMemo(() => {
    return {
      lat: locationCoordinates.lat,
      lng: Number((locationCoordinates.lng + mapShift).toFixed(6))
    };
  }, [locationCoordinates, mapShift]);

  const geocodeAddress = useCallback(async (location: google.maps.LatLngLiteral) => {
    if (!geocodingLibrary) return null;

    const geocoder = new geocodingLibrary.Geocoder();

    try {
      const geocoding = await geocoder.geocode({ location });

      if (geocoding.results) {
        return geocoding.results[0];
      } else {
        return null;
      }
    } catch (err) {
      console.log(err);
      return null;
    }
  }, [geocodingLibrary]);

  const getPoi = useCallback(async (
    category: string,
    types: string[],
    range: number,
    maxResults: number,
    extraFields: (keyof typeof PlaceFieldType)[] = [],
  ): Promise<Poi[]> => {
    if (!placesLibrary || !geometryLibrary) return [];

    const results = await placesLibrary.Place.searchNearby({
      fields: ['displayName', 'location', ...extraFields],
      locationRestriction: {
        center: locationCoordinates,
        radius: range,
      },
      includedTypes: types,
      maxResultCount: maxResults,
    });

    return results.places
      .filter((place) => place.location && place.displayName)
      .map((place) => {
        const poi: Poi = {
          category,
          name: place.displayName ?? '',
          distance: place.location
            ? (geometryLibrary.spherical.computeDistanceBetween(locationCoordinates, place.location) / 1000).toFixed(2)
            : ''
        };

        extraFields.forEach((extraField) => {
          if (category === 'evChargingStations') {
            const connectors = place[extraField]?.connectorAggregations.map((connector) => {
              return {
                count: connector.count,
                maxChargeRateKw: connector.maxChargeRateKw,
              };
            });

            poi[extraField] = connectors;
          } else {
            poi[extraField] = place[extraField];
          }
        });

        return poi;
      });
  }, [placesLibrary, geometryLibrary, locationCoordinates]);

  const cachePois = useCallback((key: string, pois: PoisList) => {
    const cachedPois = JSON.parse(localStorage.getItem(MAP_POIS_STORAGE_KEY) || "{}") as Record<string, CachedPoiItem>;

    if (!cachedPois[key]) {
      const expiryDate = new Date();
      expiryDate.setMonth(expiryDate.getMonth() + 3);

      cachedPois[key] = {
        expires: expiryDate.getTime(),
        pois
      };

      localStorage.setItem(MAP_POIS_STORAGE_KEY, JSON.stringify(cachedPois));
    }
  }, []);

  const checkExpiredPois = useCallback(() => {
    const cachedPois = localStorage.getItem(MAP_POIS_STORAGE_KEY);

    if (!cachedPois) return;

    const cachedPoisObj = JSON.parse(cachedPois) as Record<string, CachedPoiItem>;
    const now = new Date();
    const updatedPoisCacheObj: Record<string, CachedPoiItem> = {};

    for (const key in cachedPoisObj) {
      if (new Date(cachedPoisObj[key].expires) > now) {
          updatedPoisCacheObj[key] = cachedPoisObj[key];
      }
    }

    const updatedPoisCache = JSON.stringify(updatedPoisCacheObj);

    if (updatedPoisCache !== cachedPois) {
      localStorage.setItem(MAP_POIS_STORAGE_KEY, updatedPoisCache);
    }
  }, []);

  const fetchPoisFromCache = useCallback((key: string) => {
    return JSON.parse(localStorage.getItem(MAP_POIS_STORAGE_KEY) || "{}")[key];
  }, []);

  useEffect(() => {
    if (!placesLibrary || !geometryLibrary || !locationCoordinates.lat || !locationCoordinates.lng) return;

    const cacheItemKey = [locationCoordinates.lat, locationCoordinates.lng].join(":");
    const cachedPois = fetchPoisFromCache(cacheItemKey);

    const fetchPois = async () => {
      const [
        evChargingStations,
        busStops,
        trainStations,
        subwayStations,
        airports,
        groceryStores,
        coffeeShops,
        restaurants,
        touristAttractions,
      ] = await Promise.all([
        getPoi('evChargingStations', ['electric_vehicle_charging_station'], 2000, 2, ['evChargeOptions']),
        getPoi('busStops', ['bus_stop'], 1000, 2),
        getPoi('trainStations', ['train_station'], 2000, 2),
        getPoi('subwayStations', ['subway_station'], 1000, 2),
        getPoi('airports', ['airport'], 30000, 2),
        getPoi('groceryStores', ['grocery_store'], 1000, 2),
        getPoi('coffeeShops', ['coffee_shop'], 1000, 2),
        getPoi('restaurants', ['restaurant'], 1000, 2),
        getPoi('touristAttractions', ['tourist_attraction'], 1000, 2),
      ]);

      setPois({
        evChargingStations,
        busStops,
        trainStations,
        subwayStations,
        airports,
        groceryStores,
        coffeeShops,
        restaurants,
        touristAttractions,
      });

      checkExpiredPois();
      cachePois(cacheItemKey, {
        evChargingStations,
        busStops,
        trainStations,
        subwayStations,
        airports,
        groceryStores,
        coffeeShops,
        restaurants,
        touristAttractions,
      });
    };

    if (!cachedPois) {
      fetchPois();
    } else {
      setPois(cachedPois.pois);
      checkExpiredPois();
    }
  }, [placesLibrary, geometryLibrary, locationCoordinates, getPoi, cachePois, checkExpiredPois, fetchPoisFromCache]);

  useEffect(() => {
    if (!map || !center || !locationCoordinates) return;

    if (layout && layout.w > 1) {
      if (showLocationDetails) {
        map.panTo(new google.maps.LatLng({lat: locationCoordinates.lat, lng: Number((locationCoordinates.lng + mapShift).toFixed(6))}));
      } else {
        map.panTo(new google.maps.LatLng({lat: locationCoordinates.lat, lng: locationCoordinates.lng}));
      }
    }
  }, [map, showLocationDetails, locationCoordinates, center, layout, mapShift]);

  useEffect(() => {
    const fetchAddress = async () => {
      const address = await geocodeAddress(locationCoordinates);

      if (address) {
        setMapAddress(address.formatted_address);
        // setMapAddress(generateAddressName(address.address_components));
      }
    };

    if (locationCoordinates && geocodingLibrary) {
      fetchAddress();
    }
  }, [locationCoordinates, geocodingLibrary, geocodeAddress]);

  const adjustMapShift = (zoomChangeEvent: MapCameraChangedEvent) => {
    if (layout && layout.w > 1) {
      setMapShift(Number(pixelsToLatChange(200, zoomChangeEvent.detail.zoom).toFixed(3))); // adjust map shift by 200 px
    }
  };

  const pixelsToLatChange = (pixels: number, zoom: number) => {
    const degreesPerPixel = 360 / (256 * Math.pow(2, zoom));
    return pixels * degreesPerPixel;
  }

  // const generateAddressName = (addressComponents: google.maps.GeocoderAddressComponent[]) => {
  //   const componentsMap = addressComponents.reduce((acc, component) => {
  //     component.types.forEach((type) => {
  //       acc[type] = component.long_name;
  //     });
  //     return acc;
  //   }, {} as Record<string, string>);

  //   const streetNumber = componentsMap["street_number"] || "";
  //   const route = componentsMap["route"] || "";
  //   const postalCode = componentsMap["postal_code"] || "";
  //   const city = componentsMap["locality"] || "";

  //   return `${route}${streetNumber ? ` ${streetNumber}` : ""}, ${postalCode}, ${city}`;
  // };

  return !locationCoordinates.lat || !locationCoordinates.lng ? (
    <HintBox hintType="danger" title={t('dashboard.widget.mapView.missingCoordinatesError.title')}>
      {t('dashboard.widget.mapView.missingCoordinatesError.content')}
    </HintBox>
  ) : (
    <div className="h-full relative grid-item-disable-drag">
      <Map
        defaultCenter={center}
        defaultZoom={16}
        mapId={mapId}
        cameraControl={false}
        controlSize={25}
        mapTypeControl={false}
        fullscreenControl={false}
        streetViewControl={false}
        onZoomChanged={adjustMapShift}
        className="rounded-md overflow-hidden h-full w-full"
      >
        <AdvancedMarker position={locationCoordinates}>
          <img src={ICON_MAP_MARKER_IMAGE} width={32} height={32} alt="Map marker" />
        </AdvancedMarker>
      </Map>
      {showLocationDetails && layout && layout.h > 1 ? (
        <div className={classNames(
            "absolute bottom-5 bg-white rounded-md pt-1 pe-2 ps-2 overflow-y-auto rounded-md shadow-md",
            {
              'right-10 w-[50%] max-h-[90%]': layout?.w === 3,
              'right-1 left-1 max-h-[30%]': layout?.w === 1
            }
          )}>
          <div className="absolute right-0 m-2">
            <button onClick={() => setShowLocationDetails(false)}>
              <XMarkIcon className="w-6 h-6 hover:text-gray-600 transition-color duration-200 cursor-pointer" />
            </button>
          </div>
          {mapAddress && (
            <div className="pb-1 mb-1 border-b-2 border-slate-100">
              <PoiRow
                icon={<LocationIcon />}
                content={mapAddress}
                header={<span className="font-light text-sm">{t('common.address')}</span>}
              />
            </div>
          )}
          <RenderPois pois={pois} />
          <div className="w-full py-1">
            <MapMarketPricesCard multiProject={multiProject} />
          </div>
        </div>
      ) : (layout && layout.h > 1 ? (
            <Button variant="primary" className="absolute top-4 right-4 shadow-md" onClick={() => setShowLocationDetails(true)}>
              {t('project.showLocationDetails')}
            </Button>
          ) : null)}
    </div>
  );
};

export const DashboardWidgetMapView = (props: DashboardWidgetMapViewProps) => {
  const { widget, projectData, multiProject, layout } = props;
  const { t } = useTranslation();

  return (
    <WidgetContainer className="flex flex-col h-full justify-center">
      <WidgetContainerTitle>{widget.title ? widget.title : t('dashboard.widget.mapView.title')}</WidgetContainerTitle>
      <WidgetContainerContent className="flex-1">
        {settings.googleMapsApiKey && widget.id ? (
          <APIProvider apiKey={settings.googleMapsApiKey}>
            <MapContainer widget={widget} mapId={widget.id} projectData={projectData} multiProject={multiProject} layout={layout} />
          </APIProvider>
        ) : (
          <HintBox hintType="danger" title={t('dashboard.widget.mapView.missingApiKeyError.title')}>
            {t('dashboard.widget.mapView.missingApiKeyError.content')}
          </HintBox>
        )}
      </WidgetContainerContent>
    </WidgetContainer>
  );
};
