import { setVariantId } from '@client/project/store';
import { CalculationModelMetadata, useApiPostCreateVersionFromScenarioMutation } from '@client/shared/api';
import { Button, HintBox, Modal, ModalOnCloseProps, LoadingIndicator } from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';
import { useGenericDispatch } from '@client/shared/store';

interface CreateVersionFromScenarioModalProps extends ModalOnCloseProps {
  variant: CalculationModelMetadata;
}

export const CreateVersionFromScenarioModal = ({ onClose, variant }: CreateVersionFromScenarioModalProps) => {
  const { t } = useTranslation();
  const dispatch = useGenericDispatch();

  const [call, { isLoading }] = useApiPostCreateVersionFromScenarioMutation();

  const handleOnClose = () => {
    onClose(false);
  };

  const handleCreateVersion = async () => {
    try {
      const result = await safeMutation(
        call,
        {
          projectId: variant.projectId,
          calculationModelId: variant.id,
        },
        isLoading,
      );

      if (result?.versionId) {
        dispatch(setVariantId(result.versionId));
        /* const variantLink = `${ROUTES_CONFIG.PROJECT_VARIANTS.path.replace(':id', loadedProjectId ?? '')}?vid=${
          result.versionId ?? ''
        }`;
        navigate(variantLink);*/
      }

      onClose(true);
    } catch {
      /* left blank */
    }
  };

  return (
    <>
      {isLoading && <LoadingIndicator text={t('common.loading')} mode="overlay-window" />}
      <Modal.Header title={t('projectVariants.variantCreateVersionTitle')} />
      <Modal.Content>
        <HintBox>{t('projectVariants.variantCreateVersionHint')}</HintBox>
      </Modal.Content>
      <Modal.Controls className="bg-white">
        <Button onClick={handleOnClose} className="mr-2" variant="secondary">
          {t('common.cancel')}
        </Button>
        <Button onClick={handleCreateVersion}>{t('projectVariants.variantCreateVersionTitle')}</Button>
      </Modal.Controls>
    </>
  );
};
