import { api } from '@client/shared/api';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { finishAppInit, startAppInit, startChallenge } from '@client/shared/store';
import { i18n } from '@client/shared/utilities';
import toast from 'react-hot-toast';
import { fork, put, take, takeEvery } from 'redux-saga/effects';

/**
 * The main method coordinating the application "startup".
 * If you need to execute any logic before the user can actually do something,
 * this is the right place.
 */
function* initializeApp(): Generator {
  yield take(startAppInit);
  const allRoutes = Object.values(ROUTES_CONFIG);
  const isPublicRoute = allRoutes.find((route) => typeof route.public !== 'undefined' && route.public && window.location.pathname.includes(route.path));

  if (!isPublicRoute) {
    yield put(
      api.endpoints.apiPostRefreshToken.initiate(undefined, {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }) as any);
  } else if (window.location.pathname.includes(ROUTES_CONFIG.SSO.path)) {
    yield put(startChallenge());
    yield put(
      api.endpoints.apiGetChallenge.initiate(
        {
          returnUrl: window.location.pathname,
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ) as any,
    );
  } else {
    yield put(finishAppInit());
  }
}

function* onPasswordChange() {
  yield takeEvery([api.endpoints.apiPostUpdatePassword.matchFulfilled], function* () {
    toast.success(i18n.t('app.notificationPasswordChanged'));
    yield;
  });

  yield takeEvery([api.endpoints.apiPostUpdatePassword.matchRejected], function* () {
    toast.error(i18n.t('app.notificationPasswordChangeFailed'));
    yield;
  });
}

export function* appSaga() {
  yield fork(initializeApp);
  yield fork(onPasswordChange);
}
