import { useApiGetCalculationModelEarningsQuery } from '@client/shared/api';
import { useLoadedVariantId } from './useLoadedVariantId';
import { useLoadedProjectId } from './useLoadedProjectId';
import { useHasLicenseFeature, useValidateProjectPermission } from '@client/shared/permissions';

export const useLoadedVariantEarnings = () => {
  const loadedVariantId = useLoadedVariantId();
  const loadedProjectId = useLoadedProjectId();
  const hasCalculateLicenseFeature = useHasLicenseFeature('CALCULATE');
  const hasEarningsReadPermission = useValidateProjectPermission(['EARNINGS_READ'], loadedProjectId ?? '');

  return useApiGetCalculationModelEarningsQuery(
    {
      projectId: loadedProjectId ?? 'unset',
      calculationModelId: loadedVariantId ?? 'unset'
    },
    {
      skip: loadedVariantId == null || !hasCalculateLicenseFeature || !hasEarningsReadPermission,
    }
  );
};
