import {
  PlotReadModel,
  PlotType,
  useApiPostCreatePlotMutation,
  useApiPostUpdatePlotMutation,
} from '@client/shared/api';
import { TagIcon } from '@heroicons/react/24/outline';
import {
  TextInput,
  NumberInput,
  SlideOver,
  Button,
  BaseSelect,
  Form,
  FormField,
  EditNodeIcon,
  SlideOverOnCloseProps,
  Modal,
  FormWatch, MapMarkerDottedIcon, LoadingIndicator,
  ComboSelect,
  DocumentIcon,
} from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { PlotElementFormValidationValues, PlotElementFormValidationSchema } from './PlotElementFormValidationSchema';
import { useLoadedProjectUnitSystemSymbol, useLoadedProjectId, useLoadedVariantId, getAssetClasses, useLoadedAssetClasses } from '@client/project/store';
import { useState, useRef, useMemo } from 'react';
import { PlotDeleteModal } from './PlotDeleteModal';

interface PlotElementSlideOverProps extends SlideOverOnCloseProps {
  item?: PlotReadModel;
  isReadOnly: boolean;
  canDelete: boolean;
}

export const PlotElementSlideOver = ({ onClose, item, isReadOnly, canDelete }: PlotElementSlideOverProps) => {
  const { t } = useTranslation();
  const submitRef = useRef<HTMLButtonElement>(null);

  const isEditing = !!item;
  const isMainPlot = item?.plots != null && item.plots.length > 0;

  const projectId = useLoadedProjectId();

  const unitSystem = useLoadedProjectUnitSystemSymbol();

  const availableAssetClasses = useLoadedAssetClasses();
  const assetClassOptions = useMemo(() => getAssetClasses(availableAssetClasses),[availableAssetClasses]);  

  const plotTypeOptions = [
    {
      label: t('projectTaxonomy.plotType.projectRoom'),
      value: 'Plot',
    },
    {
      label: t('projectTaxonomy.plotType.Plot'),
      value: 'SpecialItem',
    },
    {
      label: t('projectTaxonomy.plotType.Infrastructure'),
      value: 'Infrastructure',
    },
  ];

  const loadedVariantId = useLoadedVariantId();

  const [postPlotPiece, { isLoading: isCreating }] = useApiPostCreatePlotMutation();
  const [updatePlotPiece, { isLoading: isUpdating }] = useApiPostUpdatePlotMutation();

  const [isOpenDeletePlotModal, setIsOpenDeletePlotModal] = useState(false);

  const handleCreateNewPlotPiece = async (data: PlotElementFormValidationValues) => {
    if (loadedVariantId == null) return;

    try {
      await safeMutation(
        postPlotPiece,
        {
          projectId: projectId ?? 'unset',
          calculationModelId: loadedVariantId,
          body: {
            name: data.name,
            size: Number(data.size) ?? 0,
            constructionSize: data.constructionSize,
            type: data.type as PlotType,
            grossVolume: data.grossVolume ?? 0,
            cost: data.cost ?? 0,
            selectedAssetClassId: data.assetClass,
            description: data.description,
            street: data.street,
            parcelNumber: data.parcelNumber,
          },
        },
        isCreating
      );

      onClose(true);
    } catch (e) {
      console.log(e);
    }
  };

  const handleEditingPlotPiece = async (data: PlotElementFormValidationValues) => {
    if (loadedVariantId == null || item?.plotId == null) return;

    try {
      await safeMutation(
        updatePlotPiece,
        {
          projectId: projectId ?? 'unset',
          calculationModelId: loadedVariantId,
          plotId: item.plotId,
          body: {
            name: data.name,
            size: Number(data.size) ?? 0,
            constructionSize: data.constructionSize,
            type: data.type as PlotType,
            grossVolume: data.grossVolume,
            cost: data.cost,
            selectedAssetClassId: data.assetClass,
            description: data.description === '' ? undefined : data.description,
            street: data.street === '' ? undefined : data.street,
            parcelNumber: data.parcelNumber === '' ? undefined : data.parcelNumber,
          },
        },
        isUpdating
      );

      onClose(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = isEditing ? handleEditingPlotPiece : handleCreateNewPlotPiece;

  const defaultFormValues = {
    name: item?.plotName ?? '',
    size: item?.totalPlotSize.value ?? 0,
    constructionSize: item?.constructionSize.value ?? undefined,
    type: item?.type ?? '',
    grossVolume: item?.grossVolume.value ?? undefined,
    cost: item?.cost.value ?? undefined,
    assetClass: item?.assetClass?.id ?? undefined,
    description: item?.description ?? '',
    street: item?.street ?? '',
    parcelNumber: item?.parcelNumber ?? '',
  };

  const handleOnCloseDeleteModal = () => {
    onClose(true);
    setIsOpenDeletePlotModal(false);
  };

  return (
    <>
      {(isCreating || isUpdating) && (
        <LoadingIndicator
          text={
            isCreating
              ? t('projectTaxonomy.creatingTaxonomyLoadingIndicator')
              : t('projectTaxonomy.updatingTaxonomyLoadingIndicator')
          }
          mode="overlay-window"
        />
      )}
      <SlideOver.Header
        onClose={onClose}
        title={item?.plotName ?? t('projectTaxonomy.newPlot')}
        subTitle={t('projectTaxonomy.newPlotDescription')}
        backgroundClassName="bg-gray-900"
      />

      <Form<PlotElementFormValidationValues>
        onSubmit={handleSubmit}
        validationSchema={PlotElementFormValidationSchema}
        defaultValues={defaultFormValues}
        className="flex flex-col flex-grow min-h-0"
      >
        <SlideOver.Content>
          <div className="m-8 bg-white">
            <div className="divide-gray-100 divide-y">
              <FormField name="type">
                {(control) => (
                  <BaseSelect
                    label={t('projectTaxonomy.newPlotLabelPlotType')}
                    options={plotTypeOptions}
                    icon={<MapMarkerDottedIcon />}
                    disabled={isReadOnly}
                    {...control}
                  />
                )}
              </FormField>
              <FormField name="name">
                {(control) => (
                  <TextInput
                    label={t('projectTaxonomy.newPlotLabelName')}
                    icon={<EditNodeIcon />}
                    disabled={isReadOnly}
                    {...control}
                  />
                )}
              </FormField>
              <FormWatch<PlotElementFormValidationValues> fieldNames={['type']}>
                {({ type }) => (
                  <FormField name="assetClass">
                    {(control) => (
                      <ComboSelect
                        label={t('projectTaxonomy.addBuildingElementLabelUsageType')}
                        options={assetClassOptions}
                        icon={<DocumentIcon />}
                        disabled={type !== 'SpecialItem'}
                        {...control}
                      />
                    )}
                  </FormField>
                )}
              </FormWatch>
              <FormField name="street">
                {(control) => (
                  <TextInput
                    label={t('projectTaxonomy.newPlotLabelStreet')}
                    icon={<TagIcon />}
                    disabled={isReadOnly}
                    {...control}
                  />
                )}
              </FormField>
              <FormField name="parcelNumber">
                {(control) => (
                  <TextInput
                    label={t('projectTaxonomy.newPlotLabelParcelNr')}
                    icon={<TagIcon />}
                    disabled={isReadOnly}
                    {...control}
                  />
                )}
              </FormField>
              <FormField name="description">
                {(control) => (
                  <TextInput
                    label={t('projectTaxonomy.newPlotLabelDescription')}
                    icon={<TagIcon />}
                    disabled={isReadOnly}
                    {...control}
                  />
                )}
              </FormField>
              <FormField name="size">
                {(control) => (
                  <NumberInput
                    label={`${t('projectTaxonomy.newPlotLabelPlotSize')} (${
                      item?.totalPlotSize.unit ?? (unitSystem)
                    })`}
                    icon={<TagIcon />}
                    disabled={isReadOnly}
                    {...control}
                  />
                )}
              </FormField>
              <FormField name="constructionSize">
                {(control) => (
                  <NumberInput
                    label={`${t('projectTaxonomy.newPlotLabelConstructionSize')} (${
                      item?.constructionSize.unit ?? (unitSystem)
                    })`}
                    icon={<TagIcon />}
                    disabled={isReadOnly}
                    {...control}
                  />
                )}
              </FormField>
              <FormField name="grossVolume">
                {(control) => (
                  <NumberInput
                    label={`${t('projectTaxonomy.newPlotLabelGrossVolume')} (${
                      item?.grossVolume.unit ?? (unitSystem)
                    })`}
                    icon={<TagIcon />}
                    disabled={isReadOnly}
                    {...control}
                  />
                )}
              </FormField>
              <FormField name="cost">
                {(control) => (
                  <NumberInput
                    label={t('projectTaxonomy.newPlotLabelCost')}
                    icon={<TagIcon />}
                    disabled={isReadOnly}
                    {...control}
                  />
                )}
              </FormField>
            </div>
          </div>
        </SlideOver.Content>
        <SlideOver.Controls>
          <div className={classNames('flex w-full', isEditing ? 'justify-between' : 'justify-end')}>
            {isEditing && !isMainPlot && canDelete && (
              <Button variant="warning" onClick={() => setIsOpenDeletePlotModal(true)}>
                {t('common.delete')}
              </Button>
            )}
            <div className="flex">
              <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
                {t('common.cancel')}
              </Button>
              {!isReadOnly && (
                <Button variant="primary" formSubmit={true} innerRef={submitRef}>
                  {isEditing ? t('common.save') : t('common.create')}
                </Button>
              )}
            </div>
          </div>
        </SlideOver.Controls>
      </Form>

      {item && (
        <Modal isOpen={isOpenDeletePlotModal} onClose={() => handleOnCloseDeleteModal()}>
          <PlotDeleteModal item={item} onClose={() => handleOnCloseDeleteModal()} />
        </Modal>
      )}
    </>
  );
};
