import * as yup from 'yup';
import { InferType } from 'yup';

export const ContractTitlePositionEditFormValidationSchema = yup.object({
  code: yup.string().required('validation.required'),
  name: yup.string().required('validation.required'),
  unit: yup.string().required('validation.required'),
  amount: yup.number().required('validation.required'),
  unitPrice: yup.number().required('validation.required'),
  unitPriceCurrency: yup.string().optional(),
  vat: yup
    .number()
    .typeError('validation.number')
    .test('range', 'validation.rangeHundred', (value) => (value as number) >= 0 && (value as number) <= 100)
    .required('validation.required'),
});

export type ContractPositionEditFormValidationValues = InferType<typeof ContractTitlePositionEditFormValidationSchema>;
