import { Trans, useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  BG_LOGIN_SCREEN_IMAGE,
  HintBox,
  LoadingIndicator,
  LOGIN_ORNAMENT_IMAGE,
  LOGO_ICON_IMAGE,
  LOGO_TEXT_IMAGE,
  useDocumentTitle
} from '@client/shared/toolkit';
import { useDatevAuthState } from '@client/project/store';
import { settings } from '@client/shared/store';
import React, { useEffect, useMemo } from 'react';
import { ROUTES_CONFIG } from '@client/shared/permissions';

/**
 * This route is called by Datev as redirect url.
 */
export const DatevCallbackPublicRoute = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  useDocumentTitle({ title: t('app.menuDatevCallback') });
  const datevAuthState = useDatevAuthState();
  const tenantSubdomain = datevAuthState?.datevTenantSubdomain;
  const clientId = datevAuthState?.datevClientId;
  const updateData = datevAuthState?.datevUpdateData;

  useEffect(() => {
    if (tenantSubdomain && tenantSubdomain !== '') {
      const target = `${window.location.protocol}//${tenantSubdomain}.${settings.mainDomain}:${window.location.port}${ROUTES_CONFIG.DATEV_CALLBACK.path}`;
      const searchParams = new URLSearchParams(window.location.search);
      window.location.href = `${target}${searchParams ? '?' + searchParams : ''}`;
    } else {
      navigate('/auth/login');
    }
  }, [tenantSubdomain, navigate]);

  const linkText = useMemo(() => {
    let linkText = t('app.settingsApiDatevMarketplaceLinkText');
    if (updateData?.projectId) {
      linkText = t('app.settingsApiDatevProjectsLinkText');
    }
    return linkText;
  }, [t, updateData?.projectId]);

  const marketPlaceLink = useMemo(() => {
    let toLink = ROUTES_CONFIG.SETTINGS_CONNECT_DATEV.path;
    if (updateData && clientId) {
      toLink = ROUTES_CONFIG.SETTINGS_CONNECT_DATEV_EDIT.path.replace(':id', updateData.apiAccessId);
      if (updateData.projectId) {
        toLink = updateData.projectView === 'Dashboard' ? `${ROUTES_CONFIG.PROJECT_DASHBOARD_SETTINGS.path.replace(':id', updateData.projectId)}?tab=connections` : `${ROUTES_CONFIG.PROJECTS_PROJECT_SETTINGS.path.replace(':id', updateData.projectId)}?tab=connections`;
      }
    }
    return (
      <NavLink className="font-bold underline" to={toLink}>
        {linkText}
      </NavLink>
    );
  }, [updateData, clientId, linkText]);

  return (
    <div className="flex-grow flex items-center justify-center bg-cover relative" style={{ backgroundImage: `url('${BG_LOGIN_SCREEN_IMAGE}')` }}>
      <div className="bg-black absolute left-0 right-0 top-0 bottom-0 opacity-25" />
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-2xl z-50">
        <div className="flex w-full shadow sm:rounded-xl bg-gray-100 overflow-hidden">
          <div className="w-2/6 min-h-full px-2 bg-[#334155] text-white flex flex-col items-center bg-bottom bg-no-repeat bg-contain" style={{ backgroundImage: `url('${LOGIN_ORNAMENT_IMAGE}')` }}>
            <div className="flex flex-col flex-grow items-center">
              <img src={LOGO_ICON_IMAGE} className="-mt-16 absolute" alt="Logo Icon" />
              <img src={LOGO_TEXT_IMAGE} className="mt-24" alt="Logo Text" />
            </div>
            <div className="text-sm pb-4"></div>
          </div>
          <div className="py-8 px-4 sm:px-10 w-4/6">
            {tenantSubdomain ? (
              <LoadingIndicator mode="overlay-window" text={t('app.settingsApiDatevWaitForRedirect')} />
            ) : (
              <HintBox hintType="info" title={t('app.settingsApiDatevInfo')}>
                <Trans i18nKey="app.settingsApiDatevRedirecting" values={{ linkText: linkText }}>
                  You will be redirected. If redirect does not happen in a few seconds, please go to {marketPlaceLink} and try to to establish a
                  connection again.
                </Trans>
              </HintBox>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
