import {
  BaseSelect,
  // BooleanInput,
  BrowserIcon,
  Button,
  CircleNotchIcon,
  ConnectedIcon,
  Form,
  FormField,
  FormRefHandle,
  FormWatch,
  Modal,
  SlideOver,
  SlideOverOnCloseProps,
  SlideOverTitle,
  // SortingArrowsIcon,
  TextInput,
} from '@client/shared/toolkit';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DatevProperty,
  SynchronizingObjectType,
  useApiAddYardiProbisProjectMappingMutation,
  useApiDeleteYardiProbisProjectMappingMutation,
  useApiUpdateYardiProbisProjectMappingMutation,
  YardiProbisMappingReadModel,
  YardiProjectConnectionReadModel,
} from '@client/shared/api';
import { safeMutation } from '@client/shared/utilities';
import classNames from 'classnames';
import {
  ApiClientMappingDeleteModal,
  YardiMappingAddFormValidationValues,
  YardiMappingAddFormValidationSchema,
  API_MAPPING,
} from '..';

interface YardiMappingAddSlideOverProps extends SlideOverOnCloseProps {
  isOpen: boolean;
  item?: YardiProbisMappingReadModel | null;
  apiAccessId?: string;
  projectId?: string;
  connectionData: YardiProjectConnectionReadModel | undefined | null;
}

export const YardiMappingAddSlideOver = ({
  isOpen,
  item,
  onClose,
  apiAccessId,
  projectId,
  connectionData,
}: YardiMappingAddSlideOverProps) => {
  const { t } = useTranslation();

  const isEdit = item !== null;

  const formRef = useRef<FormRefHandle<YardiMappingAddFormValidationValues>>();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  const [addProjectMapping, { isLoading: isAddingProjectMapping }] = useApiAddYardiProbisProjectMappingMutation();
  const [updateProjectMapping, { isLoading: isUpdatingProjectMapping }] =
    useApiUpdateYardiProbisProjectMappingMutation();
  const [yardiPropertyType, setYardiPropertyType] = useState<string>('');
  const [submitted, setSubmitted] = useState(false);

  const [objectTypeReference, setObjectTypeReference] = useState<string>(item?.type.toLowerCase() ?? '');

  const [probisPropertyOptions, setProbisPropertyOptions] = useState<{ label: string; value: string }[]>([]);
  const [userDefinedFieldOptions, setUserDefinedFieldOptions] = useState<{ label: string; value: string }[]>([]);

  const [selectedYardiProperty, setSelectedYardiProperty] = useState<DatevProperty | undefined>(undefined);

  const inputOptions = [
    { value: API_MAPPING.PROBIS_PROPERTY_NAME, label: t('app.settingsApiDatevConnectionProbisObjectId') },
    { value: API_MAPPING.USER_DEFINED_FIELD_NAME, label: t('app.settingsApiDatevConnectionCustomField') },
    { value: API_MAPPING.DEFAULT_VALUE, label: t('app.settingsApiDatevConnectionDefaultValue') },
  ];

  const objectTypeOptions = useMemo(() => {
    const options = connectionData?.probisProperties.probisProperties
      ? Object.keys(connectionData?.probisProperties.probisProperties).map((objectType) => {
          return {
            label: objectType,
            value: objectType,
          };
        })
      : [];

    if (item?.type && !options.find((o) => o.value === item?.type)) {
      options.push({ label: item?.type, value: item?.type });
    }
    return options;
  }, [connectionData?.probisProperties.probisProperties, item?.type]);

  const yardiPropertyTypeOptions = useMemo(() => {
    return connectionData?.yardiProperties
      ? Object.entries(connectionData?.yardiProperties).map(([key, yardiPropertyType]) => {
          return {
            label: yardiPropertyType.name,
            value: key,
          };
        })
      : [];
  }, [connectionData]);

  useEffect(() => {
    if (connectionData?.yardiProperties && item?.yardiPropertyName) {
      const yardiPropertyType = Object.entries(connectionData?.yardiProperties)?.find(
        ([key, yardiProperty]) => yardiProperty.name === item?.yardiPropertyName,
      );

      setYardiPropertyType(yardiPropertyType?.[0] ?? '');
    }
  }, [connectionData, connectionData?.yardiProperties, item?.yardiPropertyName]);

  const handleOnClose = (confirmed: boolean) => {
    setSubmitted(false);
    setYardiPropertyType('');
    setObjectTypeReference('');
    setSelectedYardiProperty(undefined);
    onClose(confirmed);
  };

  const handleSubmit = async (values: YardiMappingAddFormValidationValues) => {
    try {
      const objectType = values.objectType as SynchronizingObjectType;

      if (isEdit && projectId) {
        await safeMutation(
          updateProjectMapping,
          {
            projectId: projectId ?? '',
            mappingId: item?.id ?? '',
            body: {
              name: values.name,
              description: values.description,
              objectType: objectType,
              yardiPropertyName: values.yardiPropertyName,
              probisPropertyName:
                values.selectedInput === API_MAPPING.PROBIS_PROPERTY_NAME ? values.probisPropertyName : null,
              userDefinedFieldName:
                values.selectedInput === API_MAPPING.USER_DEFINED_FIELD_NAME ? values.userDefinedFieldName : null,
              defaultValue: values.selectedInput === API_MAPPING.DEFAULT_VALUE ? values.defaultValue : null,
            },
          },
          isUpdatingProjectMapping,
        );
      } else if (!isEdit && projectId) {
        await safeMutation(
          addProjectMapping,
          {
            projectId: projectId ?? '',
            body: {
              name: values.name,
              description: values.description,
              objectType: objectType,
              yardiPropertyName: values.yardiPropertyName,
              probisPropertyName:
                values.selectedInput === API_MAPPING.PROBIS_PROPERTY_NAME ? values.probisPropertyName : null,
              userDefinedFieldName:
                values.selectedInput === API_MAPPING.USER_DEFINED_FIELD_NAME ? values.userDefinedFieldName : null,
              defaultValue: values.selectedInput === API_MAPPING.DEFAULT_VALUE ? values.defaultValue : null,
            },
          },
          isAddingProjectMapping,
        );
      }

      handleOnClose(true);
    } catch (e) {
      console.log(e);
    }
  };

  const [deleteProjectMapping, { isLoading: isDeletingProjectMapping }] =
    useApiDeleteYardiProbisProjectMappingMutation();

  const deleteItem = async (itemId: string) => {
    try {
      if (item?.projectId) {
        await safeMutation(
          deleteProjectMapping,
          {
            projectId: item.projectId ?? '',
            mappingId: item?.id ?? '',
          },
          isDeletingProjectMapping,
        );
      }
      setIsOpenDeleteModal(false);
      onClose(true);
    } catch {
      /* left blank */
    }
  };

  const defaultFormValues: YardiMappingAddFormValidationValues = {
    name: item?.name ?? '',
    description: item?.description ?? '',
    objectType: item?.type ?? '',
    yardiPropertyName: item?.yardiPropertyName ?? '',
    selectedInput: item?.defaultValue
      ? API_MAPPING.DEFAULT_VALUE
      : item?.userDefinedFieldName
        ? API_MAPPING.USER_DEFINED_FIELD_NAME
        : API_MAPPING.PROBIS_PROPERTY_NAME,
    probisPropertyName: item?.probisPropertyName ?? '',
    userDefinedFieldName: item?.userDefinedFieldName ?? '',
    defaultValue: item?.defaultValue ?? '',
  };

  return (
    <SlideOver isOpen={isOpen} onClose={handleOnClose}>
      <SlideOver.Header
        title={isEdit ? item?.name ?? '' : t('app.settingsApiDatevAddConnection')}
        subTitle={t('app.settingsApiConnection')}
        backgroundClassName="bg-stone-800"
        onClose={handleOnClose}
      />
      <Form<YardiMappingAddFormValidationValues>
        validationSchema={YardiMappingAddFormValidationSchema}
        defaultValues={defaultFormValues}
        ref={formRef}
        onSubmit={handleSubmit}
        className="w-full flex flex-col flex-grow min-h-0"
      >
        <SlideOver.Content className="p-8">
          <FormField name="name">
            {(control) => (
              <TextInput
                label={t('common.name')}
                icon={<BrowserIcon />}
                disabled={item?.isMandatoryElement}
                {...control}
              />
            )}
          </FormField>

          <FormField name="description">
            {(control) => <TextInput label={t('common.description')} icon={<BrowserIcon />} {...control} />}
          </FormField>

          <FormField name="objectType">
            {(control) => (
              <BaseSelect
                label={t('app.settingsApiDatevSynchronisationType')}
                icon={<CircleNotchIcon className="-rotate-45" />}
                options={objectTypeOptions}
                disabled={item?.isMandatoryElement || isEdit}
                {...control}
              />
            )}
          </FormField>

          <SlideOverTitle title={t('common.details')} />

          <BaseSelect
            label={t('app.settingsApiYardiProperty')}
            icon={<CircleNotchIcon className="-rotate-45" />}
            options={yardiPropertyTypeOptions}
            disabled={item?.isMandatoryElement}
            value={yardiPropertyType}
            onChange={(value) => {
              const yardiProperty = connectionData?.yardiProperties[
                value as keyof typeof connectionData.yardiProperties
              ] as DatevProperty;
              setYardiPropertyType(value);
              setSelectedYardiProperty(yardiProperty);
              formRef.current?.setValue(API_MAPPING.YARDI_PROPERTY_NAME, yardiProperty?.name ?? '');
            }}
            showValidation={submitted}
            helperText={submitted && yardiPropertyType === '' ? t('validation.required') : undefined}
            isValidationValid={yardiPropertyType !== ''}
          />

          <FormField name="selectedInput">
            {(control) => (
              <BaseSelect
                label={t('app.settingsApiDatevConnectionProbisPropertyType')}
                icon={<ConnectedIcon />}
                options={inputOptions}
                className="mt-5"
                {...control}
              />
            )}
          </FormField>

          <FormWatch
            onChange={({ objectType }) => {
              if (objectType && objectTypeReference !== objectType) {
                const { probisPropertyName = '', userDefinedFieldName = '' } = formRef.current?.getValues() || {};

                const currentProperties =
                  connectionData?.probisProperties?.probisProperties[
                    objectType as keyof typeof connectionData.probisProperties.probisProperties
                  ]?.properties || [];
                const currentUdf =
                  connectionData?.probisProperties?.probisProperties[
                    objectType as keyof typeof connectionData.probisProperties.probisProperties
                  ]?.userDefinedFields || [];

                if (!currentProperties.includes(probisPropertyName)) {
                  formRef.current?.setValue(API_MAPPING.PROBIS_PROPERTY_NAME, '');
                }

                if (!currentUdf.includes(userDefinedFieldName)) {
                  formRef.current?.setValue(API_MAPPING.USER_DEFINED_FIELD_NAME, '');
                }

                setProbisPropertyOptions(currentProperties.map((property) => ({ label: property, value: property })));
                setUserDefinedFieldOptions(currentUdf.map((udf) => ({ label: udf, value: udf })));
                setObjectTypeReference(objectType);
              }
            }}
            fieldNames={['objectType', 'selectedInput']}
          >
            {({ objectType, selectedInput }) => (
              <>
                {selectedInput === API_MAPPING.PROBIS_PROPERTY_NAME && (
                  <FormField name={API_MAPPING.PROBIS_PROPERTY_NAME}>
                    {(control) => (
                      <BaseSelect
                        label={t('app.settingsApiDatevConnectionProbisObjectId')}
                        icon={<ConnectedIcon />}
                        options={probisPropertyOptions}
                        disabled={objectType === ''}
                        {...control}
                      />
                    )}
                  </FormField>
                )}
                {selectedInput === API_MAPPING.USER_DEFINED_FIELD_NAME && (
                  <FormField name={API_MAPPING.USER_DEFINED_FIELD_NAME}>
                    {(control) => (
                      <BaseSelect
                        label={t('app.settingsApiDatevConnectionCustomField')}
                        icon={<ConnectedIcon />}
                        options={userDefinedFieldOptions}
                        {...control}
                      />
                    )}
                  </FormField>
                )}
                {selectedInput === API_MAPPING.DEFAULT_VALUE &&
                  (selectedYardiProperty?.options.length === 0 || !selectedYardiProperty) && (
                    <FormField name={API_MAPPING.DEFAULT_VALUE}>
                      {(control) => (
                        <TextInput
                          label={t('app.settingsApiDatevConnectionDefaultValue')}
                          icon={<ConnectedIcon />}
                          {...control}
                        />
                      )}
                    </FormField>
                  )}
                {selectedInput === API_MAPPING.DEFAULT_VALUE &&
                  (selectedYardiProperty && selectedYardiProperty?.options.length > 0) && (
                    <FormField name={API_MAPPING.DEFAULT_VALUE}>
                      {(control) => (
                        <BaseSelect
                          label={t('app.settingsApiDatevConnectionDefaultValue')}
                          icon={<ConnectedIcon />}
                          options={
                            selectedYardiProperty?.options.map((o) => {
                              return {
                                label: o,
                                value: o,
                              };
                            }) ?? []
                          }
                          {...control}
                        />
                      )}
                    </FormField>
                  )}
              </>
            )}
          </FormWatch>
        </SlideOver.Content>
        <SlideOver.Controls
          className={classNames('w-full flex', {
            'justify-between': isEdit,
            'justify-end': !isEdit,
          })}
        >
          {isEdit && (
            <Button variant="danger" onClick={() => setIsOpenDeleteModal(true)} disabled={item?.isMandatoryElement}>
              {t('common.delete')}
            </Button>
          )}
          <div className="flex gap-3">
            <Button variant="secondary" onClick={() => handleOnClose(false)}>
              {t('common.close')}
            </Button>
            <Button variant="primary" formSubmit={true} onClick={() => setSubmitted(true)}>
              {t('common.save')}
            </Button>
          </div>
        </SlideOver.Controls>
      </Form>
      <Modal isOpen={isOpenDeleteModal} onClose={setIsOpenDeleteModal}>
        <ApiClientMappingDeleteModal
          onClose={setIsOpenDeleteModal}
          itemId={item?.id ?? ''}
          itemName={item?.name ?? ''}
          deleteItem={deleteItem}
          isLoading={isDeletingProjectMapping}
        />
      </Modal>
    </SlideOver>
  );
};
