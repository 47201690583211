import { BenchmarkingReport } from '@client/reports/shared';
import { useApiGetBenchmarkReportConfigurationsQuery, useApiGetSelectableProjectsQuery } from '@client/shared/api';
import { LoadingIndicator } from '@client/shared/toolkit';

export const BenchmarkingContainer = () => {
  const { data: configs, isFetching: isFetchingConfig } = useApiGetBenchmarkReportConfigurationsQuery();
  const { data: projectsData, isFetching: isFetchingProjects } = useApiGetSelectableProjectsQuery();
  return (
    <div className="flex-grow flex flex-col overflow-hidden">
      <div
        id="pdf-content"
        className="p-8 flex-1 flex flex-col flex-grow overflow-y-auto pdf-export-overflow-hidden min-h-full"
      >
        {(isFetchingConfig || isFetchingProjects) && <LoadingIndicator mode="overlay-window" />}
        <BenchmarkingReport config={configs && configs.length ? configs[0] : undefined} projects={projectsData?.projects} />
      </div>
    </div>
  );
};
