import React, { PropsWithChildren, ReactNode } from 'react';
import cn from 'classnames';
import classNames from 'classnames';

interface SettingsListItemProps extends PropsWithChildren {
  onClick?: () => void;
  contextMenu?: ReactNode;
}

export const SettingsListItem = (props: SettingsListItemProps) => {
  const { onClick, contextMenu, children } = props;
  return (
    <li className="flex w-full items-center py-2 px-5 gap-8 justify-between hover:bg-gray-100 transition-colors duration-300 min-h-[60px] border-b border-gray-100">
      <div
        className={classNames('flex-1 flex items-center gap-4', {
          'cursor-pointer': !!onClick,
        })}
        onClick={onClick}
      >
        {children}
      </div>
      <div className="px-1 w-6">{contextMenu}</div>
    </li>
  );
};

export const SettingsListHeader = ({ children }: PropsWithChildren) => {
  return (
    <div className="flex w-full items-center px-5 gap-8 leading-6 justify-between text-[13px] text-slate-400 border-b border-gray-100">
      <div className="flex-1 flex items-center gap-4">{children}</div>
      <div className="px-1 w-6" />
    </div>
  );
};

interface SettingsListItemTitleProps {
  icon?: ReactNode;
  title?: string | ReactNode;
  customTitle?: string | ReactNode;
  subtitle?: string;
  className?: string;
}

export const SettingsListItemTitle = (props: SettingsListItemTitleProps) => {
  const { icon, title, subtitle, className, customTitle } = props;

  return (
    <div className={cn('flex gap-4 items-center', className)}>
      <div className="w-8 flex-none">{icon}</div>
      <div className="flex flex-col overflow-hidden">
        {customTitle ? (
          customTitle
        ) : (
          <>
            <span className="text-lg font-bold leading-6 truncate">{title}</span>
            {subtitle && <span className="text-[15px] text-slate-400 leading-5 truncate">{subtitle}</span>}
          </>
        )}
      </div>
    </div>
  );
};
