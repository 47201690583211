import { ProjectTemplateReadModel, useApiGetProjectTemplatesQuery } from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { LoadingIndicator, SettingsAddButton, SlideOver } from '@client/shared/toolkit';
import React, { useState } from 'react';
import { SettingsHeader } from './SettingsHeader';
import { ProjectTemplateRow } from './ProjectTemplateRow';
import { ProjectTemplateSlideOver } from './ProjectTemplateSlideOver';
import { useNavigate } from 'react-router-dom';
import { settings } from '@client/shared/store';
import { ROUTES_CONFIG } from '@client/shared/permissions';

export const ProjectTemplatesList = ({ templateUrl }: { templateUrl: string }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: templates, isFetching } = useApiGetProjectTemplatesQuery();

  const [searchText, setSearchText] = useState<string>('');
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [showAdd, setShowAdd] = useState<boolean>(false);
  const [selectedProjectTemplate, setSelectedProjectTemplate] = useState<ProjectTemplateReadModel | undefined>();

  const projectTemplates: ProjectTemplateReadModel[] = templates?.templates ?? [];

  const handleEdit = (projectTemplate: ProjectTemplateReadModel) => {
    setSelectedProjectTemplate(projectTemplate);
    setShowDetails(true);
  };

  const handleAdd = () => {
    setShowAdd(true);
    setShowDetails(true);
  };

  const closeSlideOver = () => {
    setShowAdd(false);
    setShowDetails(false);
  };

  const openTemplate = (templateId: string) => {
    navigate(ROUTES_CONFIG.PROJECT_DASHBOARD.path.replace(':id', templateId));
    // navigate(templateUrl.replace(':templateId', templateId));
  };

  return (
    <div className="w-full">
      <SettingsHeader searchText={searchText} setSearchText={setSearchText}>
        {t('templates.projectTemplatePageTitle')}
      </SettingsHeader>
      <div className="relative h-full">
        <div className="flex w-full text-xs text-gray-500 pl-12 mt-4 mb-2">
          <div className="w-4/12">{t('templates.catalogTemplateNameColumnTitle')}</div>
          <div className="w-8/12">{t('templates.catalogTemplateCatalogColumnTitle')}</div>
        </div>

        {isFetching === true && <LoadingIndicator text={t('templates.catalogTemplatesLoadingIndicator')} />}

        <div className="border-t border-gray-200">
          {projectTemplates
            .filter(
              (x) =>
                x.name.toLowerCase().includes(searchText?.toLowerCase() ?? '') ||
                x.costCatalogName.toLowerCase().includes(searchText?.toLowerCase() ?? '') ||
                x.earningsCatalogName.toLowerCase().includes(searchText?.toLowerCase() ?? '') ||
                x.riskCatalogName.toLowerCase().includes(searchText?.toLowerCase() ?? '') ||
                x.financeCatalogName.toLowerCase().includes(searchText?.toLowerCase() ?? '') ||
                x.deliveryPhasesCatalog.toLowerCase().includes(searchText?.toLowerCase() ?? ''),
            )
            .map((template, idx) => (
              <div key={idx} className="border-b border-gray-200">
                <ProjectTemplateRow
                  projectTemplate={template}
                  handleEdit={handleEdit}
                  openTemplate={() => {
                    if (settings.devMode) {
                      openTemplate(template.templateId)
                    } else { // TODO only tmp disable new views
                      setSelectedProjectTemplate(template);
                      setShowDetails(true);
                    }
                  }}
                />
              </div>
            ))}
        </div>

        <SettingsAddButton onClick={handleAdd} />
      </div>

      <SlideOver
        isOpen={showDetails}
        onClose={closeSlideOver}
        onAfterLeave={() => setSelectedProjectTemplate(undefined)}
      >
        <ProjectTemplateSlideOver
          projectTemplate={selectedProjectTemplate}
          isAddMode={showAdd}
          onClose={closeSlideOver}
        />
      </SlideOver>
    </div>
  );
};
