import {
  Badge,
  Button,
  LoadingIndicator,
  SlideOver,
  SlideOverOnCloseProps,
  SlideOverTabOptions,
  SlideOverWithTabs,
  Modal,
  ContextMenuItem,
  UsDollarCircledIcon,
  CheckmarkFilledIcon,
  CheckmarkDottedIcon,
  ContextMenu,
} from '@client/shared/toolkit';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoiceDocuments } from './InvoiceDocuments';
import {
  ContractReadModel,
  ExternalApiReadModel,
  InvoiceReadModel,
  useApiGetExternalApisQuery,
  useApiGetInvoiceQuery,
  useApiPostReviewInvoiceWorkflowTaskMutation,
  WorkflowTaskStatus,
  apiBase,
  useApiGetInvoiceWorkflowDetailQuery,
  useApiPostAuditInvoiceMutation,
  useApiPostApproveInvoiceMutation,
  useApiPostPayInvoiceMutation,
  useApiPostReopenInvoiceMutation,
  ApiTagTypes, InvoiceType,
  ProbisErrorDataType,
} from '@client/shared/api';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { useValidateProjectPermission } from '@client/shared/permissions';
import { InvoiceTab } from './InvoiceTab';
import { safeMutation } from '@client/shared/utilities';
import { useUi } from '@client/shared/store';
import { useDispatch } from 'react-redux';
import { InvoiceEditContext } from './InvoiceEditContextProvider';
import { InvoiceReviewCommentModal } from '.';
import classNames from 'classnames';
import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline';
import { AuditLog } from '../AuditLog';
import LazyIcsViewer from './LazyIcsViewer';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

export interface InvoiceSlideOverProps extends SlideOverOnCloseProps {
  selectedInvoiceId: string;
  selectedInvoice?: InvoiceReadModel;
  setChildSlideOverIsOpen?: (val: boolean) => void;
  contract?: ContractReadModel | null;
}

export const InvoiceSlideOver = (props: InvoiceSlideOverProps) => {
  const { onClose, selectedInvoice, selectedInvoiceId, contract } = props;
  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const dispatch = useDispatch();

  const canWrite = useValidateProjectPermission(['INVOICE_WRITE'], loadedProjectId ?? '');
  const canDelete = useValidateProjectPermission(['INVOICE_DELETE'], loadedProjectId ?? '');
  const canChangeState = useValidateProjectPermission(['INVOICE_APPROVE'], loadedProjectId ?? '');

  const [invalidatingCache, setInvalidatingCache] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);

  const [invoice, setInvoice] = useState<InvoiceReadModel | undefined>(selectedInvoice);
  const [isOpenCommentModal, setIsOpenCommentModal] = useState(false);
  const [completionStatus, setCompletionStatus] = useState<WorkflowTaskStatus | null>(null);
  const [invoiceProcessingError, setInvoiceProcessingError] = useState<string | null>(null);
  const [invoiceIsProcessing, setInvoiceIsProcessing] = useState(false);

  const [auditInvoice, { isLoading: isAuditing }] = useApiPostAuditInvoiceMutation();
  const [approveInvoice, { isLoading: isApproving }] = useApiPostApproveInvoiceMutation();
  const [payInvoice, { isLoading: isPaying }] = useApiPostPayInvoiceMutation();
  const [reopenInvoice, { isLoading: isReopening }] = useApiPostReopenInvoiceMutation();
  const [childIsLoading, setChildIsLoading] = useState(false);

  const [updatedState, setUpdatedState] = useState(false);

  const { unsavedData, submitForm, isValid, isSaving, isUpdated, formRef } = useContext(InvoiceEditContext);

  const { data: workflow, isFetching: isLoadingWorkflowDetail  } = useApiGetInvoiceWorkflowDetailQuery(
    {
      invoiceId: invoice?.id ?? '',
      projectId: loadedProjectId ?? '',
      calculationModelId: loadedVariantId ?? '',
    },
    {
      skip: !loadedProjectId || !loadedVariantId || !invoice?.id || !invoice?.hasWorkflow,
    },
  );

  const [reviewWorkflow, { isLoading: reviewingInvoice }] = useApiPostReviewInvoiceWorkflowTaskMutation();

  const ui = useUi();
  const user = ui.appUser;

  const authorizedToReview = !!(
    invoice?.hasWorkflow && workflow?.currentTask?.authorizedUsers.some(
      (authorizedUser) => authorizedUser.id === user?.userId,
    ) || user.tenant?.isOwner
  );

  /* Coming from contract slide over, we need to fetch the invoice again, because the contract invoice read model contains not all data */
  const { data: loadedInvoice, isFetching: isLoadingInvoice } = useApiGetInvoiceQuery(
    {
      invoiceId: selectedInvoiceId,
      projectId: loadedProjectId ?? '',
      calculationModelId: loadedVariantId ?? '',
    },
    {
      skip: !loadedProjectId || !loadedVariantId || !selectedInvoiceId || invalidatingCache || !!selectedInvoice
    },
  );

  useEffect(() => {
    setInvoice(selectedInvoice);
  }, [selectedInvoice]);

  const canReopen = invoice?.state !== 'Pending';
  const canAudit = invoice?.state === 'Pending';
  const canApprove = invoice?.state === 'Pending' || invoice?.state === 'Audited';
  const canPay =  invoice?.state === 'Pending' || invoice?.state === 'Audited' || invoice?.state === 'Approved';

  const contextItems: ContextMenuItem[] = [
    {
      label: t('projectControl.reopen'),
      icon: <ArrowUturnLeftIcon className={classNames('h-5 w-5', canChangeState && canReopen ? 'text-yellow-500' : 'text-slate-300')}/>,
      isDisabled: !canChangeState || !canReopen,
      onClick: () => handleReopening(),
    },
    {
      label: t('projectControl.audit'),
      icon: <CheckmarkDottedIcon className={classNames('h-5 w-5', canChangeState && canAudit ? 'text-emerald-500' : 'text-slate-300')}/>,
      isDisabled: !canChangeState || !canAudit,
      onClick: () => handleAuditing(),
    },
    {
      label: t('projectControl.approve'),
      icon: <CheckmarkFilledIcon className={classNames('h-5 w-5', canChangeState && canApprove ? 'text-emerald-500' : 'text-slate-300')}/>,
      isDisabled: !canChangeState || !canApprove,
      onClick: () => handleApproving(),
    },
    {
      label: t('projectControl.pay'),
      icon: <UsDollarCircledIcon className={classNames('h-5 w-5', canChangeState && canPay ? 'text-sky-700' : 'text-slate-300')}/>,
      isDisabled: !canChangeState || !canPay,
      onClick: () => handlePaying(),
    },
  ];

  const tagsToInvalidate = useMemo(() => {
    if (!invoice) return [];
    const toInvalidate = [
      { type: ApiTagTypes.Workflow, id: invoice.id },
      { type: ApiTagTypes.Invoice, id: invoice.id },
    ];
    if (loadedVariantId) {
      toInvalidate.push({ type: ApiTagTypes.Invoices, id: loadedVariantId });
      toInvalidate.push({ type: ApiTagTypes.Tasks, id: loadedVariantId });
    }
    if (invoice.contractId) {
      toInvalidate.push({ type: ApiTagTypes.Contract, id: invoice.contractId });
    }
    return toInvalidate;
  }, [invoice, loadedVariantId])

  useEffect(() => {
    if (invoice?.hasWorkflow) {
      // setInvalidatingCache(true);
      // @ts-expect-error tmp fix for missing callback from BE
      dispatch(apiBase.util.invalidateTags(tagsToInvalidate));

      setTimeout(() => {
        setInvalidatingCache(false);
      }, 2000);
    } else {
      setInvalidatingCache(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice?.hasWorkflow]);

  const handleAuditing = async () => {
    if (invoice && loadedProjectId && loadedVariantId) {
      try {
        await safeMutation(
          auditInvoice,
          {
            invoiceId: invoice.id,
            projectId: loadedProjectId,
            calculationModelId: loadedVariantId,
          }, isAuditing)
        setUpdatedState(true);
      } catch (e) {
        console.error(e);
      }
    }
  }

  const handleApproving = async () => {
    if (invoice && loadedProjectId && loadedVariantId) {
      try {
        await safeMutation(
          approveInvoice,
          {
            invoiceId: invoice.id,
            projectId: loadedProjectId,
            calculationModelId: loadedVariantId,
          }, isApproving)
        setUpdatedState(true);
      } catch (e) {
        console.error(e);
      }
    }
  }

  const handlePaying = async () => {
    if (invoice && loadedProjectId && loadedVariantId) {
      try {
        await safeMutation(
          payInvoice,
          {
            invoiceId: invoice.id,
            projectId: loadedProjectId,
            calculationModelId: loadedVariantId,
          }, isPaying)
        setUpdatedState(true);
      } catch (e) {
        console.error(e);
      }
    }
  }

  const handleReopening = async () => {
    if (invoice && loadedProjectId && loadedVariantId) {
      try {
        await safeMutation(
          reopenInvoice,
          {
            invoiceId: invoice.id,
            projectId: loadedProjectId,
            calculationModelId: loadedVariantId,
          }, isReopening)
        setUpdatedState(true);
      } catch (e) {
        console.error(e);
      }
    }
  }

  const handleReviewWorkflow = async (comment: string | null, reUpload: boolean) => {
    setInvoiceProcessingError(null);

    if (invoice && invoice?.hasWorkflow && completionStatus) {
      try {
        setInvoiceIsProcessing(true);
        await safeMutation(
          reviewWorkflow,
          {
            projectId: loadedProjectId ?? '',
            calculationModelId: loadedVariantId ?? '',
            body: {
              invoiceId: invoice.id,
              taskCompletionStatus: completionStatus,
              comment: comment !== '' ? comment : null,
              canReUploadDocument: reUpload,
            },
          },
          reviewingInvoice,
        );

        setCompletionStatus(null) // reset
        setTimeout(
          () => {
            setInvoiceIsProcessing(false);
            setIsOpenCommentModal(false);
            // @ts-expect-error tmp fix for missing callback from BE
            dispatch(apiBase.util.invalidateTags(tagsToInvalidate));
            if(workflow?.currentTask?.isLastTask || completionStatus === 'Declined') {
              setTimeout(() => {
                onClose(false);
              }, 200);
            } else {
              onClose(false);
            }
          },
          2000,
        );
    } catch (e) {
        console.error(e);

        const error = e as FetchBaseQueryError;
        const data = error.data as ProbisErrorDataType;

        if (data.message) {
          setInvoiceProcessingError(data.message);
        }

        setInvoiceIsProcessing(false);
      }
    }
  };

  const handleReviewInvoiceModal = () => {
    setInvoiceProcessingError(null);
    setIsOpenCommentModal(false);
  };

  useEffect(() => {
    if (typeof loadedInvoice !== 'undefined' && loadedInvoice !== null && loadedInvoice.invoice) {
      const inv = loadedInvoice.invoice
      setInvoice(inv);
      if (updatedState) {
        formRef?.current?.resetForm({
          invoicingPartyId: inv.invoicingParty?.id ?? null,
          invoiceRecipientId: inv.invoiceRecipient?.id ?? null,
          calculationSchemeId: inv.calculationSchemeId ?? null,
          contractId: inv.contractId ?? null,
          code: inv.code ?? '',
          externalCode: inv.externalCode ?? '',
          type: inv.type ?? ('Single' as InvoiceType),
          dateOfReceipt: inv.dateOfReceipt,
          invoiceDate: inv.invoiceDate,
          dateOfAudit: inv.dateOfAudit,
          dateOfApproval: inv.dateOfApproval,
          paymentDate: inv.paymentDate,
          vat: inv.vat,
          claim: inv.claim,
          dueDate: inv.dueDate,
          cashDiscountDate: inv.cashDiscountDate,
          comment: inv.comment
        });
        setUpdatedState(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedInvoice]);

  // For AXA show case
  const [isJobRouter, setIsJobRouter] = useState<boolean>(false);
  const { data: externalApis, isFetching: isLoadingExternalApis } = useApiGetExternalApisQuery();

  useEffect(() => {
    if (externalApis)
      setIsJobRouter(!!externalApis?.find((x: ExternalApiReadModel) => x.api.name === 'JobRouter')?.api.isActive);
  }, [externalApis]);

  const handleReview = (status: WorkflowTaskStatus) => {
    setCompletionStatus(status);
    if (unsavedData.length) {
      // Wait until the data was saved, then open the comment modal
      submitForm();
    } else {
      setIsOpenCommentModal(true)
    }
  }

  // Wait until the data was saved, then open the comment modal
  useEffect(() => {
    if (isUpdated && completionStatus) {
      setIsOpenCommentModal(true)
    }
  }, [isUpdated, completionStatus]);

  const tabOptions: SlideOverTabOptions[] = useMemo(() => {
    if (!invoice) return [];
    return [
      {
        header: t('projectControl.invoice'),
        name: 'invoice',
        panel: (
          <InvoiceTab
            invoice={invoice}
            isJobRouter={isJobRouter}
            authorizedToReview={authorizedToReview}
            contract={contract}
            setIsLoading={setChildIsLoading}
          />
        ),
      },
      {
        header: (
          <>
            {t('projectContract.documents')}{' '}
            {invoice.documents?.length > 0 && <Badge variant="lightInfo" text={invoice.documents?.length.toString()} />}
          </>
        ),
        name: 'invoiceDocuments',
        panel: (
          <InvoiceDocuments
            invoiceId={invoice.id}
            documents={invoice.documents}
            canWrite={canWrite}
            canDelete={canDelete}
          />
        ),
      },
      {
        header: t('ics.invoiceCoverSheet'),
        name: 'ics',
        panel: (
          <LazyIcsViewer invoiceId={invoice.id} reportId={invoice.invoiceCoverSheetReportId} />
        )
        /* panel: (
          <InvoiceCoverSheetPdf invoiceId={invoice.id} />
        ), */
      },
      /* {
        header: t('projectControl.invoiceTabNotes'),
        name: 'notes',
        panel: (
          <DecoratedCard shadowVariant="normal">
            <DecoratedCard.Content>
              <ListTitle title={t('projectControl.invoiceTabNotes')} />
            </DecoratedCard.Content>
          </DecoratedCard>
        )
      }, */
      {
        header: t('projectControl.invoiceTabHistory'),
        name: 'invoiceHistory',
        panel: (
          <AuditLog
            id={invoice.logicalId ?? ''}
            targetType='Invoice'
          />
        )
      }
    ];
  }, [t, invoice, isJobRouter, authorizedToReview, contract, canWrite, canDelete]);

  /* TODO tmp workaround: current invoice workflow step is the 2nd step, all other steps should have this button disabled. */
  const isInSecondWorkflowStep = useMemo(() => {
    if (workflow?.tasksHistory) {
      const rejected = workflow.tasksHistory.filter((t) => t.status === 'Rejected');
      if (rejected.length) {
        return false;
      }
      const accepted = workflow.tasksHistory.filter((t) => t.status === 'Accepted');
      return accepted.length - rejected.length === 1;
    }
    return false;
  }, [workflow?.tasksHistory]);

  return (
    <SlideOverWithTabs
      title={
        <>
          {t('projectControl.invoice')} {invoice?.code}
        </>
      }
      subtitle={
        <>
          {invoice?.externalCode && <span className="font-bold">{invoice?.externalCode}&nbsp;</span>}
          {typeof invoice?.externalCode !== 'undefined' && invoice?.externalCode && invoice?.sequentialNumber ? '• ' : ''}
          {invoice?.sequentialNumber ? `${t('projectContract.numberLabel')} ${invoice?.sequentialNumber}` : ''}
        </>
      }
      onClose={onClose}
      tabOptions={tabOptions}
      onChange={(tabIndex) => setCurrentTab(tabIndex)}
    >
      {((invalidatingCache || childIsLoading || isLoadingInvoice || !invoice || isSaving || isLoadingExternalApis || isLoadingWorkflowDetail || isAuditing || isApproving || isPaying || isReopening)) && (
        <LoadingIndicator text={t('projectControl.fetchingInvoiceLoadingIndicator')} mode="overlay" />
      )}
      <SlideOver.Controls>
        {invoice?.hasWorkflow && workflow?.currentTask && authorizedToReview ? (
          <div className="w-full flex justify-between">
            <div className="flex-none">
              {isInSecondWorkflowStep && (
                <Button
                  variant="danger"
                  className="mr-2"
                  onClick={() => {
                    handleReview('Rejected');
                    // setCompletionStatus('Rejected');
                    // setIsOpenCommentModal(true);
                  }}
                >
                  {`← ${t('projectControl.workflowReject')}`}
                </Button>
              )}

              <Button
                variant="danger"
                className="ml-2 mr-2"
                onClick={() => {
                  handleReview('Declined');
                }}
              >
                {t('projectControl.workflowDeclined')}
              </Button>
            </div>
            <div className="flex items-center flex-wrap lg:flex-nowrap justify-end">
              {(unsavedData.length > 0 || !isValid) && (
                <div className="flex-none md:flex-1 flex-wrap w-full md:w-auto order-1 md:order-0 text-right mr-4 mt-2 md:mt-0 flex justify-end gap-2">
                  {unsavedData.length > 0 && (
                    <span className="text-sm italic text-secondary leading-none">
                      {t('projectControl.editInvoiceUnsavedDataMessage')}
                    </span>
                  )}
                  {!isValid && (
                    <span className="text-sm italic text-red-700 leading-none">
                      {t('projectControl.createInvoiceInvalidFormMessage')}
                    </span>
                  )}
                </div>
              )}
              <div className="flex items-center order-0 md:order-1">
                {unsavedData.length > 0 && (
                  <Button variant="text" className="mr-2  flex-none" onClick={submitForm}>
                    {t('projectControl.editInvoiceSaveChanges')}
                  </Button>
                )}
                <Button
                  variant="success"
                  className="flex-none"
                  onClick={() => {
                    handleReview('Accepted');
                    // setCompletionStatus('Accepted');
                    // setIsOpenCommentModal(true);
                  }}
                >
                  {workflow?.currentTask?.isLastTask
                    ? unsavedData.length > 0
                      ? t('projectControl.saveAndWorkflowFinalize')
                      : t('projectControl.workflowFinalize')
                    : unsavedData.length > 0
                      ? `${t('projectControl.saveAndWorkflowApprove')} →`
                      : `${t('projectControl.workflowApprove')} →`}
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <>
            {currentTab === 0 ? (
              <div className="flex flex-grow items-center justify-between">
                <div className="items-start">
                  {!(invoice?.hasWorkflow && !authorizedToReview) &&
                    canChangeState && !invoice?.isPxInvoice &&
                    (canAudit || canApprove || canPay || canReopen) && (
                      <ContextMenu
                        items={contextItems}
                        button={
                          <Button variant="success" className="mr-2" disabled={invoice?.isWaitingForNextTask || !!unsavedData?.length}>
                            {t('projectControl.updateState')}
                          </Button>
                        }
                      />
                    )}
                </div>
                <div className="items-end">
                  {unsavedData.length > 0 && (
                    <span className="text-sm italic text-secondary mr-4 leading-none">
                      {t('projectControl.editInvoiceUnsavedDataMessage')}
                    </span>
                  )}
                  <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
                    {t('common.close')}
                  </Button>
                  {!(invoice?.hasWorkflow && !authorizedToReview) &&
                    !invoice?.isPxInvoice &&
                    !invoice?.isWaitingForNextTask && (
                      <Button onClick={submitForm} disabled={!unsavedData.length}>
                        {t('common.save')}
                      </Button>
                    )}
                </div>
              </div>
            ) : (
              <Button variant="secondary" onClick={() => onClose(false)}>
                {t('common.close')}
              </Button>
            )}
          </>
        )}
      </SlideOver.Controls>
      <Modal isOpen={isOpenCommentModal} onClose={handleReviewInvoiceModal}>
        <InvoiceReviewCommentModal
          reviewInvoice={handleReviewWorkflow}
          completionStatus={completionStatus}
          onClose={handleReviewInvoiceModal}
          onBackAction={() => setInvoiceProcessingError(null)}
          isProcessing={invoiceIsProcessing}
          error={invoiceProcessingError}
        />
      </Modal>
    </SlideOverWithTabs>
  );
};
