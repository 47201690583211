import { BaseSelect, EditNodeIcon, LoyaltyIcon, MultiSelect } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { useApiProjectGetUserDefinedFieldsDefinitionQuery } from '@client/shared/api';
import { useEffect, useMemo } from 'react';
import { useLoadedProjectId } from '@client/project/store';

export interface ProjectUdfListSelectProps {
  selectedUDF: string;
  setSelectedUDF: (value: string) => void;
  setIsFetchingUDFs: (isFetching: boolean) => void;
  selectedUDFLabels: string[];
  setSelectedUDFLabels: (value: string[]) => void;
  disabled?: boolean;
  widthClass?: string;
}

export const ProjectUdfListSelect = (props: ProjectUdfListSelectProps) => {
  const {
    selectedUDF,
    setSelectedUDF,
    setIsFetchingUDFs,
    selectedUDFLabels,
    setSelectedUDFLabels,
    disabled = false,
    widthClass = 'w-full',
  } = props;
  const { t } = useTranslation();
  const loadedProjectId = useLoadedProjectId();

  const { data: userDefinedFieldData, isFetching: isFetchingUDFs } = useApiProjectGetUserDefinedFieldsDefinitionQuery({
    projectId: loadedProjectId ?? 'unset',
  });

  const userDefinedFieldsOptions = useMemo(() => {
    const updatedUserDefinedFields = userDefinedFieldData?.userDefinedFieldsDefinition
      ? userDefinedFieldData.userDefinedFieldsDefinition
          .filter((field) => field.fieldType === 'List')
          .map((field) => ({ label: field.name, value: field.id }))
          .sort((a, b) => a.label.localeCompare(b.label))
      : [];
    updatedUserDefinedFields.unshift({
      label: t('projectCalculate.NoSelection'),
      value: 'none',
    });

    return updatedUserDefinedFields;
  }, [userDefinedFieldData?.userDefinedFieldsDefinition, t]);

  const userDefinedFieldsLabelOptions = useMemo(() => {
    if (selectedUDF === 'none') return [];
    return (
      userDefinedFieldData?.userDefinedFieldsDefinition
        .find((field) => field.id === selectedUDF)
        ?.listItems?.map((item) => ({ label: item.label, value: item.listItemId }))
        .sort((a, b) => a.label.localeCompare(b.label)) ?? []
    );
  }, [selectedUDF, userDefinedFieldData?.userDefinedFieldsDefinition]);

  useEffect(() => {
    setIsFetchingUDFs(isFetchingUDFs);
  }, [isFetchingUDFs, setIsFetchingUDFs]);

  return (
    <>
      <BaseSelect
        label={t('common.userDefinedFields')}
        value={selectedUDF}
        onChange={(value) => setSelectedUDF(value)}
        options={userDefinedFieldsOptions}
        className={widthClass}
        icon={<LoyaltyIcon />}
        disabled={disabled}
      />
      <MultiSelect
        className={widthClass}
        label={t('common.userDefinedFieldLabels')}
        placeHolder={t('common.userDefinedFieldLabels')}
        options={userDefinedFieldsLabelOptions}
        disabled={selectedUDF === 'none' || disabled}
        value={selectedUDFLabels}
        onChange={(selected) => {
          setSelectedUDFLabels(selected);
        }}
        icon={<EditNodeIcon />}
      />
    </>
  );
};
