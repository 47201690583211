import {
  DOUGHNUT_CHART_BG_IMAGE,
  WidgetContainer,
  WidgetContainerContent,
  WidgetContainerTitle,
} from '@client/shared/toolkit';
import { DashboardWidgetProps } from '../WidgetDashboard';
import { useTranslation } from 'react-i18next';
import { ArcElement, Chart as ChartJS, ChartOptions, DoughnutController } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { useMemo } from 'react';
import { UpsertProjectClockDatasetPayload } from '@client/shared/api';
import { MAX_DATASETS } from '../WidgetEditForms';

export const DashboardWidgetProjectClock = (props: DashboardWidgetProps) => {
  const { widget } = props;
  const { t } = useTranslation();

  ChartJS.register(
    DoughnutController,
    ArcElement
  );

  const chartOptions: ChartOptions = useMemo(() => {
    return {
      responsive: true,
      maintainAspectRatio: true,
      circumference: 276,
      layout: {
        padding: {
          top: 45,
          left: 40,
          bottom: 45
        },
      },
      cutout: "27.7%",
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      scales: {
        x: {
          display: false,
        },
        y: {
          display: false,
        },
      },
    };
  }, []);

  const colors = useMemo(() => [
    {
      text: '#6b9f34',
      background: '#f1f5eb'
    },
    {
      text: '#1ba5e9',
      background: '#e6f5fc'
    },
    {
      text: '#0d69a0',
      background: '#e4eef4'
    },
    {
      text: '#0c4a6e',
      background: '#e4ebef'
    },
    {
      text: '#2c3e50',
      background: '#e7e9eb'
    },
  ], []);

  const chartData = useMemo(() => {
    const datasets: UpsertProjectClockDatasetPayload[] = widget?.additionalConfig?.ProjectClock || [];

    if (!datasets.length) return { datasets: [] };

    return {
      datasets: Array.from({ length: MAX_DATASETS * 2 }, (_, i) => {
        const dataset = datasets[Math.floor(i / 2)];
        const color = colors[Math.floor(i / 2)];

        return i % 2 === 0 && dataset
        ? {
            data: [dataset.value, 100 - dataset.value],
            backgroundColor: dataset.name ? [color?.text, 'rgba(0, 0, 0, 0)'] : 'rgba(0, 0, 0, 0)',
            borderWidth: dataset.name ? [6, 0] : [0, 0],
            borderColor: color?.background,
            borderRadius: 30,
            rotation: -5,
            hoverBackgroundColor: dataset.name ? [color?.text, 'rgba(0, 0, 0, 0)'] : 'transparent',
            hoverBorderColor: color?.background,
            pointHoverRadius: 0,
            pointHoverBorderWidth: 0,
          }
        : {
            data: [100],
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderWidth: 0,
            borderRadius: 30,
            rotation: -5,
            hoverBackgroundColor: 'transparent',
            hoverBorderColor: 'transparent',
            pointHoverRadius: 0,
            pointHoverBorderWidth: 0,
          };
      })
    };
  }, [widget?.additionalConfig?.ProjectClock, colors]);

  return (
    <WidgetContainer>
      <WidgetContainerTitle>
        {widget.title ? widget.title : t('dashboard.widget.projectClock.title')}
      </WidgetContainerTitle>
      <WidgetContainerContent className="flex-1 overflow-y-auto">
        <div className='w-[537px] h-[495px] relative'>
          <div className="absolute top-[25px] left-[160px] w-[245px]">
            <div className="flex text-xxs text-financing">
              <p className="me-4">{t('dashboard.widget.projectClock.datasetLegend.actual')}</p>
              <p>{t('dashboard.widget.projectClock.datasetLegend.deviation')}</p>
            </div>
          </div>
          <div className="absolute top-[45px] left-[23px] w-[245px]">
            {widget?.additionalConfig?.ProjectClock?.map((dataset, index) => {
              return dataset.name ? (
                <div className="flex flex-nowrap text-xxs ps-3 pe-4 rounded-l-xl" style={{ paddingTop: '4px', paddingBottom: '4px', marginBottom: '9px', backgroundColor: colors[index]?.background, color: colors[index]?.text }} key={index}>
                  <p className="font-bold w-[55%]">{t(`dashboard.widget.projectClock.datasetLabel.${dataset.name}`)}</p>
                  <p className="font-bold w-[16%] text-end">{dataset.value}%</p>
                  <p className="w-[25%] text-end">{`${dataset.deviation > 0 ? '+' : (dataset.deviation < 0 ? '-' : '')}${dataset.deviation}%`}</p>
                </div>
              ) : null;
            })}
          </div>
          <div className="h-full relative">
            <img src={DOUGHNUT_CHART_BG_IMAGE} className="bg-contain bg-center bg-no-repeat absolute top-0 left-0 z-0 w-full h-full" alt="Doughnut chart background" />
            <Chart options={chartOptions} type="doughnut" data={chartData} className="chart-export z-5" />
          </div>
        </div>
      </WidgetContainerContent>
    </WidgetContainer>
  );
};
