import {
  CalculationModelEarningsElement,
  CalculationModelEarningsGroupElement,
  CostCatalogElementDto,
  FormulaCalculationExpressionParameterModel,
  FormulaStrategyValueResponseModel,
  PlotReadModel,
  TaxonomyReadModel,
} from '@client/shared/api';
import { SizeType, UseFormulaEditorDatasetReturnValues } from '../hooks';
import { FlattenedTaxonomyItem, flattenEarningsElements, flattenTaxonomyTree } from '@client/project/shared';
import { getLanguageAndLocale } from '@client/shared/utilities';
import { t } from 'i18next';

export type FormulaBuilderConstructorArgs = UseFormulaEditorDatasetReturnValues & {
  sizes: SizeType[];
};

class FormulaBuilderClass {
  options: FormulaBuilderConstructorArgs;
  private flattenedTaxonomy: FlattenedTaxonomyItem<TaxonomyReadModel>[];
  private flattenedCostElements: CostCatalogElementDto[];
  private flattenedEarningsElements: (CalculationModelEarningsGroupElement | CalculationModelEarningsElement)[];
  private flattenedPlots: PlotReadModel[];
  private readonly language: string;

  constructor(options: FormulaBuilderConstructorArgs) {
    this.options = options;
    this.flattenedTaxonomy = flattenTaxonomyTree(options.taxonomy);
    this.flattenedCostElements = options.flatCatalogElements;
    this.flattenedEarningsElements = flattenEarningsElements(options.earningsElements);
    this.flattenedPlots = options.flatPlots;
    this.language = getLanguageAndLocale().language;
  }

  private doChangeNumberFormat = (formula: string, toLocale: 'en' | 'de') => {
    let searchValue, replacementValue;
    if (toLocale === 'de') {
      searchValue = /\./g;
      replacementValue = ',';
    } else {
      searchValue = /,/g;
      replacementValue = '.';
    }
    let result = formula;
    // eslint-disable-next-line no-useless-escape
    const matches = formula.matchAll(/(?<!['"{}])(\d+(([\.\,](\d+))|))(?!['"{}])/g);
    for (const match of matches) {
      const matchedTerm = match[0];
      const matchedSuffix = match[3];
      if (matchedSuffix) {
        const replacementText = matchedTerm.replace(searchValue, replacementValue);
        result = result.replace(match[0], replacementText);
      }
    }
    return result;
  };

  private makeInvariant = (formula: string) => {
    if (this.language === 'de') {
      return this.doChangeNumberFormat(formula, 'en');
    }
    return formula;
  };

  private makeLocalized = (formula: string, locale: string) => {
    if (locale === 'en') {
      return this.doChangeNumberFormat(formula, 'en');
    } else {
      return this.doChangeNumberFormat(formula, 'de');
    }
  };

  generateFormula(formula: string): Pick<FormulaStrategyValueResponseModel, 'expression' | 'expressionParameters'> {
    const findCostElements = (code: string | undefined, description: string) => {
      if (!code) {
        return this.flattenedCostElements.find((c) => c.description === description);
      }
      return this.flattenedCostElements.find((c) => c.code === code && c.description === description);
    };

    const  findEarningElements = (code: string | undefined, description: string) => {
      if (!code) {
        return this.flattenedEarningsElements.find((e) => e.description === description);
      }
      if(!description) {
        return this.flattenedEarningsElements.find((e) => e.code === code);
      }

      return this.flattenedEarningsElements.find((e) => e.code === code && e.description === description);
    };

    const findTaxonomyElementByName = (name: string, index?: string) => {
      if (index) {
        const elementsWithThisName = this.flattenedTaxonomy?.filter((t) => t.taxonomyItem.itemName === name);
        return elementsWithThisName.find((e, i) => i === Number(index) - 1)?.taxonomyItem;
      }

      return this.flattenedTaxonomy.find((e) => e.taxonomyItem.itemName === name)?.taxonomyItem;
    };

    const findPlotByName = (name: string, index?: string) => {
      if (index) {
        const elementsWithThisName = this.flattenedPlots?.filter((p) => p.plotName === name);
        return elementsWithThisName.find((e, i) => i === Number(index) - 1);
      }
      return this.flattenedPlots.find((p) => p.plotName === name);
    };

    const ELEMENTS_REGEX = /('[^']*')(\[\d+\])?(\.\w+)?/g;

    let result = formula;
    const args: FormulaCalculationExpressionParameterModel[] = [];

    const matches = formula.matchAll(ELEMENTS_REGEX);

    let idx = 0;
    for (const match of matches) {
      const location = `${idx}`;
      const matchedElement = match[1].replace(/'/g, '');
      const matchedIndex = match[2]?.slice(1).replace(']', '');
      const matchedSize = match[3]
        ?.slice(1)
        .replace(new RegExp(t('project.bgfRoi'), 'g'), 'BGF')
        .replace(new RegExp(t('project.ngf'), 'g'), 'NGF')
        .replace(new RegExp(t('project.mf'), 'g'), 'MFG')
        .replace(new RegExp(t('project.amount'), 'g'), 'Amount')
        .replace(new RegExp(t('project.grz'), 'g'), 'GRZ')
        .replace(new RegExp(t('project.gfz'), 'g'), 'GFZ');

      if (!matchedSize) {
        // For Cost Element matches, matchedSize is undefined as it does not match the property regex (.bgf etc.)
        const [groupCode, ...description] = matchedElement.split(' ');
        const costElement = findCostElements(groupCode, description.join(' '));
        const earningElement = findEarningElements(groupCode, description.join(' '));

        let replacementText = '';
        if (costElement) {
          replacementText = `{${location}}.effectiveValue`;
          args.push({
            costCatalogElement: {
              id: costElement.id,
              code: groupCode,
              description: description.join(' '),
              effectiveValue: 0,
            },
            position: idx,
          });
          result = result.replace(match[0], replacementText);
          idx++;
        }
        if (earningElement) {
          replacementText = `{${location}}.effectiveValue`;
          let id;
          if ('groupId' in earningElement) {
            id = earningElement.groupId;
          } else if ('id' in earningElement) {
            id = earningElement.id;
          }
          args.push({
            earningsCatalogElement: {
              id: id ?? '',
              code: groupCode,
              description: description.join(' '),
              effectiveValue: 0,
            },
            position: idx,
          });
          result = result.replace(match[0], replacementText);
          idx++;
        }
      } else {
        const building = findTaxonomyElementByName(matchedElement, matchedIndex);
        let plot;
        if (!building) {
          plot = findPlotByName(matchedElement, matchedIndex);
        }

        const size =
          this.options?.sizes.find((s: SizeType) => s.label.toLowerCase() === matchedSize.toLowerCase()) ?? undefined;
        let replacementText = '';
        if ((building || plot) && size) {
          replacementText = `{${location}}.${size.value}`;
          args.push({
            position: idx,
            taxonomyItem: building
              ? {
                  id: building.itemId,
                  name: building.itemName,
                }
              : undefined,
            plot: plot
              ? {
                  id: plot.plotId,
                  name: plot.plotName,
                  size: 0,
                }
              : undefined,
          });
          result = result.replace(match[0], replacementText);
          idx++;
        }
      }
    }
    return {
      expression: this.makeInvariant(result),
      expressionParameters: args,
    };
  }

  parseFormula(expression: string, expressionArgs: FormulaCalculationExpressionParameterModel[]) {
    let visualFormula = expression;
    visualFormula = this.makeLocalized(visualFormula, this.language);
    const PARAMETER_REGEX = /({\d})(.\w+)?/g;

    for (const match of expression.matchAll(PARAMETER_REGEX)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, prefix, suffix] = match;
      const matchIndex = parseInt(prefix.replace('{', '').replace('}', ''));
      const matchedElement = expressionArgs?.find((e) => e.position === matchIndex);

      const elementName =
        matchedElement?.taxonomyItem?.name ||matchedElement?.plot?.name ||
        ((matchedElement?.costCatalogElement?.code ||
          matchedElement?.costCatalogElement?.description) &&
          `${matchedElement?.costCatalogElement?.code} ${matchedElement?.costCatalogElement?.description}`) ||
        ((matchedElement?.earningsCatalogElement?.code || matchedElement?.earningsCatalogElement?.description) &&
          `${matchedElement?.earningsCatalogElement?.code} ${matchedElement?.earningsCatalogElement?.description}`) ;
        
      visualFormula = visualFormula.replace(prefix, `'${elementName}'[index]` || '');
      const matchedSize = suffix?.slice(1) || undefined;

      if (matchedSize === 'effectiveValue' || matchedSize === undefined) {
        visualFormula = visualFormula.replace(suffix, '').replace('[index]', '');
      } else {
        const size = this.options.sizes.find((e: SizeType) => e.value.toLowerCase() === suffix?.slice(1).toLowerCase())
          ?.label;

        const duplicateNamedElements = matchedElement?.taxonomyItem
          ? this.flattenedTaxonomy?.filter((t) => t.taxonomyItem.itemName === matchedElement?.taxonomyItem?.name).length
          : this.flattenedPlots?.filter((p) => p.plotName === matchedElement?.plot?.name).length;

        const duplicateIndex = matchedElement?.taxonomyItem
          ? this.flattenedTaxonomy
              ?.filter((t) => t.taxonomyItem.itemName === matchedElement?.taxonomyItem?.name)
              .findIndex((e) => e.taxonomyItem.itemId === matchedElement?.taxonomyItem?.id)
          : this.flattenedPlots
              ?.filter((p) => p.plotName === matchedElement?.plot?.name)
              .findIndex((e) => e.plotId === matchedElement?.plot?.id);

        visualFormula = visualFormula
          .replace(matchedSize as string, size || '')
          .replace('[index]', duplicateNamedElements > 1 ? `[${duplicateIndex + 1}]` : '');
      }
    }

    visualFormula = visualFormula
      .replace(new RegExp('BGF', 'g'), t('project.bgfRoi'))
      .replace(new RegExp('NGF', 'g'), t('project.ngf'))
      .replace(new RegExp('MFG', 'g'), t('project.mf'))
      .replace(new RegExp('Amount', 'g'), t('project.amount'))
      .replace(new RegExp('GRZ', 'g'), t('project.grz'))
      .replace(new RegExp('GFZ', 'g'), t('project.gfz'));

    return visualFormula;
  }
}

export { FormulaBuilderClass as FormulaBuilder };
