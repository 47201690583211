import {
  BaseSelect,
  Button,
  FileInput,
  Form,
  FormField,
  FormRefHandle,
  FormWatch, LoadingIndicator,
  SlideOver,
  SlideOverOnCloseProps, TagWindowIcon,
  TextInput,
} from '@client/shared/toolkit';
import { NewCatalogFormValidationSchema, NewCatalogFormValidationValues } from '../NewCatalogFormValidationSchema';
import {
  apiEndpointUrls,
  CatalogElementData,
  TemplateFileType,
  TenantCatalogType,
  useApiPostCatalogImportDataMutation,
  useApiPostCatalogImportFileMutation,
} from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { safeMutation } from '@client/shared/utilities';
import React, { useEffect, useRef, useState } from 'react';
import { ImportRecord } from './ImportRecord';
import cn from 'classnames';

export const CatalogImportSlideOver = ({ onClose }: SlideOverOnCloseProps) => {
  const { t } = useTranslation();

  const [file, setFile] = useState<File | undefined>();
  const [fileError, setFileError] = useState(false);
  const [records, setRecords] = useState<CatalogElementData[] | null>(null);

  const submitRef = useRef<HTMLButtonElement>(null);
  const formRef = useRef<FormRefHandle<NewCatalogFormValidationValues>>();

  const [post, { isLoading: isLoadingFile }] = useApiPostCatalogImportFileMutation();
  const [callImport, { isLoading: isLoadingData }] = useApiPostCatalogImportDataMutation();

  const handleFileChange = (fileList: FileList | null) => {
    setRecords(null);
    if (fileList && fileList.length > 0) {
      setFile(fileList[0]);
    } else {
      setFile(undefined);
    }
  };

  useEffect(() => {
    const uploadFile = async () => {
      if (file) {
        try {
          const formData = new FormData();
          formData.append('file', file);

          const result = await safeMutation(
            post,
            {
              body: formData as unknown as { file: Blob },
            },
            isLoadingFile,
          );

          setRecords(result?.catalogElements ?? []);
        } catch (e) {
          console.log(e);
        }
      }
    };

    if (!fileError) {
      uploadFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const handleCatalogSubmit = async (data: NewCatalogFormValidationValues) => {
    if (formRef.current) {
      const values = formRef.current.getValues();
      try {
        await safeMutation(
          callImport,
          {
            body: {
              catalogName: values.name,
              type: values.type,
              elements: records ?? [],
            },
          },
          isLoadingData,
        );
        onClose(true);
      } catch {
        /* left blank */
      }
    }
  };

  const handleDownloadTemplate = (fileType: TemplateFileType) => {
    const link = document.createElement('a');

    link.setAttribute('target', '_new');
    link.href = `${apiEndpointUrls.apiGetImportFileTemplate}?contentType=Catalog&fileType=${fileType}`;
    document.body.appendChild(link);

    link.click();
    document.body.removeChild(link);
  };

  const defaultFormValues = {
    name: '',
    type: 'Costs' as TenantCatalogType,
  };

  return (
    <Form<NewCatalogFormValidationValues>
      onSubmit={handleCatalogSubmit}
      validationSchema={NewCatalogFormValidationSchema}
      defaultValues={defaultFormValues}
      className="w-full flex flex-col justify-between h-full"
      ref={formRef}
    >
      {(isLoadingFile || isLoadingData) && (
        <LoadingIndicator text={t('common.updating')} mode="overlay" />
      )}
      <SlideOver.Header
        title={t('templates.NewCatalogTemplateTitle')}
        backgroundClassName="bg-gray-600"
        onClose={() => onClose(false)}
      />

      <SlideOver.Content className="p-8 h-full">
        <div className="m-0">
          <div className="divide-gray-100 divide-y">
            <div className="bg-white p-2">
              <FormField name="name">
                {(control) => (
                  <TextInput
                    label={t('common.name')}
                    icon={<TagWindowIcon />}
                    {...control}
                  />
                )}
              </FormField>
              <FormField name="type">
                {(control) => (
                  <BaseSelect
                    label={t('templates.catalogType')}
                    options={[
                      { label: t('templates.costCatalog'), value: 'Costs' as TenantCatalogType },
                      { label: t('templates.riskCatalog'), value: 'Risks' as TenantCatalogType },
                      { label: t('templates.earningsCatalog'), value: 'Earnings' as TenantCatalogType },
                      { label: t('templates.financingCatalog'), value: 'Finance' as TenantCatalogType },
                    ]}
                    icon={<TagWindowIcon />}
                    {...control}
                  />
                )}
              </FormField>
            </div>
            <div className="bg-white p-2">
              <FileInput
                acceptedFileTypes={['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', '.xlsx', '.xls', '.csv']}
                multiple={false}
                onChange={handleFileChange}
                setError={setFileError}
              />
              <div className="text-sm text-gray-500 text-center">{t('templates.downloadExampleTemplate')}</div>
              <div className="flex flex-row text-center justify-center space-x-1 mt-1">
                  <Button onClick={() => handleDownloadTemplate('Csv')}>Csv</Button>
                  <Button onClick={() => handleDownloadTemplate('Xlsx')}>Xlsx</Button>
              </div>
            </div>

            <div>
              <FormWatch<NewCatalogFormValidationValues> fieldNames={['type']}>
                {({ type, name }) => (
                  <>
                    {records && !records.length && (
                      <div className="my-4 ml-4 font-medium">{t('app.settingsNoCatalogItems')}</div>
                    )}
                    {records && records.length > 0 && (
                      <div className="my-4 ml-4 font-medium">{t('templates.importedCatalogTemplate')}</div>
                    )}
                    <div className="w-full flex bg-white shadow rounded-tl rounded-bl">
                      <div
                        className={cn('w-2 h-auto rounded-tl-md rounded-bl-md flex-none', {
                          'bg-costs': type === 'Costs',
                          'bg-financing': type === 'Finance',
                          'bg-earnings': type === 'Earnings',
                          'bg-risks': type === 'Risks',
                        })}
                      />
                      <div className="flex w-full flex-col text-primary">
                        {records?.map((item, i) => (
                          <div className="ml-5" key={`catalog-element-${item.data.code}-${i}`}>
                            <ImportRecord element={item} />
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                )}
              </FormWatch>
            </div>
          </div>
        </div>
      </SlideOver.Content>

      <SlideOver.Controls>
        <div className="flex justify-end">
          <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
            {t('common.cancel')}
          </Button>
          <Button variant="primary" formSubmit={true} innerRef={submitRef} disabled={!records?.length || records?.some(x => x.errors.length > 0)}>
            {t('common.import')}
          </Button>
        </div>
      </SlideOver.Controls>
    </Form>
  );
};
