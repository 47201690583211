import React, { Fragment, RefObject } from 'react';
import classNames from 'classnames';
import { ToggleButton } from '@client/shared/toolkit';
import { TimeLineView } from '../Timeline';
import { addOrRemoveDecoratedElementFromExpandedIds, getRenderElementIsVisibleAndTopStyle } from '../../utils';
import { CostGroupElement } from '../CostGroupElement';
import { CostElementRow } from '../CostElementRow';
import { CostCatalogElement, CostElement, DecoratedElement } from '../../hooks';
import { CalculateCostsProps } from '../CalculateCosts';
import { CostElementDto } from '@client/shared/api';
import { CostRestElement } from '../CostRestElement';
import { CostCommitmentElement } from '../CostCommitmentElement';
import { CostContractElement } from '../CostContractElement';
import { CostInvoicingPartyElement } from '../CostInvoicingPartyElement';
import { useTranslation } from 'react-i18next';

interface CalculateCostsRenderElementsProps extends CalculateCostsProps {
  elements: DecoratedElement<CostCatalogElement>[];
  containerRef: RefObject<HTMLDivElement>;
  isReadOnly: boolean;
  handleOpenSlideOver: (
    costElementId: string | null | undefined,
    costElement: CostElementDto | undefined,
    catalog: DecoratedElement<CostCatalogElement> | undefined,
    preselectedGroupId: string | undefined,
    contractId?: string | null,
    commitmentId?: string | null
  ) => void;
  handleOpenNewElementModal: (catalog: string | undefined) => void;
  handleOpenDeleteElement: (deleteElementId?: string | null) => void;
  level: number;
  showOnTakeOverAll: (element: DecoratedElement<CostCatalogElement>) => boolean;
  handleTakeOver: (element: DecoratedElement<CostCatalogElement>, children?: boolean) => void;
  handleOpenRegulatoryElementSlideOver: (costElementId: string | null | undefined, catalog: string | undefined) => void;
  setCreateCopy: (create: boolean) => void;
  searchResults: string[] | null;
}

export const CalculateCostsRenderElements = (props: CalculateCostsRenderElementsProps) => {
  const {
    elements,
    expandedCatalogIds = [],
    searchValue,
    searchResults,
    containerRef,
    view,
    onExpand,
    isReadOnly,
    selectedVersionId,
    optionalColumn,
    obligoColumn,
    handleOpenSlideOver,
    handleOpenNewElementModal,
    handleOpenDeleteElement,
    level,
    showOnTakeOverAll,
    handleTakeOver,
    handleOpenRegulatoryElementSlideOver,
    setCreateCopy,
  } = props;
  const { t } = useTranslation();
  let counter = -1;
  let top = 0;

  const renderRestBudget = (element: DecoratedElement<CostCatalogElement>) => {
    const { isVisible, topStyle, counter: newCounter } = getRenderElementIsVisibleAndTopStyle(counter, containerRef, top);
    counter = newCounter;
    if (!isVisible) return null;
    return (
      <CostRestElement
        restBudget={element.element.group?.modelValues?.restBudget}
        optionalColumn={optionalColumn}
        level={element.level}
        contracts={
          !!element.element.contracts?.length ||
          !!element.element.invoicingParties?.length ||
          !!element.element.commitments?.length
        }
        type="group"
        top={topStyle}
      />
    );
  };

  const renderCommitments = (
    element: DecoratedElement<CostCatalogElement>,
    level: number,
    parentIdx: number
  ) => {
    return element.element.commitments?.map((commitment, idx) => {
      const { isVisible, topStyle, counter: newCounter } = getRenderElementIsVisibleAndTopStyle(counter, containerRef, top);
      counter = newCounter;
      if (!isVisible) return null;
      return (
        <CostCommitmentElement
          key={`cost-catalog-commitment-${level}-${parentIdx}-${idx}`}
          commitment={commitment}
          level={level + 1}
          optionalColumn={optionalColumn}
          obligoColumn={obligoColumn}
          index={idx}
          budget={
            (element.element?.group?.modelValues?.total || 0) as number
          }
          restBudget={
            !!element.element.group?.modelValues.restBudget &&
            element.element.group?.modelValues.restBudget !== element.element.group?.modelValues.effectiveValue
          }
          top={topStyle}
          onClick={() => {
            handleOpenSlideOver(undefined, undefined, undefined, undefined, undefined, commitment.id);
          }}
        />
      );
    });
  };
  const renderContracts = (
    element: DecoratedElement<CostCatalogElement>,
    level: number,
    parentIdx: number
  ) => {
    return element.element.contracts?.map((contract, idx) => {
      const { isVisible, topStyle, counter: newCounter } = getRenderElementIsVisibleAndTopStyle(counter, containerRef, top);
      counter = newCounter;
      if (!isVisible) return null;
      return (
        <CostContractElement
          key={`cost-catalog-contract-${level}-${parentIdx}-${idx}`}
          contract={contract}
          level={level + 1}
          optionalColumn={optionalColumn}
          obligoColumn={obligoColumn}
          index={idx}
          budget={
            (element.element?.group?.modelValues?.total || 0) as number
          }
          restBudget={
            !!element.element.group?.modelValues.restBudget &&
            element.element.group?.modelValues.restBudget !== element.element.group?.modelValues.effectiveValue
          }
          top={topStyle}
          onClick={() => {
            handleOpenSlideOver(undefined, undefined, undefined, undefined, contract.id);
          }}
        />
      );
    });
  };
  const renderInvoiceRecipient = (
    element: DecoratedElement<CostCatalogElement>,
    level: number,
    parentIdx: number
  ) => {
    return element.element.invoicingParties?.map((invoicingParty, idx) => {
      const { isVisible, topStyle, counter: newCounter } = getRenderElementIsVisibleAndTopStyle(counter, containerRef, top);
      counter = newCounter;
      if (!isVisible) return null;
      return (
        <CostInvoicingPartyElement
          key={`cost-catalog-invoice-recipient-${level}-${parentIdx}-${idx}`}
          invoicingParty={invoicingParty}
          level={level + 1}
          optionalColumn={optionalColumn}
          obligoColumn={obligoColumn}
          index={idx}
          budget={
            (element.element?.group?.modelValues?.total || 0) as number
          }
          restBudget={
            !!element.element.group?.modelValues.restBudget &&
            element.element.group?.modelValues.restBudget !== element.element.group?.modelValues.effectiveValue
          }
          top={topStyle}
        />
      );
    });
  };

  const renderCostElementRestBudget = (ce: CostElement, level: number) => {
    const { isVisible, topStyle, counter: newCounter } = getRenderElementIsVisibleAndTopStyle(counter, containerRef, top);
    counter = newCounter;
    if (!isVisible) return null;
    return (
      <CostRestElement
        restBudget={ce.costElement?.restBudget}
        optionalColumn={optionalColumn}        
        level={level}
        contracts={!!ce.contracts?.length || !!ce.invoicingParties?.length || !!ce.commitments?.length}
        type="row"
        top={topStyle}
      />
    );
  };

  const renderCostElementCommitments = (element: CostElement, level: number, parentIdx: number) => {
    return element.commitments?.map((commitment, idx) => {
      const { isVisible, topStyle, counter: newCounter } = getRenderElementIsVisibleAndTopStyle(counter, containerRef, top);
      counter = newCounter;
      if (!isVisible) return null;
      return (
        <CostCommitmentElement
          key={`cost-element-commitment-${level}-${parentIdx}-${idx}`}
          commitment={commitment}
          level={level + 1}
          optionalColumn={optionalColumn}
          obligoColumn={obligoColumn}
          index={idx}
          budget={element.costElement?.totalValue as number}
          restBudget={
            !!element.costElement?.restBudget && element.costElement?.totalValue !== element.costElement?.restBudget
          }
          top={topStyle}
          onClick={() => {
            handleOpenSlideOver(undefined, undefined, undefined, undefined, undefined, commitment.id);
          }}
        />
      );
    });
  };
  const renderCostElementContracts = (element: CostElement, level: number, parentIdx: number) => {
    return element.contracts?.map((contract, idx) => {
      const { isVisible, topStyle, counter: newCounter } = getRenderElementIsVisibleAndTopStyle(counter, containerRef, top);
      counter = newCounter;
      if (!isVisible) return null;
      return (
        <CostContractElement
          key={`cost-element-contract-${level}-${parentIdx}-${idx}`}
          contract={contract}
          level={level + 1}
          optionalColumn={optionalColumn}
          obligoColumn={obligoColumn}
          index={idx}
          budget={element.costElement?.totalValue as number}
          restBudget={
            !!element.costElement?.restBudget && element.costElement?.totalValue !== element.costElement?.restBudget
          }
          top={topStyle}
          onClick={() => {
            handleOpenSlideOver(undefined, undefined, undefined, undefined, contract.id);
          }}
        />
      );
    });
  };
  const renderCostElementInvoiceRecipient = (
    element: CostElement,
    level: number,
    parentIdx: number
  ) => {
    return element.invoicingParties?.map((invoicingParty, idx) => {
      const { isVisible, topStyle, counter: newCounter } = getRenderElementIsVisibleAndTopStyle(counter, containerRef, top);
      counter = newCounter;
      if (!isVisible) return null;
      return (
        <CostInvoicingPartyElement
          key={`cost-catalog-invoice-recipient-${level}-${parentIdx}-${idx}`}
          invoicingParty={invoicingParty}
          level={level + 1}
          optionalColumn={optionalColumn}
          obligoColumn={obligoColumn}
          index={idx}
          budget={element.costElement?.totalValue as number}
          restBudget={
            !!element.costElement?.restBudget && element.costElement?.totalValue !== element.costElement?.restBudget
          }
          top={topStyle}
        />
      );
    });
  };

  const renderElements = (elements: DecoratedElement<CostCatalogElement>[]) => {
    return elements.map((element, i) => {
      const open = expandedCatalogIds.includes(element.categoryId) || (!!searchValue && !!element.match);
      top = top + (element.level === 0 ? 32 : 0);
      const { isVisible: parentVisible, topStyle: parentTopStyle, counter: newCounter} = getRenderElementIsVisibleAndTopStyle(counter, containerRef, top);
      counter = newCounter;
      if (searchValue && !element.match) return null;
      return (
        <Fragment key={`cost-catalog-element-wrapper-${element.element.key}`}>
          {/* TOGGLE */}
          {parentVisible && (
            <div
              data-cost-element={element.element.group?.costElementId}
              data-id={element.element.group?.id}
              className="cost-element parent w-full flex group bg-transparent hover:bg-neutral-100 transition-colors duration-200 h-[38px] absolute"
              data-level={element.level}
              style={{ top: parentTopStyle }}
              title={`${element.element.group?.code} - ${element.element.group?.description ?? t('projectCalculate.unnamedElement')}`}
            >
              <ToggleButton
                className={classNames('h-full absolute z-[5]', {
                  invisible:
                    (view === TimeLineView.TIMELINE &&
                      element.children.length === 0 &&
                      element.element?.costElements?.length === 0) ||
                    (view === TimeLineView.FINANCE &&
                      element.element.contracts?.length === 0 &&
                      element.children.length === 0 &&
                      element.element?.costElements?.length === 0) ||
                    searchValue,
                })}
                open={open}
                onClick={() =>
                  onExpand(
                    addOrRemoveDecoratedElementFromExpandedIds(expandedCatalogIds, element, open ? 'remove' : 'add'),
                  )
                }
              />
              <CostGroupElement
                isReadOnly={isReadOnly}
                view={view}
                key={`cost-catalog-element-${element.element.key ?? i}`}
                item={element}
                selectedVersionId={selectedVersionId}
                expanded={open}
                optionalColumn={optionalColumn}
                obligoColumn={obligoColumn}
                onNewElement={(type) => {
                  if (type === 'costElement')
                    handleOpenSlideOver(undefined, undefined, undefined, element.element.group?.id);
                  else if (type === 'regulatoryElement') handleOpenNewElementModal(element.element.group?.id);
                }}
                onRemoveBudget={
                  element.element.group?.costElementId
                    ? () => handleOpenDeleteElement(element.element.group?.costElementId)
                    : undefined
                }
                onCodeClick={() =>
                  onExpand(
                    addOrRemoveDecoratedElementFromExpandedIds(expandedCatalogIds, element, open ? 'remove' : 'add'),
                  )
                }
                onClick={
                  !element.disableClick
                    ? () => handleOpenSlideOver(element.element.group?.costElementId, undefined, element, undefined)
                    : undefined
                }
                onTakeOverAll={showOnTakeOverAll(element) ? () => handleTakeOver(element, true) : undefined}
                onTakeOver={
                  element.element.group?.modelValues?.forecastValue && element.element.group?.costElementId
                    ? () => handleTakeOver(element)
                    : undefined
                }
                // onDistributeAll={showOnDistributeAll(element) ? () => handleDistribute(element, true) : undefined}
                // onDistribute={showOnDistribute(element) ? () => handleDistribute(element) : undefined}
                searchValue={searchValue}
                searchResults={searchResults}
              />
            </div>
          )}

          {/* ------- TOGGLE CONTENT START -------- */}
          {open && (
            <>
              {view === TimeLineView.FINANCE &&
                !!element.element.group?.modelValues.restBudget &&
                element.element.group?.modelValues.restBudget !== element.element.group?.modelValues.effectiveValue &&
                renderRestBudget(element)}

              {view === TimeLineView.FINANCE && (
                <>
                  {renderCommitments(element, element.level, i)}
                  {renderContracts(element, element.level, i)}
                  {renderInvoiceRecipient(element, element.level, i)}
                </>
              )}

              {element.element?.costElements?.map((ce, index) => {
                const { isVisible: childVisible, topStyle: childTopStyle, counter: newCounter } = getRenderElementIsVisibleAndTopStyle(counter, containerRef, top);
                counter = newCounter;

                return (
                  <Fragment key={`cost-element-${ce.key ?? i}`}>
                    {childVisible && (
                      <div
                        className="cost-element w-full flex group absolute left-0 bg-transparent hover:bg-neutral-100 transition-colors duration-200 h-[38px]"
                        data-cost-element={ce.costElement?.elementId}
                        style={{ top: childTopStyle }}
                        data-counter={counter}
                        data-level={level}
                        title={ce.costElement?.description ?? t('projectCalculate.unnamedElement')}
                      >
                        <CostElementRow
                          isReadOnly={isReadOnly}
                          view={view}
                          selectedVersionId={selectedVersionId}
                          item={ce}
                          optionalColumn={optionalColumn}
                          obligoColumn={obligoColumn}
                          onClick={() =>
                            ce.costElement?.isLocalLawElement
                              ? handleOpenRegulatoryElementSlideOver(
                                  ce.costElement?.elementId,
                                  element.element.group?.id,
                                )
                              : handleOpenSlideOver(ce.costElement?.elementId, ce.costElement, element, undefined)
                          }
                          onRemove={
                            ce.costElement?.elementId //element.element.costElement?.elementId
                              ? () => handleOpenDeleteElement(ce.costElement?.elementId)
                              : undefined
                          }
                          onTakeOver={
                            ce?.costElement?.forecastValue && ce.costElement?.elementId
                              ? () => handleTakeOver(element) // TODO actually the cost element!
                              : undefined
                          }
                          // onDistribute={showOnDistribute(element) ? () => handleDistribute(element) : undefined}
                          searchValue={searchValue}
                          searchResults={searchResults}
                          onCopy={
                            !ce.costElement?.isLocalLawElement
                              ? () => {
                                  setCreateCopy(true);
                                  handleOpenSlideOver(ce.costElement?.elementId, ce.costElement, element, undefined);
                                }
                              : undefined
                          }
                        />
                      </div>
                    )}

                    {view === TimeLineView.FINANCE &&
                      !!ce.costElement?.restBudget &&
                      ce.costElement?.totalValue !== ce.costElement?.restBudget &&
                      renderCostElementRestBudget(ce, element.level)}

                    {view === TimeLineView.FINANCE && (
                      <>
                        {renderCostElementCommitments(
                          ce,
                          element.level,
                          i
                        )}
                        {renderCostElementContracts(ce, element.level, i)}
                        {renderCostElementInvoiceRecipient(
                          ce,
                          element.level,
                          i
                        )}
                      </>
                    )}
                  </Fragment>
                );
              })}
              {renderElements(element.children)}
            </>
          )}
          {/* ------- TOGGLE CONTENT END -------- */}
        </Fragment>
      );
    });
  };

  return renderElements(elements);
};
