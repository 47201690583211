import { formatPercentage } from '@client/shared/utilities';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DecoratedElement, RiskElement } from '../../hooks';
import { getRiskCatalogTranslation } from '../../utils';
import { CalculateElementGroupSum } from '../CalculateElementGroupSum';
import { ContextMenuItem, PencilIcon, TrashIcon, AddIcon } from '@client/shared/toolkit';
import { useLoadedProjectId } from '@client/project/store';
import { useValidateProjectPermission } from '@client/shared/permissions';
import { TimeLineView } from '../Timeline';
import { Column } from '../CalculateContainer';
import { OptionalColumn } from '../CalculateSectionHeader';
import { CalculateColumns } from '../CalculateColumns';
import {
  CalculateGroupElementInformationColumn,
  CalculateInformationColumnContent
} from '../CalculateGroupElementInformationColumn';
import { ElementRowMenu } from '../ElementRowMenu';
import { FormattedCurrency } from '@client/project/shared';
import { CalculateElementRowFinanceColumn } from '../CalculateElementRowFinanceColumn';
import { CalculateSelectableColumn } from '../CalculateSelectableColumn';

interface RiskGroupElementProps {
  item: DecoratedElement<RiskElement>;
  selectedVersionId: string;
  expanded?: boolean;
  onClick?: () => void;
  onCodeClick?: () => void;
  onNewElement?: () => void;
  onRemoveBudget?: () => void;
  isSelected?: boolean;
  optionalColumn: OptionalColumn;
  obligoColumn: OptionalColumn;
  column?: Column;
  view: TimeLineView;
  hoveredElementId: string | undefined;
  setHoveredElementId: (id: string | undefined) => void;
  isReadOnly: boolean;
  searchResults: string[] | null;
}

export const RiskGroupElement = ({
  expanded = false,
  item: {
    children,
    element: { group, showError },
    level,
  },
  onClick,
  onCodeClick,
  onNewElement,
  onRemoveBudget,
  view,
  isReadOnly,
  obligoColumn,
  optionalColumn,
  searchResults,
}: RiskGroupElementProps) => {
  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();
  const canDeleteRisks = useValidateProjectPermission(['RISKS_DELETE'], loadedProjectId ?? '');
  const canWriteRisks = useValidateProjectPermission(['RISKS_WRITE'], loadedProjectId ?? '');

  const handleOnClick = (evt: React.MouseEvent<HTMLDivElement>) => {
    evt.stopPropagation();
    onClick?.();
  };

  const handleOnCodeClick = (evt: React.MouseEvent<HTMLDivElement>) => {
    evt.stopPropagation();
    onCodeClick?.();
  };

  const childrenErrors = (children: DecoratedElement<RiskElement>[]): boolean => {
    return children.some((child) => {
      if (child.element.riskElement && child.element.riskElement?.timelineErrors?.length > 0) {
        return true;
      } else if (
        child.element.group &&
        (child.element.group.calculationState === 'Overflow' ||
          (child.element.group.timelineElement && child.element.group.timelineElement?.errors.length > 0))
      ) {
        return true;
      } else if (child.children.length > 0) {
        return childrenErrors(child.children);
      } else {
        return false;
      }
    });
  };

  const showExpanded = useMemo(() => {
    return expanded && children.some((e) => !!e.element.riskElement);
  }, [expanded, children]);

  const groupMenuItems = useMemo<ContextMenuItem[]>(() => {
    const items: ContextMenuItem[] = [];

    if (onNewElement) {
      items.push({
        isDisabled: !canWriteRisks,
        label: t('projectCalculate.rowMenu.newRiskElementTitle'),
        subtitle: t('projectCalculate.rowMenu.newRiskElementSubTitle'),
        onClick: onNewElement,
        icon: <AddIcon />,
      });
    }
    if (onClick) {
      items.push({
        label: t('projectCalculate.rowMenu.updateRiskGroupElementTitle'),
        subtitle: t('projectCalculate.rowMenu.updateRiskGroupElementSubTitle'),
        onClick: onClick,
        icon: <PencilIcon />,
      });
    }
    if (onRemoveBudget) {
      items.push({
        isDisabled: !canDeleteRisks,
        label: t('projectCalculate.rowMenu.removeRiskGroupBudgetTitle'),
        subtitle: t('projectCalculate.rowMenu.removeGroupBudgetSubTitle'),
        onClick: onRemoveBudget,
        icon: <TrashIcon />,
      });
    }
    return items;
  }, [t, onNewElement, onClick, onRemoveBudget, canWriteRisks, canDeleteRisks]);

  const translatedTitle = useMemo(() => {
    return getRiskCatalogTranslation(group?.description ?? '');
  }, [group?.description])

  const showValues = searchResults
    ? searchResults.includes(group?.riskElementId ?? '') || searchResults.includes(group?.groupId ?? '')
    : true;

  return (
    <CalculateColumns
      view={view}
      informationColumnContent={(
        <CalculateGroupElementInformationColumn
          level={level}
          view={view}
          handleOnClick={onClick ? handleOnClick : undefined}
          leftColumn={(
            <CalculateInformationColumnContent
              showCode={!!group?.code}
              code={group?.groupId ? group?.code : t('projectCalculate.riskTypeCode')}
              level={level}
              handleOnCodeClick={handleOnCodeClick}
              showExpanded={showExpanded}
              showError={showError || childrenErrors(children)}
              title={translatedTitle ? translatedTitle : t('projectCalculate.riskType')}
              >
              {(group?.hasFormula) && <sup className="font-normal italic text-gray-400">&nbsp;fx</sup>}
            </CalculateInformationColumnContent>
          )}
          rightColumn={(
            showValues && <>
              <div className="pl-2 hidden w-2/12 md:flex text-sm text-right flex-row justify-end items-center leading-tight">
                {group?.riskElementId ? (
                  <>
                    {group && (
                      <div>
                        <FormattedCurrency amount={group.amountAtRisk} />
                        <div className="text-xs font-light text-neutral-500">{t('projectCalculate.riskValue')}</div>
                      </div>
                    )}
                  </>
                ) : (
                  <> {'-'}</>
                )}
              </div>
              <div className="hidden w-5/12 md:flex xl:w-5/12 text-sm text-right flex-row justify-end items-center leading-tight">
                <div>
                  {group?.probability ? (
                    <>
                      {formatPercentage((group.probability ?? 0) / 100)}
                      <div className="text-xs font-light text-neutral-500">
                        {t('projectCalculate.riskElementLabelProbability')}
                      </div>
                    </>
                  ) : (
                    <>{'-'}</>
                  )}
                </div>
              </div>
              {group && (
                <CalculateElementGroupSum
                  classNames={`w-5/12`}
                  calculationState={group?.calculationState}
                  calculatedValue={group?.calculatedValue}
                  effectiveValue={group?.effectiveValue}
                  difference={group?.difference}
                  total={group?.total}
                  overflow={group?.overflow}
                  remainder={group?.remainder}
                  column={Column.INFORMATION}
                />
              )}
            </>
          )}
          menu={!isReadOnly && (
            <ElementRowMenu items={groupMenuItems} />
          )}
        />
      )}
      currentColumnContent={(
        <CalculateElementRowFinanceColumn
          level={level}
          handleOnClick={handleOnClick}
          columnType={Column.CURRENT}
          view={view}
        >
          {group?.calculationState === 'Empty' ? (
            <div>&nbsp;</div>
          ) : (
            <>
              {group?.calculationState === 'JustTotal' && !!group?.total && (
                <FormattedCurrency amount={group?.total} />
              )}
              {group?.calculationState === 'JustCalculated' && !!group.calculatedValue && (
                <FormattedCurrency amount={group?.calculatedValue} />
              )}
              {group?.calculationState !== 'JustCalculated' && group?.calculationState !== 'JustTotal' && !!group?.effectiveValue && (
                <FormattedCurrency amount={group?.effectiveValue} />
              )}
            </>
          )}
        </CalculateElementRowFinanceColumn>
      )}
      obligoColumnContent={(
        <CalculateSelectableColumn
          column={obligoColumn}
          level={level}
          handleOnClick={handleOnClick}
          columnType={Column.OBLIGO}
          view={view}
          values={group}
          type="riskGroup"
        />
      )}
      optionalColumnContent={(
        <CalculateSelectableColumn
          column={optionalColumn}
          level={level}
          handleOnClick={handleOnClick}
          columnType={Column.OPTIONAL_COLUMN}
          view={view}
          values={group}
          type="riskGroup"
        />
      )}
    />
  )
};
