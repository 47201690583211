import { Button, LoadingIndicator, Modal, CompanyAvatar, SigmaIcon } from '@client/shared/toolkit';
import { KeyboardEvent, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import {
  AclTenant,
  FormulaElementType,
  FormulaPayload,
  FormulaStrategyValueResponseModel,
  useApiPostCalculateFormulaMutation,
} from '@client/shared/api';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { CalculatorIcon } from '@heroicons/react/24/outline';
import { formatNumber, isEmpty, safeMutation } from '@client/shared/utilities';
import { FormulaBuilder, FormulaBuilderConstructorArgs } from '../utils';
import { getSizes, useFormulaEditorDataset } from '../hooks';
import { RenderSection, Tree } from './Tree';
import { RenderTaxonomy } from './RenderTaxonomy';
import { RenderCostElement } from './RenderCostElement';
import { RenderPlot } from './RenderPlot';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { flattenTaxonomyTree } from '@client/project/shared';
import { FormulaEditorBenchmarkModal, FormulaInput, RenderEarnings } from '.';
import { useUi } from '@client/shared/store';

type FormulaEditorModalProps = {
  defaultFormula?: string;
  onClose: (show: boolean) => void;
  factor?: FormulaStrategyValueResponseModel | null;
  catalogElementId?: string;
  catalogElementType: FormulaElementType;
  saveFormula: (data: FormulaStrategyValueResponseModel | null) => void;
  disabled?: boolean;
};

const HOVER_DELAY = 300;

enum HelpSectionId {
  SUM = 'HELP_SUM',
  SUBTRACT = 'HELP_SUBTRACT',
  MULTIPLY = 'HELP_MULTIPLY',
  DIVIDE = 'HELP_DIVIDE',
  TERNERY = 'HELP_TERNERY',
  POWER = 'HELP_POWER',
  COST_ELEMENT = 'HELP_COST_ELEMENT',
  EARNING_ELEMENT = 'HELP_EARNING_ELEMENT',
  PLOT = 'HELP_PLOT',
  BUILDING = 'HELP_BUILDING',
  OPERATORS = 'HELP_OPERATORS',
  REFERENCE_VALUES = 'HELP_REFERENCE_VALUES',
}

type OperatorType = {
  label: string;
  value: string;
  description: HelpSectionId;
};

type BenchmarkTenant = {
  tenant: AclTenant;
  icon: ReactNode;
};

const HelpSection = (props: { label: string; description: string; syntax?: ReactNode; example?: ReactNode }) => {
  const { t } = useTranslation();
  return (
    <div className="p-5 overflow-auto break-words">
      <h2 className="font-bold text-lg pb-2">{props.label}</h2>
      <p className="pb-2">{props.description}</p>
      {props.syntax && (
        <div>
          <h2 className="text-sm font-bold">{t('common.syntax')}</h2>
          <div className="bg-gray-100 w-auto p-1">{props.syntax}</div>
        </div>
      )}
      {props.example && (
        <div>
          <h2 className="pt-2 text-sm font-bold">{t('common.example')}</h2>
          <div className="bg-gray-100 w-auto p-1">{props.example}</div>
        </div>
      )}
    </div>
  );
};

const HelpSum = () => {
  const { t } = useTranslation();
  return (
    <HelpSection
      label={t('formulaEditor.sum.header')}
      description={t('formulaEditor.sum.description')}
      syntax={
        <div>
          <div>SUM(2000;3000)</div>
          <div>2000 + 3000</div>
        </div>
      }
      example={<div>200 + '300 {t('formulaEditor.description.rent')}'.BGF + SUM(2000;3000)</div>}
    />
  );
};

const HelpSubtract = () => {
  const { t } = useTranslation();
  return (
    <HelpSection
      label={t('formulaEditor.subtract.header')}
      description={t('formulaEditor.subtract.description')}
      syntax={
        <div>
          <div>SUB(2000;1000)</div>
          <div>2000 - 1000</div>
        </div>
      }
      example={<div> 3000 + (4000 - 1000) + SUB(6000;1000)</div>}
    />
  );
};

const HelpMultiply = () => {
  const { t } = useTranslation();
  return (
    <HelpSection
      label={t('formulaEditor.multiply.header')}
      description={t('formulaEditor.multiply.description')}
      syntax={
        <div>
          <div>MUL(6;3)</div>
          <div>6 * 3</div>
        </div>
      }
      example={<div>5 + (6 * 3) + MUL(9;3)</div>}
    />
  );
};

const HelpDivide = () => {
  const { t } = useTranslation();
  return (
    <HelpSection
      label={t('formulaEditor.divide.header')}
      description={t('formulaEditor.divide.description')}
      syntax={
        <div>
          <div>DIV(6;3)</div>
          <div>6 / 3</div>
        </div>
      }
      example={<div>5 + (6 / 3) + DIV(9;3)</div>}
    />
  );
};

const HelpPower = () => {
  const { t } = useTranslation();
  return (
    <HelpSection
      label={t('formulaEditor.power.header')}
      description={t('formulaEditor.power.description')}
      syntax={
        <div>
          <div>POW(2;3)</div>
        </div>
      }
      example={<div>5 + (6 / 3) + POW(2;3)</div>}
    />
  );
};

const HelpBuilding = () => {
  const { t } = useTranslation();
  return (
    <HelpSection
      label={t('projectTaxonomy.taxonomyType.Building')}
      description={t('formulaEditor.building.description')}
      syntax={<div>{t('formulaEditor.building.syntax')}</div>}
      example={<div>{t('formulaEditor.building.example')}</div>}
    />
  );
};

const HelpPlot = () => {
  const { t } = useTranslation();
  return (
    <HelpSection
      label={t('projectTaxonomy.plotType.Plot')}
      description={t('formulaEditor.plot.description')}
      syntax={<div>{t('formulaEditor.plot.syntax')}</div>}
      example={<div>{t('formulaEditor.plot.example')}</div>}
    />
  );
};

const HelpCostElements = () => {
  const { t } = useTranslation();
  return (
    <HelpSection
      label={t('projectCalculate.formulaLabelCostElement')}
      description={t('formulaEditor.cost-element.description')}
      syntax={<div>{t('formulaEditor.cost-element.syntax')}</div>}
      example={<div>{t('formulaEditor.cost-element.example')}</div>}
    />
  );
};

const HelpEarningElements = () => {
  const { t } = useTranslation();
  return (
    <HelpSection
      label={t('projectCalculate.formulaLabelEarningElement')}
      description={t('formulaEditor.earning-element.description')}
      syntax={<div>{t('formulaEditor.earning-element.syntax')}</div>}
      example={<div>{t('formulaEditor.earning-element.example')}</div>}
    />
  );
};

const HelpOperators = () => {
  const { t } = useTranslation();
  return (
    <HelpSection
      label={t('formulaEditor.operators')}
      description={t('formulaEditor.operators.description')}
      syntax={
        <div>
          {/* eslint-disable-next-line react/jsx-no-comment-textnodes */}
          <div>// IF ELSE</div>
          <div>100&nbsp;&gt;&nbsp;200&nbsp;?&nbsp;200&nbsp;:&nbsp;100</div>
        </div>
      }
      example={
        <div>
          {/* eslint-disable-next-line react/jsx-no-comment-textnodes */}
          <div>// IF ELSE</div>
          <div>
            '{t('formulaEditor.description.building')}'.Bgf&nbsp;&gt;&nbsp;1000&nbsp;? '
            {t('formulaEditor.description.building')}'.Bgf&nbsp;+&nbsp;100&nbsp;:0
          </div>
        </div>
      }
    />
  );
};

const HelpReferenceValues = ({ tenantName }: { tenantName?: string }) => {
  const { t } = useTranslation();
  return (
    <HelpSection
      label={t('projectCalculate.benchmarkingReferenceValues')}
      description={t('projectCalculate.benchmarkingReferenceValuesDescription', { tenant: tenantName ?? '' })}
    />
  );
};

const HelpTernary = () => {
  const { t } = useTranslation();
  return (
    <HelpSection
      label={t('formulaEditor.ternery')}
      description={t('formulaEditor.ternery.description')}
      syntax={
        <div>
          {/* eslint-disable-next-line react/jsx-no-comment-textnodes */}
          <div>// IF ELSE</div>
          <div>100&nbsp;&gt;&nbsp;200&nbsp;?&nbsp;200&nbsp;:&nbsp;100</div>
        </div>
      }
      example={
        <div>
          {/* eslint-disable-next-line react/jsx-no-comment-textnodes */}
          <div>// IF ELSE</div>
          <div>
            '{t('formulaEditor.description.building')}'.Bgf&nbsp;&gt;&nbsp;1000&nbsp;? '
            {t('formulaEditor.description.building')}'.Bgf&nbsp;+&nbsp;100&nbsp;:0
          </div>
        </div>
      }
    />
  );
};

/** Presentational Components */

const RenderOperator = (props: {
  operator: OperatorType;
  displayOperatorHelp: (desc: string) => void;
  addOperatorToFormula: (operator: string) => void;
  disabled?: boolean;
}) => {
  return (
    <div
      className={classNames('w-full flex flex-row flex-wrap p-2 gap-x-2 text-base uppercase', {
        'cursor-pointer bg-white': !props.disabled,
        'bg-gray-200': props.disabled,
      })}
      onMouseEnter={(event) => {
        const target = event.target as HTMLElement;
        if (target.matches(':hover')) {
          setTimeout(() => {
            props.displayOperatorHelp(props.operator.description);
          }, HOVER_DELAY);
        }
      }}
      onClick={() => props.addOperatorToFormula(props.operator.value)}
    >
      <SigmaIcon className="w-5 h-5 text-emerald-600" />
      <span>{props.operator.label}</span>
    </div>
  );
};

/**
 * Benchmarking
 */

const RenderBenchmarkTenants = (props: {
  benchmarkTenant: BenchmarkTenant;
  catalogElementId: string;
  catalogElementType: FormulaElementType;
  displayTenantHelp: (desc: string) => void;
  addBenchmarkValueToFormula: (value: string) => void;
  disabled?: boolean;
}) => {
  const {
    benchmarkTenant,
    catalogElementId,
    displayTenantHelp,
    addBenchmarkValueToFormula,
    disabled,
    catalogElementType,
  } = props;
  const { t } = useTranslation();

  const [isOpenBenchmarkModal, setIsOpenBenchmarkModal] = useState(false);

  return (
    <>
      <div
        className={classNames('w-full flex flex-row flex-wrap p-2 gap-x-2 text-base items-center', {
          'cursor-pointer bg-white': !disabled,
          'bg-gray-200': disabled,
        })}
        onMouseEnter={(event) => {
          const target = event.target as HTMLElement;
          if (target.matches(':hover')) {
            setTimeout(() => {
              displayTenantHelp(HelpSectionId.REFERENCE_VALUES);
            }, HOVER_DELAY);
          }
        }}
        onClick={() => (!disabled ? setIsOpenBenchmarkModal(true) : null)}
      >
        <CompanyAvatar
          tenantName={benchmarkTenant.tenant?.name ?? ''}
          tenantId={benchmarkTenant.tenant?.tenantId ?? ''}
          hasAvatar={benchmarkTenant.tenant?.hasAvatar ?? false}
          lastUpdate={benchmarkTenant.tenant?.avatarLastUpdate ?? ''}
          classNames="w-10 h-10"
        />
        <span>
          {t('projectCalculate.benchmarkingTenantProjects', {
            tenant: benchmarkTenant.tenant?.name ? benchmarkTenant.tenant?.name : t('app.tenant'),
          })}
        </span>
      </div>
      <FormulaEditorBenchmarkModal
        isOpen={isOpenBenchmarkModal}
        onClose={() => setIsOpenBenchmarkModal(false)}
        addBenchmarkValueToFormula={addBenchmarkValueToFormula}
        catalogElementId={catalogElementId}
        catalogElementType={catalogElementType}
      />
    </>
  );
};

/**
 * Logical Components
 */

export const FormulaEditorModal = ({
  defaultFormula,
  onClose,
  factor,
  saveFormula,
  catalogElementId,
  catalogElementType,
  disabled,
}: FormulaEditorModalProps) => {
  const { t } = useTranslation();
  const [visualFormula, setVisualFormula] = useState(() => {
    return defaultFormula ?? '';
  });

  const ui = useUi();

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const flatPlots = useFormulaEditorDataset({
    projectId: loadedProjectId ?? 'unset',
    calculationModelId: loadedVariantId ?? '',
  }).values.flatPlots;

  const [formulaPayload, setFormulaPayload] = useState<FormulaPayload>();
  const [isFormulaValid, setIsFormulaValid] = useState<boolean>(false);

  const [postElement, { isLoading, data }] = useApiPostCalculateFormulaMutation({});

  const calculatedValue = data?.calculatedValue;

  const [helpSectionId, setHelpSectionId] = useState<string>('');

  const [inputOffset, setInputOffset] = useState({ start: 0, end: 0 });
  const [isLoadingDataset, setIsLoadingDataset] = useState(false);

  const formulaEditorData = useFormulaEditorDataset({
    projectId: loadedProjectId ?? 'unset',
    calculationModelId: loadedVariantId ?? '',
  });

  const operators = useMemo(
    () => ({
      primitive: [
        { label: t('formulaEditor.sum.header'), value: 'SUM(;)', description: HelpSectionId.SUM },
        { label: t('formulaEditor.subtract.header'), value: 'SUB(;)', description: HelpSectionId.SUBTRACT },
        { label: t('formulaEditor.multiply.header'), value: 'MUL(;)', description: HelpSectionId.MULTIPLY },
        { label: t('formulaEditor.divide.header'), value: 'DIV(;)', description: HelpSectionId.DIVIDE },
        { label: t('formulaEditor.power.header'), value: 'POW(;)', description: HelpSectionId.POWER },
        { label: t('formulaEditor.ternery.header'), value: '', description: HelpSectionId.TERNERY },
      ],
    }),
    [t],
  );

  const benchmarkTenants = useMemo(
    () => (ui.appUser.tenant ? [{ tenant: ui.appUser.tenant, icon: <CalculatorIcon className="w-5 h-5 transform" /> }] : []),
    [ui.appUser.tenant],
  );

  const OPTIONS: FormulaBuilderConstructorArgs = useMemo(() => {
    return {
      ...formulaEditorData.values,
      sizes: getSizes(),
    };
  }, [formulaEditorData]);

  useEffect(() => {
    if (formulaEditorData.isLoading !== isLoadingDataset) setIsLoadingDataset(formulaEditorData.isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formulaEditorData]);

  const formulaBuilder = useMemo(() => {
    return new FormulaBuilder(OPTIONS);
  }, [OPTIONS]);

  useEffect(() => {
    if (factor?.expression) {
      const parsedFormula = formulaBuilder.parseFormula(factor?.expression, factor?.expressionParameters ?? []);
      setVisualFormula(parsedFormula);
    }
  }, [factor?.expression, formulaBuilder, factor?.expressionParameters]);

  const handleChangeTextInput = useCallback(
    (value: string) => {
      setIsFormulaValid(false);
      setVisualFormula(value);
    },
    [setIsFormulaValid, setVisualFormula],
  );

  const appendToFormula = (value: string) => {
    setIsFormulaValid(false);
    const translatedValue = value
      .replace(new RegExp('BGF', 'g'), t('project.bgfRoi'))
      .replace(new RegExp('NGF', 'g'), t('project.ngf'))
      .replace(new RegExp('MFG', 'g'), t('project.mf'))
      .replace(new RegExp('Amount', 'g'), t('project.amount'))
      .replace(new RegExp('GRZ', 'g'), t('project.grz'))
      .replace(new RegExp('GFZ', 'g'), t('project.gfz'));

    setVisualFormula((vf) => {
      const prefix = vf.substring(0, inputOffset.start);
      const suffix = vf.substring(inputOffset.start);
      return `${prefix}${translatedValue}${suffix}`;
    });
  };

  const appendValueToFormula = (value: string) => {
    setIsFormulaValid(false);
    setVisualFormula((vf) => {
      const prefix = vf.substring(0, inputOffset.start);
      const substring = vf.substring(inputOffset.start, inputOffset.end);
      if (substring.length > 0) {
        value = value.replace(';', substring);
      }
      const suffix = vf.substring(inputOffset.end);
      return `${prefix}${value}${suffix}`;
    });
  };

  const displayHelp = (desc: string) => {
    setHelpSectionId(desc);
  };

  const calculateFormulaExpression = async () => {
    const { expression, expressionParameters } = formulaBuilder.generateFormula(visualFormula.toString());

    const body: FormulaPayload = {
      expression: expression,
      expressionParameterPayloads: expressionParameters?.map((p) => ({
        costCatalogElementId: p.costCatalogElement?.id,
        earningsCatalogElementId: p.earningsCatalogElement?.id,
        taxonomyElementId: p.taxonomyItem?.id,
        plotId: p.plot?.id,
        position: p.position,
      })),
      catalogElementId: isEmpty(catalogElementId) ? null : catalogElementId,
      elementType: catalogElementType,
    };
    try {
      await safeMutation(
        postElement,
        {
          projectId: loadedProjectId ?? 'unset',
          calculationModelId: loadedVariantId ?? '',
          body,
        },
        isLoading,
      );
      setIsFormulaValid(true);
      setFormulaPayload({
        expression: expression,
        elementType: 'CostElement',
        expressionParameterPayloads: expressionParameters,
      });
    } catch (e) {
      console.log(e);
      setIsFormulaValid(false);
    }
  };

  useEffect(() => {
    if (visualFormula) {
      calculateFormulaExpression();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visualFormula]);

  const saveFormulaExpression = async () => {
    const formulaExpressionBody: FormulaStrategyValueResponseModel = {
      formulaId: '',
      expression: formulaPayload?.expression ?? '',
      expressionParameters: formulaPayload?.expressionParameterPayloads ?? [],
      value: calculatedValue ?? 0,
    };
    saveFormula(formulaExpressionBody);
    onClose(false);
  };

  const resetFormula = () => {
    setVisualFormula('');
    saveFormula(null);
  };

  const displayCalculatedValue = useMemo(() => {
    const type = typeof calculatedValue;
    switch (type) {
      case 'number':
        return formatNumber(calculatedValue);
      case 'string':
        return calculatedValue;
      case 'boolean':
        return calculatedValue ? 'true' : 'false';
      default:
        return '';
    }
  }, [calculatedValue]);

  const isSaveDisabled = !(typeof data?.calculatedValue === 'number' && isFormulaValid);

  const handleKeyDownEvents = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.stopPropagation();
    }
  };

  const delayHelperDisplay = (helpSectionId: HelpSectionId) => {
    setTimeout(() => {
      displayHelp(helpSectionId);
    }, HOVER_DELAY);
  };

  return (
    <Modal isOpen onClose={onClose} variant="full-height">
      {isLoading || isLoadingDataset ? (
        <div className="absolute z-10 flex flex-row justify-center items-center w-full h-full ">
          <LoadingIndicator />
        </div>
      ) : null}
      <div className="w-full h-full">
        <div className="flex flex-col pt-0 w-full h-full" onKeyDown={handleKeyDownEvents}>
          <div className="editor-formula-input-container p-5 shadow-md z-10">
            <div className="text-lg font-bold">{t('projectCalculate.formulaEditor')}</div>
            <div className="flex flex-row bg-white gap-x-3">
              <div className="w-5/6">
                <FormulaInput
                  value={visualFormula}
                  onChange={handleChangeTextInput}
                  onUpdateOffset={setInputOffset}
                  disabled={disabled}
                />
              </div>
              <div className="w-1/6 flex justify-start items-end text-2xl font-bold text-slate-400 bg-white">
                {displayCalculatedValue && (
                  <div className="w-full before:pr-1 before:content-['='] text-start break-all">
                    {displayCalculatedValue}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={classNames('flex flex-row max-h-[584px]')}>
            <div className="w-3/5 flex-grow-0 flex flex-col overflow-y-auto px-3 max-h-[584px]">
              {OPTIONS.taxonomy && (
                <RenderSection
                  label={t('projectTaxonomy.taxonomyType.Building')}
                  onMouseEnter={(e) => {
                    const target = e.target as HTMLElement;
                    if (target.matches(':hover')) delayHelperDisplay(HelpSectionId.BUILDING);
                  }}
                >
                  <Tree
                    nodeData={OPTIONS.taxonomy}
                    renderNode={(taxonomy, isExpanded, toggleExpanded) => (
                      <RenderTaxonomy
                        expanded={isExpanded}
                        taxonomy={taxonomy}
                        taxonomyTree={flattenTaxonomyTree(OPTIONS.taxonomy)}
                        onSelectBuilding={!disabled ? appendToFormula : () => null}
                        toggleExpansion={toggleExpanded}
                        disabled={disabled}
                      />
                    )}
                  />
                </RenderSection>
              )}
              <RenderSection
                label={t('projectTaxonomy.plotType.Plot')}
                onMouseEnter={(e) => {
                  const target = e.target as HTMLElement;
                  if (target.matches(':hover')) delayHelperDisplay(HelpSectionId.PLOT);
                }}
              >
                <Tree
                  nodeData={OPTIONS.plot}
                  renderNode={(plot, isExpanded, toggleExpanded) => (
                    <RenderPlot
                      plot={plot}
                      plotTree={flatPlots}
                      onSelectPlot={!disabled ? appendToFormula : () => null}
                      expanded={isExpanded}
                      toggleExpansion={toggleExpanded}
                      disabled={disabled}
                    />
                  )}
                />
              </RenderSection>
              <RenderSection
                label={t('projectCalculate.formulaLabelCostElement')}
                onMouseEnter={(e) => {
                  const target = e.target as HTMLElement;
                  if (target.matches(':hover')) delayHelperDisplay(HelpSectionId.COST_ELEMENT);
                }}
              >
                {OPTIONS.costElements
                  .filter((x) => !x.isUnassignedContainer)
                  .map((c) => (
                    <Tree
                      key={c.id}
                      nodeData={c}
                      renderNode={(costElement, isExpanded, toggleExpanded) => (
                        <RenderCostElement
                          key={c.id}
                          costElement={costElement}
                          expanded={isExpanded}
                          toggleExpansion={toggleExpanded}
                          onSelectCostElement={!disabled ? appendToFormula : () => null}
                          disabled={disabled}
                        />
                      )}
                    />
                  ))}
              </RenderSection>
              <RenderSection
                label={t('projectCalculate.formulaLabelRevenueElement')}
                onMouseEnter={(e) => {
                  const target = e.target as HTMLElement;
                  if (target.matches(':hover')) delayHelperDisplay(HelpSectionId.EARNING_ELEMENT);
                }}
              >
                {OPTIONS.earningsElements.map((c, idx) => (
                  <Tree
                    key={c.group?.code ?? c.earningsElement?.id ?? idx}
                    nodeData={c}
                    renderNode={(earningElement, isExpanded, toggleExpanded) => (
                      <RenderEarnings
                        key={c.group?.code ?? c.earningsElement?.id ?? idx}
                        earningElement={c}
                        expanded={isExpanded}
                        toggleExpansion={toggleExpanded}
                        onSelectEarningElement={!disabled ? appendToFormula : () => null}
                        disabled={disabled}
                      />
                    )}
                  />
                ))}
              </RenderSection>
              <RenderSection
                label={t('projectCalculate.benchmarkingReferenceValues')}
                onMouseOverHeader={(e) => {
                  const target = e.target as HTMLElement;
                  if (target.matches(':hover')) delayHelperDisplay(HelpSectionId.REFERENCE_VALUES);
                }}
              >
                <div className="grid grid-row auto-rows-fr gap-0.5">
                  {benchmarkTenants.map((benchmarkTenant: BenchmarkTenant) => (
                    <RenderBenchmarkTenants
                      key={benchmarkTenant.tenant.tenantId}
                      catalogElementType={catalogElementType}
                      catalogElementId={catalogElementId ?? ''}
                      benchmarkTenant={benchmarkTenant}
                      addBenchmarkValueToFormula={!disabled ? appendToFormula : () => null}
                      displayTenantHelp={displayHelp}
                      disabled={disabled}
                    />
                  ))}
                </div>
              </RenderSection>
              <RenderSection
                label={t('formulaEditor.operators')}
                onMouseOverHeader={(e) => {
                  const target = e.target as HTMLElement;
                  if (target.matches(':hover')) delayHelperDisplay(HelpSectionId.OPERATORS);
                }}
              >
                <div className="grid grid-row auto-rows-fr gap-0.5">
                  {operators.primitive.map((operator: OperatorType) => (
                    <RenderOperator
                      key={operator.label}
                      operator={operator}
                      addOperatorToFormula={!disabled ? appendValueToFormula : () => null}
                      displayOperatorHelp={displayHelp}
                      disabled={disabled || operator.description === 'HELP_TERNERY'}
                    />
                  ))}
                </div>
              </RenderSection>
            </div>
            <div className="bg-white p-5 w-2/5 overflow-y-auto max-h-[584px] transition">
              {helpSectionId === HelpSectionId.SUM && <HelpSum />}
              {helpSectionId === HelpSectionId.SUBTRACT && <HelpSubtract />}
              {helpSectionId === HelpSectionId.MULTIPLY && <HelpMultiply />}
              {helpSectionId === HelpSectionId.DIVIDE && <HelpDivide />}
              {helpSectionId === HelpSectionId.TERNERY && <HelpTernary />}
              {helpSectionId === HelpSectionId.POWER && <HelpPower />}
              {helpSectionId === HelpSectionId.BUILDING && <HelpBuilding />}
              {helpSectionId === HelpSectionId.PLOT && <HelpPlot />}
              {helpSectionId === HelpSectionId.COST_ELEMENT && <HelpCostElements />}
              {helpSectionId === HelpSectionId.EARNING_ELEMENT && <HelpEarningElements />}
              {helpSectionId === HelpSectionId.OPERATORS && <HelpOperators />}
              {helpSectionId === HelpSectionId.REFERENCE_VALUES && <HelpReferenceValues tenantName={ui.appUser.tenant?.name} />}
            </div>
          </div>
          <div className="grid grid-cols-2 w-full footer h-18 shadow-inner z-10 p-5">
            {!disabled && (
              <div>
                <Button variant="danger" onClick={() => resetFormula()}>
                  {t('common.reset')}
                </Button>
              </div>
            )}
            <div className="flex flex-nowrap justify-end gap-x-2">
              <div>
                <Button
                  variant="custom"
                  className="bg-gray-200 text-gray-500 hover:bg-slate-200 focus:ring"
                  onClick={() => {
                    onClose(false);
                  }}
                >
                  {t('common.cancel')}
                </Button>
              </div>
              {!disabled && (
                <div>
                  <Button onClick={saveFormulaExpression} disabled={isSaveDisabled}>
                    {t('common.save')}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
