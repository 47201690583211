import { CalculationModelMetadata } from '@client/shared/api';
import { useState } from 'react';
import { VariantListItem } from './VariantListItem';
import { VariantSlideOver } from './VariantSlideOver';
import { useTranslation } from 'react-i18next';
import {
  SlideOver,
  VariantActiveIcon,
  VariantAlternativeIcon,
  VariantDeactivatedIcon,
  VariantLockedByUserIcon,
} from '@client/shared/toolkit';
import { useLoadedProject, useLoadedProjectVariants, useLoadedVariantId } from '@client/project/store';

export const VariantList = () => {
  const { t } = useTranslation();

  const [isOpenSlideOver, setIsOpenSlideOver] = useState(false);
  const [selectedVariantId, setSelectedVariantId] = useState<string | undefined>();

  const { data: loadedProject } = useLoadedProject();
  const loadedVariantId = useLoadedVariantId();
  const { data } = useLoadedProjectVariants();

  const version = data.filter((x) => x.type === 'Version');
  const variants = data.filter((x) => x.type === 'Variant');
  const archivedVersions = data.filter((x) => x.type === 'ArchivedVersion');
  const userSnapshots = data.filter((x) => x.type === 'UserSnapshot');
  const templateScenarios = data.filter((x) => x.type === 'Scenario');
  const selectedVariant = data.find((x) => x.id === selectedVariantId);

  const handleOpenSlideOver = (variantId: string | undefined) => {
    if (variantId) {
      setSelectedVariantId(variantId);
      setIsOpenSlideOver(true);
    }
  };

  const RenderSegment = ({ variants }: { variants: CalculationModelMetadata[] }) => {
    return variants.length > 0 ? (
      <div className="bg-white shadow-md w-full">
        {variants.map((variant) => (
          <VariantListItem
            key={variant.id}
            variant={variant}
            isSelected={variant.id === loadedVariantId}
            onClick={() => handleOpenSlideOver(variant.id)}
          />
        ))}
      </div>
    ) : null;
  };

  return (
    <div>
      {loadedProject?.project.payload.isTemplate === false && (
        <div>
          <div className="flex flex-row items-center mb-6">
            <VariantActiveIcon className="flex-none w-8 h-8" />
            <div className="ml-2 text-2xl font-medium">{t('projectVariants.sectionActiveTitle')}</div>
          </div>

          <div className="w-full flex">
            <div className="w-2 h-auto bg-emerald-500 rounded-tl-md rounded-bl-md flex-shrink-0" />
            <RenderSegment variants={version} />
          </div>

          <div className="flex flex-row items-center mt-16 mb-6">
            <VariantAlternativeIcon className="flex-none w-8 h-8" />
            <div className="ml-2 text-2xl font-medium">{t('projectVariants.sectionAlternativeTitle')}</div>
          </div>
          <div className="w-full flex">
            <div className="w-2 h-auto bg-blue-500 rounded-tl-md rounded-bl-md flex-shrink-0" />
            <RenderSegment variants={variants} />
          </div>

          <div className="flex flex-row items-center mt-16 mb-6">
            <VariantDeactivatedIcon className="flex-none w-8 h-8" />
            <div className="ml-2 text-2xl font-medium">{t('projectVariants.sectionPreviousVersionsTitle')}</div>
          </div>
          {archivedVersions.length === 0 && (
            <div className="text-gray-500 text-sm ml-2">{t('projectVariants.noPreviousVersions')}</div>
          )}
          <div className="w-full flex">
            <div className="w-2 h-auto bg-red-500 rounded-tl-md rounded-bl-md flex-shrink-0" />
            <RenderSegment variants={archivedVersions} />
          </div>

          <div className="flex flex-row items-center mt-16 mb-6">
            <VariantLockedByUserIcon className="flex-none w-8 h-8" />
            <div className="ml-2 text-2xl font-medium">{t('projectVariants.sectionUserSnapshotsTitle')}</div>
          </div>
          {userSnapshots.length === 0 && (
            <div className="text-gray-500 text-sm ml-2">{t('projectVariants.noUserSnapshots')}</div>
          )}
          <div className="w-full flex">
            <div className="w-2 h-auto bg-slate-300 rounded-tl-md rounded-bl-md flex-shrink-0" />
            <RenderSegment variants={userSnapshots} />
          </div>
        </div>
      )}

      {loadedProject?.project.payload.isTemplate && (
        <div>
          <div className="flex flex-row items-center mb-6">
            <VariantActiveIcon className="flex-none w-8 h-8" />
            <div className="ml-2 text-2xl font-medium">{t('projectVariants.historyFilterVariants')}</div>
          </div>

          <div className="w-full flex">
            <div className="w-2 h-auto bg-orange-200 rounded-tl-md rounded-bl-md flex-shrink-0" />
            <RenderSegment variants={templateScenarios} />
          </div>
        </div>
      )}

      <SlideOver isOpen={isOpenSlideOver} onClose={() => setIsOpenSlideOver(false)}>
        <VariantSlideOver variant={selectedVariant} onClose={() => setIsOpenSlideOver(false)} />
      </SlideOver>
    </div>
  );
};
