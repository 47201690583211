import { Badge } from '../Badges';
import cn from 'classnames';
import { CancelIcon } from '../../icons';

export interface WizardSelectListFilterItem {
  label: string;
  selected: boolean;
}

export interface WizardSelectListFilterProps {
  items: WizardSelectListFilterItem[];
  onToggle: (label: string) => void;
  className?: string;
}

export const WizardSelectListFilter = (props: WizardSelectListFilterProps) => {
  const { items, onToggle, className } = props;
  if (!items.length) {
    return null;
  }
  return (
    <ul className={cn('flex w-full gap-2.5', className)}>
      {items.map((item, index) => (
        <li key={`wizard-select-list-filter-item-${index}`}>
          <WizardSelectListFilterItem item={item} onToggle={onToggle} />
        </li>
      ))}
    </ul>
  );
};

export interface WizardSelectListFilterItemProps {
  item: WizardSelectListFilterItem;
  onToggle: (label: string) => void;
}

export const WizardSelectListFilterItem = (props: WizardSelectListFilterItemProps) => {
  return (
    <Badge
      text={props.item.label}
      variant="custom"
      className={cn('text-white', {
        'bg-slate-400 py-1': !props.item.selected,
        'bg-emerald-500': props.item.selected
      })}
      onClick={() => props.onToggle(props.item.label)}
    >
      {props.item.selected && (
        <CancelIcon className="w-4 h-4" />
      )}
    </Badge>
  );
};
