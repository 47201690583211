import { settings, useUi } from '@client/shared/store';
import { useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import {
  AcceptInvitationView,
  ForgotPasswordView,
  LoginView,
  PasswordResetStartedView,
  PasswordResetSuccessfulView,
  ResetPasswordView,
  RegisterView,
  RegisterTrustedTenantView,
  SsoView,
} from '../components';
import { BG_LOGIN_SCREEN_IMAGE, LOGIN_ORNAMENT_IMAGE, LOGO_ICON_IMAGE, LOGO_TEXT_IMAGE } from '@client/shared/toolkit';

export const LoginRoute = () => {
  const buildId = settings.buildId?.slice(0, 7) ?? "(unknown)";

  const ui = useUi();
  const [searchParams] = useSearchParams();
  const redirectTarget = decodeURIComponent(searchParams.get('_r') || '/');
  const navigate = useNavigate();

  useEffect(() => {
    if (ui.appUser && ui.appUser.userId) {
      navigate(redirectTarget);
    }
  }, [ui.appUser, redirectTarget, navigate]);

  return (
    <div className="flex-grow flex items-center justify-center bg-cover relative"
      style={{ backgroundImage: `url('${BG_LOGIN_SCREEN_IMAGE}')` }}>
      <div className="bg-black absolute left-0 right-0 top-0 bottom-0 opacity-25" />
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-2xl z-50">
        <div className="flex w-full shadow sm:rounded-xl bg-gray-100 overflow-hidden">
          <div className="w-2/6 min-h-full px-2 bg-[#334155] text-white flex flex-col items-center bg-bottom bg-no-repeat bg-contain"
            style={{ backgroundImage: `url('${LOGIN_ORNAMENT_IMAGE}')` }}
          >
            <div className="flex flex-col flex-grow items-center">
              <img src={LOGO_ICON_IMAGE} className="-mt-16 absolute" alt="Logo Icon" />
              <img src={LOGO_TEXT_IMAGE} className="mt-24" alt="Logo Text" />
            </div>
            <div className="text-sm pb-4">ID: {buildId}</div>
          </div>
          <div className="py-8 px-4 sm:px-10 w-4/6">
            <Routes>
              <Route path="login" element={<LoginView />} />
              <Route path="forgot-password" element={<ForgotPasswordView />} />
              <Route path="reset-password/:token" element={<ResetPasswordView />} />
              <Route path="password-reset-started" element={<PasswordResetStartedView />} />
              <Route path="password-reset-successful" element={<PasswordResetSuccessfulView />} />
              <Route path="register/:token" element={<RegisterView />} />
              {/* Not used anymore but kept for potential future use */}
              <Route path="register-trusted-company/:token" element={<RegisterTrustedTenantView />} />
              <Route path="accept-invitation/:tokenId" element={<AcceptInvitationView />} />
              <Route path=":realm" element={<SsoView />} />

              <Route path="*" element={<Navigate replace to="login" />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};
