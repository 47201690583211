import { ProjectReadModel } from '@client/shared/api';
import {
  Button,
  SlideOver,
  SlideOverOnCloseProps,
  DecoratedCard,
  SlideOverTabOptions,
  SlideOverWithTabs,
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import React, { useState, useRef, useMemo } from 'react';
import { InvoiceCoverSheets } from '@client/project/shared';
import { ProjectData } from '../ProjectData';
import { EditProjectWorkflows } from './EditProjectWorkflows';
import { EditProjectConnections, ProjectUserGroups } from '.';
import { ProjectBenchmarkingSettings } from '../ProjectBenchmarkSettings';
import { useUi } from '@client/shared/store';

export interface EditProjectSlideOverProps extends SlideOverOnCloseProps {
  projectId: string;
  readOnly: boolean;
  project?: ProjectReadModel;
  thumbnail?: string;
}

export const EditProjectSlideOver = ({
  projectId,
  project,
  readOnly,
  thumbnail,
  onClose,
}: EditProjectSlideOverProps) => {
  const { t } = useTranslation();

  const ui = useUi();
  const isTenantOwner = ui.appUser.tenant?.isOwner;

  const formRef = useRef<HTMLFormElement>(null);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [benchmarkingDefaultValuesAvailable, setBenchmarkingDefaultValuesAvailable] = useState(false);
  const [isLoadingMessage, setIsLoadingMessage] = useState('');

  const handleTabChange = (index: number) => {
    setSelectedIndex(index);
  };

  const tabOptions: SlideOverTabOptions[] = useMemo(() => {
    const tabs = [
      {
        name: 'projectData',
        header: t('projectSetting.projectData'),
        panel: (
          <ProjectData
            project={project}
            thumbnail={thumbnail}
            onClose={onClose}
            formRef={formRef}
            readOnly={readOnly}
            setIsLoadingMessage={setIsLoadingMessage}
          />
        ),
      },
      {
        name: 'benchmarking',
        header: t('projectSetting.benchmarking.title'),
        panel: (
          <ProjectBenchmarkingSettings
            project={project}
            onClose={onClose}
            formRef={formRef}
            onDefaultValuesLoaded={(devaultValuesAvailable: boolean) =>
              setBenchmarkingDefaultValuesAvailable(devaultValuesAvailable)
            }
            readOnly={readOnly}
          />
        ),
        disabled: false,
      },
      {
        name: 'invoiceCoverSheets',
        header: t('app.settingsInvoiceCoverSheets'),
        panel: (
          <DecoratedCard>
            <DecoratedCard.Header>{t('app.settingsInvoiceCoverSheets')}</DecoratedCard.Header>
            <DecoratedCard.Content className="h-full px-5">
              <InvoiceCoverSheets type="project" projectId={projectId} />
            </DecoratedCard.Content>
          </DecoratedCard>
        ),
      },
      {
        name: 'workflows',
        header: t('app.settingsWorkflows'),
        panel: (
          <DecoratedCard>
            <DecoratedCard.Header>{t('app.settingsWorkflows')}</DecoratedCard.Header>
            <DecoratedCard.Content className="h-full px-5">
              <EditProjectWorkflows projectId={projectId} project={project} />
            </DecoratedCard.Content>
          </DecoratedCard>
        ),
      },
      {
        name: 'connections',
        header: t('projectSetting.tabConnections'),
        panel: <EditProjectConnections projectId={projectId} />,
      }
    ];
    if (isTenantOwner && !readOnly) {
      tabs.push({
        name: 'userManagement',
        header: t('projectSetting.userManagement.title'),
        panel: (
          <DecoratedCard>
            <DecoratedCard.Header>
              <div className="truncate">{t('projectSetting.userManagement.projectGroups')}</div>
            </DecoratedCard.Header>
            <DecoratedCard.Content className="h-full px-5">
              <ProjectUserGroups project={project} />
            </DecoratedCard.Content>
          </DecoratedCard>
        )
      });
    }
    return tabs;
  }, [t, project, onClose, readOnly, isTenantOwner, projectId, thumbnail]);


  return (
    <SlideOverWithTabs
      title={project?.payload.name ?? ''}
      subtitle={`${t('app.newProjectId')}: ${project?.payload.projectId ?? ''}`}
      onClose={onClose}
      tabOptions={tabOptions}
      onChange={handleTabChange}
      isLoadingMessage={isLoadingMessage}
    >
      <SlideOver.Controls>
        <div className="w-full flex justify-end">
          <div className="flex gap-2">
            <Button onClick={() => onClose(true)} className="mr-2" variant="secondary">
              {tabOptions[selectedIndex].name !== 'projectData' ? t('common.close') : t('common.cancel')}
            </Button>
            {(tabOptions[selectedIndex].name === 'projectData' ||
              tabOptions[selectedIndex].name === 'benchmarking') && (
              <Button
                onClick={() => formRef?.current?.submitForm()}
                variant="primary"
                disabled={tabOptions[selectedIndex].name === 'benchmarking' && !benchmarkingDefaultValuesAvailable}
              >
                {t('common.save')}
              </Button>
            )}
          </div>
        </div>
      </SlideOver.Controls>
    </SlideOverWithTabs>
  );
};
