import { useTranslation } from 'react-i18next';
import { DashboardWidgetProps } from '../WidgetDashboard';
import {
  AddressIcon,
  CardDataSet,
  LoadingIndicator,
  UserAvatar,
  WidgetContainer,
  WidgetContainerContent,
} from '@client/shared/toolkit';
import { getMeasureLabel, useLoadedProject, useLoadedProjectId } from '@client/project/store';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import React, { useMemo } from 'react';
import cn from 'classnames';
import { useUi } from '@client/shared/store';
import md5 from 'blueimp-md5';
import { apiEndpointUrls, useApiGetProjectCompanyPersonQuery, useApiGetTenantCompanyQuery } from '@client/shared/api';
import { getProjectThumbnail } from '@client/shared/utilities';

export const DashboardWidgetProjectInformation = (props: DashboardWidgetProps) => {
  const { widget, layout } = props;
  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();
  const project = useLoadedProject();

  const ui = useUi();
  const tenant = ui.appUser.tenant;

  const { data: loadedCompany, isFetching: isLoadingCompany } = useApiGetTenantCompanyQuery(
    {
      companyId: widget?.additionalConfig?.ProjectInformation?.clientId ?? '',
    },
    {
      skip: !widget?.additionalConfig?.ProjectInformation?.clientId,
    },
  );

  const { data: loadedCompanyPerson, isFetching: isLoadingCompanyPerson } = useApiGetProjectCompanyPersonQuery(
    {
      personId: widget?.additionalConfig?.ProjectInformation?.projectManagerId ?? '',
      projectId: loadedProjectId ?? '',
    },
    {
      skip: !widget?.additionalConfig?.ProjectInformation?.projectManagerId || !loadedProjectId,
    },
  );

  const projectData = useMemo(() => {
    return project?.data?.project?.payload;
  }, [project?.data?.project?.payload]);

  const thumbnail = useMemo(() => {
    return getProjectThumbnail(
      'Small',
      projectData,
      projectData?.coverPictureId,
      false,
    );
  }, [projectData]);

  const largeVariant = useMemo(() => {
    return layout?.h && layout.h > 1;
  }, [layout?.h]);

  const hasAddress = useMemo(() => {
    return projectData?.street || projectData?.number || projectData?.postalCode || projectData?.city;
  }, [projectData]);

  const avatarPath = useMemo(() => {
    if (tenant?.hasAvatar) {
      const updateHash = md5(tenant.avatarLastUpdate ?? '');
      return `${apiEndpointUrls.apiGetTenantAvatar}?updated=${updateHash}`;
    }
    return undefined;
  }, [tenant?.avatarLastUpdate, tenant?.hasAvatar]);

  const logoUrl: string | undefined = useMemo(() => {
    return loadedCompany?.logoFileId
      ? apiEndpointUrls.apiGetCompanyLogo.replace(':companyId', loadedCompany.id).replace(':fileId', loadedCompany.logoFileId)
      : undefined;
  }, [loadedCompany?.logoFileId, loadedCompany?.id]);

  return (
    <WidgetContainer>
      <WidgetContainerContent className="flex-1 overflow-y-auto flex flex-col justify-between px-4">
        {(isLoadingCompany || isLoadingCompanyPerson) && <LoadingIndicator />}
        {largeVariant ? (
          <div className="relative z-0">
            <LazyLoadImage
              className="h-[200px] w-full object-cover"
              alt={project?.data?.project?.payload?.name}
              src={thumbnail}
            />
            <div className="z-10 absolute left-0 -bottom-4 w-full max-h-[200px] overflow-y-auto">
              <div className="bg-white mx-3.5 inline-flex flex-col rounded-t-md py-1.5 pl-3.5 pr-10 shadow-xl">
                <div>
                  <span className="font-bold text-[22px]">{projectData?.name}&nbsp;|&nbsp;</span>
                  <span className="font-light text-[22px]">{projectData?.city}</span>
                </div>
                <span className="font-light text-[15px]">
                  {t('app.editProjectId')}: {projectData?.projectId}
                </span>
              </div>
            </div>
            {logoUrl && (
              <div className="z-10 absolute top-3.5 right-3.5 left-auto w-20 h-20 bg-white rounded-full flex items-center justify-center overflow-hidden p-2">
                <img src={logoUrl} className="w-full h-auto object-contain" alt={projectData?.name} />
              </div>
            )}
          </div>
        ) : (
          <div className="flex justify-between">
            <div>
              <div>
                <span className="font-bold text-[22px]">{projectData?.name}&nbsp;|&nbsp;</span>
                <span className="font-light text-[22px]">{projectData?.city}</span>
              </div>
              <span className="font-light text-[15px]">
                {t('app.editProjectId')}: {projectData?.projectId}
              </span>
            </div>
            {logoUrl && (
              <div className="w-20 h-20 bg-white rounded-full flex items-center justify-center overflow-hidden p-2 flex-none">
                <img src={logoUrl} className="w-full h-auto object-contain" alt={loadedCompany?.name} />
              </div>
            )}
          </div>
        )}
        <div className={cn('flex-1 relative z-10 bg-white w-full content-end', { 'mt-4': largeVariant })}>
          {largeVariant && (
            <div className="flex gap-2 w-full">
              {hasAddress && (
                <div className="flex gap-3 w-1/2">
                  <AddressIcon className="self-start w-6 h-auto pt-1" />
                  <CardDataSet label={t('common.address')} variant="compact">
                    <div className="font-bold text-[15px] leading-5">
                      {(projectData?.street || projectData?.number) && (
                        <>
                          {`${projectData?.street ?? '-'}${projectData?.number ? ' ' : ''}${projectData?.number}`}
                          <br />
                        </>
                      )}
                      {(projectData?.postalCode || projectData?.city) && (
                        <>
                          {`${projectData?.postalCode ?? '-'}${projectData?.city ? ' ' : ''}${projectData?.city}`}
                          <br />
                        </>
                      )}
                    </div>
                  </CardDataSet>
                </div>
              )}
              <CardDataSet
                label={t('dashboard.widget.projectInfo.type')}
                title={projectData?.measure ? getMeasureLabel(projectData.measure) : '-'}
                className="w-1/2"
                variant="compact"
              />
            </div>
          )}
          <div className="flex gap-10 justify-between w-full mt-2">
            {loadedCompanyPerson ? (
              <CardDataSet
                label={t('dashboard.widget.projectInfo.projectManager')}
                className={cn('flex-none', { 'pl-6 ml-3': largeVariant && hasAddress })}
              >
                <div className="flex gap-3.5 mt-0.5 items-center">
                  <UserAvatar
                    userName={loadedCompanyPerson.fullname ?? ''}
                    userId={loadedCompanyPerson.id ?? ''}
                    lastUpdate=""
                    hasAvatar={false}
                    classNames="w-10 h-10"
                  />
                  <CardDataSet
                    title={loadedCompanyPerson.fullname}
                    text={loadedCompanyPerson.function ?? '-'}
                    variant="compact"
                  />
                </div>
              </CardDataSet>
            ) : (
              <CardDataSet label={t('dashboard.widget.projectInfo.projectManager')} title="-" variant="compact" />
            )}
            {!largeVariant && (
              <CardDataSet
                title={projectData?.measure ? getMeasureLabel(projectData.measure) : '-'}
                variant="compact"
                label={t('dashboard.widget.projectInfo.type')}
                className="flex-none w-12"
              />
            )}
            <CardDataSet label={t('dashboard.widget.projectInfo.by')} className="flex-none">
              {tenant?.hasAvatar ? (
                <div className="h-10 max-w-[82px] content-end">
                  <LazyLoadImage
                    src={avatarPath}
                    alt={tenant?.name}
                    aria-hidden={true}
                    className="w-auto h-full object-contain"
                  />
                </div>
              ) : (
                <CardDataSet title={tenant?.name ?? '-'} variant="compact" />
              )}
            </CardDataSet>
          </div>
        </div>
      </WidgetContainerContent>
    </WidgetContainer>
  );
};
