import classNames from 'classnames';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { settings, useUi } from '@client/shared/store';
import { ProtectedNavLinkWithRef, RouteNavType, ROUTES_CONFIG } from '@client/shared/permissions';
import { AddButton, Button, LoadingIndicator, Modal, useComponentDimensions } from '@client/shared/toolkit';
import { Menu, MenuButton, MenuItem, MenuItems, Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { usePopper } from 'react-popper';
import { ReportingContext } from './ReportingContextProvider';
import { ReportAddModal } from './ReportAddModal';
import { useLoadedProjectId } from '@client/project/store';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ReportReadModel,
  useApiGetReportDashboardsSummaryQuery,
  useApiGetSystemReportsQuery,
  useApiGetTenantReportsQuery,
} from '@client/shared/api';
import {
  ExportButtons,
  LazyReportExportButtons,
} from '@client/project/shared';
import { ReportingTab } from './ReportingTab';

interface ReportingTabsProps {
  className?: string;
}

export const ReportingTabs = ({ className }: ReportingTabsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ui = useUi();
  const user = ui.appUser;
  const tenant = user.tenant;

  const location = useLocation();
  const {
    onDownload,
    calculationModelId,
    datasourceParameters,
    exportFilterConfig,
    exportVariables,
    exportCustomComponents,
    customReport,
    pdfExportSize,
  } = useContext(ReportingContext);

  const [createdDashboardId, setCreatedDashboardId] = useState<string | null>(null);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const { data: tenantReportsData, isFetching: isFetchingTenantReports } = useApiGetTenantReportsQuery({
    type: 'Generic',
  });
  const { data: systemReportsData, isFetching: isFetchingSystemReports } = useApiGetSystemReportsQuery({
    type: 'Generic',
  });

  const projectId = useLoadedProjectId();

  const { data: reportDashboardsData, isFetching: isFetchingReportDashboards } = useApiGetReportDashboardsSummaryQuery(
    {
      projectId: projectId || '',
    },
    { skip: projectId == null },
  );

  // Those reports are hardcoded for now. Goal is that those reports can be created and edited in a template of a project.
  /* const jjlReportDashboards: { id: JLL_CUSTOM_REPORT_DASHBOARD_NAME; name: string }[] = useMemo(() => {
    if (user.permissions?.licenses.find((license) => license.feature === 'JLL_REPORTING')) {
      return [
        {
          id: JLL_CUSTOM_REPORT_DASHBOARD_NAME.RisksMitigation,
          name: JLL_CUSTOM_REPORT_DASHBOARDS_CONFIG[JLL_CUSTOM_REPORT_DASHBOARD_NAME.RisksMitigation].name,
        },
        {
          id: JLL_CUSTOM_REPORT_DASHBOARD_NAME.ProgressQuality,
          name: JLL_CUSTOM_REPORT_DASHBOARDS_CONFIG[JLL_CUSTOM_REPORT_DASHBOARD_NAME.ProgressQuality].name,
        },
        {
          id: JLL_CUSTOM_REPORT_DASHBOARD_NAME.CostAndContract,
          name: JLL_CUSTOM_REPORT_DASHBOARDS_CONFIG[JLL_CUSTOM_REPORT_DASHBOARD_NAME.CostAndContract].name,
        },
        {
          id: JLL_CUSTOM_REPORT_DASHBOARD_NAME.WeeklyReport,
          name: JLL_CUSTOM_REPORT_DASHBOARDS_CONFIG[JLL_CUSTOM_REPORT_DASHBOARD_NAME.WeeklyReport].name,
        },
      ];
    }
    return [];
  }, [user.permissions?.licenses]); */

  const downloadExcelRoutes = useMemo(() => {
    return [
      ROUTES_CONFIG.REPORTING_COMPARISON.path.replace(':id', projectId ?? ''),
      ROUTES_CONFIG.REPORTING_BUDGET.path.replace(':id', projectId ?? ''),
      ROUTES_CONFIG.REPORTING_FORECAST.path.replace(':id', projectId ?? ''),
      ROUTES_CONFIG.REPORTING_CASHFLOW.path.replace(':id', projectId ?? ''),
    ];
  }, [projectId]);
  const downloadPdfRoutes = useMemo(() => {
    return [
      ...downloadExcelRoutes,
      ROUTES_CONFIG.REPORTING_BASE.path.replace(':id', projectId ?? ''),
      ROUTES_CONFIG.REPORTING_CASHFLOW.path.replace(':id', projectId ?? ''),
      ROUTES_CONFIG.REPORTING_COST_BREAKDOWN_REPORT.path.replace(':id', projectId ?? ''),
      ROUTES_CONFIG.REPORTING_BMW_COST_WATERFALL.path.replace(':id', projectId ?? ''),
      ROUTES_CONFIG.REPORTING_BMW_SAZ.path.replace(':id', projectId ?? ''),
      ROUTES_CONFIG.REPORTING_CUSTOM_REPORT.path
        .replace(':id', projectId ?? '')
        .replace(':reportId', customReport?.id ?? ''),
    ];
  }, [downloadExcelRoutes, projectId, customReport]);

  const exportRoutes = useMemo(() => {
    const routes = [
      ROUTES_CONFIG.REPORTING_DETAILS.path.replace(':id', projectId ?? ''),
      ROUTES_CONFIG.REPORTING_VARIATION.path.replace(':id', projectId ?? ''),
    ];
    return routes;
  }, [projectId]);

  const showExcelDownload = useMemo(() => {
    return downloadExcelRoutes.includes(location.pathname);
  }, [downloadExcelRoutes, location.pathname]);

  const showPdfDownload = useMemo(() => {
    return downloadPdfRoutes.includes(location.pathname);
  }, [downloadPdfRoutes, location.pathname]);

  const showExportButtons = useMemo(() => {
    return exportRoutes.includes(location.pathname);
  }, [exportRoutes, location.pathname]);

  const exportReportId = useMemo(() => {
    if (location.pathname === ROUTES_CONFIG.REPORTING_DETAILS.path.replace(':id', projectId ?? '')) {
      return 'bb82f4f0-d23d-464e-885e-5cfe1956176b';
    } else if (location.pathname === ROUTES_CONFIG.REPORTING_VARIATION.path.replace(':id', projectId ?? '')) {
      return 'c214c761-44f0-40d7-9b52-c8c3c8ceb93f';
    }
    return null;
  }, [location.pathname, projectId]);

  const exportReportButtons: ('png' | 'pdf' | 'xlxs')[] = useMemo(() => {
    if (
      location.pathname === ROUTES_CONFIG.REPORTING_DETAILS.path.replace(':id', projectId ?? '') ||
      location.pathname === ROUTES_CONFIG.REPORTING_VARIATION.path.replace(':id', projectId ?? '')
    ) {
      return ['png', 'pdf', 'xlxs'];
    }
    return [];
  }, [location.pathname, projectId]);

  const reportingTabs: RouteNavType[] = useMemo(() => {
    const tabs: RouteNavType[] = [
      {
        routeConfig: ROUTES_CONFIG.REPORTING_BASE,
        label: t('reporting.reportBaseTitle'),
      },
      {
        routeConfig: ROUTES_CONFIG.REPORTING_CASHFLOW,
        label: t('reporting.reportCashflow.title'),
      },
      {
        routeConfig: ROUTES_CONFIG.REPORTING_COMPARISON,
        label: t('reporting.reportComparison.title'),
      },
      {
        routeConfig: ROUTES_CONFIG.REPORTING_BUDGET,
        label: t('reporting.reportBudget.title'),
      },
      {
        routeConfig: ROUTES_CONFIG.REPORTING_DETAILS,
        label: t('reporting.reportDetails.title'),
      },
      {
        routeConfig: ROUTES_CONFIG.REPORTING_VARIATION,
        label: t('reporting.reportVariation.title'),
      },
      {
        routeConfig: ROUTES_CONFIG.REPORTING_FORECAST,
        label: t('reporting.reportForecast.title'),
      },
      {
        routeConfig: ROUTES_CONFIG.REPORTING_RISK,
        label: t('reporting.reportRisk.title'),
      },
    ];

    tabs.push({
      routeConfig: ROUTES_CONFIG.REPORTING_COST_BREAKDOWN_REPORT,
      label: t('reporting.reportCostBreakdown.title'),
    });

    return tabs;
  }, [t]);

  const tenantReports: RouteNavType[] = useMemo(() => {
    const tabs: RouteNavType[] = [];

    if (user.permissions?.licenses.find((license) => license.feature === 'BMW_REPORTING')) {
      tabs.push(
        {
          routeConfig: ROUTES_CONFIG.REPORTING_BMW_COST_WATERFALL,
          label: 'Cost Waterfall',
        },
        {
          routeConfig: ROUTES_CONFIG.REPORTING_BMW_SAZ,
          label: 'SAZ',
        },
      );
    }

    if (
      settings.mainDomain === 'probis.test' ||
      settings.mainDomain === 'probis.dev' ||
      (settings.mainDomain === 'probis.qa' && user.tenant?.tenantId === '311f17d5-bf27-4850-9b94-9bbca03b0207')
    ) {
      tabs.push({
        routeConfig: ROUTES_CONFIG.REPORTING_CARBON_EMISSION_CHART,
        label: 'LocalLaw97',
      });
    }

    return tabs;
  }, [user.permissions?.licenses, user.tenant?.tenantId]);

  const genericReports: ReportReadModel[] = useMemo(() => {
    let loadedReports: ReportReadModel[] = [];
    if (systemReportsData) {
      loadedReports = systemReportsData;
    }
    if (tenantReportsData) {
      loadedReports = [...loadedReports, ...tenantReportsData];
    }
    return loadedReports;
  }, [tenantReportsData, systemReportsData]);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const dimensionsWrapper = useComponentDimensions(wrapperRef);
  const downloadButtonsRef = useRef<HTMLDivElement>(null);

  const [isLoading, setIsLoading] = useState(false);

  const [targetElement, setTargetElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: 'bottom',
  });

  // TODO move that into own component!
  const [tenantReportMenuTargetElement, setTenantReportMenuTargetElement] = useState<HTMLElement | null>(null);
  const [tenantReportMenuPopperElement, setTenantReportMenuPopperElement] = useState<HTMLElement | null>(null);
  const [customReportMenuTargetElement, setCustomReportMenuTargetElement] = useState<HTMLElement | null>(null);
  const [customReportMenuPopperElement, setCustomReportMenuPopperElement] = useState<HTMLElement | null>(null);
  const { styles: tenantReportMenuStyles, attributes: tenantReportMenuAttributes } = usePopper(
    tenantReportMenuTargetElement,
    tenantReportMenuPopperElement,
    {
      placement: 'bottom-start',
      modifiers: [
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['bottom-end', 'top-start', 'top-end'],
            rootBoundary: 'viewport',
          },
        },
        { name: 'offset', options: { offset: [0, 8] } },
      ],
    },
  );
  const { styles: customReportMenuStyles, attributes: customReportMenuAttributes } = usePopper(
    customReportMenuTargetElement,
    customReportMenuPopperElement,
    {
      placement: 'bottom-start',
      modifiers: [
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['bottom-end', 'top-start', 'top-end'],
            rootBoundary: 'viewport',
          },
        },
        { name: 'offset', options: { offset: [0, 8] } },
      ],
    },
  );

  const maxTabItems = useMemo(() => {
    if (dimensionsWrapper?.width && wrapperRef.current && window.innerWidth >= 1280) {
      const maxWidth =
        dimensionsWrapper.width -
        16 - // spacing
        40 - // add custom report button
        (downloadButtonsRef.current?.clientWidth ?? 0) -
        (tenantReportMenuTargetElement?.clientWidth ?? 0) -
        (customReportMenuTargetElement?.clientWidth ?? 0);
      let tabsWidth = 0;
      let max = 0;
      const renderedTabs = wrapperRef.current.querySelectorAll('.reporting-tab');
      renderedTabs.forEach((tab) => {
        if (tabsWidth + tab.clientWidth + 8 <= maxWidth) {
          tabsWidth = tabsWidth + tab.clientWidth + 8;
          max++;
        }
      });
      return max;
    }
    return reportingTabs.length;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dimensionsWrapper.width,
    reportingTabs.length,
    wrapperRef,
    downloadButtonsRef.current?.clientWidth,
    tenantReportMenuTargetElement,
    customReportMenuTargetElement,
  ]);

  const visibleTabItems = useMemo(() => {
    const items: RouteNavType[] = [...reportingTabs];
    return items.slice(0, maxTabItems);
  }, [reportingTabs, maxTabItems]);

  const hiddenTabItems = useMemo(() => {
    const items: RouteNavType[] = [...reportingTabs];
    return items.slice(maxTabItems);
  }, [reportingTabs, maxTabItems]);

  const reportSettings: { filename: string; size?: number | null } = useMemo(() => {
    if (projectId && ROUTES_CONFIG.REPORTING_COMPARISON.path.replace(':id', projectId) === location.pathname) {
      return {
        filename: 'comparison-report',
      };
    }
    if (projectId && ROUTES_CONFIG.REPORTING_BUDGET.path.replace(':id', projectId) === location.pathname) {
      return {
        filename: 'budget-report',
      };
    }
    if (projectId && ROUTES_CONFIG.REPORTING_FORECAST.path.replace(':id', projectId) === location.pathname) {
      return {
        filename: 'forecast-report',
      };
    }
    if (projectId && ROUTES_CONFIG.REPORTING_BASE.path.replace(':id', projectId) === location.pathname) {
      return {
        filename: 'base-report',
        size: 297, // A4
      };
    }
    if (projectId && ROUTES_CONFIG.REPORTING_CASHFLOW.path.replace(':id', projectId) === location.pathname) {
      const size =
        pdfExportSize?.reportRoute === ROUTES_CONFIG.REPORTING_CASHFLOW.name ? pdfExportSize.size : undefined;

      return {
        filename: 'cashflow-report',
        size: size ? Math.ceil(size) : 297,
      };
    }
    if (projectId && ROUTES_CONFIG.REPORTING_DETAILS.path.replace(':id', projectId) === location.pathname) {
      return {
        filename: 'details-report',
      };
    }
    if (projectId && ROUTES_CONFIG.REPORTING_VARIATION.path.replace(':id', projectId) === location.pathname) {
      return {
        filename: 'variation-report',
      };
    }
    if (projectId && ROUTES_CONFIG.REPORTING_BMW_COST_WATERFALL.path.replace(':id', projectId) === location.pathname) {
      return {
        filename: 'cost-waterfall-report',
      };
    }
    if (
      projectId &&
      ROUTES_CONFIG.REPORTING_COST_BREAKDOWN_REPORT.path.replace(':id', projectId) === location.pathname
    ) {
      return {
        filename: 'cost-breakdown-report',
      };
    }
    if (
      projectId &&
      customReport &&
      ROUTES_CONFIG.REPORTING_CUSTOM_REPORT.path.replace(':id', projectId).replace(':reportId', customReport.id)
    ) {
      return {
        filename: customReport?.name ?? 'custom-report',
      };
    }
    return {
      filename: 'report',
    };
  }, [projectId, location.pathname, customReport, pdfExportSize]);

  /* const pdfExportWidth = useMemo(() => {
    if (
      projectId &&
      (ROUTES_CONFIG.REPORTING_COST_BREAKDOWN_REPORT.path.replace(':id', projectId) === location.pathname ||
        ROUTES_CONFIG.REPORTING_VARIATION.path.replace(':id', projectId) === location.pathname)
    ) {
      return 2480;
    }

    return 1440;
  }, [projectId, location.pathname]); */

  // after creating a new report and cache invalidation with successfully retrieving all custom reports again
  // navigate to the newly created widget report
  useEffect(() => {
    if (projectId && createdDashboardId && !isFetchingReportDashboards) {
      navigate(
        ROUTES_CONFIG.REPORTING_CUSTOM_REPORT.path.replace(':id', projectId).replace(':reportId', createdDashboardId),
      );
      setCreatedDashboardId(null);
    }
  }, [isFetchingReportDashboards, createdDashboardId, projectId, navigate]);

  return (
    <>
      <div
        className={classNames(
          'w-full max-w-full h-full gap-2 flex flex-nowrap items-center justify-end pdf-export-hidden',
          className,
        )}
        ref={wrapperRef}
      >
        {isLoading && <LoadingIndicator mode="overlay-window" text={t('reporting.exportingLoadingIndicator')} />}
        {isFetchingReportDashboards && <LoadingIndicator mode="overlay-window" />}
        <div
          className={classNames('flex-shrink flex gap-2 flex-wrap xl:flex-nowrap items-center', {
            'border-r pr-4': showExcelDownload || showPdfDownload || showExportButtons,
          })}
        >
          {reportingTabs.length > 0 && (
            <ul className="flex flex-wrap xl:flex-nowrap justify-center xl:justify-end gap-2 w-full">
              {visibleTabItems.map((item, i) => (
                <li key={`reporting-tab-${i}`} className="reporting-tab">
                  <ReportingTab routeConfig={item.routeConfig} projectId={projectId}>
                    {item.label}
                  </ReportingTab>
                </li>
              ))}
              {tenantReports.length > 0 && (
                <li>
                  <Menu as="div" ref={setTenantReportMenuTargetElement} className={className}>
                    {({ open, close }) => (
                      <>
                        <MenuButton className="cursor-pointer" as="div" onClick={undefined}>
                          <Button
                            variant="custom"
                            className="flex-grow-0 flex flex-row items-center justify-center w-auto rounded-full px-6 h-8 text-[15px] text-slate-500 font-bold hover:bg-slate-500 hover:text-white cursor-pointer transition-colors whitespace-nowrap"
                          >
                            <span className="font-bold">
                              {tenant?.name
                                ? t('reporting.tenantReports', { tenant: tenant?.name })
                                : t('reporting.customReports')}
                            </span>
                          </Button>
                        </MenuButton>
                        {open && (
                          <MenuItems
                            portal
                            as="div"
                            ref={setTenantReportMenuPopperElement}
                            className="w-48 font-light bg-white divide-y divide-gray-100 rounded-md shadow-lg overflow-hidden z-30 max-h-screen overflow-y-auto"
                            style={{ ...tenantReportMenuStyles.popper }}
                            {...tenantReportMenuAttributes.popper}
                          >
                            {tenantReports.map((item, index) => (
                              <MenuItem
                                key={`tenant-report-${item.label}-${index}`}
                                as={ProtectedNavLinkWithRef}
                                path={item.routeConfig.name}
                                routeConfig={item.routeConfig}
                                className={classNames(
                                  'flex px-4 py-1 hover:bg-blue-200 text-[15px] text-slate-500 font-bold w-full h-full',
                                  {
                                    'border-t border-gray-200': index >= 1,
                                  },
                                )}
                                projectId={projectId}
                                onClick={close}
                              >
                                {item.label}
                              </MenuItem>
                            ))}
                            {isFetchingTenantReports || isFetchingSystemReports ? (
                              <LoadingIndicator mode="inline" />
                            ) : (
                              <>
                                {genericReports.length > 0 &&
                                  genericReports.map((report, index) => (
                                    <MenuItem
                                      key={`tenant-specific-report-${index}`}
                                      as={ProtectedNavLinkWithRef}
                                      path={ROUTES_CONFIG.REPORTING_REPORT_VIEW.path
                                        .replace(':id', projectId ?? '')
                                        .replace(':reportId', report.metaData.reportId ?? '')}
                                      routeConfig={ROUTES_CONFIG.REPORTING_REPORT_VIEW}
                                      className={classNames(
                                        'flex px-4 py-1 hover:bg-blue-200 text-[15px] text-slate-500 font-bold w-full h-full',
                                        {
                                          'border-t border-gray-200': index >= 1,
                                        },
                                      )}
                                      onClick={close}
                                      projectId={projectId}
                                    >
                                      {report.metaData.name}
                                    </MenuItem>
                                  ))}
                              </>
                            )}
                          </MenuItems>
                        )}
                      </>
                    )}
                  </Menu>
                </li>
              )}
              {(reportDashboardsData && reportDashboardsData.length > 0) &&
                !isFetchingReportDashboards && (
                  <li>
                    <Menu as="div" ref={setCustomReportMenuTargetElement} className={className}>
                      {({ open, close }) => (
                        <>
                          <MenuButton className="cursor-pointer" as="div" onClick={undefined}>
                            <Button
                              variant="custom"
                              className="flex-grow-0 flex flex-row items-center justify-center w-auto rounded-full px-6 h-8 text-[15px] text-slate-500 font-bold hover:bg-slate-500 hover:text-white cursor-pointer transition-colors whitespace-nowrap"
                            >
                              <span className="font-bold">{t('reporting.customReports')}</span>
                            </Button>
                          </MenuButton>
                          {open && (
                            <MenuItems
                              portal
                              as="div"
                              ref={setCustomReportMenuPopperElement}
                              className="w-48 font-light bg-white divide-y divide-gray-100 rounded-md shadow-lg overflow-hidden z-30 max-h-[80vh] lg:max-h-[350px] overflow-y-auto"
                              style={{ ...customReportMenuStyles.popper }}
                              {...customReportMenuAttributes.popper}
                            >
                              {projectId &&
                                reportDashboardsData &&
                                reportDashboardsData.map((report, index) => (
                                  <MenuItem
                                    key={`custom-report-${index}`}
                                    as={ProtectedNavLinkWithRef}
                                    path={ROUTES_CONFIG.REPORTING_CUSTOM_REPORT.path
                                      .replace(':id', projectId)
                                      .replace(':reportId', report.id)}
                                    routeConfig={ROUTES_CONFIG.REPORTING_CUSTOM_REPORT}
                                    className={classNames(
                                      'flex px-4 py-1 hover:bg-blue-200 text-[15px] text-slate-500 font-bold w-full h-full',
                                      {
                                        'border-t border-gray-200': index >= 1,
                                      },
                                    )}
                                    onClick={close}
                                    projectId={projectId}
                                  >
                                    {report.name}
                                  </MenuItem>
                                ))}
                            </MenuItems>
                          )}
                        </>
                      )}
                    </Menu>
                  </li>
                )}
            </ul>
          )}

          {hiddenTabItems.length > 0 && (
            <Popover>
              <div ref={setTargetElement}>
                <PopoverButton className="h-full flex items-center">
                  <div className="px-2 flex gap-2 items-center w-full font-medium cursor-pointer">
                    <EllipsisVerticalIcon className="w-6" />
                  </div>
                </PopoverButton>
              </div>
              <PopoverPanel
                portal
                ref={setPopperElement}
                style={{ ...styles.popper }}
                {...attributes.popper}
                className="w-full mt-3 px-4 z-20 flex items-center outline-none min-h-16 bg-white shadow-lg border-t-2 py-3"
              >
                <div className={classNames('flex flex-wrap items-center w-full justify-center gap-2')}>
                  {hiddenTabItems.map((item, j) => (
                    <ReportingTab
                      key={`reporting-tab-${item.label}-${j}`}
                      routeConfig={item.routeConfig}
                      projectId={projectId}
                    >
                      {item.label}
                    </ReportingTab>
                  ))}
                </div>
              </PopoverPanel>
            </Popover>
          )}
          <AddButton variant="custom" onClick={() => setIsAddModalOpen(true)} className="shadow-md ml-1" />
        </div>
        {(showExcelDownload || showPdfDownload || showExportButtons) && (
          <div className="flex-none flex mx-4" ref={downloadButtonsRef}>
            <ExportButtons
              setIsLoading={setIsLoading}
              showPdfDownload={showPdfDownload}
              showExcelDownload={showExcelDownload}
              onExcelDownload={onDownload}
              reportName={reportSettings.filename}
              reportSize={reportSettings.size}
            />

            {showExportButtons && exportReportId && (
              <div className="flex gap-2.5 xl:gap-5 items-center h-10 justify-end">
                <LazyReportExportButtons
                  reportId={exportReportId}
                  type="export"
                  buttons={exportReportButtons}
                  calculationModelId={calculationModelId}
                  datasourceParameters={datasourceParameters}
                  filterConfig={exportFilterConfig}
                  variables={exportVariables}
                  customComponents={exportCustomComponents}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <Modal isOpen={isAddModalOpen} onClose={() => setIsAddModalOpen(false)}>
        <ReportAddModal onClose={() => setIsAddModalOpen(false)} setCreatedDashboardId={setCreatedDashboardId} />
      </Modal>
    </>
  );
};
