import {
  Button,
  CityBuildingsDottedIcon,
  CoinInHandIcon,
  ConstructionDottedIcon,
  ContractIcon,
  LoadingIndicator,
  MediumRiskIcon,
  Modal,
  ModalOnCloseProps,
  PriceTagEuroDottedIcon,
  RentDottedIcon,
  StatesOutlinedIcon,
  Wizard,
  WizardSelectList,
  WizardSlideContent,
  WizardSlideHeader,
} from '@client/shared/toolkit';
import classNames from 'classnames';
import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BenchmarkAddParameterForm } from './BenchmarkAddParameterForm';
import { BenchmarkReportConfigurationReadModel } from '@client/shared/api';

const ICON_SIZE = 'w-auto h-[42px]';

export interface BenchmarkAddParameterWizardProps extends ModalOnCloseProps {
  config?: BenchmarkReportConfigurationReadModel;
}

export type BenchmarkingColumnType =
  | 'costs'
  | 'rent' // earnings rental
  | 'sales' // earnings purchase
  | 'additions' // earnings service + operating
  | 'risks'
  | 'finance'
  | 'state'
  | 'project';

export const BenchmarkAddParameterWizard = (props: BenchmarkAddParameterWizardProps) => {
  const { onClose, config } = props;
  const { t } = useTranslation();

  const formRef = useRef<HTMLFormElement>(null);

  const isLoading = false;

  const [isCreateDisabled, setIsCreateDisabled] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedParameter, setSelectedParameter] = useState<BenchmarkingColumnType | null>(null);

  const handleOnSlideChange = async (index: number) => {
    setCurrentStep(index);
  };

  const handleCloseModal = () => {
    onClose(false);
  };

  const selectListItems = useMemo(() => {
    return [
      {
        title: t('reporting.benchmarking.parameter.constructionCosts.title'),
        subtitle: t('reporting.benchmarking.parameter.constructionCosts.subtitle'),
        icon: <ConstructionDottedIcon className="w-full h-full" />,
        disabled: false,
        handleClick: () => {
          setSelectedParameter('costs');
        },
        active: selectedParameter === 'costs',
        inBox: true,
        iconSize: ICON_SIZE,
      },
      {
        title: t('reporting.benchmarking.parameter.rent.title'),
        subtitle: t('reporting.benchmarking.parameter.rent.subtitle'),
        icon: <RentDottedIcon className="w-full h-full" />,
        disabled: false,
        handleClick: () => {
          setSelectedParameter('rent');
        },
        active: selectedParameter === 'rent',
        inBox: true,
        iconSize: ICON_SIZE,
      },
      {
        title: t('reporting.benchmarking.parameter.sales.title'),
        subtitle: t('reporting.benchmarking.parameter.sales.subtitle'),
        icon: <PriceTagEuroDottedIcon className="w-full h-full" />,
        disabled: false,
        handleClick: () => {
          setSelectedParameter('sales');
        },
        active: selectedParameter === 'sales',
        inBox: true,
        iconSize: ICON_SIZE,
      },
      {
        title: t('reporting.benchmarking.parameter.additions.title'),
        subtitle: t('reporting.benchmarking.parameter.additions.subtitle'),
        icon: <CoinInHandIcon className="w-full h-full" />,
        disabled: false,
        handleClick: () => {
          setSelectedParameter('additions');
        },
        active: selectedParameter === 'additions',
        inBox: true,
        iconSize: ICON_SIZE,
      },
      {
        title: t('reporting.benchmarking.parameter.risks.title'),
        subtitle: t('reporting.benchmarking.parameter.risks.subtitle'),
        icon: <MediumRiskIcon className="w-full h-full" />,
        disabled: false,
        handleClick: () => {
          setSelectedParameter('risks');
        },
        active: selectedParameter === 'risks',
        inBox: true,
        iconSize: ICON_SIZE,
      },
      {
        title: t('reporting.benchmarking.parameter.financing.title'),
        subtitle: t('reporting.benchmarking.parameter.financing.subtitle'),
        icon: <ContractIcon className="w-full h-full" />,
        disabled: true,
        active: selectedParameter === 'finance',
        inBox: true,
        iconSize: ICON_SIZE,
      },
      {
        title: t('reporting.benchmarking.parameter.state.title'),
        subtitle: t('reporting.benchmarking.parameter.state.subtitle'),
        icon: <StatesOutlinedIcon className="w-full h-full" />,
        disabled: true,
        active: selectedParameter === 'state',
        inBox: true,
        iconSize: ICON_SIZE,
      },
      {
        title: t('reporting.benchmarking.parameter.project.title'),
        subtitle: t('reporting.benchmarking.parameter.project.subtitle'),
        icon: <CityBuildingsDottedIcon className="w-full h-full" />,
        disabled: true,
        active: selectedParameter === 'project',
        inBox: true,
        iconSize: ICON_SIZE,
      },
    ];
  }, [t, selectedParameter]);

  const handleCreate = () => {
    formRef?.current?.submitForm();
  };

  const orderNumber = useMemo(() => {
    return config?.columns.length ?? 0;
  }, [config?.columns.length]);

  return (
    <>
      {isLoading && <LoadingIndicator text={t('common.loading')} mode="overlay-window" />}
      <Wizard
        currentStep={currentStep}
        onSlideChange={(step) => handleOnSlideChange(step)}
        className="w-[740px] h-[560px]"
      >
        <Wizard.Slides>
          <Wizard.Slide hasPadding={false}>
            <WizardSlideHeader
              title={t('reporting.benchmarking.addColumnWizard.title')}
              subTitle={t('reporting.benchmarking.addColumnWizard.description')}
            />
            <WizardSlideContent>
              <WizardSelectList items={selectListItems} grid gridCols="grid-cols-4" />
            </WizardSlideContent>
          </Wizard.Slide>
          <Wizard.Slide>
            {config?.id && selectedParameter && (
              <BenchmarkAddParameterForm
                formRef={formRef}
                configId={config?.id}
                setIsCreateDisabled={setIsCreateDisabled}
                onClose={() => onClose(false)}
                type={selectedParameter}
                orderNumber={orderNumber}
              />
            )}
          </Wizard.Slide>
        </Wizard.Slides>
        <Wizard.Navigation>
          {({ canGoPrevious, previous, isLast, canGoNext, next }) => {
            return (
              <Modal.Controls
                className={classNames({
                  'bg-slate-100': currentStep !== 1,
                  'bg-white': currentStep === 1,
                })}
              >
                {(() => {
                  if (isLast) {
                    return (
                      <>
                        <Button variant="text" disabled={!canGoPrevious} onClick={previous}>
                          {t('common.back')}
                        </Button>
                        <Button variant="primary" onClick={handleCreate} disabled={isCreateDisabled}>
                          {t('common.create')}
                        </Button>
                      </>
                    );
                  } else {
                    return (
                      <>
                        <Button variant="text" onClick={handleCloseModal}>
                          {t('common.cancel')}
                        </Button>
                        <Button variant="primary" disabled={!canGoNext} onClick={next}>
                          {t('common.next')}
                        </Button>
                      </>
                    );
                  }
                })()}
              </Modal.Controls>
            );
          }}
        </Wizard.Navigation>
      </Wizard>
    </>
  );
};
