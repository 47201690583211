import React, { useMemo, useRef, useState } from 'react';
import { FileInput, Form, FormField, FormWatch, Tabs, TextInput, Button } from '..';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { InferType } from 'yup';

export const CreateEmbeddedLinkFormValidationSchema = yup.object({
  fileName: yup.string().required('validation.required'),
  fileLink: yup.string().required('validation.required'),
});

export type CreateEmbeddedLinkFormValidationValues = InferType<typeof CreateEmbeddedLinkFormValidationSchema>;

interface UploadFileTabsProps {
  selectedIndex: number;
  setSelectedIndex: (index: number) => void;
  acceptedFileTypes: string[];
  onAddEmbedLinkDocumentClick: (data: CreateEmbeddedLinkFormValidationValues) => void; // Renamed prop
  onUploadDocumentClick?: (file: FileList | null) => void; // Prop to handle upload button click
  disabled?: boolean;
}

export const UploadFileTabs = (props: UploadFileTabsProps) => {
  const {
    selectedIndex,
    setSelectedIndex,
    acceptedFileTypes,
    onAddEmbedLinkDocumentClick,
    onUploadDocumentClick,
    disabled = false,
  } = props;
  const { t } = useTranslation();

  const [isFileSelected, setIsFileSelected] = useState(false); // State to track file selection
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);

  const tabs = useMemo(() => {
    const handleFileChange = (fileList: FileList | null) => {
      setIsFileSelected(fileList !== null && fileList.length > 0);
      setSelectedFiles(fileList);
    };

    return [
      {
        header: t('common.upload'),
        panel: (
          <div>
            <FileInput
              disabled = {disabled}
              className="bg-white rounded-sm"
              acceptedFileTypes={acceptedFileTypes}
              multiple={true}
              onChange={handleFileChange}
              selectedFiles={selectedFiles}
            />
            <div className="flex justify-end mt-4">
              <Button
                variant="primary"
                onClick={() => {
                  onUploadDocumentClick && onUploadDocumentClick(selectedFiles);
                  setSelectedFiles(null);
                  setIsFileSelected(false);
                }}
                disabled={!isFileSelected || disabled}
              >
                {t('common.upload')}
              </Button>
            </div>
          </div>
        ),
      },
      {
        header: t('common.embedLink'),
        panel: (
          <EmbedLinkForm
            onAddEmbedLinkDocumentClick={onAddEmbedLinkDocumentClick} // Updated prop name
            disabled={disabled}
          />
        ),
      },
    ];
  }, [
    t,
    acceptedFileTypes,
    selectedFiles,
    isFileSelected,
    disabled,
    onAddEmbedLinkDocumentClick,
    onUploadDocumentClick,
  ]);

  return <Tabs options={tabs} selectedIndex={selectedIndex} tabChange={setSelectedIndex} variant="upload" />;
};

interface EmbedLinkFormProps {
  onAddEmbedLinkDocumentClick: (data: CreateEmbeddedLinkFormValidationValues) => void; // Renamed prop
  disabled: boolean;
}

export const EmbedLinkForm = (props: EmbedLinkFormProps) => {
  const { onAddEmbedLinkDocumentClick, disabled } = props;

  const formRef = useRef<HTMLFormElement>(null);

  const defaultFormValues = {
    fileLink: '',
    fileName: '',
  };

  const { t } = useTranslation();

  // State to track field values
  const [formValues, setFormValues] = useState<CreateEmbeddedLinkFormValidationValues>(defaultFormValues);

  return (
    <Form<CreateEmbeddedLinkFormValidationValues>
      onSubmit={onAddEmbedLinkDocumentClick}
      validationSchema={CreateEmbeddedLinkFormValidationSchema}
      defaultValues={defaultFormValues}
      ref={formRef}
    >
      <FormWatch fieldNames={['fileLink', 'fileName']}>
        {({ fileLink, fileName }) => (
          <>
            <FormField name="fileLink">
              {(control) => (
                <TextInput
                  disabled={disabled}
                  label={t('common.fileURL')}
                  className="mb-1"
                  {...control}
                  onChange={(value) => {
                    const updatedValues = { fileLink: value, fileName };
                    setFormValues(updatedValues); // Update local state
                    control.onChange(value);
                  }}
                />
              )}
            </FormField>
            <FormField name="fileName">
              {(control) => (
                <TextInput
                  disabled={disabled}
                  label={t('common.fileName')}
                  {...control}
                  onChange={(value) => {
                    const updatedValues = { fileLink, fileName: value };
                    setFormValues(updatedValues); // Update local state
                    control.onChange(value);
                  }}
                />
              )}
            </FormField>
            <div className="flex justify-end mt-4">
              <Button
                variant="primary"
                onClick={() => {
                  onAddEmbedLinkDocumentClick(formValues);
                  formRef?.current?.setValue('fileLink', '');
                  formRef?.current?.setValue('fileName', '');
                }}
                disabled={!(formValues.fileLink.trim() !== '' && formValues.fileName.trim() !== '') || disabled}
              >
                {t('common.add')}
              </Button>
            </div>
          </>
        )}
      </FormWatch>
    </Form>
  );
};
