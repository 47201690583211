import { CurrencySpecificConversionValuesReadModel } from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import {
  AddButton,
  AddIcon,
  ContextMenu, getCurrencyLabelForCurrency,
  ProjectSettingsEditSection,
  SettingsInlineEditField,
  TrashIcon
} from '@client/shared/toolkit';
import { BenchmarkingEditValues } from '.';

export interface DefaultValuesBenchmarkingCurrencyConversionValuesEditProps {
  title: string;
  data: CurrencySpecificConversionValuesReadModel[];
  setAddMode: (currency?: string) => void;
  onChange: (data: BenchmarkingEditValues) => void;
  onDelete: (currency: string) => void;
  defaultCurrency: string;
  description?: string;
}

export const DefaultValuesBenchmarkingCurrencyConversionValuesEdit = (
  props: DefaultValuesBenchmarkingCurrencyConversionValuesEditProps,
) => {
  const { title, data, setAddMode, onChange, onDelete, defaultCurrency, description } = props;
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState<number | null>(null);
  const [currentValue, setCurrentValue] = useState<number | null>(null);
  return (
    <ProjectSettingsEditSection title={title} description={description} className="pt-4 relative">
      <div className="mb-4 flex flex-col gap-2">
        {data.length > 0 &&
          data.map((increaseValueData) => (
            <div
              className="mt-2 flex flex-col gap-2 bg-white px-4 pt-3 pb-2"
              key={`benchmarking-currency-${increaseValueData.currency}`}
            >
              <span className="font-medium text-sm">{getCurrencyLabelForCurrency(increaseValueData.currency)}</span>
              <div className="flex gap-2 flex-none w-full">
                <div className="flex gap-1 flex-1 w-auto overflow-x-auto bg-white pb-2">
                  {increaseValueData.yearlyValues.length > 0 &&
                    increaseValueData.yearlyValues.map((yearlyValue, index) => (
                      <SettingsInlineEditField
                        key={`benchmarking-currency-year-${
                          increaseValueData.currency
                        }-${yearlyValue.year.toString()}`}
                        value={`${yearlyValue.factor} ${increaseValueData.currency.toUpperCase()}/${defaultCurrency.toUpperCase()}`}
                        valueInput={yearlyValue.factor}
                        label={yearlyValue.year.toString()}
                        onChange={(val) => {
                          if (val && val !== yearlyValue.factor && isEditing === index) {
                            setCurrentValue(val);
                          }
                        }}
                        updateEditMode={(isEditMode) => {
                          if (isEditMode) {
                            setIsEditing(index);
                          } else if (isEditing === index) {
                            setIsEditing(null);
                            onChange({
                              code: increaseValueData.currency,
                              year: yearlyValue.year,
                              factor: currentValue ?? yearlyValue.factor,
                            });
                            setCurrentValue(null);
                          }
                        }}
                        onDelete={() => {
                          onChange({
                            code: increaseValueData.currency,
                            year: yearlyValue.year,
                            // setting factor to undefined will delete it
                          });
                        }}
                        min={0}
                        max={100}
                      />
                    ))}
                </div>
                <ContextMenu
                  items={[
                    {
                      label: t('app.masterDataDefaultValues.benchmarking.addNewYear'),
                      subtitle: t('app.masterDataDefaultValues.benchmarking.addNewYearDescription'),
                      icon: <AddIcon />,
                      onClick: () => {
                        setAddMode(increaseValueData.currency);
                      },
                    },
                    {
                      label: t('common.delete'),
                      subtitle: t('app.masterDataDefaultValues.benchmarking.deleteCountryCodeIncreaseValues'),
                      icon: <TrashIcon />,
                      onClick: () => onDelete(increaseValueData.currency),
                    },
                  ]}
                />
              </div>
            </div>
          ))}
      </div>
      <AddButton onClick={() => setAddMode()} className="absolute right-0 -bottom-2 z-10" />
    </ProjectSettingsEditSection>
  );
};
