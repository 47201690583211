import { Arrow, RenderChildren, RenderNode, Tree, TreeNodeProps } from './Tree';
import { CostCatalogElementDto } from '@client/shared/api';
import { useMemo } from 'react';
import classNames from 'classnames';

type RenderCostElementProps = TreeNodeProps & {
  costElement: CostCatalogElementDto;
  onSelectCostElement: (token: string) => void;
  disabled?: boolean;
};

const RenderCostElementName = (props: {
  element: CostCatalogElementDto;
  onSelectCostElement: (arg: string) => void;
  disabled?: boolean;
}) => {
  const costGroupCode = props.element.code;
  const costGroupName = useMemo(() => {
      return props.element.description ?? '';
  }, [props]);

  const elementName = `'${costGroupCode} ${costGroupName}'`;

  return (
    <div
      className={classNames('border-l-4 pl-1 border-l-red-400 flex flex-row bg-white flex-grow w-full overflow-hidden', {
        'cursor-pointer': !props.disabled,
      })}
      onClick={() => props.onSelectCostElement(elementName)}
      title={`${costGroupCode} - ${costGroupName}`}
    >
      <div className="flex flex-row items-center flex-none overflow-hidden max-w-full"><span className="truncate">{costGroupCode}</span></div>
      <div className="pl-2 w-auto flex flex-row items-center flex-1 overflow-hidden">
        <span className="truncate">{costGroupName}</span>
      </div>
    </div>
  );
};

export const RenderCostElement = (props: RenderCostElementProps) => {
  const hasChildren = Boolean(props.costElement?.children.length) || false;
  const children = props.costElement?.children ?? [];

  return (
    <div className="flex flex-col">
      <RenderNode>
        {hasChildren ? <Arrow expanded={props.expanded ?? false} onClick={props.toggleExpansion} /> : <div className="w-4 flex-none" />}
        <RenderCostElementName
          element={props.costElement}
          onSelectCostElement={props.onSelectCostElement}
          disabled={props.disabled}
        />
      </RenderNode>
      {props.expanded && (
        <RenderChildren>
          {children.map((c) => (
            <Tree
              key={c.id ?? ''}
              nodeData={c}
              renderNode={(taxonomy, isExpanded, toggleExpansion) => (
                <RenderCostElement
                  toggleExpansion={toggleExpansion}
                  expanded={isExpanded}
                  costElement={c}
                  onSelectCostElement={props.onSelectCostElement}
                  disabled={props.disabled}
                />
              )}
              className="ml-5"
            />
          ))}
        </RenderChildren>
      )}
    </div>
  );
};
