import { getAssetClassLabel, getMeasureLabel } from '@client/project/store';
import { CustomListItemReadModel } from '@client/shared/api';
import { t } from 'i18next';

export const getCustomListLabel = (target: string) => {
  switch (target) {
    case 'AssetClass':
      return t('projectTaxonomy.building.usageType');
    case 'MeasureType':
      return t('project.measure.label');
    default:
      return target;
  }
};

export const getCustomListItemLabel = (customListTarget: string, item: CustomListItemReadModel) => {
  switch (customListTarget) {
    case 'AssetClass':
      return getAssetClassLabel(item);
    case 'MeasureType':
      return getMeasureLabel(item);
    default:
      return item.label ?? item.enumValue ?? '';
  }
};