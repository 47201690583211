import { useUi } from '@client/shared/store';
import { PropsWithChildren } from 'react';
import { useLocation, Navigate } from 'react-router-dom';

export const RequireAuth = ({ children }: PropsWithChildren) => {
  const ui = useUi();
  const loggedIn = ui.appUser.userId != null;

  const location = useLocation();

  let target = '/auth/login';

  if (!location.pathname.includes('/logout')) {
    target += '?_r=' + encodeURIComponent(location.pathname);
  }

  if (!loggedIn) return <Navigate to={target} />;

  return <>{children}</>;
};
