import { useApiGetCalculationModelFinancingQuery } from '@client/shared/api';
import { useLoadedVariantId } from './useLoadedVariantId';
import { useLoadedProjectId } from './useLoadedProjectId';
import { useHasLicenseFeature, useValidateProjectPermission } from '@client/shared/permissions';

export const useLoadedVariantFinance = () => {
  const loadedVariantId = useLoadedVariantId();
  const loadedProjectId = useLoadedProjectId();
  const hasCalculateLicenseFeature = useHasLicenseFeature('CALCULATE');
  const hasFinanceReadPermission = useValidateProjectPermission(['FINANCING_READ'], loadedProjectId ?? '');

  return useApiGetCalculationModelFinancingQuery(
    {
      projectId: loadedProjectId ?? 'unset',
      calculationModelId: loadedVariantId ?? 'unset'
    },
    {
      skip: loadedVariantId == null || !hasCalculateLicenseFeature || !hasFinanceReadPermission,
    }
  );
};
