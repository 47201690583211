import { DecoratedCard, ICON_MONEY_DISTRIBUTION_WATERMARK_IMAGE } from '@client/shared/toolkit';
import { formatNumber } from '@client/shared/utilities';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface SensitivityAnalysisProps {
  className?: string
  multiple: number;
  totalRentEarnings: number;
}

export const SensitivityAnalysis = ({ className, multiple, totalRentEarnings}: SensitivityAnalysisProps) => {
  const  { t } = useTranslation();

  const calculateSensetivityMultiples = () => {
      const averageMultiple = Math.round(multiple);

      return [
          averageMultiple - 2,
          averageMultiple - 1,
          averageMultiple,
          averageMultiple + 1,
          averageMultiple + 2
      ]
    }

    const calculateSensetivityValues = () => {
      const valueInMillions = totalRentEarnings / 1000000;

      return [
          Math.round(valueInMillions * 0.8 * 10) / 10,
          Math.round(valueInMillions * 0.9 * 10) / 10,
          Math.round(valueInMillions * 10) / 10,
          Math.round(valueInMillions * 1.1 * 10) / 10,
          Math.round(valueInMillions * 1.2 * 10) / 10,
      ]
    }

    const sensitivityMultiples = calculateSensetivityMultiples();
    const sensitivityValues = calculateSensetivityValues();


  return(
    <DecoratedCard className={className}>
      <DecoratedCard.Header variant="plain">
        {t('project.sensitivityAnalysis.title')}
      </DecoratedCard.Header>
    <DecoratedCard.Content>
      <div className="px-6 py-3 h-full bg-white"
        style={{
          backgroundImage : `url(${ICON_MONEY_DISTRIBUTION_WATERMARK_IMAGE})`,
          backgroundRepeat: 'no-repeat',
          backgroundPositionX: '95%',
          backgroundPositionY: '90%'
        }}
      >
        <div className="w-full overflow-x-auto">
          <table className="w-full">
            <thead>
            <tr className="border-b">
              <th colSpan={1}/>
              <th className="text-center pt-4 font-normal text-slate-500" colSpan={5}>
                {t('project.sensitivityAnalysis.FinalizationMultiple')}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr className="border-b border-dotted">
              <td className="py-1 px-2 text-left font-bold text-lg whitespace-nowrap">{t('project.sensitivityAnalysis.LeveredIrr')}</td>
              <td className="py-1 px-2 text-right text-lg">{sensitivityMultiples[0]}x</td>
              <td className="py-1 px-2 text-right text-lg">{sensitivityMultiples[1]}x</td>
              <td className="py-1 px-2 text-right font-bold text-lg">{sensitivityMultiples[2]}x</td>
              <td className="py-1 px-2 text-right text-lg">{sensitivityMultiples[3]}x</td>
              <td className="py-1 px-2 text-right text-lg">{sensitivityMultiples[4]}x</td>
            </tr>
            {Array.from({ length: 5 }).map((_, i) => {
              const currentValue = sensitivityValues[i];
              return (
                <tr className="border-b border-dotted" key={i}>
                  <td className={classNames('flex flex-row py-1 px-2 text-lg text-left', { 'font-bold': i === 2 })}>
                    {currentValue}
                    <div className='text-xs ml-1'>{t('project.sensitivityAnalysis.millionShort')}</div>
                  </td>
                  <td className="py-1 px-2 text-right text-lg">{formatNumber(Math.round(currentValue * sensitivityMultiples[0] * 10) / 10)}</td>
                  <td className="py-1 px-2 text-right text-lg">{formatNumber(Math.round(currentValue * sensitivityMultiples[1] * 10) / 10)}</td>
                  <td className="py-1 px-2 text-right text-lg">{formatNumber(Math.round(currentValue * sensitivityMultiples[2] * 10) / 10)}</td>
                  <td className="py-1 px-2 text-right text-lg">{formatNumber(Math.round(currentValue * sensitivityMultiples[3] * 10) / 10)}</td>
                  <td className="py-1 px-2 text-right text-lg">{formatNumber(Math.round(currentValue * sensitivityMultiples[4] * 10) / 10)}</td>
                </tr>
              );
            })}
            </tbody>
          </table>
        </div>
      </div>
    </DecoratedCard.Content>
  </DecoratedCard>
  );
};
