import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import {
  Button,
  DecoratedCard,
  DocumentListItem,
  FileInput,
  ListTitle,
  LoadingIndicator,
  Modal
} from '@client/shared/toolkit';
import {
  apiEndpointUrls,
  RequirementDocumentReadModel,
  RequirementReadModel,
  useApiPostCreateClusterItemRequirementDocumentsMutation
} from '@client/shared/api';
import { RequirementDocumentDeleteModal } from './RequirementDocumentDeleteModal';
import { useLoadedPortfolioId } from '@client/portfolio/store';
import { safeMutation } from '@client/shared/utilities';

interface RequirementDocumentsProps {
  requirement: RequirementReadModel
}
export const RequirementDocuments = (props: RequirementDocumentsProps) => {
  const {
    requirement
  } = props;
  const { t } = useTranslation();
  const loadedPortfolioId = useLoadedPortfolioId();

  const [files, setFiles] = useState<FileList | null>(null);

  const [postDocuments, { isLoading: isCreatingDocuments }] = useApiPostCreateClusterItemRequirementDocumentsMutation();

  const handleFileChange = (fileList: FileList | null) => {
    setFiles(fileList);
  };

  const handleSaveDocuments = async () => {
    if (files && files.length > 0 && loadedPortfolioId && requirement.id) {
      const formData = new FormData();

      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i]);
      }
      try {
        await safeMutation(
          postDocuments,
          {
            portfolioId: loadedPortfolioId,
            clusterItemId: requirement.clusterItem.id,
            requirementId: requirement.id,
            body: formData as unknown as { files: Blob[] },
          },
          isCreatingDocuments
        );

        setFiles(null);
      } catch (e) {
        console.log(e, 'error')
      }
    }
  };

  return (
    <>
      {isCreatingDocuments && <LoadingIndicator mode="overlay" text={t('common.uploading')} />}
      <DecoratedCard shadowVariant="normal">
        <DecoratedCard.Content>
          <ListTitle title={t('projectCalculate.addElementDocument')} />
          <div className="bg-white p-3 rounded-sm">
            <FileInput
              acceptedFileTypes={[]}
              multiple={true}
              selectedFiles={files}
              onChange={(files) => handleFileChange(files)}
            />
          </div>

          <div className="flex justify-end mt-4 p-3">
            <Button variant="primary" onClick={handleSaveDocuments} disabled={files === null}>
              {t('common.upload')}
            </Button>
          </div>
        </DecoratedCard.Content>
      </DecoratedCard>

      <DecoratedCard className="mt-4" shadowVariant="normal">
        <DecoratedCard.Content className="mb-2">
          <ListTitle title={t('projectCalculate.elementDocument')} />
          {requirement.documents.length > 0 && requirement.documents.map((document) => (
            <RequirementDocumentRow
              clusterItemId={requirement.clusterItem.id}
              requirementId={requirement.id}
              document={document}
              key={document.id}
            />
          ))}
          {!requirement.documents.length && (
            <div className="flex flex-col items-center justify-center p-5 bg-white text-gray-500">
              {t('projectCalculate.elementDocumentsEmpty')}
            </div>
          )}
        </DecoratedCard.Content>
      </DecoratedCard>
    </>
  );
}

interface RequirementDocumentRowProps {
  clusterItemId: string;
  requirementId: string;
  document: RequirementDocumentReadModel;
}

const RequirementDocumentRow = ({ clusterItemId, requirementId, document }: RequirementDocumentRowProps) => {
  const loadedPortfolioId = useLoadedPortfolioId();
  const { t } = useTranslation();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const downloadUrl = apiEndpointUrls.apiDeleteClusterItemRequirementDocument
                      .replace(':portfolioId', loadedPortfolioId as string)
                      .replace(':clusterItemId', clusterItemId)
                      .replace(':requirementId', requirementId)
                      .replace(':documentId', document.id);

  return (
    <>
      <DocumentListItem
        fileName={document.fileName}
        title={document.fileName}
        fileSize={document.fileSize}
        created={document.created}
        downloadUrl={downloadUrl}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        contextMenu
        documentViewerTitle={t('common.document')}
      />
      <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
        <RequirementDocumentDeleteModal
          requirementId={requirementId}
          clusterItemId={clusterItemId}
          document={document}
          onClose={() => setIsDeleteModalOpen(false)}
        />
      </Modal>
    </>
  );
};
