import {
  setExpandedCashflowCostsReportIds,
  setExpandedCashflowEarningReportIds,
  setExpandedCashflowFinancingReportIds,
  setExpandedCashflowRiskReportIds,
  useExpandedReportingIds,
  useLoadedProjectId,
  useLoadedVariantId,
} from '@client/project/store';
import {
  CashFlowGroupReportSectionResponse,
  CashFlowIntervalEnum,
  useApiGetCashFlowGroupReportQuery,
} from '@client/shared/api';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { DecoratedCard, HeaderButton, LoadingIndicator, Modal, useComponentDimensions } from '@client/shared/toolkit';
import { formatDateOnly } from '@client/shared/utilities';
import cn from 'classnames';
import { PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ReportingContext } from '../ReportingContextProvider';
import { CashflowModal } from './CashflowModal/CashflowModal';
import { ReportCashflowTableFixed } from './Components/ReportCashflowTableFixed';
import { ReportCashflowTableMonths } from './Components/ReportCashflowTableMonths';
import { ReportCashflowTableQuarters } from './Components/ReportCashflowTableQuarters';
import { ReportCashflowTableYears } from './Components/ReportCashflowTableYears';

export const CASHFLOW_COLUMN_MIN_WIDTH = 117;
export const CASHFLOW_COLUMN_QUARTER_MIN_WIDTH = 100;
export const CASHFLOW_COLUMN_COLLAPSED_WIDTH = 50;

const PDF_EXPORT_SIZE_MULTIPLIER = 0.264375;

export const ReportCashflow = () => {
  const { t } = useTranslation();

  const projectId = useLoadedProjectId();
  const activeVariantId = useLoadedVariantId();

  const [isCashTableOpen, setIsCashTableOpen] = useState(true);
  const [isRiskTableOpen, setIsRiskTableOpen] = useState(true);
  const [isEarningTableOpen, setIsEarningTableOpen] = useState(true);
  const [isFinancingTableOpen, setIsFinancingTableOpen] = useState(true);

  const [isCashflowModalOpen, setIsCashflowModalOpen] = useState(false);
  const { cashflowSettings, setPDFExportSize, setCashflowSettings, setExportVariables } = useContext(ReportingContext);

  useEffect(() => {
    if (!cashflowSettings) {
      setIsCashflowModalOpen(true);
    }
  }, [activeVariantId, cashflowSettings]);

  const dispatch = useDispatch();

  const { data: cashflowData, isLoading: isFetching } = useApiGetCashFlowGroupReportQuery(
    {
      projectId: projectId ?? '',
      calculationModelId: activeVariantId ?? '',
      start: cashflowSettings?.startPoint ? formatDateOnly(cashflowSettings.startPoint) : undefined,
      interval: cashflowSettings?.interval,
      includeCost: cashflowSettings?.groups.includes('Costs'),
      includeRisk: cashflowSettings?.groups.includes('Risks'),
      includeRevenue: cashflowSettings?.groups.includes('Earnings'),
      includeFinance: cashflowSettings?.groups.includes('Finance'),
    },
    {
      skip: !cashflowSettings || !projectId || !activeVariantId,
    },
  );

  useEffect(() => {
    if (cashflowData) {
      // 950 is the width of the fixed columns
      // 64 is the width of the paddings
      // special case for quarters, where we add 30px to the width - need to find a better solution
      let initialWidth = 950 + 64 + (cashflowData.interval === 'Quarter' ? 20 : 0);
      let selector = '';
      if (cashflowData.interval === 'Month') {
        selector = '.cashflow-columns-months';
      } else if (cashflowData.interval === 'Quarter') {
        selector = '.cashflow-columns-quarters';
      } else if (cashflowData.interval === 'Year') {
        selector = '.cashflow-columns-years';
      }

      const width = document.querySelector(selector)?.scrollWidth;
      initialWidth += width ?? 0;

      setPDFExportSize({
        reportRoute: ROUTES_CONFIG.REPORTING_CASHFLOW.name,
        size: initialWidth * PDF_EXPORT_SIZE_MULTIPLIER,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cashflowData]);

  useEffect(() => {
    if (!cashflowSettings) {
      setExportVariables(undefined);
      return;
    }
    setExportVariables([
      {
        name: 'start',
        value: cashflowSettings.startPoint ? formatDateOnly(cashflowSettings.startPoint) : '',
      },
      {
        name: 'interval',
        value: cashflowSettings.interval ?? '',
      },
      {
        name: 'includeCost',
        value: cashflowSettings.groups.includes('Costs') ? 'true' : 'false',
      },
      {
        name: 'includeRisk',
        value: cashflowSettings.groups.includes('Risks') ? 'true' : 'false',
      },
      {
        name: 'includeRevenue',
        value: cashflowSettings.groups.includes('Earnings') ? 'true' : 'false',
      },
      {
        name: 'includeFinance',
        value: cashflowSettings.groups.includes('Finance') ? 'true' : 'false',
      },
    ]);
  }, [cashflowSettings, setExportVariables]);

  const {
    Cashflow: {
      Costs: expandedCostElements,
      Risk: expandedRiskElements,
      Earning: expandedEarningElements,
      Financing: expandedFinancingElements,
    },
  } = useExpandedReportingIds();

  const showNoData = !cashflowData?.cost && !cashflowData?.risk && !cashflowData?.revenue && !cashflowData?.finance;
  const showNoFilterSelected = !!isFetching && !cashflowData;

  return (
    <>
      <DecoratedCard className="min-w-[1383px]">
        <DecoratedCard.Header showActionButton={false}>
          <div className="flex flex-row justify-between items-center w-full">
            <div className="flex">
              <div className="truncate">{t('reporting.cashflowReport.title')}</div>
            </div>
            <div className="flex items-center">
              <div className="flex items-center mr-6">
                <div className="font-bold text-[15px] pr-6">{t('reporting.filterBy')}</div>
                <div className="flex space-x-0.5 items-center">
                  <div onClick={() => setIsCashflowModalOpen(true)}>
                    <HeaderButton
                      showToggle
                      subtitle={t('reporting.cashflowReport.customized')}
                      title={t('reporting.cashflowReport.settings')}
                      className="pr-10 cursor-pointer"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DecoratedCard.Header>
        <DecoratedCard.Content className="w-full h-full flex relative">
          {isFetching && <LoadingIndicator text={t('reporting.loadingReport')} mode="overlay-window" />}
          {showNoFilterSelected && (
            <div className="w-full h-full flex items-center justify-center py-10">
              <div className="text-slate-300">{t('reporting.cashflowReport.noFilterSelected')}</div>
            </div>
          )}
          {showNoData && (
            <div className="w-full h-full flex items-center justify-center py-10">
              <div className="text-slate-300">{t('reporting.cashflowReport.noData')}</div>
            </div>
          )}
          {cashflowData?.cost && (
            <CashflowGroup accentColor="bg-red-700">
              <div className="w-full max-w-[950px] flex-none">
                <ReportCashflowTableFixed
                  title={t('reporting.cashflowReport.sectionCost')}
                  titleColor="text-red-700"
                  footerName={t('reporting.cashflowReport.totalInvestitionCost')}
                  sectionExpanded={isCashTableOpen}
                  toggleSection={() => setIsCashTableOpen(!isCashTableOpen)}
                  data={cashflowData.cost}
                  expandedElements={expandedCostElements}
                  onExpand={(ids) => {
                    dispatch(setExpandedCashflowCostsReportIds(ids));
                  }}
                />
              </div>
              <CashflowTable
                data={cashflowData.cost}
                expandedElements={expandedCostElements}
                interval={cashflowData.interval}
                startDate={cashflowData.start ?? ''}
                endDate={cashflowData.end ?? ''}
                sectionExpanded={isCashTableOpen}
              />
            </CashflowGroup>
          )}

          {cashflowData?.risk && (
            <CashflowGroup accentColor="bg-sky-700">
              <div className="w-full max-w-[950px] flex-none">
                <ReportCashflowTableFixed
                  title={t('reporting.cashflowReport.sectionRisk')}
                  titleColor="text-sky-700"
                  footerName={t('reporting.cashflowReport.calculateRiskTotalRisk')}
                  sectionExpanded={isRiskTableOpen}
                  toggleSection={() => setIsRiskTableOpen(!isRiskTableOpen)}
                  data={cashflowData.risk}
                  expandedElements={expandedRiskElements}
                  onExpand={(ids) => {
                    dispatch(setExpandedCashflowRiskReportIds(ids));
                  }}
                />
              </div>
              <CashflowTable
                data={cashflowData.risk}
                expandedElements={expandedRiskElements}
                interval={cashflowData.interval}
                startDate={cashflowData.start ?? ''}
                endDate={cashflowData.end ?? ''}
                sectionExpanded={isRiskTableOpen}
              />
            </CashflowGroup>
          )}
          {cashflowData?.revenue && (
            <CashflowGroup accentColor="bg-lime-600">
              <div className="w-full max-w-[950px] flex-none">
                <ReportCashflowTableFixed
                  title={t('reporting.cashflowReport.sectionRevenue')}
                  titleColor="text-lime-600"
                  footerName={t('reporting.cashflowReport.calculateEarningsTotalEarnings')}
                  sectionExpanded={isEarningTableOpen}
                  toggleSection={() => setIsEarningTableOpen(!isEarningTableOpen)}
                  data={cashflowData.revenue}
                  expandedElements={expandedEarningElements}
                  onExpand={(ids) => {
                    dispatch(setExpandedCashflowEarningReportIds(ids));
                  }}
                />
              </div>
              <CashflowTable
                data={cashflowData.revenue}
                expandedElements={expandedEarningElements}
                interval={cashflowData.interval}
                startDate={cashflowData.start ?? ''}
                endDate={cashflowData.end ?? ''}
                sectionExpanded={isEarningTableOpen}
              />
            </CashflowGroup>
          )}

          {cashflowData?.finance && (
            <CashflowGroup accentColor="bg-slate-600">
              <div className="w-full max-w-[950px] flex-none">
                <ReportCashflowTableFixed
                  title={t('reporting.cashflowReport.sectionFinance')}
                  titleColor="text-slate-600"
                  footerName={t('reporting.cashflowReport.calculateFinancingTitle')}
                  sectionExpanded={isFinancingTableOpen}
                  toggleSection={() => setIsFinancingTableOpen(!isFinancingTableOpen)}
                  data={cashflowData.finance}
                  expandedElements={expandedFinancingElements}
                  onExpand={(ids) => {
                    dispatch(setExpandedCashflowFinancingReportIds(ids));
                  }}
                />
              </div>
              <CashflowTable
                data={cashflowData.finance}
                expandedElements={expandedFinancingElements}
                interval={cashflowData.interval}
                startDate={cashflowData.start ?? ''}
                endDate={cashflowData.end ?? ''}
                sectionExpanded={isFinancingTableOpen}
              />
            </CashflowGroup>
          )}
        </DecoratedCard.Content>
      </DecoratedCard>

      <Modal isOpen={isCashflowModalOpen} onClose={() => setIsCashflowModalOpen(false)} zIndex="z-50">
        <CashflowModal
          onClose={() => setIsCashflowModalOpen(false)}
          filter={cashflowSettings ?? null}
          onCashflowFilterChange={(data) => {
            setCashflowSettings(data);
            setIsCashflowModalOpen(false);
          }}
        />
      </Modal>
    </>
  );
};

interface CashflowGroupProps extends PropsWithChildren {
  accentColor: string;
}

const CashflowGroup = ({ accentColor, children }: CashflowGroupProps) => {
  const wrapper = useRef<HTMLDivElement>(null);
  const dimensions = useComponentDimensions(wrapper);
  return (
    <div className="relative w-full" ref={wrapper}>
      <div
        className={cn('w-2 my-4 bg-cyan-700 rounded -ml-1 left-0 absolute', accentColor)}
        style={{ height: (dimensions.height ?? 15) - 32 }}
      />

      <div className="w-full flex overflow-x-auto overflow-y-hidden">{children}</div>
    </div>
  );
};

export interface CashflowTableProps {
  data: CashFlowGroupReportSectionResponse;
  expandedElements: string[];
  interval: CashFlowIntervalEnum;
  startDate: string;
  endDate: string;
  sectionExpanded: boolean;
}

const CashflowTable = ({
  data,
  expandedElements,
  interval,
  startDate,
  endDate,
  sectionExpanded,
}: CashflowTableProps) => {
  return (
    <div className="w-full max-w-[calc(100%-950px)]">
      {interval === 'Month' && (
        <ReportCashflowTableMonths
          expandedElements={expandedElements}
          data={data ?? undefined}
          startDate={startDate ?? ''}
          endDate={endDate ?? ''}
          sectionExpanded={sectionExpanded}
        />
      )}
      {interval === 'Quarter' && (
        <ReportCashflowTableQuarters
          expandedElements={expandedElements}
          data={data ?? undefined}
          startDate={startDate ?? ''}
          endDate={endDate ?? ''}
          sectionExpanded={sectionExpanded}
        />
      )}
      {interval === 'Year' && (
        <ReportCashflowTableYears
          expandedElements={expandedElements}
          data={data ?? undefined}
          startDate={startDate ?? ''}
          endDate={endDate ?? ''}
          sectionExpanded={sectionExpanded}
        />
      )}
    </div>
  );
};
