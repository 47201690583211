import { UserTenantReadModel } from '@client/shared/api';
import { useUi } from '@client/shared/store';
import { Badge, BadgeGroup, ContextMenu, ContextMenuItem, CompanyAvatar } from '@client/shared/toolkit';
import { createTenantLink } from '@client/shared/utilities';
import { ArrowsRightLeftIcon } from '@heroicons/react/24/outline';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

interface CompanyRowProps {
  tenant: UserTenantReadModel;
}

export const CompanyRow = ({ tenant }: CompanyRowProps) => {
  const { t } = useTranslation();

  const ui = useUi();
  const user = ui.appUser;
  const isCurrent = user.tenant?.tenantId === tenant.tenantId;

  const getContextMenuItems = (): ContextMenuItem[] => {
    const result: ContextMenuItem[] = [];

    result.push({
      label: t('app.settingsCompaniesSwitch'),
      icon: <ArrowsRightLeftIcon />,
      onClick: () => switchTenant(tenant),
      isDisabled: isCurrent,
    });

    return result;
  };

  const switchTenant = useCallback((tenant: UserTenantReadModel) => {
    window.location.href = createTenantLink(tenant.tenantSubdomain);
  }, []);

  return (
    <div className="flex w-full border-b border-gray-100 hover:bg-slate-100 cursor-pointer items-center py-2">
      <div className="flex w-full items-center">
        <div>
          <CompanyAvatar
            tenantName={tenant?.tenantName ?? ''}
            tenantId={tenant?.tenantId ?? ''}
            hasAvatar={tenant?.tenantHasAvatar ?? false}
            lastUpdate={tenant?.tenantAvatarLastUpdate ?? ''}
            classNames="w-12 h-12 mr-3"
          />
        </div>
        <div className="w-6/12">
          <div className="font-bold">{tenant.tenantName}</div>
        </div>
        <div className="w-1/12">
          <div className="flex items-center">
            <BadgeGroup>
              <Badge className="text-center" variant="danger" text={t('app.settingsBadgeOwner')} />
            </BadgeGroup>
          </div>
        </div>
        <div className="w-2/12 flex items-center">
          <BadgeGroup>
            <Badge className="w-12 text-center" variant="secondary" text={tenant.groupCount.toString()} />
          </BadgeGroup>
        </div>
        <div className="w-2/12">
          <BadgeGroup>
            <Badge className="w-12 text-center" variant="secondary" text={tenant.projectCount.toString()} />
          </BadgeGroup>
        </div>
        <div className="w-1/12">
          <div className="flex justify-end text-gray-500 hover:text-black mr-4 mt-2 ml-2">
            <ContextMenu items={getContextMenuItems()} />
          </div>
        </div>
      </div>
    </div>
  );
};
