import { apiEndpointUrls, DocumentReadModel } from '@client/shared/api';
import { useMemo, useState } from 'react';
import { Modal, DocumentListItem } from '@client/shared/toolkit';
import { CalculateElementDocumentDeleteModal } from './CalculateElementDocumentDeleteModal';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { DocumentOwnerType } from './CalculateElementDocuments';
import { useTranslation } from 'react-i18next';

interface CalculateElementDocumentRowProps {
  ownerId: string;
  type: DocumentOwnerType;
  elementDocument: DocumentReadModel;
  canDelete: boolean;
}

const apiEndpoints = {
  'CostElement': apiEndpointUrls.apiGetCostElementDocument,
  'EarningsElement': apiEndpointUrls.apiGetEarningsElementDocument,
  'RiskElement': apiEndpointUrls.apiGetRiskElementDocument,
  'FinancingElement': apiEndpointUrls.apiGetFinancingElementDocument,
};

export const CalculateElementDocumentRow = ({
  ownerId,
  type,
  elementDocument,
  canDelete,
}: CalculateElementDocumentRowProps) => {
  const loadedProjectId = useLoadedProjectId();
  const loadedCalculationModelId = useLoadedVariantId();
  const { t } = useTranslation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const downloadUrl = useMemo(() => {
    const endpoint = apiEndpoints[type];
    if (!endpoint) return '';

    return endpoint
      .replace(':projectId', loadedProjectId as string)
      .replace(':calculationModelId', loadedCalculationModelId as string)
      .replace(`:${type.toLowerCase()}Id`, ownerId)
      .replace(':documentId', elementDocument.id);
  }, [type, loadedProjectId, loadedCalculationModelId, ownerId, elementDocument.id]);

  return (
    <>
      <DocumentListItem
        fileName={elementDocument.name}
        fileSize={elementDocument.size}
        created={elementDocument.created}
        downloadUrl={downloadUrl}
        setIsDeleteModalOpen={canDelete ? setIsDeleteModalOpen : undefined}
        contextMenu
        documentViewerTitle={t('common.document')}
      />
      <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)} variant="small">
        <CalculateElementDocumentDeleteModal
          ownerId={ownerId}
          type={type}
          document={elementDocument}
          onClose={() => setIsDeleteModalOpen(false)}
        />
      </Modal>
    </>
  );
};
