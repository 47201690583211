import * as yup from 'yup';
import { InferType } from 'yup';

export const SpvBranchFormValidationSchema = yup.object({
  companyBranchId: yup.string().required('validation.required').notOneOf([''], 'validation.required'),
  share: yup.number().required('validation.required').min(0, 'validation.min').max(100, 'validation.max'),
  isDefault: yup.boolean().required('validation.required'),
});

export type SpvBranchFormValidationValues = InferType<typeof SpvBranchFormValidationSchema>;
