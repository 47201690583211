import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import cn from 'classnames';
import { SortDownIcon } from '../../../icons';
import React, { PropsWithChildren, ReactElement, ReactNode, useEffect, useRef } from 'react';
import { ClusterList, ClusterListProps } from './ClusterList';
import { ListItem } from '../../List';
import { Badge, BadgeProps } from '../../Badges';
import { formatDate } from '@client/shared/utilities';
import { formatDistanceToNow } from 'date-fns';
import { ContextMenu, ContextMenuItem } from '../../ContextMenu';
import { Avatar } from '../../Avatar';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Highlighted } from '../../TextHighlighter';
import { apiEndpointUrls } from '@client/shared/api';

const PORTFOLIO_LIST_BG_COLORS = ['text-white bg-sky-900', 'text-slate-500 bg-slate-100', 'text-slate-500 bg-slate-50'];

export const ClusterListItem = (props: ClusterListProps) => {
  const {
    defaultOpen = true,
    listItems = [],
    level = 0,
    theme = 'cluster',
    icon,
    image,
    title,
    children,
    subtitle,
    customColor,
    onClick,
    onTitleClick,
    onToggle,
  } = props;

  const color = customColor ? customColor : PORTFOLIO_LIST_BG_COLORS[level] ?? 'bg-slate-100';

  const buttonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (buttonRef.current) {
      if (
        (defaultOpen && !buttonRef.current.dataset.headlessuiState?.includes('open')) ||
        (!defaultOpen && buttonRef.current.dataset.headlessuiState?.includes('open'))
      ) {
        buttonRef.current.click();
      }
    }
  }, [defaultOpen]);

  if (listItems.length) {
    return (
      <Disclosure defaultOpen={defaultOpen}>
        {({ open }) => (
          <>
            <DisclosureButton as="div" className="flex gap-1 min-h-[64px] w-full items-center" ref={buttonRef}>
              <div
                className="flex gap-1 min-h-[64px] w-full items-center"
                onClick={onToggle ? () => onToggle(!open) : undefined}
              >
                <SortDownIcon
                  className={cn(
                    'text-slate-600 opacity-60 transition-transform duration-100 w-6 h-6 flex-none cursor-pointer',
                    {
                      '-rotate-180': !open,
                    },
                  )}
                />
                <button className={cn('flex items-center w-full gap-4 ml-1.5 min-h-[64px]', color)}>
                  <ClusterListItemContent
                    image={image}
                    icon={icon}
                    title={title}
                    subtitle={subtitle}
                    onTitleClick={onTitleClick}
                    level={level}
                  >
                    {children}
                  </ClusterListItemContent>
                </button>
              </div>
            </DisclosureButton>
            <DisclosurePanel>
              {theme === 'requirement' ? (
                <div className={cn('flex gap-1 min-h-[64px]')}>
                  <div className="w-6 flex-none h-full" />
                  <div className="p-4 bg-slate-200 flex-1 ml-1.5">
                    <ClusterList listItems={listItems} theme={theme} level={level + 1} className="gap-y-1" />
                  </div>
                </div>
              ) : (
                <ClusterList listItems={listItems} theme={theme} level={level + 1} />
              )}
            </DisclosurePanel>
          </>
        )}
      </Disclosure>
    );
  }

  return (
    <>
      {theme === 'cluster' ? (
        <div className={cn('flex gap-1 min-h-[64px] w-full')}>
          <div className="w-6 flex-none h-full" />
          <div className={cn('flex items-center w-full gap-4 ml-1.5 min-h-[64px]', color)}>
            <ClusterListItemContent
              image={image}
              icon={icon}
              title={title}
              subtitle={subtitle}
              onClick={onClick}
              onTitleClick={onTitleClick}
              level={level}
            >
              {children}
            </ClusterListItemContent>
          </div>
        </div>
      ) : (
        <ListItem borderColor="bg-secondary" bgColor="bg-slate-50" shadow={false} as="div" onClick={onClick}>
          <ClusterListItemContent
            image={image}
            icon={icon}
            title={title}
            subtitle={subtitle}
            onTitleClick={onTitleClick}
            level={level}
            theme={theme}
          >
            {children}
          </ClusterListItemContent>
        </ListItem>
      )}
    </>
  );
};

export interface ClusterListItemContentProps extends PropsWithChildren {
  icon?: ReactNode;
  image?: ReactNode;
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  onClick?: () => void;
  onTitleClick?: () => void;
  level?: number;
  theme?: 'cluster' | 'requirement';
  parent?: boolean;
  searchValue?: string;
}

export const ClusterListItemContent = (props: ClusterListItemContentProps) => {
  const {
    icon,
    image,
    title,
    children,
    subtitle,
    onClick,
    onTitleClick,
    level = 0,
    theme = 'cluster',
    parent = false,
    searchValue
  } = props;

  return (
    <div
      className={cn('flex w-full items-center flex-wrap gap-3', {
        'cursor-pointer': onClick,
        'p-2.5': level < 3 && theme === 'cluster',
        'pl-8': level === 1 && theme === 'cluster',
        'pl-12': level === 2 && theme === 'cluster',
      })}
    >
      {(icon || image || title || subtitle) && (
        <div
          className={cn('flex items-center gap-4 overflow-hidden', {
            'flex-1 min-w-[300px] max-w-full w-full': theme === 'cluster',
            'w-full lg:w-[300px]': theme === 'requirement',
          })}
          onClick={onClick}
        >
          {icon && (
            <>
              {theme === 'cluster' ? (
                <div className="w-10 h-10 flex items-center justify-center flex-none">
                  {React.cloneElement(icon as ReactElement, { className: 'w-8 h-8' })}
                </div>
              ) : (
                <div
                  className={cn('w-8 h-8 flex items-center justify-center flex-none rounded-full text-white', {
                    'bg-slate-300': !parent,
                    'bg-slate-500': parent,
                  })}
                >
                  {React.cloneElement(icon as ReactElement, { className: 'w-4 h-4' })}
                </div>
              )}
            </>
          )}
          {image && (
            <div
              className={cn('flex-none', {
                'w-10 h-10': theme === 'cluster',
                'w-8 h-8': theme === 'requirement',
              })}
            >
              {image}
            </div>
          )}
          {(title || subtitle) && (
            <div className="flex flex-col items-start text-left leading-none">
              {title && (
                <div
                  className={cn('font-bold', {
                    'cursor-pointer transition-colors duration-300': onTitleClick,
                    'hover:text-gray-300': onTitleClick && level < 2,
                    'hover:text-gray-500': onTitleClick && level >= 2,
                    'text-[15px] leading-5': theme === 'requirement',
                    'text-lg': theme === 'cluster',
                  })}
                  onClick={
                    onTitleClick
                      ? (e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onTitleClick();
                        }
                      : undefined
                  }
                >
                  {searchValue ? <Highlighted text={title.toString()} highlight={searchValue} /> : title}
                </div>
              )}
              {subtitle && (
                <div
                  className={cn({
                    'font-bold text-[11px] text-gray-500': theme === 'requirement',
                    'font-light text-[15px]': theme === 'cluster',
                  })}
                >
                  {searchValue ? <Highlighted text={subtitle.toString()} highlight={searchValue} /> : subtitle}
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {children}
    </div>
  );
};

interface ClusterListInnerListItemContentProps {
  status?: BadgeProps;
  date?: string;
  contextMenuItems?: ContextMenuItem[];
}

export const ClusterListInnerListItemContent = (props: ClusterListInnerListItemContentProps) => {
  const { status, date, contextMenuItems } = props;

  return (
    <div className="flex justify-between items-center flex-1 min-w-[240px]">
      {status && (
        <div className="flex-none flex items-center">
          <Badge {...status} />
        </div>
      )}
      <div className="flex-1 items-center flex pl-3.5 gap-2 justify-end">
        {date && (
          <div className="items-end flex flex-col leading-none gap-1">
            <span className="text-[15px]">{formatDate(date)}</span>
            <span className="text-[12px] font-bold text-gray-500">
              {formatDistanceToNow(date, { addSuffix: true })}
            </span>
          </div>
        )}
        {contextMenuItems && (
          <div className="flex flex-none h-full">
            <ContextMenu
              items={contextMenuItems}
              className="px-2"
              stopPropagation
            />
          </div>
        )}
      </div>
    </div>
  );
};

interface ClusterListTaskListItemContentProps {
  status?: BadgeProps;
  date?: string;
  assignee?: {
    name: string;
    id: string;
    email: string;
    groupName: string;
    hasAvatar: boolean;
  };
}

export const ClusterListTaskListItemContent = (props: ClusterListTaskListItemContentProps) => {
  const { status, date, assignee } = props;

  return (
    <div className="flex-1 flex lg:pl-3.5 gap-2 justify-between flex-wrap lg:flex-nowrap lg:justify-end items-center w-full lg:w-auto">
      <div className="w-1/3 flex items-center">
        {assignee && (
          <Avatar
            name={assignee.name}
            className="w-8 h-8"
            rounded={true}
            imagePath={assignee?.hasAvatar ? apiEndpointUrls.apiGetUserAvatar.replace(':userId', assignee.id) : undefined}
          />
        )}
        <div className="w-full truncate ml-2">
          <div className="font-bold truncate">{assignee?.name}</div>
          <div className="text-sm text-gray-500 truncate">
            {assignee?.email ?? '-'}
            {assignee?.groupName && <>&nbsp;&bull;&nbsp;{assignee?.groupName}</>}
          </div>
        </div>
      </div>
      {status && (
        <div className="w-[90px] flex-none flex items-center justify-end">
          <Badge {...status} />
        </div>
      )}
      {date && (
        <div className="flex-none items-end flex flex-col leading-none gap-1 w-[130px]">
          <span className="text-[15px]">{formatDate(date)}</span>
          <span className="text-[12px] font-bold text-gray-500">{formatDistanceToNow(date, { addSuffix: true })}</span>
        </div>
      )}
      <div className="w-6 items-end flex flex-none">
        <ChevronDownIcon className="w-full font-bold -rotate-90" />
      </div>
    </div>
  );
};
