import { GaebItemDataRecord } from '@client/shared/api';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import cn from 'classnames';
import { LabeledValue, ToggleButton } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { formatCurrency, formatNumber, formatPercentage } from '@client/shared/utilities';

interface GaebImportRecordProps {
  element: GaebItemDataRecord;
  level?: number;
}

export const GaebImportRecord = ({ element, level = 0 }: GaebImportRecordProps) => {
  const { t } = useTranslation();

  return (
    <Disclosure as="div" className="w-full" defaultOpen={false}>
    {({ open }) => (
      <>
        <DisclosureButton as="div">
          <div
            className={cn('flex items-center hover:text-black hover:bg-gray-50 relative', {
              'border-b font-bold text-sm text-gray-900': level === 0,
              'text-xs text-gray-500': level > 0,
            })}
          >
            <ToggleButton className="absolute z-[5] -left-6 top-0 h-full" open={open} />
            <div className='flex flex-row items-center justify-between w-full my-1'>
              <div className="pl-2 w-full h-full flex flex-row cursor-pointer items-center">
                <div className="mr-5">{element.code}</div>
                <div style={level > 1 ? { marginLeft: `${level * 0.6}rem` } : undefined}>
                  {element.name}
                </div>
              </div>
            </div>                  
          </div>
        </DisclosureButton>
        <DisclosurePanel>
          <div className='flex flex-col'>
            <div className="flex flex-row">
              <div className="w-1/4">
                <LabeledValue value={element.code ?? '-'}>{t('common.code')}</LabeledValue>
              </div>
              <div className="w-1/4">
                <LabeledValue value={element.name ?? '-'}>{t('common.name')}</LabeledValue>
              </div>
              <div className="w-1/4">
                <LabeledValue value={formatPercentage(element.vat) ?? '-'}>{t('common.vat')}</LabeledValue>
              </div>     
              <div className="w-1/4">
                <LabeledValue value={formatPercentage(element.discount / 100)}>{t('projectContract.discount')}</LabeledValue>
              </div>             
            </div>

            {element.isPosition &&            
              <div className="flex flex-row">
                <div className="w-1/3">
                  <LabeledValue value={formatNumber(element.amount) + ' ' + element.amountUnit}>{t('common.amount')}</LabeledValue>
                </div>
                <div className="w-1/3">
                  <LabeledValue value={formatCurrency(element.unitPrice) ?? '-'}>{t('common.unitPrice')}</LabeledValue>
                </div>
                <div className="w-1/3">
                  <LabeledValue value={formatCurrency(element.totalValue) ?? '-'}>{t('common.total')}</LabeledValue>
                </div>                
              </div>
            }

            {element.isPosition &&            
              <div className="flex flex-row">
                <div className="w-1/3">
                  <LabeledValue value={formatPercentage(element.discount / 100)}>{t('projectContract.discount')}</LabeledValue>
                </div>             
              </div>
            }

            {element.children?.length > 0 && (
              <>
                {element.children.map((child, i) => (
                  <GaebImportRecord
                    key={`catalog-element-child-${child.code}-${i}`}
                    element={child}
                    level={level + 1}
                  />
                ))}
              </>
            )}                
          </div>
        </DisclosurePanel>
      </>
    )}
  </Disclosure>
  );
};