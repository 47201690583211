import { TaxonomyReadModel, State, useApiPostDuplicateTaxonomyMutation, TaxonomyType } from '@client/shared/api';
import { isDateInBetween, formatUnit, stopPropagation, safeMutation } from '@client/shared/utilities';
import { isBefore, parseISO } from 'date-fns';
import { useEffect, useState, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ContextMenuItem,
  ContextMenu,
  Badge,
  BadgeGroup,
  Modal,
  DragContainer,
  TextHighlighter,
  AddNewIcon,
  DuplicateIcon,
  MoveGrabberIcon,
  PencilIcon,
  TrashIcon,
  AddIcon,
  LoadingIndicator,
} from '@client/shared/toolkit';
import { useAllowedTaxonomyTypes } from '../../hooks';
import { useLoadedProjectId, setExpandedTaxonomyIds, useExpandedTaxonomyIds } from '@client/project/store';
import { BuildingItemAddChildModal, BuildingItemAddParentModal, BuildingItemDeleteModal } from './';
import classNames from 'classnames';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { getBuildingLabel, getChildIds } from '../../utils';
import { useDragDropManager } from 'react-dnd';
import { useDispatch } from 'react-redux';
import { FlattenedTaxonomyItem, getTaxonomyIcon } from '@client/project/shared';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { useNavigate } from 'react-router-dom';

interface BuildingRowProps {
  item: TaxonomyReadModel;
  depth: number;
  isReadOnly: boolean;
  canDelete: boolean;
  index: number;
  moveItem: (
    dragIndex: number | string,
    hoverIndex: number | string | null,
    dropped: boolean,
    validMove: boolean,
  ) => void;
  hoveredItem: TaxonomyReadModel | null;
  draggedItem: TaxonomyReadModel | null;
  dndEnabled: boolean;
  setDndEnabled: (value: boolean) => void;
  droppedOutside: () => void;
  mousePosition: number | null;
  setMousePosition: (value: number) => void;
  taxonomyTree: FlattenedTaxonomyItem<TaxonomyReadModel>[];
  searchValue?: string;
  isTaxonomyDashboardOpen: boolean;
}

export const BuildingRow = ({
  depth,
  item,
  isReadOnly,
  canDelete,
  index,
  moveItem,
  hoveredItem,
  draggedItem,
  dndEnabled,
  setDndEnabled,
  droppedOutside,
  mousePosition,
  setMousePosition,
  taxonomyTree,
  searchValue,
  isTaxonomyDashboardOpen,
}: BuildingRowProps) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  const [isOpenAddParentModal, setIsOpenAddParentModal] = useState(false);
  const [isOpenAddChildModal, setIsOpenAddChildModal] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);

  const loadedProjectId = useLoadedProjectId();
  const { forCreatingChild, forCreatingParent } = useAllowedTaxonomyTypes(item);
  const [createDuplicate, { isLoading: isDuplicating }] = useApiPostDuplicateTaxonomyMutation();

  const navigate = useNavigate();

  const addChildEnabled = forCreatingChild.length > 0;
  const addParentEnabled = forCreatingParent.length > 0;

  const dispatch = useDispatch();
  const expandedTaxonomyIds = useExpandedTaxonomyIds();
  const expandedIds = useMemo(
    () => expandedTaxonomyIds.find((x) => x.project === (loadedProjectId ?? ''))?.ids ?? [],
    [expandedTaxonomyIds, loadedProjectId],
  );

  const handleCreateDuplicate = async (includeChildren: boolean) => {
    try {
      await safeMutation(
        createDuplicate,
        {
          projectId: loadedProjectId ?? '',
          calculationModelId: item?.calculationModelId ?? '',
          taxonomyItemId: item?.itemId ?? '',
          includeChildren: includeChildren,
        },
        isDuplicating,
      );
    } catch (e) {
      console.log(e);
    }
  };

  const onAddParent = () => {
    setIsOpenAddParentModal(true);
  };

  const onAddChild = () => {
    setIsOpenAddChildModal(true);
  };

  const onUpdate = () => {
    navigate(
      ROUTES_CONFIG.PROJECT_TAXONOMY_VIEW.path
        .replace(':id', loadedProjectId ?? '')
        .replace(':itemId', item?.itemId ?? ''),
    );
  };

  const onDelete = () => {
    setDeleteModalOpen(true);
  };

  const onDuplicateGroup = () => {
    handleCreateDuplicate(true);
  };

  const onDuplicate = () => {
    handleCreateDuplicate(false);
  };

  const handleCloseSlideOver = () => {
    setDndEnabled(true);
  };

  function handleItemTypeChange() {
    if (isTaxonomyDashboardOpen && item.itemType !== 'Parking') {
      handleCloseSlideOver();
      onUpdate();
    }
  }

  useEffect(() => {
    handleItemTypeChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.itemType]);

  const rentedClosedDeal = item.dealCosts.find((x) => x.status === 'Closed' && x.type === 'Rent');
  let isRentedDealActive = false;
  if (!rentedClosedDeal?.start && !rentedClosedDeal?.end) {
    isRentedDealActive = true;
  } else if (rentedClosedDeal?.end && isBefore(Date.now(), parseISO(rentedClosedDeal.end))) {
    isRentedDealActive = true;
  } else if (rentedClosedDeal?.start && rentedClosedDeal?.end) {
    isRentedDealActive = isDateInBetween(Date.now(), rentedClosedDeal.start, rentedClosedDeal.end);
  }

  const soldClosedDeal = item.dealCosts.find((x) => x.status === 'Closed' && x.type === 'Sell');
  let isSoldDealActive = false;
  if (!soldClosedDeal?.start && !soldClosedDeal?.end) {
    isSoldDealActive = true;
  } else if (soldClosedDeal?.end && isBefore(Date.now(), parseISO(soldClosedDeal.end))) {
    isSoldDealActive = true;
  } else if (soldClosedDeal?.start && soldClosedDeal?.end) {
    isSoldDealActive = isDateInBetween(Date.now(), soldClosedDeal.start, soldClosedDeal.end);
  }

  const isRented = rentedClosedDeal?.type === 'Rent' && isRentedDealActive;
  const isSold = soldClosedDeal?.type === 'Sell' && isSoldDealActive;

  const reservedDeal = item.dealCosts.find((x) => x.status === 'Reserved');
  let isReservedDealActive = false;
  if (!reservedDeal?.start && !reservedDeal?.end) {
    isReservedDealActive = true;
  } else if (reservedDeal?.end && isBefore(Date.now(), parseISO(reservedDeal.end))) {
    isReservedDealActive = true;
  } else if (reservedDeal?.start && reservedDeal?.end) {
    isReservedDealActive = isDateInBetween(Date.now(), reservedDeal.start, reservedDeal.end);
  }

  const isReserved = (reservedDeal?.type === 'Rent' || reservedDeal?.type === 'Sell') && isReservedDealActive;

  const contextItems: ContextMenuItem[] = [
    {
      label: t('projectTaxonomy.buildingAddParentAction'),
      subtitle: t('projectTaxonomy.buildingAddParentActionDescription'),
      icon: <AddIcon />,
      onClick: () => onAddParent(),
      isDisabled: !addParentEnabled || isReadOnly,
    },
    {
      label: t('projectTaxonomy.buildingAddChildAction'),
      subtitle: t('projectTaxonomy.buildingAddChildActionDescription'),
      icon: <AddIcon />,
      onClick: () => onAddChild(),
      isDisabled: !addChildEnabled || isReadOnly,
    },
    {
      label: t('common.update'),
      subtitle: t('projectTaxonomy.buildingUpdateActionDescription'),
      icon: <PencilIcon />,
      isDisabled: isReadOnly,
      onClick: () => onUpdate(),
    },
    {
      label: t('projectTaxonomy.buildingCopyGroup'),
      subtitle: t('projectTaxonomy.buildingCopyGroupDescription'),
      icon: <DuplicateIcon />,
      isDisabled: isReadOnly,
      onClick: () => onDuplicateGroup(),
    },
    {
      label: t('common.copy'),
      subtitle: t('projectTaxonomy.buildingCopyDescription'),
      icon: <AddNewIcon />,
      isDisabled: isReadOnly,
      onClick: () => onDuplicate(),
    },
    {
      label: t('common.delete'),
      subtitle: t('projectTaxonomy.buildingDeleteActionDescription'),
      icon: <TrashIcon />,
      isDisabled: isReadOnly || !canDelete,
      onClick: () => onDelete(),
    },
  ];

  const dragDropManager = useDragDropManager();
  const monitor = dragDropManager.getMonitor();

  useEffect(() => {
    monitor.subscribeToOffsetChange(() => {
      const offset = monitor.getClientOffset();

      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        const x = offset?.x ?? 0;
        const width = rect.right - rect.left;

        if (x < width / 4) setMousePosition(1);
        else if (x < (width / 4) * 2) setMousePosition(2);
        else if (x < (width / 4) * 3) setMousePosition(3);
        else setMousePosition(4);
      }
    });
  }, [monitor, setMousePosition]);

  useEffect(() => {
    if (expandedIds.includes(item.itemId)) setIsExpanded(true);
    else setIsExpanded(false);
  }, [expandedIds, item]);

  const isChildOfDraggedItem = draggedItem && getChildIds(draggedItem).includes(item.itemId);

  const allowedChildTypes = (): TaxonomyType[] => {
    if (mousePosition === 3 || mousePosition === 4 || (item.children.length > 0 && isExpanded)) return forCreatingChild;
    const parent = taxonomyTree.find((x) => x.taxonomyItem.itemId === item.parent?.itemId);
    if (!parent) return ['BuildingComplex', 'Building', 'Floor', 'Sector', 'Room', 'Zone', 'Parking', 'Bed', 'Other'];
    if (mousePosition === 1 && lastChild && item.children.length === 0 && !isExpanded) {
      const parentsParent = taxonomyTree.find((x) => x.taxonomyItem.itemId === parent?.taxonomyItem.parent?.itemId);
      if (!parentsParent)
        return ['BuildingComplex', 'Building', 'Floor', 'Sector', 'Room', 'Zone', 'Parking', 'Bed', 'Other'];
      const parentsParentTypes = parentsParent.taxonomyItem.types as TaxonomyType[];
      return parentsParentTypes.filter(
        (_, idx: number) => idx > parentsParentTypes.indexOf(parent.taxonomyItem.itemType as TaxonomyType),
      );
    }
    const parentTypes = parent.taxonomyItem.types as TaxonomyType[];
    return parentTypes.filter(
      (_, idx: number) => idx > parentTypes.indexOf(parent.taxonomyItem.itemType as TaxonomyType),
    );
  };

  const lastChild = useMemo(() => {
    const parent = taxonomyTree.find((x) => x.taxonomyItem.itemId === item.parent?.itemId);
    if (!parent) return false;
    const parentsParent = taxonomyTree.find((x) => x.taxonomyItem.itemId === parent?.taxonomyItem.parent?.itemId);
    if (!parentsParent) return false;
    const children = parent.taxonomyItem.children;
    return children[children.length - 1].itemId === item.itemId;
  }, [item, taxonomyTree]);

  const icon = useMemo(() => {
    const IconComponent = getTaxonomyIcon(item.itemType);
    return <IconComponent className="w-8 h-8" />;
  }, [item.itemType]);

  const inner = (
    <>
      <div
        className={classNames('relative h-16 flex cursor-pointer items-center transition-all w-full justify-between', {
          'border-l-8 border-l-green-900 border-opacity-70': isTaxonomyDashboardOpen,
          'border-l-8 border-transparent': !isTaxonomyDashboardOpen,
        })}
        onClick={() => onUpdate()}
      >
        <div className="pl-2 flex w-1/3 flex-none pr-2">
          <div style={{ paddingRight: depth - 1 + 'rem' }} />
          <div className="w-6 flex justify-center items-center">
            {item.children.length > 0 && (
              <ChevronDownIcon
                className={classNames('w-6 h-6', { '-rotate-90 transform': !isExpanded })}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  dispatch(
                    setExpandedTaxonomyIds([
                      ...expandedTaxonomyIds.filter((x) => x.project !== (loadedProjectId ?? '')),
                      {
                        project: loadedProjectId ?? '',
                        ids: isExpanded ? expandedIds.filter((x) => x !== item.itemId) : [...expandedIds, item.itemId],
                      },
                    ]),
                  );
                }}
              />
            )}
          </div>
          <div className="px-4 flex-none flex">{icon}</div>
          <div className="flex flex-1 flex-col xl:flex-row justify-between flex-wrap">
            <div className="flex flex-col flex-grow truncate">
              <div className="font-bold text-lg leading-none">
                {searchValue ? (
                  <TextHighlighter
                    text={item.itemName}
                    highlighted={searchValue
                      .split(',')
                      .filter((x) => x !== '')
                      .map((x) => x.trim())}
                  />
                ) : (
                  item.itemName
                )}
                {item?.itemCustomerName && <span> &bull; {item.itemCustomerName}</span>}
              </div>
              <div className="text-[15px] leading-tight font-normal">{t(getBuildingLabel(item))}</div>
            </div>
            <div className="flex-grow flex xl:justify-end items-center">
              {isReserved && <Badge variant="success" text={t('projectRent.dealCostItemReserved')} />}
              {isReserved && (isRented || isSold) && <span>&nbsp;</span>}
              {isRented && !isSold && <Badge variant="success" text={t('projectRent.dealCostItemRented')} />}
              {isRented && isSold && (
                <BadgeGroup>
                  <Badge variant="success" text={t('projectRent.dealCostItemRented')} side="left" />
                  <Badge variant="success" text={t('projectRent.dealCostItemSold')} side="right" />
                </BadgeGroup>
              )}
              {isSold && !isRented && <Badge variant="success" text={t('projectRent.dealCostItemSold')} />}
            </div>
          </div>
        </div>
        <div className="flex-none w-2/3 place-content-end flex gap-4 h-full pl-2">
          <div className="flex-none flex flex-col text-right justify-center w-[75px] overflow-hidden">
            {!!item.sizes?.amount?.value && (
              <div className="text-right leading-tight text-gray-900">
                <div className="">{item.sizes?.amount?.value}</div>
                <div className="text-[10px] font-normal text-gray-500">
                  {t(
                    item.sizes?.amount?.value > 1
                      ? 'projectTaxonomy.parkingLotPieces'
                      : 'projectTaxonomy.parkingLotPiece',
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="flex-1 grid grid-cols-3 gap-6 max-w-[600px]">
            <ColumnSum
              state={item.sizes?.rentalSpaceState}
              value={item.sizes?.rentalSpace?.value}
              effectiveValue={item.sizes?.effectiveRentalSpaceValue?.value}
              calculatedValue={item.sizes?.calculatedRentalSpace?.value}
              remainderValue={item.sizes?.rentalSpaceRestValue?.value}
              unit={
                item.sizes?.rentalSpace?.unit ??
                item.sizes?.effectiveRentalSpaceValue?.unit ??
                item.sizes?.calculatedRentalSpace?.unit ??
                item.sizes?.rentalSpaceRestValue?.unit
              }
            />
            {item.itemType !== 'Parking' ? (
              <>
                <ColumnSum
                  state={item.sizes?.netFloorState}
                  value={item.sizes?.ngf?.value}
                  effectiveValue={item.sizes?.effectiveNetFloorValue?.value}
                  calculatedValue={item.sizes?.calculatedNetFloor?.value}
                  remainderValue={item.sizes?.netFloorRestValue?.value}
                  unit={
                    item.sizes?.ngf?.unit ??
                    item.sizes?.effectiveNetFloorValue?.unit ??
                    item.sizes?.calculatedNetFloor?.unit ??
                    item.sizes?.netFloorRestValue?.unit
                  }
                />

                <ColumnSum
                  state={item.sizes?.grossFloorState}
                  value={item.sizes?.bgf?.value}
                  effectiveValue={item.sizes?.effectiveGrossFloorValue?.value}
                  calculatedValue={item.sizes?.calculatedGrossFloor?.value}
                  remainderValue={item.sizes?.grossFloorRestValue?.value}
                  unit={
                    item.sizes?.bgf?.unit ??
                    item.sizes?.effectiveGrossFloorValue?.unit ??
                    item.sizes?.calculatedGrossFloor?.unit ??
                    item.sizes?.grossFloorRestValue?.unit
                  }
                />
              </>
            ) : (
              <>
                <div />
                <div />
              </>
            )}
          </div>

          <div className="flex w-[104px] flex-none">
            <div className="flex justify-center items-center w-8 ml-6">
              {dndEnabled && <MoveGrabberIcon className="w-6 hover:w-7 cursor-move" />}
            </div>
            <div
              className="px-3 flex items-center justify-center text-gray-500 hover:text-gray-800"
              onClick={stopPropagation}
            >
              <ContextMenu items={contextItems} className="w-6 h-6" />
            </div>
          </div>
        </div>
      </div>

      <div
        className={classNames('h-1 flex flex-row items-center transition-all relative', {
          'ml-28': (mousePosition === 3 || mousePosition === 4) && (item.children.length === 0 || !isExpanded),
          'ml-12':
            ((mousePosition === 1 && !lastChild) || mousePosition === 2) && (item.children.length === 0 || !isExpanded),
          'ml-0 ': mousePosition === 1 && lastChild && item.children.length === 0 && !isExpanded,
          'ml-20': item.children.length > 0 && isExpanded,
        })}
        style={{ paddingRight: depth - 1 + 'rem' }}
      >
        {hoveredItem?.itemId === item.itemId && draggedItem && (
          <>
            <div className="absolute w-2 h-2 border-2 border-sky-900 rounded-full" />
            <div
              className={classNames('ml-2 w-full h-1', {
                'bg-emerald-300': allowedChildTypes().includes(draggedItem?.itemType),
                'bg-red-300': !allowedChildTypes().includes(draggedItem?.itemType),
              })}
            />
          </>
        )}
      </div>
    </>
  );

  return (
    <>
      {isDuplicating && (
        <LoadingIndicator text={t('projectTaxonomy.creatingTaxonomyLoadingIndicator')} mode="overlay-window" />
      )}
      {dndEnabled ? (
        <DragContainer
          forwardRef={ref}
          selectedItemType={item.itemType as string}
          draggedItem={draggedItem}
          draggedType={draggedItem?.itemType as string}
          allowedTypesForElement={allowedChildTypes()}
          allAllowedTypes={[
            'BuildingComplex',
            'Area',
            'Quarter',
            'Building',
            'Wing',
            'Floor',
            'Unit',
            'Sector',
            'Room',
            'Zone',
            'Parking',
            'Bed',
            'Other',
          ]}
          index={index}
          moveItem={moveItem}
          id={item.itemId}
          droppedOutside={droppedOutside}
        >
          {(handlerId, isDragging) =>
            !isDragging ? (
              !isChildOfDraggedItem && (
                <div data-handler-id={handlerId}>
                  <div
                    className={classNames('text-slate-500 transition-all', {
                      'bg-slate-100': depth === 1 || index === 0,
                      'bg-slate-50': depth === 2,
                      'bg-white text-zinc-600': depth > 2,
                      'hover:bg-slate-200': !draggedItem && index !== 0,
                    })}
                    ref={ref}
                  >
                    {inner}
                  </div>
                </div>
              )
            ) : (
              <div className="h-[68px] w-full bg-white shadow-inner border border-slate-100" />
            )
          }
        </DragContainer>
      ) : (
        <div
          className={classNames('text-slate-500 transition-all hover:bg-slate-200', {
            'bg-slate-100': depth <= 1,
            'bg-slate-50': depth === 2,
            'bg-white text-zinc-600': depth > 2,
          })}
        >
          {inner}
        </div>
      )}

      <Modal isOpen={isOpenAddParentModal} onClose={() => setIsOpenAddParentModal(false)}>
        <BuildingItemAddParentModal onClose={() => setIsOpenAddParentModal(false)} child={item} />
      </Modal>

      <Modal isOpen={isOpenAddChildModal} onClose={() => setIsOpenAddChildModal(false)}>
        <BuildingItemAddChildModal onClose={() => setIsOpenAddChildModal(false)} parent={item} />
      </Modal>

      <Modal isOpen={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
        <BuildingItemDeleteModal onClose={() => setDeleteModalOpen(false)} item={item} />
      </Modal>
    </>
  );
};

interface ColumnSumProps {
  className?: string;
  state?: State;
  value?: number | null;
  calculatedValue?: number | null;
  effectiveValue?: number | null;
  remainderValue?: number | null;
  unit?: string;
}

const ColumnSum = ({
  className,
  state,
  value,
  effectiveValue,
  calculatedValue,
  remainderValue,
  unit = 'm²',
}: ColumnSumProps) => {
  const columnKpiNumber = (state?: State) => {
    switch (state) {
      case 'JustValue':
        return { value: formatUnit(value, unit) };
      case 'JustCalculated':
        return { value: formatUnit(calculatedValue, unit), className: 'italic' };
      case 'Equality':
        return { value: formatUnit(effectiveValue, unit), overflow: '+/- 0' };
      case 'Remainder':
        return {
          value: formatUnit(effectiveValue, unit),
          overflow: `+ ${formatUnit(remainderValue, unit)}`,
          overflowClassName: 'text-green-800',
        };
      case 'Overflow':
        return {
          value: formatUnit(effectiveValue, unit),
          overflow: `- ${formatUnit(remainderValue, unit, { signDisplay: 'never' })}`,
          overflowClassName: 'text-red-800',
          className: 'text-red-800',
        };
      default:
        return null;
    }
  };

  const values = columnKpiNumber(state);

  return (
    <div className={classNames('flex items-center justify-end text-gray-900', className)}>
      <div className="text-right leading-tight">
        <div className={values?.className}>{values?.value}</div>
        <div className={classNames('text-[10px]', values?.overflowClassName)}>{values?.overflow}</div>
      </div>
    </div>
  );
};
