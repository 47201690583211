import { AddToClipboardIcon, List, ListItemProps, LoadingIndicator } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TasksSortHeader } from './TasksSortHeader';
import { TasksListItem } from './TasksListItem';
import { sortByProperty, SortHeaderType } from '@client/shared/utilities';
import { useApiGetAllTasksQuery, useApiGetUserWorkflowTasksQuery, WorkflowTaskReadModel } from '@client/shared/api';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { useNavigate } from 'react-router-dom';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { useUi } from '@client/shared/store';

export const TasksContainer = ({ searchValue }: { searchValue: string }) => {
  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();
  const ui = useUi();
  const user = ui.appUser;
  const navigate = useNavigate();

  const { data: tenantTasks, isFetching } = useApiGetAllTasksQuery(
    {
      projectId: loadedProjectId ?? '',
      calculationModelId: loadedVariantId ?? '',
    },
    {
      skip: !loadedProjectId || !loadedVariantId || !user?.tenant?.isOwner,
    },
  );

  const { data: userTasks, isFetching: isLoadingMyTasks } = useApiGetUserWorkflowTasksQuery(
    {
      projectId: loadedProjectId ?? '',
      calculationModelId: loadedVariantId ?? '',
    },
    {
      skip: !loadedProjectId || !loadedVariantId,
    },
  );
  const [openTasks, setOpenTasks] = useState<WorkflowTaskReadModel[]>([]);
  const [allCompletedTasks, setAllCompletedTasks] = useState<WorkflowTaskReadModel[]>([]);

  useEffect(() => {
    if (tenantTasks && user?.tenant?.isOwner) {
      setOpenTasks(tenantTasks.workflowTasks.filter((task) => !task.completedAt));
      setAllCompletedTasks(tenantTasks.workflowTasks.filter((task) => !!task.completedAt));
    } else if (userTasks && !user?.tenant?.isOwner) {
      setOpenTasks(userTasks.workflowTasks.filter((task) => !task.completedAt));
      setAllCompletedTasks(userTasks.workflowTasks.filter((task) => !!task.completedAt));
    }
  }, [tenantTasks, userTasks, user]);

  const defaultSortHeader: SortHeaderType[] = useMemo(() => {
    return [
      {
        asc: null,
        label: t('projectTasks.sortHeaderTask'),
        type: 'string',
        value: 'taskActivityName',
      },
      {
        asc: null,
        label: t('projectTasks.sortHeaderStatus'),
        type: 'string',
        value: 'status',
      },
      {
        asc: null,
        label: t('projectTasks.sortHeaderDate'),
        type: 'date',
        value: 'createdAt',
      },
    ];
  }, [t]);

  const [sortHeader, setSortHeader] = useState<SortHeaderType[]>(defaultSortHeader);

  const onHandleSort = useCallback(
    (index: number) => {
      const currentSortValues = [...sortHeader];
      const update = currentSortValues[index];
      update.asc = update.asc === null ? true : !update.asc;
      currentSortValues.forEach((val, i) => {
        if (i !== index) {
          val.asc = null;
        }
      });
      currentSortValues[index] = update;
      setSortHeader(currentSortValues);
    },
    [sortHeader],
  );

  const tasksList = useMemo(() => {
    const openTaskListItems: ListItemProps[] = [];
    const completedTaskListItems: ListItemProps[] = [];

    let sortedOpenTasks = [...openTasks];
    let sortedCompletedTasks = [...allCompletedTasks];

    // apply search value
    if (searchValue) {
      const searchFor = searchValue.toLowerCase();
      sortedOpenTasks = sortedOpenTasks.filter((task) => {
        return (
          task.workflowName?.toLowerCase().includes(searchFor) ||
          task.taskActivityName?.toLowerCase().includes(searchFor) ||
          task.taskEntityReadModel?.name?.toLowerCase().includes(searchFor) ||
          task.taskEntityReadModel?.description?.toLowerCase().includes(searchFor)
        );
      });
      sortedCompletedTasks = sortedCompletedTasks.filter((task) => {
        return (
          task.workflowName?.toLowerCase().includes(searchFor) ||
          task.taskActivityName?.toLowerCase().includes(searchFor) ||
          task.taskEntityReadModel?.name?.toLowerCase().includes(searchFor) ||
          task.taskEntityReadModel?.description?.toLowerCase().includes(searchFor)
        );
      });
    }

    const sortByIndex = sortHeader.find((item) => item.asc !== null);
    if (sortByIndex) {
      sortedOpenTasks = sortByProperty(sortedOpenTasks, sortByIndex) as WorkflowTaskReadModel[];
      sortedCompletedTasks = sortByProperty(sortedCompletedTasks, sortByIndex) as WorkflowTaskReadModel[];
    }

    sortedOpenTasks.forEach((task) => {
      openTaskListItems.push({
        id: task.workflowTaskId,
        borderColor: 'bg-slate-400',
        children: <TasksListItem task={task} searchValue={searchValue} />,
        onClick: () => {
          if (loadedProjectId && task.taskEntityReadModel?.id) {
            if (task.taskEntityReadModel?.entityType === 'Invoice') {
              navigate(
                ROUTES_CONFIG.PROJECT_INVOICE_VIEW.path
                  .replace(':id', loadedProjectId)
                  .replace(':invoiceId', task.taskEntityReadModel.id),
              );
            } else if (
              task.taskEntityReadModel?.entityType === 'Document' &&
              task.taskEntityReadModel?.description === 'Succeeded'
            ) {
              navigate(
                ROUTES_CONFIG.PROJECT_INVOICE_DOCUMENT_VIEW.path
                  .replace(':id', loadedProjectId)
                  .replace(':documentId', task.taskEntityReadModel.id),
              );
            } else if (task.taskEntityReadModel?.entityType === 'Contract') {
              navigate(
                ROUTES_CONFIG.PROJECT_CONTRACT_VIEW.path
                  .replace(':id', loadedProjectId)
                  .replace(':contractId', task.taskEntityReadModel?.id),
              );
            } else if (task.taskEntityReadModel?.entityType === 'Supplement' && task.taskEntityReadModel?.parentId) {
              navigate(
                ROUTES_CONFIG.PROJECT_CONTRACT_TITLE_VIEW.path
                  .replace(':id', loadedProjectId)
                  .replace(':contractId', task.taskEntityReadModel?.parentId)
                  .replace(':titleId', task.taskEntityReadModel?.id),
              );
            }
          }
        },
      });
    });

    sortedCompletedTasks.forEach((task) => {
      completedTaskListItems.push({
        id: task.workflowTaskId,
        borderColor: 'bg-green-400',
        children: <TasksListItem task={task} searchValue={searchValue} />,
        onClick: () => {
          if (task.taskEntityReadModel?.entityType === 'Invoice' && loadedProjectId && task.taskEntityReadModel.id) {
            navigate(
              ROUTES_CONFIG.PROJECT_INVOICE_VIEW.path
                .replace(':id', loadedProjectId)
                .replace(':invoiceId', task.taskEntityReadModel.id),
            );
          } else if (task.taskEntityReadModel?.entityType === 'Contract' && loadedProjectId) {
            navigate(
              ROUTES_CONFIG.PROJECT_CONTRACT_VIEW.path
                .replace(':id', loadedProjectId)
                .replace(':contractId', task.taskEntityReadModel?.id),
            );
          } else if (
            task.taskEntityReadModel?.entityType === 'Supplement' &&
            loadedProjectId &&
            task.taskEntityReadModel?.parentId
          ) {
            navigate(
              ROUTES_CONFIG.PROJECT_CONTRACT_TITLE_VIEW.path
                .replace(':id', loadedProjectId)
                .replace(':contractId', task.taskEntityReadModel?.parentId)
                .replace(':titleId', task.taskEntityReadModel?.id),
            );
          }
        },
      });
    });

    return {
      openTasks: openTaskListItems,
      completedTasks: completedTaskListItems,
    };
  }, [allCompletedTasks, openTasks, searchValue, sortHeader, navigate, loadedProjectId]);

  return (
    <>
      {(isFetching || isLoadingMyTasks) && (
        <LoadingIndicator mode="overlay-window" text={t('projectTasks.loadingTasksIndicatorMessage')} />
      )}
      <List
        icon={<AddToClipboardIcon className="w-5 h-5" />}
        listTitle={t('projectTasks.openTasks')}
        items={tasksList.openTasks}
        emptyMessage={t('projectTasks.noTasks')}
        sortHeader={
          tasksList.openTasks.length > 0 ? (
            <TasksSortHeader onHandleSort={onHandleSort} sortHeader={sortHeader} />
          ) : null
        }
        collapsible
        showPagination={true}
        amountPerPage={5}
      />
      <List
        className="mt-10"
        icon={<AddToClipboardIcon className="w-5 h-5" />}
        listTitle={t('projectTasks.closedTasks')}
        items={tasksList.completedTasks}
        emptyMessage={t('projectTasks.noTasks')}
        sortHeader={
          tasksList.completedTasks.length > 0 ? (
            <TasksSortHeader onHandleSort={onHandleSort} sortHeader={sortHeader} />
          ) : null
        }
        collapsible
        showPagination={true}
        amountPerPage={5}
      />
    </>
  );
};
