import fraport_header from './fraport-header.jpg';
import project_placeholder_1 from './project-placeholder-1.jpg';
import project_placeholder_2 from './project-placeholder-2.jpg';
import project_placeholder_3 from './project-placeholder-3.jpg';
import project_placeholder_4 from './project-placeholder-4.jpg';
import project_placeholder_5 from './project-placeholder-5.jpg';
import project_placeholder_6 from './project-placeholder-6.jpg';
import project_placeholder_empty from './project-placeholder-empty.svg?url';

export const FRAPORT_HEADER_IMAGE = fraport_header;
export const PROJECT_PLACEHOLDER_1_IMAGE = project_placeholder_1;
export const PROJECT_PLACEHOLDER_2_IMAGE = project_placeholder_2;
export const PROJECT_PLACEHOLDER_3_IMAGE = project_placeholder_3;
export const PROJECT_PLACEHOLDER_4_IMAGE = project_placeholder_4;
export const PROJECT_PLACEHOLDER_5_IMAGE = project_placeholder_5;
export const PROJECT_PLACEHOLDER_6_IMAGE = project_placeholder_6;
export const PROJECT_PLACEHOLDER_EMPTY_IMAGE = project_placeholder_empty;
