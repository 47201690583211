import {
  CustomListItemReadModel,
  CustomListReadModel,
  useApiDeleteCustomListItemMutation,
  useApiPostUpdateCustomListItemMutation,
} from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useRef, useState } from 'react';
import {
  AddButton,
  PencilIcon,
  AddIcon,
  ContextMenu,
  ProjectSettingsEditSection,
  TrashIcon,
  DragContainer,
  MoveGrabberIcon,
  LoadingIndicator,
  CustomDragLayer,
  DropOnlyField,
  Badge,
} from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { DefaultValuesCustomListItemEditSlideOver } from '.';
import classNames from 'classnames';
import { getCustomListItemLabel, getCustomListLabel } from '../../../utils/getCustomListLabel';

const isChild = (parentItem: CustomListItemReadModel, childItem: CustomListItemReadModel): boolean => {
  if (childItem.parentId === parentItem.id) {
    return true;
  }
  if (parentItem.children.length > 0) {
    return parentItem.children.some((child) => isChild(child, childItem));
  }
  return false;
};

export interface DefaultValuesCustomListItemEditProps {
  defaultValuesId: string;
  customList: CustomListReadModel;
  classNames?: string;
}

export const DefaultValuesCustomListItemEdit = ({
  defaultValuesId,
  customList,
  classNames
}: DefaultValuesCustomListItemEditProps) => {
  const { t } = useTranslation();

  const [updateDefaultValue, { isLoading: isUpdating }] = useApiPostUpdateCustomListItemMutation();
  const [deleteDefaultValue, { isLoading: isDeleting }] = useApiDeleteCustomListItemMutation();

  const [isAddSlideOverOpen, setIsAddSlideOverOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<CustomListItemReadModel | null>(null);
  const [selectedItemParentId, setSelectedItemParentId] = useState<string | null>(null);
  const [hoveredItem, setHoveredItem] = useState<CustomListItemReadModel | null>(null);
  const [draggedItem, setDraggedItem] = useState<CustomListItemReadModel | null>(null);

  const handleDelete = async (itemId: string) => {
    try {
      await safeMutation(
        deleteDefaultValue,
        {
          defaultValuesId: defaultValuesId,
          customListId: customList.id,
          customListItemId: itemId,
        },
        isDeleting,
      );
    } catch (e) {
      console.error(e);
    }
  };

  const handleMove = async (movedItem: CustomListItemReadModel, newParentId: string | null) => {
    try {
      resetItems();
      await safeMutation(
        updateDefaultValue,
        {
          defaultValuesId: defaultValuesId,
          customListId: customList.id,
          customListItemId: movedItem?.id,
          body: {
            parentId: newParentId,
            enumValue: movedItem.enumValue,
            label: movedItem.label,
          },
        },
        isUpdating,
      );
    } catch (e) {
      console.error(e);
    }
  };

  const resetItems = () => {
    setHoveredItem(null);
    setDraggedItem(null);
  };

  const sortedCustomList = [...customList.items].sort((a, b) =>
    getCustomListItemLabel(customList.target, a).localeCompare(getCustomListItemLabel(customList.target, b)),
  );

  const flattenedItems = useMemo(() => {
    const flatten = (items: CustomListItemReadModel[]): CustomListItemReadModel[] => {
      return items.reduce((acc, item) => {
        acc.push(item);
        if (item.children.length > 0) {
          acc.push(...flatten(item.children));
        }
        return acc;
      }, [] as CustomListItemReadModel[]);
    };

    return flatten(customList.items);
  }, [customList.items]);

  const moveItem = useCallback(
    (dragIndex: number | string, hoverIndex: number | string | null, dropped: boolean, validMove: boolean) => {
      const dragItem = flattenedItems.find((item) => item.id === dragIndex);

      if (!hoverIndex && dragItem) {
        setHoveredItem(null);
        setDraggedItem(dragItem);
        if (dropped && validMove) {
          return handleMove(dragItem, null);
        } else if (dropped) {
          return resetItems();
        } else {
          return;
        }
      }

      const hoverItem = flattenedItems.find((item) => item.id === hoverIndex);

      if (dragItem !== hoverItem && dragItem && hoverItem) {
        setHoveredItem(hoverItem);
        setDraggedItem(dragItem);
      }

      if (dropped && validMove && dragItem && hoverItem) {
        const sameParent = dragItem?.parentId === hoverItem?.id;
        const sameItem = dragItem?.id === hoverItem?.id;
        const isItsOwnChild = isChild(dragItem, hoverItem);
        const newParentId = hoverItem?.id;

        if (!sameParent && !sameItem && !isItsOwnChild) {
          handleMove(dragItem, newParentId);
        } else if (
          sameParent ||
          customList.items.indexOf(hoverItem) === 0 ||
          customList.items.indexOf(hoverItem) === customList.items.length - 1
        ) {
          handleMove(dragItem, null);
        }

        resetItems();
      } else if (dropped && !validMove) {
        resetItems();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customList.items, flattenedItems],
  );

  const allowedType = 'customListItem';

  return (
    <ProjectSettingsEditSection title={getCustomListLabel(customList.target)} className={classNames}>
      {(isDeleting || isUpdating) && <LoadingIndicator text={t('common.loading')} mode="overlay-window" />}
      <div className="mt-2 mb-4 flex flex-col">
        <div className="mb-2 ml-4">
          <DropOnlyField moveItem={moveItem} allowedTypes={[allowedType]} isDragging={!!draggedItem} />
        </div>

        {sortedCustomList.map((item, i) => (
          <CustomListItemEdit
            item={item}
            customListTarget={customList.target}
            handleSelect={(item) => {
              setSelectedItem(item);
              setIsAddSlideOverOpen(true);
            }}
            handleAdd={(parentId) => {
              setSelectedItemParentId(parentId);
              setIsAddSlideOverOpen(true);
            }}
            handleDelete={handleDelete}
            moveItem={moveItem}
            hoveredItem={hoveredItem}
            draggedItem={draggedItem}
            droppedOutside={resetItems}
            key={`custom-list-slideover-item-${customList.target}-${item.id}`}
          />
        ))}
        <div className="ml-4">
          <DropOnlyField moveItem={moveItem} allowedTypes={[allowedType]} isDragging={!!draggedItem} />
        </div>
      </div>
      {draggedItem && (
        <CustomDragLayer>
          <div className="flex items-center h-5 bg-white shadow-md p-5">
            {getCustomListItemLabel(customList.target, draggedItem)}
          </div>
        </CustomDragLayer>
      )}
      <div className="-mt-12 w-full pr-4 flex justify-end">
        <AddButton onClick={() => setIsAddSlideOverOpen(true)} />
      </div>
      <DefaultValuesCustomListItemEditSlideOver
        defaultValuesId={defaultValuesId}
        item={selectedItem}
        parentId={selectedItemParentId}
        customList={customList}
        onClose={() => {
          setIsAddSlideOverOpen(false);
          setSelectedItem(null);
          setSelectedItemParentId(null);
        }}
        isOpen={isAddSlideOverOpen}
      />
    </ProjectSettingsEditSection>
  );
};

const CustomListItemEdit = ({
  item,
  customListTarget,
  handleSelect,
  handleAdd,
  handleDelete,
  moveItem,
  hoveredItem,
  draggedItem,
  droppedOutside,
  level = 0
}: {
  item: CustomListItemReadModel;
  customListTarget: string;
  handleSelect: (item: CustomListItemReadModel) => void;
  handleAdd: (parentId: string) => void;
  handleDelete: (itemId: string) => void;
  moveItem: (
    dragIndex: number | string,
    hoverIndex: number | string | null,
    dropped: boolean,
    validMove: boolean,
  ) => void;
  droppedOutside: () => void;
  hoveredItem: CustomListItemReadModel | null;
  draggedItem: CustomListItemReadModel | null;
  level?: number;
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  const sortedChildren = [...item.children].sort((a, b) =>
    getCustomListItemLabel(customListTarget, a).localeCompare(getCustomListItemLabel(customListTarget, b)),
  );

  const allowedType = 'customListItem';

  const hasDefaultChildren = useCallback((item: CustomListItemReadModel): boolean => {
    if (item.enumValue) {
      return true;
    } else if (item.children.length > 0) {
      return item.children.some((child) => hasDefaultChildren(child));
    } else return false;
  }, []);

  return (
    <div className={classNames('flex flex-col', level > 0 ? 'ml-4' : '')} key={`custom-list-edit-${item.id}`}>
      <DragContainer
        forwardRef={ref}
        selectedItemType={allowedType}
        draggedItem={draggedItem}
        draggedType={allowedType}
        allowedTypesForElement={[allowedType]}
        allAllowedTypes={[allowedType]}
        index={item.id}
        moveItem={moveItem}
        id={item.id}
        droppedOutside={droppedOutside}
      >
        {(handlerId, isDragging) => (
          <div
            className={classNames('w-full bg-white p-2 flex justify-between', {
              'opacity-0': isDragging,
            })}
            data-handler-id={handlerId}
            ref={ref}
          >
            <button className="w-full h-full text-left flex justify-between items-center mr-2" onClick={() => handleSelect(item)}>
              {getCustomListItemLabel(customListTarget, item)}
              <div className="h-full flex justify-center items-center min-w-28">
                {item.enumValue ? (
                  <Badge text="Probis" variant="custom" className="text-white bg-green-500" />
                ) : (
                  <Badge text={t('common.custom')} variant="custom" className="text-white bg-slate-300" />
                )}
              </div>
            </button>
            <MoveGrabberIcon className="w-6 cursor-move" />
            <ContextMenu
              items={[
                {
                  label: t('common.edit'),
                  subtitle: t('app.masterDataDefaultValues.customList.editListItem'),
                  icon: <PencilIcon />,
                  onClick: () => {
                    handleSelect(item);
                  },
                },
                {
                  label: t('app.masterDataDefaultValues.customList.addListItem'),
                  subtitle: t('app.masterDataDefaultValues.customList.addListItemDescription'),
                  icon: <AddIcon />,
                  onClick: () => {
                    handleAdd(item.id);
                  },
                },
                {
                  label: t('common.delete'),
                  subtitle: t('app.masterDataDefaultValues.customList.deleteItem'),
                  icon: <TrashIcon />,
                  isDisabled: !!item.enumValue || hasDefaultChildren(item),
                  onClick: () => handleDelete(item.id),
                },
              ]}
            />
          </div>
        )}
      </DragContainer>

      <div className="w-full">
        <div
          className={classNames('w-full h-1', {
            'bg-emerald-300': !!(
              draggedItem &&
              hoveredItem &&
              hoveredItem?.id === item.id &&
              draggedItem?.id !== item.id &&
              draggedItem?.parentId !== item.id &&
              !isChild(draggedItem, hoveredItem)
            ),
            'bg-red-300': !!(
              draggedItem &&
              hoveredItem &&
              hoveredItem?.id === item.id &&
              (draggedItem?.id === item.id || draggedItem?.parentId === item.id || isChild(draggedItem, hoveredItem))
            ),
            'bg-transparent': !draggedItem || !hoveredItem || hoveredItem?.id !== item.id,
          })}
        />
      </div>

      {sortedChildren.map((child) => (
        <CustomListItemEdit
          item={child}
          customListTarget={customListTarget}
          handleSelect={handleSelect}
          handleAdd={handleAdd}
          handleDelete={handleDelete}
          moveItem={moveItem}
          hoveredItem={hoveredItem}
          draggedItem={draggedItem}
          droppedOutside={droppedOutside}
          key={`custom-list-slideover-item-${customListTarget}-${child.id}`}
          level={level + 1}
        />
      ))}
    </div>
  );
};
