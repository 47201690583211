import { CostCatalogElementDto } from '@client/shared/api';
import { isChildCostElement } from '.';
import { findCostElement } from '@client/project/shared';

export const getFilteredCostTotal = (
  flattenedCostElements: CostCatalogElementDto[],
  currentSearchResult: string[] | null,
): number => {
  if (!currentSearchResult) return 0;
  const filteredCostElements = currentSearchResult
    ?.map((id) => findCostElement(id, flattenedCostElements))
    .filter((element) => element);

  const topLevelElements = filteredCostElements?.filter((possibleChildElement) => {
    return !filteredCostElements?.some((parentElement) =>
      parentElement && possibleChildElement ? isChildCostElement(parentElement, possibleChildElement) : false,
    );
  });

  return (
    topLevelElements.reduce((acc, element) => {
      if (element && 'totalValue' in element) {
        return acc + (element.totalValue ?? 0);
      } else if (element && 'modelValues' in element) {
        return acc + (element.modelValues.effectiveValue ?? 0);
      }
      return acc;
    }, 0) ?? 0
  );
};
