import { useTranslation } from 'react-i18next';
import { Button, Form, FormField, LoadingIndicator, SlideOver, TextInput } from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import {
  CustomListItemReadModel,
  CustomListReadModel,
  useApiDeleteCustomListItemMutation,
  useApiPostCreateCustomListItemMutation,
  useApiPostUpdateCustomListItemMutation,
} from '@client/shared/api';
import classNames from 'classnames';
import { getCustomListItemLabel, getCustomListLabel } from '../../../utils/getCustomListLabel';
import { InferType } from 'yup';
import * as yup from 'yup';
import { useCallback } from 'react';

const CustomListItemFormValidationSchema = yup.object({
  label: yup.string().required('validation.required'),
});

type CustomListItemLabelFormValidationValues = InferType<typeof CustomListItemFormValidationSchema>;

interface DefaultValuesCustomListItemEditSlideOverProps {
  defaultValuesId: string;
  customList: CustomListReadModel;
  item: CustomListItemReadModel | null;
  parentId: string | null;
  onClose: (shouldRefresh: boolean) => void;
  isOpen: boolean;
}

export const DefaultValuesCustomListItemEditSlideOver = ({
  defaultValuesId,
  customList,
  item,
  parentId,
  onClose,
  isOpen,
}: DefaultValuesCustomListItemEditSlideOverProps) => {
  const { t } = useTranslation();

  const [createDefaultValue, { isLoading: isCreating }] = useApiPostCreateCustomListItemMutation();
  const [updateDefaultValue, { isLoading: isUpdating }] = useApiPostUpdateCustomListItemMutation();

  const isAddMode = !item;

  const handleClose = () => {
    onClose(false);
  };

  const handleSubmit = async (data: CustomListItemLabelFormValidationValues) => {
    if (isAddMode) {
      try {
        await safeMutation(
          createDefaultValue,
          {
            defaultValuesId: defaultValuesId,
            customListId: customList.id,
            body: {
              parentId: parentId ?? null,
              label: data.label,
            },
          },
          isCreating,
        );
        handleClose();
      } catch (e) {
        console.error(e);
      }
    } else {
      try {
        await safeMutation(
          updateDefaultValue,
          {
            defaultValuesId: defaultValuesId,
            customListId: customList.id,
            customListItemId: item?.id,
            body: {
              parentId: parentId ?? item?.parentId,
              enumValue: item?.enumValue,
              label: data.label,
            },
          },
          isUpdating,
        );
        handleClose();
      } catch (e) {
        console.error(e);
      }
    }
  };

  const [deleteDefaultValue, { isLoading: isDeleting }] = useApiDeleteCustomListItemMutation();

  const handleDelete = async () => {
    try {
      await safeMutation(
        deleteDefaultValue,
        {
          defaultValuesId: defaultValuesId,
          customListId: customList.id,
          customListItemId: item?.id ?? '',
        },
        isDeleting,
      );
    } catch (e) {
      console.error(e);
    }
  };

  const defaultFormValues = { label: item ? getCustomListItemLabel(customList.target, item) : '' };

  const hasDefaultChildren = useCallback((item: CustomListItemReadModel): boolean => {
    if (item.enumValue) {
      return true;
    } else if (item.children.length > 0) {
      return item.children.some((child) => hasDefaultChildren(child));
    } else return false;
  }, []);

  return (
    <SlideOver isOpen={isOpen} onClose={handleClose}>
      <Form<CustomListItemLabelFormValidationValues>
        className="w-full flex flex-col justify-between h-full"
        onSubmit={handleSubmit}
        validationSchema={CustomListItemFormValidationSchema}
        defaultValues={defaultFormValues}
      >
        <SlideOver.Header
          onClose={handleClose}
          title={getCustomListLabel(customList.target)}
          backgroundClassName="bg-sky-900"
        />

        <SlideOver.Content className="p-8">
          {(isDeleting || isUpdating || isCreating) && (
            <LoadingIndicator text={t('common.loading')} mode="overlay-window" />
          )}
          <FormField name="label">{(control) => <TextInput label={t('common.name')} {...control} />}</FormField>
        </SlideOver.Content>
        <SlideOver.Controls>
          <div
            className={classNames(
              'w-full flex',
              !isAddMode && !item.enumValue && !hasDefaultChildren(item) ? 'justify-between' : 'justify-end',
            )}
          >
            {!isAddMode && !item.enumValue && !hasDefaultChildren(item) && (
              <Button variant="warning" onClick={handleDelete}>
                {t('common.delete')}
              </Button>
            )}
            <div className="flex gap-2">
              <Button variant="secondary" onClick={handleClose}>
                {t('common.cancel')}
              </Button>
              <Button className="flex-none block" formSubmit={true} variant="primary">
                {t(isAddMode ? 'common.create' : 'common.save')}
              </Button>
            </div>
          </div>
        </SlideOver.Controls>
      </Form>
    </SlideOver>
  );
};
