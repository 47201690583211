import {
  TimelineValueReadModel,
  ElementTimelineReadModel,
  useApiPostCalculateAutomaticTimelineDistributionMutation,
  useApiGetEffectiveTimelineDistributionMutation,
  DistributionValueReadModel,
  DistributionType,
  DistributionReadModel,
  EarningsType,
  useApiPostCalculateRecurringPaymentDistributionMutation,
  PaymentFrequency,
  DistributionFrequency,
} from '@client/shared/api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { formatDateOnly, formatNullableDateOnly, isEmpty } from '@client/shared/utilities';
import { Button, LoadingIndicator } from '@client/shared/toolkit';
import { useLoadedProjectId, useLoadedVariant, useLoadedVariantId } from '@client/project/store';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { TimeLineMoneyDistributionHeader } from './TimeLineMoneyDistributionHeader';
import cn from 'classnames';
import { TimeLineMoneyDistributionFooter } from './TimeLineMoneyDistributionFooter';
import { TimeLineMoneyDistributionBody } from './TimeLineMoneyDistributionBody';
import { DistributionModal } from './DistributionModal';
import { DistributionModalAddButton } from './DistributionModalAddButton';
import { TimeInput } from './TimeLineElementInput';
import { updateTimeLine } from '../../utils';

export interface ExtendedDistributionValueReadModel extends DistributionValueReadModel {
  isActual?: boolean;
}

export type DistributionValueType = 'Forecast' | 'Budget';

const convertToDistributionValueReadModel = (
  data: TimelineValueReadModel[],
  distributionTotalPercentageValue: number
) => {
  const convertedData: ExtendedDistributionValueReadModel[] = [];
  data.forEach((adv) => {
    const converted: ExtendedDistributionValueReadModel = {
      date: adv.effectiveStart,
      percentage: (adv.value / distributionTotalPercentageValue) * 100,
      value: adv.value,
      description: '',
      rest: false,
      isActual: adv.isActual,
    };
    convertedData.push(converted);
  });
  return convertedData;
};

interface TimeLineMoneyDistributionModalProps {
  variantId?: string;
  totalValue?: number;
  timeline?: ElementTimelineReadModel | null;
  totalValueType?: DistributionValueType;
  // Effective distribution values are usually fetched,
  // but in some cases we can use them directly from distribution
  // because we don't need live data
  useExistingEffectiveDistributionValues?: boolean;
  costGroupId?: string;
  riskGroupId?: string;
  earningsType?: EarningsType;
  onClose: () => void;
  onSave: (timelineUpdated: ElementTimelineReadModel | null) => void;
  disabledDistributionTypes?: DistributionType[];
  disabledGrainOptions?: DistributionFrequency[];
  disabled?: boolean;
  onChange?: (timing: ElementTimelineReadModel) => void;
  onClear?: () => void;
  hideEnd?: boolean;
  saveButtonText?: string;
  selectedDate?: string;
  isLoading?: boolean;
  amount?: number | null;
  unitPrice?: number | null;
  index?: number | null;
  indexDate?: Date | null | undefined;
  paymentFrequency?: PaymentFrequency;
}

export const TimeLineMoneyDistributionModal = (props: TimeLineMoneyDistributionModalProps) => {
  const {
    variantId,
    costGroupId,
    onClose,
    riskGroupId,
    timeline,
    totalValue,
    totalValueType,
    useExistingEffectiveDistributionValues,
    onSave,
    disabled,
    disabledDistributionTypes = [],
    disabledGrainOptions = [],
    onChange,
    onClear,
    hideEnd = false,
    saveButtonText,
    selectedDate,
    earningsType,
    index,
    indexDate,
    paymentFrequency,
    isLoading = false
  } = props;
  const { t } = useTranslation();
  // The distribution values to be rendered (rows)
  const [distributionValues, setDistributionValues] = useState<ExtendedDistributionValueReadModel[]>([]);
  const [nonRestValues, setNonRestValues] = useState<ExtendedDistributionValueReadModel[]>(
    timeline?.distribution?.manualDistributionValues.filter((v) => !v.rest) ?? []
  );
  const [oldTimeline] = useState(timeline);

  const loadedCalculationModelId = useLoadedVariantId();
  const loadedProjectId = useLoadedProjectId();

  const { data, isLoading: isLoadingCalculationModel } = useLoadedVariant();

  const distributedAmount = useMemo(() => {
    return Math.round(distributionValues.reduce((x, y) => (x = x + y.value), 0) * 100) / 100;
  }, [distributionValues]);

  const [getDistribution, { isLoading: isLoadingDistribution }] =
    useApiPostCalculateAutomaticTimelineDistributionMutation();
  const [getEffectiveDistribution, { isLoading: isLoadingEffectiveDistribution }] =
    useApiGetEffectiveTimelineDistributionMutation();
  const [getRevenueDistributions, {isLoading: isLoadingRevenueDistribution }] =
    useApiPostCalculateRecurringPaymentDistributionMutation();

  const distributionTotalPercentageValue = useMemo(() => {
    return totalValue !== undefined ? totalValue : distributedAmount;
  }, [totalValue, distributedAmount]);

  const timeLineStart = useMemo(() => {
    return timeline
      ? ({
          variantDeliveryPhaseId: timeline.startCalculationModelDeliveryPhaseId,
          variantMileStoneId: timeline.startCalculationModelMileStoneId,
          offset: timeline.startOffset,
          offsetPosition: timeline.startOffsetPosition,
          offsetUnit: timeline.startOffsetUnit,
          elementTimelineId: timeline.startElementTimelineId,
          fixedDate: timeline.startFixedStartDate,
          type: timeline.startType,
          effectiveDate: timeline.effectiveStartDate,
        } as TimeInput)
      : undefined;
  }, [timeline]);

  const timeLineEnd = useMemo(() => {
    return timeline
      ? ({
          variantDeliveryPhaseId: timeline.endCalculationModelDeliveryPhaseId,
          variantMileStoneId: timeline.endCalculationModelMileStoneId,
          offset: timeline.endOffset,
          offsetPosition: timeline.endOffsetPosition,
          offsetUnit: timeline.endOffsetUnit,
          elementTimelineId: timeline.endElementTimelineId,
          fixedDate: timeline.endDate ?? timeline.effectiveEndDate, // effectiveEndDate if not set
          type: timeline.endType,
          effectiveDate: timeline.effectiveEndDate,
          // only tl end can have duration
          duration: timeline.duration,
          durationUnit: timeline.durationUnit,
          durationStartDate: timeline.effectiveStartDate // if duration is set, use the start date
        } as TimeInput)
      : undefined;
  }, [timeline]);

  const timelineDistribution = useMemo(() => {
    return timeline ? timeline.distribution : null;
  }, [timeline]);

  const disableSave = useMemo(() => {
    return earningsType && earningsType !== 'SaleRevenue' ? false : (distributedAmount > (totalValue ?? 0) && timeline?.distribution?.type !== 'Effective');
  }, [earningsType, distributedAmount, totalValue, timeline?.distribution?.type]);

  useEffect(() => {
    const fetchDistributionValues = async () => {
      if (!timeline) return;
      try {
        const startDate = timeline.effectiveStartDate ?? '';
        const endDate = timeline.effectiveEndDate ?? '';

        const distributionValues = await getDistribution({
          projectId: loadedProjectId ?? 'unset',
          calculationModelId: loadedCalculationModelId ?? 'unset',
          body: {
            costElementId: isEmpty(costGroupId) ? null : costGroupId,
            distributionType: timeline.distribution?.type ?? 'None',
            start: startDate,
            end: endDate,
            value: totalValue ?? 0,
            distributionFrequency: timeline.distribution?.frequency ?? 'Month',
          },
        }).unwrap();
        const convertedValues = convertToDistributionValueReadModel(
          distributionValues,
          distributionTotalPercentageValue
        );
        setDistributionValues(convertedValues ?? []);
      } catch (error) {
        setDistributionValues([]);
        console.log(error);
      }
    };

    const fetchEffective = async () => {
      try {
        const values = await getEffectiveDistribution({
          projectId: loadedProjectId ?? 'unset',
          calculationModelId: loadedCalculationModelId ?? 'unset',
          costElementId: costGroupId,
          riskElementId: riskGroupId,
        }).unwrap();
        const convertedValues = convertToDistributionValueReadModel(values, distributionTotalPercentageValue);
        setDistributionValues(convertedValues ?? []);
      } catch (error) {
        setDistributionValues([]);
        console.log(error);
      }
    };

    const fetchRecurringPayment = async () => {
      if (!timeline) return;
      try {
        const startDate = timeline.effectiveStartDate ?? '';
        const endDate = timeline.effectiveEndDate ?? '';

        const revenueDistributions = await getRevenueDistributions({
          projectId: loadedProjectId ?? 'unset',
          calculationModelId: loadedCalculationModelId ?? 'unset',
          body: {
            start: startDate,
            end: endDate,
            totalValue: totalValue ?? 0,
            rentIndex: index,
            rentIndexDate: formatNullableDateOnly(indexDate),
            distributionFrequency: timeline.distribution?.frequency ?? 'Month',
            paymentFrequency: paymentFrequency ?? 'Monthly'
          },
        }).unwrap();
        const convertedValues = convertToDistributionValueReadModel(revenueDistributions, distributionTotalPercentageValue);
        setDistributionValues(convertedValues ?? []);
      } catch (error) {
        setDistributionValues([]);
        console.log(error);
      }
    };


    // reset
    if (
      isEmpty(timeline?.effectiveStartDate) ||
      isEmpty(timeline?.effectiveEndDate) ||
      timeline?.distribution?.type === 'None'
    ) {
      setNonRestValues([]);
      setDistributionValues([]);
      return;
    }

    if (timeline?.distribution?.type === 'Manual') {
      let values = [...nonRestValues];
      if (!nonRestValues || !nonRestValues.length) {
        values = timeline?.distribution?.manualDistributionValues.filter((v) => !v.rest) ?? [];
        setNonRestValues(values);
      }
      setDistributionValues(values);
      return;
    }

    if (timeline && timeline.distribution?.type === 'Effective') {
      if (useExistingEffectiveDistributionValues) {
        const convertedValues = convertToDistributionValueReadModel(
          timeline.distribution.distributionValues,
          distributionTotalPercentageValue
        );
        setDistributionValues(convertedValues ?? []);
      } else {
        fetchEffective();
      }
      return;
    }

    if (timeline && timeline?.distribution?.type) {

      if(earningsType && earningsType !== 'SaleRevenue'){
        fetchRecurringPayment();
        return;
      }

      fetchDistributionValues();
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loadedProjectId,
    loadedCalculationModelId,
    totalValue,
    timeline,
    getDistribution,
    getEffectiveDistribution,
    costGroupId,
    riskGroupId,
    useExistingEffectiveDistributionValues,
  ]);

  const handleOnChange = useCallback(
    (data: { startElement?: TimeInput; endElement?: TimeInput; elementDistribution?: DistributionReadModel }) => {
      // if timeline got updated, save it
      const timeLineUpdated = updateTimeLine(
        data,
        timeLineStart,
        timeLineEnd,
        hideEnd,
        timeline,
        timelineDistribution
      );
      if (onChange) {
        if (timeLineUpdated) {
          onChange(timeLineUpdated);
        }
      }
      return timeLineUpdated;
    },
    [timeLineStart, timeLineEnd, onChange, hideEnd, timeline, timelineDistribution]
  );

  // If something got changed, we switch to manual distribution, if not already set
  const switchToManualDistribution = useCallback(() => {
    //... switch to manual distribution
    if (timeline?.distribution?.type !== 'Manual') {
      const distribution: DistributionReadModel = {
        id: timeline?.distribution?.id ?? '',
        type: 'Manual',
        distributionValues: [],
        manualDistributionValues: [],
        frequency: 'None',
      };
      handleOnChange({ elementDistribution: distribution });
    }
  }, [timeline?.distribution?.type, timeline?.distribution?.id, handleOnChange]);

  // Save button was clicked
  const save = useCallback(() => {
    if (disableSave) {
      toast.error(t('projectCalculate.manualDistributionOverflowError'));
      return;
    }
    const manualDistributionValues = [...distributionValues];
    let timelineUpdated: ElementTimelineReadModel | null = null;

    if (timeline?.distribution?.type === 'Manual') {
      const distribution = { ...timeline.distribution, manualDistributionValues } as DistributionReadModel;
      timelineUpdated = handleOnChange({ elementDistribution: distribution });
    } else {
      timelineUpdated = handleOnChange({ elementDistribution: timeline?.distribution as DistributionReadModel });
    }
    // inform parents that saved was clicked
    onSave(timelineUpdated);
  }, [disableSave, t, distributionValues, handleOnChange, onSave, timeline]);

  // plus button was clicked, we add a new row with empty values
  const addNewElement = useCallback(() => {
    // there are already distribution values, so keep them and add just a row and...
    const values = [...distributionValues];
    // set values

    const statusMonth = data?.calculationModel?.modelMetadata?.cashFlowSettings?.statusMonth ? new Date(data?.calculationModel?.modelMetadata?.cashFlowSettings?.statusMonth) : new Date();

    const newValues = [
      ...values,
      {
        id: undefined,
        description: '',
        value: 0,
        date: (data?.calculationModel?.modelMetadata?.cashFlowSettings &&
          !!data?.calculationModel?.modelMetadata?.cashFlowSettings?.overridePlan)
            ? new Date(statusMonth.setDate(statusMonth.getDate() - 1))
             : timeline?.effectiveStartDate ?? formatDateOnly(new Date()),
      } as ExtendedDistributionValueReadModel,
    ];
    setNonRestValues(newValues);
    setDistributionValues(newValues);

    //... switch to manual distribution
    switchToManualDistribution();
  }, [distributionValues, data, timeline?.effectiveStartDate, switchToManualDistribution]);

  // when something got updated, we switch to manual distribution
  const updateDistributionValues = useCallback(
    (values: ExtendedDistributionValueReadModel[]) => {
      setDistributionValues(values);
      setNonRestValues(values);

      if (values.length) {
        //... switch to manual distribution
        switchToManualDistribution();
      } else {
        // otherwise to none
        if (timeline?.distribution?.type !== 'None') {
          const distribution: DistributionReadModel = {
            id: timeline?.distribution?.id ?? '',
            type: 'None',
            distributionValues: [],
            manualDistributionValues: [],
            frequency: timeline?.distribution?.frequency ?? 'Month',
          };
          handleOnChange({ elementDistribution: distribution });
        }
      }
    },
    [switchToManualDistribution, timeline, handleOnChange]
  );

  const handleOnClose = useCallback(() => {
    if (oldTimeline && onChange) {
      onChange(oldTimeline);
    }
    if (onClose) {
      onClose();
    }
  }, [oldTimeline, onChange, onClose]);

  return (
    <DistributionModal
      title={t('projectCalculate.MoneyDistributionTitle')}
      description={disabled ? '' : t('projectCalculate.MoneyDistributionModalDesc')}
      header={
        <TimeLineMoneyDistributionHeader
          className={cn('px-12 w-full', {
            'border-b-2 ': distributionValues.length > 0,
            'shadow-lg z-10': distributionValues.length > 2,
          })}
          variantId={variantId}
          timing={timeline}
          disabled={disabled}
          disabledDistributionTypes={disabledDistributionTypes}
          disabledGrainOptions={disabledGrainOptions}
          onClear={onClear}
          timeLineEnd={timeLineEnd}
          timeLineStart={timeLineStart}
          handleOnChange={handleOnChange}
        />
      }
      controls={
        <div className="w-full flex justify-between">
          {timeline && !disabled && (
            <Button variant="text" className="text-sm flex-none" disabled={disabled} onClick={onClear}>
              {t('projectCalculate.ClearTimelineButton')}
            </Button>
          )}
          <div className="flex space-x-2 w-full justify-end">
            <Button onClick={handleOnClose} variant="secondary">
              {disabled ? t('common.close') : t('common.cancel')}
            </Button>
            {!disabled && (
              <Button onClick={save} variant="primary">
                {saveButtonText ? saveButtonText : t('common.apply')}
              </Button>
            )}
          </div>
        </div>
      }
      footer={
        <>
          {timeline && distributionValues.length > 0 && (
            <TimeLineMoneyDistributionFooter
              totalValue={totalValue}
              totalValueType={totalValueType}
              isSaveDisabled={disableSave}
              distributedAmount={distributedAmount}
              onlyShowTotal={earningsType && earningsType !== 'SaleRevenue'}
            />
          )}
        </>
      }
    >
      {(isLoadingDistribution || isLoadingEffectiveDistribution || isLoadingCalculationModel
        || isLoading || isLoadingRevenueDistribution
      ) ? (
        <LoadingIndicator className="mt-4" text={t('projectCalculate.MoneyDistribution.LoadingIndicator')} />
      ) : (
        <>
          {distributionValues.length > 0 && timeline && timeline?.distribution?.type !== 'None' && (
            <TimeLineMoneyDistributionBody
              totalValue={totalValue}
              distributionValues={distributionValues}
              timeline={timeline}
              setDistributionValues={updateDistributionValues}
              selectedDate={selectedDate}
              statusMonth={data?.calculationModel?.modelMetadata?.cashFlowSettings?.statusMonth}
              cashFlowOverride={data?.calculationModel?.modelMetadata?.cashFlowSettings?.overridePlan ?? false}
              disabled={disabled}
            />
          )}

          {/* Add Button */}
          {!disabled && !(earningsType && earningsType !== 'SaleRevenue') && <DistributionModalAddButton onClick={addNewElement} />}
        </>
      )}
    </DistributionModal>
  );
};
