import avatar_moritz from './avatar_moritz.jpg';
import bg_login_screen from './bg-login-screen.jpg';
import bg_projects from './bg-projects.jpg';
import datev_logo from './datev-logo.png';
import parking_placeholder from './parking-placeholder.jpg';
import preview_costs from './preview_costs.png';
import preview_taxonomy from './preview_taxonomy.png';
import preview_timeline from './preview_timeline.png';
import preview_ui from './preview_ui.png';
import preview_variants from './preview_variants.png';
import docuware_logo from './docuware-logo.svg?url';
import doughnut_chart_bg from './doughnut-chart-bg.svg?url';
import fake_calculate_element_comment from './fake-calculate-element-comment.svg?url';
import fake_calculate_element_history_de from './fake-calculate-element-history-de.svg?url';
import fake_calculate_element_history_en from './fake-calculate-element-history-en.svg?url';
import icon_bar_chart_watermark from './icon-bar-chart-watermark.svg?url';
import icon_clipboard_watermark from './icon-clipboard-watermark.svg?url';
import icon_formula from './icon-formula.svg?url';
import icon_map_marker from './icon-map-marker.svg?url';
import icon_money_distribution_watermark from './icon-money-distribution-watermark.svg?url';
import login_ornament from './login-ornament.svg?url';
import logo_icon from './logo-icon.svg?url';
import logo_menu from './logo-menu.svg?url';
import logo_text_with_icon from './logo-text-with-icon.svg?url';
import logo_text from './logo-text.svg?url';
import logo_yardi from './logo-yardi.svg?url';
import rent_background from './rent-background.svg?url';

export const AVATAR_MORITZ_IMAGE = avatar_moritz;
export const BG_LOGIN_SCREEN_IMAGE = bg_login_screen;
export const BG_PROJECTS_IMAGE = bg_projects;
export const DATEV_LOGO_IMAGE = datev_logo;
export const DOCUWARE_LOGO_IMAGE = docuware_logo;
export const DOUGHNUT_CHART_BG_IMAGE = doughnut_chart_bg;
export const FAKE_CALCULATE_ELEMENT_COMMENT_IMAGE = fake_calculate_element_comment;
export const FAKE_CALCULATE_ELEMENT_HISTORY_DE_IMAGE = fake_calculate_element_history_de;
export const FAKE_CALCULATE_ELEMENT_HISTORY_EN_IMAGE = fake_calculate_element_history_en;
export const ICON_BAR_CHART_WATERMARK_IMAGE = icon_bar_chart_watermark;
export const ICON_CLIPBOARD_WATERMARK_IMAGE = icon_clipboard_watermark;
export const ICON_FORMULA_IMAGE = icon_formula;
export const ICON_MAP_MARKER_IMAGE = icon_map_marker;
export const ICON_MONEY_DISTRIBUTION_WATERMARK_IMAGE = icon_money_distribution_watermark;
export const LOGIN_ORNAMENT_IMAGE = login_ornament;
export const LOGO_ICON_IMAGE = logo_icon;
export const LOGO_MENU_IMAGE = logo_menu;
export const LOGO_TEXT_IMAGE = logo_text;
export const LOGO_TEXT_WITH_ICON_IMAGE = logo_text_with_icon;
export const LOGO_YARDI_IMAGE = logo_yardi;
export const PARKING_PLACEHOLDER_IMAGE = parking_placeholder;
export const PREVIEW_COSTS_IMAGE = preview_costs;
export const PREVIEW_TAXONOMY_IMAGE = preview_taxonomy;
export const PREVIEW_TIMELINE_IMAGE = preview_timeline;
export const PREVIEW_UI_IMAGE = preview_ui;
export const PREVIEW_VARIANTS_IMAGE = preview_variants;
export const RENT_BACKGROUND_IMAGE = rent_background;
export * from './project-placeholder';
