
/**
 * Returns an array of dates starting with 1s between two dates.
 * @param startDate - The start date.
 * @param endDate - The end date.
 * @returns An array of dates between the two dates.
 */

export const getMonthsBetweenDates = (startDate?: string, endDate?: string) => {
  if (!startDate || !endDate) {
      return [];
  }

  const start = new Date(startDate);
  const end = new Date(endDate);
  const monthArray = [];

  const current = new Date(start);
  current.setDate(1);

  while (current <= end) {
    monthArray.push(new Date(current));
    current.setMonth(current.getMonth() + 1);
  }

  return monthArray;
}