import { useApiGetCalculationModelCostsQuery } from '@client/shared/api';
import { useLoadedVariantId } from './useLoadedVariantId';
import { useLoadedProjectId } from './useLoadedProjectId';
import { useHasLicenseFeature, useValidateProjectPermission } from '@client/shared/permissions';

export const  useLoadedVariantCosts = () => {
  const loadedVariantId = useLoadedVariantId();
  const loadedProjectId = useLoadedProjectId();
  const hasCalculateLicenseFeature = useHasLicenseFeature('CALCULATE');
  const hasCostsReadPermission = useValidateProjectPermission(['COSTS_READ'], loadedProjectId ?? '');

  return useApiGetCalculationModelCostsQuery(
    {
      projectId: loadedProjectId ?? 'unset',
      calculationModelId: loadedVariantId ?? 'unset'
    },
    {
      skip: loadedVariantId == null || !hasCalculateLicenseFeature || !hasCostsReadPermission,
    }
  );
};
