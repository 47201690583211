import { ProjectTemplateReadModel } from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { Badge, BadgeGroup, ContextMenu, PencilIcon } from '@client/shared/toolkit';
import { RectangleGroupIcon } from '@heroicons/react/24/outline';

interface ProjectTemplateRowProps {
  projectTemplate: ProjectTemplateReadModel;
  handleEdit: (projectTemplate: ProjectTemplateReadModel) => void;
  openTemplate: () => void;
}

export const ProjectTemplateRow = ({ projectTemplate, handleEdit, openTemplate }: ProjectTemplateRowProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row">
      <div
        className="w-full flex flex-row py-2 text-gray-500 hover:text-black hover:bg-gray-100 cursor-pointer"
        onClick={openTemplate}
      >
        <div className="flex flex-row w-4/12">
          <div className="flex mx-3">
            <RectangleGroupIcon className="w-6 " />
          </div>

          <div className="">
            <div className="font-bold text-base text-gray-900">{projectTemplate.name}</div>
          </div>
        </div>

        <div className="text-black items-center w-8/12">
          <BadgeGroup>
            <Badge text={projectTemplate.costCatalogName} variant="danger" />
            <Badge text={projectTemplate.riskCatalogName} variant="info" />
            <Badge text={projectTemplate.earningsCatalogName} variant="success" />
            <Badge text={projectTemplate.financeCatalogName} variant="primary" />
            <Badge text={projectTemplate.deliveryPhasesCatalog} variant="lightInfo" />
          </BadgeGroup>
        </div>
      </div>
      <div className="py-2">
        <div className="">
          <ContextMenu
            items={[
              {
                label: t('common.edit'),
                subtitle: t('templates.editTemplate'),
                icon: <PencilIcon />,
                onClick: () => {
                  handleEdit(projectTemplate);
                },
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};
