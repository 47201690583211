import { useDrag, useDrop, DragSourceMonitor } from 'react-dnd';
import type { Identifier, XYCoord } from 'dnd-core';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useEffect } from 'react';

export interface DragItem {
  index: number;
  id: string;
  type: string;
}

interface DragContainerProps {
  forwardRef: React.RefObject<HTMLDivElement>;
  index: number | string;
  selectedItemType: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  draggedItem: any | null;
  draggedType: string;
  allowedTypesForElement: string[];
  allAllowedTypes: string[];
  moveItem: (dragIndex: number | string, hoverIndex: number | string | null, dropped: boolean, validMove: boolean) => void;
  children: (handlerId: Identifier | null, isDragging: boolean) => React.ReactNode;
  id: string;
  droppedOutside?: () => void;
}

export function DragContainer({
  forwardRef,
  selectedItemType,
  draggedItem,
  draggedType,
  allowedTypesForElement,
  allAllowedTypes,
  index,
  moveItem,
  children,
  id,
  droppedOutside,
}: DragContainerProps) {
  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: allAllowedTypes,

    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },

    hover(element: DragItem, monitor) {
      if (!forwardRef.current) return;
      const dragIndex = element.index;
      const hoverIndex = index;
      const hoverBoundingRect = forwardRef.current?.getBoundingClientRect();
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex !== hoverIndex && hoverClientY !== 0) moveItem(dragIndex, hoverIndex, false, false);
    },

    drop: (element: DragItem) => {
      const dragIndex = element.index;
      const hoverIndex = index;

      draggedItem && allowedTypesForElement.includes(draggedType)
        ? moveItem(dragIndex, hoverIndex, true, true)
        : moveItem(dragIndex, hoverIndex, true, false);
    },
  });

  const [{ isDragging }, drag, dragPreview] = useDrag({
    type: selectedItemType,

    item: () => {
      return { id, index };
    },

    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),

    end: (item, monitor) => {
      const didDrop = monitor.didDrop();
      if (!didDrop && droppedOutside) droppedOutside();
    },
  });

  useEffect(() => {
    dragPreview(getEmptyImage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  drag(drop(forwardRef));

  return <div ref={forwardRef}>{children(handlerId, isDragging)}</div>;
}
