import './styles.scss';
import 'reactflow/dist/style.css';
import { setDelay } from '@client/shared/api';
import { StrictMode } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from '.';
import { createRoot } from 'react-dom/client';
import { settings } from '@client/shared/store';
import { AppModule } from '@client/main/shell';
import { initSentry, initSettings } from './initializers';
import { initI18n } from '@client/shared/utilities';
import { initUserpilot } from './initializers';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// Ordering is important, loadSettings() should stay first
initSettings();
initSentry();
initI18n();
initUserpilot();
// initMixPanel();
// initHotjar();

setDelay(settings.queryDelay);

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <DndProvider backend={HTML5Backend}>
          <AppModule />
        </DndProvider>
      </Provider>
    </BrowserRouter>
  </StrictMode>
);
