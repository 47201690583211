import { SlideOverTitle } from '@client/shared/toolkit';
import { FeatureElement, FeatureElementEditProps, FeatureElementRef } from '../FeatureElement';
import { useApiPostUpdateVatElementMutation, VatElementReadModel } from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { forwardRef, Ref, useEffect, useImperativeHandle } from 'react';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { VatReturnEdit } from '../Distribution';

interface VatElementEditSectionProps extends FeatureElementEditProps {
  element: VatElementReadModel;
  handleChangeElement: (element: VatElementReadModel) => void;
}

export const VatElementEditSection = forwardRef((props: VatElementEditSectionProps, ref: Ref<FeatureElementRef>) => {
  const { setIsUpdating, elementId, handleChangeElement, element, isReadOnly, setElement } = props;
  const { t } = useTranslation();
  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  useImperativeHandle(ref, () => ({ updateData }));
  const updateData = (checkElement: FeatureElement) => {
    return handleUpdateVatElement(checkElement as VatElementReadModel);
  };

  const [updateVatElement, { isLoading: isUpdatingVat }] = useApiPostUpdateVatElementMutation();

  useEffect(() => {
    setIsUpdating(isUpdatingVat);
  }, [isUpdatingVat, setIsUpdating]);

  const handleUpdateVatElement = async (vatElement: VatElementReadModel | null) => {
    if (elementId && loadedProjectId && loadedVariantId) {
      try {
        return await updateVatElement({
          id: elementId,
          projectId: loadedProjectId,
          calculationModelId: loadedVariantId,
          body: {
            vatReturnPercentage: vatElement?.vatReturnPercentage ?? 0,
            delayMonths: vatElement?.delayMonths ?? 0,
            costGroupIds: vatElement?.costGroupIds ?? null,
            userDefinedFieldId: vatElement?.userDefinedFieldId === 'none' ? null : vatElement?.userDefinedFieldId,
            userDefinedFieldLabelIds:
              vatElement?.userDefinedFieldId === 'none' ? null : vatElement?.userDefinedFieldLabelIds ?? null,
          },
        }).unwrap();
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <>
      <SlideOverTitle title={t('projectCalculate.vatReturnPlan')} className="px-8" />
      <div className="mb-4 mx-8 ">
        <VatReturnEdit
          vatCostElement={element}
          updateVatCostElement={(updatedElement) => {
            setElement(updatedElement);
            handleChangeElement(updatedElement);
          }}
          disabled={isReadOnly}
        />
      </div>
    </>
  );
});
