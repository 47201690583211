import {
  CostElementMultiSelect,
  findCostElement,
  useFlattenCostElements
} from '@client/project/shared';
import { useApiGetCalculationModelCostsQuery } from '@client/shared/api';
import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { ProjectCalculationModelElementsSelect } from './ProjectCalculationModelElementsSelect';

export interface ProjectCalculationModelCostsSelectProps {
  setIsFetching: (isFetching: boolean) => void;
  selectedElementIds: string[];
  setSelectedElementIds: (value: string[]) => void;
  className?: string;
  label?: string;
  showControls?: boolean;
}

export const ProjectCalculationModelCostsSelect = (props: ProjectCalculationModelCostsSelectProps) => {
  const { setIsFetching, selectedElementIds, setSelectedElementIds, label, className = 'w-full', showControls } = props;

  const { t } = useTranslation();
  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const { data: costData, isFetching } = useApiGetCalculationModelCostsQuery(
    {
      projectId: loadedProjectId ?? 'unset',
      calculationModelId: loadedVariantId ?? '',
    },
    { skip: loadedVariantId == null },
  );

  const flattenedCostElements = useFlattenCostElements(costData);
  const popoverButtonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setIsFetching(isFetching);
  }, [isFetching, setIsFetching]);

  const selectedLabels = useMemo(() => {
    return selectedElementIds.length > 0
      ? selectedElementIds
          .map((costElementId) => {
            const costElement = findCostElement(costElementId, flattenedCostElements);
            return costElement?.description === ''
              ? t('projectCalculate.unnamedElement')
              : costElement?.description ?? '';
          })
          .join(', ')
      : t('projectCalculate.NoSelection');
  }, [selectedElementIds, flattenedCostElements, t]);

  return (
    <ProjectCalculationModelElementsSelect
      className={className}
      label={label ?? t('projectCalculate.financingElementFinancedCostGroups')}
      selectedLabels={selectedLabels}
      popoverButtonRef={popoverButtonRef}
    >
      <CostElementMultiSelect
        costData={costData?.payload?.catalogElements ?? []}
        selectedElements={selectedElementIds}
        updateSelectedElements={setSelectedElementIds}
        onClose={() => popoverButtonRef.current?.click()}
        showControls={showControls}
      />
    </ProjectCalculationModelElementsSelect>
  );
};
