import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import { useDrop } from 'react-dnd';
import { DragItem } from '..';

export interface DropOnlyFieldProps extends PropsWithChildren {
  moveItem: (
    dragIndex: number | string,
    hoverIndex: number | string | null,
    dropped: boolean,
    validMove: boolean,
  ) => void;
  className?: string;
  allowedTypes: string[];
  allow?: boolean;
  isDragging: boolean;
}

export const DropOnlyField = ({
  moveItem,
  allowedTypes,
  className,
  children,
  allow = true,
  isDragging,
}: DropOnlyFieldProps) => {
  const [{ isOver, canDrop }, dropRef] = useDrop(() => ({
    accept: allowedTypes,
    hover(element: DragItem) {
      moveItem(element.id, null, false, false);
    },
    drop: (element: DragItem) => {
      moveItem(element.id, null, true, true);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const isActive = isOver && canDrop;

  return (
    <div
      ref={dropRef}
      className={classNames(className ? className : 'h-5', {
        'border-2 border-dashed': (isDragging || isActive),
        'bg-slate-50': isActive && allow,
        'bg-amber-50': isActive && !allow,
      })}
    >
      {children}
    </div>
  );
};
