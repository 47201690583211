import { CustomListItemReadModel } from '@client/shared/api';
import { getAssetClassLabel } from './getAssetClassLabel';
import { ComboSelectOption } from '@client/shared/toolkit';

export const getAssetClasses = (availableAssetClasses: CustomListItemReadModel[]): ComboSelectOption[] => {
  return availableAssetClasses
    .map((assetClass) => toComboSelectOptionRecursively(assetClass))
    .sort((a, b) => {
      return a.label.localeCompare(b.label);
    });
};

export const getFlattenedAssetClasses = (
  availableAssetClasses: CustomListItemReadModel[],
): CustomListItemReadModel[] => {
  const flattenAssetClasses = (assetClasses: CustomListItemReadModel[]): CustomListItemReadModel[] => {
    const result: CustomListItemReadModel[] = [];
    assetClasses.forEach((assetClass) => {
      result.push(assetClass);
      result.push(...flattenAssetClasses(assetClass.children));
    });
    return result;
  };
  return flattenAssetClasses(availableAssetClasses);
};

const toComboSelectOptionRecursively = (assetClass: CustomListItemReadModel): ComboSelectOption => {
  return {
    label: getAssetClassLabel(assetClass),
    value: assetClass.id,
    disabled: false,
    options: assetClass.children
      .map((child) => toComboSelectOptionRecursively(child))
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      }),
  };
};
