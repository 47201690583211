import React, { Ref, forwardRef, useImperativeHandle } from 'react';
import { CatalogEditingProps, SaveCatalogChanges } from './CatalogEditingModal';
import { FinancingCatalogElementReadModel, useApiGetFinancingCatalogQuery } from '@client/shared/api';
import { CatalogEditingRows } from './CatalogEditingRows';
import { useTranslation } from 'react-i18next';
import { BankBuildingIcon, LoadingIndicator } from '@client/shared/toolkit';

export const FinanceCatalogEditing = forwardRef(({ catalog, selectedMappedCatalog, setSelectedMappedCatalog, showCreateMapping, setShowCreateMapping }: CatalogEditingProps, ref: Ref<SaveCatalogChanges>) => {
  const { data, isFetching } = useApiGetFinancingCatalogQuery({ id: catalog.id });
  const elements: FinancingCatalogElementReadModel[] = data?.financingCatalog.elements ?? [];
  const rootNodes = elements.filter((x) => !x.parentId);
  const mappings = data?.financingCatalog.mappings ?? [];
  const { t } = useTranslation()

  useImperativeHandle(ref, () => ({ saveChanges }));

  const saveChanges = (): boolean => {
    console.log('saving');
    return true;
  };

  return (
    <>
      {isFetching && (<LoadingIndicator text={t('app.settingsLoadingCatalogElements')} mode="overlay" />)}
      <CatalogEditingRows
        title={t('projectCalculate.calculateFinancingTitle')}
        icon={<BankBuildingIcon />}
        bgColor="bg-financing"
        catalog={catalog}
        mappings={mappings}
        elements={rootNodes}
        type="Finance"
        selectedMappedCatalog={selectedMappedCatalog}
        setSelectedMappedCatalog={setSelectedMappedCatalog}
        showCreateMapping={showCreateMapping}
        setShowCreateMapping={setShowCreateMapping}
      />
    </>
  )
});
