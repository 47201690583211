import {
  InvoiceDocumentReadModel,
  useApiPostCreateInvoiceDocumentsMutation,
  useApiPostCreateInvoiceEmbedLinkDocumentsMutation,
} from '@client/shared/api';
import {
  CreateEmbeddedLinkFormValidationValues,
  DecoratedCard,
  ListTitle,
  LoadingIndicator,
  UploadFileTabs,
} from '@client/shared/toolkit';
import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoiceDocumentRow } from './InvoiceDocumentRow';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { safeMutation } from '@client/shared/utilities';

interface InvoiceDocumentsProps {
  invoiceId: string;
  documents: InvoiceDocumentReadModel[];
  canWrite: boolean;
  canDelete: boolean;
}

export const InvoiceDocuments = ({ invoiceId, documents, canWrite, canDelete }: InvoiceDocumentsProps) => {
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const loadedProjectId = useLoadedProjectId();
  const loadedCalculationModelId = useLoadedVariantId();

  const [postInvoiceDocuments, { isLoading: isLoadingInvoiceDocuments }] = useApiPostCreateInvoiceDocumentsMutation();

  const handleSaveDocuments = async (fileList: FileList | null) => {
    if (fileList) {
      const formData = new FormData();
      Array.from(fileList).forEach(file => formData.append('files', file));

      try {
        await safeMutation(
          postInvoiceDocuments,
          {
            projectId: loadedProjectId ?? 'unset',
            calculationModelId: loadedCalculationModelId ?? 'unset',
            invoiceId: invoiceId,
            body: formData as unknown as { files: Blob[] },
          },
          isLoadingInvoiceDocuments,
        );
      } catch (e) {
        console.log(e, 'error');
      }
    }
  };

  const [postInvoiceEmbedLinkDocuments, { isLoading: isLoadingInvoiceEmbedLinkDocuments }] =
    useApiPostCreateInvoiceEmbedLinkDocumentsMutation();

  const handleAddEmbedLinkDocument = async (data: CreateEmbeddedLinkFormValidationValues) => {
    try {
      await safeMutation(
        postInvoiceEmbedLinkDocuments,
        {
          projectId: loadedProjectId ?? 'unset',
          calculationModelId: loadedCalculationModelId ?? 'unset',
          invoiceId: invoiceId,
          embedFileName: data.fileName,
          embedUrlLink: data.fileLink,
        },
        isLoadingInvoiceEmbedLinkDocuments,
      );
    } catch (e) {
      console.log(e, 'error');
    }
  };

  const docList = useMemo(() => {
    return documents.filter((document) => !document.isEmbedLinkDocument);
  }, [documents]);

  const embedDocList = useMemo(() => {
    return documents.filter((document) => document.isEmbedLinkDocument);
  }, [documents]);

  return (
    <>
      {(isLoadingInvoiceDocuments || isLoadingInvoiceEmbedLinkDocuments) && (
        <LoadingIndicator text={t('common.uploading') ?? ''} mode="overlay" />
      )}
      <DecoratedCard shadowVariant="normal">
        <DecoratedCard.Content>
          <ListTitle title={t('projectCalculate.addElementDocument')} />
          <div className="bg-white p-3 rounded-sm">
            <UploadFileTabs
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
              acceptedFileTypes={[]}
              onAddEmbedLinkDocumentClick={handleAddEmbedLinkDocument}
              onUploadDocumentClick={handleSaveDocuments} // Upload action
              disabled={!canWrite}
            />
          </div>
        </DecoratedCard.Content>
      </DecoratedCard>

      <DecoratedCard className="mt-4" shadowVariant="normal">
        <DecoratedCard.Content className="mb-2">
          <ListTitle title={t('projectCalculate.elementDocument')} />
          {docList.length === 0 ? (
            <div className="flex flex-col items-center justify-center p-5 bg-white text-gray-500">
              {t('projectCalculate.elementDocumentsEmpty')}
            </div>
          ) : (
            <>
              {docList.map((document) => (
                <InvoiceDocumentRow
                  invoiceId={invoiceId}
                  invoiceDocument={document}
                  key={document.id}
                  canDelete={canDelete}
                />
              ))}
            </>
          )}
        </DecoratedCard.Content>
      </DecoratedCard>

      <DecoratedCard className="mt-4" shadowVariant="normal">
        <DecoratedCard.Content className="mb-2">
          <ListTitle title={t('projectCalculate.elementEmbedLinkDocument')} />
          {embedDocList.length === 0 ? (
            <div className="flex flex-col items-center justify-center p-5 bg-white text-gray-500">
              {t('projectCalculate.elementEmbedLinkDocumentsEmpty')}
            </div>
          ) : (
            <>
              {embedDocList.map((document) => (
                <InvoiceDocumentRow
                  invoiceId={invoiceId}
                  invoiceDocument={document}
                  key={document.id}
                  canDelete={canDelete}
                />
              ))}
            </>
          )}
        </DecoratedCard.Content>
      </DecoratedCard>
    </>
  );
};
