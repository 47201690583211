import { WorkflowAssignableActivityConfigurationOptions, WorkflowAssignableActivityReadModel, WorkflowReadModel } from '@client/shared/api';
import { SettingsListHeader, SlideOver, SortDownIcon } from '@client/shared/toolkit';
import cn from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { includesSearchValue } from '@client/shared/utilities';
import { WorkflowRow } from './WorkflowRow';
import { WorkflowTasksSlideOver } from './WorkflowTasksSlideOver';

interface WorkflowsListProps {
  workflows?: WorkflowReadModel[];
  assignableWorkflows?: WorkflowAssignableActivityReadModel[];
  configurationOptions?: WorkflowAssignableActivityConfigurationOptions[];
  projectId?: string;
  searchText?: string;
  showSortHeader?: boolean;
}

export const WorkflowsList = (props: WorkflowsListProps) => {
  const { workflows, assignableWorkflows, projectId, searchText, showSortHeader = true, configurationOptions } = props;
  const { t } = useTranslation();

  const [isSortedByNameAsc, setIsSortedByNameAsc] = useState(true);
  const [selectedWorkflow, setSelectedWorkflow] = useState<string | null>(null);
  const [openEditModal, setOpenEditModal] = useState<'delete' | 'edit' | 'create' | null>(null);

  const sortByFieldName = useCallback(() => {
    setIsSortedByNameAsc((prev) => !prev);
  }, []);

  const sortedWorkflows = useMemo(() => {
    let sorted = [...(workflows ?? [])];
    if (sorted.length) {
      sorted.sort(function (a, b) {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (isSortedByNameAsc) {
          return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
        } else {
          return nameB < nameA ? -1 : nameB > nameA ? 1 : 0;
        }
      });
    }
    if (searchText) {
      sorted = sorted.filter((workflow) => {
        return includesSearchValue(workflow.name, searchText);
      });
    }
    return sorted;
  }, [isSortedByNameAsc, searchText, workflows]);

  const sortedAssignableWorkflows = useMemo(() => {
    let sorted = [...(assignableWorkflows ?? [])];
    if (sorted.length) {
      sorted.sort(function (a, b) {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (isSortedByNameAsc) {
          return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
        } else {
          return nameB < nameA ? -1 : nameB > nameA ? 1 : 0;
        }
      });
    }
    if (searchText) {
      sorted = sorted.filter((workflow) => {
        return includesSearchValue(workflow.name, searchText);
      });
    }
    return sorted;
  }, [isSortedByNameAsc, searchText, assignableWorkflows]);

  return (
    <>
      {showSortHeader && (
        <SettingsListHeader>
          <div className="flex gap-4 items-center w-6/12 flex-none">
            <div className="w-8 flex-none" />
            <div className="flex-1 flex justify-between items-center cursor-pointer" onClick={sortByFieldName}>
              {t('app.settingsSortByNameLabel')}
              <SortDownIcon
                className={cn('text-slate-400 opacity-60 transition-transform duration-100 w-7', {
                  '-rotate-180': !isSortedByNameAsc,
                })}
              />
            </div>
          </div>
          <div className="w-2/12 truncate">{t('app.settingsWorkflowType')}</div>
          <div className="w-2/12 truncate">{t('app.settingsWorkflowUsedInProjects')}</div>
          {assignableWorkflows && <div className="w-2/12 truncate">{t('app.settingsWorkflowAssigned')}</div>}
        </SettingsListHeader>
      )}
      <ul>
        {sortedWorkflows.map((workflow, i) => (
          <WorkflowRow
            key={`workflow-${workflow.definitionId}-${i}`}
            workflow={workflow}
            onEdit={() => undefined}
            onDelete={() => undefined}
            searchText={searchText}
            assignable={false}
          />
        ))}
        {sortedAssignableWorkflows.map((workflow, i) => (
          <WorkflowRow
            key={`workflow-${workflow.definitionId}-${i}`}
            workflow={workflow}
            onEdit={() => {
              setSelectedWorkflow(workflow.definitionId);
              setOpenEditModal('edit');
            }}
            onDelete={() => {
              setSelectedWorkflow(workflow.definitionId);
              setOpenEditModal('delete');
            }}
            searchText={searchText}
            assignable={true}
          />
        ))}
      </ul>
      <SlideOver
        isOpen={openEditModal === 'edit'}
        onClose={() => setOpenEditModal(null)}
        onAfterLeave={() => setSelectedWorkflow(null)}
      >
        <WorkflowTasksSlideOver
          workflow={
            selectedWorkflow
              ? sortedAssignableWorkflows.find((workflow) => workflow.definitionId === selectedWorkflow) ?? null
              : null
          }
          onClose={() => setOpenEditModal(null)}
          projectId={projectId}
          configurationOptions={configurationOptions ?? []}
        />
      </SlideOver>
    </>
  );
};
