import { ShortCommitmentReadModel, ShortContractReadModel, useApiGetCommitmentQuery } from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import {
  Badge,
  Button,
  SlideOver,
  SlideOverOnCloseProps,
  SlideOverWithTabs,
  LoadingIndicator,
} from '@client/shared/toolkit';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { CommitmentDocumentsTab, CommitmentTab } from '.';
import { useValidateProjectPermission } from '@client/shared/permissions';

interface CommitmentSlideOverProps extends SlideOverOnCloseProps {
  setChildSlideOverIsOpen: (val: boolean) => void;
  shortCommitment?: ShortCommitmentReadModel | null;
  contracts?: ShortContractReadModel[] | null;
  commitmentId?: string;
}

export const CommitmentSlideOver = ({
  onClose,
  shortCommitment,
  contracts,
  setChildSlideOverIsOpen,
  commitmentId,
}: CommitmentSlideOverProps) => {
  const { t } = useTranslation();
  
  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const canWrite = useValidateProjectPermission(['CONTRACT_WRITE'], loadedProjectId ?? '');
  const canDelete = useValidateProjectPermission(['CONTRACT_DELETE'], loadedProjectId ?? '');

  const { data: commitment, isFetching: isLoadingCommitment } = useApiGetCommitmentQuery(
    {
      commitmentId: commitmentId ?? shortCommitment?.id ?? '',
      projectId: loadedProjectId ?? '',
      calculationModelId: loadedVariantId ?? '',
    },
    {
      skip: !loadedProjectId || !loadedVariantId || (!shortCommitment?.id && !commitmentId),
    },
  );

  const tabOptions = useMemo(() => {
    if (!commitment) return [];
    return [
      {
        header: <>{t('projectContract.commitment')} </>,
        name: 'commitment',
        panel: (
          <CommitmentTab
            shortCommitment={shortCommitment}
            commitmentId={commitmentId}
            contracts={contracts}
            canWrite={canWrite}
            setChildSlideOverIsOpen={setChildSlideOverIsOpen}
          />
        ),
      },
      {
        header: (
          <>
            {t('projectContract.documents')}{' '}
            {commitment && commitment?.documents?.length > 0 && (
              <Badge variant="lightInfo" text={commitment?.documents?.length.toString()} />
            )}
          </>
        ),
        name: 'documents',
        panel: (
            <CommitmentDocumentsTab
              commitmentId={commitment.id}
              documents={commitment.documents}
              canWrite={canWrite}
              canDelete={canDelete}
            />
        ),
      },
    ];
  }, [commitment, t, shortCommitment, commitmentId, contracts, canWrite, setChildSlideOverIsOpen, canDelete]);

  return (
    <>
      {isLoadingCommitment && <LoadingIndicator text={t('common.loading')} mode="overlay-window" />}

      <SlideOverWithTabs
        tabOptions={tabOptions}
        title={<div className="text-2xl leading-tight font-bold truncate">{commitment?.name}</div>}
        subtitle={
          <div className="whitespace-nowrap text-[13px] leading-tight text-gray-500 pt-1">
            {t('projectContract.commitment')}&nbsp;<span className="font-bold">{commitment?.code}</span>
          </div>
        }
        onClose={onClose}
      ></SlideOverWithTabs>

      <SlideOver.Controls>
        <Button onClick={() => onClose(false)} variant="secondary">
          {t('common.close')}
        </Button>
      </SlideOver.Controls>
    </>
  );
};
