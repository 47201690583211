import {
  apiBase, ApiTagTypes,
  InvoiceReadModel,
  useApiGetInvoiceWorkflowDetailQuery,
  useApiStartInvoiceWorkflowMutation,
} from '@client/shared/api';
import { useEffect, useState } from 'react';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { useDispatch } from 'react-redux';
import { StartWorkflowDisabledMessage, WorkflowDetail } from '../..';
import { safeMutation } from '@client/shared/utilities';
import { CircledPlayIcon, ContextMenuItem } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';

interface InvoiceWorkflowsProps {
  invoice: InvoiceReadModel;
}

export const InvoiceWorkflows = ({ invoice }: InvoiceWorkflowsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const [startWorkflow, { isLoading: isStartingWorkflow }] = useApiStartInvoiceWorkflowMutation();
  const { data: workflow, isFetching: isLoadingWorkflow } = useApiGetInvoiceWorkflowDetailQuery(
    {
      invoiceId: invoice.id,
      projectId: loadedProjectId ?? '',
      calculationModelId: loadedVariantId ?? '',
    },
    {
      skip: !loadedProjectId || !loadedVariantId || !invoice.id || !invoice.hasWorkflow,
    },
  );

  const [workflowStarted, setWorkflowStarted] = useState(false);

  useEffect(() => {
    if (workflowStarted && !workflow) {
      const interval = setInterval(() => {
        if (workflowStarted && workflow) {
          setWorkflowStarted(false);
          clearInterval(interval);
        } else {
          // @ts-expect-error tmp fix for missing callback from BE
          dispatch(apiBase.util.invalidateTags([{ type: ApiTagTypes.Invoices, id: loadedVariantId ?? '' }, ApiTagTypes.Contract, ApiTagTypes.Tasks, { type: ApiTagTypes.Workflow, id: invoice.id }, { type: ApiTagTypes.Invoice, id: invoice.id }]));
        }
      }, 10000);
      return () => clearInterval(interval);
    } else if (workflowStarted && workflow) {
      setWorkflowStarted(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowStarted, workflow]);

  const handleStartWorkflow = async () => {
    if (loadedProjectId && loadedVariantId) {
      try {
        setWorkflowStarted(true);
        await safeMutation(
          startWorkflow,
          {
            body: {
              projectId: loadedProjectId,
              calculationModelId: loadedVariantId,
              invoiceId: invoice.id,
            },
          },
          isStartingWorkflow,
        );
      } catch (e) {
        console.error(e);
      }
    }
  };

  const contextMenuItems: ContextMenuItem[] = [
    {
      label: invoice.startWorkflowStatus.canRestartWorkflow
        ? invoice.startWorkflowStatus.workflowName ? t('projectControl.restartWorkflowName', { name: invoice.startWorkflowStatus.workflowName }) : t('projectControl.restartWorkflow')
        : invoice.startWorkflowStatus.workflowName ? t('projectControl.startWorkflowName', { name: invoice.startWorkflowStatus.workflowName }) : t('projectControl.startWorkflow'),
      subtitle: t('projectControl.startWorkflowForInvoice'),
      icon: <CircledPlayIcon />,
      onClick: () => {
        handleStartWorkflow();
      },
      isDisabled: (!invoice.startWorkflowStatus?.canStartWorkflow && !invoice.startWorkflowStatus?.canRestartWorkflow) || (workflowStarted && !workflow),
      children: !invoice.startWorkflowStatus.canStartWorkflow && !invoice.startWorkflowStatus?.canRestartWorkflow ? (
        <StartWorkflowDisabledMessage contractId={invoice.contractId} startWorkflowStatus={invoice.startWorkflowStatus} projectId={loadedProjectId ?? ''} workflowType='Invoice' />
      ) : null,
      truncateText: false
    },
  ];

  return (
     <WorkflowDetail 
       workflow={workflow}
       contextMenuItems={contextMenuItems}
       workflowLoading={isLoadingWorkflow || isStartingWorkflow}
       workflowType="Invoice"
       workflowStarted={workflowStarted}
     />
  );
};