import { useCallback, useEffect, useState } from 'react';
import {
  EarningsType,
  InflationReturnResult,
  useApiGetEarningInflationDistributionSuggestionMutation,
  useApiGetInflationDistributionSuggestionMutation,
  useApiPostCreateInflationCostElementMutation,
  useApiPostCreateInflationEarningElementMutation,
} from '@client/shared/api';
import { formatDateOnly, safeMutation } from '@client/shared/utilities';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { Button, LoadingIndicator } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { DistributionModal } from '@client/project/shared';
import { InflationCalcEdit } from './InflationCalcEdit';
import { useUi } from '@client/shared/store';
import { InflationCalcDistribution } from './InflationCalcDistribution';

interface InflationCalcModalProps {
  catalogId: string;
  onClose: () => void;
  onSave: (id: string) => void;
  type: 'costs' | 'earnings';
  earningsType?: EarningsType;
  id?: string;
}

export const InflationCalcModal = ({ catalogId, onClose, onSave, type, earningsType, id }: InflationCalcModalProps) => {
  const { t } = useTranslation();
  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const [inflationRate, setInflationRate] = useState<number>(0);
  const [startDate, setStartDate] = useState(formatDateOnly(new Date()));
  const [endDate, setEndDate] = useState(formatDateOnly(new Date()));
  const [selectedElementIds, setSelectedElementIds] = useState<string[]>([]);
  const [selectedUDF, setSelectedUDF] = useState('none');
  const [selectedUDFLabels, setSelectedUDFLabels] = useState<string[]>([]);
  const [distribution, setDistribution] = useState<InflationReturnResult | null>(null);

  // TODO remove
  const ui = useUi();
  const user = ui.appUser;

  const [createInflationCostElement, { isLoading: isCreatingCost }] = useApiPostCreateInflationCostElementMutation();
  const [createInflationEarningsElement, { isLoading: isCreatingEarning }] =
    useApiPostCreateInflationEarningElementMutation();

  const [getSuggestionCosts, { isLoading: isFetchingSuggestionCosts }] =
    useApiGetInflationDistributionSuggestionMutation();
  const [getSuggestionEarnings, { isLoading: isFetchingSuggestionEarnings }] =
    useApiGetEarningInflationDistributionSuggestionMutation();

  const fetchDistribution = useCallback(async () => {
    if (
      user?.tenant?.tenantId &&
      loadedVariantId &&
      loadedProjectId &&
      !isFetchingSuggestionCosts &&
      !isFetchingSuggestionEarnings
    ) {
      if (type === 'costs') {
        try {
          const result = await getSuggestionCosts({
            projectId: loadedProjectId,
            calculationModelId: loadedVariantId,
            body: {
              costCatalogElementIds: selectedElementIds,
              startDate: startDate,
              inflationRate: inflationRate ?? 0,
              userDefinedFieldId: selectedUDF === 'none' ? null : selectedUDF,
              userDefinedFieldLabelIds:
                selectedUDFLabels.length === 0 || selectedUDF === 'none' ? null : selectedUDFLabels,
            },
          }).unwrap();
          if (result?.suggestion) {
            setDistribution(result.suggestion);
            setEndDate(result.suggestion.endDate);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          const result = await getSuggestionEarnings({
            projectId: loadedProjectId,
            calculationModelId: loadedVariantId,
            body: {
              earningElementIds: selectedElementIds,
              startDate: startDate,
              inflationRate: inflationRate ?? 0,
              userDefinedFieldId: selectedUDF === 'none' ? null : selectedUDF,
              userDefinedFieldLabelIds:
                selectedUDFLabels.length === 0 || selectedUDF === 'none' ? null : selectedUDFLabels,
            },
          }).unwrap();
          if (result?.suggestion) {
            setDistribution(result.suggestion);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  }, [
    selectedElementIds,
    loadedProjectId,
    selectedUDF,
    loadedVariantId,
    type,
    getSuggestionCosts,
    startDate,
    inflationRate,
    getSuggestionEarnings,
    isFetchingSuggestionCosts,
    isFetchingSuggestionEarnings,
    user?.tenant?.tenantId,
    selectedUDFLabels,
  ]);

  useEffect(() => {
    if (selectedElementIds.length) {
      fetchDistribution();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedElementIds, inflationRate, startDate, selectedUDFLabels]);

  const handleSave = async () => {
    if (loadedProjectId && loadedVariantId && distribution) {
      if (type === 'costs') {
        try {
          await safeMutation(
            createInflationCostElement,
            {
              calculationModelId: loadedVariantId ?? 'unset',
              projectId: loadedProjectId ?? 'unset',
              body: {
                costCatalogId: catalogId,
                suggestedPlan: distribution,
                costGroupIds: selectedElementIds,
                startDate: startDate,
                inflationRate: inflationRate,
                userDefinedFieldId: selectedUDF === 'none' ? null : selectedUDF,
                userDefinedFieldLabelIds:
                  selectedUDFLabels.length === 0 || selectedUDF === 'none' ? null : selectedUDFLabels,
              },
            },
            isCreatingCost,
          );
          onSave('');
        } catch (e) {
          console.log(e);
        }
      } else {
        try {
          await safeMutation(
            createInflationEarningsElement,
            {
              calculationModelId: loadedVariantId ?? 'unset',
              projectId: loadedProjectId ?? 'unset',
              body: {
                earningCatalogId: catalogId,
                type: earningsType ?? 'SaleRevenue', // nur nicht rent! // TODO remove
                suggestedPlan: distribution,
                earningsElementIds: selectedElementIds,
                startDate: startDate,
                inflationRate: inflationRate,
                userDefinedFieldId: selectedUDF === 'none' ? null : selectedUDF,
                userDefinedFieldLabelIds:
                  selectedUDFLabels.length === 0 || selectedUDF === 'none' ? null : selectedUDFLabels,
              },
            },
            isCreatingEarning,
          );
          onSave('');
        } catch (e) {
          console.log(e);
        }
      }
    }
  };

  return (
    <>
      {(isCreatingCost || isCreatingEarning) && <LoadingIndicator text={t('common.saving') ?? ''} mode="overlay" />}
      <DistributionModal
        title={t('projectCalculate.inflationCalcModal.title')}
        description={
          type === 'costs'
            ? t('projectCalculate.inflationCalcModal.subtitleCosts')
            : t('projectCalculate.inflationCalcModal.subtitleEarnings')
        }
        header={
          <InflationCalcEdit
            id={id}
            type={type}
            inflationRate={inflationRate}
            setInflationRate={setInflationRate}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            selectedElementIds={selectedElementIds}
            setSelectedElementIds={setSelectedElementIds}
            selectedUDF={selectedUDF}
            setSelectedUDF={setSelectedUDF}
            selectedUDFLabels={selectedUDFLabels}
            setSelectedUDFLabels={setSelectedUDFLabels}
            className="w-full px-12 pb-3 border-b-2 shadow-lg z-10 relative"
          />
        }
        controls={
          <div className="w-full flex justify-between">
            <div className="flex space-x-2 w-full justify-end">
              <Button onClick={onClose} variant="secondary">
                {t('common.cancel')}
              </Button>
              <Button
                onClick={handleSave}
                variant="primary"
                disabled={isCreatingCost || isCreatingEarning || selectedElementIds.length === 0}
              >
                {t('common.apply')}
              </Button>
            </div>
          </div>
        }
      >
        <InflationCalcDistribution distribution={distribution} />
      </DistributionModal>
    </>
  );
};
