import { Userpilot } from 'userpilot';
import { settings } from '@client/shared/store';

export const initUserpilot = () => {
  // disable locally
  if (settings.mainDomain !== 'probis.test') {
    // TODO: wire up in settings / deployment
    const APP_TOKEN = settings.devMode || settings.mainDomain === 'probis.qa' ? 'STG-NX-5a4a3022' : 'NX-5a4a3022';
    Userpilot.initialize(APP_TOKEN);
  }
};
