import { YardiClientMappings } from '@client/project/shared';
import { useApiGetYardiApiAccessQuery } from '@client/shared/api';
import { Button, LoadingIndicator, SlideOver, SlideOverOnCloseProps } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';

interface YardiClientSlideOverProps extends SlideOverOnCloseProps {
  apiId: string;
  apiAccessId: string;
  isOpen: boolean;
}

export const YardiClientSlideOver = ({ isOpen, onClose, apiAccessId, apiId }: YardiClientSlideOverProps) => {
  const { t } = useTranslation();

  const { data, isFetching } = useApiGetYardiApiAccessQuery(
    {
      accessId: apiAccessId,
    },
    {
      skip: !apiAccessId,
    },
  );

  return (
    <>
      <SlideOver isOpen={isOpen} onClose={onClose} variant="2xl">
        <SlideOver.Header title={t('app.settingsApiYardiConnection')} onClose={onClose} />
        <SlideOver.Content className="p-10">
          {isFetching && <LoadingIndicator text={t('common.loading')} mode="overlay" />}
          <YardiClientMappings apiAccessId={apiAccessId} projectId={apiId} connectionData={data?.yardiConfiguration} />
        </SlideOver.Content>
        <SlideOver.Controls className="w-full flex justify-end">
          <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
            {t('common.close')}
          </Button>
        </SlideOver.Controls>
      </SlideOver>
      <div></div>
    </>
  );
};
