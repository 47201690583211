import React, { PropsWithChildren, ReactElement, ReactNode } from 'react';
import { InputIcon } from '@client/shared/toolkit';
import cn from 'classnames';
import { ProtectedNavLink, RouteConfig } from '@client/shared/permissions';

interface SettingsNavLinkProps extends PropsWithChildren {
  disabled?: boolean;
  iconPath?: string;
  icon?: ReactElement | ReactNode | string;
  to?: string;
  isMenuOpen?: boolean;
  routeConfig: RouteConfig;
  onClick?: () => void;
}

export const SettingsNavLink = ({
  children,
  icon,
  iconPath,
  to,
  disabled,
  isMenuOpen = false,
  routeConfig,
  onClick
}: SettingsNavLinkProps) => {
  return (
    <ProtectedNavLink
      path={to}
      disabled={disabled}
      routeConfig={routeConfig}
      className={cn('flex items-center text text-gray-900 hover:text-gray-500 transition-colors', {
        'justify-center': !isMenuOpen,
      })}
      activeClassName="font-bold"
      disabledClassName="flex items-center text text-gray-500 cursor-default"
      onClick={onClick}
    >
      {iconPath && (
        <InputIcon
          src={iconPath}
          className={cn(
            'w-6 h-6 inline-flex align-bottom flex-none items-center justify-center',
            isMenuOpen ? 'mr-4' : '',
          )}
        />
      )}
      {icon && (
        <div
          className={cn(
            'w-6 h-6 inline-flex align-bottom flex-none items-center justify-center',
            isMenuOpen ? 'mr-4' : '',
          )}
        >
          {React.cloneElement(icon as ReactElement, { className: 'w-6 h-6' })}
        </div>
      )}
      <span className={cn(isMenuOpen ? 'block' : 'hidden md:block')}>{children}</span>
    </ProtectedNavLink>
  );
};
