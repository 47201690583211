import { CustomBenchmarkPropertyReadModel } from '@client/shared/api';
import { t } from 'i18next';

export const getBenchmarkPropertyCode = (benchmarkProperty : CustomBenchmarkPropertyReadModel) => {
  if (benchmarkProperty.code !== ''){
    return benchmarkProperty.code
  }  
  
  switch (benchmarkProperty.property){
    case 'NFA':
      return t('benchmark.nfaCode')
    case 'GFA':
      return t('benchmark.gfaCode')
    case 'FAC':
      return t('benchmark.facCode')
    case 'GV':
      return t('benchmark.gvCode')
    case 'RS':
      return t('benchmark.rsCode')
    case 'ES':
      return t('benchmark.esCode')
    case 'PA':
      return t('benchmark.paCode')    
    default:
      return benchmarkProperty.code
  }
}

export const getBenchmarkPropertyDescription = (benchmarkProperty : CustomBenchmarkPropertyReadModel) => {
  if (benchmarkProperty.description !== ''){
    return benchmarkProperty.description
  }
  
  switch (benchmarkProperty.property){
    case 'NFA':
      return t('benchmark.nfaDescription')
    case 'GFA':
      return t('benchmark.gfaDescription')
    case 'FAC':
      return t('benchmark.facDescription')
    case 'GV':
      return t('benchmark.gvDescription')
    case 'RS':
      return t('benchmark.rsDescription')
    case 'ES':
      return t('benchmark.esDescription')
    case 'PA':
      return t('benchmark.paDescription')
    default:
      return benchmarkProperty.description
  }
}

export const getBenchmarkPropertyOptions = (customProperties : CustomBenchmarkPropertyReadModel[]) => {
  const options = customProperties.map((x) => {return {
    value : x.id,
    label : `${getBenchmarkPropertyCode(x)} - ${getBenchmarkPropertyDescription(x)} (${x.metricUnit}/${x.imperialUnit})`
  }});

  options.sort((a,b) => a.label.localeCompare(b.label))
  return options;
}