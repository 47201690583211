import * as yup from 'yup';
import { InferType } from 'yup';
import { WidgetDashboardEditFormProps } from '.';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo, useRef } from 'react';
import {
  BaseSelect,
  BaseSelectOption,
  ComboSelect,
  Form,
  FormField,
  FormRefHandle,
  LoadingIndicator,
  SlideOver,
} from '@client/shared/toolkit';
import { useLoadedProjectId } from '@client/project/store';
import { useApiGetProjectCompaniesQuery } from '@client/shared/api';

export const WidgetDashboardEditFormProjectInformationValidationSchema = yup.object({
  title: yup.string().required('validation.required'),
  size: yup.string().required('validation.required'),
  projectManagerId: yup.string().optional().nullable(),
  clientId: yup.string().optional().nullable(),
});

export type WidgetDashboardEditFormProjectInformationValidationValues = InferType<
  typeof WidgetDashboardEditFormProjectInformationValidationSchema
>;

export const WidgetEditFormProjectInformation = (props: WidgetDashboardEditFormProps) => {
  const { buttons, widget, handleSubmit, selectedSize, sizeOptions } = props;
  const { t } = useTranslation();

  const formRef = useRef<FormRefHandle<WidgetDashboardEditFormProjectInformationValidationValues>>();

  const loadedProjectId = useLoadedProjectId();
  const { data, isFetching } = useApiGetProjectCompaniesQuery(
    {
      projectId: loadedProjectId ?? '',
    },
    {
      skip: !loadedProjectId,
    },
  );

  const companiesOptions: BaseSelectOption[] = useMemo(() => {
    return data
      ? data
          ?.filter((company) => company.type === 'Company')
          .map((company) => ({
            label: company.name,
            value: company.id,
          }))
      : [];
  }, [data]);

  const projectManagerOptions: BaseSelectOption[] = useMemo(() => {
    const options: BaseSelectOption[] = [];
    if (data) {
      data.forEach((company) => {
        if (company.persons.length) {
          if (company.type === 'Company') {
            const childOptions: BaseSelectOption[] = company.persons.map((person) => {
              return {
                label: person.fullname,
                value: person.id,
              };
            });
            options.push({
              label: company.name,
              value: company.id,
              disabled: true,
              options: childOptions,
            });
          } else {
            options.push({
              label: company.name,
              value: company.persons[0].id,
            });
          }
        }
      });
    }
    return options;
  }, [data]);

  const defaultFormValues = useMemo(() => {
    let clientId = widget?.widget.additionalConfig?.ProjectInformation?.clientId;
    let projectManagerId = widget?.widget.additionalConfig?.ProjectInformation?.projectManagerId;

    if (projectManagerOptions.length && projectManagerId) {
      const foundOption = projectManagerOptions.findIndex((option) => {
        if (option.value === projectManagerId) {
          return true;
        }
        if (option.options?.length) {
          const foundChildOption = option.options.findIndex((childOption) => childOption.value === projectManagerId);
          return foundChildOption >= 0;
        }
        return false;

          // option.value === projectManagerId || (option.options && option.options.findIndex((childOption) => childOption.value === projectManagerId) > 0)
      });
      if (foundOption < 0) {
        projectManagerId = null;
      }
    } else {
      projectManagerId = null;
    }

    if (companiesOptions.length && clientId) {
      const foundOption = companiesOptions.findIndex((option) => option.value === clientId || option.options?.findIndex((childOption) => childOption.value === clientId));
      if (foundOption < 0) {
        clientId = null;
      }
    } else {
      clientId = null;
    }
    return {
      title: widget?.widget.title ?? t('dashboard.widget.projectInformation.title'),
      size: selectedSize,
      projectManagerId: projectManagerId,
      clientId: clientId,
    };
  }, [t, widget?.widget.title, selectedSize, widget?.widget.additionalConfig?.ProjectInformation?.projectManagerId, widget?.widget.additionalConfig?.ProjectInformation?.clientId, projectManagerOptions, companiesOptions]);

  useEffect(() => {
    formRef.current?.resetForm(defaultFormValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectManagerOptions, companiesOptions]);

  return (
    <Form<WidgetDashboardEditFormProjectInformationValidationValues>
      onSubmit={handleSubmit}
      validationSchema={WidgetDashboardEditFormProjectInformationValidationSchema}
      defaultValues={defaultFormValues}
      className="flex flex-col flex-grow min-h-0"
      ref={formRef}
    >
      <SlideOver.Content className="p-8 relative">
        {isFetching && <LoadingIndicator mode="overlay" text={t('common.loading')} />}
        <FormField name="size">
          {(control) => <BaseSelect label={t('dashboard.widget.size')} options={sizeOptions} {...control} />}
        </FormField>
        <FormField name="projectManagerId">
          {(control) => (
            <ComboSelect
              className="mt-4"
              nullable
              label={t('dashboard.widget.projectInformation.projectManager')}
              options={projectManagerOptions}
              pageOptions
              {...control}
            />
          )}
        </FormField>
        <FormField name="clientId">
          {(control) => (
            <ComboSelect
              className="mt-1"
              nullable
              label={t('dashboard.widget.projectInformation.client')}
              options={companiesOptions}
              pageOptions
              {...control}
            />
          )}
        </FormField>
      </SlideOver.Content>
      {buttons}
    </Form>
  );
};
