import { ContextMenu } from '@client/shared/toolkit';
import {
  BENCHMARK_DRAG_TYPE,
  BenchmarkingDragDropItem,
  BenchmarkingDragDropItemProps,
} from './BenchmarkingDragDropItem';
import cn from 'classnames';

export const BenchmarkingRowHeader = (props: BenchmarkingDragDropItemProps) => {
  const { contextMenuItems, title, subtitle, isHovered, isDragDisabled } = props;
  return (
    <BenchmarkingDragDropItem
      {...props}
      className={cn('py-3 pr-3 flex gap-2 item-center h-[58px] border-b border-slate-300', {
        'bg-slate-100 shadow-inner': isHovered,
        'transition-all duration-300 cursor-move': !isDragDisabled,
      })}
      type={BENCHMARK_DRAG_TYPE.ROW}
    >
      {contextMenuItems && contextMenuItems.length > 0 && (
        <ContextMenu items={contextMenuItems} className="flex-none text-gray-600" />
      )}
      <div className="flex flex-col gap-1 overflow-hidden">
        <span className="font-bold text-[15px] truncate leading-[18px]" title={title}>
          {title}
        </span>
        <div className="text-[9px] leading-none">{subtitle}</div>
      </div>
    </BenchmarkingDragDropItem>
  );
};
