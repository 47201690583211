import {
  ApiDeleteBenchmarkReportColumnApiArg,
  ApiDeleteBenchmarkReportColumnApiResponse,
  ApiGetBenchmarkReportApiArg,
  ApiGetBenchmarkReportApiResponse,
  ApiGetBenchmarkReportConfigurationsApiArg,
  ApiGetBenchmarkReportConfigurationsApiResponse,
  ApiPostCreateBenchmarkReportColumnApiArg,
  ApiPostCreateBenchmarkReportColumnApiResponse,
  ApiPostCreateBenchmarkReportConfigurationApiArg,
  ApiPostCreateBenchmarkReportConfigurationApiResponse,
  ApiPostUpdateBenchmarkReportColumnApiArg,
  ApiPostUpdateBenchmarkReportColumnApiResponse,
  ApiPostUpdateBenchmarkReportConfigurationApiArg,
  ApiPostUpdateBenchmarkReportConfigurationApiResponse,
} from '../apiGenerated';
import { ApiTagTypes } from '../apiTagTypes';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';

export const enhancedApiBenchmarkingEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  apiGetBenchmarkReportConfigurations: {
    providesTags: (
      _result: ApiGetBenchmarkReportConfigurationsApiResponse,
      _error: FetchBaseQueryError,
      _args: ApiGetBenchmarkReportConfigurationsApiArg,
    ) => [{ type: ApiTagTypes.BenchmarkReportConfig, id: 'LIST' }],
  },
  apiGetBenchmarkReport: {
    providesTags: (
      _result: ApiGetBenchmarkReportApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetBenchmarkReportApiArg,
    ) => [
      { type: ApiTagTypes.BenchmarkReportConfig, id: 'LIST' },
      { type: ApiTagTypes.BenchmarkReportConfig, id: args.reportConfigurationId }
    ],
  },
  /*------------------ INVALIDATES TAGS --------------------*/
  apiPostCreateBenchmarkReportColumn: {
    invalidatesTags: (
      _result: ApiPostCreateBenchmarkReportColumnApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateBenchmarkReportColumnApiArg,
    ) => {
      return error == null
        ? [
          { type: ApiTagTypes.BenchmarkReportConfig, id: 'LIST' },
          { type: ApiTagTypes.BenchmarkReportConfig, id: args.reportConfigurationId },
        ]
        : [];
    },
  },
  apiPostCreateBenchmarkReportConfiguration: {
    invalidatesTags: (
      _result: ApiPostCreateBenchmarkReportConfigurationApiResponse,
      error: FetchBaseQueryError,
      _args: ApiPostCreateBenchmarkReportConfigurationApiArg,
    ) => {
      return error == null ? [{ type: ApiTagTypes.BenchmarkReportConfig, id: 'LIST' }] : [];
    },
  },
  apiPostUpdateBenchmarkReportConfiguration: {
    invalidatesTags: (
      _result: ApiPostUpdateBenchmarkReportConfigurationApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateBenchmarkReportConfigurationApiArg,
    ) => {
      return error == null
        ? [
            { type: ApiTagTypes.BenchmarkReportConfig, id: 'LIST' },
            { type: ApiTagTypes.BenchmarkReportConfig, id: args.reportConfigurationId },
          ]
        : [];
    },
  },
  apiDeleteBenchmarkReportColumn: {
    invalidatesTags: (
      _result: ApiDeleteBenchmarkReportColumnApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteBenchmarkReportColumnApiArg,
    ) => {
      return error == null
        ? [
            { type: ApiTagTypes.BenchmarkReportConfig, id: 'LIST' },
            { type: ApiTagTypes.BenchmarkReportConfig, id: args.reportConfigurationId },
          ]
        : [];
    },
  },
  apiPostUpdateBenchmarkReportColumn: {
    invalidatesTags: (
      _result: ApiPostUpdateBenchmarkReportColumnApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateBenchmarkReportColumnApiArg,
    ) => {
      return error == null
        ? [
          { type: ApiTagTypes.BenchmarkReportConfig, id: 'LIST' },
          { type: ApiTagTypes.BenchmarkReportConfig, id: args.reportConfigurationId },
        ]
        : [];
    },
  }
};
