import { DefaultValuesReadModel } from '@client/shared/api';
import { Button, LoadingIndicator, SlideOver, SlideOverOnCloseProps } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { DefaultValuesCustomListItemEdit } from './DefaultValuesCustomListItemEdit';

export interface DefaultValuesCustomListEditSlideOverProps extends SlideOverOnCloseProps {
  defaultValues?: DefaultValuesReadModel;
  isFetching?: boolean;
}

export const DefaultValuesCustomListEditSlideOver = ({
  defaultValues,
  onClose,
  isFetching,
}: DefaultValuesCustomListEditSlideOverProps) => {
  const { t } = useTranslation();

  return (
    <>
      <SlideOver.Header
        onClose={() => onClose(false)}
        title={`${t('projectSetting.customLists.title')} - ${t('app.masterDataDefaultValues.title')}`}
        backgroundClassName="bg-sky-900"
      />

      <SlideOver.Content className="p-8 divide-y">
        {isFetching ? (
          <LoadingIndicator text={t('common.loading')} mode="overlay" />
        ) : (
          defaultValues?.customLists?.map((customList, i) => (
            <DefaultValuesCustomListItemEdit
              defaultValuesId={defaultValues.id}
              customList={customList}
              key={`custom-list-slideover-item-${customList.id}`}
              classNames={i > 0 ? 'pt-4' : 'mt-0'}
            />
          ))
        )}
      </SlideOver.Content>

      <SlideOver.Controls>
        <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
          {t('common.cancel')}
        </Button>
      </SlideOver.Controls>
    </>
  );
};
