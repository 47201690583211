import { settings } from '@client/shared/store';

// extend window typing with our variable
import queryString from 'query-string';

declare global {
  interface Window {
    env: { [name: string]: string };
  }
}

function getEnv(envName: string, default1: string | undefined, default2: string): string {
  // see if we have it in the window object
  if (window != null && window.env != null && window.env[envName] != null) return window.env[envName];

  const query = queryString.parse(window.location.search);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (query[envName] != null) return query[envName] as any as string;

  // in environment variables?
  if (default1 != null && default1 !== "") return default1;

  // fall back to user specified default value
  return default2;
}

export const initSettings = () => {
  settings.mainDomain = getEnv('VITE_MAIN_DOMAIN', process.env.VITE_MAIN_DOMAIN, 'probis.test');
  settings.enableSentry = getEnv('VITE_ENABLE_SENTRY', process.env.VITE_ENABLE_SENTRY, 'false') === 'true';

  // reflect ASPNETCORE_ENVIRONMENT
  settings.sentryEnvironment = getEnv('VITE_SENTRY_ENVIRONMENT', process.env.VITE_SENTRY_ENVIRONMENT, 'local.development');

  settings.clarityId = getEnv('VITE_CLARITY_ID', process.env.VITE_CLARITY_ID, '');

  /* set by azure pipelines */
  settings.buildId = getEnv('VITE_BUILD_ID', process.env.VITE_BUILD_ID, '(unknown)');

  /* set by azure pipelines */
  settings.devMode = getEnv('VITE_DEV_MODE', process.env.VITE_DEV_MODE, 'false') === 'true';

  settings.queryDelay = getEnv('VITE_QUERY_DELAY', process.env.VITE_QUERY_DELAY, '');
  settings.googleMapsApiKey = getEnv('VITE_GOOGLE_MAPS_API_KEY', process.env.VITE_GOOGLE_MAPS_API_KEY, '');
};
