import { Button, FileInput, LoadingIndicator, SlideOver, SlideOverOnCloseProps } from '@client/shared/toolkit';
import { apiEndpointUrls, CostElementData, TemplateFileType, useApiPostImportCostElementsFileMutation, useApiPostParseCostElementFileMutation } from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { safeMutation } from '@client/shared/utilities';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast'
import cn from 'classnames';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { CostElementImportRecord } from './CostElementImportRecord';

export const ImportCostElementsSlideOver = ({ onClose }: SlideOverOnCloseProps) => {
  const { t } = useTranslation();

  const [file, setFile] = useState<File | undefined>();
  const [fileError, setFileError] = useState(false);
  const [records, setRecords] = useState<CostElementData[]>([]);

  const [post, { isLoading: isLoadingFile }] = useApiPostParseCostElementFileMutation();
  const [callImport, {isLoading: isLoadingImport}] = useApiPostImportCostElementsFileMutation();

  const loadedProjectId = useLoadedProjectId();
  const loadedCalculationModelId = useLoadedVariantId();

  const handleFileChange = (fileList: FileList | null) => {
    setRecords([]);
    if (fileList && fileList.length > 0) {
      setFile(fileList[0]);
    } else {
      setFile(undefined);
    }
  };

  useEffect(() => {
    const uploadFile = async () => {
      if (file) {
        try {
          const formData = new FormData();
          formData.append('file', file);

          const result = await safeMutation(
            post,
            {
              projectId: loadedProjectId ?? '',
              calculationModelId: loadedCalculationModelId ?? '',
              body: formData as unknown as { file: Blob },
            },
            isLoadingFile,
          );

          setRecords(result?.costElements ?? []);
        } catch (e) {
          console.log(e);
        }
      }
    };

    if (!fileError) {
      uploadFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const handleDataImport = () => {

      try {
        callImport({
          projectId: loadedProjectId ?? '',
          calculationModelId: loadedCalculationModelId ?? '',
          body: records ?? []
        }).unwrap()
        .then((data) => {
            setRecords(data.errorElements);
            if(data.errorElements.length === 0)
              onClose(true);
            else
              toast.error(t('app.importRecordsError'));
        })
      } catch {
        /* left blank */
      }
  };

  const handleDownloadTemplate = (fileType: TemplateFileType) => {
    const link = document.createElement('a');

    link.setAttribute('target', '_new');
    link.href = `${apiEndpointUrls.apiGetImportCostElementFileTemplate.replace(':projectId', loadedProjectId as string)}?fileType=${fileType}`;
    document.body.appendChild(link);

    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      {(isLoadingFile || isLoadingImport) && (
        <LoadingIndicator text={t('projectCalculate.costElementsLoading') ?? ''} mode="overlay" />
      )}
      <SlideOver.Header
        title={t('projectCalculate.costElementImport')}
        backgroundClassName="bg-gray-600"
        onClose={() => onClose(false)}
      />

      <SlideOver.Content className="p-8 h-full">
        <div className="m-0">
          <div className="divide-gray-100 divide-y">
            <div className="bg-white p-2">
              <FileInput
                acceptedFileTypes={['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', '.xlsx', '.xls', '.csv']}
                multiple={false}
                onChange={handleFileChange}
                setError={setFileError}
              />
              <div className="text-sm text-gray-500 text-center">{t('templates.downloadExampleTemplate')}</div>
              <div className="flex flex-row text-center justify-center space-x-1 mt-1">
                <Button onClick={() => handleDownloadTemplate('Csv')}>Csv</Button>
                <Button onClick={() => handleDownloadTemplate('Xlsx')}>Xlsx</Button>
              </div>
            </div>
            <div className="mt-2 w-full flex bg-white shadow rounded-tl rounded-bl">
              <div className={cn('w-2 h-auto rounded-tl-md rounded-bl-md flex-none bg-blue-600')} />
              <div className="flex w-full flex-col text-primary">
                {records.map((item, i) => (
                  <div className="ml-5" key={`cost-element-${item.data.name}-${i}`}>
                    <CostElementImportRecord element={item} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </SlideOver.Content>

      <SlideOver.Controls>
        <div className="flex justify-end">
          <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
            {t('common.cancel')}
          </Button>
          <Button variant="primary" disabled={!records?.length} onClick={() => handleDataImport()}>
            {t('common.import')}
          </Button>
        </div>
      </SlideOver.Controls>
    </>
  );
};
