import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DecoratedElement, FinancingElement } from '../hooks';
import { CalculateElementGroupSum } from './CalculateElementGroupSum';
import { TimeLineView } from './Timeline';
import { Column } from './CalculateContainer';
import { OptionalColumn } from './CalculateSectionHeader';
import { CalculateColumns } from './CalculateColumns';
import {
  CalculateGroupElementInformationColumn,
  CalculateInformationColumnContent,
} from './CalculateGroupElementInformationColumn';
import { ElementRowMenu } from './ElementRowMenu';
import { ContextMenuItem, PencilIcon, AddIcon } from '@client/shared/toolkit';
import { useValidateProjectPermission } from '@client/shared/permissions';
import { useLoadedProjectId } from '@client/project/store';
import { FormattedCurrency } from '@client/project/shared';
import { CalculateElementRowFinanceColumn } from './CalculateElementRowFinanceColumn';
import { CalculateSelectableColumn } from './CalculateSelectableColumn';
import { FinancingGroupReadModel } from '@client/shared/api';
import { isEmpty } from '@client/shared/utilities';

interface FinancingGroupElementProps {
  item: DecoratedElement<FinancingElement>;
  selectedVersionId: string;
  expanded?: boolean;
  onClick?: () => void;
  onCodeClick?: () => void;
  onNewElement?: () => void;
  optionalColumn: OptionalColumn;
  obligoColumn: OptionalColumn;
  view: TimeLineView;
  isReadOnly: boolean;
  searchResults: string[] | null;
}

export const FinancingGroupElement = ({
  expanded = false,
  item: {
    children,
    element: { group },
    level,
  },
  onClick,
  onCodeClick,
  onNewElement,
  view,
  isReadOnly,
  optionalColumn,
  obligoColumn,
  searchResults,
}: FinancingGroupElementProps) => {
  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();
  const canWriteFinancing = useValidateProjectPermission(['FINANCING_WRITE'], loadedProjectId ?? '');

  const handleOnClick = (evt: React.MouseEvent<HTMLDivElement>) => {
    evt.stopPropagation();
    onClick?.();
  };

  const handleOnCodeClick = (evt: React.MouseEvent<HTMLDivElement>) => {
    evt.stopPropagation();
    onCodeClick?.();
  };

  const showExpanded = useMemo(() => {
    return expanded && children.some((e) => !!e.element.financingElement);
  }, [expanded, children]);

  const groupMenuItems = useMemo<ContextMenuItem[]>(() => {
    const items: ContextMenuItem[] = [];

    if (onNewElement) {
      items.push({
        isDisabled: !canWriteFinancing,
        label: t('projectCalculate.rowMenu.newFinancingElementTitle'),
        subtitle: t('projectCalculate.rowMenu.newFinancingElementSubTitle'),
        onClick: onNewElement,
        icon: <AddIcon />,
      });
    }
    if (onClick) {
      items.push({
        label: t('projectCalculate.rowMenu.updateFinancingElementTitle'),
        subtitle: t('projectCalculate.rowMenu.updateFinancingElementSubTitle'),
        onClick: onClick,
        icon: <PencilIcon />,
      });
    }

    return items;
  }, [t, onNewElement, onClick, canWriteFinancing]);

  const getFinancingCode = (group : FinancingGroupReadModel | undefined) => {
    if (!group){
      return undefined;
    }

    if (!isEmpty(group.groupId)){
      return group.code;
    }

    switch (group.financingType){
      case 'OwnCapital': return t('projectCalculate.financingType.OwnCapitalCode');
      case 'Grant': return t('projectCalculate.financingType.GrantCode');
      case 'Credit': return t('projectCalculate.financingType.CreditCode');
      case 'Fund': return t('projectCalculate.financingType.FundCode');
      default: return t('projectCalculate.financingType.NoneCode');
    }
  };

  const getFinancingTitle = (group : FinancingGroupReadModel | undefined) => {
    if (!group){
      return undefined;
    }

    if (!isEmpty(group.groupId)){
      return group.title;
    }

    switch (group.financingType){
      case 'OwnCapital': return t('projectCalculate.financingType.OwnCapital');
      case 'Grant': return t('projectCalculate.financingType.Grant');
      case 'Credit': return t('projectCalculate.financingType.Credit');
      case 'Fund': return t('projectCalculate.financingType.Fund');
      default: return t('projectCalculate.financingType.None');
    }
  };

  const showValues = searchResults?.length
    ? searchResults.includes(group?.groupId ?? '') || searchResults.includes(group?.catalogId ?? '')
    : true;
    
  return (
    <CalculateColumns
      view={view}
      informationColumnContent={
        <CalculateGroupElementInformationColumn
          level={level}
          view={view}
          handleOnClick={onClick ? handleOnClick : undefined}
          leftColumn={
            <CalculateInformationColumnContent
              showCode={!!getFinancingCode(group)}
              code={getFinancingCode(group)}
              level={level}
              handleOnCodeClick={handleOnCodeClick}
              showExpanded={showExpanded}
              title={getFinancingTitle(group)}
            />
          }
          rightColumn={
            showValues && <>
              <div className="hidden md:flex w-3/12 text-sm text-right flex-row justify-end items-center leading-tight">
                <div>
                  <FormattedCurrency amount={group?.totalGrantAmount} />
                  <div className="text-xs font-light">{t('projectCalculate.financingElementLabelTotalGrants')}</div>
                </div>
              </div>
              <div className="hidden w-4/12 md:flex text-right flex-row justify-end items-center leading-tight">
                <div>
                  <FormattedCurrency amount={group?.totalCost} />
                  <div className="text-xs font-light">{t('projectCalculate.financingElementLabelTotalCosts')}</div>
                </div>
              </div>
              <CalculateElementGroupSum
                calculationState="JustTotal"
                classNames="w-5/12"
                total={group?.totalValue}
                column={Column.INFORMATION}
              />
            </>
          }
          menu={!isReadOnly && <ElementRowMenu items={groupMenuItems} />}
        />
      }
      currentColumnContent={
        <CalculateElementRowFinanceColumn
          level={level}
          columnType={Column.CURRENT}
          view={view}
        >
          {!!group?.totalValue && <FormattedCurrency amount={group?.totalValue} />}
        </CalculateElementRowFinanceColumn>
      }
      obligoColumnContent={
        <CalculateSelectableColumn
          column={obligoColumn}
          level={level}
          handleOnClick={handleOnClick}
          columnType={Column.OBLIGO}
          view={view}
          values={group}
          type="financingGroup"
        />
      }
      optionalColumnContent={
        <CalculateSelectableColumn
          column={obligoColumn}
          level={level}
          handleOnClick={handleOnClick}
          columnType={Column.OPTIONAL_COLUMN}
          view={view}
          values={group}
          type="financingGroup"
        />
      }
    />
  );
};
