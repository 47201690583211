import { BudgetOverrunWarningReadModel, ContractReadModel, InvoiceReadModel } from '@client/shared/api';
import { InvoiceTitlesList } from './InvoiceTitlesList';
import React from 'react';
import cn from 'classnames';
import { FormattedCurrency } from '../../FormattedCurrency';
import { useTranslation } from 'react-i18next';
import { Badge } from '@client/shared/toolkit';
import { formatDistanceToNow } from 'date-fns';

interface InvoiceContractInvoiceListProps {
  contract: ContractReadModel;
  currentInvoice: InvoiceReadModel;
  saveDistribution: () => void;
  budgetOverrun: BudgetOverrunWarningReadModel | undefined;
  budgetAssigned: boolean;
  canEdit: boolean;
}

export const InvoiceContractInvoiceList = (props: InvoiceContractInvoiceListProps) => {
  const { contract, currentInvoice, saveDistribution, budgetOverrun, budgetAssigned, canEdit } = props;

  return (
    <div className="flex flex-col gap-3 divide-y-2">
      {contract.invoices.map((invoice, i) => {
        if (invoice.id === currentInvoice.id) {
          return (
            <div key={`invoice-contract-invoice-${invoice.id}-${i}`}>
              <InvoiceContractInvoiceListItem
                active
                contract={contract}
                currentInvoice={invoice}
                index={i}
                saveDistribution={saveDistribution}
                budgetOverrun={budgetOverrun}
                budgetAssigned={budgetAssigned}
                canEdit={canEdit}
              />
              <InvoiceTitlesList invoice={currentInvoice} contract={contract} isLastInvoice={i === contract.invoices.length - 1} saveDistribution={saveDistribution} budgetOverrun={budgetOverrun} budgetAssigned={budgetAssigned} canEdit={canEdit} />
            </div>
          )
        } else {
          return (
            <InvoiceContractInvoiceListItem
              key={`invoice-contract-invoice-${invoice.id}-${i}`}
              contract={contract}
              currentInvoice={invoice}
              index={i}
              saveDistribution={saveDistribution}
              budgetOverrun={budgetOverrun}
              budgetAssigned={budgetAssigned}
              canEdit={canEdit}
            />
          )
        }
      })}
    </div>
  );
};

interface InvoiceContractInvoiceListItemProps extends InvoiceContractInvoiceListProps {
  active?: boolean;
  index: number
}

const InvoiceContractInvoiceListItem = (props: InvoiceContractInvoiceListItemProps) => {
  const {
    active = false,
    currentInvoice,
    index
  } = props;

  const { t } = useTranslation()

  return (
    <div
      className={cn('flex justify-between items-start leading-none relative pt-4', {
        'text-gray-400': !active,
      })}
    >
      <div className="flex flex-col gap-1 max-w-[200px]">
        <div className={cn('flex gap-2 items-center', active ? 'font-bold' : '')}>
          <span className="truncate whitespace-nowrap" title={currentInvoice.code}>{index + 1}. {currentInvoice.code}</span>
          {currentInvoice.state === 'Pending' && (
            <Badge text={t('common.new')} variant="lightSuccess" className="uppercase flex-none" />
          )}
        </div>
        {currentInvoice.state === 'Approved' && currentInvoice.dateOfApproval && (
          <span className="text-[11px]">{currentInvoice.dateOfApproval ? t('projectControl.approvedDateAgo', { date: formatDistanceToNow(currentInvoice.dateOfApproval, { addSuffix: true, includeSeconds: false } ) }) : ''}</span>
        )}
        {currentInvoice.state === 'Audited' && currentInvoice.dateOfAudit && (
          <span className="text-[11px]">{currentInvoice.dateOfAudit ? t('projectControl.auditedDateAgo', { date: formatDistanceToNow(currentInvoice.dateOfAudit, { addSuffix: true, includeSeconds: false }) }) : ''}</span>
        )}
        {currentInvoice.state === 'Paid' && currentInvoice.paymentDate && (
          <span className="text-[11px]">{currentInvoice.paymentDate ? t('projectControl.payedDateAgo', { date: formatDistanceToNow(currentInvoice.paymentDate, { addSuffix: true, includeSeconds: false }) }) : ''}</span>
        )}
      </div>
      {!active && (
        <div className="flex flex-col items-end gap-1">
          <span className="font-bold leading-none"><FormattedCurrency amount={currentInvoice.invoiceValueNet} /></span>
          <span className="text-[10px]">{t('common.gross')}: <FormattedCurrency amount={currentInvoice.invoiceValueGross} /></span>
        </div>
      )}
      {currentInvoice.state === 'Paid' && (
        <div className="absolute bg-white text-[10px] -rotate-12 border-2 font-bold px-2 py-px border-green-400 text-green-400 rounded right-0 -top-2">
          {t('projectControl.statePaid')}
        </div>
      )}
    </div>
  );
};
