import { WorkflowAssignableActivityReadModel, WorkflowReadModel } from '@client/shared/api';
import {
  ContextMenu,
  PencilIcon,
  SettingsListItem,
  SettingsListItemTitle,
  TextHighlighter,
  WorkflowIconLargeIcon,
} from '@client/shared/toolkit';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface WorkflowRowProps {
  workflow: WorkflowAssignableActivityReadModel | WorkflowReadModel;
  onEdit: () => void;
  onDelete: () => void;
  searchText?: string;
  assignable?: boolean;
}

export const WorkflowRow = (props: WorkflowRowProps) => {
  const { workflow, onEdit, searchText, assignable } = props;
  const { t } = useTranslation();
  return (
    <SettingsListItem
      onClick={assignable ? onEdit : undefined}
      contextMenu={
        assignable && (
          <ContextMenu
            items={[
              {
                label: t('common.edit'),
                subtitle: t('common.editEntity', { entity: t('app.settingsWorkflow') }),
                icon: <PencilIcon />,
                onClick: onEdit,
              },
              /* {
              label: t('common.delete'),
              subtitle: t('common.deleteElement'),
              icon: <TrashIcon />,
              onClick: onDelete,
            }, */
            ]}
          />
        )
      }
    >
      <SettingsListItemTitle
        className="w-6/12 flex-none"
        icon={<WorkflowIconLargeIcon className="w-full" />}
        title={searchText ? <TextHighlighter text={workflow.name} highlighted={[searchText]} /> : workflow.name}
        //subtitle={workflow.description && (
        //               <span className="text-[15px] text-slate-400 leading-5">
        //                 {searchText ? (
        //                   <TextHighlighter text={workflow.description} highlighted={[searchText]} />
        //                 ) : (
        //                   workflow.description
        //                 )}
        //               </span>
        //             )}
      />
      {assignable && 'workflowType' in workflow && <div className="w-2/12 truncate">{workflow.workflowType}</div>}
      <div className="w-2/12" />
      {assignable && 'activities' in workflow && (
        <div className="w-2/12 truncate">
          {workflow.activities.every((a) => a.authorizedGroups?.length) ? t('common.yes') : t('common.no')}
        </div>
      )}
    </SettingsListItem>
  );
};
