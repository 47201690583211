import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { apiEndpointUrls, ContractDocumentReadModel } from '@client/shared/api';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, DocumentListItem } from '@client/shared/toolkit';
import { CommitmentDocumentDeleteModal } from './CommitmentDocumentDeleteModal';

interface CommitmentDocumentRowProps {
  commitmentId: string | undefined;
  commitmentDocument: ContractDocumentReadModel;
  canDelete: boolean;
}

export const CommitmentDocumentRow = ({
  commitmentId,
  commitmentDocument,
  canDelete,
}: CommitmentDocumentRowProps) => {
  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();
  const loadedCalculationModelId = useLoadedVariantId();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const downloadUrl = apiEndpointUrls.apiGetCommitmentDocument.replace(':projectId', loadedProjectId as string).replace(':calculationModelId', loadedCalculationModelId as string).replace(':commitmentId', commitmentId as string).replace(':documentId', commitmentDocument.id);

  return (
    <>
      <DocumentListItem
        fileName={commitmentDocument.name}
        fileSize={commitmentDocument.size}
        created={commitmentDocument.created}
        downloadUrl={commitmentDocument.isEmbedLinkDocument ? commitmentDocument.storagePath : downloadUrl}
        setIsDeleteModalOpen={canDelete ? setIsDeleteModalOpen : undefined}
        contextMenu
        isEmbedUrl={commitmentDocument.isEmbedLinkDocument}
        documentViewerTitle={t('projectContract.contract')}
      />
      <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
        <CommitmentDocumentDeleteModal
          commitmentId={commitmentId}
          document={commitmentDocument}
          onClose={() => setIsDeleteModalOpen(false)}
        />
      </Modal>
    </>
  );
};
