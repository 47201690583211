import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import React, { PropsWithChildren, ReactNode, useEffect } from 'react';
import { Modifier, usePopper } from 'react-popper';
import { Placement } from '@popperjs/core/lib/enums';

export interface ContextMenuItem extends PropsWithChildren {
  label: string;
  subtitle?: string;
  icon?: ReactNode | string;
  onClick: () => void;
  isDisabled?: boolean;
  isVisible?: boolean;
  stopPropagation?: boolean;
  truncateText?: boolean;
  passDataCy?: string;
}

interface ContextMenuProps {
  items: ContextMenuItem[];
  button?: React.ReactElement;
  className?: string;
  placement?: Placement;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  modifiers?: Partial<Modifier<any>>[];
  handlePopoverVisibility?: (isOpen: boolean) => void;
  stopPropagation?: boolean;
}

export const ContextMenu = (props: ContextMenuProps) => {
  const {
    button,
    items,
    className,
    placement = 'bottom-start',
    modifiers = [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['bottom-end', 'top-start', 'top-end'],
          rootBoundary: 'viewport',
        },
      },
      { name: 'offset', options: { offset: [0, 8] } },
    ],
    handlePopoverVisibility,
    stopPropagation = false,
  } = props;
  const [targetElement, setTargetElement] = React.useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = React.useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: placement,
    modifiers: modifiers,
  });

  // detect closing popover
  useEffect(() => {
    if (handlePopoverVisibility) {
      handlePopoverVisibility(!!popperElement);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popperElement]);

  return (
    <Menu as="div" ref={setTargetElement} className={className}>
      {({ open, close }) => (
        <>
          <MenuButton
            className="cursor-pointer"
            as="div"
            onClick={
              stopPropagation
                ? (e) => {
                    e.stopPropagation();
                  }
                : undefined
            }
          >
            {button ? button : <EllipsisVerticalIcon className="w-6 h-6" />}
          </MenuButton>
          {open && (
            <MenuItems
              portal
              as="div"
              ref={setPopperElement}
              className="w-60 font-light bg-white divide-y divide-gray-100 rounded-md shadow-lg overflow-hidden z-30"
              style={{ ...styles.popper }}
              {...attributes.popper}
            >
              {items
                .filter((item) => item.isVisible ?? true)
                .map((item, index) => (
                  <MenuItem
                    key={index}
                    as="div"
                    className={classNames(
                      'text-left text-sm',
                      item.isDisabled ? 'cursor-not-allowed text-gray-200' : 'cursor-pointer text-gray-600',
                      index >= 1 ? 'border-t border-gray-200' : '',
                    )}
                    onClick={
                      item.isDisabled
                        ? undefined
                        : (e) => {
                            if (item.stopPropagation) {
                              e.preventDefault();
                              e.stopPropagation();
                            }
                            close();
                            item.onClick();
                            if (item.stopPropagation) {
                              return false;
                            }
                          }
                    }
                    data-cy={item.passDataCy}
                  >
                    {({ focus }) => (
                      <>
                        {item.children ? (
                          item.children
                        ) : (
                          <div className={classNames('flex px-4 py-1', { 'bg-blue-200': focus })}>
                            {item.icon && (
                              <div className="flex items-center justify-center">
                                <div className="w-5 h-5 ">{item.icon}</div>
                              </div>
                            )}
                            <div
                              className={classNames(
                                'text-left flex-grow',
                                item.truncateText ? 'truncate' : '',
                              )}
                            >
                              <div
                                className={classNames(
                                  'font-bold ml-3 text-left',
                                  item.truncateText ? 'truncate' : '',
                                )}
                              >
                                {item.label}
                              </div>
                              {item.subtitle && (
                                <div
                                  className={classNames(
                                    'text-xs ml-3 text-left',
                                    item.truncateText ? 'truncate' : '',
                                  )}
                                >
                                  {item.subtitle}
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </MenuItem>
                ))}
            </MenuItems>
          )}
        </>
      )}
    </Menu>
  );
};
