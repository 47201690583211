import { useTranslation } from 'react-i18next';
import { Badge, CheckBox, WidgetContainer, WidgetContainerContent, WidgetContainerTitle } from '@client/shared/toolkit';
import { DashboardWidgetProps } from '../WidgetDashboard';
import { formatDate } from '@client/shared/utilities';
import React from 'react';
import { TaskReadModel } from '@client/shared/api';

export const DashboardWidgetTasks = (props: DashboardWidgetProps) => {
  const { widget } = props;
  const { t } = useTranslation();
  return (
    <WidgetContainer>
      <WidgetContainerTitle>{widget.title ? widget.title : t('dashboard.widget.tasks.title')}</WidgetContainerTitle>
      <WidgetContainerContent className="flex-1 overflow-y-auto">
        {widget?.additionalConfig?.Tasks && widget.additionalConfig.Tasks.length > 0 && (
          <ul className="divide-y">
            {widget.additionalConfig.Tasks.map((task, i) => (
              <li key={`widget-${widget.id}-task-${i}`}>
                <TaskRow {...task} />
              </li>
            ))}
          </ul>
        )}
      </WidgetContainerContent>
    </WidgetContainer>
  );
};

export const TaskRow = (props: TaskReadModel) => {
  const { name, completed, date } = props;
  return (
    <div className="flex items-center justify-between gap-4 py-2">
      <div className="flex gap-4 flex-1 text-[15px]">
        <CheckBox
          className="w-2/12 flex-none mt-0.5"
          checked={completed}
          name=""
          onChange={() => {
            // empty
          }}
          disabled
        />
        {name}
      </div>
      {date && (
        <Badge
          text={formatDate(new Date(date))}
          className="flex-none self-start bg-slate-300 text-white"
          variant="custom"
        />
      )}
    </div>
  );
};
