import { AddButton, Button, SlideOver, SlideOverOnCloseProps } from '@client/shared/toolkit';
import { CustomBenchmarkPropertyReadModel, DefaultValuesReadModel } from '@client/shared/api';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultValuesReferenceUnitEditSlideOver } from './DefaultValuesReferenceUnitEditSlideOver';
import { ReferenceUnitsList } from './ReferenceUnitsList';

export interface DefaultValuesReferenceUnitEditSlideOverProps extends SlideOverOnCloseProps {
  defaultValues?: DefaultValuesReadModel;
}

export const DefaultValuesReferenceUnitsSlideOver = ({
  defaultValues,
  onClose,
}: DefaultValuesReferenceUnitEditSlideOverProps) => {
  const { t } = useTranslation();

  const [isSlideOverOpen, setIsSlideOverOpen] = useState(false);
  const [selectedReferenceUnit, setSelectedReferenceUnit] = useState<CustomBenchmarkPropertyReadModel | null>(null);

  return (
    <>
      <SlideOver.Header
        onClose={() => onClose(false)}
        title={`${t('projectSetting.referenceUnits.title')} - ${t('app.masterDataDefaultValues.title')}`}
        backgroundClassName="bg-sky-900"
      />
      <SlideOver.Content className="p-8">
        <ReferenceUnitsList
          defaultValues={defaultValues}
          handleClick={(refUnit) => {
            setSelectedReferenceUnit(refUnit);
            setIsSlideOverOpen(true);
          }}
        />
        <AddButton onClick={() => setIsSlideOverOpen(true)} className="-mt-4 ml-auto mr-2 flex-none" />
      </SlideOver.Content>
      <SlideOver.Controls>
        <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
          {t('common.cancel')}
        </Button>
      </SlideOver.Controls>
      <SlideOver
        isOpen={isSlideOverOpen}
        onClose={() => setIsSlideOverOpen(false)}
        onAfterLeave={() => setSelectedReferenceUnit(null)}
      >
        <DefaultValuesReferenceUnitEditSlideOver
          defaultValues={defaultValues}
          referenceUnit={selectedReferenceUnit}
          onClose={() => setIsSlideOverOpen(false)}
        />
      </SlideOver>
    </>
  );
};
