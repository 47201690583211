import * as yup from 'yup';
import { InferType } from 'yup';
import { API_MAPPING } from '..';

export const YardiMappingAddFormValidationSchema = yup.object({
  name: yup.string().required('validation.required'),
  description: yup.string().required('validation.required'),
  objectType: yup.string().required('validation.required'),
  yardiPropertyName: yup.string().required('validation.required'),
  selectedInput: yup.string().required('validation.required'),
  probisPropertyName: yup.string().when('selectedInput', ([selectedInput], schema) => {
    if (selectedInput === API_MAPPING.PROBIS_PROPERTY_NAME) {
      return yup.string().required('validation.required');
    }
    return yup.string().optional();
  }),
  userDefinedFieldName: yup.string().when('selectedInput', ([selectedInput], schema) => {
    if (selectedInput === API_MAPPING.USER_DEFINED_FIELD_NAME) {
      return yup.string().required('validation.required');
    }
    return yup.string().optional();
  }),
  defaultValue: yup.string().when('selectedInput', ([selectedInput], schema) => {
    if (selectedInput === API_MAPPING.DEFAULT_VALUE) {
      return yup.string().required('validation.required');
    }
    return yup.string().optional();
  }),
});

export type YardiMappingAddFormValidationValues = InferType<typeof YardiMappingAddFormValidationSchema>;
