import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { 
  ContractTitleReadModel,
  useApiDeleteContractTitleDocumentMutation,
  ContractTitleDocumentReadModel,  
} from '@client/shared/api';
import { ModalOnCloseProps, ConfirmModal } from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { useTranslation, Trans } from 'react-i18next';

interface ContractTitleDocumentDeleteModalProps extends ModalOnCloseProps {
  title: ContractTitleReadModel;
  document: ContractTitleDocumentReadModel;
}

export const ContractTitleDocumentDeleteModal = ({ title, document, onClose }: ContractTitleDocumentDeleteModalProps) => {
  const { t } = useTranslation();
  const loadedProjectId = useLoadedProjectId();
  const loadedCalculationModelId = useLoadedVariantId();
  
  const [deleteContractTitleDocument, { isLoading : isLoadingContractTitleDocument }] = useApiDeleteContractTitleDocumentMutation();

  const handleDelete = async () => {
    try {
      await safeMutation(
        deleteContractTitleDocument,
        {
          projectId: loadedProjectId ?? 'unset',
          calculationModelId: loadedCalculationModelId ?? 'unset',
          contractId: title.contractId,
          contractTitleId: title.id,
          documentId: document.id,
        },
        isLoadingContractTitleDocument
      );

      onClose(true);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <ConfirmModal
    title={
      document.isEmbedLinkDocument ? t('projectCalculate.DeleteEmbedDocument') : t('projectCalculate.DeleteDocument')
    }
      onConfirm={handleDelete}
      onClose={() => onClose(false)}
      confirmVariant="danger"
      confirmText={t('common.delete')}
    >
      <Trans 
        i18nKey={
          document.isEmbedLinkDocument ? "projectCalculate.EmbedDocumentDeleteConfirmation" : "projectCalculate.DocumentDeleteConfirmation"
        }
        values={{ entity: document.name }}>
        <div>
          Möchten Sie die Datei <span className="font-bold">Entity</span> wirklich löschen?
        </div>
        <div className="pt-2">Dies kann nicht rückgängig gemacht werden!</div>
      </Trans>
    </ConfirmModal>
  );
};
