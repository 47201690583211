// import { LoadingIndicator } from '@client/shared/toolkit';
// import { useTranslation } from 'react-i18next';
// import { settings } from '@client/shared/store';
import {
  ShortProjectGroupReadModel,
  //useApiGetBenchmarkReportConfigurationsQuery,
  //useApiGetBenchmarkReportQuery,
} from '@client/shared/api';
// import { BenchmarkingReport } from '@client/reports/shared';

export const MultiReportBenchmarking = (_props: { group: ShortProjectGroupReadModel }) => {
  // const { t } = useTranslation();

  // const { data } = useApiGetProjectGroupQuery({ groupId: group.id || '' }, { skip: !group.id });

  /* const { data: report, isFetching } = useApiGetBenchmarkReportQuery(
    {
      reportConfigurationId: '',
      assetClassFilter: '',
    },
    {
      skip: true,
    },
  );

  const { data: config, isFetching: isFetchingConfig } = useApiGetBenchmarkReportConfigurationsQuery(undefined, {
    skip: true,
  });

  if (!settings.devMode) {
    return '';
  }

  return (
    <>
      {(isFetching || isFetchingConfig) && <LoadingIndicator text={t('common.loading')} mode="overlay-window" />}
      <BenchmarkingReport addProjectsEnabled={false} />
    </>
  ); */

  return null;
};
