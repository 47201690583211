import React, { Ref, forwardRef, useImperativeHandle } from 'react';
import { CatalogEditingProps, SaveCatalogChanges } from './CatalogEditingModal';
import { CostCatalogElementReadModel, useApiGetCatalogQuery } from '@client/shared/api';
import { CatalogEditingRows } from './CatalogEditingRows';
import { useTranslation } from 'react-i18next';
import { CalculateCostsIcon, LoadingIndicator } from '@client/shared/toolkit';

export const CostCatalogEditing = forwardRef(({ catalog, selectedMappedCatalog, setSelectedMappedCatalog, showCreateMapping, setShowCreateMapping }: CatalogEditingProps, ref: Ref<SaveCatalogChanges>) => {
  const { t } = useTranslation()
  const { data, isFetching } = useApiGetCatalogQuery({ id: catalog.id });
  const elements: CostCatalogElementReadModel[] = data?.costCatalog.elements ?? [];
  const rootNodes = elements.filter((x) => !x.parentId);
  const mappings = data?.costCatalog.mappings ?? [];

  useImperativeHandle(ref, () => ({ saveChanges }));

  const saveChanges = (): boolean => {
    console.log('saving');
    return true;
  };

  return (
    <>
      {isFetching && (<LoadingIndicator text={t('app.settingsLoadingCatalogElements')} mode="overlay" />)}
      <CatalogEditingRows
        title={t('projectCalculate.calculateCostsTitle')}
        icon={<CalculateCostsIcon />}
        bgColor="bg-costs"
        catalog={catalog}
        mappings={mappings}
        elements={rootNodes}
        type="Costs"
        selectedMappedCatalog={selectedMappedCatalog}
        setSelectedMappedCatalog={setSelectedMappedCatalog}
        showCreateMapping={showCreateMapping}
        setShowCreateMapping={setShowCreateMapping}
      />
    </>
  )
});
