import { FormattedCurrency } from '@client/project/shared';
import { CashFlowGroupReportSectionResponse, CashFlowGroupReportReadModel } from '@client/shared/api';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import cn from 'classnames';
import { useMemo, useState } from 'react';
import { ReportCashflowCell } from './ReportCashflowCell';
import { Collapsible } from '@client/shared/toolkit';
import { CASHFLOW_COLUMN_MIN_WIDTH, CASHFLOW_COLUMN_COLLAPSED_WIDTH } from '../ReportCashflow';

interface ReportCashflowTableQuartersProps {
  data: CashFlowGroupReportSectionResponse | undefined;
  startDate: string;
  endDate: string;
  expandedElements: string[];
  sectionExpanded: boolean;
}

export const ReportCashflowTableQuarters = ({
  data,
  startDate,
  endDate,
  expandedElements,
  sectionExpanded,
}: ReportCashflowTableQuartersProps) => {
  const [expandedYears, setExpandedYears] = useState<number[]>([]);
  const years = useMemo(() => {
    if (startDate && endDate) {
      const startYear = new Date(startDate).getFullYear();
      const endYear = new Date(endDate).getFullYear();
      const yearsArray = [...Array(endYear - startYear + 1)].map((_, i) => startYear + i);
      setExpandedYears(yearsArray);
      return yearsArray;
    }
    return [];
  }, [startDate, endDate]);

  const minWidth = useMemo(() => {
    return 4 * expandedYears.length * CASHFLOW_COLUMN_MIN_WIDTH + (years.length - expandedYears.length) * 47;
  }, [expandedYears.length, years.length]);

  const toggleYear = (year: number) => {
    const copyOfYears = [...expandedYears];
    const indexOf = copyOfYears.indexOf(year);

    if (indexOf > -1) {
      copyOfYears.splice(indexOf, 1);
    } else {
      copyOfYears.push(year);
    }
    setExpandedYears(copyOfYears.sort());
  };

  const elements = data?.data ?? [];

  return (
    <div className="w-full flex flex-col pr-5">
      <div className="h-8"></div>
      <div className="overflow-x-auto cashflow-columns-quarters">
        <div className="flex" style={{ minWidth: minWidth }}>
          {years.map((year, i) => {
            return (
              <ReportCashflowCell
                key={`year-${year}`}
                header
                styles={{ paddingTop: !expandedYears.includes(year) ? 24 : undefined }}
                className={cn({
                  'pdf-export-hidden text-gray-400': !expandedYears.includes(year),
                  'flex-1': expandedYears.includes(year) && expandedYears.length <= 2,
                })}
              >
                <div className="h-5 text-center">
                  {years.length > 1 && (
                    <button
                      className="pdf-export-hidden absolute top-0 left-0 bg-gray-100 center flex justify-center items-center z-50 rounded hover:bg-gray-200 transition-colors duration-300"
                      onClick={() => toggleYear(year)}
                    >
                      {!expandedYears.includes(year) ? (
                        <PlusIcon className="w-4 h-4" />
                      ) : (
                        <MinusIcon className="w-4 h-4" />
                      )}
                    </button>
                  )}
                  {year}
                </div>
                {expandedYears.includes(year) && (
                  <div className="h-6">
                    <div className="grid grid-cols-4">
                      {[...Array(4)].map((_e, i) => {
                        return (
                          <ReportCashflowCell
                            className="flex-1 pr-2"
                            key={`header-quarter-${year}-${i}`}
                            header
                            styles={{ minWidth: CASHFLOW_COLUMN_MIN_WIDTH }}
                          >
                            {`Q${i + 1}/${year.toString().slice(-2)}`}
                          </ReportCashflowCell>
                        );
                      })}
                    </div>
                  </div>
                )}
                <div className="h-1 border-slate-300 border-b" />
                <Collapsible open={sectionExpanded}>
                  {elements.map((element) => {
                    return (
                      <ReportCashflowValueQuarterCell
                        expandedYears={expandedYears}
                        year={year}
                        element={element}
                        expandedElements={expandedElements}
                        key={`value-cell-${element.uid}`}
                      />
                    );
                  })}
                </Collapsible>
                <div>
                  <div className="grid grid-cols-4 pb-2">
                    {[...Array(4)].map((_e, i) => {
                      const quarter = data?.summary.quarters.find(
                        (quarter) => quarter.year === year && quarter.quarter === i + 1,
                      );
                      return (
                        <ReportCashflowCell
                          className="flex-1"
                          key={`footer-quarter-${year}-${i}`}
                          footer
                          styles={{ minWidth: expandedYears.includes(year) ? CASHFLOW_COLUMN_MIN_WIDTH : 0 }}
                        >
                          <div className="flex-grow">
                            <div className="flex items-center justify-end pl-2 h-11 text-[16px] font-bold truncate border-b border-slate-200 border-dotted">
                              {expandedYears.includes(year) && (
                                <div>
                                  <FormattedCurrency amount={quarter?.payed} />
                                </div>
                              )}
                            </div>
                          </div>
                        </ReportCashflowCell>
                      );
                    })}
                  </div>
                </div>
              </ReportCashflowCell>
            );
          })}
        </div>
      </div>
    </div>
  );
};

interface ReportCashflowValueQuarterCellProps {
  expandedYears: number[];
  year: number;
  element: CashFlowGroupReportReadModel;
  expandedElements: string[];
  level?: number;
}

const ReportCashflowValueQuarterCell = ({
  expandedYears,
  year,
  element,
  expandedElements,
  level = 0,
}: ReportCashflowValueQuarterCellProps) => {
  const expandedYear = expandedYears.includes(year);

  return (
    <div>
      <div
        className={cn('hover:bg-slate-50 transition-colors flex', {
          'bg-gray-50 font-semibold text-[14px]': level === 0,
          'bg-white text-[12px]': level > 0,
        })}
      >
        {(expandedYear ? [...Array(4)] : [...Array(1)]).map((_e, i) => {
          const quarter = element.values?.quarters.find((value) => value.year === year && value.quarter === i + 1);
          return (
            <ReportCashflowCell
              className={cn('flex-1 pr-2', {
                'border-dotted': level > 0,
              })}
              key={`${element.uid}-quarter-${year}-${i}`}
              styles={{ minWidth: expandedYear ? CASHFLOW_COLUMN_MIN_WIDTH : CASHFLOW_COLUMN_COLLAPSED_WIDTH }}
            >
              <div
                className={cn('h-10 flex items-center justify-end', {
                  'text-[14px]': level === 0,
                  'font-normal text-[12px]': level > 0,
                })}
              >
                {expandedYear && <FormattedCurrency amount={quarter?.payed} />}
              </div>
            </ReportCashflowCell>
          );
        })}
      </div>

      {expandedElements.includes(element.uid) &&
        element.children?.map((child) => (
          <ReportCashflowValueQuarterCell
            expandedYears={expandedYears}
            year={year}
            element={child}
            expandedElements={expandedElements}
            level={level + 1}
            key={`value-cell-${child.uid}`}
          />
        ))}
    </div>
  );
};
