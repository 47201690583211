import { ListItem, ListSortHeaderItem } from '@client/shared/toolkit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SortHeaderType } from '@client/shared/utilities';

interface TasksSortHeaderProps {
  onHandleSort: (index: number) => void
  sortHeader: SortHeaderType[]
}
export const TasksSortHeader = (props: TasksSortHeaderProps) => {
  const { onHandleSort, sortHeader } = props;
  const { t } = useTranslation()
  return (
    <ListItem
      className="hidden sm:flex"
      borderColor="bg-transparent"
      bgColor=""
      shadow={false}
      padding={false}
      as="div"
    >
      <div className="flex w-full gap-3.5 pt-2 text-[13px] text-gray-500 justify-between">
        <div className="flex w-full md:w-1/2 2xl:w-2/3 gap-3.5 items-center flex-none">
          <div className="w-8 hidden md:block text-gray-500 text-center">
            {t('projectTasks.sortHeaderType')}
          </div>
          <div>
            <ListSortHeaderItem
              label={sortHeader[0].label}
              asc={sortHeader[0].asc}
              onClick={() => onHandleSort(0)}
              alignRight={false}
            />
          </div>
        </div>
        <div className="flex w-full md:w-1/2 2xl:w-1/3 items-center justify-end overflow-hidden">
          <div className="w-1/3 text-gray-500">
            {t('projectTasks.sortHeaderAssignedTo')}
          </div>
          <div className="w-1/3 flex items-center justify-end">
            <ListSortHeaderItem
              label={sortHeader[1].label}
              asc={sortHeader[1].asc}
              onClick={() => onHandleSort(1)}
              alignRight
            />
          </div>
          <div className="w-1/3 hidden md:block">
            <ListSortHeaderItem
              label={sortHeader[2].label}
              asc={sortHeader[2].asc}
              onClick={() => onHandleSort(2)}
              alignRight
            />
          </div>
        </div>
      </div>
    </ListItem>
  );
}
