import { DecoratedCard, SelectedCustomIcon } from '@client/shared/toolkit';
import { TaxonomyReadModel, PlotReadModel } from '@client/shared/api';
import { formatUnit } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';
import { getAssetClassLabel } from '@client/project/store';

function getTaxonomyUsageTypePercentages(data: TaxonomyReadModel) {
  const totalSize = data.sizes?.bgf?.value || 0;
  const assetClasses: { [key: string]: number } = {};
  const percentages: { [key: string]: number } = {};

  data.children.forEach((child) => {
    const assetClass = child.selectedAssetClass;
    const size = child.sizes?.bgf?.value || 0;
    const assetClassLabel = getAssetClassLabel(assetClass);

    if (assetClassLabel && assetClass && assetClass.enumValue !== 'Undefined' && size > 0) {
      assetClasses[assetClassLabel] = (assetClasses[assetClassLabel] ?? 0) + size;
    }
  });

  for (const usageType in assetClasses) {
    percentages[usageType] = (assetClasses[usageType] / totalSize) * 100;
  }
  return percentages;
}

function getPlotUsageTypePercentages(data: PlotReadModel) {
  const totalSize = data.totalPlotSize || 0;
  const assetClasses: { [key: string]: number } = {};
  const percentages: { [key: string]: number } = {};

  data.plots.forEach((child) => {
    const assetClass = child.assetClass;
    const size = child.totalPlotSize || 0;
    const assetClassLabel = getAssetClassLabel(assetClass);

    if (assetClassLabel && assetClass && assetClass.enumValue !== 'Undefined' && !!size.value) {
      assetClasses[assetClassLabel] = (assetClasses[assetClassLabel] ?? 0) + (size.value ?? 0);
    }
  });

  for (const usageType in assetClasses) {
    percentages[usageType] = (assetClasses[usageType] / (totalSize.value ?? 0)) * 100;
  }
  return percentages;
}

interface UsageTypeCardProps {
  item?: TaxonomyReadModel | undefined;
  plot?: PlotReadModel | undefined;
  setIsEditPlotAreaSlideOverOpen?: (value: boolean) => void;
  setSelectedField?: (field: string) => void;
  setIsBuildingItemSlideOverOpen?: (value: boolean) => void;
}

export const UsageTypeCard = ({
  item,
  plot,
}: UsageTypeCardProps) => {
  const { t } = useTranslation();

  let taxonomyUsageTypes: { [key: string]: number } = {};
  if (item) {
    taxonomyUsageTypes = getTaxonomyUsageTypePercentages(item);
  }

  let plotAssetClasses: { [key: string]: number } = {};
  if (plot) {
    plotAssetClasses = getPlotUsageTypePercentages(plot);
  }

  return (
    <DecoratedCard>
      <DecoratedCard.Header
        showActionButton={false}
      >
        <div className="truncate">{t(`projectTaxonomy.building.usage`)}</div>
      </DecoratedCard.Header>
      <DecoratedCard.Content className="p-5 w-full h-full grid grid-cols-1 bg-white gap-3">
        {item && (
          <>
            {Object.keys(taxonomyUsageTypes).map((usageType) => (
              <div key={usageType} className="grid grid-cols-8 items-center">
                <SelectedCustomIcon className="w-3 h-3" />
                <div className="col-span-7">
                  <div className="text-base font-bold text-gray-900">
                    {formatUnit(taxonomyUsageTypes[usageType], '% ')}
                    {item?.selectedAssetClass?.label ?? t(`projectTaxonomy.usageType.${item?.selectedAssetClass?.enumValue}`)}
                  </div>
                  <div className="text-xs text-gray-500">{t('projectTaxonomy.building.usageType')}</div>
                </div>
              </div>
            ))}
            {Object.keys(taxonomyUsageTypes).length === 0 && (
              <div className="flex flex-col items-center">
                <div className="text-base font-bold text-gray-900">
                  {item?.selectedAssetClass?.label ?? t(`projectTaxonomy.usageType.${item?.selectedAssetClass?.enumValue}`)}
                </div>
                <div className="text-xs text-gray-500">{t('projectTaxonomy.building.usageType')}</div>
              </div>
            )}
          </>
        )}
        {plot && (
          <>
            {Object.keys(plotAssetClasses).map((assetClass) => (
              <div key={assetClass} className="grid grid-cols-8 items-center">
                <SelectedCustomIcon className="w-3 h-3" />
                <div className="col-span-7">
                  <div className="text-base font-bold text-gray-900">
                    {formatUnit(plotAssetClasses[assetClass], '% ')}
                    {assetClass}
                  </div>
                  <div className="text-xs text-gray-500">{t('projectTaxonomy.building.usageType')}</div>
                </div>
              </div>
            ))}
            {Object.keys(plotAssetClasses).length === 0 && plot.assetClass?.enumValue !== 'Undefined' && (
              <div className="flex flex-col items-center">
                <div className="text-base font-bold text-gray-900">
                  {getAssetClassLabel(plot.assetClass)}
                </div>
                <div className="text-xs text-gray-500">{t('projectTaxonomy.building.usageType')}</div>
              </div>
            )}
          </>
        )}
      </DecoratedCard.Content>
    </DecoratedCard>
  );
};
