import { EarningsElementShortReadDto } from '@client/shared/api';
import { ElementMultiSelect, GenericElementMultiSelectProps, MultiSelectGroup } from '.';
import { useMemo } from 'react';

export interface EarningsElementMultiSelectProps extends GenericElementMultiSelectProps {
  earningsData: EarningsElementShortReadDto[];
}
export const EarningsElementMultiSelect = (props: EarningsElementMultiSelectProps) => {
  const { earningsData, onClose, selectedElements, updateSelectedElements, showControls } = props;

  // map the data to MultiSelectGroup[]
  const mappedElements = useMemo(() => {
    const mapToMultiSelectGroup = (elements: EarningsElementShortReadDto[], ids: string[]): {
      elements: MultiSelectGroup[],
      ids: string[]
    } => {
      const mapped = elements.map((item) => {
        ids.push(item.group?.groupId ?? item.earningsElement?.id ?? '');
        const childGroups = item.group?.children?.filter((child) => {
          return child.group;
        });
        const childElements = item.group?.children?.filter((child) => {
          return child.earningsElement;
        });
        return {
          id: item.group?.groupId ?? item.earningsElement?.id,
          elementId: item.group?.earningsElementId ?? item.earningsElement?.id, // for udf search
          code: item.group?.code ?? item.earningsElement?.code,
          description: item.group?.description ?? item.earningsElement?.description,
          children: childGroups?.length ? mapToMultiSelectGroup(childGroups, ids).elements : [],
          elements: childElements?.length ? mapToMultiSelectGroup(childElements, ids).elements : [],
        } as MultiSelectGroup
      });
      return {
        elements: mapped,
        ids: ids
      };
    }
    return mapToMultiSelectGroup(earningsData, []);
  }, [earningsData]);

  return (
    <ElementMultiSelect
      elements={mappedElements.elements}
      elementIds={mappedElements.ids}
      selected={selectedElements}
      type="Earnings"
      onClose={onClose}
      updateSelectedElements={updateSelectedElements}
      showControls={showControls}
    />
  );
}
