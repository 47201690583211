import { useTranslation } from 'react-i18next';
import { useApiDeleteCatalogMappingMutation } from '@client/shared/api';
import { safeMutation } from '@client/shared/utilities';
import { ConfirmModal, HintBox, LoadingIndicator, ModalOnCloseProps } from '@client/shared/toolkit';
import {
  TenantCatalogReadModel,
  TenantCatalogType
} from '@client/shared/api';

interface CatalogDeleteMappingModalProps extends ModalOnCloseProps {
  catalog: TenantCatalogReadModel;
  mappingId: string;
  type: TenantCatalogType;
}

export const CatalogDeleteMappingModal = (props: CatalogDeleteMappingModalProps) => {
  const { onClose, catalog, type, mappingId } = props;
  const { t } = useTranslation();

  const [call, { isLoading }] = useApiDeleteCatalogMappingMutation();

  const handleOnClose = () => {
    onClose(false);
  };

  const handleDelete = async () => {
    try {
      await safeMutation(
        call,
        {
          body: {
            catalogId: catalog.id,
            mappingId: mappingId,
            type: type
          },
        },
        isLoading,
      );
      onClose(true);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <ConfirmModal
      title={t('app.deleteTenantCatalogMapping', { name: catalog.name })}
      onConfirm={handleDelete}
      onClose={handleOnClose}
      confirmVariant="danger"
      confirmText={t('common.delete')}
      isLoading={isLoading}
    >
      {isLoading && (
        <LoadingIndicator text={t('common.loading')} mode="overlay" />
      )}
      <HintBox hintType="warning">{t('app.deleteCatalogMappingHint')}</HintBox>
    </ConfirmModal>
  );
}
