import {
  BaseSelect,
  BaseSelectOption,
  Button,
  Form,
  FormField, FormWatch,
  LoadingIndicator,
  SlideOver,
  SlideOverOnCloseProps,
} from '@client/shared/toolkit';
import {
  TenantCatalogReadModel,
  TenantCatalogType,
  useApiGetAvailableCatalogsForMappingQuery,
  useApiCreateCatalogMappingMutation,
} from '@client/shared/api';
import React, { useMemo } from 'react';
import * as yup from 'yup';
import { InferType } from 'yup';
import { useTranslation } from 'react-i18next';
import { safeMutation } from '@client/shared/utilities';

export const CreateCatalogMappingFormValidationSchema = yup.object({
  catalogId: yup.string().required(),
  mappedCatalogId: yup.string().required(),
});

export type CreateCatalogMappingFormValidationValues = InferType<typeof CreateCatalogMappingFormValidationSchema>;

interface CatalogCreateMappingSlideOverProps extends SlideOverOnCloseProps {
  catalog: TenantCatalogReadModel;
  type: TenantCatalogType;
}

export const CatalogCreateMappingSlideOver = (props: CatalogCreateMappingSlideOverProps) => {
  const { catalog, onClose, type } = props;
  const { t } = useTranslation();

  const [createMapping, { isLoading }] = useApiCreateCatalogMappingMutation();

  const { data, isFetching } = useApiGetAvailableCatalogsForMappingQuery({
    catalogId: catalog.id,
    type: type,
  });

  const handleCatalogElementMappingSubmit = async (data: CreateCatalogMappingFormValidationValues) => {
    try {
      await safeMutation(
        createMapping,
        {
          body: {
            catalogType: type,
            sourceCatalogId: data.catalogId,
            targetCatalogId: data.mappedCatalogId,
          }
        },
        isLoading,
      );
      onClose(false);
    } catch (e) {
      console.error(e);
    }
  };

  const catalogElementOptions: BaseSelectOption[] = useMemo(() => {
    return data?.catalogs
      ? data.catalogs.map((availableCatalog) => {
          return {
            label: availableCatalog.name,
            value: availableCatalog.id,
          };
        })
      : [];
  }, [data?.catalogs]);

  const defaultFormValues = {
    catalogId: catalog.id,
    mappedCatalogId: '',
  };

  return (
    <Form<CreateCatalogMappingFormValidationValues>
      onSubmit={handleCatalogElementMappingSubmit}
      validationSchema={CreateCatalogMappingFormValidationSchema}
      defaultValues={defaultFormValues}
      className="w-full flex flex-col justify-between h-full"
    >
      {(isFetching || isLoading) && <LoadingIndicator text={t('common.loading')} mode="overlay" />}
      <SlideOver.Header
        title={t('templates.newCatalogMappingTitle')}
        subTitle={catalog.name}
        backgroundClassName="bg-gray-600"
        onClose={() => onClose(false)}
      />
      <FormWatch fieldNames={['catalogId', 'mappedCatalogId']}>
        {({ catalogId, mappedCatalogId }) => (
          <>
            <SlideOver.Content>
              <div className="m-8 bg-white">
                <div className="divide-gray-100 divide-y">
                  <FormField name="catalogId">
                    {(control) => (
                      <BaseSelect
                        label={t('templates.catalog')}
                        options={[{ label: catalog.name, value: catalog.id }]}
                        {...control}
                        disabled
                      />
                    )}
                  </FormField>
                  <FormField name="mappedCatalogId">
                    {(control) => (
                      <BaseSelect
                        label={t('templates.mappedCatalog')}
                        options={catalogElementOptions}
                        {...control}
                        disabled={!catalogElementOptions.length}
                      />
                    )}
                  </FormField>
                  {!catalogElementOptions.length && (
                    <div className="px-4 py-2 italic text-gray-500 text-sm">{t('templates.noCatalogsAvailableForMapping')}</div>
                  )}
                </div>
              </div>
            </SlideOver.Content>
            <SlideOver.Controls>
              <div className="flex justify-end">
                <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
                  {t('common.cancel')}
                </Button>
                <Button variant="primary" formSubmit={true} disabled={!mappedCatalogId}>
                  {t('common.save')}
                </Button>
              </div>
            </SlideOver.Controls>
          </>
        )}
      </FormWatch>
    </Form>
  );
};
