import { LoadingIndicator, Modal } from '@client/shared/toolkit';
import { ROUTES_CONFIG, useHasUploadLicense } from '@client/shared/permissions';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { apiEndpointUrls, useApiGetCrossTenantContractsQuery } from '@client/shared/api';
import { UploadWizard } from '@client/project/shared';
import { getInitials } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';

export const ProjectUpload = ({ selectProject = false }: { selectProject?: boolean }) => {
  const { tenantId, projectId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const hasUploadLicense = useHasUploadLicense();

  /** @deprecated cross tenant upload no longer required  */
  const {
    data: loadedProject,
    isFetching,
    isError,
  } = useApiGetCrossTenantContractsQuery(
    {
      crossTenantProjectId: projectId ?? '',
      crossTenantId: tenantId ?? '',
    },
    {
      skip: true,
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(() => {
    if (!selectProject) {
      if (isError) {
        navigate(ROUTES_CONFIG.PROJECTS.name);
      } else {
        setIsOpen(true);
      }
    } else {
      setIsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedProject, isError, selectProject]);

  const contracts = useMemo(() => {
    if (!selectProject && loadedProject) {
      return loadedProject.contracts ?? [];
    }
    return [];
  }, [loadedProject, selectProject]);

  const logo = useMemo(() => {
    if (!loadedProject) {
      return null;
    }
    let logo = '';
    if (loadedProject?.project.hasAvatarImage) {
      logo = apiEndpointUrls.apiGetCrossTenantAvatar.replace(':crossTenantId', loadedProject?.project.tenantId)
    }

    return (
      <div className="absolute top-0 -translate-y-1/2 left-0 w-[260px] z-10 flex flex-col items-center gap-2">
        {logo ? (
          <img
            src={logo}
            className="bg-white rounded-md overflow-hidden w-[100px] h-[100px] object-cover"
            alt="Logo Icon"
          />
        ) : (
          <div className="w-[100px] h-[100px] rounded p-2 text-lg font-medium leading-none overflow-hidden bg-white flex items-center justify-center">
            {getInitials(loadedProject?.project.tenantName)}
          </div>
        )}
        {/*<span className="text-white font-light text-[13px]">{project?.tenantName}</span> */}
      </div>
    );
  }, [loadedProject]);

  if ((!tenantId || !projectId) && !selectProject) {
    navigate(ROUTES_CONFIG.PROJECTS.name);
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      onAfterLeave={() => {
        navigate(ROUTES_CONFIG.PROJECTS.name);
      }}
      variant="default"
      outerContent={logo}
    >
      {isFetching ? (
        <LoadingIndicator text={t('project.projectLoadingIndicator')} mode="overlay" />
      ) : (
        <UploadWizard
          contracts={contracts}
          selectProject={selectProject}
          project={loadedProject?.project}
          loadedProjectId={projectId}
          onClose={() => setIsOpen(false)}
          isCrossTenant={!hasUploadLicense}
        />
      )}
    </Modal>
  );
};
