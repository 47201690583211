import { Routes, Route, Navigate } from 'react-router-dom';
import { ProtectedRoute, ROUTES_CONFIG } from '@client/shared/permissions';
import { BenchmarkingContainer } from '@client/reports/benchmarking';

export const ReportsRoutes = () => {
  return (
    <Routes>
      <Route
        path={ROUTES_CONFIG.REPORTS_BENCHMARKING.name}
        element={
          <ProtectedRoute routeConfig={ROUTES_CONFIG.REPORTS_BENCHMARKING}>
            <BenchmarkingContainer />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<Navigate to={ROUTES_CONFIG.REPORTS_BENCHMARKING.name} replace />} />
    </Routes>
  );
};
