import {
  CalculationModelRiskElementReadModel,
  CalculationModelRiskGroupReadModel,
  RiskElementShortReadDto,
} from '@client/shared/api';

export const flattenRisksElements = (
  riskElements: RiskElementShortReadDto[],
): (CalculationModelRiskGroupReadModel | CalculationModelRiskElementReadModel)[] => {
  return riskElements.flatMap((riskElement) => {
    if (riskElement.group) {
      return [riskElement.group, ...flattenRisksElements(riskElement.group.children)];
    } else if (riskElement.riskElement) {
      return riskElement.riskElement;
    } else {
      return [];
    }
  });
};

/**
 * Flattens all nested risk groups to one level.
 * @param riskElements
 */
export const flattenRisksGroups = (
  riskElements: RiskElementShortReadDto[],
): CalculationModelRiskGroupReadModel[] => {
  return riskElements.flatMap((riskElement) => {
    if (riskElement.group) {
      return [riskElement.group, ...flattenRisksGroups(riskElement.group.children)];
    } else {
      return [];
    }
  });
};
