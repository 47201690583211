import React, { PropsWithChildren } from 'react';
import { ProtectedNavLink, RouteConfig } from '@client/shared/permissions';

export interface ReportingTabProps extends PropsWithChildren {
  routeConfig: RouteConfig;
  className?: string;
  projectId?: string;
}

export const ReportingTab = ({ children, routeConfig, projectId }: ReportingTabProps) => {
  return (
    <ProtectedNavLink
      path={routeConfig.name}
      routeConfig={routeConfig}
      className="flex-grow-0 flex flex-row items-center justify-center w-auto rounded-full px-6 h-8 text-[15px] text-slate-500 font-bold hover:bg-slate-500 hover:text-white cursor-pointer transition-colors whitespace-nowrap"
      activeClassName="bg-slate-500 text-white"
      disabledClassName="flex-grow-0 flex flex-row items-center justify-center w-auto rounded-full px-6 h-8 text-[15px] text-slate-500 font-bold whitespace-nowrap cursor-not-allowed"
      projectId={projectId}
    >
      {children}
    </ProtectedNavLink>
  );
};
