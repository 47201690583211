import {
  ApiCreateCatalogElementApiArg,
  ApiCreateCatalogElementApiResponse,
  ApiCreateCatalogElementMappingApiArg,
  ApiCreateCatalogElementMappingApiResponse,
  ApiCreateCatalogMappingApiArg,
  ApiCreateCatalogMappingApiResponse,
  ApiDeleteCatalogApiArg,
  ApiDeleteCatalogApiResponse,
  ApiDeleteCatalogElementApiArg,
  ApiDeleteCatalogElementApiResponse,
  ApiDeleteCatalogElementMappingApiArg,
  ApiDeleteCatalogElementMappingApiResponse,
  ApiDeleteCatalogMappingApiArg,
  ApiDeleteCatalogMappingApiResponse,
  ApiDeleteCatalogTemplateApiResponse,
  ApiGetAvailableCatalogsForMappingApiArg,
  ApiGetAvailableCatalogsForMappingApiResponse,
  ApiGetCatalogApiArg,
  ApiGetCatalogApiResponse,
  ApiGetCatalogElementCodeApiArg,
  ApiGetCatalogElementCodeApiResponse,
  ApiGetEarningsCatalogApiArg,
  ApiGetEarningsCatalogApiResponse,
  ApiGetFinancingCatalogApiArg,
  ApiGetFinancingCatalogApiResponse,
  ApiGetRiskCatalogApiArg,
  ApiGetRiskCatalogApiResponse,
  ApiGetTenantCatalogsApiArg,
  ApiGetTenantCatalogsApiResponse,
  ApiMoveCatalogElementApiArg,
  ApiMoveCatalogElementApiResponse,
  ApiPostCatalogImportDataApiArg,
  ApiPostCatalogImportDataApiResponse,
  ApiPostCreateCatalogTemplateApiResponse,
  ApiUpdateCatalogApiArg,
  ApiUpdateCatalogApiResponse,
  ApiUpdateCatalogElementApiArg,
  ApiUpdateCatalogElementApiResponse,
} from '../apiGenerated';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { ApiTagTypes } from '../apiTagTypes';

export const enhancedApiCatalogEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  apiGetCatalogTemplates: {
    providesTags: [{ type: ApiTagTypes.CatalogTemplates, id: 'LIST' }],
  },
  apiGetTenantCatalogs: {
    providesTags: (
      _result: ApiGetTenantCatalogsApiResponse,
      _error: FetchBaseQueryError,
      _args: ApiGetTenantCatalogsApiArg,
    ) => [{ type: ApiTagTypes.TenantCatalog, id: 'LIST' }],
  },
  apiGetAvailableCatalogsForMapping: {
    providesTags: (
      _result: ApiGetAvailableCatalogsForMappingApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetAvailableCatalogsForMappingApiArg,
    ) => [{ type: ApiTagTypes.TenantCatalog, id: args.catalogId }],
  },
  apiGetCatalog: {
    providesTags: (result: ApiGetCatalogApiResponse, _error: FetchBaseQueryError, args: ApiGetCatalogApiArg) => {
      const tags = [
        { type: ApiTagTypes.TenantCatalog, id: 'COSTS' },
        { type: ApiTagTypes.TenantCatalog, id: args.id },
      ];

      if (result.costCatalog.mappings.length) {
        result.costCatalog.mappings.forEach((mapping) => {
          tags.push({
            type: ApiTagTypes.TenantCatalog,
            id: mapping.id,
          });
        });
      }

      return tags;
    },
  },
  apiGetRiskCatalog: {
    providesTags: (
      _result: ApiGetRiskCatalogApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetRiskCatalogApiArg,
    ) => [
      { type: ApiTagTypes.TenantCatalog, id: 'RISKS' },
      { type: ApiTagTypes.TenantCatalog, id: args.id },
    ],
  },
  apiGetEarningsCatalog: {
    providesTags: (
      _result: ApiGetEarningsCatalogApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetEarningsCatalogApiArg,
    ) => [
      { type: ApiTagTypes.TenantCatalog, id: 'EARNINGS' },
      { type: ApiTagTypes.TenantCatalog, id: args.id },
    ],
  },
  apiGetFinancingCatalog: {
    providesTags: (
      _result: ApiGetFinancingCatalogApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetFinancingCatalogApiArg,
    ) => [
      { type: ApiTagTypes.TenantCatalog, id: 'FINANCE' },
      { type: ApiTagTypes.TenantCatalog, id: args.id },
    ],
  },
  apiGetCatalogElementCode: {
    providesTags: (
      _result: ApiGetCatalogElementCodeApiResponse,
      _error: FetchBaseQueryError,
      _args: ApiGetCatalogElementCodeApiArg,
    ) => [{ type: ApiTagTypes.TenantCatalog, id: 'ELEMENTCODE' }],
  },
  /*------------------ INVALIDATES TAGS --------------------*/
  apiPostCreateCatalogTemplate: {
    invalidatesTags: (_result: ApiPostCreateCatalogTemplateApiResponse, error: FetchBaseQueryError) => {
      return error == null ? [{ type: ApiTagTypes.CatalogTemplates, id: 'LIST' }] : [];
    },
  },
  apiDeleteCatalogTemplate: {
    invalidatesTags: (_result: ApiDeleteCatalogTemplateApiResponse, error: FetchBaseQueryError) => {
      return error == null ? [{ type: ApiTagTypes.CatalogTemplates, id: 'LIST' }] : [];
    },
  },
  apiPostUpdateCatalogTemplate: {
    invalidatesTags: (_result: ApiDeleteCatalogTemplateApiResponse, error: FetchBaseQueryError) => {
      return error == null ? [{ type: ApiTagTypes.CatalogTemplates, id: 'LIST' }] : [];
    },
  },
  apiCreateCatalog: {
    invalidatesTags: (
      _result: ApiCreateCatalogElementApiResponse,
      error: FetchBaseQueryError,
      _args: ApiCreateCatalogElementApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.TenantCatalog, id: 'LIST' }] : []),
  },
  apiPostCatalogImportData: {
    invalidatesTags: (
      _result: ApiPostCatalogImportDataApiResponse,
      error: FetchBaseQueryError,
      _args: ApiPostCatalogImportDataApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.TenantCatalog, id: 'LIST' }] : []),
  },
  apiDeleteCatalog: {
    invalidatesTags: (
      _result: ApiDeleteCatalogApiResponse,
      error: FetchBaseQueryError,
      _args: ApiDeleteCatalogApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.TenantCatalog, id: 'LIST' }] : []),
  },
  apiUpdateCatalog: {
    invalidatesTags: (
      _result: ApiUpdateCatalogApiResponse,
      error: FetchBaseQueryError,
      _args: ApiUpdateCatalogApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.TenantCatalog, id: 'LIST' }] : []),
  },
  apiDeleteCatalogElement: {
    invalidatesTags: (
      _result: ApiDeleteCatalogElementApiResponse,
      _error: FetchBaseQueryError,
      args: ApiDeleteCatalogElementApiArg,
    ) => {
      switch (args.body.type) {
        case 'Costs':
          return [{ type: ApiTagTypes.TenantCatalog, id: 'COSTS' }];
        case 'Risks':
          return [{ type: ApiTagTypes.TenantCatalog, id: 'RISKS' }];
        case 'Earnings':
          return [{ type: ApiTagTypes.TenantCatalog, id: 'EARNINGS' }];
        case 'Finance':
          return [{ type: ApiTagTypes.TenantCatalog, id: 'FINANCE' }];
      }
      return [];
    },
  },
  apiUpdateCatalogElement: {
    invalidatesTags: (
      _result: ApiUpdateCatalogElementApiResponse,
      _error: FetchBaseQueryError,
      args: ApiUpdateCatalogElementApiArg,
    ) => {
      switch (args.body.type) {
        case 'Costs':
          return [{ type: ApiTagTypes.TenantCatalog, id: 'COSTS' }];
        case 'Risks':
          return [{ type: ApiTagTypes.TenantCatalog, id: 'RISKS' }];
        case 'Earnings':
          return [{ type: ApiTagTypes.TenantCatalog, id: 'EARNINGS' }];
        case 'Finance':
          return [{ type: ApiTagTypes.TenantCatalog, id: 'FINANCE' }];
      }
      return [];
    },
  },
  apiCreateCatalogElement: {
    invalidatesTags: (
      _result: ApiCreateCatalogElementApiResponse,
      _error: FetchBaseQueryError,
      args: ApiCreateCatalogElementApiArg,
    ) => {
      switch (args.body.type) {
        case 'Costs':
          return [
            { type: ApiTagTypes.TenantCatalog, id: 'COSTS' },
            { type: ApiTagTypes.TenantCatalog, id: 'ELEMENTCODE' },
          ];
        case 'Risks':
          return [
            { type: ApiTagTypes.TenantCatalog, id: 'RISKS' },
            { type: ApiTagTypes.TenantCatalog, id: 'ELEMENTCODE' },
          ];
        case 'Earnings':
          return [
            { type: ApiTagTypes.TenantCatalog, id: 'EARNINGS' },
            { type: ApiTagTypes.TenantCatalog, id: 'ELEMENTCODE' },
          ];
        case 'Finance':
          return [
            { type: ApiTagTypes.TenantCatalog, id: 'FINANCE' },
            { type: ApiTagTypes.TenantCatalog, id: 'ELEMENTCODE' },
          ];
      }
      return [];
    },
  },
  apiMoveCatalogElement: {
    invalidatesTags: (
      _result: ApiMoveCatalogElementApiResponse,
      _error: FetchBaseQueryError,
      args: ApiMoveCatalogElementApiArg,
    ) => {
      switch (args.body.type) {
        case 'Costs':
          return [
            { type: ApiTagTypes.TenantCatalog, id: 'COSTS' },
            { type: ApiTagTypes.TenantCatalog, id: 'ELEMENTCODE' },
          ];
        case 'Risks':
          return [
            { type: ApiTagTypes.TenantCatalog, id: 'RISKS' },
            { type: ApiTagTypes.TenantCatalog, id: 'ELEMENTCODE' },
          ];
        case 'Earnings':
          return [
            { type: ApiTagTypes.TenantCatalog, id: 'EARNINGS' },
            { type: ApiTagTypes.TenantCatalog, id: 'ELEMENTCODE' },
          ];
        case 'Finance':
          return [
            { type: ApiTagTypes.TenantCatalog, id: 'FINANCE' },
            { type: ApiTagTypes.TenantCatalog, id: 'ELEMENTCODE' },
          ];
      }
      return [];
    },
  },
  apiCreateCatalogMapping: {
    invalidatesTags: (
      _result: ApiCreateCatalogMappingApiResponse,
      error: FetchBaseQueryError,
      args: ApiCreateCatalogMappingApiArg,
    ) => {
      return error == null ? [{ type: ApiTagTypes.TenantCatalog, id: args.body.sourceCatalogId }] : [];
    },
  },
  apiDeleteCatalogMapping: {
    invalidatesTags: (
      _result: ApiDeleteCatalogMappingApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteCatalogMappingApiArg,
    ) => {
      return error == null ? [{ type: ApiTagTypes.TenantCatalog, id: args.body.catalogId }] : [];
    },
  },
  apiCreateCatalogElementMapping: {
    invalidatesTags: (
      _result: ApiCreateCatalogElementMappingApiResponse,
      error: FetchBaseQueryError,
      args: ApiCreateCatalogElementMappingApiArg,
    ) => {
      return error == null ? [{ type: ApiTagTypes.TenantCatalog, id: args.body.mappingId }] : [];
    },
  },
  apiDeleteCatalogElementMapping: {
    invalidatesTags: (
      _result: ApiDeleteCatalogElementMappingApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteCatalogElementMappingApiArg,
    ) => {
      return error == null ? [{ type: ApiTagTypes.TenantCatalog, id: args.body.mappingId }] : [];
    },
  },
};
