import * as yup from 'yup';
import { InferType } from 'yup';

export const ChangePasswordFormValidationSchema = yup.object({
  currentPassword: yup.string().when('hasIdp', {
    is: (currentPassword: string) => currentPassword !== '',
    then: (schema) => schema.optional().nullable(),
    otherwise: (schema) => schema.required('validation.required')
  }),
  newPassword: yup.string().required('validation.required').matches(
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*_-]).{8,}$/,
    'validation.passwordStrength'
  ),
  newPasswordConfirmation: yup.string().test('passwords-match', 'validation.passwordMatch', function (value) {
    return this.parent.newPassword === value;
  }),
  hasIdp: yup.boolean().optional().nullable()
});

export type ChangePasswordFormValidationValues = InferType<typeof ChangePasswordFormValidationSchema>;
