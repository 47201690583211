import { getUnitLabel } from './getUnitLabel';

export const METRIC_COST_UNITS = ['lumpsum', 'm²', 'piece', '%', 'm', 'm³', 'kg', 'ton', 'liter', 'hour', 'container'];
export const IMPERIAL_COST_UNITS = ['lumpsum', 'ft²', 'piece', '%', 'ft', 'ft³', 'lb', 'st', 'gal', 'hour', 'container'];

export const getMetricImperialUnitOptions = (type: 'metric' | 'imperial') => {
  return METRIC_COST_UNITS.map((metricUnit, i) => {
    return {
      label: `${getUnitLabel(metricUnit)} / ${getUnitLabel(IMPERIAL_COST_UNITS[i] ?? 'n.a.')}`,
      value: type === 'metric' ? metricUnit : IMPERIAL_COST_UNITS[i] ?? 'n.a.'
    }
  });
}
