import { CalculationModelRiskGroupReadModel, CalculationModelRiskElementReadModel } from '@client/shared/api';
import { isChildRiskElement } from '.';
import { findRisksElement } from '@client/project/shared';

export const getFilteredRisksTotal = (
  flattenedRiskElements: (CalculationModelRiskGroupReadModel | CalculationModelRiskElementReadModel)[],
  currentSearchResult: string[] | null,
): number => {
  if (!currentSearchResult) return 0;
  const filteredRiskElements = currentSearchResult
    ?.map((id) => findRisksElement(id, flattenedRiskElements))
    .filter((element) => element);

  const topLevelElements = filteredRiskElements?.filter((possibleChildElement) => {
    return !filteredRiskElements?.some((parentElement) => {
      return parentElement && possibleChildElement ? isChildRiskElement(parentElement, possibleChildElement) : false;
    });
  });

  return (
    topLevelElements.reduce((acc, element) => {
      if (element && 'total' in element) {
        return acc + (element.total ?? 0);
      } else if (element && 'effectiveValue' in element) {
        return acc + (element.effectiveValue ?? 0);
      }
      return acc;
    }, 0) ?? 0
  );
};
