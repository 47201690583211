import { FormattedCurrency, FormulaEditorBenchmarkSelectableFilter } from '.';
import { CheckBox, Highlighted, InfoIcon, NumberInput, Tooltip, useComponentDimensions } from '@client/shared/toolkit';
import { countryCodes, formatDate } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';
import { BenchmarkingFilterProject } from '../utils';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';

interface BenchmarkingProjectSelectProps {
  benchmarkProjects: BenchmarkingFilterProject[];
  selectedFilters: FormulaEditorBenchmarkSelectableFilter;
  selectedElements: BenchmarkingFilterProject[];
  setSelectedElements: (elements: BenchmarkingFilterProject[]) => void;
  showCalculation?: boolean;
  setCalculatedAverage?: (value: number | undefined) => void;
}

const BENCHMARKING_PROJECT_PAGE_SIZE = 10;
const BENCHMARKING_PROJECT_ROW_HEIGHT = 72;

export const BenchmarkingProjectSelect = ({
  benchmarkProjects,
  selectedFilters,
  selectedElements,
  setSelectedElements,
  showCalculation = true,
  setCalculatedAverage,
}: BenchmarkingProjectSelectProps) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const { width } = useComponentDimensions(containerRef);
  const [page, setPage] = React.useState<number>(1);
  const [customFactors, setCustomFactors] = useState<{ [key: string]: number }>({});

  const filteredBenchmarkProjects = useMemo(() => {
    return benchmarkProjects.filter((project) => {
      const matchesAssetClasses =
        selectedFilters.assetClasses.length === 0 ||
        project.assetClasses.some((assetClass) => selectedFilters.assetClasses.includes(assetClass.id));

      const matchesStartDate = !selectedFilters.start || new Date(project.start) >= selectedFilters.start;

      const matchesEndDate = !selectedFilters.end || new Date(project.end) <= selectedFilters.end;

      const searchInputs = selectedFilters.searchInput.split(',').map((input) => input.trim().toLowerCase());
      const matchesSearchInput = searchInputs.some(
        (input) =>
          project.name.toLowerCase().includes(input) ||
          (project.countryCode &&
            (countryCodes.getCountryName(project.countryCode) ?? project.countryCode).toLowerCase().includes(input)) ||
          project?.city?.toLowerCase().includes(input),
      );

      return matchesAssetClasses && matchesStartDate && matchesEndDate && matchesSearchInput;
    }) as BenchmarkingFilterProject[];
  }, [benchmarkProjects, selectedFilters]);

  const calculatedAverage = useMemo(() => {
    const newCalculatedAverage = selectedElements.length === 1
      ? (selectedElements[0].calculatedUnitValue ?? 0) * (customFactors[selectedElements[0].id] ?? 1)
      : selectedElements.reduce((acc, project) => acc + ((project.calculatedUnitValue ?? 0) * (customFactors[project.id] ?? 1)), 0) /
          selectedElements.length || 0;
    return parseFloat(newCalculatedAverage?.toFixed(2) ?? '0');
  }, [selectedElements, customFactors]);

  useEffect(() => {
    if (setCalculatedAverage) {
      setCalculatedAverage(calculatedAverage);
    }
  }, [calculatedAverage, setCalculatedAverage]);

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    const position = Math.ceil((scrollTop / (scrollHeight - clientHeight)) * BENCHMARKING_PROJECT_ROW_HEIGHT);
    if (position === BENCHMARKING_PROJECT_ROW_HEIGHT) {
      setPage(page + 1);
    }
  };

  const scrollBarWidth = useMemo(() => {
    return (containerRef.current?.offsetWidth ?? 0) - (containerRef.current?.clientWidth ?? 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  useEffect(() => {
    setCustomFactors(filteredBenchmarkProjects.reduce((acc, item) => {
      return { ...acc, [item.id]: 1 };
    }, {}));
  }, [filteredBenchmarkProjects]);

  return (
    <>
      {showCalculation && filteredBenchmarkProjects.length > 0 && (
        <div className="w-full py-2 bg-gray-100 text-xs text-gray-500 flex" style={{ paddingRight: scrollBarWidth }}>
          <div className="px-2 w-10 flex-none" />
          <div className="px-2 w-1/4 flex-none">{t('reporting.benchmarking.projects')}</div>
          <div className="grid grid-cols-3 flex-1 items-stretch">
            <div className="px-2 flex items-center place-content-end">{t('reporting.benchmarking.forecast')}</div>
            <div className="px-2 flex items-center place-content-end">
              {t('projectCalculate.benchmarkingFactor')}
            </div>
            <div className="px-2 pr-2 flex items-center place-content-end">
              {t('reporting.benchmarking.adjustedRate')}
            </div>
          </div>
        </div>
      )}
      <div
        className={cn('flex flex-col gap-0.5 overflow-y-scroll', {
          'mt-2 max-h-[calc(100%_-_65px)]': !showCalculation,
          'max-h-[calc(100%_-_148px)]': showCalculation
        })}
        onScroll={handleScroll}
        ref={containerRef}
      >
        {filteredBenchmarkProjects.map((element: BenchmarkingFilterProject, i) => {
          if (i > page * BENCHMARKING_PROJECT_PAGE_SIZE) {
            return null;
          }
          return (
            <div className="w-full bg-white flex divide-x font-medium text-gray-900 items-stretch" key={element.id}>
              <div className="p-2 flex items-center justify-center w-10 flex-none">
                <CheckBox
                  className="cursor-pointer"
                  checked={selectedElements.some((project) => project.id === element.id)}
                  onChange={(checked) => {
                    setSelectedElements(
                      checked
                        ? [...selectedElements, element]
                        : selectedElements.filter((project: BenchmarkingFilterProject) => project.id !== element.id),
                    );
                  }}
                />
              </div>
              <div className="p-2 w-1/4 flex-none overflow-hidden flex flex-col justify-center gap-0.5">
                <div>
                  <Highlighted text={element.name} highlight={selectedFilters.searchInput} />
                </div>
                <div className="text-xs text-slate-700">
                  <Highlighted text={element.city ?? ''} highlight={selectedFilters.searchInput} />
                  {' - '}
                  <Highlighted
                    text={
                      element.countryCode ? countryCodes.getCountryName(element.countryCode) ?? element.countryCode : ''
                    }
                    highlight={selectedFilters.searchInput}
                  />
                </div>
                <div className="text-xs text-slate-700 flex gap-2">
                  {`${formatDate(new Date(element.start))} - ${formatDate(new Date(element.end))}`}
                </div>
              </div>
              {showCalculation && (
                <div className="grid grid-cols-3 flex-1 divide-x items-stretch">
                  <div className="p-2 flex items-center place-content-end">
                    <FormattedCurrency amount={element.forecastValue} />
                  </div>
                  <div className="p-2 flex items-center place-content-end">
                    <NumberInput
                      label={t('projectCalculate.benchmarkingFactor')}
                      value={customFactors[element.id] ?? 1}
                      onChange={(val) => {
                        const copy = {...customFactors};
                        copy[element.id] = val ?? 1;
                        setCustomFactors(copy);
                      }}
                      textRight
                    />
                  </div>
                  <div className="p-2 flex items-center place-content-end">
                    <Tooltip
                      label={
                        <div className="flex gap-1 items-center">
                          <InfoIcon className="w-4 h-4 flex-none text-gray-500 cursor-pointer" />
                          <FormattedCurrency amount={(element.calculatedUnitValue ?? 0) * (customFactors[element.id] ?? 1)} />
                        </div>
                      }
                    >
                      <div className="px-2">
                        <div className="grid grid-cols-2 gap-x-2 gap-y-1 text-xs">
                          <div className="font-bold">{t('reporting.benchmarking.forecast')}</div>
                          <div className="text-right">
                            <FormattedCurrency amount={element.forecastValue} />
                          </div>
                          <div className="font-bold">{t('reporting.benchmarking.costIndex')}</div>
                          <div className="text-right">{element.index}</div>
                          <div className="font-bold">{t('projectCalculate.benchmarkingRegionalFactor')}</div>
                          <div className="text-right">{element.regionalFactor}</div>
                          <div className="font-bold">{t('reporting.benchmarking.currencyConversionFactor')}</div>
                          <div className="text-right">{element.currencyConversionFactor}</div>
                          <div className="font-bold">{t('reporting.benchmarking.benchmarkPropertyValue')}</div>
                          <div className="text-right">{element.benchmarkPropertyValue}</div>
                          <div className="font-bold">{t('reporting.benchmarking.unitConversionFactor')}</div>
                          <div className="text-right">{element.unitConversionFactor}</div>
                          <div className="font-bold">{t('projectCalculate.benchmarkingFactor')}</div>
                          <div className="text-right">{customFactors[element.id] ?? 1}</div>
                        </div>
                        <div className="border-t text-xs mt-2 pt-2 grid grid-cols-2 gap-x-2">
                          <div>
                            (<FormattedCurrency amount={element.forecastValue} /> * {element.index} *{' '}
                            {element.regionalFactor} * {element.currencyConversionFactor} /{' '}
                            {element.benchmarkPropertyValue} * {element.unitConversionFactor}) * {customFactors[element.id] ?? 1}
                          </div>
                          <div className="text-right font-bold">
                            <FormattedCurrency amount={(element.calculatedUnitValue ?? 0) * (customFactors[element.id] ?? 1)} />
                          </div>
                        </div>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
      {showCalculation && filteredBenchmarkProjects.length > 0 && (
        <div
          className="flex flex-col w-full items-end pt-2 bg-gray-100 pr-2"
          style={{ paddingRight: scrollBarWidth + 8 }}
        >
          <div className="font-medium text-gray-900">
            <FormattedCurrency amount={calculatedAverage} />
          </div>
          <div className="text-xs">{t('projectCalculate.benchmarkingAverage')}</div>
        </div>
      )}
    </>
  );
};
