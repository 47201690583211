import React, { PropsWithChildren, ReactElement, ReactNode, useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useApiGetProjectTemplateQuery } from '@client/shared/api';
import { Button, CloseIcon, LoadingIndicator } from '@client/shared/toolkit';
import { useDispatch } from 'react-redux';
import { setProjectId } from '@client/project/store';

export interface TemplateNavItem {
  icon: ReactNode;
  label: string;
  path: string;
  disabled?: boolean;
}

export interface ProjectTemplateProps extends PropsWithChildren {
  navItems: TemplateNavItem[];
  backUrl: string;
  handleClose?: () => void;
}

export const ProjectTemplate = (props: ProjectTemplateProps) => {
  const { navItems, backUrl, children, handleClose } = props;
  const { t } = useTranslation();
  const { templateId } = useParams();
  const dispatch = useDispatch();

  const { data: template, isFetching } = useApiGetProjectTemplateQuery(
    { templateId: templateId ?? '' },
    { skip: !templateId, refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    dispatch(
      setProjectId({
        projectId: '409ac524-85d4-49c2-896a-610971b3dd3a', // templateId
        variantId: 'c8fc9719-0efa-4314-9d41-394781decfcc',
        isTemplate: true
      })
    );
  }, [dispatch]);

  return (
    <div className="flex flex-col h-full w-full">
      <div className="bg-white shadow-lg lg:px-4 flex justify-between relative z-20">
        <div className="flex flex-col justify-center py-2">
          <h1 className="text-xl font-bold truncate">{template?.template.name}</h1>
          <h2 className="text-xs text-gray-500">{t('templates.projectTemplate.title')}</h2>
        </div>
        <div className="self-end">
          {isFetching && <LoadingIndicator mode="overlay-window" />}
          <ul className="w-full items-center justify-end flex min-w-fit h-full pt-2">
            {navItems.map((navItem, i) => (
              <li key={`nav-item-${i}`} className="flex flex-none flex-col h-full min-w-[80px]">
                {navItem.disabled ? (
                  <div className="text-slate-300 border-transparent px-3 h-full font-normal border-b-4 flex flex-col items-center justify-center text-[13px] gap-1">
                    {React.cloneElement(navItem.icon as ReactElement, { className: 'w-8 h-8' })}
                    <div className="truncate w-full text-center whitespace-normal">{navItem.label}</div>
                  </div>
                ) : (
                  <NavLink
                    to={navItem.path}
                    className={({ isActive }) =>
                      cn(
                        'border-b-4 px-3 h-full flex flex-col items-center justify-center text-[13px] gap-1',
                        isActive ? 'border-secondary' : 'border-transparent',
                      )
                    }
                  >
                    {React.cloneElement(navItem.icon as ReactElement, { className: 'w-8 h-8' })}
                    <div className="truncate w-full text-center whitespace-normal">{navItem.label}</div>
                  </NavLink>
                )}
              </li>
            ))}
            <li className="flex flex-none flex-col h-full px-4">
              {handleClose ? (
                <Button variant="custom" onClick={handleClose} hasPadding={false}>
                  <CloseIcon className="w-5" />
                </Button>
              ) : (
                <NavLink to={backUrl}>
                  <CloseIcon className="w-5" />
                </NavLink>
              )}
            </li>
          </ul>
        </div>
      </div>
      <div className="flex flex-grow flex-col overflow-x-hidden relative bg-slate-50">{children}</div>
    </div>
  );
};
