import { apiEndpointUrls, ContractReadModel } from '@client/shared/api';
import {
  Card,
  CardDataSet,
  ContractIcon,
  CardPaymentCustomIcon,
  HintBox,
  PencilIcon,
  CardActionButton, AddressCard, UploadBadge,
  Badge,
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import { getTenantTrustBadgeByStatus, getTenantTrustBadgeLabelByStatus } from '../../utils';
import { useLoadedProjectId } from '@client/project/store';
import { formatDate } from '@client/shared/utilities';

export interface ContractDetailsProps {
  contract: ContractReadModel;
  handleEditButtonClick: (value: string) => void;
}

export const ContractDetails = ({ contract, handleEditButtonClick }: ContractDetailsProps) => {
  const { t } = useTranslation()

  const loadedProjectId = useLoadedProjectId()

  const tenantTrustBadge = useMemo(() => {
    if (contract?.contractor?.projectAssignments?.length && loadedProjectId) {
      const foundAssignment = contract?.contractor.projectAssignments.find((assignment) => assignment.project.projectId === loadedProjectId)
      if (foundAssignment && foundAssignment.tenantTrust) {
        return (
          <UploadBadge
            className="absolute left-0 -top-4"
            variant={getTenantTrustBadgeByStatus(
              foundAssignment.tenantTrust.trustState,
              foundAssignment.tenantTrust.trustIsActive,
            )}
            tooltip={getTenantTrustBadgeLabelByStatus(
              foundAssignment.tenantTrust.trustState,
              foundAssignment.tenantTrust.trustIsActive,
            )}
            size="small"
          />
        )
      }
    }
    return null
  }, [loadedProjectId, contract?.contractor?.projectAssignments])

  return (
    <div className="flex flex-col gap-6">
      {contract.isPxContract && <HintBox hintType="info">{t('projectContract.warningSynchronizedContract')}</HintBox>}
      <div className="flex flex-wrap md:flex-nowrap gap-6">
        <AddressCard
          customName={tenantTrustBadge ? (
            <div className="font-bold text-lg leading-tight mb-1.5">
              {contract.contractor?.name
                ? contract.contractor?.companyName && contract.contractor?.companyName !== contract.contractor?.name
                  ? `${contract.contractor?.companyName} - ${contract.contractor?.name}`
                  : contract.contractor?.name
                : '-'}
              <div className="flex-none inline-flex ml-2 relative">{tenantTrustBadge}</div>
            </div>
          ) : undefined}
          className="md:w-1/3 relative"
          address={contract.contractor?.address ?? undefined}
          companyName={contract.contractor?.companyName}
          name={contract.contractor?.name}
          logo={
            contract.contractor?.logoImageId
              ? apiEndpointUrls.apiGetCompanyLogo.replace(':companyId', contract.contractor?.companyId).replace(':fileId', contract.contractor?.logoImageId)
              : undefined
          }
          handleEditButtonClick={!contract.isPxContract ? () => handleEditButtonClick('contractor') : undefined}
          label={t('projectContract.contractor')}
        />
        <Card className="relative md:w-1/3">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4">
            <CardDataSet label={t('projectContract.contractCode')}>
              <span className="font-bold text-lg">{contract.code}</span>
            </CardDataSet>
            <CardDataSet className='items-start' label={t('projectContract.contractState')}>
              {contract.state === 'Pending' &&
                <Badge className='mt-1' text={t('projectContract.pending')} variant='lightInfo'/>
              }
              {contract.state === 'Settled' &&
                <Badge className='mt-1' text={t('projectContract.settled')} variant='success'/>
              }
              {contract.state === 'Paid' &&
                <Badge className='mt-1' text={t('projectContract.paid')} variant='info'/>
              }
            </CardDataSet>
            <CardDataSet label={t('projectContract.contractDate')}>
              <span className="font-bold text-lg">{contract.contractDate ? formatDate(contract.contractDate) : '-'}</span>
            </CardDataSet>
            <CardDataSet/>
            <CardDataSet label={t('projectContract.constructionPhases')}>
              <span>-</span>
            </CardDataSet>
            <CardDataSet label={t('projectContract.commitment')}>
              <span className='font-bold leading-tight'>{contract.commitmentName ? contract.commitmentName : '-'}</span>
            </CardDataSet>
            {!contract.isPxContract && (
              <CardActionButton icon={<PencilIcon />} onClick={() => handleEditButtonClick('code')} />
            )}
          </div>
        </Card>
        <AddressCard
          className="md:w-1/3 relative"
          address={contract.client?.address ?? undefined}
          companyName={contract.client?.companyName}
          name={contract.client?.name}
          logo={
            contract.client?.logoImageId
              ? apiEndpointUrls.apiGetCompanyLogo.replace(':companyId', contract.client?.companyId).replace(':fileId', contract.client?.logoImageId)
              : undefined
          }
          handleEditButtonClick={!contract.isPxContract ? () => handleEditButtonClick('client') : undefined}
          label={t('projectContract.contractClient')}
        />
      </div>
      <div className="flex flex-wrap md:flex-nowrap gap-6">
        <Card className="relative w-2/3">
          <div className="flex gap-4 h-full">
            <ContractIcon className="w-[55px] flex-none mt-4" />
            <div className="w-5/6">
              <p className="font-bold text-[32px] leading-tight truncate">{contract.name}</p>
              <p className="mt-0.5 text-gray-500 text-[15px] truncate">{contract.description ? contract.description : '-'}</p>
            </div>
            {!contract.isPxContract && (
              <CardActionButton icon={<PencilIcon />} onClick={() => handleEditButtonClick('name')} />
            )}
          </div>
        </Card>
        <Card className="relative w-1/3">
          <div className="flex gap-5">
            <CardPaymentCustomIcon className="w-[57px] flex-none mt-4" />
            <div className="flex flex-col gap-4">
              <CardDataSet label={t('projectContract.costCentre')}>
                <span className="font-bold text-lg">-</span>
              </CardDataSet>
              <CardDataSet label={t('projectContract.bookingAccount')} text="-" />
            </div>
            {!contract.isPxContract && (
              <CardActionButton icon={<PencilIcon />} onClick={() => handleEditButtonClick('budget')} />
            )}
          </div>
        </Card>
      </div>
    </div>
  );
};
