import { getCompanyBranches, useLoadedProject } from '@client/project/store';
import {
  SpvReadModel,
  useApiGetProjectSelectCompanyBranchesQuery,
  useApiPostCreateProjectSpvMutation,
} from '@client/shared/api';
import {
  BooleanInput,
  Button,
  ComboSelect,
  EditNodeIcon,
  Form,
  FormField,
  FormHelperText,
  LoadingIndicator,
  Modal,
  NumberInput,
  PercentageIcon,
} from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SpvBranchFormValidationSchema, SpvBranchFormValidationValues } from '.';

interface SpvModalProps {
  isOpen: boolean;
  onClose: (id?: string) => void;
  projectId: string | undefined;
  projectSpvs?: SpvReadModel[];
}

export const SpvModal = ({ isOpen, onClose, projectId, projectSpvs }: SpvModalProps) => {
  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement>(null);

  const project = useLoadedProject();

  const [submitted, setSubmitted] = useState(false);
  const [areSharesValid, setAreSharesValid] = useState(true);

  const handleOnClose = (branchId?: string) => {
    onClose(branchId);
    setSubmitted(false);
    setAreSharesValid(true);
  };

  const existingSpvs = projectSpvs ? projectSpvs : project.data?.project.payload.spvs;

  const { data: loadedBranches, isFetching: isLoadingBranches } = useApiGetProjectSelectCompanyBranchesQuery(
    {
      projectId: projectId ?? '',
    },
    {
      skip: !projectId,
    },
  );

  const [createSpv, { isLoading: isCreatingSpv }] = useApiPostCreateProjectSpvMutation();

  const availableShares = useMemo(() => {
    return existingSpvs?.reduce((acc, spv) => acc - spv.share, 100) ?? 0;
  }, [existingSpvs]);

  const branchOptions = useMemo(() => {
    const filteredBranches = loadedBranches?.filter((branch) => {
      return !existingSpvs?.some((spv) => spv.companyBranchId === branch.id);
    });
    return getCompanyBranches(filteredBranches);
  }, [existingSpvs, loadedBranches]);

  const handleSubmit = async (data: SpvBranchFormValidationValues) => {
    if (projectId && areSharesValid) {
      try {
        await safeMutation(
          createSpv,
          {
            projectId: projectId,
            body: {
              companyBranchId: data.companyBranchId,
              share: data.share,
              isDefault: data.isDefault,
            },
          },
          isCreatingSpv,
        );
        handleOnClose(data.companyBranchId);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const defaultFormValues = {
    companyBranchId: '',
    share: 0,
    isDefault: existingSpvs?.length === 0,
  };

  return (
    <Modal isOpen={isOpen} onClose={() => handleOnClose()} variant="custom" className="w-[360px] h-[480px]">
      <Form<SpvBranchFormValidationValues>
        ref={formRef}
        onSubmit={handleSubmit}
        validationSchema={SpvBranchFormValidationSchema}
        defaultValues={defaultFormValues}
        className="w-full flex flex-col h-full"
      >
        <>
          <Modal.Header title={t('projectSetting.addSpv')} description={t('projectSetting.addSpvDescription')} />
          <Modal.Content className="pb-3 divide-y">
            {(isLoadingBranches || isCreatingSpv) && (
              <LoadingIndicator text={t(isLoadingBranches ? 'common.loading' : 'common.saving')} mode="overlay" />
            )}
            <FormField name="companyBranchId">
              {(control) => (
                <ComboSelect
                  icon={<EditNodeIcon />}
                  label={t('projectSetting.branch')}
                  options={branchOptions}
                  {...control}
                />
              )}
            </FormField>
            <FormField name="share">
              {(control) => (
                <>
                  <NumberInput
                    label={t('projectSetting.spvShare')}
                    icon={<PercentageIcon />}
                    min={0}
                    max={100}
                    {...control}
                    onChange={(value) => {
                      setAreSharesValid((value ?? 0) <= availableShares);
                      control.onChange(value);
                    }}
                    isValidationValid={areSharesValid && control.isValidationValid}
                    showValidation={submitted && (!areSharesValid || control.showValidation)}
                  />
                  {submitted && !areSharesValid && (
                    <FormHelperText
                      text={t('projectSetting.spvShareValidation', { share: availableShares })}
                      error={!areSharesValid}
                      className={'w-full bg-white'}
                      truncate={false}
                    />
                  )}
                </>
              )}
            </FormField>

            <FormField name="isDefault">
              {(control) => <BooleanInput label={t('projectSetting.spvDefault')} variant="switch" {...control} />}
            </FormField>
          </Modal.Content>
          <Modal.Controls className="bg-white">
            <Button onClick={() => handleOnClose()} variant="secondary">
              {t('common.cancel')}
            </Button>
            <Button
              onClick={() => {
                setSubmitted(true);
                formRef.current?.submitForm();
              }}
              variant="primary"
            >
              {t('common.save')}
            </Button>
          </Modal.Controls>
        </>
      </Form>
    </Modal>
  );
};
