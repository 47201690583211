import {
  BaseSelect,
  Button,
  Form,
  FormField,
  FormRefHandle,
  LoadingIndicator,
  SlideOver,
  SlideOverOnCloseProps,
  TagWindowIcon,
  TextInput,
} from '@client/shared/toolkit';
import React, { useMemo, useRef, useState } from 'react';
import {
  CustomBenchmarkPropertyPayload,
  CustomBenchmarkPropertyReadModel,
  DefaultValuesReadModel,
  useApiPostUpdateDefaultValuesMutation,
} from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { InferType } from 'yup';
import cn from 'classnames';
import { getMetricImperialUnitOptions } from '@client/project/shared';
import { safeMutation } from '@client/shared/utilities';
import { getBenchmarkPropertyCode } from '../../catalogs';

export const EditReferenceUnitDefaultFormValidationSchema = yup.object({
  customPropertyId: yup.string().optional().nullable(),
  code: yup.string().required('validation.required'),
  description: yup.string().required('validation.required'),
  metricUnit: yup.string().required('validation.required'),
  imperialUnit: yup.string().required('validation.required'),
});
export type EditReferenceUnitDefaultFormValidationValues = InferType<
  typeof EditReferenceUnitDefaultFormValidationSchema
>;

interface DefaultValuesReferenceUnitEditSlideOverProps extends SlideOverOnCloseProps {
  defaultValues?: DefaultValuesReadModel;
  referenceUnit: CustomBenchmarkPropertyReadModel | null;
}

export const getCustomBenchmarkPropertiesPayload = (
  customBenchmarkProperties: CustomBenchmarkPropertyReadModel[],
): CustomBenchmarkPropertyPayload[] => {
  return customBenchmarkProperties.map((refUnit) => {
    return {
      customPropertyId: refUnit.id,
      code: refUnit.code,
      description: refUnit.description,
      imperialUnit: refUnit.imperialUnit,
      metricUnit: refUnit.metricUnit,
    } as CustomBenchmarkPropertyPayload;
  });
};

export const DefaultValuesReferenceUnitEditSlideOver = (props: DefaultValuesReferenceUnitEditSlideOverProps) => {
  const { defaultValues, referenceUnit, onClose } = props;
  const { t } = useTranslation();

  const formRef = useRef<FormRefHandle<EditReferenceUnitDefaultFormValidationValues>>();

  const [updateDefaultValues, { isLoading: isUpdating }] = useApiPostUpdateDefaultValuesMutation();
  const [codeExists, setCodeExists] = useState(false);

  const handleFormSubmit = async (data: EditReferenceUnitDefaultFormValidationValues, deleteItem = false) => {
    if (defaultValues) {
      const customBenchmarkProperties = getCustomBenchmarkPropertiesPayload(defaultValues.customBenchmarkProperties);
      if (data.customPropertyId) {
        const foundIndex = customBenchmarkProperties.findIndex(
          (refUnit) => refUnit.customPropertyId === data.customPropertyId,
        );
        if (foundIndex >= 0) {
          if (deleteItem) {
            // Delete
            customBenchmarkProperties.splice(foundIndex, 1);
          } else {
            // Update
            customBenchmarkProperties[foundIndex] = data;
          }
        }
      } else {
        // Create
        customBenchmarkProperties.push(data);
      }

      console.log(customBenchmarkProperties, 'customBenchmarkProperties')

      try {
        await safeMutation(
          updateDefaultValues,
          {
            defaultValuesId: defaultValues.id,
            body: {
              dueDateDeadline: defaultValues.dueDateDeadline,
              cashDiscountDeadline: defaultValues.cashDiscountDeadline,
              currency: defaultValues.currency,
              valueType: defaultValues.valueType,
              unitSystem: defaultValues.unitSystem,
              constructionCostIncreases: defaultValues.constructionCostIncreases,
              rentalIncreases: defaultValues.rentalIncreases,
              salesIncreases: defaultValues.salesIncreases,
              currencyConversions: defaultValues.currencyConversions,
              customBenchmarkProperties: customBenchmarkProperties,
            },
          },
          isUpdating,
        );
        onClose(false);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleDelete = async () => {
    if (formRef?.current?.getValues()) {
      await handleFormSubmit(formRef?.current?.getValues(), true);
    }
  };

  const defaultFormValues = {
    customPropertyId: referenceUnit?.id ?? undefined,
    code: referenceUnit?.code ?? '',
    description: referenceUnit?.description ?? '',
    metricUnit: referenceUnit?.metricUnit ?? '',
    imperialUnit: referenceUnit?.imperialUnit ?? '',
  };

  const metricImperialUnitOptions = useMemo(() => {
    return getMetricImperialUnitOptions('metric');
  }, []);

  const imperialUnitOptions = useMemo(() => {
    return getMetricImperialUnitOptions('imperial');
  }, []);

  return (
    <>
      <SlideOver.Header
        onClose={() => onClose(false)}
        title={`${t('projectSetting.referenceUnits.title')} - ${t('app.masterDataDefaultValues.title')}`}
        backgroundClassName="bg-sky-900"
      />
      <Form<EditReferenceUnitDefaultFormValidationValues>
        onSubmit={(data) => handleFormSubmit(data, false)}
        validationSchema={EditReferenceUnitDefaultFormValidationSchema}
        defaultValues={defaultFormValues}
        className="w-full flex flex-col justify-between h-full"
        ref={formRef}
      >
        {isUpdating && <LoadingIndicator mode="overlay" />}
        <SlideOver.Content className="p-8 flex flex-col gap-0.5">
          <FormField name="code">
            {(control) => (
              <TextInput
                icon={<TagWindowIcon />}
                label={t('projectSetting.referenceUnits.code')}
                {...control}
                showValidation={(control.value && codeExists) || control.showValidation}
                isValidationValid={(!control.value || (control.value && !codeExists)) && control.isValidationValid}
                helperText={control.value && codeExists ? t('projectSetting.referenceUnits.codeAlreadyExists') : control.helperText ?? undefined}
                onChange={(val) => {
                  let exists = defaultValues?.defaultBenchmarkProperties?.find((refUnit) => getBenchmarkPropertyCode(refUnit) === val || refUnit.property === val);
                  if (!exists) {
                    exists = defaultValues?.customBenchmarkProperties?.find((refUnit) => refUnit.code === val && refUnit.id !== referenceUnit?.id);
                  }
                  setCodeExists(!!exists);
                  control.onChange(val);
                }}
              />
            )}
          </FormField>
          <FormField name="description">
            {(control) => (
              <TextInput icon={<TagWindowIcon />} label={t('projectSetting.referenceUnits.name')} {...control} />
            )}
          </FormField>
          {/* we only show one select and then select the imperial based on the selected metric unit */}
          <FormField name="metricUnit">
            {(control) => (
              <BaseSelect
                label={t('projectSetting.referenceUnits.unit')}
                options={metricImperialUnitOptions}
                {...control}
                onChange={(val) => {
                  const foundIndex = metricImperialUnitOptions.findIndex((option) => option.value === val);
                  if (foundIndex >= 0) {
                    const foundImperialUnit = imperialUnitOptions[foundIndex]?.value ?? '';
                    formRef.current?.setValue('imperialUnit', foundImperialUnit);
                  }
                  control.onChange(val);
                }}
              />
            )}
          </FormField>
          <FormField name="imperialUnit">
            {(control) => (
              <BaseSelect
                label={t('projectSetting.referenceUnits.unitImperial')}
                options={imperialUnitOptions}
                {...control}
                className="hidden"
              />
            )}
          </FormField>
        </SlideOver.Content>
        <SlideOver.Controls className={cn('flex justify-end', { 'justify-between': referenceUnit?.id })}>
          {referenceUnit?.id && (
            <Button variant="warning" onClick={handleDelete}>
              {t('common.delete')}
            </Button>
          )}
          <div className="flex">
            <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
              {t('common.cancel')}
            </Button>
            <Button variant="primary" formSubmit disabled={codeExists}>
              {t('common.save')}
            </Button>
          </div>
        </SlideOver.Controls>
      </Form>
    </>
  );
};
