import { useLoadedProjectId, useLoadedVariantId, useReadOnly } from '@client/project/store';
import { Modal, ProtectIcon, SlideOver } from '@client/shared/toolkit';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DecoratedElement, RiskElement } from '../../hooks';
import { RiskElementSlideOver, RiskElementDeleteModal } from '.';
import { useValidateCatalogHasRestrictions, useValidateProjectPermission } from '@client/shared/permissions';
import { TimeLineDataContext } from '../Timeline';
import { SectionTitle } from '../CalculateSectionHeader';
import { CalculateSectionHeaders } from '../CalculateSectionHeaders';
import { CalculateSection } from '../CalculateSection';
import { EditRisksTimeLineMoneyDistributionModal } from '../Distribution';
import { CalculateRisksRenderElements } from '../Risks';
import { CalculateProps } from '../CalculateContainer';
import { getCalculateSectionHeight } from '../../utils';
import { CalculateContext, CalculateTotal } from '..';
import cn from 'classnames';

interface CalculateRisksProps extends CalculateProps {
  elements?: DecoratedElement<RiskElement>[];
  selectedVersionElements?: DecoratedElement<RiskElement>[];
  filteredTotal?: number;
  searchResults: string[] | null;
}

export const CalculateRisks = (props: CalculateRisksProps) => {
  const {
    view,
    elements = [],
    variant,
    archivedVersions = [],
    selectedVersionId = 'none',
    setSelectedVersionId,
    optionalColumn,
    setOptionalColumn,
    obligoColumn,
    setObligoColumn,
    open = true,
    onCollapse,
    expandedCatalogIds = [],
    onCollapseContainer,
    barChartData = false,
    searchValue,
    filteredTotal = 0,
  } = props;
  const { t } = useTranslation();

  const loadedVariantId = useLoadedVariantId();
  const loadedProjectId = useLoadedProjectId();
  const { setSelectedTimelineElement, selectedTimelineElement } = useContext(TimeLineDataContext);
  const { isListCollapsed } = useContext(CalculateContext)

  const containerRef = useRef<HTMLDivElement>(null);

  const catalogHasRestrictions = useValidateCatalogHasRestrictions(
    loadedProjectId ?? '',
    variant?.modelMetadata.riskCatalogId,
  );
  const disableRisks = !useValidateProjectPermission(['RISKS_WRITE'], loadedProjectId ?? '');
  const isReadOnly = useReadOnly() || disableRisks;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedElement, setSelectedElement] = useState<DecoratedElement<RiskElement> | undefined>();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [deleteElementId, setDeleteElementId] = useState<string | undefined>();
  const [preselectedGroupId, setPreselectedGroupId] = useState<string | undefined>();
  const [isMoneyDistributionModalOpen, setIsMoneyDistributionModalOpen] = useState(false);

  const handleOpenSlideOver = (
    element: DecoratedElement<RiskElement> | undefined,
    preselectedGroupId: string | undefined,
  ) => {
    if (element) {
      setSelectedElement(element);
    } else {
      setSelectedElement(undefined);
    }

    setPreselectedGroupId(preselectedGroupId);

    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (selectedTimelineElement?.elem?.elementId && selectedTimelineElement.elem?.elementType === 'riskElement') {
      setIsMoneyDistributionModalOpen(true);
    }
  }, [selectedTimelineElement, setSelectedTimelineElement]);

  useEffect(() => {
    if (!isMoneyDistributionModalOpen && setSelectedTimelineElement) {
      setSelectedTimelineElement(null);
    }
  }, [isMoneyDistributionModalOpen, setSelectedTimelineElement]);

  const handleOpenDeleteElement = (deleteElementId?: string | null) => {
    if (!deleteElementId) {
      return;
    }

    setDeleteElementId(deleteElementId);
    setIsOpenDeleteModal(true);
  };

  const handleCloseDeleteElement = () => {
    setIsOpenDeleteModal(false);
    setDeleteElementId(undefined);
  };

  const height = useMemo(() => {
    return getCalculateSectionHeight(elements, 0, 0, expandedCatalogIds);
  }, [expandedCatalogIds, elements]);

  const totalSectionTimeline = useMemo(() => {
    const totalSum = variant?.modelMetadata.totalRisks.value;
    const showFilteredTotal = totalSum && searchValue ? filteredTotal !== totalSum : false;

    return (
      <div className="ml-2 w-full flex justify-end self-end">
        <div className={cn("flex justify-end pr-4", {
          'w-full': isListCollapsed,
          'w-1/3 md:w-1/2': !isListCollapsed
        })}>
          {showFilteredTotal && (
            <div className={cn("hidden md:block", {
              'w-1/2': isListCollapsed,
              'w-4/12': !isListCollapsed
            })}>
              <CalculateTotal
                catalogHasRestrictions={catalogHasRestrictions}
                totalText={t('projectCalculate.calculateFilteredSum')}
                colorClass="text-gray-500"
                totalCost={filteredTotal}
                paddingClass=""
              />
            </div>
          )}
          <div className={cn({
            'w-1/2': isListCollapsed,
            'w-5/12': !isListCollapsed
          })}>
            <CalculateTotal
              catalogHasRestrictions={catalogHasRestrictions}
              totalText={t('projectCalculate.calculateRiskTotalRisk')}
              colorClass="text-risks"
              totalCost={totalSum}
              paddingClass=""
            />
          </div>
        </div>
      </div>
    );
  }, [variant?.modelMetadata.totalRisks.value, searchValue, filteredTotal, isListCollapsed, catalogHasRestrictions, t]);

  return (
    <div className="relative flex cursor-default transition-[margin] duration-300">
      <div className="w-full" ref={containerRef}>
        <CalculateSectionHeaders
          view={view}
          archivedVersions={archivedVersions}
          selectedVersionId={selectedVersionId}
          setSelectedVersionId={setSelectedVersionId}
          optionalColumn={optionalColumn}
          setOptionalColumn={setOptionalColumn}
          obligoColumn={obligoColumn}
          setObligoColumn={setObligoColumn}
          title={SectionTitle.RISKS}
          icon={<ProtectIcon className="w-7 h-7" />}
          onCollapse={onCollapse}
          onCollapseContainer={() => onCollapseContainer(!open)}
          showLevelToggles={elements.length > 0 && !searchValue}
        />
        <CalculateSection
          catalogHasRestrictions={catalogHasRestrictions}
          open={open}
          view={view}
          totalCost={variant?.modelMetadata.totalRisks.value}
          bgColor="bg-risks"
          totalColor="text-risks"
          totalText={t('projectCalculate.calculateRiskTotalRisk')}
          totalSectionTimeline={totalSectionTimeline}
          hasBarChart={barChartData}
          height={elements.length > 0 ? height : undefined}
        >
          {elements.length > 0 ? (
            <CalculateRisksRenderElements
              containerRef={containerRef}
              selectedElement={selectedElement}
              elements={elements}
              handleOpenSlideOver={handleOpenSlideOver}
              handleOpenDeleteElement={handleOpenDeleteElement}
              isReadOnly={isReadOnly}
              {...props}
            />
          ) : (
            <div className="text-lg font-bold p-4 h-12">{t('projectCalculate.NoElements')}</div>
          )}
        </CalculateSection>
      </div>
      <SlideOver isOpen={isModalOpen} onClose={handleCloseModal} onAfterLeave={() => setSelectedElement(undefined)}>
        <RiskElementSlideOver
          onClose={handleCloseModal}
          variantId={loadedVariantId}
          decoratedRiskElement={selectedElement}
          disabled={isReadOnly}
          riskCatalogId={variant?.payload.riskCatalogId ?? undefined}
          preselectedGroupId={preselectedGroupId}
        />
      </SlideOver>

      <Modal isOpen={isOpenDeleteModal} onClose={handleCloseDeleteElement}>
        <RiskElementDeleteModal
          elementId={deleteElementId}
          variantId={loadedVariantId}
          onClose={handleCloseDeleteElement}
        />
      </Modal>

      {selectedTimelineElement?.elem?.elementId && selectedTimelineElement.elem?.elementType === 'riskElement' && (
        <Modal
          isOpen={isMoneyDistributionModalOpen}
          onClose={() => setIsMoneyDistributionModalOpen(false)}
          variant="max"
        >
          <EditRisksTimeLineMoneyDistributionModal
            onClose={() => setIsMoneyDistributionModalOpen(false)}
            selectedElement={selectedTimelineElement.elem}
            riskGroupId={
              selectedTimelineElement.elem.type === 'group' ? selectedTimelineElement.elem.elementId : undefined
            }
            onSave={() => {
              setIsMoneyDistributionModalOpen(false);
            }}
            disabled={isReadOnly}
            selectedDate={selectedTimelineElement.date}
          />
        </Modal>
      )}
    </div>
  );
};
