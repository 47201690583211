import { lazy, useMemo } from 'react';
import { LazyComponent } from '@client/shared/toolkit';
import {
  DataSourceReadModel,
  useApiGetReportDashboardDataSourceQuery,
  useApiGetReportExportDataSourceQuery,
  useApiGetReportGenericDataSourceQuery,
  useApiGetReportQuery,
} from '@client/shared/api';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { useUi } from '@client/shared/store';
import { ReportExportFilterConfig, ReportExportVariable, ReportExportCustomComponent } from '@client/shared/utilities';

const ReportExportComponent = lazy(() =>
  import('../../../lazy/src/components/ReportExport').catch((e) => {
    console.error('Component Failed Loading:', e);
    return { default: () => <div>Error loading the component.</div> };
  }),
);

export const LazyReportExportButtons = (props: {
  reportId: string;
  type?: 'dashboard' | 'generic' | 'export';
  buttons?: ('png' | 'pdf' | 'xlxs')[];
  calculationModelId?: string;
  datasourceParameters?: string | null;
  filterConfig?: ReportExportFilterConfig;
  variables?: ReportExportVariable[];
  customComponents?: ReportExportCustomComponent[];
}) => {
  const {
    reportId,
    type = 'dashboard',
    buttons,
    calculationModelId,
    datasourceParameters,
    filterConfig,
    variables,
    customComponents,
  } = props;
  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();
  const userData = useUi();

  const calculationModel = useMemo(() => {
    if (calculationModelId) {
      return calculationModelId;
    }
    return loadedVariantId;
  }, [calculationModelId, loadedVariantId]);

  // if reportId is set, the invoice has own report
  const { data: reportData, isFetching } = useApiGetReportQuery(
    {
      reportId: reportId,
    },
    {
      skip: !loadedProjectId || !calculationModel || !userData,
    },
  );

  // We need to load the datasource with the correct data, because in report response there are only dummy ids
  const { data: dashboardDataSource, isFetching: isFetchingDashboardDataSource } =
    useApiGetReportDashboardDataSourceQuery(
      {
        dataSourceId: reportData?.dataSourceModel?.id ?? '',
        projectId: loadedProjectId ?? '',
        calculationModelId: calculationModel ?? '',
      },
      {
        skip: !loadedProjectId || !calculationModel || !reportData?.dataSourceModel?.id || type !== 'dashboard',
      },
    );
  const { data: genericDataSource, isFetching: isFetchingGenericDataSource } = useApiGetReportGenericDataSourceQuery(
    {
      dataSourceId: reportData?.dataSourceModel?.id ?? '',
      projectId: loadedProjectId ?? '',
      calculationModelId: calculationModel ?? '',
    },
    {
      skip: !loadedProjectId || !calculationModel || !reportData?.dataSourceModel?.id || type !== 'generic',
    },
  );
  const { data: exportDataSource, isFetching: isFetchingExportDataSource } = useApiGetReportExportDataSourceQuery(
    {
      dataSourceId: reportData?.dataSourceModel?.id ?? '',
      projectId: loadedProjectId ?? '',
      calculationModelId: calculationModel ?? '',
    },
    {
      skip: !loadedProjectId || !calculationModel || !reportData?.dataSourceModel?.id || type !== 'export',
    },
  );

  const dataSource: DataSourceReadModel | undefined = useMemo(() => {
    if (genericDataSource || dashboardDataSource || exportDataSource) {
      let source = { ...genericDataSource };
      if (type === 'dashboard') {
        source = { ...dashboardDataSource };
      } else if (type === 'export') {
        source = { ...exportDataSource };
      }
      if (source && datasourceParameters) {
        source.url += `?${datasourceParameters}`;
      }
      return source as DataSourceReadModel;
    }
    return undefined;
  }, [type, genericDataSource, dashboardDataSource, exportDataSource, datasourceParameters]);

  return (
    <LazyComponent>
      <ReportExportComponent
        isLoading={
          isFetching || isFetchingDashboardDataSource || isFetchingGenericDataSource || isFetchingExportDataSource
        }
        reportData={reportData}
        dataSource={dataSource}
        buttons={buttons}
        filterConfig={filterConfig}
        variables={variables}
        customComponents={customComponents}
      />
    </LazyComponent>
  );
};
