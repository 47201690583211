import {
  Button,
  Modal,
  ModalOnCloseProps,
  Wizard,
  WizardSlideContent,
  WizardSlideHeader,
} from '@client/shared/toolkit';
import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BenchmarkReportConfigurationReadModel,
  SelectProjectReadModel,
  useApiPostCreateBenchmarkReportConfigurationMutation,
  useApiPostUpdateBenchmarkReportConfigurationMutation,
} from '@client/shared/api';
import { safeMutation } from '@client/shared/utilities';
import {
  BenchmarkingProjectFilter,
  BenchmarkingFilterProject,
  BenchmarkingProjectSelect,
  FormulaEditorBenchmarkSelectableFilter,
} from '@client/project/shared';

interface BenchmarkAddProjectWizardProps extends ModalOnCloseProps {
  config?: BenchmarkReportConfigurationReadModel;
  projects?: SelectProjectReadModel[];
}

export const BenchmarkAddProjectWizard = (props: BenchmarkAddProjectWizardProps) => {
  const { onClose, config, projects } = props;
  const { t } = useTranslation();

  const [currentStep, setCurrentStep] = useState(0);
  const [selectedElements, setSelectedElements] = useState<BenchmarkingFilterProject[]>([]);

  const [createConfig, { isLoading: isCreating }] = useApiPostCreateBenchmarkReportConfigurationMutation();
  const [updateConfig, { isLoading: isUpdating }] = useApiPostUpdateBenchmarkReportConfigurationMutation();

  const handleOnSlideChange = async (index: number) => {
    setCurrentStep(index);
  };
  const handleCloseModal = () => {
    onClose(false);
  };

  const handleAddProjects = async () => {
    let selectedProjectIds = selectedElements.map((element, index) => {
      return {
        orderNumber: index,
        projectId: element.id
      }});

    if (!config) {
      try {
        await safeMutation(
          createConfig,
          {
            body: {
              name: 'Report title',
              projects: selectedProjectIds,
            },
          },
          isCreating,
        );
        onClose(false);
      } catch (e) {
        console.log(e);
      }
    } else {
      selectedProjectIds = selectedProjectIds.concat(config?.projects ?? []);

      try {
        await safeMutation(
          updateConfig,
          {
            reportConfigurationId: config.id,
            body: {
              name: config.name,
              projects: selectedProjectIds,
            },
          },
          isUpdating,
        );
        onClose(false);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const [selectedFilters, setSelectedFilters] = useState<FormulaEditorBenchmarkSelectableFilter>({
    searchInput: '',
    assetClasses: [] as string[],
    start: null,
    end: null,
  });

  /* filters out not already selected projects */
  const selectableProjects = useMemo(() => {
    if (config?.projects.length && projects?.length) {
      return projects.filter((project) => !config.projects.some(x => x.projectId === project.id));
    }
    return projects ?? [];
  }, [config?.projects, projects]);

  return (
    <Wizard
      currentStep={currentStep}
      onSlideChange={(step) => handleOnSlideChange(step)}
      className="w-[740px] h-[560px]"
    >
      <Wizard.Slides>
        <Wizard.Slide hasPadding={false}>
          <WizardSlideHeader
            title={t('reporting.benchmarking.addProject.title')}
            subTitle={t('reporting.benchmarking.addProject.description')}
          />
          <WizardSlideContent className="h-[310px]">
            <BenchmarkingProjectFilter
              benchmarkProjects={selectableProjects as BenchmarkingFilterProject[]}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              disabled={!selectableProjects.length}
            />
            {selectableProjects.length > 0 ? (
              <BenchmarkingProjectSelect
                benchmarkProjects={selectableProjects as BenchmarkingFilterProject[]}
                selectedElements={selectedElements}
                setSelectedElements={setSelectedElements}
                selectedFilters={selectedFilters}
                showCalculation={false}
              />
            ) : (
              <div className="mt-4 italic">{t('reporting.benchmarking.addProject.noProjects')}</div>
            )}
          </WizardSlideContent>
        </Wizard.Slide>
      </Wizard.Slides>
      <Wizard.Navigation>
        {({ isLast }) => {
          return (
            <Modal.Controls
              className={classNames({
                'bg-slate-100': currentStep !== 1,
                'bg-white': currentStep === 1,
              })}
            >
              <Button variant="text" onClick={handleCloseModal}>
                {t('common.cancel')}
              </Button>
              {(() => {
                if (isLast) {
                  return (
                    <Button
                      variant="primary"
                      onClick={handleAddProjects}
                      disabled={!selectedElements.length || isCreating}
                    >
                      {t('common.continue')}
                    </Button>
                  );
                }
              })()}
            </Modal.Controls>
          );
        }}
      </Wizard.Navigation>
    </Wizard>
  );
};
