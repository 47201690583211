import { useTranslation } from 'react-i18next';
import { MatrixCellData, WidgetMatrix } from '@client/shared/toolkit';
import { DashboardWidgetProps } from '../WidgetDashboard';
import { useMemo } from 'react';

export const DashboardWidgetRiskMatrix = (props: DashboardWidgetProps) => {
  const { widget } = props;
  const { t } = useTranslation();

  const matrixData = useMemo(() => {
    let matrixCells: MatrixCellData[][] = [];
    if (widget) {
      matrixCells = [
        [
          { topValue: 10, bottomValue: 10, color: 'bg-yellow-200'},
          { topValue: 10, bottomValue: 10, color: 'bg-red-300' },
          { topValue: 10, bottomValue: 10, color: 'bg-red-300' }, 
        ],
        [
          { topValue: 10, bottomValue: 10, color: 'bg-green-300' },
          { topValue: 10, bottomValue: 10, color: 'bg-yellow-200' },
          { topValue: 10, bottomValue: 10, color: 'bg-red-300' }, 
        ],
        [
          { topValue: 10, bottomValue: 10, color: 'bg-green-300' },
          { topValue: 10, bottomValue: 10, color: 'bg-green-300' },
          { topValue: 10, bottomValue: 10, color: 'bg-yellow-200' }, 
        ],
      ];
    }
    return matrixCells;
  }, [widget]);

  return (
    <WidgetMatrix
      title={t('reporting.reportRisk.riskMatrixTitle')}
      isLoading={false}
      data={matrixData}
      rowLabels={[t('project.dashboard.widget.matrix.high'), t('project.dashboard.widget.matrix.medium'), t('project.dashboard.widget.matrix.low')]}
      colLabels={[t('project.dashboard.widget.matrix.low'), t('project.dashboard.widget.matrix.medium'), t('project.dashboard.widget.matrix.high')]}
      verticalTitle={t('reporting.reportRisk.riskMatrixVerticalTitle')}
      horizontalTitle={t('reporting.reportRisk.riskMatrixHorizontalTitle')}
    />
  );
};
