import { setCookie } from 'typescript-cookie';
import { settings } from '@client/shared/store';

export const COOKIE_DATEV_AUTH_DATA = 'datev_auth';
export const updateDatevAuthCookie = (datevCacheKey: string | undefined | null, tenantSubdomain: string | undefined | null) => {
  const datevData = {
    datevCacheKey: datevCacheKey ?? '',
    datevTenantSubdomain: tenantSubdomain ?? '',
  }

  setCookie(COOKIE_DATEV_AUTH_DATA, JSON.stringify(datevData), {
    expires: 1,
    path: '/',
    domain: settings.mainDomain,
    sameSite: 'Strict',
  });
};
