import {
  CalculationModelEarningsGroupElement,
  CalculationModelEarningsQueryResponse,
  useApiGetCalculationModelEarningsQuery,
} from '@client/shared/api';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { useMemo } from 'react';
import { flattenEarningGroups } from '../utils';

export const useFlattenEarningsElements = (
  data?: CalculationModelEarningsQueryResponse,
  skipFetch = false,
): CalculationModelEarningsGroupElement[] => {
  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const { data: earningsData } = useApiGetCalculationModelEarningsQuery(
    {
      projectId: loadedProjectId ?? 'unset',
      calculationModelId: loadedVariantId ?? '',
    },
    { skip: loadedVariantId == null || !!data || skipFetch },
  );

  return useMemo(() => {
    if (data) {
      return data.payload.earnings ? flattenEarningGroups(data.payload.earnings) : [];
    } else if (earningsData?.payload.earnings) {
      return flattenEarningGroups(earningsData?.payload.earnings);
    }
    return [];
  }, [earningsData?.payload.earnings, data]);
};
