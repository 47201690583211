import {
  LoadingIndicator,
  WidgetContainer,
  WidgetContainerContent,
  WidgetContainerTitle,
} from '@client/shared/toolkit';
import { DashboardWidgetProps } from '../WidgetDashboard';
import { useTranslation } from 'react-i18next';
import { useLoadedProjectId } from '@client/project/store';
import React, { useMemo } from 'react';
import { useApiProjectGetUserDefinedFieldsDefinitionByElementQuery } from '@client/shared/api';
import { DataFeed, FontLight } from './components';
import { formatDate } from '@client/shared/utilities';

export const DashboardWidgetProjectUdfs = (props: DashboardWidgetProps) => {
  const { widget } = props;
  const { t } = useTranslation();
  const loadedProjectId = useLoadedProjectId();

  const { data: userDefinedFieldsResponse, isFetching } = useApiProjectGetUserDefinedFieldsDefinitionByElementQuery(
    {
      projectId: loadedProjectId ?? 'unset',
      elementId: loadedProjectId,
      elementType: 'Project',
    },
    { skip: !loadedProjectId },
  );

  const projectUdfs = useMemo(() => {
    return userDefinedFieldsResponse?.userDefinedFieldsDefinition ?? [];
  }, [userDefinedFieldsResponse]);

  return (
    <WidgetContainer className="flex flex-col">
      <WidgetContainerTitle>
        {widget.title ? widget.title : t('dashboard.widget.projectUdfs.title')}
      </WidgetContainerTitle>
      <WidgetContainerContent className="flex-1 overflow-y-auto divide-y-2">
        {isFetching && <LoadingIndicator mode="overlay" text={t('common.loading')} />}
        {!isFetching &&
          projectUdfs.length > 0 &&
          projectUdfs.map((udf) => {
            if (udf.userDefinedField) {
              return (
                <DataFeed
                  key={`project-udf-${udf.id}`}
                  header={<FontLight>{udf.name}</FontLight>}
                  className="mx-0"
                  title={
                    udf.userDefinedField
                      ? udf.userDefinedField?.date
                        ? formatDate(new Date(udf.userDefinedField.date))
                        : udf.userDefinedField?.text
                          ? udf.userDefinedField?.text
                          : udf.userDefinedField?.number
                            ? udf.userDefinedField?.number
                            : udf.userDefinedField?.listSelectedItem?.label ?? '-'
                      : '-'
                  }
                  icon=""
                />
              );
            }
            return '';
          })}
      </WidgetContainerContent>
    </WidgetContainer>
  );
};
