import { DecoratedElement } from '../hooks';
import { includesSearchValue } from '@client/shared/utilities';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function filterElementBySearchValue(element: DecoratedElement<any>, searchValue: string, includeChildren = false): boolean {
  if (!searchValue) return true

  let match = false
  // TODO highlight the value which matches
  // TODO define all values that shall be searched here
  const searchInValues = [
    element.element?.group?.code,
    element.element?.group?.description,
    element.element?.group?.title,
    element.element?.financingElement?.description,
    element.element?.financingElement?.title,
    element.element?.costElement?.description,
    element.element?.costElement?.title,
    element.element?.riskElement?.description,
    element.element?.riskElement?.title,
    element.element?.earningElement?.description,
    element.element?.earningElement?.title
  ]

  searchInValues.forEach((searchIn) => {
    if (!match) {
      match = includesSearchValue(searchIn, searchValue)
    }
  })

  if (includeChildren && !match && element.children.length) {
    const childrenMatch = filterElementsBySearchValue(element.children, searchValue);
    match = childrenMatch.length > 0
  }

  return match
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function filterElementsBySearchValue(elements: DecoratedElement<any>[], searchValue: string): DecoratedElement<any>[] {
  if (!searchValue) return elements
  return elements.filter((element) => {
    let match = false
    // TODO highlight the value which matches
    // TODO define all values that shall be searched here
    const searchInValues = [
      element.element?.group?.code,
      element.element?.group?.description,
      element.element?.group?.title,
      element.element?.financingElement?.description,
      element.element?.financingElement?.title,
      element.element?.costElement?.description,
      element.element?.costElement?.title,
      element.element?.riskElement?.description,
      element.element?.riskElement?.title,
      element.element?.earningElement?.description,
      element.element?.earningElement?.title
    ]
    searchInValues.forEach((searchIn) => {
      if (!match) {
        match = includesSearchValue(searchIn, searchValue)
      }
    })

    if (!match && element.children.length) {
      const childrenMatch = filterElementsBySearchValue(element.children, searchValue);
      match = childrenMatch.length > 0
    }

    element.match = match
    return match
  });
}
