import {
  ApiDeleteProjectApiArg,
  ApiDeleteProjectApiResponse,
  ApiDeleteProjectPermissionGroupApiArg,
  ApiDeleteProjectPermissionGroupApiResponse,
  ApiDeleteProjectSpvApiArg,
  ApiDeleteProjectSpvApiResponse,
  ApiGetAuthProjectGroupsApiArg,
  ApiGetAuthProjectGroupsApiResponse,
  ApiGetProjectApiArg,
  ApiGetProjectApiResponse,
  ApiGetProjectDashboardApiArg,
  ApiGetProjectDashboardApiResponse,
  ApiGetProjectPermissionGroupsApiArg,
  ApiGetProjectPermissionGroupsApiResponse,
  ApiGetProjectSpvApiResponse,
  ApiGetProjectSpvsApiArg,
  ApiGetProjectSpvsApiResponse,
  ApiPostCreateProjectApiArg,
  ApiPostCreateProjectApiResponse,
  ApiPostCreateProjectPermissionGroupApiArg,
  ApiPostCreateProjectPermissionGroupApiResponse,
  ApiPostCreateProjectSpvApiArg,
  ApiPostCreateProjectSpvApiResponse,
  ApiPostProjectValuationApiArg,
  ApiPostProjectValuationApiResponse,
  ApiPostUpdateProjectMetadataApiArg,
  ApiPostUpdateProjectMetadataApiResponse,
  ApiPostUpdateProjectPermissionGroupApiArg,
  ApiPostUpdateProjectPermissionGroupApiResponse,
  ApiPostUploadProjectCoverApiArg,
  ApiPostUploadProjectCoverApiResponse,
} from '../apiGenerated';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { ApiTagTypes } from '../apiTagTypes';

export const enhancedApiProjectEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  apiGetProject: {
    providesTags: (result: ApiGetProjectApiResponse, _error: FetchBaseQueryError, args: ApiGetProjectApiArg) => [
      { type: ApiTagTypes.Project, id: args.projectId },
      ...(result?.project?.calculationModels?.map((dto): { type: ApiTagTypes.CalculationModel; id: string } => ({
        type: ApiTagTypes.CalculationModel,
        id: dto.id,
      })) ?? []),
    ],
  },
  apiGetProjectDashboard: {
    providesTags: (
      _result: ApiGetProjectDashboardApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetProjectDashboardApiArg,
    ) => [
      { type: ApiTagTypes.Project, id: args.projectId },
      { type: ApiTagTypes.CalculationModel, id: args.calculationModelId },
    ],
  },
  apiGetProjectSpvs: {
    providesTags: (
      _result: ApiGetProjectSpvsApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetProjectSpvsApiArg,
    ) => [
      { type: ApiTagTypes.Project, id: args.projectId },
      { type: ApiTagTypes.Spvs, id: 'LIST' },
    ],
  },
  apiGetProjectSpv: {
    providesTags: (
      _result: ApiGetProjectSpvApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetProjectSpvApiResponse,
    ) => [{ type: ApiTagTypes.Project }, { type: ApiTagTypes.Spvs, id: args.id }],
  },
  apiGetProjectPermissionGroups: {
    providesTags: (
      _result: ApiGetProjectPermissionGroupsApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetProjectPermissionGroupsApiArg,
    ) => [
      { type: ApiTagTypes.Project, id: args.projectId },
      { type: ApiTagTypes.ProjectPermissionGroups, id: args.projectId },
    ],
  },
  apiGetAuthProjectGroups: {
    providesTags: (
      _result: ApiGetAuthProjectGroupsApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetAuthProjectGroupsApiArg,
    ) => [
      { type: ApiTagTypes.AuthGroups, id: 'LIST' },
      { type: ApiTagTypes.Project, id: args.projectId },
      { type: ApiTagTypes.ProjectPermissionGroups, id: args.projectId },
    ],
  },
  /*------------------ INVALIDATES TAGS --------------------*/
  apiPostUploadProjectCover: {
    invalidatesTags: (
      _result: ApiPostUploadProjectCoverApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUploadProjectCoverApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Project, id: args.projectId },
            {
              type: ApiTagTypes.CompanyProjectImage,
              id: args.projectId,
            },
            ApiTagTypes.Projects,
          ]
        : [],
  },
  apiPostCreateProjectCopy: {
    invalidatesTags: [ApiTagTypes.Projects],
  },
  apiPostUpdateProjectMetadata: {
    invalidatesTags: (
      _result: ApiPostUpdateProjectMetadataApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateProjectMetadataApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Project, id: args.projectId },
            { type: ApiTagTypes.UserDefinedFields, id: args.projectId },
            ApiTagTypes.Projects,
            ApiTagTypes.Spvs,
            ApiTagTypes.CompanyBranches,
          ]
        : [],
  },
  apiPostCreateProject: {
    invalidatesTags: (
      _result: ApiPostCreateProjectApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateProjectApiArg,
    ) => {
      if (error == null) {
        if ((args.body as FormData).get('projectGroupId')) {
          return [
            { type: ApiTagTypes.Project, id: args.body.ProjectGroupId },
            ApiTagTypes.Projects,
            ApiTagTypes.TenantCatalog,
          ];
        }
        return [ApiTagTypes.Projects, ApiTagTypes.TenantCatalog];
      }
      return [];
    },
  },
  apiDeleteProject: {
    invalidatesTags: (
      _result: ApiDeleteProjectApiResponse,
      error: FetchBaseQueryError,
      _args: ApiDeleteProjectApiArg,
    ) =>
      error == null
        ? [
            ApiTagTypes.Projects,
            ApiTagTypes.TenantCatalog,
            { type: ApiTagTypes.UserDefinedFieldDefinition, id: 'LIST' },
            ApiTagTypes.UserDefinedFields,
          ]
        : [],
  },
  apiPostProjectValuation: {
    invalidatesTags: (
      _result: ApiPostProjectValuationApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostProjectValuationApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Project, id: args.projectId },
            { type: ApiTagTypes.CalculationModel, id: args.projectId },
            { type: ApiTagTypes.Taxonomy, id: args.projectId },
          ]
        : [],
  },
  apiPostCreateProjectSpv: {
    invalidatesTags: (
      _result: ApiPostCreateProjectSpvApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateProjectSpvApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Project, id: args.projectId },
            ApiTagTypes.CompanyBranches,
            ApiTagTypes.Projects,
            ApiTagTypes.Spvs,
          ]
        : [],
  },
  apiDeleteProjectSpv: {
    invalidatesTags: (
      _result: ApiDeleteProjectSpvApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteProjectSpvApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Project, id: args.projectId },
            ApiTagTypes.CompanyBranches,
            ApiTagTypes.Projects,
            ApiTagTypes.Spvs,
            { type: ApiTagTypes.Invoice },
            { type: ApiTagTypes.Invoices, id: args.projectId },
            { type: ApiTagTypes.InvoiceDocuments, id: args.projectId },
          ]
        : [],
  },
  apiPostCreateProjectPermissionGroup: {
    invalidatesTags: (
      _result: ApiPostCreateProjectPermissionGroupApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateProjectPermissionGroupApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Project, id: args.projectId },
            { type: ApiTagTypes.Contracts, id: args.projectId },
            { type: ApiTagTypes.Commitments, id: args.projectId },
            { type: ApiTagTypes.Invoices, id: args.projectId },
            { type: ApiTagTypes.ProjectPermissionGroups, id: args.projectId },
          ]
        : [],
  },
  apiPostUpdateProjectPermissionGroup: {
    invalidatesTags: (
      _result: ApiPostUpdateProjectPermissionGroupApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateProjectPermissionGroupApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Project, id: args.projectId },
            { type: ApiTagTypes.Contracts, id: args.projectId },
            { type: ApiTagTypes.Commitments, id: args.projectId },
            { type: ApiTagTypes.Invoices, id: args.projectId },
            { type: ApiTagTypes.ProjectPermissionGroups, id: args.projectId },
          ]
        : [],
  },
  apiDeleteProjectPermissionGroup:  {
    invalidatesTags: (
      _result: ApiDeleteProjectPermissionGroupApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteProjectPermissionGroupApiArg,
    ) =>
      error == null
        ? [
          { type: ApiTagTypes.Project, id: args.projectId },
          { type: ApiTagTypes.Contracts, id: args.projectId },
          { type: ApiTagTypes.Commitments, id: args.projectId },
          { type: ApiTagTypes.Invoices, id: args.projectId },
          { type: ApiTagTypes.ProjectPermissionGroups, id: args.projectId },
        ]
        : [],
  },
};
