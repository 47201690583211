import {
  CalculateElementRowFinanceColumn,
  Column,
  OptionalColumn,
  TimeLineView
} from './index';
import React, { useMemo } from 'react';
import {
  CommitmentDto,
  ContractDto,
  InvoicingPartyDto,
  CalculationModelEarningsElement,
  CalculationModelEarningsGroupElement,
  CalculationModelRiskElementReadModel,
  CalculationModelRiskGroupReadModel,
  CostCatalogElementDto,
  CostElementDto, FinancingElementShortReadModel,
  FinancingGroupReadModel
} from '@client/shared/api';
import { FormattedCurrency } from '@client/project/shared';
import { formatPercentage } from '@client/shared/utilities';

export const getOverBudget = (forecast?: number | null, budget?: number | null) => {
  if (forecast && budget && forecast > budget) {
    return ((forecast - budget) / budget)
  }
  return undefined
}

export const getUnderBudget = (forecast?: number | null, budget?: number | null) => {
  if (forecast && budget && forecast > budget) {
    return ((forecast - budget) / forecast)
  }
  return undefined
}

export type CalculateSelectableColumnProps = {
  column: OptionalColumn
  level: number
  handleOnClick?: (evt: React.MouseEvent<HTMLDivElement>) => void
  view: TimeLineView
  values?: CostCatalogElementDto  | CostElementDto | CommitmentDto
    | ContractDto | CalculationModelRiskGroupReadModel
    | CalculationModelRiskElementReadModel | CalculationModelEarningsGroupElement
    | CalculationModelEarningsElement | FinancingGroupReadModel
    | FinancingElementShortReadModel | InvoicingPartyDto | number
  type: 'costCatalogElement' | 'costElement' | 'contract'
    | 'commitment' | 'riskGroup' | 'riskElement'
    | 'earningsGroup' | 'earningElement'
    | 'financingGroup' | 'financingElement' | 'invoice' | 'rest'
  columnType: Column.OBLIGO | Column.OPTIONAL_COLUMN
}
export const CalculateSelectableColumn = (props: CalculateSelectableColumnProps) => {
  const {
    column,
    level,
    handleOnClick,
    view,
    values,
    type,
    columnType
  } = props

  const parsedValues= useMemo(() => {
    if (!values) return undefined
    switch (type) {
      case 'costCatalogElement': {
        const val = values as CostCatalogElementDto
        return {
          forecastValue: val.modelValues.totalForecastValue,
          paymentValue: val.modelValues.paymentValue,
          totalPaymentValue: val.modelValues.absolutePaymentValue,
          obligo: val.modelValues?.obligo,
          contractValue: val.modelValues?.contractValue,
          supplementValue: val.modelValues?.supplementValue,
          invoiceValue: val.modelValues?.invoiceValue,
          overBudgetPercent: getOverBudget(val.modelValues?.forecastValue, val.modelValues?.effectiveValue)
        }
      }
      case 'costElement': {
        const val = values as CostElementDto
        return {
          forecastValue: val.totalForecastValue,
          paymentValue: val.paymentValue,
          totalPaymentValue: val.absolutePaymentValue,
          obligo: val.obligo,
          contractValue: val.contractValue,
          supplementValue: val.supplementValue,
          invoiceValue: val.invoiceValue,
          overBudgetPercent: getOverBudget(val.forecastValue, val.totalValue)
        }
      }
      case 'commitment': {
        const val = values as CommitmentDto
        return {
          forecastValue: val.forecastValue,
          paymentValue: val.paymentValue,
          totalPaymentValue: val.absolutePaymentValue,
          obligo: val.obligo,
          contractValue: val.contractValue,
          supplementValue: val.supplementValue,
          invoiceValue: val.invoiceValue,
        }
      }
      case 'contract': {
        const val = values as ContractDto
        return {
          forecastValue: val.forecastValue,
          paymentValue: val.paymentValue,
          totalPaymentValue: val.absolutePaymentValue,
          obligo: val.obligo,
          contractValue: val.contractValue,
          supplementValue: val.supplementValue,
          invoiceValue: val.invoiceValue,
        }
      }
      case 'invoice': {
        const val = values as InvoicingPartyDto
        return {
          forecastValue: val.forecastValue,
          paymentValue: val.paymentValue,          
          totalPaymentValue: val.absolutePaymentValue,
          obligo: val.obligo,
          invoiceValue: val.invoiceValue
        }
      }
      case 'riskGroup': {
        const val = values as CalculationModelRiskGroupReadModel
        return {
          forecastValue: val.effectiveForecast
        }
      }
      case 'riskElement': {
        const val = values as CalculationModelRiskElementReadModel
        return {
          forecastValue: val.totalForecast
        }
      }
      case 'earningsGroup': {
        const val = values as CalculationModelEarningsGroupElement
        return {
          forecastValue: val.effectiveForecast,
          paymentValue: val.financeTimeline?.totalPaymentNet,
          totalPaymentValue: val.financeTimeline?.totalPaymentNet,
        }
      }
      case 'earningElement': {
        const val = values as CalculationModelEarningsElement
        return {
          forecastValue: val.totalForecast,
          paymentValue: val.financeTimeline?.totalPaymentNet,     
          totalPaymentValue: val.financeTimeline?.totalPaymentNet,
        }
      }
      case 'financingGroup': {
        const val = values as FinancingGroupReadModel
        return {
          paymentValue: val.financeTimeline?.totalPaymentNet,          
          totalPaymentValue: val.financeTimeline?.totalPaymentNet,
        }
      }
      case 'financingElement': {
        const val = values as FinancingElementShortReadModel
        return {
          paymentValue: val.financeTimeline?.totalPaymentNet,
          totalPaymentValue: val.financeTimeline?.totalPaymentNet
        }
      }
      case 'rest':{
        const val = values as number
        return {
          forecastValue: val
        }
      }
    }
  }, [type, values])

  return (
    <CalculateElementRowFinanceColumn
      level={level}
      handleOnClick={handleOnClick}
      columnType={columnType}
      view={view}
    >
      {parsedValues && (
        <>
          {column === OptionalColumn.FORECAST && !!parsedValues.forecastValue && (
            <>
              {parsedValues.overBudgetPercent ? (
                <div className="flex gap-1 items-center">
                  <span className="text-[9px] text-red-800">
                    {formatPercentage(
                      parsedValues.overBudgetPercent,
                      { maxDigits: 0 }
                    )}
                  </span>
                  <FormattedCurrency amount={parsedValues.forecastValue} />
                </div>
              ): (
                <FormattedCurrency amount={parsedValues.forecastValue} />
              )}
            </>
          )}
          {column === OptionalColumn.PAYMENT && !!parsedValues.paymentValue && (
            <FormattedCurrency amount={parsedValues.paymentValue} />
          )}
          {column === OptionalColumn.ABSOLUTE_PAYMENT && !!parsedValues.totalPaymentValue && (
            <FormattedCurrency amount={parsedValues.totalPaymentValue} />
          )}
          {column === OptionalColumn.OBLIGO && parsedValues.obligo !== null && parsedValues.obligo !== undefined &&
            (<FormattedCurrency amount={parsedValues.obligo} />)
          }
          {column === OptionalColumn.CONTRACT && !!parsedValues.contractValue && (
            <FormattedCurrency amount={parsedValues.contractValue} />
          )}
          {column === OptionalColumn.SUPPLEMENT && !!parsedValues.supplementValue && (
            <FormattedCurrency amount={parsedValues.supplementValue} />
          )}
          {column === OptionalColumn.INVOICE && !!parsedValues.invoiceValue && (
            <FormattedCurrency amount={parsedValues.invoiceValue} />
          )}
          {column === OptionalColumn.TOTAL_CONTRACT &&
            (!!parsedValues.contractValue || !!parsedValues.supplementValue) && (
              <FormattedCurrency amount={(parsedValues.contractValue ?? 0) + (parsedValues.supplementValue ?? 0)} />
            )}
        </>
      )}
    </CalculateElementRowFinanceColumn>
  )
}
