import { Avatar, BrokenLinkIcon, CardDataSet } from '@client/shared/toolkit';
import { apiEndpointUrls, SelectCompanyBranchReadModel } from '@client/shared/api';
import cn from 'classnames';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

interface BranchDataProps {
  branch: SelectCompanyBranchReadModel;
  label: string;
  branchNameClassName?: string;
  notMatched?: boolean;
}

export const BranchData = (props: BranchDataProps) => {
  const { branch, label, branchNameClassName, notMatched } = props;
  return (
    <div className="flex gap-5">
      <Avatar
        name={branch.name ?? ''}
        className="w-12 h-12 flex-none"
        rounded={false}
        randomBgColor={false}
        imagePath={branch.companyLogoId ? apiEndpointUrls.apiGetCompanyLogo.replace(':companyId', branch.companyId).replace(':fileId', branch.companyLogoId) : undefined}
      />
      <CardDataSet label={label}>
        <div className="flex items-center">
          <span
            className={cn('font-bold text-lg leading-tight mb-1.5', branchNameClassName, {
              'text-yellow-500 w-2/3 mr-2': notMatched,
            })}
          >
            {branch.name ? (branch.companyName && branch.companyName !== branch.name ? `${branch.companyName} - ${branch.name}` : branch.name) : '-'}
          </span>
          {notMatched && (
            <div className="flex">
              <BrokenLinkIcon className="w-6 h-6 mr-1" />
              <ExclamationTriangleIcon className="w-6 h-6 text-yellow-500" />
            </div>
          )}
        </div>
        {branch.address && (
          <>
            <span className="text-gray-500 text-[15px] leading-tight">
              {branch.address.street} {branch.address.number}
            </span>
            <span className="text-gray-500 text-[15px] leading-tight">
              {branch.address.postalCode} {branch.address.city}
            </span>
            <span className="text-gray-500 text-[15px] leading-tight">{branch.address.country}</span>
          </>
        )}
      </CardDataSet>
    </div>
  );
};
