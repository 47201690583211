import { apiEndpointUrls } from '@client/shared/api';
import { Avatar } from './Avatar';
import md5 from "blueimp-md5";

interface UserAvatarProps {
  userId: string;
  userName: string;
  hasAvatar: boolean;
  lastUpdate: string;
  classNames?: string;
}

export const UserAvatar = ({ userId, userName, hasAvatar, lastUpdate, classNames }: UserAvatarProps) => {
  const updateHash = md5(lastUpdate);
  const avatarPath = hasAvatar ? `${apiEndpointUrls.apiGetUserAvatar.replace(':userId', userId)}?updated=${updateHash}` : undefined;

  const classes = classNames ? classNames : 'w-12 h-12';

  return <Avatar name={userName} className={classes} rounded={true} imagePath={avatarPath} />;
};

