import {
  ContractReadModel,
  ContractTitlePositionReadModel,
  useApiDeleteContractPositionMutation
} from '@client/shared/api';
import { HintBox, ModalOnCloseProps, ConfirmModal, LoadingIndicator } from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';

interface ContractPositionDeleteModalProps extends ModalOnCloseProps {
  contract: ContractReadModel;
  position: ContractTitlePositionReadModel;
}

export const ContractPositionDeleteModal = ({ contract, position, onClose }: ContractPositionDeleteModalProps) => {
  const { t } = useTranslation();
  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();
  const [deleteContractTitle, { isLoading }] = useApiDeleteContractPositionMutation();

  const handleOnClose = () => {
    onClose(false);
  };

  const handleDelete = async () => {
    try {
      await safeMutation(
        deleteContractTitle,
        {
          contractId: contract.id,
          projectId: loadedProjectId ?? '',
          calculationModelId: loadedVariantId ?? '',
          positionId: position.id
        },
        isLoading
      );
      onClose(true);
    } catch (e) {
      console.error(e)
    }
  };

  return (
    <ConfirmModal
      title={t('projectContract.deleteContractTitle')}
      onConfirm={handleDelete}
      onClose={handleOnClose}
      confirmVariant="danger"
      confirmText={t('common.delete')}
    >      
      {isLoading && <LoadingIndicator text={t('projectContract.deleteContractTitleLoading') ?? ''} mode="overlay" />}
      <HintBox hintType="warning">{t('projectContract.deleteContractPositionHint')}</HintBox>
    </ConfirmModal>
  );
};
