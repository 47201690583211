import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, Navigate, useSearchParams } from 'react-router-dom';
import { ProjectLayout } from './components';
import { ProjectRoutes } from './ProjectRoutes';
import { useTranslation } from 'react-i18next';
import {
  setProjectId,
  useLoadedProject,
  useLoadedProjectVariants,
} from '@client/project/store';
import { LoadingIndicator } from '@client/shared/toolkit';
import { ROUTES_CONFIG } from '@client/shared/permissions';

export const ProjectModule = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  if (id == null) throw new Error('Can only render if project id (:id) is set');

  const [searchParams] = useSearchParams();
  const queryParamVariantId = searchParams.get('vid');

  const { data: projectData, isFetching, isLoading, isError, isSuccess } = useLoadedProject();
  const { data: projectVariants } = useLoadedProjectVariants();

  const variantExists = projectVariants.find((x) => x.id === queryParamVariantId) !== undefined;
  const activeVariant = projectVariants.find((x) => x.type === 'Version') ?? projectVariants.find((x) => x.type === 'Scenario');

  const variantId = variantExists
    ? queryParamVariantId !== null
      ? queryParamVariantId
      : activeVariant?.id
    : activeVariant?.id;

  useEffect(() => {
    dispatch(
      setProjectId({
        projectId: id,
        variantId: variantId,
        isTemplate: projectData?.project?.payload?.isTemplate ?? false,
        versionCreated: projectData?.project?.payload?.versionCreated ?? true
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, variantId]);

  return (
    <ProjectLayout>
      {isFetching === true && <LoadingIndicator mode="overlay-window" text={t('project.loadingProjectDetails')} />}

      {isLoading === false && (
        <>
          {isError === true && <Navigate to={{ pathname: ROUTES_CONFIG.PROJECTS.path }} />}
          {isSuccess === true && <ProjectRoutes />}
        </>
      )}
    </ProjectLayout>
  );
};
