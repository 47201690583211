import {
  Button,
  ContractNumberIcon,
  Form,
  FormField,
  FormRefHandle,
  LoadingIndicator,
  Modal,
  PriceTagIcon,
  SlideOver,
  SlideOverOnCloseProps,
  TextInput,
  RenameIcon,
  BillIcon,
} from '@client/shared/toolkit';
import {
  ContractReadModel,
  ContractTitleReadModel,
  ContractTitlePositionReadModel,
  useApiPostUpdateContractTitlePositionMutation,
  useApiPostCreateContractTitlePositionMutation
} from '@client/shared/api';
import React, { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { safeMutation } from '@client/shared/utilities';
import {
  ContractTitleEditFormValidationSchema,
} from './ContractTitleEditFormValidationValues';
import { useLoadedProject, useLoadedVariantId } from '@client/project/store';
import { useValidateProjectPermission } from '@client/shared/permissions';
import classNames from 'classnames';
import cn from 'classnames';
import { ContractPositionDeleteModal, ContractPositionEditFormValidationValues } from '..';

//type SubArea = 'Values' | 'Documents' | 'History';

interface ContractEditPositionSlideOverProps extends SlideOverOnCloseProps {
  contract: ContractReadModel;
  contractTitle: ContractTitleReadModel;
  position?: ContractTitlePositionReadModel;
}

export const ContractEditPositionSlideOver = ({
  onClose,
  contract,
  contractTitle,
  position
}: ContractEditPositionSlideOverProps) => {
  const { t } = useTranslation();

  const submitRef = useRef<HTMLButtonElement>(null);
  const formRef = useRef<FormRefHandle<ContractPositionEditFormValidationValues>>();

  const [update, { isLoading: isUpdating }] = useApiPostUpdateContractTitlePositionMutation();
  const [create, { isLoading: isCreating }] = useApiPostCreateContractTitlePositionMutation();


  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const loadedProject = useLoadedProject().currentData?.project.payload;

  const loadedProjectId = loadedProject?.id;
  const loadedVariantId = useLoadedVariantId();

  const defaultVat = contract.vat ?? loadedProject?.vat ?? 0;

  const canWrite = useValidateProjectPermission(['CONTRACT_WRITE'], loadedProjectId ?? '') && contract.gaebType !== 'X86';

  const canDelete =
    useValidateProjectPermission(['CONTRACT_DELETE'], loadedProjectId ?? '') &&
    !contract.isPxContract &&
    contract.gaebType !== 'X86';


  const handleSubmit = async (data: ContractPositionEditFormValidationValues, close: boolean) => {
    if (contract?.id && contractTitle?.id && loadedProjectId && loadedVariantId) {
      if (position) {
        try {
          await safeMutation(
            update,
            {
              contractId: contract.id,
              projectId: loadedProjectId,
              calculationModelId: loadedVariantId,
              body: { 
                  titlePositionId: position?.id ?? '', 
                  data: {
                    code: data.code,
                    name: data.name,
                    unit: data.unit,
                    amount: data.amount,
                    unitPrice: data.unitPrice,
                    vat: data.vat,
                } 
              },
            },
            isUpdating,
          );
          if (close) onClose(true);
        } catch (e) {
          console.error(e);
        }
      } else {
        try {
          await safeMutation(
            create,
            {
              contractId: contract.id,
              projectId: loadedProjectId,
              calculationModelId: loadedVariantId,
              body: {
                parentTitleId: contractTitle.id,
                data: {
                  code: data.code,
                  name: data.name,
                  unit: data.unit,
                  amount: data.amount,
                  unitPrice: data.unitPrice,
                  vat: data.vat,
                }
              },
            },
            isCreating,
          );
          if (close) onClose(true);
        } catch (e) {
          console.error(e);
        }
      }
    }
  };

  const defaultFormValues = {
    code: position?.code ?? '',
    name: position?.name ?? '',
    vat: position?.vat ?? defaultVat ?? 0,
    amount: position?.amount ?? 0,
    unit: position?.unit ?? '',
    unitPrice: position?.unitPrice ?? 0,
  };

  const handleDelete = () => {
    setIsDeleteModalOpen(true);
  };

  return (
    <>
      <SlideOver.Header
        title={position?.name ?? t('projectContarct.addNewPosition')}
        subTitle={position ? '' : t('projectContarct.addNewPositionDescription')}
        onClose={onClose}
        backgroundClassName="bg-gray-900"
      >
        <div className="flex flex-row pt-2 pl-8 bg-gray-900 text-white">
          {/* {!title?.hasChildren && !title?.isGaebTitle && (
            <button
              type="button"
              className={classNames('px-4 pb-1 text block border-l border-r', {
                'font-bold border-b-2 border-white': subarea === 'Values',
              })}
              onClick={() => setSubarea('Values')}
            >
              <div className="flex felx-row items-center">{t('common.values')}</div>
            </button>
          )}
          {title && !title.hasChildren && !title.isGaebTitle && (
            <>
              <button
                type="button"
                className={classNames('px-4 pb-1 text block border-r', {
                  'font-bold border-b-2 border-white': subarea === 'Documents',
                })}
                onClick={() => setSubarea('Documents')}
              >
                <div className="flex felx-row items-center">{t('common.documents')}</div>
              </button>
              <button
                type="button"
                className={classNames('px-4 pb-1 text block border-r', {
                  'font-bold border-b-2 border-white': subarea === 'History',
                })}
                onClick={() => setSubarea('History')}
              >
                <div className="flex felx-row items-center">{t('common.history')}</div>
              </button>
            </>
          )} */}
        </div>
      </SlideOver.Header>
      {!((isUpdating || isCreating) && (
          <LoadingIndicator text={t('projectContract.saveContractTitleLoadingIndicator')} mode="overlay" />
        ))}
      <SlideOver.Content>
        <Form<ContractPositionEditFormValidationValues>
          onSubmit={(data) => handleSubmit(data, true)}
          validationSchema={ContractTitleEditFormValidationSchema}
          defaultValues={defaultFormValues}
          className="w-full flex flex-col flex-grow min-h-0"
          ref={formRef}
        >
          <div>
            <div className="m-8">
              <div className="divide-gray-100 divide-y bg-white">
                <FormField name="code">
                  {(control) => (
                    <TextInput
                      label={t('common.code')}
                      icon={<ContractNumberIcon className="h-6 w-6" />}
                      disabled={!canWrite}
                      {...control}
                    />
                  )}
                </FormField>
                <FormField name="name">
                  {(control) => (
                    <TextInput
                      label={t('common.name')}
                      icon={<RenameIcon className="h-6 w-6" />}
                      disabled={!canWrite}
                      {...control}
                    />
                  )}
                </FormField>
              </div>

              <div className="divide-gray-100 divide-y mt-8 bg-white">
                <FormField name="unit">
                  {(control) => (
                    <TextInput
                      label={t('projectCalculate.earningsElementLabelUnit')}
                      icon={<RenameIcon className="h-6 w-6" />}
                      disabled={!canWrite}
                      {...control}
                    />
                  )}
                </FormField>
                <FormField name="amount">
                  {(control) => (
                    <TextInput
                      label={t('projectCalculate.earningsElementLabelAmount')}
                      icon={<BillIcon className="h-6 w-6" />}
                      disabled={!canWrite}
                      {...control}
                    />
                  )}
                </FormField>
                <FormField name="unitPrice">
                  {(control) => (
                    <TextInput
                      label={t('common.unitPrice')}
                      icon={<PriceTagIcon className="h-6 w-6" />}
                      disabled={!canWrite}
                      {...control}
                    />
                  )}
                </FormField>                
              </div>
            </div>
          </div>
        </Form>
      </SlideOver.Content>
      <SlideOver.Controls>
        <div className={cn('w-full flex gap-2', canDelete && position ? 'justify-between' : 'justify-end')}>
          {canDelete && position && (
            <Button onClick={handleDelete} variant="warning">
              {t('common.delete')}
            </Button>
          )}
          <div className={cn('w-full flex justify-end gap-2')}>
            
              <Button onClick={() => onClose(false)} variant="secondary">
                {t('common.cancel')}
              </Button>
            
            <div className="flex justify-end gap-2">
              {canWrite && (
                <Button
                  variant="primary"
                  innerRef={submitRef}
                  onClick={() => {
                    formRef?.current?.submitForm();
                    const values = formRef.current?.getValues();
                    if(values) {
                      handleSubmit(values, true);
                    }
                  }}
                >
                  {t('common.save')}
                </Button>
              )}
            </div>
          </div>
        </div>
      </SlideOver.Controls>

       <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
        {position && (
          <ContractPositionDeleteModal
            onClose={(close) => {
              setIsDeleteModalOpen(false);
              if (close) {
                onClose(close);
              }
            }}
            contract={contract}
            position={position}
          />
        )}
      </Modal>
    </>
  );
};
