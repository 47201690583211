import classNames from 'classnames';
import React, { Fragment, PropsWithChildren, ReactNode, useState } from 'react';
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
  Transition
} from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { Modal, ConfirmModal } from '.';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

export interface SlideOverOnCloseProps {
  onClose: (confirm: boolean) => void;
}

interface SlideOverCloseModalProps extends PropsWithChildren {
  onClose: (success: boolean) => void;
  onAbort: (success: boolean) => void;
  title?: string;
  description?: string | ReactNode;
  confirmText?: string;
  cancelText?: string;
}

export const SlideOverCloseModal = ({ onClose, onAbort, title, description, confirmText, cancelText }: SlideOverCloseModalProps) => {
  const { t } = useTranslation();

  const handleOnClose = () => {
    onClose(false);
  };

  const handleOnAbort = () => {
    onAbort(false);
  };

  return (
    <ConfirmModal
      title={typeof title !== 'undefined' ? title : t('slideOver.CloseWithoutSaving')}
      description={description}
      onConfirm={handleOnClose}
      onClose={handleOnAbort}
      confirmVariant="danger"
      cancelVariant="text"
      confirmText={confirmText ?? t('common.close')}
      cancelText={cancelText ?? t('common.cancel')}
      size="small"
    />
  );
};

interface SlideOverDialogProps extends SlideOverOnCloseProps {
  isOpen: boolean;
  hideBackdrop?: boolean;
  variant?: 'default' | 'small' | 'large' | 'custom' | 'x-large' | '2xl';
  className?: string;
  onAfterLeave?: () => void;
  zIndex?: string;
  confirmBeforeClose?: boolean;
  preventClickOutsideClose?: boolean;
  initialFocus?: React.RefObject<HTMLElement>;
  cancelConfirmTitle?: string;
  cancelConfirmDescription?: string | ReactNode;
  confirmButtonText?: string;
  cancelButtonText?: string;
}

export const SlideOverDialog = ({
  children,
  hideBackdrop,
  isOpen,
  onClose,
  variant = 'default',
  className,
  onAfterLeave,
  zIndex = 'z-30',
  confirmBeforeClose = false,
  preventClickOutsideClose = false,
  initialFocus,
  cancelConfirmTitle,
  cancelConfirmDescription,
  confirmButtonText,
  cancelButtonText
}: PropsWithChildren<SlideOverDialogProps>) => {
  const [isCloseModalOpen, setIsCloseModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsCloseModalOpen(true);
  };

  const handleClose = () => {
    setIsCloseModalOpen(false);
    onClose(true);
  };

  const handleAbort = () => {
    setIsCloseModalOpen(false);
  };

  return (
    <Transition show={isOpen} as={Fragment} unmount={true} appear={true}>
      <Dialog
        open={isOpen}
        initialFocus={initialFocus ? initialFocus : undefined}
        as="div"
        className={classNames('relative', zIndex)}
        onClose={
          confirmBeforeClose
            ? handleOpenModal
            : !preventClickOutsideClose
              ? handleClose
              : () => {
                  // eslint-disable-next-lines @typescript-eslint/no-empty-function
                }
        }
        static
      >
        {!hideBackdrop && (
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-500 bg-opacity-75 duration-500 sm:duration-700 transition-opacity ease-out data-[closed]:opacity-0"
          />
        )}
        <div className="fixed inset-0 overflow-hidden flex w-screen items-center justify-center">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <DialogPanel
                className={classNames(
                  'pointer-events-auto flex h-full flex-col overflow-hidden bg-white shadow-xl',
                  'transition ease-in-out',
                  'data-[enter]:duration-500 data-[enter]:sm:duration-700 data-[enter]:data-[closed]:translate-x-full',
                  'data-[leave]:duration-500 data-[leave]:sm:duration-700 data-[leave]:data-[closed]:translate-x-full',
                  {
                    'w-[320px]': variant === 'small',
                    'w-[469px]': variant === 'default',
                    'w-[1024px]': variant === 'large',
                    'w-[1170px]': variant === 'x-large',
                    'w-[1232px]': variant === '2xl',
                  },
                  className
                )}
                transition
                onTransitionEnd={() => {
                  if (onAfterLeave && !isOpen) {
                    onAfterLeave();
                  }
                }}
              >
                <div className="relative flex-grow min-h-0 flex flex-col">
                  <Modal
                    isOpen={isCloseModalOpen}
                    onClose={handleAbort}
                    showCloseButton={false}
                    variant="small-wide"
                  >
                    <SlideOverCloseModal
                      onClose={handleClose}
                      onAbort={handleAbort}
                      title={cancelConfirmTitle}
                      description={cancelConfirmDescription}
                      confirmText={confirmButtonText}
                      cancelText={cancelButtonText}
                    />
                  </Modal>

                  <div className="flex flex-col w-full h-full">{children}</div>
                </div>
              </DialogPanel>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

interface SlideOverHeaderProps extends PropsWithChildren {
  onClose: (success: boolean) => void;
  title: string | ReactNode
  subTitle?: string | ReactNode;
  className?: string;
  backgroundClassName?: string;
  showCloseButton?: boolean;
  additionalContentBefore?: ReactNode | string;
  truncateTitle?: boolean;
}

export const SlideOverHeader = ({
  backgroundClassName = 'bg-red-700',
  className,
  children,
  subTitle,
  title,
  onClose,
  showCloseButton = true,
  additionalContentBefore,
  truncateTitle = true,
}: SlideOverHeaderProps) => (
  <>
    <DialogTitle className={classNames('w-full text-white pt-8 pb-4 px-8 relative', backgroundClassName, className)}>
      {additionalContentBefore}
      {subTitle && <div className="text-xs font-bold">{subTitle}</div>}
      <div className={cn('text-4xl font-medium', truncateTitle ? 'truncate' : '')}>{title}&nbsp;</div>
      {showCloseButton && (
        <div className="absolute p-2 top-0 right-0 z-50" onClick={() => onClose(false)}>
          <XMarkIcon className="w-8 h-8 hover:text-gray-600 transition-color duration-200 cursor-pointer" />
        </div>
      )}
    </DialogTitle>
    {children}
  </>
);

interface SlideOverContentProps extends PropsWithChildren {
  className?: string;
  isLoading?: boolean;
  onKeyEnter?: () => void;
}

export const SlideOverContent = ({ children, className, onKeyEnter }: SlideOverContentProps) => (
  <div
    className={classNames('bg-gray-100 w-full flex-grow overflow-y-auto flex flex-col relative', className)}
    onKeyDown={
      onKeyEnter
        ? (e) => {
            if (e.key === 'Enter') {
              onKeyEnter();
            }
          }
        : undefined
    }
  >
    {children}
  </div>
);

interface SlideOverControlsProps extends PropsWithChildren {
  className?: string;
  disabled?: boolean;
}

export const SlideOverControls = ({ children, disabled, className }: SlideOverControlsProps) => (
  <div className={classNames('px-8 py-4 flex justify-end mt-auto', { 'pointer-events-none': disabled }, className)}>
    {children}
  </div>
);

export const SlideOver = Object.assign(SlideOverDialog, {
  Header: SlideOverHeader,
  Content: SlideOverContent,
  Controls: SlideOverControls,
});
