import { Routes, Route, Navigate } from 'react-router-dom';
import { MultiDashboardRoute } from '@client/project/dashboard';
import { ProtectedRoute, ROUTES_CONFIG } from '@client/shared/permissions';
import { MultiReportingRoute } from '@client/project/reporting';

/**
 * MultiProjectRoute is a module entrypoint (we are talking about one specific project)
 * It tries to ensure valid data at a top point and keep sub routes from having to deal
 * with query parameters / path segments
 */
export const MultiProjectRoutes = () => {
  return (
    <Routes>
      <Route
        path={ROUTES_CONFIG.MULTI_PROJECT_DASHBOARD.name}
        element={
          <ProtectedRoute routeConfig={ROUTES_CONFIG.MULTI_PROJECT_DASHBOARD}>
            <MultiDashboardRoute />
          </ProtectedRoute>
        }
      />
      <Route
        path={`${ROUTES_CONFIG.MULTI_PROJECT_REPORTING.name}/*`}
        element={
          <ProtectedRoute routeConfig={ROUTES_CONFIG.MULTI_PROJECT_REPORTING}>
            <MultiReportingRoute />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<Navigate to={ROUTES_CONFIG.MULTI_PROJECT_DASHBOARD.name} replace />} />
    </Routes>
  );
};
