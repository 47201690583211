import { apiEndpointUrls, DealDocumentReadModel, DealType } from '@client/shared/api';
import { useMemo, useState } from 'react';
import {
  Modal,
  DocumentListItem
} from '@client/shared/toolkit';
import { DealDocumentDeleteModal } from './DealDocumentDeleteModal';
import { useCanDeleteDeal } from '../../hooks';
import { useLoadedProjectId } from '@client/project/store';

interface DealDocumentRowProps {
  dealId: string;
  type: DealType;
  dealDocument: DealDocumentReadModel;
  isReadOnly: boolean;
}

const apiEndpoints = {
  'Sell': apiEndpointUrls.apiGetSellDealDocument,
  'Rent': apiEndpointUrls.apiGetRentDealDocument,
};

export const DealDocumentRow = ({ dealId, type, dealDocument, isReadOnly }: DealDocumentRowProps) => {
  const loadedProjectId = useLoadedProjectId();
  const canDelete = useCanDeleteDeal(type);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const downloadUrl = useMemo(() => {
    const endpoint = apiEndpoints[type];
    if (!endpoint) return '';

    return endpoint
      .replace(':projectId', loadedProjectId as string)
      .replace(':dealId', dealId)
      .replace(':documentId', dealDocument.id);
  }, [type, loadedProjectId, dealId, dealId]);

  return (
    <>
      <DocumentListItem
        fileName={dealDocument.name}
        fileSize={dealDocument.size}
        created={dealDocument.created}
        downloadUrl={downloadUrl}
        setIsDeleteModalOpen={canDelete ? setIsDeleteModalOpen : undefined}
        contextMenu={!isReadOnly}
      />
      <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)} variant="small">
        <DealDocumentDeleteModal
          dealId={dealId}
          type={type}
          document={dealDocument}
          onClose={() => setIsDeleteModalOpen(false)}
        />
      </Modal>
    </>
  );
};
