import * as yup from 'yup';
import { InferType } from 'yup';
import { WidgetDashboardEditFormProps } from '.';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  AddButton,
  BooleanInput,
  DatePicker,
  Form,
  FormField,
  FormRefHandle,
  SlideOver,
  SlideOverTitle,
  TextInput,
  TrashIcon,
} from '@client/shared/toolkit';
import { formatDateOnly, i18n } from '@client/shared/utilities';
import { UpsertTaskPayload } from '@client/shared/api';

const DEFAULT_TASK: UpsertTaskPayload = {
  name: i18n.t('dashboard.widget.tasks.task') ?? 'Task',
  completed: false,
  date: formatDateOnly(new Date()),
};

export const WidgetDashboardEditFormTasksValidationSchema = yup.object({
  title: yup.string().required('validation.required'),
  size: yup.string().required('validation.required'),
  tasks: yup
    .array()
    .of(yup.mixed<UpsertTaskPayload>().required('validation.required'))
    .min(1)
    .required('validation.required'),
});

export type WidgetDashboardEditFormTasksValidationValues = InferType<
  typeof WidgetDashboardEditFormTasksValidationSchema
>;

export const WidgetEditFormTasks = (props: WidgetDashboardEditFormProps) => {
  const { buttons, widget, handleSubmit, selectedSize } = props;
  const { t } = useTranslation();

  const formRef = useRef<FormRefHandle<WidgetDashboardEditFormTasksValidationValues>>();
  const [tasks, setTasks] = useState<UpsertTaskPayload[]>([DEFAULT_TASK]);

  useEffect(() => {
    setTasks(widget?.widget.additionalConfig?.Tasks ?? [DEFAULT_TASK]);
  }, [widget?.widget.additionalConfig?.Tasks]);

  const defaultFormValues = useMemo(() => {
    return {
      title: widget?.widget.title ?? t('dashboard.widget.tasks.title'),
      size: selectedSize,
      tasks: widget?.widget.additionalConfig?.Tasks ?? [DEFAULT_TASK],
    };
  }, [t, widget?.widget.title, selectedSize, widget?.widget.additionalConfig?.Tasks]);

  const onAdd = () => {
    if (tasks.length < 10) {
      const copy = [...tasks];
      copy.push(DEFAULT_TASK);
      setTasks(copy);
      formRef.current?.setValue('tasks', copy);
    }
  };

  const onUpdateTask = (data: UpsertTaskPayload, index: number) => {
    if (tasks[index]) {
      const copy = [...tasks];
      copy[index] = data;
      setTasks(copy);
      formRef.current?.setValue('tasks', copy);
    }
  };

  const onDeleteTask = (index: number) => {
    if (tasks[index]) {
      const copy = [...tasks];
      copy.splice(index, 1);
      setTasks(copy);
      formRef.current?.setValue('tasks', copy);
    }
  };

  return (
    <Form<WidgetDashboardEditFormTasksValidationValues>
      onSubmit={handleSubmit}
      validationSchema={WidgetDashboardEditFormTasksValidationSchema}
      defaultValues={defaultFormValues}
      className="flex flex-col flex-grow min-h-0"
      ref={formRef}
    >
      <SlideOver.Content className="p-8">
        <FormField name="title">
          {(control) => <TextInput label={t('dashboard.widget.edit.title')} {...control} />}
        </FormField>

        {tasks.map((task, index) => (
          <TaskForm
            key={`widget-tasks-form-${index}`}
            task={task}
            index={index}
            onUpdateTask={(data: UpsertTaskPayload) => onUpdateTask(data, index)}
            canDelete={tasks.length > 1}
            onDelete={() => onDeleteTask(index)}
          />
        ))}
        {tasks.length < 10 && (
          <div className="flex w-full justify-end items-center z-50 relative">
            <AddButton onClick={onAdd} className="-mt-4" />
          </div>
        )}
      </SlideOver.Content>
      {buttons}
    </Form>
  );
};

export interface TaskFormProps {
  task: UpsertTaskPayload;
  onUpdateTask: (data: UpsertTaskPayload) => void;
  index: number;
  onDelete: () => void;
  canDelete: boolean;
}

export const TaskForm = (props: TaskFormProps) => {
  const { task, index, onDelete, canDelete, onUpdateTask } = props;
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex justify-between items-center">
        <SlideOverTitle title={`${t('dashboard.widget.tasks.task')} ${index + 1}`} />
        {canDelete && <TrashIcon className="text-gray-500 w-5 h-5 mt-4 cursor-pointer" onClick={onDelete} />}
      </div>
      <TextInput
        label={t('dashboard.widget.edit.title')}
        value={task.name}
        onChange={(val) => {
          const copy = { ...task };
          copy.name = val;
          onUpdateTask(copy);
        }}
      />
      <DatePicker
        label={t('dashboard.widget.tasks.date')}
        value={task.date}
        onChange={(val) => {
          const copy = { ...task };
          copy.date = val ? formatDateOnly(val) : '';
          onUpdateTask(copy);
        }}
      />
      <BooleanInput
        label={t('dashboard.widget.tasks.checked')}
        value={task.completed}
        onChange={(val) => {
          const copy = { ...task };
          copy.completed = val;
          onUpdateTask(copy);
        }}
        variant="checkbox-left"
      />
    </div>
  );
};
