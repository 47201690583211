import { sortByProperty, SortHeaderType } from '@client/shared/utilities';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  YardiAccessConfigReadModel,
  YardiProjectConnectionReadModel,
} from '@client/shared/api';
import { API_MAPPING, ApiClientMappingList } from '../ApiClients/ApiClientMappingList';

interface YardiClientMappingsProps {
  projectId?: string;
  apiAccessId?: string;
  connectionData: YardiAccessConfigReadModel | YardiProjectConnectionReadModel | undefined | null;
}

export const YardiClientMappings = ({ connectionData }: YardiClientMappingsProps) => {
  const { t } = useTranslation();
  const defaultSortHeader: SortHeaderType[] = useMemo(() => {
    return [
      {
        asc: true,
        label: t('app.settingsApiYardiProperty'),
        type: 'string',
        value: API_MAPPING.YARDI_PROPERTY_NAME,
      },
      {
        asc: null,
        label: t('app.settingsApiDatevConnectionProbisObjectId'),
        type: 'string',
        value: API_MAPPING.PROBIS_PROPERTY_NAME,
      },
      {
        asc: null,
        label: t('app.settingsApiDatevConnectionCustomField'),
        type: 'string',
        value: API_MAPPING.USER_DEFINED_FIELD_NAME,
      },
      {
        asc: null,
        label: t('app.settingsApiDatevConnectionDefaultValue'),
        type: 'string',
        value: API_MAPPING.DEFAULT_VALUE,
      },
    ];
  }, [t]);

  const [sortHeader, setSortHeader] = useState<SortHeaderType[]>(defaultSortHeader);

  const yardiProbisMappings = useMemo(() => {
    return connectionData?.mappings ?? [];
  }, [connectionData?.mappings]);

  const sortedYardiProbisMappings = useMemo(() => {
    const sortBy = sortHeader.find((item) => item.asc !== null);
    let sortedMappings = [...(yardiProbisMappings ?? [])];
    if (sortBy) {
      return (sortedMappings = sortByProperty(sortedMappings, sortBy));
    } else {
      return sortedMappings;
    }
  }, [yardiProbisMappings, sortHeader]);

  const mappingTypes = useMemo(
    () => [...new Set(yardiProbisMappings.map((mapping) => mapping.type))],
    [yardiProbisMappings],
  );

  const onHandleSort = useCallback(
    (index: number) => {
      const currentSortValues = [...sortHeader];
      const update = currentSortValues[index];
      update.asc = update.asc === null ? true : !update.asc;
      currentSortValues.forEach((val, i) => {
        if (i !== index) {
          val.asc = null;
        }
      });
      currentSortValues[index] = update;
      setSortHeader(currentSortValues);
    },
    [sortHeader],
  );

  return (
    <div className="relative flex flex-col gap-5">
      {mappingTypes.length > 0 ? (
        mappingTypes.map((mappingType, idx) => (
          <ApiClientMappingList
            mappingType={API_MAPPING.YARDI}
            mappings={sortedYardiProbisMappings.filter((item) => item.type === mappingType)}
            title={mappingType}
            sortHeader={idx === 0 ? sortHeader : undefined}
            onHandleSort={idx === 0 ? onHandleSort : undefined}
            key={idx}
          />
        ))
      ) : (
        <div className="text-center text-gray-400">{t('app.settingsApiDatevNoConnections')}</div>
      )}
    </div>
  );
};
