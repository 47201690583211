import { USDollarCircleDottedIcon } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { useGetCurrencySymbol } from '@client/project/store';
import { DoubleDataFeed } from './ProjectMetadata';

interface ProjectPerMtrStatisticsFeed {
  avgCostPerSqMtr: string;
  avgearningPerSqMtr: string;
  unit: string;
}

const ProjectPerMtrStatisticsFeed = (props: ProjectPerMtrStatisticsFeed) => {
  const { t } = useTranslation();
  const currentCurrencySymbol = useGetCurrencySymbol()
  return (
    <DoubleDataFeed
      icon={<USDollarCircleDottedIcon className="w-full h-full" />}
      leftTitle={t('project.costPerSqMtr') + ` ${props.unit  === 'Metric'? 'm²' : 'ft²'}`}
      leftValue={ props.avgCostPerSqMtr.replace('€', currentCurrencySymbol)}
      leftFooter={<>&nbsp;</>}
      rightTitle={t('project.earningPerSqMtr') + ` ${props.unit  === 'Metric'? 'm²' : 'ft²'}`}
      rightValue={props.avgearningPerSqMtr.replace('€', currentCurrencySymbol)}
      rightFooter={<>&nbsp;</>}
    />
  );
};

export default ProjectPerMtrStatisticsFeed;
