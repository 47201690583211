import { useParams } from 'react-router-dom';
import { Button, HintBox, LoadingIndicator } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useApiGetAcceptUserInvitationQuery, useApiGetChallengeQuery } from '@client/shared/api';

export const AcceptInvitationView = () => {
  const { t } = useTranslation();
  const { tokenId } = useParams();
  const [fetchLoginUrl, setFetchLoginUrl] = useState(false);

  const {
    data: acceptInvitationResponse,
    isFetching: isProcessingInvitation,
    isError: acceptInvitationError,
    isSuccess: acceptInvitationSuccess,
    status: acceptInvitationStatus
  } = useApiGetAcceptUserInvitationQuery({
    tokenId: tokenId ?? ''
  });

  const { data: challengeResponse, isFetching: isFetchingLoginUrl } = useApiGetChallengeQuery(
      { returnUrl: '' },
      { skip: !fetchLoginUrl },
    );

  useEffect(() => {
    if (acceptInvitationError) {
      setFetchLoginUrl(true);
    } else if (acceptInvitationSuccess && acceptInvitationResponse?.redirectUrl) {
      setTimeout(() => {
        window.location.href = acceptInvitationResponse.redirectUrl;
      }, 5000);
    }
  }, [acceptInvitationResponse, acceptInvitationError, acceptInvitationSuccess]);

  return (
    <>
      {(isProcessingInvitation || isFetchingLoginUrl || (acceptInvitationStatus === 'rejected' && !challengeResponse)) && (
        <div className="flex h-full justify-center"><LoadingIndicator text={t('auth.invitationProcessing')} /></div>
      )}
      {acceptInvitationStatus === 'rejected' && !isFetchingLoginUrl && (
        <>
          <HintBox hintType="danger" title={t('error.tenant_trust.invitation_error.title')}>
            {t('error.tenant_trust.invitation_error.content')}
          </HintBox>
          <div className="flex justify-end mt-5">
            <Button
              onClick={(evt) => {
                evt.preventDefault();
                if (challengeResponse) {
                  window.location.href = challengeResponse.challengeUrl as string;
                }
              }}
              variant="primary"
            >
              {t('auth.toLogin')}
            </Button>
          </div>
        </>
      )}
      {acceptInvitationSuccess && (
        <HintBox hintType="info" title={t('auth.invitation')} className='bg-green-200'>
          {t('auth.invitationRedirectInfo')}
        </HintBox>
      )}
    </>
  );
};
