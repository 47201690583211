import { useDocumentTitle, TitleBar } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { useProjects } from '@client/project/store';
import { ProtectedRoute, ROUTES_CONFIG } from '@client/shared/permissions';
import { MultiReportBenchmarking, MultiReportingTabs } from '../components';
import { settings } from '@client/shared/store';

export const MultiReportingRoute = () => {
  const { t } = useTranslation();

  useDocumentTitle({ title: t('reporting.routeTitle') });

  const { id } = useParams<{
    id: string;
  }>();
  const { groups } = useProjects();
  const group = groups.find((g) => g.id === id);

  if (!group || !id) {
    return null;
  }

  return (
    <>
      <TitleBar
        title={group?.name ?? t('reporting.multiReportingTitle')}
        subTitle={t('reporting.multiReportingSubTitle')}
      >
        {settings.devMode && <MultiReportingTabs projectId={id} />}
      </TitleBar>
      <div className="flex-grow flex flex-col overflow-hidden">
        <div id="pdf-content" className="p-8 flex-1 flex flex-col flex-grow overflow-y-auto pdf-export-overflow-hidden">
          <Routes>
            <Route
              path={ROUTES_CONFIG.MULTI_PROJECT_REPORTING_BENCHMARKING.name}
              element={
                <ProtectedRoute routeConfig={ROUTES_CONFIG.MULTI_PROJECT_REPORTING_BENCHMARKING}>
                  <MultiReportBenchmarking group={group} />
                </ProtectedRoute>
              }
            />
            <Route
              path="*"
              element={<Navigate to={ROUTES_CONFIG.MULTI_PROJECT_REPORTING_BENCHMARKING.name} replace />}
            />
          </Routes>
        </div>
      </div>
    </>
  );
};
