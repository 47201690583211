import { DefaultValuesReadModel, useApiGetDefaultValuesQuery } from '@client/shared/api';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditButton, getCurrencyLabelForCurrency, LoadingIndicator, SlideOver } from '@client/shared/toolkit';
import { DefaultValuesBenchmarkingEditSlideOver } from './DefaultValuesBenchmarkingEditSlideOver';
import { countryCodes } from '@client/shared/utilities';

export const DefaultValuesBenchmarking = ({ defaultValues }: { defaultValues?: DefaultValuesReadModel }) => {
  const { t } = useTranslation();
  const [isBenchmarkingSlideOverOpen, setIsBenchmarkingSlideOverOpen] = useState<boolean>(false);
  const { data: response, isFetching } = useApiGetDefaultValuesQuery(undefined, {
    skip: typeof defaultValues !== 'undefined',
  });
  const defaultBenchmarkingValues = useMemo(() => {
    return defaultValues ?? response;
  }, [defaultValues, response]);

  if (!defaultBenchmarkingValues) {
    return null;
  }

  return (
    <div className="pl-10">
      <div className="flex flex-row items-center py-1 gap-2">
        <span className="font-bold">{t('projectSettings.benchmarking.increaseConstructionCosts')}:</span>
        {defaultBenchmarkingValues?.constructionCostIncreases?.length ? defaultBenchmarkingValues?.constructionCostIncreases.map((item) => countryCodes.getCountryName(item.countryCode)).join(', ') : '-'}
      </div>
      <div className="flex flex-row items-center py-1 gap-2">
        <span className="font-bold">{t('projectSettings.benchmarking.rentIncrease')}:</span>
        {defaultBenchmarkingValues?.rentalIncreases?.length ? defaultBenchmarkingValues?.rentalIncreases.map((item) => countryCodes.getCountryName(item.countryCode)).join(', ') : '-'}
      </div>
      <div className="flex flex-row items-center py-1 gap-2">
        <span className="font-bold">{t('projectSettings.benchmarking.salesIncreases')}:</span>
        {defaultBenchmarkingValues?.salesIncreases?.length ? defaultBenchmarkingValues?.salesIncreases.map((item) => countryCodes.getCountryName(item.countryCode)).join(', ') : '-'}
      </div>
      <div className="flex flex-row items-center py-1 gap-2">
        <span className="font-bold">{t('projectSettings.benchmarking.currencyConversion')}:</span>
        {defaultBenchmarkingValues?.currencyConversions?.length ? defaultBenchmarkingValues?.currencyConversions.map((item) => getCurrencyLabelForCurrency(item.currency)).join(', ') : '-'}
      </div>
      <div className="absolute top-5 right-10">
        <EditButton className="ml-auto" onClick={() => setIsBenchmarkingSlideOverOpen(true)} />
      </div>
      {isFetching && <LoadingIndicator text={t('app.masterDataDefaultValues.Loading')} />}
      <SlideOver isOpen={isBenchmarkingSlideOverOpen} onClose={() => setIsBenchmarkingSlideOverOpen(false)} variant="x-large">
        <DefaultValuesBenchmarkingEditSlideOver defaultValues={defaultBenchmarkingValues} onClose={() => setIsBenchmarkingSlideOverOpen(false)} />
      </SlideOver>
    </div>
  );
};
