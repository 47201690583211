import { DatevWizard } from './DatevWizard';
import { useNavigate } from 'react-router-dom';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { useDatevAuthState } from '@client/project/store';

export const DatevWizardView = ({ apiId, apiAccessId }: { apiId?: string; apiAccessId?: string }) => {
  const navigate = useNavigate();
  const datevAuthState = useDatevAuthState();

  const handleOnClose = () => {
    navigate(ROUTES_CONFIG.SETTINGS_CONNECT.path);
  };

  return (
    <DatevWizard
      isOpen={!!apiId}
      onClose={handleOnClose}
      apiId={apiId ?? ''}
      apiAccessId={apiAccessId}
      isReset={datevAuthState?.datevWizardType === 'Reset'}
      datevAuthState={datevAuthState}
    />
  );
};
