import classNames from 'classnames';
import React, { PropsWithChildren, useId } from 'react';
import { getNumberSeparators, isEmpty } from '@client/shared/utilities';
import { FormHelperText } from './FormHelperText';
import { CheckBox } from './CheckBox';
import { FlipSwitch } from './FlipSwitch';

interface CheckBoxInputProps extends PropsWithChildren {
  label: string;
  subLabel?: string;
  value?: boolean | null;
  onChange: (value: boolean) => void;
  icon?: React.ReactNode;
  className?: string;
  labelClassName?: string;
  disabled?: boolean;
  name?: string;
  autoComplete?: string;
  showValidation?: boolean;
  isValidationValid?: boolean;
  helperText?: string;
  variant?: 'checkbox' | 'checkbox-left' | 'switch' | 'switch-left';
  helperTextTruncate?: boolean;
}

const localeSettings: { groupSeparator: string | undefined; decimalSeparator: string | undefined } = {
  groupSeparator: undefined,
  decimalSeparator: undefined,
};

export const BooleanInput = ({
  autoComplete,
  className,
  labelClassName,
  disabled,
  icon,
  isValidationValid,
  label,
  name,
  onChange,
  showValidation,
  value,
  helperText,
  subLabel,
  variant,
  helperTextTruncate = true,
  children,
}: CheckBoxInputProps) => {
  const inputId = useId();
  const [isFocused, setIsFocused] = React.useState(false);

  if (localeSettings.decimalSeparator === undefined) {
    const { decimalSeparator, groupSeparator } = getNumberSeparators();

    localeSettings.groupSeparator = groupSeparator;
    localeSettings.decimalSeparator = decimalSeparator;
  }

  const handleOnChangeValues = () => {
    if (onChange) {
      onChange(!value);
    }
  };

  const handleFocusChange = (focus: boolean) => {
    setIsFocused(focus);
  };

  const Component = variant === 'switch' || variant === 'switch-left' ? FlipSwitch : CheckBox;

  return (
    <div className={className}>
      <div
        className={classNames('w-full relative h-14 px-3 flex flex-row bg-white cursor-pointer', {
          'shadow-[inset_0px_0px_0px_1px] shadow-red-500':
            showValidation && isValidationValid != null && !isValidationValid,
          'shadow-[inset_0px_0px_0px_1px] shadow-green-500': showValidation && isValidationValid,
        })}
      >
        {icon && (
          <div className='flex items-center h-full'>
            <div className='h-5 w-5 flex items-center justify-center'>{icon}</div>
          </div>
        )}
        <div
          className={classNames('relative flex-grow flex flex-row-reverse items-center', {
            'ml-2': icon,
            'mx-1': !icon,
            'justify-end': variant === 'checkbox-left' || variant === 'switch-left',
          })}
        >
          <Component
            id={inputId}
            checked={value ?? false}
            disabled={disabled}
            autoComplete={autoComplete}
            name={name}
            onFocus={() => handleFocusChange(true)}
            onBlur={() => handleFocusChange(false)}
            onChange={handleOnChangeValues}
          />
          {children}
          <label
            htmlFor={inputId}
            className={classNames(
              'absolute top-0 left-0 right-0 text-lg duration-200 origin-0 font-medium select-none transform truncate pr-6 text-left',
              labelClassName,
              {
                'pt-3 mt-[3px]': isEmpty(subLabel),
                'pt-4 mt-[4px]': !isEmpty(subLabel),
                'pl-8': variant === 'checkbox-left',
                'pl-12': variant === 'switch-left',
                'text-gray-800 cursor-pointer': !disabled,
                'text-gray-400': disabled,
              },
            )}
          >
            {label}
            {subLabel && (
              <div className='transform text-xs -translate-y-10 font-normal text-gray-600 -mt-px truncate'>
                {subLabel}
              </div>
            )}
          </label>
        </div>
        <div
          className={classNames('absolute bottom-0 h-0.5 bg-black left-0 right-0 duration-300 transition-opacity', {
            'opacity-0': !isFocused,
          })}
        />
      </div>
      {helperText && (
        <div className='w-full bg-white'>
          <FormHelperText text={helperText} error={!isValidationValid} truncate={helperTextTruncate} />
        </div>
      )}
    </div>
  );
};
