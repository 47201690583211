import { PropsWithChildren, ReactNode, useEffect, useRef } from 'react';
import cn from 'classnames';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { useClickAway } from 'react-use';

export interface DocumentViewerFileDataGroupProps extends PropsWithChildren {
  className?: string;
  divider?: boolean;
  isOpen?: boolean;
  onClick?: () => void;
  toggledContent?: ReactNode;
  toggledContentClassName?: string;
  allowChangeMode?: boolean
}

export const DocumentViewerFileDataGroup = (props: DocumentViewerFileDataGroupProps) => {
  const {
    className,
    children,
    divider = true,
    isOpen = false,
    onClick,
    toggledContent,
    toggledContentClassName,
    allowChangeMode = true
  } = props;

  const ref = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (buttonRef.current) {
      if (
        (isOpen && buttonRef.current.dataset.headlessuiState !== 'open') ||
        (!isOpen && buttonRef.current.dataset.headlessuiState === 'open')
      ) {
        buttonRef.current.click();
      }
    }
  }, [isOpen]);

  useClickAway(ref, () => {
    if (buttonRef.current && buttonRef.current.dataset.headlessuiState === 'open' && allowChangeMode) {
      buttonRef.current.click()
    }
  })

  if (toggledContent) {
    return (
      <Disclosure as="div" defaultOpen={isOpen} ref={ref}>
        <div
          className={cn(
            'rounded-md w-full shadow flex flex-col bg-white overflow-hidden px-6 py-4',
            { 'divide-y-2': divider,},
            className,
          )}
          onClick={onClick}
        >
          <DisclosureButton as="div" className="w-full" ref={buttonRef}>
            {children}
          </DisclosureButton>
          <DisclosurePanel
            className={cn('bg-gray-100 -mx-6 -mb-4 px-6 py-4 mt-4 shadow-inner', toggledContentClassName)}
          >
            {toggledContent}
          </DisclosurePanel>
        </div>
      </Disclosure>
    );
  }

  return (
    <div
      className={cn(
        'rounded-md w-full shadow flex flex-col bg-white overflow-hidden px-6 py-4 border-1',
        { 'divide-y-2': divider },
        className,
      )}
    >
      {children}
    </div>
  );
};
