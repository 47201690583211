import { Tabs } from '@client/shared/toolkit';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CatalogTemplates } from './CatalogTemplates';
import { SettingsProjectTemplates } from './SettingsProjectTemplates';
import { useSearchParams } from 'react-router-dom';

export const SettingsTemplates = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedIndex, setSelectedIndex] = useState(searchParams.get('tab') ? searchParams.get('tab') === 'catalog' ? 0 : 1 : 0);

  useEffect(() => {
    searchParams.set('tab', selectedIndex === 0 ? 'catalog' : 'project');
    setSearchParams(searchParams, { replace: true });
  }, [selectedIndex, searchParams, setSearchParams]);

  const tabs = useMemo(() => {
    return [
      {
        header: t('templates.catalogTemplatePageTitle'),
        panel: <CatalogTemplates />
      },
      {
        header: t('templates.projectTemplate.title'),
        panel: <SettingsProjectTemplates />
      },
    ];
  }, [t]);

  return (
    <Tabs
      options={tabs}
      selectedIndex={selectedIndex}
      tabChange={(tab) => setSelectedIndex(tab)}
    />
  );
}
