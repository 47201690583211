import { PropsWithChildren, ReactNode } from 'react';
import { ContextMenuItem, DragItem } from '@client/shared/toolkit';
import { useDrag, useDrop } from 'react-dnd';

export enum BENCHMARK_DRAG_TYPE {
  ROW = 'row',
  COLUMN = 'column',
}

export interface BenchmarkingDragDropItemProps extends PropsWithChildren {
  id: string;
  index: number;
  title: string;
  subtitle: string | ReactNode;
  contextMenuItems?: ContextMenuItem[];
  dropItem?: (id: string, toIndex: number, type: BENCHMARK_DRAG_TYPE) => void;
  isDragDisabled?: boolean;
  isLast?: boolean;
  type: BENCHMARK_DRAG_TYPE;
  className?: string;
  hoverItem?: (index: number | null, type: BENCHMARK_DRAG_TYPE) => void;
  isHovered?: boolean;
}

export const BenchmarkingDragDropItem = (props: BenchmarkingDragDropItemProps) => {
  const { id, index, dropItem, isDragDisabled = true, type, className, children, hoverItem } = props;
  const [{ opacity }, drag] = useDrag(
    () => ({
      type: type,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
      }),
      item: () => {
        return { id, index };
      },
      canDrag: !isDragDisabled && !!dropItem,
      end: (item, monitor) => {
        if (item.index !== index && dropItem && !monitor.didDrop() && hoverItem) {
          hoverItem(null, type);
        }
      },
    }),
    [],
  );

  const [, drop] = useDrop(() => ({
    accept: type,
    hover: (draggedItem: DragItem) => {
      if (draggedItem && draggedItem.index !== index) {
        // Update the dragged item's index
        draggedItem.index = index;
        if (hoverItem) {
          hoverItem(index, type);
        }
      }
    },
    drop: (element: DragItem) => {
      if (dropItem) {
        dropItem(element.id, element.index, type);
        if (hoverItem) {
          hoverItem(null, type);
        }
      }
    },
    canDrop: () => !isDragDisabled && !!dropItem,
  }),[index, type, dropItem, hoverItem, isDragDisabled]);

  return (
    <div className={className} ref={(node) => drag(drop(node))} style={{ opacity }}>
      {children}
    </div>
  );
};
