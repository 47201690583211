import {
  api,
  ApiGetChallengeApiResponse,
  ApiGetExchangeApiResponse,
  ApiPostCheckSessionApiResponse,
  ApiPostCreateUserInvitationApiResponse
} from '@client/shared/api';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import {
  finishAppInit,
  getAppUser,
  login,
  logout,
  settings,
  startChallenge,
  UserState
} from '@client/shared/store';
import { getLocale, i18n, setLanguageAndLocale, wait } from '@client/shared/utilities';
import { PayloadAction } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { fork, put, select, take, takeEvery } from 'redux-saga/effects';
import { Userpilot } from 'userpilot';
import { removeCookie } from 'typescript-cookie';

function* onUserLoggedOut() {
  // react to certain known calls and ensure that the user is logged out
  yield takeEvery(
    [
      api.endpoints.apiPostLogout.matchFulfilled,
      api.endpoints.apiPostLogoutSessions.matchFulfilled,
      api.endpoints.apiPostLogout.matchRejected,
      api.endpoints.apiPostLogoutSessions.matchRejected,
      api.endpoints.apiPostLogin.matchRejected,
      api.endpoints.apiPostCheckSession.matchRejected,
      api.endpoints.apiPostRefreshToken.matchRejected,
    ],
    function* () {
      yield put(logout());
    },
  );
}
function* onLogout() {
  yield takeEvery([logout], function* () {
    removeCookie('datev_auth', { path: '/', domain: settings.mainDomain }); // TODO use constant COOKIE_DATEV_AUTH_DATA
    yield put(api.util.resetApiState());
    yield triggerChallenge('');
  });
}

function* onGetRefreshToken() {
  yield takeEvery(
    [api.endpoints.apiPostRefreshToken.matchFulfilled],
    function* () {
      const appUser: UserState = yield select(getAppUser);
      if (!appUser?.userId) {
        yield validateServerSession();
      } else {
        yield put(finishAppInit());
      }
    },
  );

}

function* onCheckSessionAuthenticated() {
  yield takeEvery(
    [api.endpoints.apiPostCheckSession.matchFulfilled],
    function* (action: PayloadAction<ApiPostCheckSessionApiResponse>) {

      yield put(
        login({
          userId: action.payload.user?.userId,
          firstName: action.payload?.user?.firstName ?? '',
          lastName: action.payload?.user?.lastName ?? '',
          email: action.payload?.user?.email ?? '',
          hasAvatar: action.payload?.user?.hasAvatar,
          lastUpdate: action.payload?.user?.lastUpdate,
          language: action.payload?.user?.language ?? undefined,
          tenant: action.payload.tenant ?? undefined,
          tenants: action.payload.tenants,
          permissions: action.payload?.tenantPermissions ?? undefined,
        }),
      );

      const userLanguage = action.payload.user?.language;
      if (userLanguage) {
        yield setLanguageAndLocale(userLanguage);
      }

      //TODO: hopefully remove this again when we decide against userpilot
      Userpilot.identify(action.payload.user?.userId || '', {
        email: action.payload.user?.email,
        name: `${action.payload.user?.firstName} ${action.payload.user?.lastName}`,
        locale_code: getLocale(userLanguage ?? 'en').code,
      });

      yield true;
    },
  );
}

function* onGetChallengeFulfilled() {
  // If there is a SSO realm configured, redirect to the challenge URL to authenticate
  yield takeEvery(
    [api.endpoints.apiGetChallenge.matchFulfilled],
    function* (action: PayloadAction<ApiGetChallengeApiResponse>) {
      const isDevMode = settings.devMode;
      if (!window.location.href.includes(ROUTES_CONFIG.SSO.path) && !window.location.href.includes('accept-invitation') && action?.payload?.challengeUrl && !isDevMode) {
        yield (window.location.href = action.payload.challengeUrl);
        yield wait(1000);
      }
      yield put(finishAppInit());

    },
  );
}

function* onGetChallengeRejected() {
  yield takeEvery(
    [api.endpoints.apiGetChallenge.matchRejected],
    function* (_: PayloadAction<ApiGetChallengeApiResponse>) {
      yield put(finishAppInit());
    },
  );
}


function* onGetExchangeFulfilled() {
  // Exchanged SSO code for a session, now set the tokens and redirect to the returnUrl
  yield takeEvery(
    [api.endpoints.apiGetExchange.matchFulfilled],
    function* (_: PayloadAction<ApiGetExchangeApiResponse>) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      yield put(api.endpoints.apiPostCheckSession.initiate(undefined, {}) as any);
    },
  );
}

function* onInvitationChange() {
  yield takeEvery(
    [api.endpoints.apiPostCreateUserInvitation.matchFulfilled],
    function* (_: PayloadAction<ApiPostCreateUserInvitationApiResponse>) {
      toast.success(i18n.t('auth.notificationInvitationSent'));
      yield;
    },
  );
}

function* validateServerSession() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield put(api.endpoints.apiPostCheckSession.initiate(undefined, {}) as any);
  yield take([
    api.endpoints.apiPostCheckSession.matchFulfilled,
    api.endpoints.apiPostCheckSession.matchRejected,
  ]);
  yield put(finishAppInit());
}

function* triggerChallenge(returnUrl: string) {
  yield put(startChallenge());
  yield put(
    api.endpoints.apiGetChallenge.initiate(
      {
        returnUrl: returnUrl
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ) as any,
  );
}

export const authSaga = function* () {
  yield fork(onCheckSessionAuthenticated);
  yield fork(onGetChallengeFulfilled);
  yield fork(onGetChallengeRejected);
  yield fork(onGetExchangeFulfilled);
  yield fork(onGetRefreshToken);
  yield fork(onUserLoggedOut);
  yield fork(onInvitationChange);
  yield fork(onLogout);
};
