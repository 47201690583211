import { Button, FileInput, LoadingIndicator, SlideOver, SlideOverOnCloseProps } from '@client/shared/toolkit';
import {
  InvoiceYardiDataRecord,
  useApiPostImportYardiInvoicesMutation,
  useApiPostParseYardiInvoiceFileMutation,
} from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { safeMutation } from '@client/shared/utilities';
import { useEffect, useState } from 'react';
import cn from 'classnames';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { InvoiceYardiImportRecord } from './InvoiceYardiImportRecord';
import toast from 'react-hot-toast';

export const ImportYardiInvoicesSlideOver = ({ onClose }: SlideOverOnCloseProps) => {
  const { t } = useTranslation();

  const [uploadFile, setUploadFile] = useState<File | undefined>();
  const [uploadFileError, setUploadFileError] = useState(false);
  const [invoiceToImport, setinvoiceToImport] = useState<InvoiceYardiDataRecord[]>([]);
  const [importErrors, setImportErrors] = useState<string[]>([]);

  const [apiUploadYardiInvoiceFile, { isLoading: isLoadingFile }] = useApiPostParseYardiInvoiceFileMutation();
  const [apiImportYardiIncoices, { isLoading: isLoadingImport }] = useApiPostImportYardiInvoicesMutation();

  const loadedProjectId = useLoadedProjectId();
  const loadedCalculationModelId = useLoadedVariantId();

  const handleFileChange = (fileList: FileList | null) => {
    setinvoiceToImport([]);
    setImportErrors([]);
    if (fileList && fileList.length > 0) {
      setUploadFile(fileList[0]);
    } else {
      setUploadFile(undefined);
    }
  };

  useEffect(() => {
    const handleUploadYardiFile = async () => {
      if (uploadFile) {
        setinvoiceToImport([]);
        setImportErrors([]);
        try {
          const formData = new FormData();
          formData.append('file', uploadFile);

          const result = await safeMutation(
            apiUploadYardiInvoiceFile,
            {
              projectId: loadedProjectId ?? '',
              calculationModelId: loadedCalculationModelId ?? '',
              body: formData as unknown as { file: Blob },
            },
            isLoadingFile,
          );

          setinvoiceToImport(result?.invoices ?? []);
          setImportErrors(result?.errors ?? []);
        } catch (e) {
          console.log(e);
        }
      }
    };

    if (!uploadFileError) {
      handleUploadYardiFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadFile]);

  const handleImportYardiInvoice = () => {
    try {
      apiImportYardiIncoices({
        projectId: loadedProjectId ?? '',
        calculationModelId: loadedCalculationModelId ?? '',
        body: invoiceToImport ?? []
      }).unwrap()
      .then((data) => {
          
          if(data.errorElements.length === 0)
          {
            toast.success(t('projectCalculate.notificationInvoiceImportSuccess'));
            onClose(true);
          }
          else
          {
            toast.error(t('app.importRecordsError'));
            // setRecords(data.errorElements);
          }
      })
    } catch {
      /* left blank */
    }
  };

  return (
    <>
      {(isLoadingFile || isLoadingImport) && (
        <LoadingIndicator text={t('projectControl.fetchingInvoiceLoadingIndicator') ?? ''} mode="overlay" />
      )}

      <SlideOver.Header
        title={t('projectControl.invoiceYardiImport')}
        backgroundClassName="bg-gray-600"
        onClose={() => onClose(false)}
      />
      <SlideOver.Content className="p-8 h-full">
        <div className="m-0">
          <div className="divide-gray-100 divide-y">
            <div className="bg-white p-2">
              <FileInput
                acceptedFileTypes={['text/csv', '.csv']}
                multiple={false}
                onChange={handleFileChange}
                setError={setUploadFileError}
              />
            </div>
            {invoiceToImport && invoiceToImport.length > 0 && (
              <>
                <br />
                <div className="mt-2 w-full flex bg-white shadow rounded-tl rounded-bl">
                  <div className={cn('w-2 h-auto rounded-tl-md rounded-bl-md flex-none bg-blue-600')} />
                  <div className="flex w-full flex-col text-primary">
                    {invoiceToImport.map((item, i) => (
                      <div className="ml-5" key={`invoice-${item.probisInvoiceID}-${i}`}>
                        <InvoiceYardiImportRecord element={item} />
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
            {importErrors && importErrors.length > 0 && (
              <>
                <br />
                <div className="mt-2 w-full flex bg-white shadow rounded-tl rounded-bl">
                  <div
                    className={cn('w-2 h-auto rounded-tl-md rounded-bl-md flex-none')}
                    style={{ backgroundColor: `red` }}
                  />
                  <div className="flex w-full flex-col text-0" style={{ marginLeft: '5px' }}>
                    {t('projectControl.datevExportErrors')}
                    <br />
                    <br />
                    <div>
                      {importErrors.map((item, i) => (
                        <>{t(item)}</>
                      ))}
                    </div>
                    <br />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </SlideOver.Content>

      <SlideOver.Controls>
        <div className="flex justify-end">
          <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
            {t('common.cancel')}
          </Button>
          <Button
            variant="primary"
            disabled={
              !invoiceToImport?.length
            }
            onClick={() => handleImportYardiInvoice()}
          >
            {t('common.import')}
          </Button>
        </div>
      </SlideOver.Controls>
    </>
  );
};
