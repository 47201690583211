import { Navigate, Route, Routes } from 'react-router-dom';
import { ProtectedRoute, ROUTES_CONFIG } from '@client/shared/permissions';
import { ProjectTemplateCalculate } from './Calculate';
import { ProjectTemplateReports } from './Reports';

export const ProjectTemplateRoutes = () => {
  return (
    <Routes>
      <Route
        path={`${ROUTES_CONFIG.SETTINGS_TEMPLATES_PROJECT_CALCULATE.name}`}
        element={
          <ProtectedRoute routeConfig={ROUTES_CONFIG.SETTINGS_TEMPLATES_PROJECT_CALCULATE}>
            <ProjectTemplateCalculate />
          </ProtectedRoute>
        }
      />
      <Route
        path={`${ROUTES_CONFIG.SETTINGS_TEMPLATES_PROJECT_REPORTS.name}`}
        element={
          <ProtectedRoute routeConfig={ROUTES_CONFIG.SETTINGS_TEMPLATES_PROJECT_REPORTS}>
            <ProjectTemplateReports />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<Navigate to={ROUTES_CONFIG.SETTINGS_TEMPLATES_PROJECT_CALCULATE.name} replace />} />
    </Routes>
  );
};
