import { useNavigate, useParams } from 'react-router-dom';
import { Button, HintBox, LoadingIndicator } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ProbisErrorDataType, useApiGetChallengeQuery, useApiGetExchangeQuery } from '@client/shared/api';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

export const SsoView = () => {
  const { t } = useTranslation();
  const { realm } = useParams();
  const navigate = useNavigate();
  const [fetchLoginUrl, setFetchLoginUrl] = useState(false);
  const [timeoutLoading, setTimeoutLoading] = useState(false);

  const searchParams = new URLSearchParams(window.location.search);
  const code = searchParams.get('code');
  const returnUrl = searchParams.get('returnUrl');
  const state = searchParams.get('state');

  const { data: exchangeData, isFetching, isError, isSuccess, error } = useApiGetExchangeQuery({
    realm: realm ?? '',
    code: code ?? '',
    state: state ?? '',
    returnUrl: returnUrl ?? ''
  });

  const { data: challengeResponse, isFetching: isFetchingLoginUrl } = useApiGetChallengeQuery(
    { returnUrl: '' },
    { skip: !fetchLoginUrl },
  );

  useEffect(() => {
    if (isError) {
      setFetchLoginUrl(true);
    } else if (isSuccess && exchangeData?.returnUrl) {
      setTimeout(() => {
        navigate(exchangeData.returnUrl);
      }, 2000);
      setTimeoutLoading(true);
    }
  }, [isError, isSuccess, exchangeData, navigate]);

  return (
      (isFetching || isFetchingLoginUrl || timeoutLoading) && (
        <div className="flex h-full justify-center"><LoadingIndicator text={t('auth.loginInProgress')} /></div>
      ) ||
      isError && error && !isFetchingLoginUrl && (
        <>
          <HintBox hintType="danger" title={t('auth.ssoUnauthenticated')}>
            {t(((error as FetchBaseQueryError).data as ProbisErrorDataType).code as string || 'error.general.unknown')}
          </HintBox>
          <div className="flex justify-end mt-5">
            <Button
              onClick={(evt) => {
                evt.preventDefault();
                if (challengeResponse) {
                  window.location.href = challengeResponse.challengeUrl as string;
                }
              }}
              variant="primary"
            >
              {t('auth.toLogin')}
            </Button>
          </div>
        </>
      )
    );
};
