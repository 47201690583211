import {
  Badge,
  DealTasksIcon,
  FormDottedIcon,
  LOGO_MENU_IMAGE,
  ProjectMenuIcon,
  useComponentDimensions
} from '@client/shared/toolkit';
import React, { createRef, ReactElement, useCallback, useMemo, useRef, useState } from 'react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { usePopper } from 'react-popper';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SideNavigationItem, RouteNavType, ROUTES_CONFIG } from '@client/shared/permissions';
import { RequirementShortReadModel, useApiGetClusterItemsQuery } from '@client/shared/api';
import { useLoadedPortfolio } from '@client/portfolio/store';
import { useUi } from '@client/shared/store';
import { checkIsReporter, getClusterItemIdsForUser, isAssetManager } from '@client/portfolio/shared';

const constructPath = (slug: string, portfolioId?: string) => {
  if (!portfolioId) {
    return '';
  }
  return `${ROUTES_CONFIG.PORTFOLIO.path}/${portfolioId}/${slug}`;
};

const navigationItems: RouteNavType[] = [
  {
    label: 'portfolio.menu.projecting',
    icon: <FormDottedIcon />,
    routeConfig: ROUTES_CONFIG.PORTFOLIO_PROJECTING,
  },
  {
    label: 'portfolio.menu.property',
    icon: <ProjectMenuIcon />,
    routeConfig: ROUTES_CONFIG.PORTFOLIO_PROPERTY,
  },
  {
    label: 'portfolio.menu.myTasks',
    icon: <DealTasksIcon />,
    routeConfig: ROUTES_CONFIG.PORTFOLIO_TASKS,
  },
  /* {
    label: 'portfolio.menu.timeline',
    icon: <VerticalTimelineDottedIcon className="transform rotate-90" />,
    routeConfig: ROUTES_CONFIG.PORTFOLIO_TIMELINE
  } */
];

export const PortfolioSideNavigation = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const ui = useUi();

  const { id: portfolioId } = useParams<{ id: string }>();

  const { data: loadedPortfolio } = useLoadedPortfolio();

  const { data: clusterItemsResponse } = useApiGetClusterItemsQuery(
    {
      portfolioId: portfolioId ?? '',
    },
    {
      skip: !portfolioId,
    },
  );

  const portfolio = useMemo(() => {
    return loadedPortfolio?.portfolio;
  }, [loadedPortfolio?.portfolio]);

  const menuListElementRef = createRef<HTMLDivElement>();
  const dimensions = useComponentDimensions(menuListElementRef);

  const [targetElement, setTargetElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: 'right',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['right-start', 'bottom'],
          rootBoundary: 'viewport',
        },
      },
    ],
  });

  const menuItemHeight = Math.round(4 * 16);

  const maxItems = Math.max(0, Math.floor((dimensions?.height ?? 0) / menuItemHeight) - 1);

  const visibleItems = navigationItems.slice(0, maxItems);
  const hiddenItems = navigationItems.slice(maxItems);

  const showMore = hiddenItems.length > 0;

  const popoverButtonRef = useRef<HTMLDivElement>(null);
  const closePopper = useCallback(() => {
    if (popperElement) {
      popoverButtonRef?.current?.click();
    }
  }, [popperElement]);

  const subTitle = useMemo(() => {
    if (location.pathname.includes('projecting')) {
      return t('portfolio.menu.projecting');
    } else if (location.pathname.includes('property')) {
      return t('portfolio.menu.property');
    }
    return t('app.portfolio');
  }, [location.pathname, t]);

  const tasksBadge = useMemo(() => {
    if (clusterItemsResponse?.clusterItems && ui.appUser.userId) {
      const userClusterItemIds = getClusterItemIdsForUser(ui.appUser.userId);

      const requirements = clusterItemsResponse.clusterItems.reduce((requirements, clusterItem) => {
        if (userClusterItemIds.includes(clusterItem.id)) {
          return [...requirements, ...clusterItem.requirements.filter((req) =>
            // only the requirements the asset manager is allowed to see
            (isAssetManager(ui.appUser.userId) && req.state === 'Submitted') ||
            // only the requirements the requirement reporter is allowed to see
            (checkIsReporter(req.reportedById, req.reportedByDeputyId, ui.appUser.userId) && req.state === 'Rejected')
          )];
        }
        return requirements;
      }, [] as RequirementShortReadModel[]);
      if (requirements.length) {
        return <Badge text={requirements.length.toString()} variant="danger" className="absolute top-0.5 right-0 mr-1" />
      }
    }
    return null;
  }, [clusterItemsResponse?.clusterItems, ui.appUser.userId])

  return (
    <nav className="pdf-export-hidden flex flex-wrap lg:flex-nowrap lg:flex-col items-center bg-white text-gray-700 shadow-lg justify-between lg:justify-start lg:h-full w-full lg:w-16 z-10 border-b lg:border-b-0">
      <div className="h-16 w-16 lg:w-full flex justify-center items-center lg:border-b border-r flex-shrink-0">
        <NavLink className="mx-4" to={ROUTES_CONFIG.PORTFOLIOS.path}>
          <img className="mx-auto" src={LOGO_MENU_IMAGE} alt="Logo Menu" />
        </NavLink>
      </div>
      <div className="lg:hidden min-w-0 flex-1 truncate px-4 text-black">
        <h1 className="text-xl font-bold truncate">{portfolio?.name ?? t('app.portfolio')}</h1>
        <h2 className="text-xs text-gray-500">{subTitle}</h2>
      </div>
      <div className="lg:flex-none overflow-hidden lg:flex-grow lg:w-full" ref={menuListElementRef}>
        <ul className="w-full flex flex-wrap lg:flex-nowrap lg:flex-col justify-end lg:justify-start">
          {visibleItems.map((item) => (
            <li key={item.routeConfig.name}>
              <SideNavigationItem
                passDataCy={`portfolio-navigation-${item.routeConfig.name}`}
                icon={item.icon as ReactElement}
                path={constructPath(item.routeConfig.name, portfolioId)}
                label={item.label}
                onNavItemClick={closePopper}
                routeConfig={item.routeConfig}
                additionalContent={item.routeConfig.name === 'tasks' ? tasksBadge : undefined}
              />
            </li>
          ))}
          {showMore && (
            <Popover as="li">
              <div className="w-full" ref={setTargetElement}>
                <PopoverButton className="w-full">
                  <div
                    ref={popoverButtonRef}
                    className="h-16 px-2 flex flex-col justify-center items-center w-full text-xs font-medium border-r-4 border-l-4 border-l-transparent border-r-transparent hover:bg-secondary/10 transition-colors duration-200 lg:border-b cursor-pointer"
                  >
                    <EllipsisVerticalIcon className="w-8" />
                  </div>
                </PopoverButton>
              </div>
              <PopoverPanel
                portal
                ref={setPopperElement}
                style={{ ...styles.popper }}
                {...attributes.popper}
                className="z-20 flex bg-white outline-none shadow"
              >
                <ul className="flex flex-row flex-wrap lg:flex-nowrap w-screen lg:w-auto border-t lg:border-t-0">
                  {hiddenItems.map((item) => (
                    <li
                      key={item.routeConfig.name}
                      className="w-1/4 lg:w-auto border-b lg:border-b-0 border-r lg:border-r-0"
                    >
                      <SideNavigationItem
                        passDataCy={`portfolio-navigation-${item.routeConfig.name}`}
                        icon={item.icon as ReactElement}
                        path={constructPath(item.routeConfig.name, portfolioId)}
                        label={item.label}
                        onNavItemClick={closePopper}
                        routeConfig={item.routeConfig}
                        additionalContent={item.routeConfig.name === 'tasks' ? tasksBadge : undefined}
                      />
                    </li>
                  ))}
                </ul>
              </PopoverPanel>
            </Popover>
          )}
        </ul>
      </div>
    </nav>
  );
};
