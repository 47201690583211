import {
  BgfIcon,
  ChequeIcon,
  CityBuildingsDottedIcon,
  FullScreenIcon,
  LoadingIndicator,
  RoeCustomIcon,
  RoiCustomIcon,
  TimesheetDottedIcon,
  WorldwideLocationDottedIcon
} from '@client/shared/toolkit';
import { FontLight } from './FontLight';
import { ProjectStartEndFeed } from './ProjectStartEndFeed';
import React, { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import { formatDate, formatNumber, formatUnit, monthDiff } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';
import { ProjectPlannedFeed } from './ProjectPlannedFeeds';
import classNames from 'classnames';
import ProjectPerMtrStatisticsFeed from './ProjectPerMtrStatisticsFeed';
import { useUnitSystem } from '@client/project/store';
import {
  ElementUserDefinedFieldDefinitionReadModel,
  useApiProjectGetUserDefinedFieldsDefinitionByElementQuery,
} from '@client/shared/api';
import cn from 'classnames';

interface DataInfoProps {
  icon: ReactNode;
  header?: ReactNode;
  title: ReactNode | string;
  footer?: ReactNode;
  className?: string;
  variant?: 'default' | 'small';
}

export const DataFeed = (props: DataInfoProps) => {
  const iconClasses = props.variant === 'small' ? 'w-4' : 'w-10 h-10 flex-none';
  return (
    <div className={cn('flex justify-start flex-auto py-4 mx-4 gap-2', props.className)}>
      {props.icon && (
        <div className={cn('flex items-center flex-none text-slate-500 w-10 xl:w-14')}>
          {React.cloneElement(props.icon as ReactElement, { className: iconClasses })}
        </div>
      )}
      <div className="flex flex-col gap-y-1 justify-center flex-grow">
        {props.header}
        <div className="title font-bold text-lg leading-none">{props.title}</div>
        {props.footer}
      </div>
    </div>
  );
};

interface DoubleDataFeedProps {
  icon: ReactNode;
  leftTitle: string;
  leftValue: string;
  leftFooter: ReactNode | string;
  rightTitle: string;
  rightValue: string;
  rightFooter: ReactNode | string;
}

export const DoubleDataFeed = (props: DoubleDataFeedProps) => (
  <div className="flex flex-row py-1 gap-2 h-20 mx-4">
    {props.icon && (
      <div className={cn('flex items-center flex-none text-slate-500 w-10 xl:w-14')}>
        {React.cloneElement(props.icon as ReactElement, { className: 'w-10 h-10 flex-none'})}
      </div>
    )}
    <div className="flex flex-col gap-y-1 justify-center flex-grow pr-2">
      <div className="flex justify-between gap-2">
        <FontLight>{props.leftTitle}</FontLight>
        <FontLight classNames="text-right">{props.rightTitle}</FontLight>
      </div>
      <div className="flex justify-between gap-2">
        <span className="font-bold text-lg whitespace-nowrap leading-none">{props.leftValue}</span>
        <span className="font-bold text-lg whitespace-nowrap leading-none text-right">{props.rightValue}</span>
      </div>
      <div className="flex justify-between gap-2">
        <FontLight>{props.leftFooter}</FontLight>
        <FontLight>{props.rightFooter}</FontLight>
      </div>
    </div>
  </div>
);

const UsageTypeFeed = (props: { usageType: string; label: string }) => (
  <div className="w-full flex gap-2">
    <div className="w-auto flex justify-center items-center">
      {props.usageType === '-' || props.usageType === '0 %' ? (
        <div className="w-3 h-3 border-2 border-black rounded-full" />
      ) : (
        <div className="w-3 h-3 border-2 border-green-600 rounded-full" />
      )}
    </div>
    <div className="dot font-bold">
      {props.usageType === '-' ? `${props.usageType} 0% ${props.label}` : `- ${props.usageType} ${props.label}`}
    </div>
  </div>
);

interface DataContainerProps {
  bgf: string;
  grz: number;
  plot: string;
  gfz: number;
  avgCostPerSqMtr: string;
  avgEarningPerSqMtr: string;
  avgRentPerSqMtr: string;
  avgSalePerSqMtr: string;
  roi: string;
  irr: string;
  roe: string;
  ownCapitalRatio: string;
  plannedCostAndRisk: string;
  plannedCostUnit: string;
  plannedEarnings: string;
  deviation: string;
  deviationPercent: number | string;
  address: {
    street: string;
    number: string;
    city: string;
    postalCode: string;
  };
  startDate: string;
  endDate: string;
  deliveryPhasesCount: number;
  totalProfit: string | ReactNode;
  usageType: {
    work: string;
    residence: string;
    rest: string;
  };
  unitSystem: string;
  projectId: string;
}

const GroupingContainer = ({ children }: { children: ReactNode }) => (
  <div className="px-2 bg-white rounded-md shadow-md divide-y-2 border-1">{children}</div>
);

export const ProjectMetadata = (props: DataContainerProps) => {
  const { t } = useTranslation();

  const addressLine1 = `${props.address.street} ${props.address.number}`;
  const addressLine2 = `${props.address.postalCode} ${props.address.city}`;
  const unitSystem = useUnitSystem();

  const deviationTextClassNames = classNames(
    typeof props.deviationPercent === 'number' && props.deviationPercent < 1 && 'text-red-600',
    typeof props.deviationPercent === 'number' && props.deviationPercent >= 1 && 'text-green-600',
    'font-bold',
  );
  const months = useMemo(() => {
    return monthDiff(new Date(props.startDate), new Date(props.endDate));
  }, [props.startDate, props.endDate]);

  const [userDefinedFields, setUserDefinedFields] = useState<ElementUserDefinedFieldDefinitionReadModel[]>([]);

  const { data: userDefinedFieldsResponse, isFetching } = useApiProjectGetUserDefinedFieldsDefinitionByElementQuery(
    {
      projectId: props.projectId ?? 'unset',
      elementId: props.projectId,
      elementType: 'Project',
    },
    { skip: !props.projectId },
  );

  useEffect(() => {
    let udfs: ElementUserDefinedFieldDefinitionReadModel[] = [];
    if (userDefinedFieldsResponse?.userDefinedFieldsDefinition) {
      udfs = userDefinedFieldsResponse.userDefinedFieldsDefinition;
    }
    setUserDefinedFields(udfs);
  }, [userDefinedFieldsResponse]);

  return (
    <div className="w-full h-full grid grid-rows-[fit-content(2fr)_fit-content(2fr)] sm:grid-cols-2 auto-rows-min gap-x-3 gap-y-3">
      {isFetching && <LoadingIndicator text={t('common.loading') ?? ''} />}
      <GroupingContainer>
        <DataFeed
          header={<FontLight>{t('project.bgfRoi')}</FontLight>}
          footer={
            <FontLight>
              {t('project.grz')}{' '}
              {formatNumber(props.grz, {
                maxDigits: 1,
              })}
            </FontLight>
          }
          title={props.bgf}
          icon={<BgfIcon />}
        />
        <DataFeed
          header={<FontLight>{t('project.plot')}</FontLight>}
          footer={
            <FontLight>
              {t('project.gfz')}{' '}
              {formatNumber(props.gfz, {
                maxDigits: 1,
              })}
            </FontLight>
          }
          title={props.plot}
          icon={<FullScreenIcon />}
        />
        <ProjectPerMtrStatisticsFeed
          avgCostPerSqMtr={props.avgCostPerSqMtr}
          avgearningPerSqMtr={props.avgEarningPerSqMtr}
          unit={unitSystem}
        />
      </GroupingContainer>
      <GroupingContainer>
        <DataFeed
          header={<FontLight>{t('project.runtime')}</FontLight>}
          footer={<FontLight>{`${props.deliveryPhasesCount} ${t('project.phases')}`}</FontLight>}
          title={props.startDate && props.endDate ? `${months} ${t('common.month', { count: months })}` : '-'}
          icon={<TimesheetDottedIcon />}
        />
        <ProjectStartEndFeed startDate={props.startDate} endDate={props.endDate} />
        <DataFeed
          header={<FontLight>{t('common.address')}</FontLight>}
          title={
            <div className="flex flex-col">
              <FontLight>{addressLine1}</FontLight>
              <FontLight>{addressLine2}</FontLight>
            </div>
          }
          icon={<WorldwideLocationDottedIcon />}
          footer={<FontLight>&nbsp;</FontLight>}
        />
      </GroupingContainer>
      <GroupingContainer>
        <ProjectPlannedFeed
          plannedCostsAndRisks={props.plannedCostAndRisk}
          plannedEarnings={props.plannedEarnings}
          plannedCostsUnit={props.plannedCostUnit}
        />
        <DataFeed
          header={<FontLight>{t('projectTaxonomy.dashboardDeviation')}</FontLight>}
          footer={
            <FontLight classNames={deviationTextClassNames}>
              {typeof props.deviationPercent === 'number' ? formatUnit(props.deviationPercent, '%') : '-'}
            </FontLight>
          }
          title={props.deviation}
          icon={<ChequeIcon />}
        />
      </GroupingContainer>
      <GroupingContainer>
        <DoubleDataFeed
          icon={<RoiCustomIcon />}
          leftTitle={t('project.roi')}
          leftValue={props.roi}
          leftFooter={props.totalProfit}
          rightTitle={t('project.irr')}
          rightValue={props.irr}
          rightFooter={<>&nbsp;</>}
        />
        <DoubleDataFeed
          icon={<RoeCustomIcon />}
          leftTitle={t('project.ROELabel')}
          leftValue={props.roe}
          leftFooter={<>&nbsp;</>}
          rightTitle={t('project.owncapitalratio')}
          rightValue={props.ownCapitalRatio}
          rightFooter={<>&nbsp;</>}
        />
      </GroupingContainer>
      <GroupingContainer>
        <div className="w-full h-full relative">
          <CityBuildingsDottedIcon className="w-auto max-h-[calc((100%_/_3)_*_2)] absolute text-slate-200 z-0 right-5 bottom-0" />
          <div className="flex flex-col p-2 relative z-10 h-25">
            <div className="w-full">
              <FontLight>{t('project.typeOfUse')}</FontLight>
            </div>
            <UsageTypeFeed usageType={props.usageType.work} label={t('projectTaxonomy.usageType.Office')} />
            <UsageTypeFeed usageType={props.usageType.residence} label={t('projectTaxonomy.usageType.Residential')} />
            <UsageTypeFeed usageType={props.usageType.rest} label={t('project.usageType.Rest')} />
          </div>
        </div>
      </GroupingContainer>
      <GroupingContainer>
        {userDefinedFields.length > 0 &&
          userDefinedFields.map((udf) => {
            if (udf.userDefinedField) {
              return (
                <DataFeed
                  key={`project-udf-${udf.id}`}
                  header={<FontLight>{udf.name}</FontLight>}
                  title={
                    udf.userDefinedField
                      ? udf.userDefinedField?.date
                        ? formatDate(new Date(udf.userDefinedField.date))
                        : udf.userDefinedField?.text
                          ? udf.userDefinedField?.text
                          : udf.userDefinedField?.number
                            ? udf.userDefinedField?.number
                            : udf.userDefinedField?.listSelectedItem?.label ?? '-'
                      : '-'
                  }
                  icon=""
                />
              );
            }
            return '';
          })}
      </GroupingContainer>
    </div>
  );
};
