import {
  ApiDeleteCustomDashboardWidgetApiArg,
  ApiDeleteCustomDashboardWidgetApiResponse,
  ApiDeleteReportDashboardApiArg,
  ApiDeleteReportDashboardApiResponse,
  ApiDeleteReportDashboardWidgetApiArg,
  ApiDeleteReportDashboardWidgetApiResponse,
  ApiGetCustomDashboardApiArg,
  ApiGetCustomDashboardApiResponse,
  ApiGetReportDashboardApiArg,
  ApiGetReportDashboardApiResponse,
  ApiGetReportDashboardsApiArg,
  ApiGetReportDashboardsApiResponse,
  ApiGetReportDashboardsSummaryApiArg,
  ApiGetReportDashboardsSummaryApiResponse,
  ApiPostCreateCustomDashboardApiArg,
  ApiPostCreateCustomDashboardApiResponse,
  ApiPostCreateReportDashboardApiArg,
  ApiPostCreateReportDashboardApiResponse,
  ApiPostUpdateCustomDashboardApiArg,
  ApiPostUpdateCustomDashboardApiResponse,
  ApiPostUpdateReportDashboardApiArg,
  ApiPostUpdateReportDashboardApiResponse,
} from '../apiGenerated';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { ApiTagTypes } from '../apiTagTypes';

export const enhancedApiDashboardEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  apiGetCustomDashboard: {
    providesTags: (
      _result: ApiGetCustomDashboardApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetCustomDashboardApiArg,
    ) => [{ type: ApiTagTypes.CustomDashboard, id: args.projectId }],
  },
  apiGetReportDashboards: {
    providesTags: (
      _result: ApiGetReportDashboardsApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetReportDashboardsApiArg,
    ) => [{ type: ApiTagTypes.ReportDashboard, id: args.projectId }],
  },
  apiGetReportDashboardsSummary: {
    providesTags: (
      _result: ApiGetReportDashboardsSummaryApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetReportDashboardsSummaryApiArg,
    ) => [{ type: ApiTagTypes.ReportDashboard, id: args.projectId }],
  },
  apiGetReportDashboard: {
    providesTags: (
      _result: ApiGetReportDashboardApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetReportDashboardApiArg,
    ) => [{ type: ApiTagTypes.ReportDashboard, id: args.dashboardId }],
  },
  /*------------------ INVALIDATES TAGS --------------------*/
  apiDeleteCustomDashboardWidget: {
    invalidatesTags: (
      _result: ApiDeleteCustomDashboardWidgetApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteCustomDashboardWidgetApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.CustomDashboard, id: args.projectId }] : []),
  },
  apiPostCreateCustomDashboard: {
    invalidatesTags: (
      _result: ApiPostCreateCustomDashboardApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateCustomDashboardApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.CustomDashboard, id: args.projectId }] : []),
  },
  apiPostUpdateCustomDashboard: {
    invalidatesTags: (
      _result: ApiPostUpdateCustomDashboardApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateCustomDashboardApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.CustomDashboard, id: args.projectId },
            { type: ApiTagTypes.CustomDashboard, id: args.dashboardId },
          ]
        : [],
  },
  apiPostCreateReportDashboard: {
    invalidatesTags: (
      _result: ApiPostCreateReportDashboardApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateReportDashboardApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.ReportDashboard, id: args.projectId }] : []),
  },
  apiPostUpdateReportDashboard: {
    invalidatesTags: (
      _result: ApiPostUpdateReportDashboardApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateReportDashboardApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.ReportDashboard, id: args.projectId },
            { type: ApiTagTypes.ReportDashboard, id: args.dashboardId },
          ]
        : [],
  },
  apiDeleteReportDashboardWidget: {
    invalidatesTags: (
      _result: ApiDeleteReportDashboardWidgetApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteReportDashboardWidgetApiArg,
    ) => 
      error == null
        ? [
            { type: ApiTagTypes.ReportDashboard, id: args.projectId },
            { type: ApiTagTypes.ReportDashboard, id: args.dashboardId },
          ]
        : [],
  },
  apiDeleteReportDashboard: {
    invalidatesTags: (
      _result: ApiDeleteReportDashboardApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteReportDashboardApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.ReportDashboard, id: args.projectId }] : []),
  },
};
