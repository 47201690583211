import {
  CommitmentDocumentReadModel,
  useApiPostCreateCommitmentDocumentsMutation,
  useApiPostCreateCommitmentEmbedLinkDocumentsMutation,
} from '@client/shared/api';
import {
  CreateEmbeddedLinkFormValidationValues,
  DecoratedCard,
  ListTitle,
  LoadingIndicator,
  UploadFileTabs,
} from '@client/shared/toolkit';
import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CommitmentDocumentRow } from './CommitmentDocumentRow';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { safeMutation } from '@client/shared/utilities';

interface CommitmentDocumentsTabProps {
  commitmentId: string;
  documents: CommitmentDocumentReadModel[];
  canWrite: boolean;
  canDelete: boolean;
}

export const CommitmentDocumentsTab = ({
  commitmentId,
  documents,
  canWrite,
  canDelete,
}: CommitmentDocumentsTabProps) => {
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const loadedProjectId = useLoadedProjectId();
  const loadedCalculationModelId = useLoadedVariantId();

  const [postCommitmentDocuments, { isLoading: isLoadingCommitmentDocuments }] =
    useApiPostCreateCommitmentDocumentsMutation();

  const handleSaveDocuments = async (fileList: FileList | null) => {
    if (fileList) {
      const formData = new FormData();
      Array.from(fileList).forEach(file => formData.append('files', file));

      try {
        await safeMutation(
          postCommitmentDocuments,
          {
            projectId: loadedProjectId ?? 'unset',
            calculationModelId: loadedCalculationModelId ?? 'unset',
            commitmentId: commitmentId ?? 'unset',
            body: formData as unknown as { files: Blob[] },
          },
          isLoadingCommitmentDocuments,
        );

      } catch (e) {
        console.log(e, 'error');
      }
    }
  };

  const [postCommitmentEmbedLinkDocuments, { isLoading: isLoadingCommitmentEmbedLinkDocuments }] =
    useApiPostCreateCommitmentEmbedLinkDocumentsMutation();

  const handleAddEmbedLinkDocument = async (data: CreateEmbeddedLinkFormValidationValues) => {
    try {
      await safeMutation(
        postCommitmentEmbedLinkDocuments,
        {
          projectId: loadedProjectId ?? 'unset',
          calculationModelId: loadedCalculationModelId ?? 'unset',
          commitmentId: commitmentId ?? 'unset',
          embedFileName: data.fileName,
          embedUrlLink: data.fileLink,
        },
        isLoadingCommitmentEmbedLinkDocuments,
      );
    } catch (e) {
      console.log(e, 'error');
    }
  };

  const docList = useMemo(() => {
    return documents.filter((document) => !document.isEmbedLinkDocument);
  }, [documents]);

  const embedDocList = useMemo(() => {
    return documents.filter((document) => document.isEmbedLinkDocument);
  }, [documents]);

  return (
    <>
      {(isLoadingCommitmentDocuments || isLoadingCommitmentEmbedLinkDocuments) && (
        <LoadingIndicator text={t('common.uploading') ?? ''} mode="overlay" />
      )}

      <DecoratedCard shadowVariant="normal">
        <DecoratedCard.Content>
          <ListTitle title={t('projectCalculate.addElementDocument')} />
          <div className="bg-white p-3 rounded-sm">
            <UploadFileTabs
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
              acceptedFileTypes={[]}
              onAddEmbedLinkDocumentClick={handleAddEmbedLinkDocument}
              onUploadDocumentClick={handleSaveDocuments} // Upload action
              disabled={!canWrite}
            />
          </div>
        </DecoratedCard.Content>
      </DecoratedCard>

      <DecoratedCard className="mt-4" shadowVariant="normal">
        <DecoratedCard.Content className="mb-2">
          <ListTitle title={t('projectCalculate.elementDocument')} />
          {docList.length === 0 ? (
            <div className="flex flex-col items-center justify-center p-5 bg-white text-gray-500">
              {t('projectCalculate.elementDocumentsEmpty')}
            </div>
          ) : (
            <>
              {docList.map((document) => (
                <CommitmentDocumentRow
                  commitmentId={commitmentId}
                  commitmentDocument={document}
                  key={document.id}
                  canDelete={canDelete}
                />
              ))}
            </>
          )}
        </DecoratedCard.Content>
      </DecoratedCard>

      <DecoratedCard className="mt-4" shadowVariant="normal">
        <DecoratedCard.Content className="mb-2">
          <ListTitle title={t('projectCalculate.elementEmbedLinkDocument')} />
          {embedDocList.length === 0 ? (
            <div className="flex flex-col items-center justify-center p-5 bg-white text-gray-500">
              {t('projectCalculate.elementEmbedLinkDocumentsEmpty')}
            </div>
          ) : (
            <>
              {embedDocList.map((document) => (
                <CommitmentDocumentRow
                  commitmentId={commitmentId}
                  commitmentDocument={document}
                  key={document.id}
                  canDelete={canDelete}
                />
              ))}
            </>
          )}
        </DecoratedCard.Content>
      </DecoratedCard>
    </>
  );
};
