import {
  AuthProjectPermissionGroupReadModel,
  ProjectReadModel
} from '@client/shared/api';
import {
  ContextMenu,
  DecoratedCardAddButton,
  LoadingIndicator,
  Modal,
  PencilIcon,
  SettingsListHeader,
  SettingsListItem,
  SettingsListItemTitle,
  SlideOver,
  SortDownIcon,
  TextHighlighter,
  TrashIcon,
} from '@client/shared/toolkit';
import cn from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectUserGroupSlideOver } from './ProjectUserGroupSlideOver';
import { UserGroupIcon } from '@heroicons/react/24/outline';
import { ProjectGroupDeleteModal } from './ProjectGroupDeleteModal';
import { includesSearchValue } from '@client/shared/utilities';
import { useLoadedProjectPermissionGroups } from '@client/project/store';

export interface EditProjectUserGroupsProps {
  project?: ProjectReadModel;
  searchText?: string;
}

export const ProjectUserGroups = (props: EditProjectUserGroupsProps) => {
  const { project, searchText = '' } = props;
  const { t } = useTranslation();
  const [isSortedByNameAsc, setIsSortedByNameAsc] = useState(true);
  const [isOpenSlideOver, setIsOpenSlideOver] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<AuthProjectPermissionGroupReadModel | null>(null);

  const sortByFieldName = useCallback(() => {
    setIsSortedByNameAsc((prev) => !prev);
  }, []);

  const { data: permissionGroups, isFetching: isLoadingPermissionGroups } = useLoadedProjectPermissionGroups(false, project?.payload?.id);

  const allProjectPermissionGroup = useMemo(() => {
    return Array.from(
      new Set(permissionGroups?.flatMap((group) => group.assignedGroups.flatMap((userGroup) => userGroup.id))),
    );
  }, [permissionGroups]);

  const sortedProjectUserGroups = useMemo(() => {
    let sortedGroups = [...(permissionGroups ?? [])];
    if (sortedGroups.length) {
      sortedGroups.sort(function (a, b) {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (isSortedByNameAsc) {
          return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
        } else {
          return nameB < nameA ? -1 : nameB > nameA ? 1 : 0;
        }
      });
    }
    if (searchText) {
      sortedGroups = sortedGroups.filter((group) => {
        return includesSearchValue(group.name, searchText);
      });
    }
    return sortedGroups;
  }, [permissionGroups, isSortedByNameAsc, searchText]);

  return (
    <>
      <div className="my-5">
        {isLoadingPermissionGroups && <LoadingIndicator text={t('app.settingsLoadingUserGroups')} />}

        {!isLoadingPermissionGroups && permissionGroups && permissionGroups.length > 0 && (
          <>
            <SettingsListHeader>
              <div className="flex gap-4 items-center w-6/12 flex-none">
                <div className="w-8" />
                <div className="flex-1 flex justify-between items-center cursor-pointer" onClick={sortByFieldName}>
                  {t('app.settingsSortByNameLabel')}
                  <SortDownIcon
                    className={cn('text-slate-400 opacity-60 transition-transform duration-100 w-7', {
                      '-rotate-180': !isSortedByNameAsc,
                    })}
                  />
                </div>
              </div>
            </SettingsListHeader>
            <ul>
              {sortedProjectUserGroups.map((projectGroup) => (
                <SettingsListItem
                  key={`project-group-${projectGroup.id}`}
                  onClick={() => {
                    setSelectedGroup(projectGroup);
                    setIsOpenSlideOver(true);
                  }}
                  contextMenu={
                    <ContextMenu
                      items={[
                        {
                          label: t('common.edit'),
                          subtitle: t('projectSetting.userManagement.editProjectGroup.subtitle'),
                          icon: <PencilIcon />,
                          onClick: () => {
                            setSelectedGroup(projectGroup);
                            setIsOpenSlideOver(true);
                          },
                        },
                        {
                          label: t('common.delete'),
                          subtitle: t('projectSetting.userManagement.deleteProjectGroup.subtitle'),
                          icon: <TrashIcon />,
                          onClick: () => {
                            setSelectedGroup(projectGroup);
                            setIsOpenDeleteModal(true);
                          },
                        },
                      ]}
                    />
                  }
                >
                  <SettingsListItemTitle
                    className="w-6/12 flex-none"
                    icon={<UserGroupIcon className="w-full" />}
                    title={
                      searchText ? (
                        <TextHighlighter text={projectGroup.name} highlighted={[searchText]} />
                      ) : (
                        projectGroup.name
                      )
                    }
                  />
                </SettingsListItem>
              ))}
            </ul>
          </>
        )}
        {!isLoadingPermissionGroups && permissionGroups && !permissionGroups.length && (
          <div className="mt-4">{t('app.settingsNoProjectGroups')}</div>
        )}
      </div>
      <DecoratedCardAddButton onClick={() => setIsOpenSlideOver(true)} />

      <SlideOver
        isOpen={isOpenSlideOver}
        onClose={() => setIsOpenSlideOver(false)}
        onAfterLeave={() => setSelectedGroup(null)}
      >
        <ProjectUserGroupSlideOver
          group={selectedGroup}
          onClose={() => setIsOpenSlideOver(false)}
          projectId={project?.payload?.id ?? ''}
          allProjectPermissionGroup={allProjectPermissionGroup}
        />
      </SlideOver>
      <Modal
        isOpen={isOpenDeleteModal}
        onClose={() => setIsOpenDeleteModal(false)}
        onAfterLeave={() => setSelectedGroup(null)}
      >
        {selectedGroup && (
          <ProjectGroupDeleteModal
            groupId={selectedGroup.id}
            groupName={selectedGroup.name}
            onClose={() => setIsOpenDeleteModal(false)}
            projectId={project?.payload?.id ?? ''}
          />
        )}
      </Modal>
    </>
  );
};
