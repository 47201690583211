import {
  CostCatalogElementReadModel,
  EarningsCatalogElementReadModel,
  FinancingCatalogElementReadModel,
  RiskCatalogElementReadModel,
  TenantCatalogReadModel,
  useApiGetCustomBenchmarkPropertiesQuery,
  useApiMoveCatalogElementMutation,
} from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import React, { PropsWithChildren, useCallback, useMemo, useRef, useState } from 'react';
import {
  SlideOver,
  SlideOverOnCloseProps,
  ContextMenu,
  ToggleButton,
  Button,
  Modal,
  SettingsAddButton,
  PencilIcon,
  TrashIcon,
  AddIcon,
  UpwardArrowIcon,
  DownwardArrowIcon,
  LoadingIndicator,
  ContextMenuItem,
} from '@client/shared/toolkit';

import cn from 'classnames';
import {
  CatalogDeleteModal,
  CatalogElementCreateSlideOver,
  CostCatalogEditing,
  EarningsCatalogEditing,
  FinanceCatalogEditing,
  RiskCatalogEditing,
  SaveCatalogChanges,
} from '.';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { getTranslatedEarningsType } from '../../utils';
import { safeMutation } from '@client/shared/utilities';

export const CatalogHeader = ({ title, icon }: { title: string; icon: React.ReactNode }) => {
  return (
    <div className="flex flex-row items-center text-2xl w-full mb-5">
      <div className="w-7 flex-none">{icon}</div>
      <span className="ml-4 font-medium">{title}</span>
    </div>
  );
};

interface CatalogSectionProps extends PropsWithChildren {
  bgColor: string;
}

export const CatalogSection = ({ bgColor = 'bg-costs', children }: CatalogSectionProps) => {
  return (
    <div className="w-full flex bg-white shadow rounded-tl rounded-bl">
      <div className={cn('w-2 h-auto rounded-tl-md rounded-bl-md flex-none', bgColor)} />
      <div className="flex w-full flex-col text-primary">{children}</div>
    </div>
  );
};

interface CatalogSlideOverRowProps {
  catalog: TenantCatalogReadModel;
  catalogElement:
    | CostCatalogElementReadModel
    | FinancingCatalogElementReadModel
    | RiskCatalogElementReadModel
    | EarningsCatalogElementReadModel;
  handleEdit: (
    catalog:
      | CostCatalogElementReadModel
      | FinancingCatalogElementReadModel
      | RiskCatalogElementReadModel
      | EarningsCatalogElementReadModel,
  ) => void;
  addChild: (
    catalog:
      | CostCatalogElementReadModel
      | FinancingCatalogElementReadModel
      | RiskCatalogElementReadModel
      | EarningsCatalogElementReadModel,
  ) => void;
  handleDelete: (
    catalog:
      | CostCatalogElementReadModel
      | FinancingCatalogElementReadModel
      | RiskCatalogElementReadModel
      | EarningsCatalogElementReadModel,
  ) => void;
  level: number;
  idx: number;
  canMoveUp: boolean;
  canMoveDown: boolean;
  selectedMappedCatalog: string;
  handleEditMapping: (
    catalog:
      | CostCatalogElementReadModel
      | FinancingCatalogElementReadModel
      | RiskCatalogElementReadModel
      | EarningsCatalogElementReadModel,
  ) => void;
}

export const CatalogSlideOverRow = ({
  catalog,
  catalogElement,
  handleEdit,
  addChild,
  handleDelete,
  level,
  idx,
  canMoveDown,
  canMoveUp,
  selectedMappedCatalog,
  handleEditMapping,
}: CatalogSlideOverRowProps) => {
  const { t } = useTranslation();

  const [moveCatalogElement, { isLoading: isMovingCatalogElement }] = useApiMoveCatalogElementMutation();

  const handleMove = useCallback(
    async (direction: 'up' | 'down', elementId: string) => {
      if (elementId) {
        try {
          await safeMutation(
            moveCatalogElement,
            {
              body: {
                catalogId: catalog.id,
                type: catalog.type,
                elementId: elementId,
                newIndexInParent: direction === 'up' ? idx - 1 : idx + 1,
              },
            },
            isMovingCatalogElement,
          );
        } catch (e) {
          console.log(e);
        }
      }
    },
    [moveCatalogElement, catalog.id, catalog.type, idx, isMovingCatalogElement],
  );

  const mappedCatalogElement = useMemo(() => {
    if (selectedMappedCatalog && catalogElement.mappings.length) {
      return catalogElement.mappings.find((mapping) => mapping.targetCatalogId === selectedMappedCatalog);
    }
    return null;
  }, [selectedMappedCatalog, catalogElement.mappings]);

  const contextItems: ContextMenuItem[] = useMemo(() => {
    const menuItems: ContextMenuItem[] = [
      {
        label: t('common.edit'),
        subtitle: '',
        icon: <PencilIcon />,
        onClick: () => handleEdit(catalogElement),
        stopPropagation: true,
        isDisabled: !catalog.isTenantCatalog,
      },
      {
        label: t('common.delete'),
        subtitle: '',
        icon: <TrashIcon />,
        onClick: () => handleDelete(catalogElement),
        stopPropagation: true,
        isDisabled: !catalog.isTenantCatalog,
      },
      {
        label: t('templates.catalogAddChild'),
        subtitle: '',
        icon: <AddIcon />,
        onClick: () => addChild(catalogElement),
        stopPropagation: true,
        isDisabled: !catalog.isTenantCatalog,
      },
      {
        isDisabled: !catalog.isTenantCatalog || !canMoveUp,
        label: t('projectCalculate.rowMenu.moveElementUpTitle'),
        subtitle: t('projectCalculate.rowMenu.moveElementUpSubTitle'),
        onClick: () => handleMove('up', catalogElement.id),
        icon: <UpwardArrowIcon className="w-5" />,
        stopPropagation: true,
      },
      {
        isDisabled: !catalog.isTenantCatalog || !canMoveDown,
        label: t('projectCalculate.rowMenu.moveElementDownTitle'),
        subtitle: t('projectCalculate.rowMenu.moveElementDownSubTitle'),
        onClick: () => handleMove('down', catalogElement.id),
        icon: <DownwardArrowIcon className="w-5" />,
        stopPropagation: true,
      },
    ];
    if (mappedCatalogElement) {
      menuItems.push({
        label: t('projectCalculate.rowMenu.editElementMappingTitle'),
        subtitle: t('projectCalculate.rowMenu.editElementMappingSubTitle'),
        onClick: () => handleEditMapping(catalogElement),
        icon: <PencilIcon />,
        stopPropagation: true,
      });
    } else {
      menuItems.push({
        label: t('projectCalculate.rowMenu.createElementMappingTitle'),
        subtitle: t('projectCalculate.rowMenu.createElementMappingSubTitle'),
        onClick: () => handleEditMapping(catalogElement),
        icon: <AddIcon />,
        stopPropagation: true,
        isDisabled: !selectedMappedCatalog,
      });
    }
    return menuItems;
  }, [
    t,
    handleEdit,
    catalogElement,
    catalog.isTenantCatalog,
    handleDelete,
    addChild,
    canMoveUp,
    canMoveDown,
    mappedCatalogElement,
    handleEditMapping,
    handleMove,
    selectedMappedCatalog,
  ]);

  if (catalogElement.children?.length) {
    return (
      <Disclosure as="div" className="w-full" defaultOpen={level === 0}>
        {({ open }) => (
          <>
            {isMovingCatalogElement && <LoadingIndicator text={t('common.loading')} mode="overlay" />}
            <DisclosureButton as="div">
              <div
                className={cn('flex items-center h-[38px] hover:text-black hover:bg-gray-50 relative', {
                  'border-b font-bold text-sm text-gray-900': level === 1,
                  'text-xs text-gray-500': level > 1,
                })}
              >
                <ToggleButton className="absolute z-[5] -left-6 top-0 h-full" open={open} />
                <div
                  className={cn('pl-2 w-full h-full flex flex-row cursor-pointer items-center')}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleEdit(catalogElement);
                    return false;
                  }}
                >
                  <div className="mr-5">{catalogElement.code}</div>
                  <div style={level > 1 ? { marginLeft: `${level * 0.6}rem` } : undefined}>
                    {catalogElement.description}
                  </div>
                  <div className="ml-5 text-gray-500 text-xs font-normal flex gap-2">
                    {catalog.type === 'Earnings' &&
                      getTranslatedEarningsType((catalogElement as EarningsCatalogElementReadModel).earningsType)}
                    {mappedCatalogElement && (
                      <div className="text-gray-500">
                        {mappedCatalogElement.targetElementCode} - {mappedCatalogElement.targetElementName}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex w-fit justify-end">
                  <ContextMenu items={contextItems} />
                </div>
              </div>
            </DisclosureButton>
            <DisclosurePanel>
              {catalogElement.children?.length > 0 && (
                <>
                  {catalogElement.children.map((child, i) => (
                    <CatalogSlideOverRow
                      catalog={catalog}
                      catalogElement={child}
                      handleEdit={(element) => handleEdit(element)}
                      handleDelete={(element) => handleDelete(element)}
                      key={child.id}
                      level={level + 1}
                      addChild={(element) => addChild(element)}
                      idx={i}
                      canMoveUp={i > 0}
                      canMoveDown={i < catalogElement.children.length - 1}
                      selectedMappedCatalog={selectedMappedCatalog}
                      handleEditMapping={(element) => handleEditMapping(element)}
                    />
                  ))}
                </>
              )}
            </DisclosurePanel>
          </>
        )}
      </Disclosure>
    );
  }

  return (
    <div
      className={cn('flex items-center h-[38px] hover:text-black hover:bg-gray-50', {
        'border-b font-bold text-sm text-gray-900': level === 1,
        'text-xs text-gray-500': level > 1,
      })}
    >
      {isMovingCatalogElement && <LoadingIndicator text={t('common.loading')} mode="overlay" />}
      <div
        className={cn('pl-2 w-full h-full flex flex-row cursor-pointer items-center')}
        onClick={() => handleEdit(catalogElement)}
      >
        <div className="mr-5">{catalogElement.code}</div>
        <div style={level > 2 ? { marginLeft: `${level * 0.6}rem` } : undefined}>{catalogElement.description}</div>
        <div className="ml-5 text-gray-500 text-xs font-normal">
          {catalog.type === 'Earnings' &&
            getTranslatedEarningsType((catalogElement as EarningsCatalogElementReadModel).earningsType)}
          {mappedCatalogElement && (
            <div className="text-gray-500">
              {mappedCatalogElement.targetElementCode} - {mappedCatalogElement.targetElementName}
            </div>
          )}
        </div>
      </div>
      <div className="flex w-fit justify-end">
        <ContextMenu items={contextItems} />
      </div>
    </div>
  );
};

interface CatalogSlideOverProps extends SlideOverOnCloseProps {
  catalog: TenantCatalogReadModel;
}

export const CatalogSlideOver = ({ catalog, onClose }: CatalogSlideOverProps) => {
  const { t } = useTranslation();

  const {data: benchmarkProperties} = useApiGetCustomBenchmarkPropertiesQuery(undefined, { skip : catalog.type === 'Finance'})

  const [showDelete, setShowDelete] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [showCreateMapping, setShowCreateMapping] = useState(false);
  const [selectedMappedCatalog, setSelectedMappedCatalog] = useState<string>('');

  const costCatalogRef = useRef<SaveCatalogChanges>(null);
  const riskCatalogRef = useRef<SaveCatalogChanges>(null);
  const earnCatalogRef = useRef<SaveCatalogChanges>(null);
  const fincCatalogRef = useRef<SaveCatalogChanges>(null);

  const renderContent = (): JSX.Element => {
    switch (catalog.type) {
      case 'Costs':
        return (
          <CostCatalogEditing
            ref={costCatalogRef}
            catalog={catalog}
            selectedMappedCatalog={selectedMappedCatalog}
            setSelectedMappedCatalog={setSelectedMappedCatalog}
            showCreateMapping={showCreateMapping}
            setShowCreateMapping={setShowCreateMapping}
          />
        );
      case 'Earnings':
        return (
          <EarningsCatalogEditing
            ref={earnCatalogRef}
            catalog={catalog}
            selectedMappedCatalog={selectedMappedCatalog}
            setSelectedMappedCatalog={setSelectedMappedCatalog}
            showCreateMapping={showCreateMapping}
            setShowCreateMapping={setShowCreateMapping}
          />
        );
      case 'Risks':
        return (
          <RiskCatalogEditing
            ref={riskCatalogRef}
            catalog={catalog}
            selectedMappedCatalog={selectedMappedCatalog}
            setSelectedMappedCatalog={setSelectedMappedCatalog}
            showCreateMapping={showCreateMapping}
            setShowCreateMapping={setShowCreateMapping}
          />
        );
      case 'Finance':
        return (
          <FinanceCatalogEditing
            ref={fincCatalogRef}
            catalog={catalog}
            selectedMappedCatalog={selectedMappedCatalog}
            setSelectedMappedCatalog={setSelectedMappedCatalog}
            showCreateMapping={showCreateMapping}
            setShowCreateMapping={setShowCreateMapping}
          />
        );
    }
  };

  const contextItems: ContextMenuItem[] = useMemo(() => {
    return [
      {
        label: t('templates.NewCatalogTemplateElementTitle'),
        subtitle: t('templates.NewCatalogTemplateElementSubTitle'),
        icon: <AddIcon />,
        onClick: () => setShowCreate(true),
      },
      {
        label: t('templates.newCatalogMappingTitle'),
        subtitle: t('templates.newCatalogMappingSubtitle'),
        icon: <AddIcon />,
        onClick: () => setShowCreateMapping(true),
      },
    ];
  }, [t]);

  return (
    <>
      <SlideOver.Header
        title={catalog?.name ?? t('templates.NewCatalogTemplateTitle')}
        backgroundClassName="bg-gray-600"
        onClose={() => onClose(false)}
      />

      <SlideOver.Content className="p-8 h-full">
        {renderContent()}
        {catalog.isTenantCatalog && <SettingsAddButton menuItems={contextItems} />}
      </SlideOver.Content>

      <SlideOver.Controls>
        <div className="flex flex-grow">
          <Button variant="warning" onClick={() => setShowDelete(true)} disabled={!catalog.isTenantCatalog}>
            {t('common.delete')}
          </Button>
        </div>
        <div className="flex justify-end">
          <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
            {t('common.close')}
          </Button>
        </div>
      </SlideOver.Controls>

      <Modal isOpen={showDelete} onClose={() => setShowDelete(false)} onAfterLeave={() => onClose(false)}>
        <CatalogDeleteModal catalog={catalog} onClose={() => setShowDelete(false)} />
      </Modal>

      <SlideOver isOpen={showCreate} onClose={() => setShowCreate(false)}>
        <CatalogElementCreateSlideOver
          catalogId={catalog.id}
          parentElement={null}
          benchmarkProperties={benchmarkProperties ?? []}
          onClose={() => setShowCreate(false)}
          type={catalog.type}
        />
      </SlideOver>
    </>
  );
};
