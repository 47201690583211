import { useTranslation } from 'react-i18next';
import { useState, useRef } from 'react';
import {
  Button,
  Form,
  FormField,
  EditNodeIcon,
  SlideOver,
  TextInput,
  Modal,
  SlideOverOnCloseProps,
  LoadingIndicator,
  BaseSelect,
  TagWindowIcon,
  RulerIcon,
  PercentageIcon,
  NumberInput,
  UsDollarCircledIcon,
  CurrencySelect,
} from '@client/shared/toolkit';
import {
  Currency,
  ProjectTemplateReadModel,
  UnitSystem,
  useApiGetProjectTemplateCatalogsQuery,
  useApiPostProjectTemplateMutation,
  useApiPutProjectTemplateMutation,
  ValueType,
} from '@client/shared/api';
import * as yup from 'yup';
import { InferType } from 'yup';
import { i18n, safeMutation } from '@client/shared/utilities';
import { ProjectTemplateDeleteModal } from './ProjectTemplateDeleteModal';

export const ProjectTemplateFormValidationSchema = yup.object({
  name: yup.string().required('validation.required'),
  vat: yup.number().optional(),
  unitSystem: yup.mixed<UnitSystem>().oneOf(['Metric', 'Imperial', 'Universal']).required('validation.required'),
  valueType: yup.mixed<ValueType>().oneOf(['Net', 'Gross']).required('validation.required'),
  currency: yup.mixed<Currency>().oneOf(['Usd', 'Eur', 'Gbp']).required('validation.required'), 
  costCatalogId: yup.string().required('validation.required'),
  riskCatalogId: yup.string().required('validation.required'),
  earningsCatalogId: yup.string().required('validation.required'),
  financingCatalogId: yup.string().required('validation.required'),
  deliveryPhaseId: yup.string().required('validation.required'),
});

export type ProjectTemplateFormValidationValues = InferType<typeof ProjectTemplateFormValidationSchema>;

interface ProjectTemplateDetailsSlideOverProps extends SlideOverOnCloseProps {
  isAddMode: boolean;
  projectTemplate: ProjectTemplateReadModel | undefined;
}

export const ProjectTemplateSlideOver = ({
  isAddMode,
  projectTemplate,
  onClose,
}: ProjectTemplateDetailsSlideOverProps) => {
  const { t } = useTranslation();
  const submitRef = useRef<HTMLButtonElement>(null);

  const [postUpdate, { isLoading: isUpdating }] = useApiPutProjectTemplateMutation();
  const [postCreate, { isLoading: isCreating }] = useApiPostProjectTemplateMutation();
  const [showDelete, setShowDelete] = useState<boolean>(false);

  const { data: catalogData, isFetching: isFetchingCatalogs } = useApiGetProjectTemplateCatalogsQuery();

  const valueTypeOptions = [
    { label: t('common.net'), value: 'Net' },
    { label: t('common.gross'), value: 'Gross' },
  ];

  const getUnitSystemLabel = (unitSystem: string): string => {
    switch (unitSystem) {
      case 'Metric':
        return i18n.t('project.unitSystemMetricLabel');
      case 'Imperial':
        return i18n.t('project.unitSystemImperialLabel');
      default:
        return unitSystem;
    }
  };

  const unitSystemOptions = ['Metric', 'Imperial'].map((option) => ({
    label: getUnitSystemLabel(option),
    value: option,
  }));

  const costCatalogOptions =
    catalogData?.templateCatalogs
      .filter((x) => x.type === 'Costs')
      .map((catalog: { name: string; id: string }) => ({
        label: catalog.name,
        value: catalog.id,
      })) ?? [];

  const earningsCatalogOptions =
    catalogData?.templateCatalogs
      .filter((x) => x.type === 'Earnings')
      .map((catalog: { name: string; id: string }) => ({
        label: catalog.name,
        value: catalog.id,
      })) ?? [];

  const riskCatalogOptions =
    catalogData?.templateCatalogs
      .filter((x) => x.type === 'Risks')
      .map((catalog: { name: string; id: string }) => ({
        label: catalog.name,
        value: catalog.id,
      })) ?? [];

  const financeCatalogOptions =
    catalogData?.templateCatalogs
      .filter((x) => x.type === 'Finance')
      .map((catalog: { name: string; id: string }) => ({
        label: catalog.name,
        value: catalog.id,
      })) ?? [];

  const phasesCatalogOptions =
    catalogData?.templateCatalogs
      .filter((x) => x.type === 'DeliveryPhases')
      .map((catalog: { name: string; id: string }) => ({
        label: catalog.name,
        value: catalog.id,
      })) ?? [];

  const defaultFormValues = {
    name: projectTemplate?.name ?? '',
    vat: projectTemplate?.vat ?? 0,
    unitSystem: projectTemplate?.unitSystem ?? 'Metric',
    valueType: projectTemplate?.valueType ?? 'Net',
    currency: projectTemplate?.currency ?? 'Eur',
    costCatalogId: projectTemplate?.costCatalogId,
    riskCatalogId: projectTemplate?.riskCatalogId,
    earningsCatalogId: projectTemplate?.earningsCatalogId,
    financingCatalogId: projectTemplate?.financeCatalogId,
    deliveryPhaseId: projectTemplate?.deliveryPhasesCatalogId,
  };

  const handleProjectTemplateSubmit = async (data: ProjectTemplateFormValidationValues) => {
    if (isAddMode) {
      try {
        await safeMutation(
          postCreate,
          {
            body: {
              metaData: {
                name: data.name,
                vat: data.vat ?? 0,
                unitSystem: data.unitSystem,
                calculateValueType: data.valueType,
                currency: data.currency
              },           
              costCatalogId: data.costCatalogId,
              riskCatalogId: data.riskCatalogId,
              earningsCatalogId: data.earningsCatalogId,
              financeCatalogId: data.financingCatalogId,
              deliveryPhasesCatalogId: data.deliveryPhaseId
            },
          },
          isCreating
        );
        onClose(true);
      } catch {
        /* left blank */
      }
    } else {
      try {
        await safeMutation(
          postUpdate,
          {
            templateId: projectTemplate?.templateId ?? '',
            body: {
              name: data.name,
              vat: data.vat ?? 0,
              unitSystem: data.unitSystem,
              calculateValueType: data.valueType,
              currency: data.currency
            },
          },
          isUpdating
        );
        onClose(true);
      } catch {
        /* left blank */
      }
    }
  };

  return (
    <>
      <Form<ProjectTemplateFormValidationValues>
        onSubmit={handleProjectTemplateSubmit}
        validationSchema={ProjectTemplateFormValidationSchema}
        defaultValues={defaultFormValues}
        className="w-full flex flex-col justify-between h-full"
      >
        <SlideOver.Header
          title={projectTemplate?.name ?? t('templates.NewProjectTemplateTitle')}
          backgroundClassName="bg-gray-600"
          onClose={() => onClose(false)}
        />

        <SlideOver.Content>
          {isUpdating || isCreating || isFetchingCatalogs ? (
            <div className="m-2">
              <LoadingIndicator text={t('templates.projectTemplatesLoadingIndicator') ?? ''} />
            </div>
          ) : (
            <>
              <div className="m-8 bg-white">
                <div className="divide-gray-100 divide-y">
                  <FormField name="name">
                    {(control) => <TextInput label={t('common.name')} icon={<TagWindowIcon />} {...control} />}
                  </FormField>
                </div>
              </div>

              <div className="m-8 bg-white">
                <div className="divide-gray-100 divide-y">
                  <FormField name="vat">
                    {(control) => (
                      <NumberInput label={t('app.newProjectTaxRate')} icon={<PercentageIcon />} {...control} />
                    )}
                  </FormField>
                  <FormField name="unitSystem">
                    {(control) => (
                      <BaseSelect
                        label={t('project.measurementSystem')}
                        icon={<RulerIcon />}
                        options={unitSystemOptions}
                        {...control}
                      />
                    )}
                  </FormField>
                  <FormField name="valueType">
                    {(control) => (
                      <BaseSelect
                        label={t('projectCalculate.valueType')}
                        options={valueTypeOptions}
                        icon={<UsDollarCircledIcon />}
                        {...control}
                      />
                    )}
                  </FormField>
                  <CurrencySelect />
                </div>
              </div>

              <div className="mx-8 mb-8 bg-white">
                <div className="divide-gray-100 divide-y">
                  <FormField name="costCatalogId">
                    {(control) => (
                      <BaseSelect
                        disabled={!isAddMode}
                        label={t('templates.costCatalog')}
                        icon={<EditNodeIcon />}
                        options={costCatalogOptions}
                        {...control}
                      />
                    )}
                  </FormField>
                  <FormField name="riskCatalogId">
                    {(control) => (
                      <BaseSelect
                        disabled={!isAddMode}
                        label={t('templates.riskCatalog')}
                        icon={<EditNodeIcon />}
                        options={riskCatalogOptions}
                        {...control}
                      />
                    )}
                  </FormField>
                  <FormField name="earningsCatalogId">
                    {(control) => (
                      <BaseSelect
                        disabled={!isAddMode}
                        label={t('templates.earningsCatalog')}
                        icon={<EditNodeIcon />}
                        options={earningsCatalogOptions}
                        {...control}
                      />
                    )}
                  </FormField>
                  <FormField name="financingCatalogId">
                    {(control) => (
                      <BaseSelect
                        disabled={!isAddMode}
                        label={t('templates.financingCatalog')}
                        icon={<EditNodeIcon />}
                        options={financeCatalogOptions}
                        {...control}
                      />
                    )}
                  </FormField>
                </div>
              </div>
              <div className="mx-8 mb-8 bg-white">
                <div className="divide-gray-100 divide-y">
                  <FormField name="deliveryPhaseId">
                    {(control) => (
                      <BaseSelect
                        disabled={!isAddMode}
                        label={t('app.newProjectDeliveryPhasesCatalog')}
                        icon={<EditNodeIcon />}
                        options={phasesCatalogOptions}
                        {...control}
                      />
                    )}
                  </FormField>
                </div>
              </div>
            </>
          )}
        </SlideOver.Content>
        <SlideOver.Controls>
          <div className="w-full flex justify-between">
            {!isAddMode && (
              <Button variant="warning" onClick={() => setShowDelete(true)}>
                {t('common.delete')}
              </Button>
            )}
            <div className="flex ml-auto">
              <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
                {t('common.cancel')}
              </Button>
              <Button variant="primary" formSubmit={true} innerRef={submitRef}>
                {t('common.save')}
              </Button>
            </div>
          </div>
        </SlideOver.Controls>
      </Form>

      <Modal isOpen={showDelete} onClose={onClose} variant="small">
        <ProjectTemplateDeleteModal
          projectTemplate={projectTemplate}
          onClose={(success: boolean) => {
            setShowDelete(false);

            if (success) {
              onClose(true);
            }
          }}
        />
      </Modal>
    </>
  );
};
