import {
  ReactFlow,
  Position,
  Handle,
  NodeProps,
  useNodesState,
  useEdgesState,
  addEdge,
  ReactFlowProvider,
  useReactFlow,
} from 'reactflow';
import { VariantSlideOver } from './VariantSlideOver';
import React, {
  createContext,
  createRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  PropsWithChildren,
} from 'react';
import { useLoadedProject, useLoadedProjectVariants } from '@client/project/store';
import { CalculationModelMetadata } from '@client/shared/api';
import classNames from 'classnames';
import { useComponentDimensions, SlideOver, CalculationModelIcon } from '@client/shared/toolkit';
import { humanizeJsonDate } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';
import { constructCalculationModelGraph, TCalculationModelNode } from '../utils';
import { useDebounce, useWindowSize } from 'react-use';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';
import { versionTextFromType } from '@client/project/shared';

// use same height for root node and variant nodes
const HEIGHT = 'h-[113px]';

type TSelectedCalculationModelContext = {
  setCalculationModel?: (model: CalculationModelMetadata | undefined) => void;
};

const SelectedCalculationModelContext = createContext<TSelectedCalculationModelContext>({});

/**
 * The root node (aka start button)
 */
const RootNode = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* outer container to match size of all elements */}
      <div className={classNames('flex justify-end items-center nodrag', HEIGHT)}>
        <Handle
          type="source"
          position={Position.Right}
          isConnectable={false}
          className="!w-0 !min-w-0 !bg-transparent"
        />
        {/* inner container for the green circle */}
        <div className="rounded-full border-slate-800 bg-slate-700 w-14 h-14 flex flex-row justify-center items-center p-2">
          {/* centered text element */}
          <div className="text-white text-[15px] font-bold truncate">{t('projectVariants.historyNodeStart')}</div>
        </div>
      </div>
    </>
  );
};

/**
 * Renders on calculation model
 */
const CalculationModelNode = ({ data: { calculationModel } }: NodeProps<TCalculationModelNode>) => {
  const { t } = useTranslation();

  let borderColor = 'bg-slate-50 border-slate-200';
  const nodeTypeText =
    versionTextFromType(t, calculationModel.type, calculationModel.version) ??
    t('projectVariants.historyNodeTypeVersion');

  // set node type label, border and background color depending on calculation model type
  switch (calculationModel.type) {
    case 'Version':
      borderColor = 'bg-emerald-500 border-emerald-500';
      break;
    case 'ArchivedVersion':
      borderColor = 'bg-slate-700 border-slate-700';
      break;
    case 'Variant':
      borderColor = 'bg-emerald-800 border-emerald-800';
      break;
    case 'ArchivedVariant':
      borderColor = 'bg-gray-200 border-gray-200';
      break;
    case 'SystemSnapshot':
      borderColor = 'bg-gray-200 border-gray-200';
      break;
    case 'UserSnapshot':
      borderColor = 'bg-gray-200 border-gray-200';
      break;
    case 'Scenario':
        borderColor = 'bg-orange-200 border-orange-200';
        break;
    default:
      throw new Error('Unknown type: ' + calculationModel.type);
  }

  const selectedCalculationModelContext = useContext(SelectedCalculationModelContext);

  return (
    <div
      className={classNames('flex flex-col justify-center cursor-pointer w-[88px] relative', HEIGHT)}
      onClick={() => selectedCalculationModelContext.setCalculationModel?.(calculationModel)}
    >
      <Handle type="target" position={Position.Left} className="!w-0 !min-w-0 !bg-transparent" isConnectable={false} />
      <div className={classNames('flex flex-col shadow-md border-[5px] items-center flex-grow', borderColor, HEIGHT)}>
        <div
          className="w-full h-full bg-white flex flex-col items-center p-2"
          style={{ clipPath: `polygon(80% 0, 100% 15%, 100% 100%, 0 100%, 0 0)` }}
        >
          <div className="my-2">
            <CalculationModelIcon className="h-8 w-8" />
          </div>
          <div className="flex flex-col truncate w-full">
            <div className="font-bold text-[11px] pt-2 text-center truncate">{calculationModel.name}</div>
          </div>
          <div className="flex flex-col truncate w-full">
            <div className="text-[8px] text-center truncate">{nodeTypeText}</div>
          </div>
        </div>
      </div>

      <div className="flex flex-col flex-shrink text-gray-400 absolute -mb-[113px] pt-8 truncate leading-none">
        <div className="text-[11px] font-bold">
          {t('common.timeAgo', { time: humanizeJsonDate(calculationModel.created?.changedAt) })}
        </div>
        <div className="text-[8px]">{calculationModel.created?.changedBy}</div>
      </div>

      <Handle type="source" position={Position.Right} className="!w-0 !min-w-0 !bg-transparent" isConnectable={false} />
    </div>
  );
};

const nodeTypes = {
  root: RootNode,
  calculationModel: CalculationModelNode,
};

const CalculationModelHistoryOptions = () => {
  const { t } = useTranslation();

  const { data: loadedProject} = useLoadedProject();
  const { data: calculationModels } = useLoadedProjectVariants();

  const [showUserSnapshots, setShowUserSnapshots] = useState(false);
  const [showSystemSnapshots, setShowSystemSnapshots] = useState(false);
  const [showVariants, setShowVariants] = useState(true);
  const [showArchivedVariants, setShowArchivedVariants] = useState(true);
  const [showArchivedVersions, setShowArchivedVersions] = useState(true);

  const reactFlowInstance = useReactFlow();

  const windowSize = useWindowSize();

  useDebounce(
    () => {
      reactFlowInstance.fitView({
        duration: 200,
        maxZoom: 1,
        padding: 0.3,
      });
    },
    300,
    [reactFlowInstance, windowSize.height, windowSize.width]
  );

  // only recalculate graph if needed
  const graph = useMemo(() => {
    return constructCalculationModelGraph(calculationModels, {
      showVariants: showVariants,
      showUserSnapshots: showUserSnapshots,
      showArchivedVersions: showArchivedVersions,
      showSystemSnapshots: showSystemSnapshots,
      showArchivedVariants: showArchivedVariants,
    });
  }, [
    showArchivedVariants,
    showArchivedVersions,
    showSystemSnapshots,
    showUserSnapshots,
    showVariants,
    calculationModels,
  ]);

  useEffect(() => {
    reactFlowInstance.setNodes(graph.nodes);
    reactFlowInstance.setEdges(graph.edges);

    setTimeout(() => {
      reactFlowInstance.fitView({
        duration: 200,
        maxZoom: 1,
        padding: 0.3,
      });
    }, 200);
  }, [graph, reactFlowInstance]);

  if(loadedProject?.project.payload.isTemplate){
    return (
      <div></div>
    );
  }

  return (
    <div className="bg-slate-100 flex p-1 rounded-full space-x-1">
      <CalculationModelHistoryFilterButton
        active={showUserSnapshots}
        onClick={() => setShowUserSnapshots(!showUserSnapshots)}
      >
        {t('projectVariants.historyFilterUserSnapshots')}
      </CalculationModelHistoryFilterButton>
      <CalculationModelHistoryFilterButton
        active={showSystemSnapshots}
        onClick={() => setShowSystemSnapshots(!showSystemSnapshots)}
      >
        {t('projectVariants.historyFilterSystemSnapshots')}
      </CalculationModelHistoryFilterButton>
      <CalculationModelHistoryFilterButton
        active={showArchivedVariants}
        onClick={() => setShowArchivedVariants(!showArchivedVariants)}
      >
        {t('projectVariants.historyFilterArchivedVariants')}
      </CalculationModelHistoryFilterButton>
      <CalculationModelHistoryFilterButton
        active={showArchivedVersions}
        onClick={() => setShowArchivedVersions(!showArchivedVersions)}
      >
        {t('projectVariants.historyFilterArchivedVersions')}
      </CalculationModelHistoryFilterButton>
      <CalculationModelHistoryFilterButton 
        active={showVariants} 
        onClick={() => setShowVariants(!showVariants)}>
        {t('projectVariants.historyFilterVariants')}
      </CalculationModelHistoryFilterButton>
    </div>
  );
};

interface CalculationModelHistoryFilterButtonProps extends PropsWithChildren {
  onClick: () => void;
  active: boolean;
  className?: string;
}

const CalculationModelHistoryFilterButton = ({
  children,
  onClick,
  active = true,
}: CalculationModelHistoryFilterButtonProps) => (
  <div
    className={classNames(
      'flex items-center space-x-2 text-xs cursor-pointer px-2 rounded-full py-px transition-colors duration-200 hover:bg-slate-300',
      {
        'bg-slate-500 text-white font-bold hover:bg-slate-600': active,
        'bg-slate-100 text-slate-500': !active,
      }
    )}
    onClick={onClick}
  >
    {active ? <EyeIcon className="h-4 w-4" /> : <EyeSlashIcon className="h-4 w-4" />}
    <div>{children}</div>
  </div>
);

export const CalculationModelHistory = () => {
  const [nodes, , onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [selectedCalculationModel, setSelectedCalculationModel] = useState<CalculationModelMetadata | undefined>();
  const [isOpenSlideOver, setIsOpenSlideOver] = useState(false);

  const containerRef = createRef<HTMLDivElement>();
  const dimensions = useComponentDimensions(containerRef);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onConnect = useCallback((params: any) => setEdges((els) => addEdge(params, els)), [setEdges]);

  const handleOpenSlideOver = (model: CalculationModelMetadata | undefined) => {
    setSelectedCalculationModel(model);
    setIsOpenSlideOver(true);
  };

  return (
    <>
      <ReactFlowProvider>
        <SelectedCalculationModelContext.Provider value={{ setCalculationModel: handleOpenSlideOver }}>
          <div className="flex absolute bottom-0 z-10 w-full">
            <CalculationModelHistoryOptions />
          </div>

          <div className="flex-grow relative" ref={containerRef}>
            {/* we need any width/height set to prevent console error messages */}
            <div
              className="bg-slate-50 border-slate-200 w-10 h-10 absolute"
              style={{ width: dimensions.width, height: dimensions.height }}
            >
              <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                nodeTypes={nodeTypes}
                fitView
                maxZoom={1}
                attributionPosition="top-right"
              />
            </div>
          </div>
        </SelectedCalculationModelContext.Provider>
      </ReactFlowProvider>

      <SlideOver isOpen={isOpenSlideOver} onClose={() => setIsOpenSlideOver(false)}>
        <VariantSlideOver variant={selectedCalculationModel} onClose={() => setIsOpenSlideOver(false)} />
      </SlideOver>
    </>
  );
};
