import {
  AddButton,
  Modal,
} from '@client/shared/toolkit';
import { safeMutation, sortByProperty, SortHeaderType } from '@client/shared/utilities';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DatevMappingAddSlideOver } from '.';
import {
  DatevConnectionResponse,
  DatevProbisMappingReadModel,
  useApiDeleteTenantDatevProbisMappingMutation,
  YardiProbisMappingReadModel,
} from '@client/shared/api';
import { API_MAPPING, ApiClientMappingDeleteModal, ApiClientMappingList } from '..';

interface DatevClientSlideOverMappingTabProps {
  projectId?: string;
  apiAccessId?: string;
  connectionData: DatevConnectionResponse | undefined;
}

export const DatevClientSlideOverMappingTab = ({
  projectId,
  apiAccessId,
  connectionData,
}: DatevClientSlideOverMappingTabProps) => {
  const { t } = useTranslation();

  const [isOpenEditSlideover, setIsOpenEditSlideover] = useState(false);
  const [selectedItem, setSelectedItem] = useState<DatevProbisMappingReadModel | null>(null);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  const defaultSortHeader: SortHeaderType[] = useMemo(() => {
    return [
      {
        asc: true,
        label: t('app.settingsApiDatevConnectionDatevId'),
        type: 'string',
        value: API_MAPPING.DATEV_PROPERTY_NAME,
      },
      {
        asc: null,
        label: t('app.settingsApiDatevConnectionProbisObjectId'),
        type: 'string',
        value: API_MAPPING.PROBIS_PROPERTY_NAME,
      },
      {
        asc: null,
        label: t('app.settingsApiDatevConnectionCustomField'),
        type: 'string',
        value: API_MAPPING.USER_DEFINED_FIELD_NAME,
      },
      {
        asc: null,
        label: t('app.settingsApiDatevConnectionDefaultValue'),
        type: 'string',
        value: API_MAPPING.DEFAULT_VALUE,
      },
      // {
      //   asc: null,
      //   label: t('app.settingsApiDatevConnectionRegex'),
      //   type: 'string',
      //   value: 'regex',
      // },
    ];
  }, [t]);

  const [sortHeader, setSortHeader] = useState<SortHeaderType[]>(defaultSortHeader);

  const datevProbisMappings = useMemo(() => {
    return connectionData?.datevProbisMapping ?? [];
  }, [connectionData?.datevProbisMapping]);

  const sortedDatevProbisMappings = useMemo(() => {
    const sortBy = sortHeader.find((item) => item.asc !== null);
    let sortedMappings = [...(datevProbisMappings ?? [])];
    if (sortBy) {
      return (sortedMappings = sortByProperty(sortedMappings, sortBy));
    } else {
      return sortedMappings;
    }
  }, [datevProbisMappings, sortHeader]);

  const mappingTypes = useMemo(
    () => [...new Set(datevProbisMappings.map((mapping) => mapping.type))],
    [datevProbisMappings],
  );

  const handleAdd = () => {
    setSelectedItem(null);
    setIsOpenEditSlideover(true);
  };

  const handleEdit = (item: DatevProbisMappingReadModel | YardiProbisMappingReadModel) => {
    if (API_MAPPING.DATEV_PROPERTY_NAME in item) {
      setSelectedItem(item);
    }
    setIsOpenEditSlideover(true);
  };

  const handleDelete = (item: DatevProbisMappingReadModel | YardiProbisMappingReadModel) => {
    if (API_MAPPING.DATEV_PROPERTY_NAME in item) {
      setSelectedItem(item);
    }
    setIsOpenDeleteModal(true);
  };

  const [deleteTenantMapping, { isLoading: isDeletingTenantMapping }] = useApiDeleteTenantDatevProbisMappingMutation();

  const deleteItem = async (itemId: string) => {
    try {
      const item = datevProbisMappings.find((item) => item.id === itemId);
      await safeMutation(
        deleteTenantMapping,
        {
          apiAccessId: apiAccessId,
          mappingId: item?.id ?? '',
          datevApiAccessId: item?.apiAccessId ?? '',
        },
        isDeletingTenantMapping,
      );
      setIsOpenDeleteModal(false);
    } catch {
      /* left blank */
    }
  };

  const onHandleSort = useCallback(
    (index: number) => {
      const currentSortValues = [...sortHeader];
      const update = currentSortValues[index];
      update.asc = update.asc === null ? true : !update.asc;
      currentSortValues.forEach((val, i) => {
        if (i !== index) {
          val.asc = null;
        }
      });
      currentSortValues[index] = update;
      setSortHeader(currentSortValues);
    },
    [sortHeader],
  );

  return (
    <div className="relative flex flex-col gap-5">
      {mappingTypes.length > 0 ? (
        mappingTypes.map((mappingType, idx) => (
          <ApiClientMappingList
            mappingType={API_MAPPING.DATEV}
            mappings={sortedDatevProbisMappings.filter((item) => item.type === mappingType)}
            title={mappingType}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            sortHeader={idx === 0 ? sortHeader : undefined}
            onHandleSort={idx === 0 ? onHandleSort : undefined}
            key={idx}
          />
        ))
      ) : (
        <div className="text-center text-gray-400">{t('app.settingsApiDatevNoConnections')}</div>
      )}
      <div className="absolute right-10 -bottom-5">
        <AddButton onClick={handleAdd} />
      </div>
      <DatevMappingAddSlideOver
        isOpen={isOpenEditSlideover}
        onClose={() => setIsOpenEditSlideover(false)}
        item={selectedItem}
        apiAccessId={apiAccessId}
        projectId={projectId}
        connectionData={connectionData}
      />
      <Modal isOpen={isOpenDeleteModal} onClose={setIsOpenDeleteModal}>
        <ApiClientMappingDeleteModal
          onClose={setIsOpenDeleteModal}
          itemId={selectedItem?.id ?? ''}
          itemName={selectedItem?.name ?? ''}
          deleteItem={deleteItem}
          isLoading={isDeletingTenantMapping}
        />
      </Modal>
    </div>
  );
};
