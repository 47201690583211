import cn from 'classnames';
import { ReactNode } from 'react';
import { PROJECT_PLACEHOLDER_EMPTY_IMAGE } from '../images';

interface EmptyProjectsCardProps {
  onClick: () => void;
  disabled?: boolean
  title?: string
  subTitle?: string
  icon?: ReactNode
}

export const EmptyProjectsCard = ({ onClick, disabled = false, title, subTitle, icon }: EmptyProjectsCardProps) => {
  return (
    <div className="group relative px-5 pt-5 pb-7" onClick={disabled ? undefined : onClick}>
      <div className={cn('rounded-md overflow-hidden bg-white-100 shadow-lg border border-solid border-gray-100', disabled ? 'cursor-not-allowed text-gray-200' : 'cursor-pointer')}>
        <div className="w-full h-60 overflow-hidden">
          <img
            src={PROJECT_PLACEHOLDER_EMPTY_IMAGE}
            className={cn('rounded-none h-full bg-gray-100 transition-transform duration-300 transform w-full object-cover', disabled ? '' : 'group-hover:scale-105')}
            alt={title}
            loading="lazy"
          />
          {icon && (
            <div className="absolute top-5 bg-white rounded-full h-12 w-12 flex items-center justify-center mt-4 ml-4">
              {icon}
            </div>
          )}
        </div>
        <div className="m-4 flex items-center justify-between">
          <div className="min-w-0">
            <div className="font-medium text-2xl truncate mr-8">
              <span aria-hidden="true" className="absolute top-0 right-0 left-0 bottom-20" />
              {title}
              <span aria-hidden="true" className="absolute top-0 right-12 left-0 bottom-0 fill-slate-500" />
            </div>
            {subTitle && <div className="mt-1 text-sm truncate mr-8">{subTitle}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};
