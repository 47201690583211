import { BenchmarkReportColumnConfigurationReadModel } from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { ContextMenuItem, TrashIcon } from '@client/shared/toolkit';
import React, { useMemo } from 'react';
import { BenchmarkingColumnHeader, BenchmarkingRowHeader, BenchmarkingHeaderData } from '.';
import { BENCHMARK_DRAG_TYPE } from './BenchmarkingDragDropItem';

export interface BenchmarkingParameterHeaderProps extends BenchmarkingHeaderData {
  column: BenchmarkReportColumnConfigurationReadModel;
}

export const BenchmarkingParameterHeader = (props: BenchmarkingParameterHeaderProps) => {
  const {
    column,
    handleDelete,
    index,
    dropItem,
    isDragDisabled = true,
    type,
    isLast = false,
    hoverItem,
    isHovered,
  } = props;
  const { t } = useTranslation();
  const contextMenuItems: ContextMenuItem[] = useMemo(() => {
    return [
      {
        label: t('reporting.benchmarking.column.remove.title'),
        subtitle: t('reporting.benchmarking.column.remove.subtitle'),
        icon: <TrashIcon />,
        onClick: handleDelete,
      },
    ];
  }, [t, handleDelete]);

  const catalogElementTitle = useMemo(() => {
    return column.catalogElementCode && column.catalogElementDescription
      ? `${column.catalogElementCode} ${column.catalogElementDescription}`
      : column.catalogElementDescription ?? '';
  }, [column.catalogElementDescription, column.catalogElementCode]);

  const headerProps = useMemo(() => {
    return {
      id: column.id,
      index: index,
      title: catalogElementTitle,
      subtitle: column.catalogName ?? '',
      dropItem: dropItem,
      isDragDisabled: isDragDisabled,
      isLast: isLast,
      contextMenuItems: contextMenuItems,
      type: type,
      hoverItem: hoverItem,
      isHovered: isHovered,
    };
  }, [
    column.id,
    index,
    catalogElementTitle,
    column.catalogName,
    dropItem,
    isDragDisabled,
    isLast,
    contextMenuItems,
    type,
    hoverItem,
    isHovered,
  ]);

  if (type === BENCHMARK_DRAG_TYPE.ROW) {
    return <BenchmarkingRowHeader {...headerProps} />;
  }
  return <BenchmarkingColumnHeader {...headerProps} />;
};
