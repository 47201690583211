import { useTranslation } from 'react-i18next';
import { DashboardWidgetProps } from '../WidgetDashboard';
import {
  Badge,
  DollarBagIcon,
  TimesheetIcon,
  WidgetContainer,
  WidgetContainerContent,
  WidgetContainerTitle,
} from '@client/shared/toolkit';
import { UpsertRiskMitigationPayload } from '@client/shared/api';
import { useMemo } from 'react';
import cn from 'classnames';

/**
 * Risk and Mitigation List Widget
 */
export const DashboardWidgetRiskMitigationList = (props: DashboardWidgetProps) => {
  const { widget } = props;
  const { t } = useTranslation();
  return (
    <WidgetContainer>
      <WidgetContainerTitle>
        {widget.title ? widget.title : t('dashboard.widget.riskMitigationList.title')}
      </WidgetContainerTitle>
      <WidgetContainerContent className="flex-1 overflow-y-auto">
        {widget?.additionalConfig?.RiskMitigationList && widget.additionalConfig.RiskMitigationList.length > 0 && (
          <ul className="flex flex-col gap-2">
            {widget.additionalConfig.RiskMitigationList.map((riskMitigation, i) => (
              <li key={`widget-${widget.id}-risk-mitigation-${i}`}>
                <RiskMitigationRow {...riskMitigation} />
              </li>
            ))}
          </ul>
        )}
      </WidgetContainerContent>
    </WidgetContainer>
  );
};

export const RiskMitigationRow = (props: UpsertRiskMitigationPayload) => {
  const { name, description, type, status } = props;
  const { t } = useTranslation();

  const colors = useMemo(() => {
    switch (status) {
      case 'Critical':
        return {
          text: 'text-[#FAA5A6]',
          background: 'bg-[#FAA5A6]',
        };
      case 'Important':
        return {
          text: 'text-[#F8CBAD]',
          background: 'bg-[#F8CBAD]',
        };
      case 'ToConsider':
        return {
          text: 'text-[#F2DA8C]',
          background: 'bg-[#F2DA8C]',
        };
    }
  }, [status]);

  const icon = useMemo(() => {
    const iconClasses = cn('w-[21px] flex-none', colors.text);
    switch (type) {
      case 'Cost':
        return <DollarBagIcon className={iconClasses} />;
      case 'Time':
        return <TimesheetIcon className={iconClasses} />;
    }
  }, [type, colors.text]);

  const statusLabel = useMemo(() => {
    switch (status) {
      case 'Critical':
        return t('dashboard.widget.riskMitigation.status.critical');
      case 'Important':
        return t('dashboard.widget.riskMitigation.status.important');
      case 'ToConsider':
        return t('dashboard.widget.riskMitigation.status.toConsider');
    }
  }, [status, t]);

  return (
    <div className="py-4 px-5 flex items-center justify-between gap-3 rounded-md shadow-md">
      {icon}
      <div className="flex flex-col flex-1 gap-0.5">
        <div className="flex gap-2">
          <span className="font-bold text-[15px]">{name}</span>
          <Badge text={statusLabel} className={cn('flex-none self-start text-white', colors.background)} variant="custom" />
        </div>
        <div className="text-slate-400 text-[11px]">{description}</div>
      </div>
    </div>
  );
};
