import { useApiPostForgotPasswordMutation } from '@client/shared/api';
import { XMarkIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, TextInput, Form, FormField } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import {
  ForgotPasswordFormValidationValues,
  ForgotPasswordFormValidationSchema,
} from './ForgotPasswordFormValidationSchema';

export const ForgotPasswordView = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [call, callState] = useApiPostForgotPasswordMutation();
  const { isLoading } = callState;

  const handleSubmit = async (data: ForgotPasswordFormValidationValues) => {
    try {
      await call({
        body: {
          email: { email: data.email },
        },
      }).unwrap();

      navigate('/auth/password-reset-started');
    } catch (e) {
      // left blank intentionally
    }
  };

  const defaultFormValues = { email: '' };

  return (
    <Form<ForgotPasswordFormValidationValues>
      onSubmit={handleSubmit}
      validationSchema={ForgotPasswordFormValidationSchema}
      defaultValues={defaultFormValues}
    >
      <div
        className="flex cursor-pointer"
        onClick={() => {
          navigate('/auth/login');
        }}
      >
        <XMarkIcon className="absolute -mt-6 ml-[23rem] w-8 h-8" fill="#6B7280" />
      </div>
      <div className="flex flex-row items-center mb-6 mt-3">
        <h1 className="text-4xl font-semibold text-primary">{t('auth.forgotPasswordTitle')}</h1>
      </div>
      <div className="text-sm text-gray-500 mb-4">{t('auth.forgotPasswordDescription')}</div>
      <FormField name="email">{(control) => <TextInput label={t('common.email')} {...control} />}</FormField>
      <div className="pt-32 flex place-content-end">
        <Button
          variant="secondary"
          onClick={() => {
            navigate('/auth/login');
          }}
        >
          {t('common.cancel')}
        </Button>
        <Button variant="primary" disabled={isLoading} formSubmit={true}>
          {t('auth.forgotPasswordAction')}
        </Button>
      </div>
    </Form>
  );
};
