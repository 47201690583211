import { ModalOnCloseProps, PercentageIcon, Co2Icon, IncreaseIcon } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { FeatureElementItem, FeatureElementType, NewFeatureElementModal } from './NewFeatureElementModal';

interface NewCostsFeatureElementModalProps extends ModalOnCloseProps {
  catalogElementId?: string;
  onClose: (clearSelection?: boolean) => void;
  onOpenSlideOver: (featureElementType: FeatureElementType) => void;
}

export const NewCostsFeatureElementModal = ({ onClose, onOpenSlideOver }: NewCostsFeatureElementModalProps) => {
  const { t } = useTranslation();

  // const currentStep = !(catalogElementId != null) ? 0 : 1;

  const featureElementTypes: FeatureElementItem[] = [
    {
      label: 'Local Law 97',
      subtitle: 'New York',
      value: 'localLaw97',
      icon: <Co2Icon className="w-14 h-14 bg-slate-900 p-3 rounded-full text-white" />,
      disabled: false,
    },
    // {
    //   label: 'General ESG',
    //   subtitle: 'EU Law',
    //   value: 'generalEsg',
    //   icon: <Co2Icon className="w-14 h-14 bg-slate-900 p-3 rounded-full text-white" />,
    //   disabled: true,
    // },
    // {
    //   label: 'Labour Settlements',
    //   subtitle: 'New York',
    //   value: 'labourSettlements',
    //   icon: <Co2Icon className="w-14 h-14 bg-slate-900 p-3 rounded-full text-white" />,
    //   disabled: true,
    // },
    {
      label: t('projectCalculate.vatReturnModal.vat'),
      subtitle: t('projectCalculate.vatReturnModal.vatElement'),
      value: 'vat',
      icon: <PercentageIcon className="w-14 h-14 p-3 rounded-full" />,
      disabled: false,
    },
    {
      label: t('projectCalculate.inflationCalcModal.title'),
      subtitle: t('projectCalculate.inflationCalcModal.subtitleCosts'),
      value: 'inflation',
      icon: <IncreaseIcon className="w-14 h-14 p-3 rounded-full" />,
      disabled: false,
    },
  ];
  return (
    <NewFeatureElementModal
      featureElementTypes={featureElementTypes}
      selectedFeatureElementType="localLaw97"
      onClose={onClose}
      onSelectFeatureElement={onOpenSlideOver}
    />
  );
};
