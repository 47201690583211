import {
  LoadingIndicator,
  WidgetContainer,
  WidgetContainerContent,
  WidgetContainerTitle,
} from '@client/shared/toolkit';
import { WidgetConfig } from '../WidgetDashboard';
import { useTranslation } from 'react-i18next';
import { ProjectReadModel, useApiGetWaterfallBudgetReportQuery, WaterfallBudgetReportReadModel } from '@client/shared/api';
import { useLoadedProjectId } from '@client/project/store';
import { useEffect, useMemo, useRef, useState } from 'react';
import { DashboardWidgetType } from '../utils';
import { ChartDataset, ChartOptions, Chart as ChartJS } from 'chart.js';
import { getLanguageAndLocale } from '@client/shared/utilities';
import { Chart } from 'react-chartjs-2';

const BAR_COLORS = {
  initialBudget: '#cbd5e1',
  currentBudget: '#94a2b8',
  commissionedContract: '#637288',
  commissionedSupplement: '#334155',
  payment: '#1d2a3b',
  contractForecast: '#0c4a6e',
  restBudget: '#085a85',
  income: '#3dd599',
  outcome: '#b71e23'
} as Record<string, string>;
const BAR_WIDTH = 30;

interface ExtraBarDataIntf {
  label: string;
  data: number;
  background: string;
}

interface DashboardWidgetWaterfallProps {
  widget: WidgetConfig;
  projectData: ProjectReadModel;
}

export const DashboardWidgetWaterfall = (props: DashboardWidgetWaterfallProps) => {
  const { widget, projectData } = props;
  const { t } = useTranslation();
  const locale = getLanguageAndLocale().locale;
  const loadedProjectId = useLoadedProjectId();

  const chartContainer = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (chartContainer.current) {
      setHeight(chartContainer.current.clientHeight);
      setWidth(chartContainer.current.clientWidth);
    }
  }, []);

  const { data: budgetReportResponse, isFetching } = useApiGetWaterfallBudgetReportQuery(
    {
      projectId: loadedProjectId ?? 'unset',
      initialCalculationModelId: widget?.additionalConfig?.Waterfall?.calculationModelId ? widget?.additionalConfig[DashboardWidgetType.Waterfall].calculationModelId : '' 
    },
    { skip: !loadedProjectId },
  );

  const options: ChartOptions = useMemo(() => {
    return {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        title: {
          display: false,
        },
        legend: {
          display: false
        },
        tooltip: {
          enabled: true,
          filter: (tooltipItem) => {
            return tooltipItem.dataset.label !== "commissionedSupplementEmpty";
          },
          callbacks: {
            title: (tooltipItem) => {
              if (!tooltipItem[0]) return '';
              return tooltipItem[0].dataset.label === "restBudget" ? t('dashboard.widget.financingControlling.restBudget') : tooltipItem[0].label;
            },
            label: (tooltipItem) => {
              return new Intl.NumberFormat(locale, {
                style: 'currency',
                currency: 'EUR',
                maximumFractionDigits: 2,
                signDisplay: 'auto',
                notation: 'compact',
                compactDisplay: 'short',
                currencyDisplay: 'narrowSymbol'
              }).format(Number(tooltipItem.raw as number));
            }
          }
        },
        datalabels: {
          display: false
        }
      },
      scales: {
        x: {
          stacked: true,
          beginAtZero: true,
          ticks: {
            font: {
              family: 'Roboto',
              size: 9,
            },
            color: '#64748B',
            padding: 5,
            showLabelBackdrop: false
          },
          grid: {
            drawOnChartArea: false,
            drawTicks: false
          },
          border: {
            width: 0
          }
        },
        y: {
          stacked: true,
          beginAtZero: true,
          ticks: {
            font: {
              family: 'Roboto',
              size: 10
            },
            color: '#64748B',
            callback: (value) => {
              return new Intl.NumberFormat(locale, {
                style: 'currency',
                currency: 'EUR',
                maximumFractionDigits: 2,
                signDisplay: 'auto',
                notation: 'compact',
                compactDisplay: 'short',
                currencyDisplay: 'narrowSymbol'
              }).format(Number(value));
            },
            mirror: true,
            padding: 10,
            labelOffset: -10,
            z: 10
          },
          position: 'right',
          grid: {
            drawBorder: false,
            color: (context) => (context.tick.value === 0 ? "#cbd5e1" : "rgba(0,0,0,0.1)"),
            lineWidth: (context) => (context.tick.value === 0 ? 2 : 1),
          },
          border: {
            display: false
          }
        }
      },
      layout: {
        padding: {
          top: 0,
          left: 0,
          bottom: 0,
          right: 0
        },
      },
    };
  }, [locale, t]);

  const data = useMemo(() => {
    const datasets = [] as ChartDataset[];
    const labels = [] as string[];

    if (budgetReportResponse) {
      Object.keys(budgetReportResponse).forEach((key, i) => {
        if (
          key !== 'restBudget' && 
          !(projectData.calculationModels.length === 1 && key === 'initialBudget') &&
          !(!budgetReportResponse.income && (key === 'income' || key === 'outcome'))
        ) {
          labels.push(t(`dashboard.widget.financingControlling.${key}`));
        }
      });
      // Empty label to create some space between last bar and y axis
      labels.push("");

      const generateDataset = (section: string, prepend?: ExtraBarDataIntf, append?: ExtraBarDataIntf) => {
        const previousLength = datasets.length > 0 ? datasets[datasets.length - 1].data.length : 0;
        const data = new Array(previousLength).fill(null);

        const pushDataset = (
          label: string,
          value: number,
          backgroundColor: string
        ) => {
          datasets.push({
            label,
            data: [...data, value],
            backgroundColor,
            barThickness: BAR_WIDTH,
          });
        };

        if (prepend) {
          pushDataset(prepend.label, prepend.data, prepend.background);
        }
      
        pushDataset(
          section,
          budgetReportResponse[section as keyof WaterfallBudgetReportReadModel],
          BAR_COLORS[section]
        );
      
        if (append) {
          pushDataset(append.label, append.data, append.background);
        }
      };

      if (projectData.calculationModels.length > 1) {
        generateDataset('initialBudget');
      }

      generateDataset('currentBudget');
      generateDataset('commissionedContract');
      generateDataset('commissionedSupplement', {
        label: 'commissionedSupplementEmpty',
        data: budgetReportResponse.commissionedContract,
        background: 'rgba(0, 0, 0, 0)'
      });
      generateDataset('payment');
      generateDataset('contractForecast', undefined, {
        label: 'restBudget',
        data: budgetReportResponse.restBudget,
        background: BAR_COLORS.restBudget
      });

      if (budgetReportResponse.income) {
        generateDataset('income');
        generateDataset('outcome');
      }
    }

    return {
      labels,
      datasets
    };
  }, [budgetReportResponse, t, projectData.calculationModels]);

  const percentagesPlugin = {
    id: "percentagesPlugin",
    beforeDraw: (chart: ChartJS) => {
      if (!budgetReportResponse) return;

      const { ctx } = chart;
      
      ctx.save();
      ctx.font = "bold 11px Roboto";
      ctx.textAlign = "center";

      if (budgetReportResponse.commissionedContract && budgetReportResponse.commissionedSupplement) {
        ["commissionedContract", "commissionedSupplement"].forEach((section) => {
          const index = chart.data.datasets.findIndex((dataset) => dataset.label === section);
          if (index > -1) {
            const meta = chart.getDatasetMeta(index);
            const bar = meta.data[meta.data.length - 1];
  
            const val = Math.round((budgetReportResponse[section as keyof WaterfallBudgetReportReadModel] * 100) / (budgetReportResponse.commissionedContract + budgetReportResponse.commissionedSupplement));
            const x = bar.x;
            const y = bar.y - (val < 0 ? -14: 5);
            ctx.fillStyle = BAR_COLORS[section];
            ctx.fillText(`${(section === "commissionedSupplement" ? "+" : "")}${val}%`, x, y);
          }
        });
      }

      if (budgetReportResponse.restBudget) {
        const index = chart.data.datasets.findIndex((dataset) => dataset.label === "restBudget");
        if (index > -1) {
          const meta = chart.getDatasetMeta(index);
          const bar = meta.data[meta.data.length - 1];
  
          const val = Math.round((budgetReportResponse.restBudget * 100) / budgetReportResponse.currentBudget);
          const x = bar.x;
          const y = bar.y - (val < 0 ? -14: 5);
          ctx.fillStyle = BAR_COLORS.restBudget;
          ctx.fillText(`+${val}%`, x, y);
        }
      }

      ["payment", "income", "outcome"].forEach((section) => {
        if (budgetReportResponse[section as keyof WaterfallBudgetReportReadModel] && budgetReportResponse.contractForecast) {
          const datasetIndex = chart.data.datasets.findIndex((dataset) => dataset.label === section);
          if (datasetIndex > -1) {
            const meta = chart.getDatasetMeta(datasetIndex);
            const bar = meta.data[meta.data.length - 1];
            const val = Math.round((budgetReportResponse[section as keyof WaterfallBudgetReportReadModel] * 100) / budgetReportResponse.contractForecast);
            const x = bar.x;
            const y = bar.y - (val < 0 ? -14: 5);
            if (section === "income") {
              ctx.fillStyle = BAR_COLORS.income;
            } else if (section === "outcome") {
              ctx.fillStyle = BAR_COLORS.outcome;
            } else {
              ctx.fillStyle = "black";
            }
            ctx.fillText(`${val}%`, x, y);
          }
        }
      });
  
      ctx.restore();
    },
  };

  const drawHorizontalLinePlugin = {
    id: "drawHorizontalLine",
    beforeDraw: (chart: ChartJS) => {
      const { ctx, scales, data } = chart;
      
      const currentBudgetIndex = chart.data.datasets.findIndex((dataset) => dataset.label === "currentBudget");
      const forecastIndex = chart.data.datasets.findIndex((dataset) => dataset.label === "contractForecast");

      if (currentBudgetIndex > -1 && forecastIndex > -1) {
        const dataset = data.datasets[currentBudgetIndex].data;
        const yPosition = scales.y.getPixelForValue(dataset[0] as number);
    
        ctx.save();
        ctx.beginPath();
        ctx.strokeStyle = "#c8dae4";
        ctx.lineWidth = 1;

        const currentBudgetData = chart.getDatasetMeta(currentBudgetIndex);
        const currentBudgetX = currentBudgetData.data[currentBudgetData.data.length - 1].x - (BAR_WIDTH / 2);
        const forecastData = chart.getDatasetMeta(forecastIndex);
        const forecastX = forecastData.data[forecastData.data.length - 1].x + (BAR_WIDTH / 2);
    
        ctx.moveTo(currentBudgetX, yPosition);
        ctx.lineTo(forecastX, yPosition);
        ctx.stroke();
        ctx.restore();
      }
    },
  };

  return (
    <WidgetContainer>
      <WidgetContainerTitle>
        {widget.title ? widget.title : t('dashboard.widget.waterfall.title')}
      </WidgetContainerTitle>
      <WidgetContainerContent className="flex-1">
        {isFetching && <LoadingIndicator mode="overlay" text={t('common.loading')} />}
        {!isFetching && (
          <div ref={chartContainer} className='h-full'>
            <Chart options={options} type="bar" data={data} plugins={[percentagesPlugin, drawHorizontalLinePlugin]} height={height} width={width} />
          </div>
        )}
      </WidgetContainerContent>
    </WidgetContainer>
  );
};
