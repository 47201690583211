import { useEffect, useMemo } from 'react';
import { MapContainer, TileLayer, useMapEvents } from 'react-leaflet';
import L, { FitBoundsOptions } from 'leaflet';
import { useTranslation } from 'react-i18next';
import 'leaflet/dist/leaflet.css';
import { ICON_MAP_MARKER_IMAGE } from '../images';

interface CenteredFeatureGroupProps {
  geo: L.LatLngExpression;
  offsetWidth: number;
}

const CenteredFeatureGroup = ({ geo, offsetWidth }: CenteredFeatureGroupProps) => {
  const map = useMapEvents({
    unload: (event) => {
      // fixes: Cannot read properties of undefined (reading '_leaflet_pos') at _r._onZoomTransitionEnd (leaflet-src.js:4839:10)
      delete event.target._animatingZoom;
    },
  });

  // Declare our custom leaflet map marker icon
  const MAP_PARKER_ICON = new L.Icon({
    iconUrl: ICON_MAP_MARKER_IMAGE,
    iconRetinaUrl: ICON_MAP_MARKER_IMAGE,
    popupAnchor: [-0, -0],
    iconSize: [27, 39],
  });

  const marker = L.marker(geo, { icon: MAP_PARKER_ICON });
  const featureGroup = L.featureGroup([marker]).addTo(map);

  useEffect(() => {
    map.invalidateSize();
    map.fitBounds(
      featureGroup.getBounds() as L.LatLngBoundsExpression,
      { paddingBottomRight: [offsetWidth, 0] } as FitBoundsOptions,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offsetWidth, geo]);

  return null;
};

export interface LocationInformation {
  street: string;
  number: string | null;
  postalCode: string;
  city: string;
}

interface ProjectInfoCardMapContentProps {
  latitude?: number | null;
  longitude?: number | null;
  offsetWidth?: number;
}

export const ProjectInfoCardMapContent = ({ offsetWidth = 0, ...props }: ProjectInfoCardMapContentProps) => {
  // Default openstreetmap tile set provider url
  const MAP_PROVIDER_URL = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';

  const { t } = useTranslation();

  const geo: L.LatLngExpression = useMemo(() => {
    return [props.latitude || 0, props.longitude || 0];
  }, [props.latitude, props.longitude]);

  if (!props.latitude || !props.longitude) {
    return (
      <div className="text-center text-gray-500">
        <p>{t('project.projectInfoCardMapError')}</p>
      </div>
    );
  }

  return (
    <MapContainer
      attributionControl={false}
      center={geo}
      zoom={17}
      scrollWheelZoom={true}
      zoomControl={true}
      doubleClickZoom={true}
      className="w-full h-full z-0"
      // zoomAnimation={false} // zoomAnimation causes leaflet pos error
    >
      <TileLayer url={MAP_PROVIDER_URL} />
      <CenteredFeatureGroup geo={geo} offsetWidth={offsetWidth} />
    </MapContainer>
  );
};
