import { HintBox, ModalOnCloseProps, ConfirmModal, LoadingIndicator } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';

interface ApiClientMappingDeleteModalProps extends ModalOnCloseProps {
  itemId: string;
  itemName: string;
  deleteItem: (itemId: string) => void;
  isLoading?: boolean;
}

export const ApiClientMappingDeleteModal = ({
  itemId,
  itemName,
  onClose,
  deleteItem,
  isLoading,
}: ApiClientMappingDeleteModalProps) => {
  const { t } = useTranslation();

  return (
    <ConfirmModal
      title={t('app.settingsApiDatevConnectionDeleteItem', { name: itemName ?? '' })}
      onConfirm={() => deleteItem(itemId)}
      onClose={() => onClose(true)}
      confirmVariant="danger"
      confirmText={t('common.delete')}
    >
      {isLoading && <LoadingIndicator text={t('common.deleteLoading') ?? ''} />}
      <HintBox hintType="warning">{t('app.settingsApiDatevConnectionDeleteItemWarning')}</HintBox>
    </ConfirmModal>
  );
};
