import * as yup from 'yup';
import { InferType } from 'yup';
import { WidgetDashboardEditFormProps } from './WidgetEditFormDefault';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  AddButton,
  BaseSelect,
  BaseSelectOption,
  Form,
  FormField,
  FormRefHandle,
  NumberInput,
  PercentageIcon,
  SlideOver,
  SlideOverTitle,
  TextInput,
  TrashIcon,
} from '@client/shared/toolkit';
import { UpsertProjectClockDatasetPayload } from '@client/shared/api';
import cn from 'classnames';

const WidgetDashboardEditFormProjectClockDatasetValidationSchema = yup.object({
  name: yup.string().required('validation.required'),
  value: yup.number().min(0).max(100).required('validation.required'),
  deviation: yup.number().min(-100).max(100).required('validation.required')
});

const WidgetDashboardEditFormProjectClockValidationSchema = yup.object({
  title: yup.string().required('validation.required'),
  size: yup.string().required('validation.required'),
  datasets: yup
      .array()
      .of(WidgetDashboardEditFormProjectClockDatasetValidationSchema)
      .min(1)
      .required('validation.required'),
});

export type WidgetDashboardEditFormProjectClockValidationValues = InferType<
  typeof WidgetDashboardEditFormProjectClockValidationSchema
>;

const DEFAULT_DATASET: UpsertProjectClockDatasetPayload = {
  name: '',
  value: 0,
  deviation: 0
};

export const MAX_DATASETS = 5;

export const WidgetEditFormProjectClock = (props: WidgetDashboardEditFormProps) => {
  const { buttons, widget, handleSubmit, sizeOptions, selectedSize } = props;
  const { t } = useTranslation();

  const formRef = useRef<FormRefHandle<WidgetDashboardEditFormProjectClockValidationValues>>();
  const [datasets, setDatasets] = useState<UpsertProjectClockDatasetPayload[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    setDatasets(widget?.widget.additionalConfig?.ProjectClock ?? [DEFAULT_DATASET]);
  }, [widget?.widget.additionalConfig?.ProjectClock]);

  const defaultFormValues = useMemo(() => {
    return {
      title: widget?.widget.title ?? t('dashboard.widget.projectClock.title'),
      size: selectedSize,
      datasets: widget?.widget.additionalConfig?.ProjectClock ?? [DEFAULT_DATASET],
    };
  }, [t, widget?.widget.title, selectedSize, widget?.widget.additionalConfig?.ProjectClock]);

    const onAdd = () => {
      setDatasets((prevDataset) => {
        if (prevDataset.length >= MAX_DATASETS) return prevDataset;

        const newDataset = [...prevDataset, { name: '', value: 0, deviation: 0 }];
        formRef.current?.setValue('datasets', newDataset);

        return newDataset;
      });
    };

    const onUpdateDataset = (data: UpsertProjectClockDatasetPayload, index: number) => {
      setDatasets((prevDataset) => {
        const newDataset = prevDataset.map((dataset, i) => (i === index ? data : dataset));
        formRef.current?.setValue('datasets', newDataset);
        return newDataset;
      });
    };

    const onDeleteDataset = (index: number) => {
      setDatasets((prevDataset) => {
        const newDataset = prevDataset.filter((_, i) => i !== index);
        formRef.current?.setValue('datasets', newDataset);
        return newDataset;
      });
    };

  return (
    <Form<WidgetDashboardEditFormProjectClockValidationValues>
      onSubmit={handleSubmit}
      validationSchema={WidgetDashboardEditFormProjectClockValidationSchema}
      defaultValues={defaultFormValues}
      className="flex flex-col flex-grow min-h-0"
      ref={formRef}
    >
      <SlideOver.Content className="p-8">
        <FormField name="title">
          {(control) => <TextInput label={t('dashboard.widget.edit.title')} {...control} />}
        </FormField>
        <FormField name="size">
          {(control) => <BaseSelect label={t('dashboard.widget.size')} options={sizeOptions} {...control} />}
        </FormField>
        {datasets.map((dataset, index) => (
          <ProjectClockValueForm
            key={`project-clock-value-form-${index}`}
            dataset={dataset}
            index={index}
            onUpdateDataset={(data: UpsertProjectClockDatasetPayload) => onUpdateDataset(data, index)}
            canDelete={datasets.length > 1}
            onDelete={() => onDeleteDataset(index)}
            handlePopoverVisibility={(isOpen) => setIsDropdownOpen(isOpen)}
          />
        ))}
        {datasets.length < MAX_DATASETS && (
          <div className={cn('flex w-full justify-end items-center z-50 relative', { 'opacity-0 pointer-events-none': isDropdownOpen })}>
            <AddButton onClick={onAdd} className="-mt-4" />
          </div>
        )}
      </SlideOver.Content>
      {buttons}
    </Form>
  );
};

interface ProjectClockValueFormProps {
  dataset: UpsertProjectClockDatasetPayload;
  onUpdateDataset: (data: UpsertProjectClockDatasetPayload) => void;
  index: number;
  canDelete: boolean;
  onDelete: () => void;
  handlePopoverVisibility?: (isOpen: boolean) => void;
}

const ProjectClockValueForm = (props: ProjectClockValueFormProps) => {
  const { dataset, index, canDelete, onDelete, onUpdateDataset, handlePopoverVisibility } = props;
  const { t } = useTranslation();

   const valueLabelOptions: BaseSelectOption[] = useMemo(() => {
      return [
        {
          value: 'marketing',
          label: t('dashboard.widget.projectClock.datasetLabel.marketing'),
        },
        {
          value: 'planning',
          label: t('dashboard.widget.projectClock.datasetLabel.planning'),
        },
        {
          value: 'awards',
          label: t('dashboard.widget.projectClock.datasetLabel.awards'),
        },
        {
          value: 'constructionStatus',
          label: t('dashboard.widget.projectClock.datasetLabel.constructionStatus'),
        },
        {
          value: 'payment',
          label: t('dashboard.widget.projectClock.datasetLabel.payment'),
        },
      ];
    }, [t]);

  return (
    <div>
      <div className="flex justify-between items-center">
        <SlideOverTitle title={`${index + 1}. ${t('dashboard.widget.projectClock.dataset')}`} />
        {canDelete && <TrashIcon className="text-gray-500 w-5 h-5 mt-4 cursor-pointer" onClick={onDelete} />}
      </div>
      <FormField name={`datasets[${index}].name`}>
        {(control) => <BaseSelect
        {...control}
        label={t('dashboard.widget.projectClock.datasetTitle')}
        value={dataset.name}
        options={valueLabelOptions}
        onChange={(val) => {
          const updatedDataset = { ...dataset, name: val };
          onUpdateDataset(updatedDataset);
          control.onChange(val);
        }}
        handlePopoverVisibility={handlePopoverVisibility}
      />}
      </FormField>
      <FormField name={`datasets[${index}].value`}>
        {(control) => <NumberInput
            label={t('dashboard.widget.projectClock.datasetValue')}
            {...control}
            value={dataset.value}
            onChange={(val) => {
              onUpdateDataset({ ...dataset, value: val ?? 0 });
              control.onChange(val);
            }}
            icon={<PercentageIcon />}
            decimalScale={0}
            allowNegative={false}
            min={0}
            max={100}
            maxLength={3}
            step={1}
          />}
      </FormField>
      <FormField name={`datasets[${index}].deviation`}>
        {(control) => <NumberInput
          {...control}
          label={t('dashboard.widget.projectClock.datasetDeviation')}
          value={dataset.deviation}
          onChange={(val) => {
            onUpdateDataset({ ...dataset, deviation: val ?? 0 });
            control.onChange(val);
          }}
          formatGroup={false}
          allowNegative={true}
          icon={<PercentageIcon />}
          decimalScale={3}
          min={-100}
          max={100}
          maxLength={6}
          step={1}
        />}
      </FormField>
    </div>
  );
};
