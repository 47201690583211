import { CompanyBranchReadModel, useApiGetProjectsQuery } from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { countryCodes } from '@client/shared/utilities';
import { getSettingsProjectThumbnail } from '../../utils';
import { LoadingIndicator, PencilIcon } from '@client/shared/toolkit';
import React from 'react';

interface PartnerCompanyBranchProps {
  branch?: CompanyBranchReadModel;
  onEdit: (branch: CompanyBranchReadModel) => void;
}

export const PartnerCompanyBranch = ({ branch, onEdit }: PartnerCompanyBranchProps) => {
  const { t } = useTranslation();
  const { data, isFetching } = useApiGetProjectsQuery();

  const projects = (data?.projects ?? []).slice();
  const groups = (data?.projectGroups ?? []).slice();

  return (
    <div className="w-full grid grid-cols-2 gap-3">
      {isFetching && <LoadingIndicator text={t('app.settingsCompaniesLoading') ?? ''} />}
      <div className="bg-white p-4 relative">
        <div className="right-5 absolute">
          <PencilIcon
            className="hover:opacity-70 cursor-pointer w-5"
            onClick={() => (branch ? onEdit(branch) : undefined)}
          />
        </div>
        <div className="font-bold">
          {t('app.masterDataBranch')}
          <br />
          {branch?.name}
        </div>
        <div className="mt-4 flex w-full justify-between ">
          <div className="w-1/2">
            {branch?.address?.supplement && (
              <>
                {branch?.address?.supplement} <br />
              </>
            )}
            {(branch?.address?.street || branch?.address?.number) && (
              <>
                {branch?.address?.street} {branch?.address?.number} <br />
              </>
            )}
            {(branch?.address?.postalCode || branch?.address?.city) && (
              <>
                {branch?.address?.postalCode} {branch?.address?.city} <br />
              </>
            )}
            {countryCodes.getCountryName(branch?.address?.country ?? '')}
          </div>
          <div className="w-1/2">
            {branch?.phone && (
              <>
                {t('common.phoneNumber')}: {branch?.phone}
                <br />
              </>
            )}
            {branch?.mobile && (
              <>
                {t('common.mobileNumber')}: {branch?.mobile}
              </>
            )}
          </div>
        </div>
        <div>
          {branch?.bankAccounts.map((account, index) => (
            <div className="mt-4" key={index}>
              <div className="font-bold">{t('app.companiesBankAccount')}</div>
              <div className="mt-2">
                {account.bankName && (
                  <>
                    {account.bankName} <br />
                  </>
                )}
                {account.accountOwner && (
                  <>
                    {account.accountOwner} <br />
                  </>
                )}
                {account.iban && (
                  <>
                    {account.iban} <br />
                  </>
                )}
                {account.swift}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* <div className="bg-white p-4">
        <div className="text-xs text-gray-600">{t('app.masterDataEmployees')}</div>
        <div className="mt-4 flex flex-col">
          {mitarbeiter.map((subject, index) => (
            <div className="py-2 flex">
              <UserAvatar
                key={index}
                userId={subject.userId}
                userName={subject.name}
                hasAvatar={subject.hasAvatar}
                classNames={'h-10 w-10 bg-slate-400 even:bg-slate-600'}
              />
              <div className="pl-3">
                <div className="font-bold">{subject.name}</div>
                <div className="text-xs text-gray-800">{subject.role}</div>
              </div>
            </div>
          ))}
        </div>
      </div> */}
      <div className="bg-white p-4">
        <div className="text-xs text-gray-600">{t('app.masterDataAssignedProjects')}</div>
        <div className="flex flex-col h-full pb-3 justify-center items-center">
          {branch?.projectAssignments.map((project, index) => (
            <div
              className="w-full h-full my-2 py-6 flex flex-col justify-center items-center shadow-lg [text-shadow:_1_1px_0_rgb(0_0_0_/_90%)] text-white"
              style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2)), url(${getSettingsProjectThumbnail(
                  project.project.projectId,
                  projects,
                  groups
                )})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
              key={project.project.projectId}
            >
              <div className="font-bold">{project.project.name}</div>
              <div className="text-xs font-bold">{project.project.city}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
