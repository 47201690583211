import {
  Button,
  ContextMenuItem,
  FloatingActionButton,
  LoadingIndicator,
  Modal,
  AddIcon,
  UploadProjectCard,
  EmptyProjectsCard,
  SkyscrapersDottedIcon,
  Pagination,
  UploadIcon
} from '@client/shared/toolkit';
import {
  OpenModal,
  ProjectCopyModal,
  ProjectDeleteModal, ProjectMoveModal,
  ProjectOverviewCard,
  ProjectSettings,
  ProjectUpload
} from '@client/project/edit';
import {
  ProjectsFloatingMenu,
  MultiProjectDeleteModal,
  MultiProjectNewWizard,
  MultiProjectOverviewCard,
  ProjectNewWizard
} from '.';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { setToggledFilters, useProjects } from '@client/project/store';
import {
  ShortProjectReadModel,
  ShortProjectGroupReadModel,
  TrustedProjectReadModel,
  apiEndpointUrls
} from '@client/shared/api';
import { enableMapSet, produce } from 'immer';
import {
  ProtectedRoute,
  ROUTES_CONFIG,
  useHasUploadLicense,
  useValidateTenantPermission
} from '@client/shared/permissions';
import { Square2StackIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { getCrossTenantProjectThumbnail, getInitials } from '@client/shared/utilities';
import { useDispatch } from 'react-redux';
import { ProjectNewFromTemplateWizard } from './ProjectNewFromTemplateWizard';
enableMapSet();
export interface ProjectsContainerProps {
  searchText: string | null;
  showAllCheckboxes: boolean;
  searchResults: string[];
  setSearchText: (val: string) => void;
}

type ProjectsContainerCardListItem = {
  type: 'multiProject' | 'project' | 'trustedProject';
  data: ShortProjectGroupReadModel | ShortProjectReadModel | TrustedProjectReadModel;
};
type ProjectsContainerCardList = ProjectsContainerCardListItem[];

export const ProjectsContainer = (props: ProjectsContainerProps) => {
  const { searchText, showAllCheckboxes, searchResults, setSearchText } = props;
  const { t } = useTranslation();
  const navigate = useNavigate()
  const { projects, groups, trustedProjects, isFetching } = useProjects();
  const dispatch = useDispatch();
  const amountPerPage = 8;

  const [openModal, setOpenModal] = useState<OpenModal>('None');
  const [expandedProject, setExpandedProject] = useState<string | undefined>(undefined);
  const [checkedStatus, setCheckedStatus] = useState(new Set<string>());

  const [selectedGroupProject, setSelectedGroupProject] = useState<ShortProjectGroupReadModel | null | undefined>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const projectData = useMemo(() => {
    if (searchText) {
      return projects.filter((obj) => searchResults.includes(obj.id))
    }
    return projects
  }, [projects, searchText, searchResults]);

  const multiProjectData = useMemo(() => {
    if (searchText) {
      return groups.filter((obj) => {
        const childProjects = obj.projects.map((p) => p.id);
        return searchResults.includes(obj.id) || childProjects.some(item => searchResults.includes(item));
      })
    }
    return groups;
  }, [groups, searchText, searchResults]);

  const trustedProjectsData = useMemo(() => {
    if (searchText) {
      const searchFor = searchText.toLowerCase();
      return trustedProjects.filter((trustedProject) => trustedProject.name.toLowerCase().includes(searchFor))
    }
    return trustedProjects
  }, [trustedProjects, searchText])

  const clearChecked = () => {
    setCheckedStatus(new Set<string>());
  };

  const selectProject = (id: string) => {
    setCheckedStatus(
      produce((draft) => {
        if (draft.has(id)) {
          draft.delete(id);
        } else {
          draft.add(id);
        }
      })
    );
  };

  const onClickReporting = (direction: 'project' | 'multiProject') => {
    if(direction === 'project' && firstSelectedProject){
      navigate(`${ROUTES_CONFIG.PROJECT_REPORTING.path.replace(':id', firstSelectedProject.id)}`)
    } else if(direction === 'multiProject' && firstSelectedMultiProject) {
      navigate(`${ROUTES_CONFIG.MULTI_PROJECT_REPORTING.path.replace(':id', firstSelectedMultiProject.id)}`)
    }
  }

  const hasTenantProjectCreatePermission = useValidateTenantPermission(['TENANT_PROJECT_CREATE']);
  const hasTenantProjectCopyPermission = useValidateTenantPermission(['TENANT_PROJECT_COPY']);
  const hasUploadLicense = useHasUploadLicense();

  let contextItems: ContextMenuItem[] = [];

  if (hasTenantProjectCreatePermission) {
    contextItems = [
      {
        label: t('app.wizardNewMultiProject'),
        subtitle: t('app.emptyProject'),
        isDisabled: !hasTenantProjectCreatePermission,
        icon: <AddIcon />,
        onClick: () => {
          setCheckedStatus(new Set<string>());
          setSelectedGroupProject(null);
          setOpenModal('NewGroup');
        },
      },
      {
        label: t('app.newProject'),
        subtitle: t('app.emptyProject'),
        isDisabled: !hasTenantProjectCreatePermission,
        icon: <AddIcon />,
        onClick: () => {
          setSelectedGroupProject(null);
          setOpenModal('New');
        },
        passDataCy: "new-project"
      },
      {
        label: t('app.newProject'),
        subtitle: t('app.emptyProjectFromTemplate'),
        isDisabled: !hasTenantProjectCreatePermission,
        icon: <AddIcon />,
        onClick: () => {
          setSelectedGroupProject(null);
          setOpenModal('NewFromTemplate');
        },
        passDataCy: "new-project"
      },
      {
        label: t('common.copyEntity', { entity: t('app.project') }),
        subtitle: t('app.newProjectAsCopy'),
        isDisabled: !hasTenantProjectCopyPermission || projects.length === 0,
        icon: <Square2StackIcon />,
        onClick: () => setOpenModal('Copy'),
      },
    ];
  }
  if (hasUploadLicense) {
    contextItems.push({
      label: t('projectControl.uploadInvoiceTitle'),
      subtitle: t('projectControl.uploadInvoiceSubTitle'),
      icon: <UploadIcon />,
      onClick: () => {
        navigate(ROUTES_CONFIG.PROJECTS_UPLOAD.path);
      },
      isDisabled: projects.length === 0
    });
  }

  const handleCloseModal = (clearSelection?: boolean | undefined) => {
    setOpenModal('None');
    if (clearSelection) {
      clearChecked();
    }
  };

  const openNewMultiProjectWizard = () => {
    setOpenModal('NewGroup');
  };

  const handleClickOutside = (event: globalThis.MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target as HTMLDivElement)) {
      let element: ParentNode | null = event.target as HTMLElement | ParentNode | null;
      let close = true;
      while (element && element.parentNode) {
        element = element.parentNode;
        if (element.parentNode) {
          const id = (element as HTMLElement)?.getAttribute('id');
          // if clicked target is part of modal or slide over, do not close the expanded project
          if (id === 'headlessui-portal-root') {
            close = false;
            break;
          }
          // or if clicked target is part of floating menu
          const classNames = (element as HTMLElement)?.getAttribute('class');
          if (classNames?.includes('floating-projects-menu')) {
            close = false;
            break;
          }
        }
      }
      if (close) {
        setSelectedGroupProject(null);
        setExpandedProject(undefined);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('mousemove', handleClickOutside, true);
    };
  }, []);

  const firstSelectedProject = useMemo(() => {
    if (checkedStatus.size) {
      const first = Array.from(checkedStatus.values())[0];

      const inProjectData = projectData.find((data) => {
        return data.id === first;
      });

      const inMultiProjectData = multiProjectData.reduce((acc, group) => {
        const foundProject = group.projects.find((project) => {
          return project.id === first;
        });
        if (foundProject) {
          acc = foundProject;
        }
        return acc;
      }, undefined as ShortProjectReadModel | undefined);

      return inProjectData ?? inMultiProjectData;
    }
    return undefined;
  }, [checkedStatus, projectData, multiProjectData]);

  const firstSelectedMultiProject = useMemo(() => {
    if (checkedStatus.size) {
      const first = Array.from(checkedStatus.values())[0];
      return multiProjectData.find((group) => group.id === first);
    }
    return null;
  }, [checkedStatus, multiProjectData]);

  const isMultiProjectSelected = useMemo(() => {
    const selected = Array.from(checkedStatus.values());
    let foundProject = null;
    selected.every((selectedId) => {
      foundProject = multiProjectData.find((data) => {
        return data.id === selectedId;
      });
      return !foundProject;
    });
    return !!foundProject;
  }, [checkedStatus, multiProjectData]);

  const openUploadWizard = (projectId: string, tenantId: string) => {
    navigate((`${ROUTES_CONFIG.PROJECTS_PROJECT_UPLOAD.path.replace(':projectId', projectId)}`).replace(':tenantId', tenantId));
  }

  const resetSearch = useCallback(() => {
    setSearchText('');
    dispatch(setToggledFilters({ filters: [], type: 'Project' }));
  }, [dispatch, setSearchText])

  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(amountPerPage);

  const updateShownItems = useCallback((startIndex: number, endIndex: number) => {
    setStartIndex(startIndex);
    setEndIndex(endIndex);
  }, []);

  const projectCardsTotal = useMemo(() => {
    return projectData.length + multiProjectData.length + trustedProjectsData.length;
  }, [projectData.length, multiProjectData.length, trustedProjectsData.length]);

  const cardsList = useMemo(() => {
    const list: ProjectsContainerCardList = [];
    if (multiProjectData.length) {
      multiProjectData.forEach((item) => {
        list.push({
          type: 'multiProject',
          data: item
        });
      })
    }
    if (projectData.length) {
      projectData.forEach((item) => {
        list.push({
          type: 'project',
          data: item
        });
      })
    }
    if (trustedProjectsData.length) {
      trustedProjectsData.forEach((item) => {
        list.push({
          type: 'trustedProject',
          data: item
        });
      })
    }
    return list;
  }, [multiProjectData, projectData, trustedProjectsData]);

  const visibleCards = useMemo(() => {
    return cardsList.slice(startIndex, endIndex);
  }, [cardsList, startIndex, endIndex]);

  return (
    <>
      {isFetching === true && <LoadingIndicator mode="overlay" text={t('app.loadingProjects')}></LoadingIndicator>}
      <div
        className="mt-0 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 relative grid-flow-dense"
        ref={containerRef}
      >
        {isFetching === false && groups.length === 0 && projects.length === 0 && (
          <EmptyProjectsCard
            onClick={() => setOpenModal('New')}
            disabled={hasTenantProjectCreatePermission}
            title={t('app.emptyCreateProject')}
            subTitle={t('app.emptyNoProjects')}
            icon={<SkyscrapersDottedIcon className="h-7" />}
          />
        )}
        {multiProjectData.length === 0 && projectData.length === 0 && searchText && projects.length > 0 && (
          <div className="inline-block">
            {t('app.noProjectsFound')}
            &nbsp;
            <Button variant="text" hasPadding={false} onClick={resetSearch}>{t('common.resetSearch')}</Button>
          </div>
        )}
        {visibleCards.map((item, index) => {
          if (item.type === 'multiProject') {
            const group = item.data as ShortProjectGroupReadModel;
            return (
              <MultiProjectOverviewCard
                project={group}
                key={group.id}
                showCheckbox={showAllCheckboxes}
                isProjectSelected={checkedStatus.has(group.id)}
                selectedProjects={checkedStatus}
                onSelectProject={selectProject}
                expanded={expandedProject === group.id}
                onToggleExpand={(id: string) => {
                  if (expandedProject === group.id) {
                    setExpandedProject(undefined);
                  } else {
                    setExpandedProject(group.id);
                  }
                }}
                onAddSubProject={() => {
                  setSelectedGroupProject(group);
                  setOpenModal('New');
                }}
                searchText={searchText ?? ''}
                multiProjects={multiProjectData}
              />
            );
          } else if (item.type === 'project') {
            const project = item.data as ShortProjectReadModel;
            return (
              <ProjectOverviewCard
                project={project}
                key={project.id}
                showCheckbox={showAllCheckboxes}
                isProjectSelected={checkedStatus.has(project.id)}
                onSelectProject={selectProject}
                enableHover
                searchText={searchText ?? ''}
                multiProjects={multiProjectData}
              />
            );
          } else if (item.type === 'trustedProject') {
            const project = item.data as TrustedProjectReadModel;
            const thumbnail = getCrossTenantProjectThumbnail(project)
            let logo = ''
            if (project.hasAvatarImage) {
              logo = apiEndpointUrls.apiGetCrossTenantAvatar.replace(':crossTenantId', project.tenantId)
            }
            return (
              <UploadProjectCard
                key={`upload-project-${project.id}-${index}`}
                badgeLabel={t('app.uploadProject')}
                logo={logo}
                logoAltText={project.name}
                onClick={() => openUploadWizard(project.id, project.tenantId)}
                subTitle={`${project.city} • ${project.tenantName}`}
                thumbnail={thumbnail}
                title={project.name}
                initials={getInitials(project.tenantName)}
              />
            );
          }
          return '';
        })}
      </div>
      {projectCardsTotal > amountPerPage && (
        <Pagination totalItems={projectCardsTotal} setShownItems={updateShownItems} amountPerPage={amountPerPage} />
      )}
      {Boolean(checkedStatus.size) && (
        <div className="h-16 fixed z-10 left-10 right-10 bottom-10 min-w-0 flex flex-row justify-center items-center">
          <ProjectsFloatingMenu
            onClickMultiProject={openNewMultiProjectWizard}
            onClickReporting={onClickReporting}
            selectedItemsCount={checkedStatus.size}
            clearSelection={clearChecked}
            openModal={(modal: OpenModal) => setOpenModal(modal)}
            isMultiProjectSelected={isMultiProjectSelected}
          />
        </div>
      )}

      <FloatingActionButton passDataCy="projects-button" menuItems={contextItems} position="fixed right-20 bottom-16" />

      <Modal isOpen={openModal === 'New'} onClose={handleCloseModal}>
        <ProjectNewWizard onClose={handleCloseModal} selectedGroupProject={selectedGroupProject} />
      </Modal>

      <Modal isOpen={openModal === 'NewFromTemplate'} onClose={handleCloseModal}>
        <ProjectNewFromTemplateWizard onClose={handleCloseModal} selectedGroupProject={selectedGroupProject} />
      </Modal>

      <Modal isOpen={openModal === 'Copy'} onClose={handleCloseModal}>
        <ProjectCopyModal
          onClose={handleCloseModal}
          selectedProject={firstSelectedProject}
          selectedGroupProject={selectedGroupProject}
        />
      </Modal>

      <Modal isOpen={openModal === 'NewGroup'} onClose={handleCloseModal}>
        <MultiProjectNewWizard
          selectedProjectIds={Array.from(checkedStatus.values())}
          onClose={handleCloseModal}
          onCreate={(id: string) => setExpandedProject(id)}
        />
      </Modal>

      <Modal isOpen={openModal === 'Delete' && !!firstSelectedMultiProject} onClose={handleCloseModal}>
        {firstSelectedMultiProject && (
          <MultiProjectDeleteModal project={firstSelectedMultiProject} onClose={handleCloseModal} />
        )}
      </Modal>

      <Modal isOpen={openModal === 'Delete' && !!firstSelectedProject} onClose={handleCloseModal}>
        {firstSelectedProject && (
          <ProjectDeleteModal project={firstSelectedProject} onClose={handleCloseModal} />
        )}
      </Modal>

      <Modal isOpen={openModal === 'Move' && !!firstSelectedProject} onClose={handleCloseModal}>
        <ProjectMoveModal multiProjects={multiProjectData} selectedProjectIds={Array.from(checkedStatus.values())} onClose={handleCloseModal} />
      </Modal>

      <Routes>
        <Route
          path={ROUTES_CONFIG.PROJECTS_PROJECT_SETTINGS.name}
          element={
            <ProtectedRoute
              routeConfig={ROUTES_CONFIG.PROJECTS_PROJECT_SETTINGS}
            >
              <ProjectSettings />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES_CONFIG.PROJECTS_UPLOAD.name}
          element={
            <ProtectedRoute
              routeConfig={ROUTES_CONFIG.PROJECTS_UPLOAD}
            >
              <ProjectUpload selectProject />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES_CONFIG.PROJECTS_PROJECT_UPLOAD.name}
          element={
            <ProtectedRoute
              routeConfig={ROUTES_CONFIG.PROJECTS_PROJECT_UPLOAD}
            >
              <ProjectUpload />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to='' />} />
      </Routes>
    </>
  );
};
