import { useApiGetCalculationModelQuery } from '@client/shared/api';
import { useLoadedVariantId } from './useLoadedVariantId';
import { useLoadedProjectId } from './useLoadedProjectId';
import { useHasLicenseFeature } from '@client/shared/permissions';

export const useLoadedVariant = () => {
  const loadedVariantId = useLoadedVariantId();
  const loadedProjectId = useLoadedProjectId();
  const hasCalculateLicenseFeature = useHasLicenseFeature('CALCULATE');

  return useApiGetCalculationModelQuery(
    {
      projectId: loadedProjectId ?? 'unset',
      calculationModelId: loadedVariantId ?? 'unset'
    },
    {
      skip: loadedVariantId == null || !hasCalculateLicenseFeature,
    }
  );
};
