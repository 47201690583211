import { useMapsLibrary } from "@vis.gl/react-google-maps";
import { ChangeEvent, useEffect, useRef, useState } from "react";

interface AddressAutocompleteProps {
  value: string;
  onChange?: (value: string) => void;
  onPlaceSelect: (place: google.maps.places.PlaceResult) => void;
}

export const AddressAutocomplete = ({ value, onChange, onPlaceSelect }: AddressAutocompleteProps) => {
  const [placeAutocomplete, setPlaceAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const places = useMapsLibrary('places');

  useEffect(() => {
    if (!places || !inputRef.current) return;

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, {
      fields: ['geometry', 'formatted_address', 'address_components']
    }));
  }, [places]);

  useEffect(() => {
    if (!placeAutocomplete) return;

    placeAutocomplete.addListener('place_changed', () => {
      onPlaceSelect(placeAutocomplete.getPlace());
    });
  }, [onPlaceSelect, placeAutocomplete]);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <div className="absolute top-2 left-2 right-2">
      <input ref={inputRef} value={value} onChange={handleOnChange} className="rounded px-3 py-1 outline-none w-full" />
    </div>
  );
};