import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SetProjectPayload = { projectId: string | undefined; variantId: string | undefined; isTemplate?: boolean; versionCreated?: boolean; };

export interface ProjectState {
  loadedProjectId?: string;
  loadedVariantId?: string;
  isTemplate?: boolean;
  versionCreated?: boolean;
}

const initialState: ProjectState = {};

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setProjectId: (state, action: PayloadAction<SetProjectPayload | undefined>) => {
      // if project id changes, remove variant information
      state.loadedProjectId = action.payload?.projectId;
      state.loadedVariantId = action.payload?.variantId;
      state.isTemplate = action.payload?.isTemplate ?? false;
      state.versionCreated = action.payload?.versionCreated ?? true;
    },
    setVariantId: (state, action: PayloadAction<string>) => {
      state.loadedVariantId = action.payload;
    },
    setIsTemplate: (state, action: PayloadAction<boolean>) => {
      state.isTemplate = action.payload;
    },
    setIsVersionCreated: (state, action: PayloadAction<boolean>) => {
      state.versionCreated = action.payload;
    },
  },
});

export const { setProjectId, setVariantId, setIsTemplate, setIsVersionCreated } = projectSlice.actions;
