import {
  DecoratedElement,
  FinancingElement,
} from '../../hooks';
import {
  addOrRemoveDecoratedElementFromExpandedIds,
  getRenderElementIsVisibleAndTopStyle
} from '../../utils';
import { CalculateProps } from '../CalculateContainer';
import {
  FinancingElementRow,
  FinancingGroupElement,
} from '..';
import React, { Fragment, RefObject } from 'react';
import { ToggleButton } from '@client/shared/toolkit';
import classNames from 'classnames';

interface CalculateFinancingRenderElementsProps extends CalculateProps {
  containerRef: RefObject<HTMLDivElement>;
  elements: DecoratedElement<FinancingElement>[];
  handleOpenSlideOver: (
    elementId: DecoratedElement<FinancingElement> | undefined,
    preselectedGroupId: string | null | undefined
  ) => void;
  handleOpenDeleteElement: (deleteElementId?: string | null) => void;
  handleLockElement: (lock: boolean, elementId?: string | null) => void;
  isReadOnly: boolean;
  searchResults: string[] | null;
}

export const CalculateFinancingRenderElements = (props: CalculateFinancingRenderElementsProps) => {
  const {
    containerRef,
    view,
    elements,
    expandedCatalogIds = [],
    onExpand,
    selectedVersionId,
    optionalColumn,
    obligoColumn,
    handleOpenSlideOver,
    handleOpenDeleteElement,
    handleLockElement,
    isReadOnly,
    searchResults,
  } = props;

  let counter = -1;
  let top = 0;

  const renderElements = (elements: DecoratedElement<FinancingElement>[]) => {
    return elements.map((element) => {
      const open = expandedCatalogIds.includes(element.categoryId);
      top = top + (element.level === 0 ? 32 : 0);
      const {
        isVisible,
        topStyle,
        counter: newCounter,
      } = getRenderElementIsVisibleAndTopStyle(counter, containerRef, top);
      counter = newCounter;

      return (
        <Fragment key={element.element.key}>
          {isVisible && (
            <div
              className={classNames(
                'w-full flex group bg-transparent hover:bg-neutral-100 transition-colors duration-200 h-[38px] absolute',
              )}
              data-level={element.level}
              style={{ top: topStyle }}
            >
              <ToggleButton
                open={open}
                className={classNames('absolute h-full z-[5]', {
                  invisible: element.children.length === 0,
                })}
                onClick={() => {
                  onExpand(
                    addOrRemoveDecoratedElementFromExpandedIds(expandedCatalogIds, element, open ? 'remove' : 'add'),
                  );
                }}
              />
              {element.element.type === 'group' ? (
                <FinancingGroupElement
                  view={view}
                  key={`financing_group_${element.element.key}`}
                  item={element}
                  expanded={open}
                  optionalColumn={optionalColumn}
                  obligoColumn={obligoColumn}
                  onCodeClick={() => {
                    onExpand(
                      addOrRemoveDecoratedElementFromExpandedIds(expandedCatalogIds, element, open ? 'remove' : 'add'),
                    );
                  }}
                  onNewElement={() => handleOpenSlideOver(undefined, element.element.group?.groupId)}
                  onClick={element.disableClick ? undefined : () => handleOpenSlideOver(element, undefined)}
                  selectedVersionId={selectedVersionId}
                  isReadOnly={isReadOnly}
                  searchResults={searchResults}
                />
              ) : (
                <FinancingElementRow
                  key={`financing_element_${element.element.key}`}
                  item={element}
                  optionalColumn={optionalColumn}
                  obligoColumn={obligoColumn}
                  onClick={() => handleOpenSlideOver(element, undefined)}
                  selectedVersionId={selectedVersionId}
                  view={view}
                  onRemove={
                    element.element.financingElement?.id
                      ? () => handleOpenDeleteElement(element.element.financingElement?.id)
                      : undefined
                  }
                  onLock={
                    element.element.financingElement?.id && !element.element.financingElement.isLocked
                      ? () => handleLockElement(true, element.element.financingElement?.id)
                      : undefined
                  }
                  onUnlock={
                    element.element.financingElement?.id && element.element.financingElement.isLocked
                      ? () => handleLockElement(false, element.element.financingElement?.id)
                      : undefined
                  }
                  isReadOnly={isReadOnly}
                  searchResults={searchResults}
                />
              )}
            </div>
          )}
          {open && renderElements(element.children)}
        </Fragment>
      );
    });
  };

  return renderElements(elements);
};
