import { CalculationModelRiskGroupReadModel, CalculationModelRiskElementReadModel } from '@client/shared/api';

export function isChildRiskElement(
  parentElement: CalculationModelRiskGroupReadModel | CalculationModelRiskElementReadModel,
  childElement: CalculationModelRiskGroupReadModel | CalculationModelRiskElementReadModel,
): boolean {
  if (parentElement === childElement || ('children' in parentElement && parentElement?.children?.length === 0)) {
    return false;
  }
  if (
    'children' in parentElement &&
    parentElement?.children?.some((referenceElement) => {
      const matchesGroupId =
        referenceElement.group && 'groupId' in childElement && referenceElement.group.groupId === childElement.groupId;

      const matchesRiskElementId =
        referenceElement.group &&
        'riskElementId' in childElement &&
        referenceElement.group.riskElementId === childElement.riskElementId;

      if (matchesGroupId || matchesRiskElementId) {
        return true;
      }

      if ('group' in referenceElement && referenceElement.group) {
        return isChildRiskElement(referenceElement.group, childElement);
      } else if ('riskElement' in referenceElement && referenceElement.riskElement) {
        return isChildRiskElement(referenceElement.riskElement, childElement);
      } else {
        return false;
      }
    })
  ) {
    return true;
  }

  return false;
}
