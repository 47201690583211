import {
  BaseSelectOption,
  Button,
  ContractIcon,
  DocumentDottedIcon,
  Form,
  FormField,
  LoadingIndicator,
  MultiSelect,
  SlideOver,
  SlideOverOnCloseProps,
  TextInput,
  TagWindowIcon,
  Modal,
  SlideOverTitle,
  ComboSelect,
} from '@client/shared/toolkit';
import React, { useMemo, useState } from 'react';
import {
  AuthProjectPermissionGroupReadModel,
  useApiGetAuthProjectGroupsQuery,
  useApiGetSelectableCommitmentsQuery,
  useApiGetSelectableContractsQuery,
  useApiGetSelectableInvoicesQuery,
  useApiPostCreateProjectPermissionGroupMutation,
  useApiPostUpdateProjectPermissionGroupMutation,
} from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { UserGroupIcon } from '@heroicons/react/24/outline';
import * as yup from 'yup';
import { InferType } from 'yup';
import { safeMutation } from '@client/shared/utilities';
import { ProjectGroupDeleteModal } from './ProjectGroupDeleteModal';
import classNames from 'classnames';
import { useLoadedVariantId } from '@client/project/store';

export const ProjectUserGroupEditValidationSchema = yup.object({
  name: yup.string().required('validation.required'),
  description: yup.string().optional().nullable(),
  userGroups: yup.array().of(yup.string().required('validation.required')).optional(),
  commitments: yup.array().of(yup.string().required('validation.required')).optional(),
  contracts: yup.array().of(yup.string().required('validation.required')).optional(),
  invoices: yup.array().of(yup.string().required('validation.required')).optional(),
});

export type ProjectUserGroupEditValidationValues = InferType<typeof ProjectUserGroupEditValidationSchema>;

interface EditProjectUserGroupSlideOverProps extends SlideOverOnCloseProps {
  group: AuthProjectPermissionGroupReadModel | null;
  projectId: string;
  allProjectPermissionGroup: string[];
}

export const ProjectUserGroupSlideOver = (props: EditProjectUserGroupSlideOverProps) => {
  const { group, projectId, allProjectPermissionGroup, onClose } = props;
  const { t } = useTranslation();
  const loadedVariantId = useLoadedVariantId();

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  const [create, { isLoading: isCreating }] = useApiPostCreateProjectPermissionGroupMutation();
  const [update, { isLoading: isUpdating }] = useApiPostUpdateProjectPermissionGroupMutation();

  const { data: projectUserGroups, isFetching } = useApiGetAuthProjectGroupsQuery({
    projectId: projectId,
  });

  const { data: fetchedContracts, isFetching: isFetchingContracts } = useApiGetSelectableContractsQuery(
    {
      projectId: projectId,
      calculationModelId: loadedVariantId ?? '',
    },
    {
      skip: !loadedVariantId,
    },
  );

  const { data: fetchedCommitments, isFetching: isFetchingCommitments } = useApiGetSelectableCommitmentsQuery(
    {
      projectId: projectId,
      calculationModelId: loadedVariantId ?? '',
    },
    {
      skip: !loadedVariantId,
    },
  );

  const { data: fetchedInvoices, isFetching: isFetchingInvoices } = useApiGetSelectableInvoicesQuery(
    {
      projectId: projectId,
      calculationModelId: loadedVariantId ?? '',
    },
    {
      skip: !loadedVariantId,
    },
  );

  // A user can be assigned to only ONE GROUP PER PROJECT.
  const userGroupOptions: BaseSelectOption[] = useMemo(() => {
    const options: BaseSelectOption[] = [];
    if (projectUserGroups && projectUserGroups.length) {
      projectUserGroups.forEach((userGroup) => {
        if (
          (!allProjectPermissionGroup.includes(userGroup.id) ||
            (group && group.assignedGroups.find((g) => g.id === userGroup.id))) &&
          !options.find((o) => o.value === userGroup.id)
        ) {
          options.push({
            label: userGroup.name,
            value: userGroup.id,
          });
        }
      });
    }
    return options;
  }, [projectUserGroups, allProjectPermissionGroup, group]);

  const handleSubmit = async (data: ProjectUserGroupEditValidationValues) => {
    if (!group) {
      try {
        await safeMutation(
          create,
          {
            projectId: projectId,
            body: {
              name: data.name,
              comment: data.description,
              assignedGroups: data.userGroups ?? [],
              assignedCommitments: data.commitments ?? [],
              assignedContracts: data.contracts ?? [],
              assignedInvoices: data.invoices ?? [],
            },
          },
          isCreating,
        );

        onClose(true);
      } catch (e) {
        console.log(e);
      }
    } else {
      try {
        await safeMutation(
          update,
          {
            projectId: projectId,
            permissionGroupId: group.id,
            body: {
              name: data.name,
              comment: data.description,
              assignedGroups: data.userGroups ?? [],
              assignedCommitments: data.commitments ?? [],
              assignedContracts: data.contracts ?? [],
              assignedInvoices: data.invoices ?? [],
            },
          },
          isUpdating,
        );

        onClose(true);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const contractOptions: BaseSelectOption[] = useMemo(() => {
    return fetchedContracts
      ? fetchedContracts
          .filter((c) => !c.commitmentId)
          .map((c) => {
            return { label: `${c.code} - ${c.name}`, value: c.id };
          })
      : [];
  }, [fetchedContracts]);

  const commitmentOptions: BaseSelectOption[] = useMemo(() => {
    return fetchedCommitments
      ? fetchedCommitments.map((c) => {
          return { label: `${c.code} - ${c.name}`, value: c.id };
        })
      : [];
  }, [fetchedCommitments]);

  const invoiceOptions: BaseSelectOption[] = useMemo(() => {
    return fetchedInvoices?.invoices
      ? fetchedInvoices.invoices.map((i) => {
          return { label: i.code, value: i.id };
        })
      : [];
  }, [fetchedInvoices?.invoices]);

  const defaultFormValues = {
    name: group?.name ?? '',
    description: group?.comment ?? '',
    userGroups: group?.assignedGroups?.map((g) => g.id) ?? [],
    commitments: group?.assignedCommitments?.map((c) => c.id) ?? [],
    contracts: group?.assignedContracts?.map((c) => c.id) ?? [],
    invoices: group?.assignedInvoices?.map((c) => c.id) ?? [],
  };

  return (
    <>
      <Form<ProjectUserGroupEditValidationValues>
        validationSchema={ProjectUserGroupEditValidationSchema}
        defaultValues={defaultFormValues}
        onSubmit={handleSubmit}
        className="w-full flex flex-col justify-between h-full"
      >
        <SlideOver.Header
          title={group ? group.name : t('projectSetting.userManagement.projectGroup')}
          subTitle={
            group
              ? t('projectSetting.userManagement.editProjectGroup.subtitle')
              : t('projectSetting.userManagement.createProjectGroup.subtitle')
          }
          backgroundClassName="bg-slate-900"
          onClose={onClose}
        />
        <SlideOver.Content className="p-8">
          {isFetching ||
          isCreating ||
          isUpdating ||
          isFetchingContracts ||
          isFetchingCommitments ||
          isFetchingInvoices ? (
            <LoadingIndicator text={t('common.loading')} mode="overlay" />
          ) : (
            <div className="flex flex-col gap-0.5">
              <FormField name="name">
                {(control) => <TextInput icon={<TagWindowIcon />} label={t('common.name')} {...control} />}
              </FormField>
              <FormField name="description">
                {(control) => (
                  <TextInput
                    icon={<TagWindowIcon />}
                    label={t('common.description')}
                    inputType="textarea"
                    {...control}
                  />
                )}
              </FormField>
              <FormField name="userGroups">
                {(control) => (
                  <MultiSelect
                    icon={<UserGroupIcon />}
                    label={t('projectSetting.userManagement.editProjectGroup.teams')}
                    options={userGroupOptions}
                    nullable
                    {...control}
                  />
                )}
              </FormField>
              <SlideOverTitle title={t('projectSettings.userManagement.editProjectGroup.permissions')} />
              <FormField name="commitments">
                {(control) => (
                  <ComboSelect
                    multiple
                    icon={<ContractIcon />}
                    label={t('projectSetting.userManagement.editProjectGroup.commitments')}
                    options={commitmentOptions}
                    nullable
                    {...control}
                    onChange={undefined}
                    onChangeMultiple={(selectedOptions) => {
                      const ids = selectedOptions?.length ? selectedOptions.map((option) => option.value) : [];
                      control.onChange(ids);
                    }}
                    pageOptions
                    disabled={!commitmentOptions.length}
                  />
                )}
              </FormField>
              <FormField name="contracts">
                {(control) => (
                  <ComboSelect
                    icon={<ContractIcon />}
                    label={t('projectSetting.userManagement.editProjectGroup.contracts')}
                    options={contractOptions}
                    nullable
                    multiple
                    {...control}
                    onChange={undefined}
                    onChangeMultiple={(selectedOptions) => {
                      const ids = selectedOptions?.length ? selectedOptions.map((option) => option.value) : [];
                      control.onChange(ids);
                    }}
                    pageOptions
                    disabled={!contractOptions.length}
                  />
                )}
              </FormField>
              <FormField name="invoices">
                {(control) => (
                  <ComboSelect
                    icon={<DocumentDottedIcon />}
                    label={t('projectSetting.userManagement.editProjectGroup.invoices')}
                    options={invoiceOptions}
                    nullable
                    multiple
                    {...control}
                    onChange={undefined}
                    onChangeMultiple={(selectedOptions) => {
                      const ids = selectedOptions?.length ? selectedOptions.map((option) => option.value) : [];
                      control.onChange(ids);
                    }}
                    pageOptions
                    disabled={!invoiceOptions.length}
                  />
                )}
              </FormField>
            </div>
          )}
        </SlideOver.Content>
        <SlideOver.Controls>
          <div className={classNames('flex w-full', group ? 'justify-between' : 'justify-end')}>
            {group && (
              <Button variant="warning" onClick={() => setIsOpenDeleteModal(true)}>
                {t('common.delete')}
              </Button>
            )}
            <div className="flex">
              <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
                {t('common.cancel')}
              </Button>
              <Button variant="primary" formSubmit>
                {t('common.save')}
              </Button>
            </div>
          </div>
        </SlideOver.Controls>
      </Form>
      {group && (
        <Modal isOpen={isOpenDeleteModal} onClose={() => setIsOpenDeleteModal(false)}>
          <ProjectGroupDeleteModal
            groupId={group.id}
            groupName={group.name}
            onClose={() => setIsOpenDeleteModal(false)}
            projectId={projectId}
          />
        </Modal>
      )}
    </>
  );
};
