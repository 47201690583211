import { ConfirmModal, HintBox, LoadingIndicator, ModalOnCloseProps } from '@client/shared/toolkit';
import {
  CostCatalogElementReadModel,
  EarningsCatalogElementReadModel,
  FinancingCatalogElementReadModel,
  RiskCatalogElementReadModel,
  TenantCatalogType,
  useApiDeleteCatalogElementMappingMutation,
} from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { safeMutation } from '@client/shared/utilities';

interface CatalogElementMappingDeleteModalProps extends ModalOnCloseProps {
  mappingId: string;
  mappingElementId: string;
  catalogElement:
    | CostCatalogElementReadModel
    | FinancingCatalogElementReadModel
    | RiskCatalogElementReadModel
    | EarningsCatalogElementReadModel;
  type: TenantCatalogType;
}

export const CatalogElementMappingDeleteModal = (props: CatalogElementMappingDeleteModalProps) => {
  const { t } = useTranslation();
  const { mappingId, mappingElementId, catalogElement, type, onClose } = props;

  const [call, { isLoading }] = useApiDeleteCatalogElementMappingMutation();

  const handleOnClose = () => {
    onClose(false);
  };

  const handleDelete = async () => {
    try {
      await safeMutation(
        call,
        {
          body: {
            mappingId: mappingId,
            mappingElementId: mappingElementId,
            type: type,
          },
        },
        isLoading,
      );
      onClose(true);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <ConfirmModal
      title={t('app.deleteTenantCatalogElementMapping', { name: catalogElement.description })}
      onConfirm={handleDelete}
      onClose={handleOnClose}
      confirmVariant="danger"
      confirmText={t('common.delete')}
      isLoading={isLoading}
    >
      {isLoading && <LoadingIndicator text={t('common.loading')} mode="overlay" />}
      <HintBox hintType="warning">{t('app.deleteCatalogElementMappingHint')}</HintBox>
    </ConfirmModal>
  );
};
