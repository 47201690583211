import { Menu, MenuButton, MenuItem, MenuItems, Portal } from '@headlessui/react';
import { BaseSelectOption, HeaderButton, SelectedCustomIcon, UnselectedCustomIcon } from '@client/shared/toolkit';
import { usePopper } from 'react-popper';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import cn from 'classnames';

interface BenchmarkingUserTypeFilterProps {
  assetClasses: BaseSelectOption[];
  handleSelect: (id?: string) => void;
  selected?: string;
  disabled?: boolean;
}

export const BenchmarkingUserTypeFilter = (props: BenchmarkingUserTypeFilterProps) => {
  const { assetClasses, handleSelect, selected, disabled = false } = props;
  const { t } = useTranslation();
  const [targetElement, setTargetElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['bottom-end', 'top-start', 'top-end'],
          rootBoundary: 'viewport',
        },
      },
      { name: 'offset', options: { offset: [0, 8] } },
    ],
  });

  const selectedAssetClassLabel = useMemo(() => {
    return assetClasses.find((assetClass) => assetClass.value === selected)?.label;
  }, [assetClasses, selected]);

  return (
    <div className="flex items-center">
      <Menu as="div" ref={setTargetElement} className="z-20 w-full relative">
        <MenuButton className={cn('block', { 'cursor-not-allowed': disabled })} disabled={disabled}>
          <HeaderButton title={t('reporting.benchmarking.filter.usageType.label')} showToggle className="w-[210px]">
            <div className="text-left font-semibold text-sm leading-tight whitespace-nowrap text-gray-400 my-auto truncate">
              {selectedAssetClassLabel ?? t('reporting.benchmarking.filter.usageType.noSelection')}
            </div>
          </HeaderButton>
        </MenuButton>
        <Portal>
          <MenuItems
            className="z-40 absolute right-0 w-96 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-y-auto max-h-96"
            as="div"
            ref={setPopperElement}
            style={{ ...styles.popper }}
            {...attributes.popper}
          >
            <div className="divide-y divide-gray-100">
              <MenuItem>
                {({ focus }) => (
                  <div
                    onClick={() => handleSelect(undefined)}
                    className={cn('text-gray-900 group flex rounded-none w-full py-3 px-4 text-sm cursor-pointer', {
                      'bg-gray-100': focus,
                    })}
                  >
                    <div className="grow text-left w-7/12 pr-3 my-auto">
                      <div className="text-[15px] font-bold truncate">
                        {t('reporting.benchmarking.filter.usageType.noSelection')}
                      </div>
                    </div>
                    <div className="w-12 text-right my-auto">
                      {!selected ? (
                        <SelectedCustomIcon className="w-5 h-5 ml-auto" />
                      ) : (
                        <UnselectedCustomIcon className="w-5 h-5 ml-auto" />
                      )}
                    </div>
                  </div>
                )}
              </MenuItem>
              {assetClasses.map((assetClass) => (
                <MenuItem key={`usage-type-filter-asset-class-${assetClass.value}`}>
                  {({ focus }) => (
                    <div
                      onClick={() => handleSelect(assetClass.value)}
                      className={cn('text-gray-900 group flex rounded-none w-full py-3 px-4 text-sm cursor-pointer', {
                        'bg-gray-100': focus,
                      })}
                    >
                      <div className="grow text-left w-7/12 pr-3 my-auto">
                        <div className="text-[15px] font-bold truncate">{assetClass.label}</div>
                      </div>
                      <div className="w-12 text-right my-auto">
                        {selected === assetClass.value ? (
                          <SelectedCustomIcon className="w-5 h-5 ml-auto" />
                        ) : (
                          <UnselectedCustomIcon className="w-5 h-5 ml-auto" />
                        )}
                      </div>
                    </div>
                  )}
                </MenuItem>
              ))}
            </div>
          </MenuItems>
        </Portal>
      </Menu>
    </div>
  );
};
