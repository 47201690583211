import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { useApiGetCalculationModelCostsQuery } from '@client/shared/api';
import { ComboSelect, ComboSelectOption, LoadingIndicator } from '@client/shared/toolkit';
import cn from 'classnames';
import { ReactNode, useMemo } from 'react';

interface CostCatalogElementSelectProps {
  label: string;
  selectedCostCatalogElements: string[];
  setSelectedCostCatalogElements: (items: string[]) => void;
  multiple?: boolean;
  className?: string;
  icon?: ReactNode;
  disabled?: boolean;
}

/**
 * Top level select of catalog element(s).
 */
export const CostCatalogElementSelect = (props: CostCatalogElementSelectProps) => {
  const {
    label,
    selectedCostCatalogElements,
    setSelectedCostCatalogElements,
    multiple = true,
    className,
    icon,
    disabled = false,
  } = props;

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const { data: costsData, isFetching: isLoadingCostData } = useApiGetCalculationModelCostsQuery(
    {
      projectId: loadedProjectId ?? 'unset',
      calculationModelId: loadedVariantId ?? '',
    },
    { skip: !loadedProjectId || !loadedVariantId },
  );

  const options: ComboSelectOption[] = useMemo(() => {
    if (costsData?.payload.catalogElements) {
      return costsData.payload.catalogElements
        .filter((elem) => !elem.hasParent)
        .map((elem) => {
          return {
            label: `${elem.code} - ${elem.description}`,
            value: elem.id,
            shortLabel: elem.code,
          };
        });
    }
    return [];
  }, [costsData?.payload.catalogElements]);

  return (
    <div className={cn('relative', className)}>
      {isLoadingCostData ? (
        <LoadingIndicator mode="overlay" />
      ) : (
        <ComboSelect
          label={label}
          icon={icon}
          multiple={multiple}
          value={selectedCostCatalogElements}
          options={options}
          onChangeMultiple={(options) => {
            const ids = options?.length ? options.map((option) => option.value) : [];
            setSelectedCostCatalogElements(ids);
          }}
          disabled={disabled}
        />
      )}
    </div>
  );
};
