import { AddButton, AddButtonProps } from '.';
import { ContextMenu, ContextMenuItem } from '../ContextMenu';
interface SettingsAddButtonProps extends AddButtonProps {
  menuItems?: ContextMenuItem[];
}

export const SettingsAddButton = (props: SettingsAddButtonProps) => {
  return (
    <div className="flex w-full justify-end items-center mt-2">
      <div className="w-11 h-11">
        {props.menuItems && props.menuItems.length > 0 ? (
          <ContextMenu
            items={props.menuItems}
            button={
              <AddButton variant="primary" {...props} />
            }
          />
        ) : (
          <AddButton variant="primary" {...props} />
        )}
      </div>
    </div>
  );
};
