import { CashFlowIntervalEnum, TenantCatalogType } from '@client/shared/api';
import * as yup from 'yup';
import { InferType } from 'yup';

export const CashflowFilterValidationSchema = yup.object({
  startPoint: yup.date().required('validation.required').nullable().optional(),
  interval: yup.mixed<CashFlowIntervalEnum>().oneOf(['Month', 'Quarter', 'Year']).required('validation.required'),
  groups: yup.array().of(yup.string<TenantCatalogType>()).min(1).required('validation.required'),
});

export type CashFlowFilterValidationValues = InferType<typeof CashflowFilterValidationSchema>;
