import {
  TaxonomyReadModel,
  useApiPostUploadTaxonomyFileMutation,
  useApiDeleteTaxonomyItemFileMutation,
  useApiPostUploadPlotFileMutation,
  useApiDeletePlotItemFileMutation,
  TaxonomyItemFileTypeEnum,
  PlotItemFileTypeEnum,
  PlotReadModel,
  apiEndpointUrls,
} from '@client/shared/api';
import { Button, FileInput, LoadingIndicator, SlideOver, SlideOverOnCloseProps } from '@client/shared/toolkit';
import classNames from 'classnames';
import { useState } from 'react';
import { useLoadedProjectId } from '@client/project/store';
import { useTranslation } from 'react-i18next';
import { safeMutation } from '@client/shared/utilities';
import { DocumentIcon, ArrowDownCircleIcon } from '@heroicons/react/24/solid';

const ALLOWED_FILE_TYPES_IMAGE_ONLY = ['image/bmp', 'image/jpg', 'image/jpeg', 'image/png']
const ALLOWED_FILE_TYPES_ALL = ['image/bmp', 'image/jpg', 'image/jpeg', 'image/png', 'application/pdf']

interface FileManagmentSlideOverProps extends SlideOverOnCloseProps {
  item?: TaxonomyReadModel | undefined;
  plot?: PlotReadModel | undefined;
  isReadOnly: boolean;
  canDelete: boolean;
  field: string;
}

export const FileManagementSlideOver = ({
  item,
  plot,
  canDelete,
  onClose,
  field,
}: FileManagmentSlideOverProps) => {
  const { t } = useTranslation();
  const [isFileUploadOpen, setIsImageUploadOpen] = useState(true);
  const [file, setFile] = useState<File | null>(null);
  const [fileError, setFileError] = useState(false);
  const [postTaxonomyImage, { isLoading: isPostingTaxonomyImage }] = useApiPostUploadTaxonomyFileMutation();
  const [postPlotImage, { isLoading: isPostingPlotImage }] = useApiPostUploadPlotFileMutation();
  const [postTaxonomyDelete, { isLoading: isDeletingTaxonomyImage }] = useApiDeleteTaxonomyItemFileMutation();
  const [postPlotDelete, { isLoading: isDeletingPlotImage }] = useApiDeletePlotItemFileMutation();
  const loadedProjectId = useLoadedProjectId();

  const fileTypeDict: {
    [key: string]: { title: string; uploadButtonTitle: string; allowedFileTypes: string[] };
  } = {
    Image: {
      title: 'projectTaxonomy.dashboardImages',
      uploadButtonTitle: 'projectTaxonomy.uploadNewImage',
      allowedFileTypes: ALLOWED_FILE_TYPES_IMAGE_ONLY
    },
    Sketch: {
      title: 'projectTaxonomy.dashboardSketches',
      uploadButtonTitle: 'projectTaxonomy.uploadNewSketch',
      allowedFileTypes: ALLOWED_FILE_TYPES_ALL
    },
    Document: {
      title: 'projectTaxonomy.dashboardDocuments',
      uploadButtonTitle: 'projectTaxonomy.uploadNewDocument',
      allowedFileTypes: ALLOWED_FILE_TYPES_ALL
    },
    FloorPlan: {
      title: 'projectTaxonomy.dashboardFloorPlans',
      uploadButtonTitle: 'projectTaxonomy.uploadNewFloorPlan',
      allowedFileTypes: ALLOWED_FILE_TYPES_ALL
    },
    SitePlan: {
      title: 'projectTaxonomy.dashboardSitePlans',
      uploadButtonTitle: 'projectTaxonomy.uploadNewSitePlan',
      allowedFileTypes: ALLOWED_FILE_TYPES_ALL
    },
    Contract: {
      title: 'projectTaxonomy.dashboardContract',
      uploadButtonTitle: 'projectTaxonomy.uploadNewContract',
      allowedFileTypes: ALLOWED_FILE_TYPES_ALL
    },
    Defect: {
      title: 'projectTaxonomy.dashboardDefects',
      uploadButtonTitle: 'projectTaxonomy.uploadNewDefect',
      allowedFileTypes: ALLOWED_FILE_TYPES_ALL
    },
  };

  const handleUpload = async () => {
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('FileType', field);

    if (item && loadedProjectId) {
      try {
        await safeMutation(
          postTaxonomyImage,
          {
            projectId: loadedProjectId,
            calculationModelId: item?.calculationModelId ?? '',
            taxonomyItemId: item?.itemId ?? '',
            body: formData as unknown as { file: Blob; FileType: TaxonomyItemFileTypeEnum },
          },
          isPostingTaxonomyImage
        );

        setIsImageUploadOpen(false);

        setTimeout(() => {
          setIsImageUploadOpen(true);
        }, 100);
        setFile(null);
      } catch (e) {
        console.log(e);
      }
    }

    if (plot && loadedProjectId) {
      try {
        await safeMutation(
          postPlotImage,
          {
            projectId: loadedProjectId,
            calculationModelId: plot?.calculationModelId ?? '',
            plotItemId: plot?.plotId ?? '',
            body: formData as unknown as { file: Blob; FileType: PlotItemFileTypeEnum },
          },
          isPostingPlotImage
        );

        onClose(true);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleDelete = async (id: string) => {
    if (item && loadedProjectId) {
      try {
        await safeMutation(
          postTaxonomyDelete,
          {
            projectId: loadedProjectId,
            calculationModelId: item?.calculationModelId ?? '',
            taxonomyItemId: item?.itemId ?? '',
            fileId: id,
          },
          isDeletingTaxonomyImage
        );
      } catch (e) {
        console.log(e);
      }
    }

    if (plot && loadedProjectId) {
      try {
        await safeMutation(
          postPlotDelete,
          {
            projectId: loadedProjectId,
            calculationModelId: plot?.calculationModelId ?? '',
            plotItemId: plot?.plotId ?? '',
            fileId: id,
          },
          isDeletingPlotImage
        );
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleFileChange = (fileList: FileList | null) => {
    if (fileList) {
      setFile(fileList[0]);
    } else {
      setFile(null);
    }
  };

  const isImage = (fileName: string) => {
    const extension = fileName.split('.').pop()?.toLowerCase();
    return ['jpg', 'jpeg', 'png', 'bmp'].includes(extension ?? '');
  };

  const files = item ? item.files : plot?.files;

  return (
    <>
      {(isPostingTaxonomyImage || isPostingPlotImage) && (
        <LoadingIndicator text={t('common.uploading')} mode="overlay-window" />
      )}
      {(isDeletingTaxonomyImage || isDeletingPlotImage) && (
        <LoadingIndicator text={t('projectTaxonomy.removingTaxonomyImageLoadingIndicator')} mode="overlay-window" />
      )}
      <SlideOver.Header
        onClose={() => onClose(false)}
        title={item?.itemName ?? plot?.plotName ?? t('projectTaxonomy.taxonomyItem')}
        subTitle={t(item ? `projectTaxonomy.taxonomyType.${item?.itemType}` : `projectTaxonomy.plotType.${plot?.type}`)}
        backgroundClassName="bg-sky-900"
      />

      <SlideOver.Content>
        <div className="mt-8 flex flex-col items-center justify-center m-8">
          <div className="text-3xl font-bold text-gray-500">{t(fileTypeDict[field].title)}</div>
          {isFileUploadOpen && (
            <>
              <div className="mt-5 w-full h-full">
                <FileInput
                  className="bg-white rounded-sm"
                  acceptedFileTypes={fileTypeDict[field].allowedFileTypes}
                  multiple={false}
                  onChange={(files) => handleFileChange(files)}
                  setError={setFileError}
                />
              </div>
              {/* <div className="mt-2 text-sm text-gray-900">
                Allowed: <span className="font-medium">{fileTypeDict[field].allowedFileTypes}</span>
              </div> */}
            </>
          )}

          <Button
            variant="primary"
            className={classNames('mt-5', { hidden: !isFileUploadOpen })}
            onClick={handleUpload}
            disabled={!file || fileError}
          >
            {t('projectTaxonomy.upload')}
          </Button>
        </div>

        <div className="m-8 mt-0 bg-white rounded-sm">
          <div className="divide-gray-100 divide-y">
            {files
              ?.filter((file) => file.fileType === field)
              .map((file) => (
                <div key={file.id} className="flex items-center justify-between p-4">
                  <div className="flex items-center mr-2 min-w-0 ">
                    {field !== 'Document' && isImage(file.name) ? (
                      <div className="w-16 h-16 flex items-center">
                        <img
                          src={
                            item
                            ? apiEndpointUrls.apiGetTaxonomyItemFile.replace(':projectId', loadedProjectId as string).replace(':calculationModelId', item?.calculationModelId as string).replace(':taxonomyItemId', item?.itemId as string).replace(':fileId', file.id)
                            : apiEndpointUrls.apiGetPlotItemFile.replace(':projectId', loadedProjectId as string).replace(':calculationModelId', plot?.calculationModelId as string).replace(':plotItemId', plot?.plotId as string).replace(':fileId', file.id)
                          }
                          alt={file.name}
                          className="w-full h-full object-cover object-center"
                        />
                      </div>
                    ) : (
                      <div className="w-min h-full">
                        <DocumentIcon className="w-6 h-6 fill-slate-400" />
                      </div>
                    )}

                    <div className="w-min ml-4 text-sm font-medium text-gray-900 truncate">{file.name}</div>
                  </div>
                  <div className="flex items-center">
                    <a
                      href={
                        item
                        ? apiEndpointUrls.apiGetTaxonomyItemFile.replace(':projectId', loadedProjectId as string).replace(':calculationModelId', item?.calculationModelId as string).replace(':taxonomyItemId', item?.itemId as string).replace(':fileId', file.id)
                        : apiEndpointUrls.apiGetPlotItemFile.replace(':projectId', loadedProjectId as string).replace(':calculationModelId', plot?.calculationModelId as string).replace(':plotItemId', plot?.plotId as string).replace(':fileId', file.id)
                      }
                      target="_blank"
                      rel="noreferrer"
                      download
                    >
                      <ArrowDownCircleIcon className="w-10 h-10 mr-2 fill-slate-400 hover:fill-slate-500" />
                    </a>
                    {canDelete && (
                      <Button variant="secondary" onClick={() => handleDelete(file.id)}>
                        {t('common.delete')}
                      </Button>
                    )}
                  </div>
                </div>
              ))}
            {item?.files?.filter((file) => file.fileType === field).length === 0 && (
              <div className="flex items-center justify-center p-4">
                <div className="text-sm font-medium text-gray-900">{t('projectTaxonomy.dashboardEmpty')}</div>
              </div>
            )}
          </div>
        </div>
      </SlideOver.Content>
      <SlideOver.Controls>
        <div className={classNames('flex w-full justify-end')}>
          <div className="flex">
            <Button variant="primary" className="mr-2" onClick={() => onClose(false)}>
              {t('common.close')}
            </Button>
          </div>
        </div>
      </SlideOver.Controls>
    </>
  );
};
