import { CalculationModelEarningsElement, CalculationModelEarningsGroupElement } from '@client/shared/api';

export const findEarningsElement = (
  elementId: string,
  flattenedElements: CalculationModelEarningsGroupElement[],
): CalculationModelEarningsGroupElement | CalculationModelEarningsElement | undefined => {
  const foundElement = flattenedElements.find((x) => x.earningsElementId === elementId || x.groupId === elementId);
  if (foundElement) return foundElement;
  else {
    let foundElement: CalculationModelEarningsGroupElement | CalculationModelEarningsElement | undefined = undefined;
    flattenedElements.forEach((element) => {
      if (!foundElement && element.children.length) {
        element.children.forEach((earningsElement) => {
          if (earningsElement.earningsElement && earningsElement.earningsElement.id === elementId) {
            foundElement = earningsElement.earningsElement;
          }
        });
      }
    });
    return foundElement;
  }
};
