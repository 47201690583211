import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { EditNodeIcon } from '@client/shared/toolkit';
import cn from 'classnames';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { PropsWithChildren, Ref, useState } from 'react';
import { usePopper } from 'react-popper';
import { Placement } from '@popperjs/core';

export interface ProjectCalculationModelElementsSelectProps extends PropsWithChildren {
  label: string;
  selectedLabels: string;
  className?: string;
  popoverButtonRef: Ref<HTMLDivElement>;
  fallbackPlacements?: Placement[] | undefined;
  height?: string;
}

export const ProjectCalculationModelElementsSelect = (props: ProjectCalculationModelElementsSelectProps) => {
  const {
    selectedLabels,
    label,
    children,
    className,
    popoverButtonRef,
    fallbackPlacements = ['right-end', 'top'],
    height
  } = props;

  const [targetElement, setTargetElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: fallbackPlacements,
        },
      },
    ],
  });

  if (!popoverButtonRef) return null;

  return (
    <div className={className}>
      <Popover>
        <div ref={setTargetElement}>
          <PopoverButton className="w-full flex justify-between bg-white pr-2 h-14">
            <div className="h-14 w-3/4 pl-3 py-1 flex items-center" ref={popoverButtonRef}>
              <div className="flex items-center h-full">
                <div className="h-5 w-5 flex items-center justify-center">
                  <EditNodeIcon />
                </div>
              </div>
              <div className="relative ml-2 w-full">
                <div className="text-left pt-4 w-full text-lg appearance-none focus:outline-none bg-transparent truncate font-medium text-gray-80">
                  {selectedLabels}
                </div>
                <div className="absolute top-0 left-0 right-0 w-48 text-xs duration-200 origin-0 text-gray-600 select-none transform truncate text-left">
                  {label}
                </div>
              </div>
            </div>
            <div className={cn('w-1/4 flex items-center justify-end h-full pl-12')}>
              <ChevronDownIcon className="w-5 h-5 text-gray-800" />
            </div>
          </PopoverButton>
        </div>
        <PopoverPanel
          portal
          ref={setPopperElement}
          style={{ ...styles.popper }}
          {...attributes.popper}
          className={cn('z-20 flex w-[600px] truncate bg-gray-100 p-4 shadow-lg rounded', height ?? 'h-[500px]')}
        >
          {children}
        </PopoverPanel>
      </Popover>
    </div>
  );
};
