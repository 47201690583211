import { SpvReadModel, useApiDeleteProjectSpvMutation } from '@client/shared/api';
import {
  AddButton,
  ContextMenu,
  SingleChoiceIcon,
  NumberInput,
  PercentageIcon,
  SlideOverTitle,
  BooleanInput,
  FormHelperText,
} from '@client/shared/toolkit';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { safeMutation } from '@client/shared/utilities';
import { SpvModal } from '@client/project/shared';

interface EditProjectSpvProps {
  spvs: SpvReadModel[];
  setSpvs: (spvs: SpvReadModel[]) => void;
  readOnly: boolean;
  projectId: string | undefined;
  submitted: boolean;
  spvPayloadValid: boolean;
  setSpvPayloadValid: (valid: boolean) => void;
}

export const EditProjectSpv = ({
  spvs,
  setSpvs,
  readOnly,
  projectId,
  submitted,
  spvPayloadValid,
  setSpvPayloadValid,
}: EditProjectSpvProps) => {
  const { t } = useTranslation();

  const [isOpenAddSpvModal, setIsOpenAddSpvModal] = useState(false);

  const [deleteSpv, { isLoading: isDeletingSpv }] = useApiDeleteProjectSpvMutation();

  const handleDelete = async (id: string) => {
    if (projectId) {
      try {
        await safeMutation(
          deleteSpv,
          {
            projectId: projectId,
            spvId: id,
          },
          isDeletingSpv,
        );
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    const shares = spvs.reduce((acc, spv) => acc + spv.share, 0) ?? 0;
    setSpvPayloadValid(shares <= 100);
  }, [spvs, setSpvPayloadValid]);

  return (
    <>
      <SlideOverTitle title={t('projectSetting.spvs')} />
      <div className="divide-y">
        {spvs.length > 0 ? (
          spvs?.map((spv, idx) => (
            <div className="flex justify-between items-center bg-white divide-x" key={`spv-branch-${idx}`}>
              <div className="w-full flex justify-between items-center divide-x">
                <div className="w-5/12 flex h-14 px-3 bg-white gap-3">
                  <div className="flex items-center h-full">
                    <SingleChoiceIcon className="h-5 w-5" />
                  </div>
                  <span className="flex items-center text-gray-800 text-lg truncate">{spv.name}</span>
                </div>
                <NumberInput
                  className="flex-grow"
                  label={t('projectSetting.spvShare')}
                  icon={<PercentageIcon />}
                  min={0}
                  max={100}
                  value={spv.share}
                  onChange={(value) => {
                    const updatedSpvPayload = [...spvs];
                    updatedSpvPayload[idx] = {
                      ...updatedSpvPayload[idx],
                      share: value ?? 0,
                    };
                    setSpvs(updatedSpvPayload);
                  }}
                  disabled={readOnly}
                  isValidationValid={spvPayloadValid && spv.share !== null}
                  showValidation={submitted && (!spvPayloadValid || spv.share === null)}
                />

                <BooleanInput
                  className="w-3/12"
                  label={t('projectSetting.spvDefault')}
                  value={spv.isDefault}
                  onChange={(value) => {
                    const updatedSpvPayload: SpvReadModel[] = [];
                    spvs.forEach((updatedSpv) =>
                      updatedSpvPayload.push({
                        ...updatedSpv,
                        isDefault: updatedSpv.id === spv.id ? value : false,
                      }),
                    );
                    setSpvs(updatedSpvPayload);
                  }}
                  disabled={readOnly}
                  variant="switch"
                />
              </div>
              <ContextMenu
                items={[
                  {
                    label: t('common.delete'),
                    subtitle: t('projectSetting.spvDelete'),
                    onClick: () => {
                      handleDelete(spv.id);
                    },
                    isDisabled: readOnly,
                  },
                ]}
                className=""
              />
            </div>
          ))
        ) : (
          <span className="text-gray-600 mx-3">{t('projectSetting.noSpvBranchesConfigured')}</span>
        )}
      </div>
      {submitted && !spvPayloadValid && (
        <FormHelperText
          text={t('projectSetting.spvShareHundredValidation')}
          error={!spvPayloadValid}
          className={'w-full bg-white'}
          truncate={false}
        />
      )}
      {!readOnly && (
        <div className="-mt-4 mb-4 w-full flex justify-end relative z-10">
          <AddButton
            onClick={() => {
              setIsOpenAddSpvModal(true);
            }}
            className="mr-4"
          />
        </div>
      )}
      <SpvModal
        isOpen={isOpenAddSpvModal}
        onClose={() => {
          setIsOpenAddSpvModal(false);
        }}
        projectId={projectId}
        projectSpvs={spvs}
      />
    </>
  );
};
