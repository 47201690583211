import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { NavLink } from 'react-router-dom';
import { PreviewFeature } from './PreviewFeature';
import { PreviewTestimonial } from './PreviewTestimonial';
import { PREVIEW_COSTS_IMAGE, PREVIEW_TAXONOMY_IMAGE, PREVIEW_TIMELINE_IMAGE, PREVIEW_UI_IMAGE, PREVIEW_VARIANTS_IMAGE } from '@client/shared/toolkit';

interface PreviewRouteProps {
  hideBackButton?: boolean;
}

export const PreviewRoute = ({ hideBackButton = false }: PreviewRouteProps) => (
  <div className="overflow-y-auto w-full flex-grow flex flex-col">
    <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 min-h-full">
      {!hideBackButton && (
        <div className="text-center">
          <NavLink to="/" className="flex items-center text-gray-600 hover:text-blue-600 ">
            <ArrowLeftIcon className="h-12 w-12" />
            <span className="ml-4 text-xl ">go back</span>
          </NavLink>
        </div>
      )}

      <div className="text-center">
        <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
          <span className="block xl:inline">Probis - Calculate</span>{' '}
          <span className=" bg-yellow-300 rounded-3xl p-2"> Preview</span>
        </h1>
      </div>

      <PreviewTestimonial />

      <PreviewFeature flip={false} image={PREVIEW_UI_IMAGE} title="Visual Preview">
        This version gives an outlook on the visual UI/UX the team is heading for. We support latest Browsers (Chrome,
        Firefox, Safari and newest Microsoft Edge) and Apple IPad.
      </PreviewFeature>

      <PreviewFeature flip={false} image={PREVIEW_COSTS_IMAGE} title="Cost calculation">
        The main focus is to show a possible way to calculate your costs in a project. We support budget-down, cost-up
        and hybrid approaches.
      </PreviewFeature>

      <PreviewFeature flip={false} image={PREVIEW_VARIANTS_IMAGE} title="Variant handling">
        You can easily switch between different variants of your calculation; creating copies and snapshots to explore
        different paths of your calculation.
      </PreviewFeature>

      <PreviewFeature flip={false} image={PREVIEW_TAXONOMY_IMAGE} title="Building/lot taxonomy">
        Your lot and building taxonomy plays a huge role in modelling your costs, revenues and income streams. Although
        this version is not complete in that direction, you can still get a first impression on where we want to go.
      </PreviewFeature>
      <PreviewFeature flip={false} image={PREVIEW_TIMELINE_IMAGE} title="Timelines">
        In order to model your financing streams correctly, we need to understand when costs, risks, revenues or
        financing do occur. This preview version contains a first visual representation to treat timings as a first
        class citizen.
      </PreviewFeature>
    </main>
  </div>
);
