import {
  SlideOver,
  Button,
  ResumeIcon,
  TextInput,
  Modal,
  SlideOverOnCloseProps,
  HintBox,
  BaseSelectOption,
  ComboSelect,
  TagWindowIcon,
  EditNodeIcon,
  SlideOverTitle,
  LoadingIndicator,
  FAKE_CALCULATE_ELEMENT_COMMENT_IMAGE,
  FAKE_CALCULATE_ELEMENT_HISTORY_DE_IMAGE,
  FAKE_CALCULATE_ELEMENT_HISTORY_EN_IMAGE,
} from '@client/shared/toolkit';
import {
  ElementTimelineReadModel,
  ElementUserDefinedFieldDefinitionReadModel,
  FormulaPayload,
  RiskElementReadModel,
  useApiGetProjectWritableRiskCatalogQuery,
  useApiGetRiskElementQuery,
  useApiPostCreateRiskElementMutation,
  useApiPostGenerateNextProjectObjectCodeMutation,
  useApiPostUpdateRiskElementMutation,
  UserDefinedFieldPayload,
} from '@client/shared/api';
import { useEffect, useState, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { RiskElementDeleteModal } from './RiskElementDeleteModal';
import { useTranslation } from 'react-i18next';
import { getLanguageAndLocale, safeMutation } from '@client/shared/utilities';
import { RiskFormulaInput } from './RiskFormulaInput';
import { DecoratedElement, RiskElement, useElementErrors } from '../../hooks';
import { useLoadedProjectId } from '@client/project/store';
import { useFeatureFlags } from '@client/shared/store';
import { useValidateCanWriteCatalogElement, useValidateProjectPermission } from '@client/shared/permissions';
import ExternalForecast from '../ExternalForecast';
import { CalculateElementDocuments } from '../Documents';
import { SlideOverTotalSection } from '../SlideOverTotalSection';
import {
  FormattedCurrency,
  timelineReadModelToPayload,
  TimeLineDistribution,
  EditUserDefinedFields,
} from '@client/project/shared';

interface RiskElementSlideOverProps extends SlideOverOnCloseProps {
  variantId?: string;
  decoratedRiskElement?: DecoratedElement<RiskElement>;
  disabled?: boolean;
  riskCatalogId?: string;
  preselectedGroupId?: string;
}

type NestedModal = 'None' | 'delete';

type SubArea = 'Values' | 'Documents' | 'History' | 'Comments';

export const RiskElementSlideOver = ({
  disabled,
  decoratedRiskElement,
  onClose,
  variantId,
  riskCatalogId,
  preselectedGroupId,
}: RiskElementSlideOverProps) => {
  const { t } = useTranslation();
  const submitRef = useRef<HTMLButtonElement>(null);

  const { fakeUi: showFakeUi } = useFeatureFlags();

  const currentLanguage = getLanguageAndLocale().language;

  const calculationState = decoratedRiskElement?.element.group?.calculationState;
  const timelineErrors = decoratedRiskElement?.element.errors;

  const [subarea, setSubarea] = useState<SubArea>('Values');

  const projectId = useLoadedProjectId();

  const catalogElementId = decoratedRiskElement?.element.group
    ? decoratedRiskElement?.element.group?.groupId
    : decoratedRiskElement?.element.riskElement?.groupId;
  const canDeleteRisks = useValidateProjectPermission(['RISKS_DELETE'], projectId ?? '');
  const readOnly = !useValidateCanWriteCatalogElement(projectId ?? '', riskCatalogId, catalogElementId) || disabled;

  const translatedErrors = useElementErrors(calculationState, timelineErrors ?? [], []);

  const [getNextCode] = useApiPostGenerateNextProjectObjectCodeMutation();
  const [putElement, { isLoading: isUpdating }] = useApiPostUpdateRiskElementMutation();
  const [postElement, { isLoading: isCreating }] = useApiPostCreateRiskElementMutation();
  const [nestedModal, setNestedModal] = useState<NestedModal>('None');

  const [selectedCustomFields, setSelectedCustomFields] = useState<ElementUserDefinedFieldDefinitionReadModel[] | null>(
    null,
  );
  const [userDefinedFieldsPayload, setUserDefinedFieldsPayload] = useState<UserDefinedFieldPayload[] | undefined>();

  // for custom fields valid check
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [customFieldsAreValid, setCustomFieldsAreValid] = useState(true);

  const [isLoadingFormula, setIsLoadingFormula] = useState(false);

  const [code, setCode] = useState(decoratedRiskElement?.element.group?.code ?? decoratedRiskElement?.element.riskElement?.code ?? '');
  const [description, setDescription] = useState(decoratedRiskElement?.element.group?.description ?? decoratedRiskElement?.element.riskElement?.description ?? '');
  const [selectedCatalogElementId, setSelectedCatalogElementId] = useState<string | null>(preselectedGroupId ?? decoratedRiskElement?.element.group?.groupId ?? null);

  const [elementTimeline, setElementTimeline] = useState<ElementTimelineReadModel | undefined | null>(null);

  const riskElement = decoratedRiskElement?.element;

  const elementId = useMemo(
    () => riskElement?.group?.riskElementId ?? riskElement?.riskElement?.riskElementId ?? undefined,
    [riskElement],
  );

  const isEditing = !!elementId;
  const { data: dataRiskElement, isFetching } = useApiGetRiskElementQuery(
    {
      projectId: projectId ?? 'unset',
      calculationModelId: variantId ?? 'unset',
      id: elementId ?? '',
    },
    { skip: !isEditing || !elementId },
  );

  const defaultRiskElement: RiskElementReadModel = useMemo(
    () => ({
      id: '',
      calculationModelId: variantId ?? '',
      code: riskElement?.riskElement?.code ?? riskElement?.group?.code ?? '',
      description: riskElement?.riskElement?.description
        ? riskElement?.group?.description ?? ''
        : t('projectCalculate.rowMenu.newRiskElementTitle'),
      probability: 0,
      amount: { type: 'Static', staticValue: { unit: 'lumpsum', value: null } },
      unitPrice: { type: 'Static', staticValue: null },
      total: 0,
      fixedAmount: null,
      fixedUnitPrice: null,
      amountPercentage: null,
      riskAmount: 0,
      earningsElementId: null,
      riskTarget: 'FixedValue',
      isGroup: !!riskElement?.group?.groupId,
      riskCatalogElementId:
        preselectedGroupId ?? riskElement?.riskElement?.riskElementId ?? riskElement?.group?.groupId,
      documents: [],
    }),
    [preselectedGroupId, riskElement?.group, riskElement?.riskElement, variantId, t],
  );

  const { data: riskCatalog, isFetching: isLoadingRiskCatalog } = useApiGetProjectWritableRiskCatalogQuery(
    {
      projectId: projectId ?? '',
      id: riskCatalogId ?? 'unset',
    },
    {
      skip: !riskCatalogId,
    },
  );

  const catalogOptions: BaseSelectOption[] = useMemo(() => {
    const options: BaseSelectOption[] = [];
    for (const catalogOption of riskCatalog?.elements ?? []) {
      options.push({
        label:  (catalogOption.code || catalogOption.description) ? `${catalogOption.code} ${catalogOption.description}` : t('projectCalculate.costElementNoLabel'),
        value: catalogOption.id ?? 'no-id',
      });
    }

    return options.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
  }, [riskCatalog?.elements, t]);

  const [formulaResult, setFormulaResult] = useState(dataRiskElement?.readModel);

  const total = formulaResult?.total;
  const isGroup = dataRiskElement ? dataRiskElement.readModel.isGroup : defaultRiskElement.isGroup;

  const displayExternalForecast = Boolean(decoratedRiskElement);
  const forecastValue: number = decoratedRiskElement?.element?.group?.totalForecast ?? 0;

  useEffect(() => {
    const getNextContractCode = async () => {
      if (projectId && variantId) {
        const isGettingNextCode = false;
        try {
          const ownerObjectId = defaultRiskElement.riskCatalogElementId !== 'unassigned' 
            ? defaultRiskElement.riskCatalogElementId
            : null;

          const nextCodeResponse = await safeMutation(
            getNextCode,
            {
              projectId: projectId,
              calculationModelId: variantId,
              body: {
                ownerObjectId: ownerObjectId,
                projectObjectType: 'RiskElements'
              }
            },
            isGettingNextCode,
          );
          if (typeof nextCodeResponse !== 'undefined') {
            setCode(nextCodeResponse.code);
          }
        } catch (e) {
          console.log(e);
        }
      }
    }

    if (isEditing && dataRiskElement) {
      setCode(dataRiskElement.readModel.code ?? '');
      setDescription(dataRiskElement.readModel.description ?? '');
      setElementTimeline(dataRiskElement.readModel.elementTimeline);
      setFormulaResult(dataRiskElement.readModel);
      setSelectedCatalogElementId(dataRiskElement.readModel.riskCatalogElementId ?? null);
    } else {
      if (!isGroup){
        getNextContractCode();
        setDescription(defaultRiskElement.description);
      }

      setElementTimeline(defaultRiskElement.elementTimeline);
      setFormulaResult(defaultRiskElement);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultRiskElement, dataRiskElement, isEditing, getNextCode, variantId, projectId, isGroup]);

  const getFormulaRequestBody = (arg: 'amount' | 'unitPrice'): FormulaPayload => {
    const formulaPayload: FormulaPayload = {
      expression: formulaResult?.[arg]?.formulaValue?.expression ?? '',
      expressionParameterPayloads:
        formulaResult?.[arg]?.formulaValue?.expressionParameters?.map((p) => ({
          position: p.position,
          taxonomyElementId: p?.taxonomyItem?.id,
          costCatalogElementId: p?.costCatalogElement?.id,
          earningsCatalogElementId: p?.earningsCatalogElement?.id,
          plotId: p?.plot?.id,
        })) ?? [],
      catalogElementId: selectedCatalogElementId,
      elementType: 'RiskElement',
    };
    return formulaPayload;
  };

  const handleCreate = async () => {
    if (customFieldsAreValid) {
      try {
        const amountType = formulaResult?.amount?.type ?? 'Static';
        const unitType = formulaResult?.unitPrice?.type ?? 'Static';

        await safeMutation(
          postElement,
          {
            projectId: projectId ?? 'unset',
            calculationModelId: variantId ?? 'unset',
            body: {
              isGroup,
              code: code,
              description: description,
              riskCatalogElementId:
                selectedCatalogElementId !== '00000000-0000-0000-0000-000000000000'
                  ? selectedCatalogElementId
                  : undefined,
              probability: formulaResult?.probability ?? 0,
              amount: {
                type: formulaResult?.amount?.type ?? 'Static',
                staticFactor:
                  amountType === 'Static' || amountType === 'Formula'
                    ? {
                        value: amountType === 'Formula' ? 0 : formulaResult?.amount?.staticValue?.value ?? 0,
                        unit: formulaResult?.amount.staticValue?.unit ?? '',
                      }
                    : null,
                formula: formulaResult?.amount?.type === 'Formula' ? getFormulaRequestBody('amount') : undefined,
              },
              unitPrice: {
                type: formulaResult?.unitPrice?.type ?? 'Static',
                staticFactor:
                  unitType === 'Static'
                    ? {
                        value: formulaResult?.unitPrice.staticValue?.value ?? 0,
                        unit: formulaResult?.unitPrice.staticValue?.unit ?? '€',
                      }
                    : null,
                formula: formulaResult?.unitPrice?.type === 'Formula' ? getFormulaRequestBody('unitPrice') : undefined,
              },
              elementTimeline:
                elementTimeline == null || elementTimeline?.distribution === null
                  ? null
                  : timelineReadModelToPayload(elementTimeline ?? null),
              userDefinedFieldsPayload: userDefinedFieldsPayload?.length ? userDefinedFieldsPayload : undefined,
            },
          },
          isCreating,
        );

        onClose(true);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleUpdate = async () => {
    if (customFieldsAreValid) {
      // const amountType = formulaResult?.amount?.type ?? 'Static';
      const unitType = formulaResult?.unitPrice?.type ?? 'Static';

      try {
        await safeMutation(
          putElement,
          {
            projectId: projectId ?? 'unset',
            calculationModelId: variantId ?? 'unset',
            id: elementId ?? 'unset',
            body: {
              isGroup,
              code: code,
              description: description,
              riskCatalogElementId: selectedCatalogElementId,
              probability: formulaResult?.probability ?? 0,
              amount: {
                type: formulaResult?.amount?.type ?? 'Static',
                staticFactor: formulaResult?.amount?.staticValue
                  ? {
                      value: formulaResult?.amount?.staticValue?.value ?? 0,
                      unit: formulaResult?.amount.staticValue?.unit ?? '', // TODO: maybe nullable?
                    }
                  : null,
                formula: formulaResult?.amount?.type === 'Formula' ? getFormulaRequestBody('amount') : undefined,
              },
              unitPrice: {
                type: unitType ?? 'Static',
                staticFactor: formulaResult?.unitPrice.staticValue
                  ? {
                      value: formulaResult?.unitPrice.staticValue?.value ?? 0,
                      unit: formulaResult?.unitPrice.staticValue?.unit ?? '€',
                    }
                  : null,
                formula: formulaResult?.unitPrice?.type === 'Formula' ? getFormulaRequestBody('unitPrice') : undefined,
              },
              elementTimeline:
                elementTimeline == null || elementTimeline?.distribution === null
                  ? null
                  : timelineReadModelToPayload(elementTimeline ?? null),
              userDefinedFieldsPayload: userDefinedFieldsPayload?.length ? userDefinedFieldsPayload : undefined,
            },
          },
          isUpdating,
        );

        onClose(true);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleCloseNestedModal = (deleted: boolean) => {
    setNestedModal('None');

    if (deleted) {
      onClose(true);
    }
  };

  return (
    <>
      {(isFetching || isLoadingRiskCatalog || isCreating || isUpdating || isLoadingFormula) && <LoadingIndicator mode="overlay" />}
      <SlideOver.Header
        onClose={onClose}
        title={t('projectCalculate.riskElementTitle')}
        subTitle={isGroup ? t('projectCalculate.groupLabelSubTitle') : t('projectCalculate.riskElementSubTitle')}
        backgroundClassName="bg-sky-800"
      >
        <div className="flex flex-row pt-2 pl-8 bg-sky-800 text-white">
          <button
            type="button"
            className={classNames('px-4 pb-1 text block border-l border-r', {
              'font-bold border-b-2 border-white': subarea === 'Values',
            })}
            onClick={() => setSubarea('Values')}
          >
            <div className="flex felx-row items-center">{t('common.values')}</div>
          </button>
          {isEditing && (
            <>
              <button
                type="button"
                className={classNames('px-4 pb-1 text block border-r', {
                  'font-bold border-b-2 border-white': subarea === 'Documents',
                })}
                onClick={() => setSubarea('Documents')}
              >
                <div className="flex felx-row items-center">{t('common.documents')}</div>
              </button>
              {showFakeUi && (
                <>
                  <button
                    type="button"
                    className={classNames('px-4 pb-1 text block border-l border-r', {
                      'font-bold border-b-2 border-white': subarea === 'History',
                    })}
                    onClick={() => setSubarea('History')}
                  >
                    <div className="flex felx-row items-center">{t('common.history')}</div>
                  </button>
                  <button
                    type="button"
                    className={classNames('px-4 pb-1 text block border-r', {
                      'font-bold border-b-2 border-white': subarea === 'Comments',
                    })}
                    onClick={() => setSubarea('Comments')}
                  >
                    <div className="flex felx-row items-center">{t('common.comments')}</div>
                  </button>
                </>
              )}
            </>
          )}
        </div>
      </SlideOver.Header>
      <SlideOver.Content
        onKeyEnter={() => {
          submitRef.current?.click();
        }}
      >
        {translatedErrors && translatedErrors.length > 0 && (
          <HintBox hintType="warning">{translatedErrors.join(`\n`)}</HintBox>
        )}

        {subarea === 'Comments' && (
          <div className="p-8">
            <img className="w-full h-auto" src={FAKE_CALCULATE_ELEMENT_COMMENT_IMAGE} alt="comments" />
          </div>
        )}

        {subarea === 'History' && (
          <div className="p-8">
            {currentLanguage === 'de' ? (
              <img className="w-full h-auto" src={FAKE_CALCULATE_ELEMENT_HISTORY_DE_IMAGE} alt="history" />
            ) : (
              <img className="w-full h-auto" src={FAKE_CALCULATE_ELEMENT_HISTORY_EN_IMAGE} alt="history" />
            )}
          </div>
        )}

        {subarea === 'Documents' && (
          <div className="m-8">
            <CalculateElementDocuments
              ownerId={elementId ?? ''}
              type="RiskElement"
              canWrite={!readOnly}
              canDelete={canDeleteRisks}
              documents={dataRiskElement?.readModel.documents ?? []}
            />
          </div>
        )}

        {subarea === 'Values' && (
          <>
            <div className="m-8 bg-white">
              <div className="divide-gray-100 divide-y">
                {!isGroup ? (
                  <ComboSelect
                    label={t('projectCalculate.groupLabelGroupSelector')}
                    options={catalogOptions}
                    value={selectedCatalogElementId ?? ''}
                    disabled={readOnly ?? !riskCatalogId}
                    onChange={(selected) => setSelectedCatalogElementId(selected)}
                    icon={<EditNodeIcon />}
                  />
                ) : (
                  <TextInput
                    label={t('projectCalculate.costElementLabelCatalogElement')}
                    value={`${code} - ${description}`}
                    disabled={true}
                    icon={<TagWindowIcon />}
                  />
                )}
                {!isGroup &&
                  <>
                    <TextInput
                        label={t('projectCalculate.riskElementLabelPosition')}
                        value={code}
                        onChange={(value) => setCode(value)}
                        icon={<EditNodeIcon />}
                        disabled={readOnly}
                    />
                    <TextInput
                        label={t('projectCalculate.riskElementLabelDescription')}
                        value={description}
                        onChange={(value) => setDescription(value)}
                        icon={<ResumeIcon />}
                        disabled={readOnly}
                    />
                  </>
                }
              </div>
            </div>
            <SlideOverTitle title={t('projectCalculate.riskElementTitle')} className="px-8" />
            <div className="mb-4 mx-8 bg-white">
              <RiskFormulaInput
                value={formulaResult ?? defaultRiskElement}
                onChange={setFormulaResult}
                disabled={readOnly}
                setIsLoading={setIsLoadingFormula}
              />
            </div>
            <div className="px-8">
              <SlideOverTitle title={t('projectCalculate.earningsElementLabelTimeline')} />
              <div className="mb-8 bg-white">
                <TimeLineDistribution
                  totalValue={total}
                  variantId={variantId}
                  timing={elementTimeline}
                  disabled={readOnly}
                  disabledDistributionTypes={!isGroup || !elementId ? ['Effective'] : []}
                  riskGroupId={isGroup ? elementId : undefined}
                  onChange={(value) => {
                    setElementTimeline(value);
                  }}
                  clearable={true}
                  onClear={() => {
                    setElementTimeline(null);
                  }}
                />
              </div>
            </div>
            {!readOnly && (
              <EditUserDefinedFields
                type="Risk"
                elementId={elementId}
                calculateElementType={isGroup ? 'Group' : 'Element'}
                setUpdatePayload={setUserDefinedFieldsPayload}
                selectedCustomFields={selectedCustomFields}
                setSelectedCustomFields={setSelectedCustomFields}
                isSubmitted={isFormSubmitted}
                updateIsValid={setCustomFieldsAreValid}
              />
            )}
            <SlideOverTotalSection
              label={t('projectCalculate.riskElementLabelNetSum')}
              value={<FormattedCurrency amount={total} />}
            />
            {displayExternalForecast && <ExternalForecast forecastValue={forecastValue} />}
          </>
        )}
      </SlideOver.Content>
      <SlideOver.Controls disabled={isFetching || isLoadingRiskCatalog || isCreating || isUpdating || isLoadingFormula}>
        <div className={classNames('flex-grow flex justify-end', { 'justify-between': isEditing && !readOnly })}>
          {isEditing && canDeleteRisks && !disabled && (
            <Button variant="warning" onClick={() => setNestedModal('delete')}>
              {t('common.delete')}
            </Button>
          )}
          <div className="flex">
            <Button variant="secondary" onClick={() => onClose(false)} className="mr-2">
              {t('common.cancel')}
            </Button>
            {!readOnly && (
              <Button
                variant="primary"
                onClick={() => {
                  setIsFormSubmitted(true);
                  if (isEditing) {
                    handleUpdate();
                  } else {
                    handleCreate();
                  }
                }}
                innerRef={submitRef}
              >
                {t('common.save')}
              </Button>
            )}
          </div>
        </div>
      </SlideOver.Controls>

      <Modal isOpen={nestedModal === 'delete'} onClose={handleCloseNestedModal}>
        <RiskElementDeleteModal elementId={elementId} variantId={variantId} onClose={handleCloseNestedModal} />
      </Modal>
    </>
  );
};
