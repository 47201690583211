import { useTranslation } from 'react-i18next';
import { DecoratedElement, FinancingElement } from '../hooks';
import React, { useMemo } from 'react';
import { TimeLineView } from './Timeline';
import { Column } from './CalculateContainer';
import { OptionalColumn } from './CalculateSectionHeader';
import { CalculateColumns } from './CalculateColumns';
import { CalculateElementRowInformationColumn } from './CalculateElementRowInformationColumn';
import { CalculateInformationColumnContent } from './CalculateGroupElementInformationColumn';
import { CalculateElementRowFinanceColumn } from './CalculateElementRowFinanceColumn';
import { ContextMenuItem, LockIcon, PencilIcon, TrashIcon, UnlockIcon } from '@client/shared/toolkit';
import { useLoadedProjectId } from '@client/project/store';
import { useValidateProjectPermission } from '@client/shared/permissions';
import { ElementRowMenu } from './ElementRowMenu';
import { FormattedCurrency } from '@client/project/shared';
import { CalculateSelectableColumn } from './CalculateSelectableColumn';

interface FinancingElementProps {
  item: DecoratedElement<FinancingElement>;
  selectedVersionId: string;
  onClick: () => void;
  onCodeClick?: () => void;
  optionalColumn: OptionalColumn;
  obligoColumn: OptionalColumn;
  view: TimeLineView;
  onRemove?: () => void;
  onLock?: () => void;
  onUnlock?: () => void;
  isReadOnly: boolean;
  searchResults: string[] | null;
}

export const FinancingElementRow = ({
  item: {
    element: { financingElement },
    level,
    parent,
  },
  onClick,
  view,
  onRemove,
  onLock,
  onUnlock,
  isReadOnly,
  optionalColumn,
  obligoColumn,
  searchResults,
}: FinancingElementProps) => {
  const { t } = useTranslation();
  const loadedProjectId = useLoadedProjectId();
  const canDeleteFinancing = useValidateProjectPermission(['FINANCING_DELETE'], loadedProjectId ?? '');
  const canWriteFinancing = useValidateProjectPermission(['FINANCING_WRITE'], loadedProjectId ?? '');

  const handleOnClick = (evt: React.MouseEvent<HTMLDivElement>) => {
    evt.stopPropagation();
    onClick?.();
  };

  const contextMenuItems = useMemo<ContextMenuItem[]>(() => {
    const items: ContextMenuItem[] = [];

    if (onClick) {
      items.push({
        label: t('projectCalculate.rowMenu.updateFinancingElementRowTitle'),
        subtitle: t('projectCalculate.rowMenu.updateFinancingElementRowSubTitle'),
        onClick: onClick,
        icon: <PencilIcon />,
      });
    }
    if (onRemove) {
      items.push({
        isDisabled: !canDeleteFinancing,
        label: t('projectCalculate.rowMenu.removeFinancingElementTitle'),
        subtitle: t('projectCalculate.rowMenu.removeFinancingElementSubTitle'),
        onClick: onRemove,
        icon: <TrashIcon />,
      });
    }
    if (onLock) {
      items.push({
        isDisabled: !canWriteFinancing,
        label: t('projectCalculate.rowMenu.lockFinancingElementTitle'),
        subtitle: t('projectCalculate.rowMenu.lockFinancingElementSubTitle'),
        onClick: onLock,
        icon: <LockIcon />,
      });
    }
    if (onUnlock) {
      items.push({
        isDisabled: !canWriteFinancing,
        label: t('projectCalculate.rowMenu.unlockFinancingElementTitle'),
        subtitle: t('projectCalculate.rowMenu.unlockFinancingElementSubTitle'),
        onClick: onUnlock,
        icon: <UnlockIcon />,
      });
    }

    return items;
  }, [onClick, t, onRemove, canDeleteFinancing, canWriteFinancing, onLock, onUnlock]);

  const showValues = searchResults?.length
    ? searchResults.includes(financingElement?.id ?? '') || searchResults.includes(financingElement?.financingCatalogElementId ?? '')
    : true;

  return (
    <CalculateColumns
      view={view}
      informationColumnContent={
        <CalculateElementRowInformationColumn
          level={level}
          view={view}
          handleOnClick={handleOnClick}
          contentType="row"
          leftColumn={
            <CalculateInformationColumnContent
              showCode={!!financingElement?.code}
              code={financingElement?.code}
              level={level}
              title={financingElement?.description || financingElement?.title}
              contentType="row"
              showExpanded
              showError={(financingElement?.errors?.length ?? 0) > 0}
            >
              {financingElement?.isLocked && <LockIcon className="h-3" />}
            </CalculateInformationColumnContent>
          }
          rightColumn={
            showValues && <>
              <div className="hidden md:flex w-3/12 text-sm text-right flex-row justify-end items-center leading-tight">
                <div>
                  <FormattedCurrency amount={financingElement?.totalGrantAmount} />
                  <div className="text-xs font-light">{t('projectCalculate.financingElementLabelTotalGrants')}</div>
                </div>
              </div>
              <div className="hidden w-4/12 md:flex text-sm text-right flex-row justify-end items-center leading-tight">
                <div>
                  <FormattedCurrency amount={financingElement?.totalCost} />
                  <div className="text-xs font-light">{t('projectCalculate.financingElementLabelTotalCosts')}</div>
                </div>
              </div>
              <div className="w-5/12 text-right flex flex-row justify-end items-center leading-tight">
                <div className="text-sm font-bold"><FormattedCurrency amount={financingElement?.totalValue} /></div>
              </div>
            </>
          }
          menu={!isReadOnly && <ElementRowMenu items={contextMenuItems} />}
        />
      }
      currentColumnContent={
        <CalculateElementRowFinanceColumn
          level={level + 1}
          handleOnClick={handleOnClick}
          columnType={Column.CURRENT}
          view={view}
        >
          <FormattedCurrency amount={financingElement?.totalValue} />
        </CalculateElementRowFinanceColumn>
      }
      obligoColumnContent={(
        <CalculateSelectableColumn
          column={obligoColumn}
          level={level + 1}
          handleOnClick={handleOnClick}
          columnType={Column.OBLIGO}
          view={view}
          values={financingElement}
          type="financingElement"
        />
      )}
      optionalColumnContent={
        <CalculateSelectableColumn
          column={optionalColumn}
          level={level + 1}
          handleOnClick={handleOnClick}
          columnType={Column.OPTIONAL_COLUMN}
          view={view}
          values={financingElement}
          type="financingElement"
        />
      }
    />
  );
};
