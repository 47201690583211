import {
  DecoratedCard,
  SettingsInlineEditField,
  Form,
  LoadingIndicator,
  SlideOverOnCloseProps,
  HintBox,
  GlobeIcon,
} from '@client/shared/toolkit';
import {
  BenchmarkSettingsPayload,
  Currency,
  ProjectReadModel,
  useApiPostUpdateProjectMetadataMutation,
  ValueType,
  YearlyValuePayload,
} from '@client/shared/api';
import React, { ReactNode, RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectSettingsEditSection } from '@client/shared/toolkit';
import { EditProjectFormValidationSchema, EditProjectFormValidationValues } from '../EditProject';
import { useLoadedDefaultValues, useLoadedMeasureTypes } from '@client/project/store';
import { formatNumber, formatPercentage, safeMutation } from '@client/shared/utilities';
import { ProjectBenchmarkSettingsReferenceUnits } from './ProjectBenchmarkSettingsReferenceUnits';

export type BenchmarkArrayKeys =
  | 'constructionCostIncreases'
  | 'rentalIncreases'
  | 'salesIncreases'
  | 'currencyConversions';

export type YearlyValueBenchmarkSettingsType = 'construction' | 'rental' | 'sales' | 'currency';

interface ProjectBenchmarkingSettingsProps extends SlideOverOnCloseProps {
  project?: ProjectReadModel;
  formRef: RefObject<HTMLFormElement>;
  onDefaultValuesLoaded: (arg: boolean) => void;
  readOnly: boolean;
}

export const ProjectBenchmarkingSettings = (props: ProjectBenchmarkingSettingsProps) => {
  const { project, formRef, onDefaultValuesLoaded } = props;
  const { t } = useTranslation();

  const [benchmarkSettings, setBenchmarkSettings] = useState<BenchmarkSettingsPayload | null>(null);
  const [warning, setWarning] = useState<{ title: string; content: string | ReactNode } | undefined>();

  const [postProjectMetadata, { isLoading }] = useApiPostUpdateProjectMetadataMutation();
  const { data: defaultValues, isFetching } = useLoadedDefaultValues();

  const availableMeasures = useLoadedMeasureTypes();
  const defaultMeasure = availableMeasures.length !== 0 ? availableMeasures[0].id : '';

  const projectCurrency = useMemo(() => {
    return project?.payload.currency.currency;
  }, [project?.payload.currency.currency]);

  // show only years from end date until current year - 1
  const years = useMemo(() => {
    const years = [];
    if (project?.payload.start && project?.payload.end) {
      let startYear = new Date(project.payload.end).getFullYear();
      const maxYear = new Date().getFullYear() - 1;
      if (startYear <= maxYear) {
        while (startYear <= maxYear) {
          years.push(startYear);
          startYear++;
        }
      }
    }
    return years;
  }, [project?.payload.start, project?.payload.end]);

  const defaultBenchmarkSettings = useMemo((): BenchmarkSettingsPayload | null => {
    if (defaultValues && project) {
      return {
        regionalFactor: 1,
        constructionCostIncreases:
          defaultValues.constructionCostIncreases
            .filter((item) => item.countryCode === project.payload.countryCode)
            .reduce((yearlyValues, values) => [...yearlyValues, ...values.yearlyValues], [] as YearlyValuePayload[]) ??
          [],
        rentalIncreases:
          defaultValues.rentalIncreases
            .filter((item) => item.countryCode === project.payload.countryCode)
            .reduce((yearlyValues, values) => [...yearlyValues, ...values.yearlyValues], [] as YearlyValuePayload[]) ??
          [],
        salesIncreases:
          defaultValues.salesIncreases
            .filter((item) => item.countryCode === project.payload.countryCode)
            .reduce((yearlyValues, values) => [...yearlyValues, ...values.yearlyValues], [] as YearlyValuePayload[]) ??
          [],
        currencyConversions:
          defaultValues.currencyConversions
            .filter((item) => item.currency === project.payload.currency.currency)
            .reduce((yearlyValues, values) => [...yearlyValues, ...values.yearlyValues], [] as YearlyValuePayload[]) ??
          [],
        customPropertyValues: []
      };
    }
    return null;
  }, [defaultValues, project]);

  const originalBenchmarkSettings = useMemo((): BenchmarkSettingsPayload | undefined => {
    if (project?.payload?.benchmarkSettings) {
      return {
        regionalFactor: project.payload.benchmarkSettings.regionalFactor,
        constructionCostIncreases: project.payload.benchmarkSettings.constructionCostIncreases,
        rentalIncreases: project.payload.benchmarkSettings.rentalIncreases,
        salesIncreases: project.payload.benchmarkSettings.salesIncreases,
        currencyConversions: project.payload.benchmarkSettings.currencyConversions,
        customPropertyValues: project.payload.benchmarkSettings.customPropertyValues
      };
    }
    return undefined;
  }, [project?.payload]);

  useEffect(() => {
    if (originalBenchmarkSettings) {
      setBenchmarkSettings(originalBenchmarkSettings);
      onDefaultValuesLoaded(true);
    } else if (defaultValues && project?.payload) {
      setBenchmarkSettings({
        regionalFactor: 1,
        constructionCostIncreases: [],
        rentalIncreases: [],
        salesIncreases: [],
        currencyConversions: [],
        customPropertyValues: []
      });
      onDefaultValuesLoaded(true);
    }
  }, [originalBenchmarkSettings, defaultValues, onDefaultValuesLoaded, project?.payload]);

  useEffect(() => {
    if (!isFetching && !isLoading && project) {
      const warningMessages: string[] = [];
      if (!defaultValues) {
        warningMessages.push(t('projectSetting.benchmarking.defaultValuesWarning.content'));
      }
      if (!project?.payload.countryCode) {
        warningMessages.push(t('projectSetting.benchmarking.missingProjectCountryCodeWarning.content'));
      }
      if (!years.length) {
        warningMessages.push(
          t('projectSetting.benchmarking.endYearLargerThanMaxDate.content', {
            endYear: new Date(project.payload.end).getFullYear(),
            lastYear: new Date().getFullYear() - 1,
          }),
        );
      }

      if (warningMessages.length) {
        const warningMessage = (
          <ul>
            {warningMessages.map((message, i) => (
              <li key={`warning-message-${i}`}>{message}</li>
            ))}
          </ul>
        );
        setWarning({
          title: t('projectSetting.benchmarking.defaultValuesWarning.title'),
          content: warningMessage,
        });
      }
    }
  }, [years, defaultValues, isFetching, isLoading, project, t]);

  const getFactorForYear = useCallback(
    (
      type: YearlyValueBenchmarkSettingsType,
      year: number,
    ): { value: number; icon?: ReactNode; isOverwritten: boolean; isUpdated: boolean } => {
      let prop: BenchmarkArrayKeys = 'constructionCostIncreases';
      switch (type) {
        case 'construction':
          prop = 'constructionCostIncreases';
          break;
        case 'rental':
          prop = 'rentalIncreases';
          break;
        case 'sales':
          prop = 'salesIncreases';
          break;
        case 'currency':
          prop = 'currencyConversions';
          break;
      }

      let originalFactor: number | undefined = undefined;
      if (originalBenchmarkSettings && Array.isArray(originalBenchmarkSettings[prop])) {
        originalFactor = originalBenchmarkSettings[prop].find((cc) => cc.year === year)?.factor;
      }
      let overwrittenFactor: number | undefined = undefined;
      if (benchmarkSettings && Array.isArray(benchmarkSettings[prop])) {
        overwrittenFactor = benchmarkSettings[prop].find((cc) => cc.year === year)?.factor;
      }
      let settingsFactor = overwrittenFactor;
      let icon: ReactNode | undefined;
      if (!settingsFactor) {
        // get default value if exists
        if (defaultBenchmarkSettings && Array.isArray(defaultBenchmarkSettings[prop])) {
          settingsFactor = defaultBenchmarkSettings[prop].find((cc) => cc.year === year)?.factor;
          icon = settingsFactor ? <GlobeIcon /> : '*';
        }
      }
      return {
        value: settingsFactor ?? 1,
        icon: icon,
        isOverwritten: !icon,
        isUpdated: overwrittenFactor !== originalFactor,
      };
    },
    [benchmarkSettings, defaultBenchmarkSettings, originalBenchmarkSettings],
  );

  const handleSubmit = async (data: EditProjectFormValidationValues) => {
    if (project?.payload.id) {
      try {
        await safeMutation(
          postProjectMetadata,
          {
            projectId: project?.payload.id,
            body: {
              projectId: data.projectId,
              emailSlug: data.emailSlug ?? '',
              name: data.name,
              street: data.street,
              houseNumber: data.houseNumber,
              postalCode: data.postalCode,
              city: data.city,
              countryCode: data.countryCode,
              vat: data.vat,
              selectedMeasureId: data.measure,
              currency: data.currency as Currency,
              calculateValueType: data.calculateValueType as ValueType,
              userDefinedFieldsPayload: undefined,
              benchmarkSettingsPayload: benchmarkSettings,
            },
          },
          isLoading,
        );
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleFactorChange = useCallback(
    (type: YearlyValueBenchmarkSettingsType, val: number | null, year: number) => {
      if (benchmarkSettings) {
        const settingsCopy = { ...benchmarkSettings };
        let entries: YearlyValuePayload[] = [];
        switch (type) {
          case 'construction':
            entries = [...settingsCopy.constructionCostIncreases];
            break;
          case 'rental':
            entries = [...settingsCopy.rentalIncreases];
            break;
          case 'sales':
            entries = [...settingsCopy.salesIncreases];
            break;
          case 'currency':
            entries = [...settingsCopy.currencyConversions];
            break;
        }

        const foundIndex = entries.findIndex((value) => value.year === year);
        if (foundIndex >= 0) {
          const entryCopy = { ...entries[foundIndex] };
          if (val === null) {
            entries.splice(foundIndex, 1);
          } else {
            entryCopy.factor = val;
            entries[foundIndex] = entryCopy;
          }
        } else if (val !== null) {
          entries.push({
            year: year,
            factor: val,
          });
        }

        switch (type) {
          case 'construction':
            settingsCopy.constructionCostIncreases = entries;
            break;
          case 'rental':
            settingsCopy.rentalIncreases = entries;
            break;
          case 'sales':
            settingsCopy.salesIncreases = entries;
            break;
          case 'currency':
            settingsCopy.currencyConversions = entries;
            break;
        }
        setBenchmarkSettings(settingsCopy);
      }
    },
    [benchmarkSettings],
  );

  const defaultFormValues = {
    projectId: project?.payload.projectId ?? '',
    emailSlug: project?.payload.emailSlug ?? '',
    name: project?.payload.name ?? '',
    street: project?.payload.street ?? '',
    houseNumber: project?.payload.number ?? '',
    postalCode: project?.payload.postalCode ?? '',
    city: project?.payload.city ?? '',
    countryCode: project?.payload.countryCode ?? undefined,
    vat: project?.payload.vat ?? undefined,
    measure: project?.payload.measure?.id ?? defaultMeasure,
    currency: project?.payload.currency.currency,
    calculateValueType: project?.payload.calculateValueType,
  };

  return (
    <DecoratedCard>
      <DecoratedCard.Header showActionButton={false}>
        <div className="flex flex-row justify-between items-center w-full">
          <div className="flex">
            <div className="truncate">{t('projectSetting.benchmarking.title')}</div>
          </div>
        </div>
      </DecoratedCard.Header>
      <DecoratedCard.Content className="px-6">
        {(isLoading || isFetching) && <LoadingIndicator mode="overlay" />}
        {(defaultValues || benchmarkSettings) && project && (
          <Form<EditProjectFormValidationValues>
            onSubmit={handleSubmit}
            validationSchema={EditProjectFormValidationSchema}
            defaultValues={defaultFormValues}
            className="relative"
            ref={formRef}
          >
            <ProjectSettingsEditSection title={t('projectSetting.benchmarking.title')}>
              <div className="mt-3 flex-none w-auto">
                <SettingsInlineEditField
                  value={`${formatNumber(benchmarkSettings?.regionalFactor ?? 1)}x`}
                  valueInput={benchmarkSettings?.regionalFactor ?? 1}
                  label={t('projectSettings.benchmarking.regionalFactor')}
                  onChange={(val) => {
                    if (benchmarkSettings) {
                      const settingsCopy = { ...benchmarkSettings };
                      settingsCopy.regionalFactor = val ?? 1;
                      setBenchmarkSettings(settingsCopy);
                    }
                  }}
                  min={0}
                  max={100}
                />
              </div>
            </ProjectSettingsEditSection>
            {warning ? (
              <div className="my-3">
                <HintBox hintType="warning" title={warning.title}>
                  {warning.content}
                </HintBox>
              </div>
            ) : (
              <>
                <ProjectSettingsEditSection title={t('projectSettings.benchmarking.increaseConstructionCosts')}>
                  <div className="mt-3 flex gap-1 flex-none w-auto overflow-x-auto pb-2">
                    {years.map((year) => {
                      const yearResult = getFactorForYear('construction', year);
                      return (
                        <SettingsInlineEditField
                          key={`benchmarking-cost-factor-year-${year.toString()}`}
                          value={`${formatPercentage(yearResult.value / 100, { maxDigits: 3, minDigits: 0 })} ${t(
                            'projectSettings.benchmarking.perYearLabel',
                          )}`}
                          valueInput={yearResult.value}
                          label={year.toString()}
                          onChange={(val) => handleFactorChange('construction', val, year)}
                          onDelete={
                            yearResult.isOverwritten ? () => handleFactorChange('construction', null, year) : undefined
                          }
                          icon={yearResult.icon}
                          isHighlighted={yearResult.isUpdated}
                          min={0}
                          max={100}
                        />
                      );
                    })}
                  </div>
                </ProjectSettingsEditSection>
                <ProjectSettingsEditSection title={t('projectSettings.benchmarking.rentIncrease')}>
                  <div className="mt-3 flex gap-1 flex-none w-auto overflow-x-auto pb-2">
                    {years.map((year) => {
                      const yearResult = getFactorForYear('rental', year);
                      return (
                        <SettingsInlineEditField
                          key={`benchmarking-rent-increase-year-${year.toString()}`}
                          value={`${formatPercentage(yearResult.value / 100, { maxDigits: 3, minDigits: 0 })} ${t(
                            'projectSettings.benchmarking.perYearLabel',
                          )}`}
                          valueInput={yearResult.value}
                          label={year.toString()}
                          onChange={(val) => handleFactorChange('rental', val, year)}
                          onDelete={
                            yearResult.isOverwritten ? () => handleFactorChange('rental', null, year) : undefined
                          }
                          icon={yearResult.icon}
                          isHighlighted={yearResult.isUpdated}
                          min={0}
                          max={100}
                        />
                      );
                    })}
                  </div>
                </ProjectSettingsEditSection>
                <ProjectSettingsEditSection title={t('projectSettings.benchmarking.salesIncreases')}>
                  <div className="mt-3 flex gap-1 flex-none w-auto overflow-x-auto pb-2">
                    {years.map((year) => {
                      const yearResult = getFactorForYear('sales', year);
                      return (
                        <SettingsInlineEditField
                          key={`benchmarking-sales-increase-year-${year.toString()}`}
                          value={`${formatPercentage(yearResult.value / 100, { maxDigits: 3, minDigits: 0 })}  ${t(
                            'projectSettings.benchmarking.perYearLabel',
                          )}`}
                          valueInput={yearResult.value}
                          label={year.toString()}
                          onChange={(val) => handleFactorChange('sales', val, year)}
                          onDelete={
                            yearResult.isOverwritten ? () => handleFactorChange('sales', null, year) : undefined
                          }
                          icon={yearResult.icon}
                          isHighlighted={yearResult.isUpdated}
                          min={0}
                          max={100}
                        />
                      );
                    })}
                  </div>
                </ProjectSettingsEditSection>
                {/* USD to USD needs no conversion */}
                {projectCurrency !== defaultValues?.currency && (
                  <ProjectSettingsEditSection title={t('projectSettings.benchmarking.currencyConversion')}>
                    <div className="mt-3 flex gap-1 flex-none w-auto overflow-x-auto pb-2">
                      {years.map((year) => {
                        const yearResult = getFactorForYear('currency', year);
                        return (
                          <SettingsInlineEditField
                            key={`benchmarking-currency-factor-year-${year.toString()}`}
                            value={`${yearResult.value} ${projectCurrency?.toUpperCase()}/${
                              defaultValues?.currency.toUpperCase() ?? 'EUR'
                            }`}
                            valueInput={yearResult.value}
                            label={year.toString()}
                            onChange={(val) => handleFactorChange('currency', val, year)}
                            onDelete={
                              yearResult.isOverwritten ? () => handleFactorChange('currency', null, year) : undefined
                            }
                            icon={yearResult.icon}
                            isHighlighted={yearResult.isUpdated}
                            min={0}
                            max={100}
                          />
                        );
                      })}
                    </div>
                  </ProjectSettingsEditSection>
                )}
              </>
            )}
            <ProjectBenchmarkSettingsReferenceUnits
              benchmarkSettings={benchmarkSettings}
              setBenchmarkSettings={setBenchmarkSettings}
            />
          </Form>
        )}
      </DecoratedCard.Content>
    </DecoratedCard>
  );
};
