import { createSelector } from '@reduxjs/toolkit';
import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { logout } from '@client/shared/store';
import { apiEndpointUrls } from './apiGeneratedEndpointUrls';

export type ProbisErrorDataType = {
  message?: string | null;
  code?: string | null;
  messageParameters?: string[] | null;
  errorType?: string; // 'Domain' | 'None' | 'Exception' | 'Validation'
};

// eslint-disable-next-line prefer-const
let queryDelay = 0;
const mutex = new Mutex();

export const setDelay = (delay: string | null | undefined) => {
  if (delay == null) return;
  queryDelay = +delay;
};

const baseQuery = fetchBaseQuery({
  credentials: 'include',
});

const dynamicBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args: string | FetchArgs,
  _api: BaseQueryApi,
  extraOptions: object,
) => {
  await mutex.waitForUnlock();

  if (queryDelay > 0) {
    console.log(`⚠️ Delaying call to ${_api.endpoint} by ${queryDelay}ms`);
    await new Promise((resolve) => setTimeout(resolve, queryDelay));
  }

  let result = await baseQuery(args, _api, extraOptions);
  
  const skipRefreshTokenEndpoints = [
    apiEndpointUrls.apiPostCheckSession,
    apiEndpointUrls.apiGetExchange.replace('/:realm', ''),
    apiEndpointUrls.apiPostRefreshToken,
    apiEndpointUrls.apiPostLogin,
    apiEndpointUrls.apiPostLogout,
    apiEndpointUrls.apiPostCreateUserInvitation
  ];

  // If we get a 401, try to refresh the token and retry the request
  if (
    result.error &&
    result.error.status === 401 &&
    result.meta?.response?.url &&
    !skipRefreshTokenEndpoints.some(url => result.meta?.response?.url?.includes(url))
  ) {

    // Acquire mutex - if another request is already refreshing, this will wait
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        // Check if another request already refreshed the token
        const refreshResult = await baseQuery({ url: apiEndpointUrls.apiPostRefreshToken, method: 'POST' }, _api, extraOptions);
        
        if (!refreshResult.error) {
          // Retry the request after refreshing the token
          result = await baseQuery(args, _api, extraOptions);
        } else {
          _api.dispatch(logout());
        }
      } finally {
        // Release mutex
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, _api, extraOptions);
    }
  }

  return result;
};

export const apiBase = createApi({
  baseQuery: dynamicBaseQuery,
  endpoints: () => ({}),
});

export const isRtkLoadingSelector = createSelector(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (state: any) => state.api,
  (state) => {
    for (const query of Object.keys(state.queries)) {
      if (state.queries[query]?.status === 'pending') return true;
    }
    for (const query of Object.keys(state.mutations)) {
      if (state.mutations[query]?.status === 'pending') return true;
    }
    return false;
  },
);
