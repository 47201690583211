import {
  DocumentPositionBoundingBoxReadModel,
  DocumentViewerFileDataGroup,
  Form,
  FormWatch,
  FormRefHandle,
  InvoiceDocumentPositionReadModel,
  Card,
} from '@client/shared/toolkit';
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  AiEvalResultReadModel,
  InvoiceState,
  InvoiceType,
  ProbisErrorDataType,
  ShortContractReadModel,
  useApiGetContractQuery,
  useApiPostCreateInvoiceByContractMutation,
  useApiPostCreateInvoiceMutation,
  useApiPostGenerateNextProjectObjectCodeMutation,
  useApiPostUpdateAiEvalDocumentInvoiceMetadataMutation,
} from '@client/shared/api';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { useValidateProjectPermission } from '@client/shared/permissions';
import { formatDateOnly, safeMutation } from '@client/shared/utilities';
import {
  InvoiceCreateFormValidationSchema,
  InvoiceCreateFormValidationValues,
} from '../InvoiceCreateFormValidationValues';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import cn from 'classnames';
import { InvoiceDocumentReviewValues } from './InvoiceDocumentReviewValues';
import { InvoiceDataEdit } from '../InvoiceTab';
import { InvoiceDocumentReviewContract, InvoiceEditContext } from '..';
import { useForm } from 'react-hook-form';
import { InvoiceDocumentReviewPartyAndRecipient } from './InvoiceDocumentReviewPartyAndRecipient';

interface InvoiceDocumentFileDataProps {
  invoiceDocument?: AiEvalResultReadModel;
  allBoundingBoxes?: {
    boxes: number[][][];
    pages: number[];
    texts: string[][];
  } | null;
  hoveredBox: number | null;
  showAllBoxes: boolean;
  boxes?: (DocumentPositionBoundingBoxReadModel | null)[];
  setShowAllBoxes: () => void;
  setHoveredBox: (index: number | null) => void;
  setIsFormValid?: (isValid: boolean) => void;
  onClose: () => void;
  setIsLoading?: (isLoading: boolean) => void;
}

export type InvoiceDocumentFileDataRef = {
  createInvoice: () => Promise<boolean | undefined>;
  saveInvoice: () => Promise<boolean | undefined>;
};

export const InvoiceDocumentFileData = forwardRef<InvoiceDocumentFileDataRef, InvoiceDocumentFileDataProps>(
  (props, ref) => {
    const { invoiceDocument, hoveredBox, boxes, setHoveredBox, setIsFormValid, onClose, setIsLoading } = props;
    const { setUnsavedData, updateUnsavedData, setIsSaving, setIsUpdated } = useContext(InvoiceEditContext);

    const loadedProjectId = useLoadedProjectId();
    const loadedVariantId = useLoadedVariantId();
    const canWrite = useValidateProjectPermission(['INVOICE_WRITE'], loadedProjectId ?? '');

    const [createInvoice, { isLoading: isCreating }] = useApiPostCreateInvoiceMutation();
    const [createInvoiceByContract, { isLoading: isCreatingByContract }] = useApiPostCreateInvoiceByContractMutation();
    const [updateInvoiceDocument, { isLoading: isUpdating }] = useApiPostUpdateAiEvalDocumentInvoiceMetadataMutation();

    const [getNextCode, { isLoading: isGettingCode }] = useApiPostGenerateNextProjectObjectCodeMutation();

    const formRef = useRef<FormRefHandle<InvoiceCreateFormValidationValues>>(null);

    const [codeError, setCodeError] = useState(false);
    const [selectedContract, setSelectedContract] = useState<ShortContractReadModel | null>(null);
    const [allowChangeMode, setAllowChangeMode] = useState(true);

    const { data: fetchedContract } = useApiGetContractQuery(
      {
        projectId: loadedProjectId ?? '',
        calculationModelId: loadedVariantId ?? '',
        contractId: selectedContract?.id ?? '',
      },
      {
        skip: !loadedProjectId || !loadedVariantId || !selectedContract?.id,
        refetchOnMountOrArgChange: true
      },
    );

    useEffect(() => {
      if (
        !invoiceDocument?.invoiceMetadata?.workflowDefinitionId &&
        selectedContract &&
        fetchedContract &&
        fetchedContract.workflowDefinitionId &&
        formRef.current
      ) {
        formRef.current?.setValue('workflow', fetchedContract.workflowDefinitionId);
      }
    }, [selectedContract, fetchedContract, invoiceDocument]);

    // use overwritten data first, if not set, use the data coming from ai eval results
    const defaultFormValues = useMemo(() => {
      let dateOfReceipt = '';
      if (invoiceDocument?.invoiceMetadata?.dateOfReceipt || invoiceDocument?.result?.invoiceDetails?.dateOfReceipt || invoiceDocument?.document.created) {
        dateOfReceipt = formatDateOnly(
          new Date(invoiceDocument?.invoiceMetadata?.dateOfReceipt?? invoiceDocument?.result?.invoiceDetails?.dateOfReceipt ?? invoiceDocument.document.created),
        );
      }

      let netValue = invoiceDocument?.result?.invoiceDetails.net ?? 0;
      if (invoiceDocument?.invoiceMetadata?.claim) {
        netValue = Number(
          (
            invoiceDocument.invoiceMetadata.claim /
            (1 + (invoiceDocument.invoiceMetadata.vat ?? invoiceDocument?.result?.invoiceDetails.vat ?? 0) / 100)
          ).toFixed(2),
        )
      } else {
        netValue = invoiceDocument?.result?.invoiceDetails.net === 0 && invoiceDocument?.result?.invoiceDetails.gross
          ? invoiceDocument?.result?.invoiceDetails.gross
            ? Number(
              (
                invoiceDocument?.result?.invoiceDetails.gross /
                (1 + invoiceDocument?.result?.invoiceDetails.vat / 100)
              ).toFixed(2),
            )
            : 0
          : invoiceDocument?.result?.invoiceDetails.net ?? 0;
      }

      return {
        invoicingPartyId: invoiceDocument?.invoiceMetadata ? invoiceDocument?.invoiceMetadata.invoicingPartyId ?? null : invoiceDocument?.result?.customerId ?? null,
        invoiceRecipientId: invoiceDocument?.invoiceMetadata ? invoiceDocument.invoiceMetadata.invoiceRecipientId ?? null : null,
        contractId: invoiceDocument?.invoiceMetadata ? invoiceDocument.invoiceMetadata.uploadedByContract?.id ?? null : invoiceDocument?.result?.contractId ?? null,
        code: invoiceDocument?.invoiceMetadata?.code ?? '', // internal code coming from nextCodeResponse
        externalCode: invoiceDocument?.invoiceMetadata?.externalCode ?? invoiceDocument?.result?.invoiceDetails.number ?? '',
        state: 'Pending' as InvoiceState,
        type: invoiceDocument?.invoiceMetadata ? invoiceDocument?.invoiceMetadata?.invoiceType ?? ('Single' as InvoiceType) : invoiceDocument?.result?.invoiceDetails.type ?? ('Single' as InvoiceType),
        dateOfReceipt: dateOfReceipt,
        invoiceDate: invoiceDocument?.invoiceMetadata ? invoiceDocument?.invoiceMetadata.invoiceDate ?? null : invoiceDocument?.result?.invoiceDetails.invoiceDate ?? null,
        dateOfAudit: null,
        dateOfApproval: null,
        paymentDate: null,
        vat: invoiceDocument?.invoiceMetadata ? invoiceDocument?.invoiceMetadata.vat ?? 0 : invoiceDocument?.result?.invoiceDetails.vat ?? 0,
        net: netValue,
        claim: invoiceDocument?.invoiceMetadata ? invoiceDocument?.invoiceMetadata.claim ?? 0 : invoiceDocument?.result?.invoiceDetails.gross ?? 0,
        dueDate: invoiceDocument?.invoiceMetadata ? invoiceDocument?.invoiceMetadata.dueDate ?? null : invoiceDocument?.result?.invoiceDetails.dueDate ?? null,
        cashDiscountDate: invoiceDocument?.invoiceMetadata ? invoiceDocument?.invoiceMetadata?.cashDiscountDate ?? null : invoiceDocument?.result?.invoiceDetails.cashDiscountDate ?? null,
        workflow: invoiceDocument?.invoiceMetadata?.workflowDefinitionId ?? fetchedContract?.workflowDefinitionId ?? null,
        comment: invoiceDocument?.invoiceMetadata?.comment ?? '',
        permissionGroups: []
      };
    }, [fetchedContract?.workflowDefinitionId, invoiceDocument]);

    useEffect(() => {
      const getNextInvoiceCode = async () => {
        if (loadedProjectId && loadedVariantId && !formRef.current?.getValues().code) {
          const isGettingNextCode = false;
          const nextCodeResponse = await safeMutation(
            getNextCode,
            {
              projectId: loadedProjectId,
              calculationModelId: loadedVariantId,
              body: { projectObjectType: 'Invoice' },
            },
            isGettingNextCode,
          );

          if (nextCodeResponse?.code) {
            formRef.current?.setValue('code', nextCodeResponse.code);
            updateUnsavedData('code', defaultFormValues.code === nextCodeResponse.code);
          }
        }
      };

      if (formRef.current && !formRef.current.getValues().code && !isGettingCode && invoiceDocument) {
        getNextInvoiceCode();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceDocument, defaultFormValues?.code]);

    useEffect(() => {
      if (
        !defaultFormValues.contractId &&
        invoiceDocument?.result?.invoiceDetails.type !== undefined &&
        invoiceDocument?.result?.invoiceDetails.type !== 'Single' &&
        setIsFormValid
      ) {
        setIsFormValid(false);
      }
    }, [defaultFormValues.contractId, invoiceDocument?.result?.invoiceDetails.type, setIsFormValid]);

    const positions: InvoiceDocumentPositionReadModel[] = []; // TODO

    const handleError = (e: unknown) => {
      const error = e as FetchBaseQueryError;
      const data = error.data as ProbisErrorDataType;
      if (data?.code === 'error.invoice.code_already_exists') {
        setCodeError(true);
      }
      setIsSaving(false);
    };

    const reset = () => {
      setUnsavedData([]);
      setIsSaving(false);
      setIsUpdated(true);
      setCodeError(false);
    };

    const handleSubmit = async (data: InvoiceCreateFormValidationValues, mode: 'create' | 'save') => {
      if (loadedProjectId && loadedVariantId && invoiceDocument?.document.id) {
        if (data.contractId && mode === 'create') {
          try {
            await safeMutation(
              createInvoiceByContract,
              {
                projectId: loadedProjectId,
                calculationModelId: loadedVariantId,
                contractId: data.contractId,
                body: {
                  invoiceDocumentId: invoiceDocument.document.id,
                  code: data.code,
                  externalCode: data.externalCode,
                  type: data.type,
                  vat: data.vat,
                  claim: data.claim,
                  invoiceDate: data.invoiceDate
                    ? formatDateOnly(new Date(data.invoiceDate))
                    : formatDateOnly(new Date()),
                  dateOfReceipt: data.dateOfReceipt
                    ? formatDateOnly(new Date(data.dateOfReceipt))
                    : formatDateOnly(new Date()),
                  dueDate: data.dueDate ? formatDateOnly(new Date(data.dueDate)) : null,
                  cashDiscountDate: data.cashDiscountDate ? formatDateOnly(new Date(data.cashDiscountDate)) : null,
                  comment: data.comment,
                  workflowDefinitionId: data.workflow,
                },
              },
              isCreatingByContract,
            );
            reset();
            onClose();
            return true;
          } catch (e) {
            handleError(e);
            return false;
          }
        } else if (mode === 'create' && !data.contractId) {
          try {
            await safeMutation(
              createInvoice,
              {
                projectId: loadedProjectId,
                calculationModelId: loadedVariantId,
                body: {
                  invoiceDocumentId: invoiceDocument.document.id,
                  invoicingPartyId: data.invoicingPartyId,
                  invoiceRecipientId: data.invoiceRecipientId,
                  code: data.code,
                  externalCode: data.externalCode,
                  type: data.type,
                  vat: data.vat,
                  claim: data.claim,
                  invoiceDate: data.invoiceDate
                    ? formatDateOnly(new Date(data.invoiceDate))
                    : formatDateOnly(new Date()),
                  dateOfReceipt: data.dateOfReceipt
                    ? formatDateOnly(new Date(data.dateOfReceipt))
                    : formatDateOnly(new Date()),
                  dueDate: data.dueDate ? formatDateOnly(new Date(data.dueDate)) : null,
                  cashDiscountDate: data.cashDiscountDate ? formatDateOnly(new Date(data.cashDiscountDate)) : null,
                  comment: data.comment,
                  workflowDefinitionId: data.workflow,
                },
              },
              isCreating,
            );
            reset();
            onClose();
            return true;
          } catch (e) {
            handleError(e);
            return false;
          }
        } else if (mode === 'save') {
          try {
            await safeMutation(
              updateInvoiceDocument,
              {
                projectId: loadedProjectId,
                documentId: invoiceDocument.document.id,
                body: {
                  contractId: data.contractId,
                  workflowDefinitionId: data.workflow,
                  invoiceType: data.type,
                  invoicingPartyId: data.invoicingPartyId,
                  invoiceRecipientId: data.invoiceRecipientId,
                  code: data.code,
                  externalCode: data.externalCode,
                  vat: data.vat,
                  claim: data.claim,
                  invoiceDate: data.invoiceDate
                    ? formatDateOnly(new Date(data.invoiceDate))
                    : formatDateOnly(new Date()),
                  dateOfReceipt: data.dateOfReceipt
                    ? formatDateOnly(new Date(data.dateOfReceipt))
                    : formatDateOnly(new Date()),
                  dueDate: data.dueDate ? formatDateOnly(new Date(data.dueDate)) : null,
                  cashDiscountDate: data.cashDiscountDate ? formatDateOnly(new Date(data.cashDiscountDate)) : null,
                  comment: data.comment,
                },
              },
              isUpdating,
            );
            reset();
            return true;
          } catch (e) {
            handleError(e);
            return false;
          }
        }
      }
    };

    const { trigger } = useForm();

    useImperativeHandle(ref, () => ({
      createInvoice: async () => {
        if (formRef.current) {
          const isValid = await trigger();
          if (setIsFormValid) {
            setIsFormValid(isValid);
          }
          return (await handleSubmit(formRef.current.getValues(), 'create')) ?? false;
        }
        return false;
      },
      saveInvoice: async () => {
        if (formRef.current) {
          const isValid = await trigger();
          if (setIsFormValid) {
            setIsFormValid(isValid);
          }
          return (await handleSubmit(formRef.current.getValues(), 'save')) ?? false;
        }
        return false;
      },
    }));

    useEffect(() => {
      if (setIsLoading) {
        setIsLoading(isCreating || isCreatingByContract || isUpdating);
      }
    }, [isCreating, isCreatingByContract, isUpdating, setIsLoading]);

    // render form with default values only if invoice document was loaded already
    if (!invoiceDocument) return null;

    return (
      <Form<InvoiceCreateFormValidationValues>
        onSubmit={(data) => handleSubmit(data, 'create')}
        validationSchema={InvoiceCreateFormValidationSchema}
        defaultValues={defaultFormValues}
        className="flex flex-col gap-7"
        ref={formRef}
      >
        <FormWatch<InvoiceCreateFormValidationValues>
          onChange={({ invoicingPartyId }) => {
            if (invoicingPartyId && !!selectedContract && selectedContract?.contractorId !== invoicingPartyId) {
              setSelectedContract(null);
            }
          }}
          fieldNames={[
            'dateOfReceipt',
            'claim',
            'net',
            'vat',
            'type',
            'invoicingPartyId',
            'invoiceRecipientId',
            'workflow',
          ]}
        >
          {({
              dateOfReceipt,
              claim,
              net,
              vat,
              type,
              invoicingPartyId,
              invoiceRecipientId,
              workflow
          }) => (
            <>
              <Card>
                <InvoiceDocumentReviewPartyAndRecipient
                  contract={selectedContract}
                  invoicingPartyId={invoicingPartyId}
                  invoiceRecipientId={invoiceRecipientId}
                  invoiceDocument={invoiceDocument}
                  formRef={formRef}
                  defaultFormValues={defaultFormValues}
                  updateUnsavedData={updateUnsavedData}
                />
              </Card>

              {/* CONTRACT */}
              <InvoiceDocumentReviewContract
                contract={selectedContract}
                invoicingPartyId={invoicingPartyId}
                allowChangeMode={allowChangeMode}
                setAllowChangeMode={setAllowChangeMode}
                setSelectedContract={setSelectedContract}
                formRef={formRef}
                dateOfReceipt={dateOfReceipt}
                type={type}
                invoiceDocument={invoiceDocument}
                defaultFormValues={defaultFormValues}
                updateUnsavedData={updateUnsavedData}
                setIsLoading={setIsLoading}
                workflow={workflow}
              />

              {/* INVOICE DATA */}
              <DocumentViewerFileDataGroup className="relative">
                <InvoiceDataEdit
                  codeError={codeError}
                  defaultFormValues={defaultFormValues}
                  allowChangeMode={allowChangeMode}
                  setAllowChangeMode={setAllowChangeMode}
                  canEdit={canWrite}
                  updateUnsavedData={updateUnsavedData}
                  first
                  showType
                  contractId={selectedContract?.id ?? null}
                />
              </DocumentViewerFileDataGroup>

              {/* INVOICE TITLES AND VALUES */}
              <DocumentViewerFileDataGroup className="relative" divider={false}>
                {/* INVOICE TITLES */}
                {positions && positions?.length > 0 && (
                  <div className="flex flex-col gap-4 text-[15px] truncate">
                    {positions.map((position, i) => (
                      <div
                        key={`invoice-position-${i}`}
                        className={cn('flex flex-nowrap gap-4 justify-between transition-colors duration-300', {
                          'bg-red-500/20': hoveredBox === i,
                          'cursor-pointer': boxes?.length && boxes[i],
                        })}
                        onMouseEnter={boxes?.length && boxes[i] ? () => setHoveredBox(i) : undefined}
                        onMouseLeave={boxes?.length && boxes[i] ? () => setHoveredBox(null) : undefined}
                      >
                        <span className="truncate">{position.value}</span>
                        <span className="text-right whitespace-nowrap">
                          {position.correctedValue ? position.correctedValue : position.originalValue}
                        </span>
                      </div>
                    ))}
                  </div>
                )}
                {/* INVOICE VALUES */}
                <InvoiceDocumentReviewValues
                  claim={claim}
                  net={net}
                  vat={vat}
                  formRef={formRef}
                  defaultFormValues={defaultFormValues}
                  updateUnsavedData={(key, value) => {
                    updateUnsavedData(key, value);
                  }}
                />
              </DocumentViewerFileDataGroup>
            </>
          )}
        </FormWatch>
      </Form>
    );
  },
);
