export const apiEndpointUrls = {
  apiPostAcceptUserInvitation: "/api/user/invitation/:tokenId/accept",
  apiGetAcceptUserInvitation: "/api/user/invitation/:tokenId/accept",
  apiPostAcceptTenantInvite: "/api/tenant/invite/accept",
  apiPostActiveApiAccess: "/api/tenant/externalapis/activate",
  apiAdminPostActivateLicenseGrant: "/api/admin/tenants/:tenantId/licenses/grants/:licenseGrantId/activate",
  apiAdminPostActivatePublicApiAccess: "/api/admin/tenants/:tenantId/public-api-access/activate",
  apiPostApiAccess: "/api/tenant/externalapis",
  apiGetExternalApis: "/api/tenant/externalapis",
  apiRemoveApiAccess: "/api/tenant/externalapis",
  apiUpdateApiAccess: "/api/tenant/externalapis",
  apiAddDatevApiAccess: "/api/tenant/add-datev-access",
  apiPostAddDocuWareAccess: "/api/docuware/:apiId",
  apiAddProjectDatevProbisMapping: "/api/projects/:projectId/datev-probis-mapping",
  apiPostCreateRentDealContact: "/api/projects/:projectId/rent/deals/:dealId/contacts",
  apiPostCreateRentDealContractItem: "/api/projects/:projectId/rent/deals/:dealId/contractItems",
  apiPostCreateRentDealCostItem: "/api/projects/:projectId/rent/deals/:dealId/costItems",
  apiPostCreateRentDealDocument: "/api/projects/:projectId/rent/deals/:dealId/documents",
  apiPostCreateSellDealContact: "/api/projects/:projectId/sell/deals/:dealId/contacts",
  apiPostCreateSellDealContractItem: "/api/projects/:projectId/sell/deals/:dealId/contractItems",
  apiPostCreateSellDealCostItem: "/api/projects/:projectId/sell/deals/:dealId/costItems",
  apiPostCreateSellDealDocument: "/api/projects/:projectId/sell/deals/:dealId/documents",
  apiAddTenantDatevProbisMapping: "/api/tenant/datev-access/:datevApiAccessId/datev-probis-mapping",
  apiAddYardiProbisProjectMapping: "/api/projects/:projectId/yardi-probis-mapping",
  apiDeleteAiEvalDocument: "/api/projects/:projectId/aievaldocument/:documentId",
  apiGetAiEvalResult: "/api/projects/:projectId/aievaldocument/:documentId",
  apiPostApplyCostElementForecastAsBudget: "/api/projects/:projectId/calculation-models/:calculationModelId/cost-elements/:costElementId/forecast",
  apiPostApproveInvoice: "/api/projects/:projectId/calculation-models/:calculationModelId/invoices/:invoiceId/approve",
  apiPostCreateCalculationModelArchive: "/api/projects/:projectId/calculation-models/:calculationModelId/archive",
  apiPostAuditInvoice: "/api/projects/:projectId/calculation-models/:calculationModelId/invoices/:invoiceId/audit",
  apiGetAuthRoles: "/api/auth/roles",
  apiPostCreateAuthRole: "/api/auth/roles",
  apiGetBaseReport: "/api/projects/:projectId/reporting/base",
  apiGetBmwChangeRequestReport: "/api/projects/:projectId/calculation-models/:calculationModelId/reporting/bmw-change-request",
  apiGetBmwRiskReport: "/api/projects/:projectId/calculation-models/:calculationModelId/reporting/bmw-risk",
  apiGetBmwWaterfallReport: "/api/projects/:projectId/calculation-models/:calculationModelId/reporting/bmw-waterfall",
  apiGetBugetReport: "/api/projects/:projectId/calculation-models/:calculationModelId/reporting/budget",
  apiGetBudgetExcelReport: "/api/projects/:projectId/calculation-models/:calculationModelId/reporting/budget/excel",
  apiPostCalculateAllConsumptionsCost: "/api/projects/:projectId/calculation-models/:calculationModelId/calculate-all-consumptions-cost",
  apiPostCalculateConsumptionCost: "/api/projects/:projectId/calculation-models/:calculationModelId/calculate-consumption-cost",
  apiAdminPostCalculateContracts: "/api/admin/tenants/:tenantId/projects/:projectId/contracts/calculate",
  apiPostCalculateContractTitle: "/api/projects/:projectId/calculation-models/:calculationModelId/calculate-contract-title",
  apiPostCalculateFormula: "/api/projects/:projectId/calculation-models/:calculationModelId/calculate-formula",
  apiPostCalculateInvoiceValueDistribution: "/api/projects/:projectId/calculation-models/:calculationModelId/invoices/:invoiceId/calculate-distribution",
  apiCancelAiEvalProcess: "/api/projects/:projectId/aievaldocument/:documentId/cancel",
  apiGetCashFlowGroupReport: "/api/projects/:projectId/calculation-models/:calculationModelId/reporting/cash-flow-group",
  apiGetCashFlowGroupExcelReport: "/api/projects/:projectId/calculation-models/:calculationModelId/reporting/cash-flow-group/excel",
  apiGetCashFlowReport: "/api/projects/:projectId/calculation-models/:calculationModelId/reporting/cash-flow",
  apiGetCashOutReport: "/api/projects/:projectId/calculation-models/:calculationModelId/reporting/cash-out",
  apiAdminPostChangeCalculateValueType: "/api/admin/tenants/:tenantId/projects/:projectId/calculateValueType/change",
  apiPostUpdateCalculationModelVersion: "/api/projects/:projectId/calculation-models/:calculationModelId/version",
  apiPostUpdateUserEmail: "/api/user/change-email",
  apiPostUpdateUserLanguage: "/api/user/change-language",
  apiPostUpdateUserName: "/api/user/change-name",
  apiPostUpdatePassword: "/api/auth/change-password",
  apiChangeProjectDatevClient: "/api/projects/:projectId/change-datev-client/:datevClientId",
  apiChangeTenantDatevClient: "/api/tenant/datev-access/:datevApiAccessId/change-datev-client/:datevClientId",
  apiPostCheckSession: "/api/auth/check-session",
  apiGetDevelopmentCommandList: "/api/development/commandlist",
  apiGetComparisonReport: "/api/projects/:projectId/calculation-models/:calculationModelId/reporting/comparison/:targetId",
  apiGetComparisonExcelReport: "/api/projects/:projectId/calculation-models/:calculationModelId/reporting/comparison/:targetId/excel",
  apiPostReviewAiEvalDocumentWorkflowTask: "/api/projects/:projectId/calculation-models/:calculationModelId/workflows/review-document",
  apiPostReviewContractWorkflowTask: "/api/projects/:projectId/calculation-models/:calculationModelId/workflows/review-contract",
  apiPostReviewInvoiceWorkflowTask: "/api/projects/:projectId/calculation-models/:calculationModelId/workflows/review-invoice",
  apiPostReviewSupplementWorkflowTask: "/api/projects/:projectId/calculation-models/:calculationModelId/workflows/review-supplement",
  apiGetImportContractFileTemplate: "/api/projects/:projectId/import/templates/contracts",
  apiGetContractReport: "/api/projects/:projectId/calculation-models/:calculationModelId/reporting/contract",
  apiGetContractWithContractorReport: "/api/projects/:projectId/calculation-models/:calculationModelId/reporting/contracts-with-contractor",
  apiAdminPostConvertContracts: "/api/admin/tenants/:tenantId/projects/:projectId/contracts/convert/:moveBudgets",
  apiPostCopyAuthRole: "/api/auth/roles/:roleId/copy",
  apiPostCreateCalculationModelCopy: "/api/projects/:projectId/calculation-models/:calculationModelId/copy",
  apiPostCopyContract: "/api/projects/:projectId/calculation-models/:calculationModelId/contracts/:contractId/copy",
  apiPostCreateProjectCopy: "/api/projects/:projectId/copy",
  apiPostCopyProjectInvoiceCalculationScheme: "/api/projects/:projectId/invoice-calculation-schemes/:invoiceCalculationSchemeId/copy",
  apiAdminPostCopyProjectToTenant: "/api/admin/project/copy",
  apiPostCopyTenantInvoiceCalculationScheme: "/api/invoice-calculation-schemes/:invoiceCalculationSchemeId/copy",
  apiPostCopyTenantInvoiceCalculationSchemeToProject: "/api/invoice-calculation-schemes/:invoiceCalculationSchemeId/copyToProject/:projectId",
  apiAdminPostCopyWorkflow: "/api/admin/elsa/copy-workflow",
  apiGetImportCostElementFileTemplate: "/api/projects/:projectId/import/templates/cost-elements",
  apiPostAssignableWorkflowActivities: "/api/projects/:projectId/workflows/assignable-activities",
  apiGetAssignableWorkflowActivities: "/api/projects/:projectId/workflows/assignable-activities",
  apiPostCreateAuthGroup: "/api/auth/groups",
  apiGetAuthGroups: "/api/auth/groups",
  apiPostCreateBenchmarkReportColumn: "/api/reporting/benchmark/report-configurations/:reportConfigurationId/columns",
  apiPostCreateBenchmarkReportConfiguration: "/api/reporting/benchmark/report-configurations",
  apiGetBenchmarkReportConfigurations: "/api/reporting/benchmark/report-configurations",
  apiPostCreateTaxonomy: "/api/projects/:projectId/calculation-models/:calculationModelId/taxonomy",
  apiGetTaxonomy: "/api/projects/:projectId/calculation-models/:calculationModelId/taxonomy",
  apiPostCreateCalculationModelDeliveryPhases: "/api/projects/:projectId/calculation-models/:calculationModelId/delivery-phases",
  apiGetCalculationModelDeliveryPhases: "/api/projects/:projectId/calculation-models/:calculationModelId/delivery-phases",
  apiPostCreateCalculationModelMilestones: "/api/projects/:projectId/calculation-models/:calculationModelId/milestones",
  apiGetCalculationModelMilestones: "/api/projects/:projectId/calculation-models/:calculationModelId/milestones",
  apiPostCreateCalculationModelSnapshot: "/api/projects/:projectId/calculation-models/:calculationModelId/snapshot",
  apiCreateCatalogElement: "/api/catalog/element",
  apiDeleteCatalogElement: "/api/catalog/element",
  apiUpdateCatalogElement: "/api/catalog/element",
  apiCreateCatalogElementMapping: "/api/catalog/mapping/element",
  apiDeleteCatalogElementMapping: "/api/catalog/mapping/element",
  apiCreateCatalog: "/api/catalog",
  apiDeleteCatalog: "/api/catalog",
  apiUpdateCatalog: "/api/catalog",
  apiCreateCatalogMapping: "/api/catalog/mapping",
  apiDeleteCatalogMapping: "/api/catalog/mapping",
  apiPostCreateCatalogTemplate: "/api/templates/catalogs",
  apiGetCatalogTemplates: "/api/templates/catalogs",
  apiPostCreateClusterItemRequirementDocuments: "/api/portfolios/:portfolioId/cluster-items/:clusterItemId/requirements/:requirementId/documents",
  apiPostCreateClusterItemRequirement: "/api/portfolios/:portfolioId/cluster-items/:clusterItemId/requirements",
  apiPostCreateCommitmentDocuments: "/api/projects/:projectId/calculation-models/:calculationModelId/commitments/:commitmentId/documents",
  apiDeleteCommitmentDocument: "/api/projects/:projectId/calculation-models/:calculationModelId/commitments/:commitmentId/documents",
  apiPostCreateCommitmentEmbedLinkDocuments: "/api/projects/:projectId/calculation-models/:calculationModelId/commitments/:commitmentId/embed-link-documents",
  apiPostCreateCommitment: "/api/projects/:projectId/calculation-models/:calculationModelId/commitments",
  apiGetCommitments: "/api/projects/:projectId/calculation-models/:calculationModelId/commitments",
  apiPostCreateCompanyBranch: "/api/companies/:companyId/branches",
  apiPostCreateCompany: "/api/companies/company",
  apiPostCreatePrivatePerson: "/api/companies/privateperson",
  apiPostCreateCompanyPerson: "/api/companies/:companyId/persons",
  apiPostCreateContractDocuments: "/api/projects/:projectId/calculation-models/:calculationModelId/contracts/:contractId/documents",
  apiPostCreateContractEmbedLinkDocuments: "/api/projects/:projectId/calculation-models/:calculationModelId/contracts/:contractId/embed-link-documents",
  apiPostCreateContract: "/api/projects/:projectId/calculation-models/:calculationModelId/contracts",
  apiGetContracts: "/api/projects/:projectId/calculation-models/:calculationModelId/contracts",
  apiPostCreateContractTitleDocument: "/api/projects/:projectId/calculation-models/:calculationModelId/contracts/:contractId/contract-titles/:contractTitleId/documents",
  apiGetContractTitleDocuments: "/api/projects/:projectId/calculation-models/:calculationModelId/contracts/:contractId/contract-titles/:contractTitleId/documents",
  apiPostCreateContractTitleEmbedLinkDocument: "/api/projects/:projectId/calculation-models/:calculationModelId/contracts/:contractId/contract-titles/:contractTitleId/embed-link-documents",
  apiPostCreateContractTitle: "/api/projects/:projectId/calculation-models/:calculationModelId/contracts/:contractId/titles",
  apiPostCreateContractTitlePosition: "/api/projects/:projectId/calculation-models/:calculationModelId/contracts/:contractId/positions",
  apiPostUpdateContractTitlePosition: "/api/projects/:projectId/calculation-models/:calculationModelId/contracts/:contractId/positions",
  apiPostCreateCostElementDocument: "/api/projects/:projectId/calculation-models/:calculationModelId/cost-elements/:costElementId/documents",
  apiPostCreateCostElement: "/api/projects/:projectId/calculation-models/:calculationModelId/cost-elements",
  apiPostCreateCustomDashboard: "/api/projects/:projectId/custom-dashboards",
  apiGetCustomDashboard: "/api/projects/:projectId/custom-dashboards",
  apiPostCreateCustomListItem: "/api/default-values/:defaultValuesId/custom-lists/:customListId/items",
  apiCreateDatevAuthenticationUrl: "/api/tenant/datev-authenticate-url",
  apiCreateDatevCache: "/api/tenant/datev-cache",
  apiPostCreateDeliveryPhaseCatalog: "/api/delivery-phases-catalog/:catalogId/delivery-phase",
  apiPostCreateDeliveryPhase: "/api/delivery-phases-catalog",
  apiGetCatalogsDeliveryPhases: "/api/delivery-phases-catalog",
  apiPostCreateEarningsElementDocument: "/api/projects/:projectId/calculation-models/:calculationModelId/earnings-elements/:earningsElementId/documents",
  apiPostCreateEarningsElement: "/api/projects/:projectId/calculation-models/:calculationModelId/earnings-elements",
  apiPostCreateElementTimeline: "/api/projects/:projectId/calculation-models/:calculationModelId/element-timelines",
  apiPostCreateFinancingElementDocument: "/api/projects/:projectId/calculation-models/:calculationModelId/financing-elements/:financingElementId/documents",
  apiPostCreateFinancingElement: "/api/projects/:projectId/calculation-models/:calculationModelId/financing-elements",
  apiPostCreateInflationCostElement: "/api/projects/:projectId/calculation-models/:calculationModelId/cost-elements/inflation",
  apiPostCreateInflationEarningElement: "/api/projects/:projectId/calculation-models/:calculationModelId/earnings-elements/inflation",
  apiPostCreateInvoiceByContract: "/api/projects/:projectId/calculation-models/:calculationModelId/contracts/:contractId/invoices",
  apiPostCreateInvoiceByContractWithSourceDocument: "/api/projects/:projectId/calculation-models/:calculationModelId/contracts/:contractId/invoices/withSourceDocument",
  apiPostCreateInvoiceDocuments: "/api/projects/:projectId/calculation-models/:calculationModelId/invoices/:invoiceId/documents",
  apiPostCreateInvoiceEmbedLinkDocuments: "/api/projects/:projectId/calculation-models/:calculationModelId/invoices/:invoiceId/embed-link-documents",
  apiPostCreateInvoice: "/api/projects/:projectId/calculation-models/:calculationModelId/invoices",
  apiGetInvoices: "/api/projects/:projectId/calculation-models/:calculationModelId/invoices",
  apiPostCreateInvoiceSourceDocument: "/api/projects/:projectId/calculation-models/:calculationModelId/invoices/:invoiceId/documents/source",
  apiPostCreateInvoiceTitle: "/api/projects/:projectId/calculation-models/:calculationModelId/invoices/:invoiceId/titles",
  apiPostCreateInvoiceWithSourceDocument: "/api/projects/:projectId/calculation-models/:calculationModelId/invoices/withSourceDocument",
  apiAdminCreateKlippaConfigurations: "/api/admin/klippa-configurations",
  apiAdminGetKlippaConfigurations: "/api/admin/klippa-configurations",
  apiAdminPostCreateLicense: "/api/admin/licenses",
  apiAdminGetLicenses: "/api/admin/licenses",
  apiAdminPostCreateLicenseGrant: "/api/admin/tenants/:tenantId/licenses/:licenseId/grants",
  apiPostCreateLocalLawCostElement: "/api/projects/:projectId/calculation-models/:calculationModelId/cost-elements/local-law",
  apiPostCreatePlot: "/api/projects/:projectId/calculation-models/:calculationModelId/plots",
  apiGetPlotsByCalculationModel: "/api/projects/:projectId/calculation-models/:calculationModelId/plots",
  apiPostCreateProjectCalculationRule: "/api/projects/:projectId/invoice-calculation-schemes/:invoiceCalculationSchemeId/calculation-rule-groups/:calculationRuleGroupId/calculation-rules",
  apiPostCreateProjectCalculationRuleGroup: "/api/projects/:projectId/invoice-calculation-schemes/:invoiceCalculationSchemeId/calculation-rule-groups",
  apiPostCreateEuriborRateProjection: "/api/euribor-rate-projection",
  apiPostCreateProject: "/api/projects",
  apiGetProjects: "/api/projects",
  apiPostCreateProjectFromTemplate: "/api/projects/from-template",
  apiPostCreateProjectInvoiceCalculationScheme: "/api/projects/:projectId/invoice-calculation-schemes",
  apiGetProjectInvoiceCalculationSchemes: "/api/projects/:projectId/invoice-calculation-schemes",
  apiPostCreateProjectPermissionGroup: "/api/projects/:projectId/permission-groups",
  apiGetProjectPermissionGroups: "/api/projects/:projectId/permission-groups",
  apiPostCreateProjectSpv: "/api/projects/:projectId/spvs",
  apiGetProjectSpvs: "/api/projects/:projectId/spvs",
  apiPostProjectTemplate: "/api/project-templates",
  apiGetProjectTemplates: "/api/project-templates",
  apiPostProjectValuation: "/api/pricehubble/projectvaluation",
  apiAdminPostCreatePublicApiAccess: "/api/admin/tenants/:tenantId/public-api-access",
  apiAdminDeletePublicApiAccess: "/api/admin/tenants/:tenantId/public-api-access",
  apiAdminGetPublicApiAccess: "/api/admin/tenants/:tenantId/public-api-access",
  apiPostCreateRentDeal: "/api/projects/:projectId/rent/deals",
  apiGetRentDeals: "/api/projects/:projectId/rent/deals",
  apiPostCreateReportDashboard: "/api/projects/:projectId/report-dashboards",
  apiGetReportDashboards: "/api/projects/:projectId/report-dashboards",
  apiPostCreateRiskElementDocument: "/api/projects/:projectId/calculation-models/:calculationModelId/risk-elements/:riskElementId/documents",
  apiPostCreateRiskElement: "/api/projects/:projectId/calculation-models/:calculationModelId/risk-elements",
  apiPostCreateSellDeal: "/api/projects/:projectId/sell/deals",
  apiGetSellDeals: "/api/projects/:projectId/sell/deals",
  apiPostCreateSubContractTitle: "/api/projects/:projectId/calculation-models/:calculationModelId/contracts/:contractId/titles/subtitle",
  apiPostTemplateScenario: "/api/project-templates/:templateId/scenarios",
  apiGetTemplateScenarios: "/api/project-templates/:templateId/scenarios",
  apiPostCreateTenantCalculationRule: "/api/invoice-calculation-schemes/:invoiceCalculationSchemeId/calculation-rule-groups/:calculationRuleGroupId/calculation-rules",
  apiPostCreateTenantCalculationRuleGroup: "/api/invoice-calculation-schemes/:invoiceCalculationSchemeId/calculation-rule-groups",
  apiAdminPostCreateTenant: "/api/admin/tenant",
  apiPostCreateTenantInvoiceCalculationScheme: "/api/invoice-calculation-schemes",
  apiGetTenantInvoiceCalculationSchemes: "/api/invoice-calculation-schemes",
  apiPostCreateTenantUserDefinedFieldDefinition: "/api/tenant/user-defined-fields-definition",
  apiGetUserDefinedFieldsDefinition: "/api/tenant/user-defined-fields-definition",
  apiAdminPostCreateUser: "/api/admin/user",
  apiPostCreateVatCostElement: "/api/projects/:projectId/calculation-models/:calculationModelId/cost-elements/vat",
  apiPostCreateProjectVersion: "/api/projects/:projectId/version",
  apiPostCreateVersionFromScenario: "/api/projects/:projectId/calculation-models/:calculationModelId/scenarios/version",
  apiGetCrossTenantAvatar: "/api/cross-tenant/:crossTenantId/avatar",
  apiPostCrossTenantUploadAiEvalDocument: "/api/cross-tenant/:crossTenantId/projects/:crossTenantProjectId/aievaldocument/:contractId",
  apiGetExchangeDataImports: "/api/data/getImports/:proId",
  apiGetExchangeDataHasNewImports: "/api/data/hasNewImports/:proId/:calcModelId",
  apiPostExchangeDataProcessImport: "/api/data/processImport/:proId/:calcModelId",
  apiAdminPostDeactivateLicenseGrant: "/api/admin/tenants/:tenantId/licenses/grants/:licenseGrantId/deactivate",
  apiAdminPostDeactivatePublicApiAccess: "/api/admin/tenants/:tenantId/public-api-access/deactivate",
  apiGetDefaultInvoiceCoverSheetReport: "/api/reports/default/invoice-cover-sheet",
  apiAdminDeleteBudgetAssignments: "/api/admin/tenants/:tenantId/projects/:projectId/budget-assignments/delete",
  apiDeleteAuthGroup: "/api/auth/groups/:groupId",
  apiPostUpdateAuthGroup: "/api/auth/groups/:groupId",
  apiDeleteAuthRole: "/api/auth/roles/:roleId",
  apiPostUpdateAuthRole: "/api/auth/roles/:roleId",
  apiDeleteBenchmarkReportColumn: "/api/reporting/benchmark/report-configurations/:reportConfigurationId/columns/:columnId",
  apiPostUpdateBenchmarkReportColumn: "/api/reporting/benchmark/report-configurations/:reportConfigurationId/columns/:columnId",
  apiDeleteBenchmarkReportConfiguration: "/api/reporting/benchmark/report-configurations/:reportConfigurationId",
  apiPostUpdateBenchmarkReportConfiguration: "/api/reporting/benchmark/report-configurations/:reportConfigurationId",
  apiDeleteCalculationModelDeliveryPhases: "/api/projects/:projectId/calculation-models/:calculationModelId/delivery-phases/:deliveryPhaseId",
  apiPostUpdateCalculationModelDeliveryPhases: "/api/projects/:projectId/calculation-models/:calculationModelId/delivery-phases/:deliveryPhaseId",
  apiDeleteCalculationModel: "/api/projects/:projectId/calculation-models/:calculationModelId",
  apiGetCalculationModel: "/api/projects/:projectId/calculation-models/:calculationModelId",
  apiDeleteCalculationModelMilestones: "/api/projects/:projectId/calculation-models/:calculationModelId/milestones/:milestoneId",
  apiPostUpdateCalculationModelMilestones: "/api/projects/:projectId/calculation-models/:calculationModelId/milestones/:milestoneId",
  apiDeleteCatalogTemplate: "/api/templates/catalogs/:catalogTemplateId",
  apiGetCatalogTemplate: "/api/templates/catalogs/:catalogTemplateId",
  apiPostUpdateCatalogTemplate: "/api/templates/catalogs/:catalogTemplateId",
  apiDeleteClusterItemRequirementDocument: "/api/portfolios/:portfolioId/cluster-items/:clusterItemId/requirements/:requirementId/documents/:documentId",
  apiGetClusterItemRequirementDocument: "/api/portfolios/:portfolioId/cluster-items/:clusterItemId/requirements/:requirementId/documents/:documentId",
  apiDeleteClusterItemRequirement: "/api/portfolios/:portfolioId/cluster-items/:clusterItemId/requirements/:requirementId",
  apiGetClusterItemRequirement: "/api/portfolios/:portfolioId/cluster-items/:clusterItemId/requirements/:requirementId",
  apiPostUpdateClusterItemRequirement: "/api/portfolios/:portfolioId/cluster-items/:clusterItemId/requirements/:requirementId",
  apiDeleteCommitment: "/api/projects/:projectId/calculation-models/:calculationModelId/commitments/:commitmentId",
  apiGetCommitment: "/api/projects/:projectId/calculation-models/:calculationModelId/commitments/:commitmentId",
  apiPostUpdateCommitment: "/api/projects/:projectId/calculation-models/:calculationModelId/commitments/:commitmentId",
  apiDeleteCompanyBranch: "/api/companies/:companyId/branches/:companyBranchId",
  apiPostUpdateCompanyBranch: "/api/companies/:companyId/branches/:companyBranchId",
  apiDeleteCompany: "/api/companies/:companyId",
  apiGetTenantCompany: "/api/companies/:companyId",
  apiDeleteCompanyPerson: "/api/companies/:companyId/persons/:companyPersonId",
  apiPostUpdateCompanyPerson: "/api/companies/:companyId/persons/:companyPersonId",
  apiDeleteContractDocument: "/api/projects/:projectId/calculation-models/:calculationModelId/contracts/:contractId/documents/:documentId",
  apiGetContractDocument: "/api/projects/:projectId/calculation-models/:calculationModelId/contracts/:contractId/documents/:documentId",
  apiDeleteContract: "/api/projects/:projectId/calculation-models/:calculationModelId/contracts/:contractId",
  apiGetContract: "/api/projects/:projectId/calculation-models/:calculationModelId/contracts/:contractId",
  apiPostUpdateContract: "/api/projects/:projectId/calculation-models/:calculationModelId/contracts/:contractId",
  apiAdminDeleteContracts: "/api/admin/tenants/:tenantId/projects/:projectId/contracts/delete",
  apiDeleteContractTitleDocument: "/api/projects/:projectId/calculation-models/:calculationModelId/contracts/:contractId/contract-titles/:contractTitleId/documents/:documentId",
  apiGetContractTitleDocument: "/api/projects/:projectId/calculation-models/:calculationModelId/contracts/:contractId/contract-titles/:contractTitleId/documents/:documentId",
  apiDeleteContractTitle: "/api/projects/:projectId/calculation-models/:calculationModelId/contracts/:contractId/titles/:contractTitleId",
  apiPostUpdateContractTitle: "/api/projects/:projectId/calculation-models/:calculationModelId/contracts/:contractId/titles/:contractTitleId",
  apiDeleteContractPosition: "/api/projects/:projectId/calculation-models/:calculationModelId/contracts/:contractId/positions/:positionId",
  apiDeleteCostElementDocument: "/api/projects/:projectId/calculation-models/:calculationModelId/cost-elements/:costElementId/documents/:documentId",
  apiGetCostElementDocument: "/api/projects/:projectId/calculation-models/:calculationModelId/cost-elements/:costElementId/documents/:documentId",
  apiDeleteCostElement: "/api/projects/:projectId/calculation-models/:calculationModelId/cost-elements/:id",
  apiGetCostElement: "/api/projects/:projectId/calculation-models/:calculationModelId/cost-elements/:id",
  apiPostUpdateCostElement: "/api/projects/:projectId/calculation-models/:calculationModelId/cost-elements/:id",
  apiDeleteCustomDashboardWidget: "/api/projects/:projectId/custom-dashboards/:dashboardId/widgets/:widgetId",
  apiDeleteCustomListItem: "/api/default-values/:defaultValuesId/custom-lists/:customListId/items/:customListItemId",
  apiPostUpdateCustomListItem: "/api/default-values/:defaultValuesId/custom-lists/:customListId/items/:customListItemId",
  apiDeleteDatevApiAccess: "/api/tenant/delete-datev-api-access/:apiAccessId",
  apiDeleteDeliveryPhaseCatalog: "/api/delivery-phases-catalog/:id",
  apiGetDeliveryPhaseCatalog: "/api/delivery-phases-catalog/:id",
  apiDeleteEarningsElementDocument: "/api/projects/:projectId/calculation-models/:calculationModelId/earnings-elements/:earningsElementId/documents/:documentId",
  apiGetEarningsElementDocument: "/api/projects/:projectId/calculation-models/:calculationModelId/earnings-elements/:earningsElementId/documents/:documentId",
  apiDeleteEarningsElement: "/api/projects/:projectId/calculation-models/:calculationModelId/earnings-elements/:id",
  apiGetEarningsElement: "/api/projects/:projectId/calculation-models/:calculationModelId/earnings-elements/:id",
  apiPostUpdateEarningsElement: "/api/projects/:projectId/calculation-models/:calculationModelId/earnings-elements/:id",
  apiDeleteElementTimeline: "/api/projects/:projectId/calculation-models/:calculationModelId/element-timelines/:elementTimelineId",
  apiGetElementTimeline: "/api/projects/:projectId/calculation-models/:calculationModelId/element-timelines/:elementTimelineId",
  apiPostUpdateElementTimeline: "/api/projects/:projectId/calculation-models/:calculationModelId/element-timelines/:elementTimelineId",
  apiDeleteEuriborRateProjection: "/api/euribor-rate-projection/:id",
  apiPostUpdateEuriborRateProjection: "/api/euribor-rate-projection/:id",
  apiDeleteFinancingElementDocument: "/api/projects/:projectId/calculation-models/:calculationModelId/financing-elements/:financingElementId/documents/:documentId",
  apiGetFinancingElementDocument: "/api/projects/:projectId/calculation-models/:calculationModelId/financing-elements/:financingElementId/documents/:documentId",
  apiDeleteFinancingElement: "/api/projects/:projectId/calculation-models/:calculationModelId/financing-elements/:id",
  apiGetFinancingElement: "/api/projects/:projectId/calculation-models/:calculationModelId/financing-elements/:id",
  apiPostUpdateFinancingElement: "/api/projects/:projectId/calculation-models/:calculationModelId/financing-elements/:id",
  apiDeleteTenantInvite: "/api/tenant/delete-invite",
  apiDeleteInvoiceByContract: "/api/projects/:projectId/calculation-models/:calculationModelId/contracts/:contractId/invoices/:invoiceId",
  apiPostUpdateInvoiceByContract: "/api/projects/:projectId/calculation-models/:calculationModelId/contracts/:contractId/invoices/:invoiceId",
  apiDeleteInvoiceDocument: "/api/projects/:projectId/calculation-models/:calculationModelId/invoices/:invoiceId/documents/:documentId",
  apiGetInvoiceDocument: "/api/projects/:projectId/calculation-models/:calculationModelId/invoices/:invoiceId/documents/:documentId",
  apiDeleteInvoice: "/api/projects/:projectId/calculation-models/:calculationModelId/invoices/:invoiceId",
  apiGetInvoice: "/api/projects/:projectId/calculation-models/:calculationModelId/invoices/:invoiceId",
  apiPostUpdateInvoice: "/api/projects/:projectId/calculation-models/:calculationModelId/invoices/:invoiceId",
  apiDeleteInvoiceTitle: "/api/projects/:projectId/calculation-models/:calculationModelId/invoices/:invoiceId/titles/:invoiceTitleId",
  apiPostUpdateInvoiceTitle: "/api/projects/:projectId/calculation-models/:calculationModelId/invoices/:invoiceId/titles/:invoiceTitleId",
  apiAdminDeleteLicense: "/api/admin/licenses/:licenseId",
  apiAdminPostUpdateLicense: "/api/admin/licenses/:licenseId",
  apiAdminDeleteLicenseGrant: "/api/admin/tenants/:tenantId/licenses/grants/:licenseGrantId",
  apiAdminPostUpdateLicenseGrant: "/api/admin/tenants/:tenantId/licenses/grants/:licenseGrantId",
  apiDeletePlot: "/api/projects/:projectId/calculation-models/:calculationModelId/plots/:plotId",
  apiGetPlot: "/api/projects/:projectId/calculation-models/:calculationModelId/plots/:plotId",
  apiPostUpdatePlot: "/api/projects/:projectId/calculation-models/:calculationModelId/plots/:plotId",
  apiDeletePlotItemFile: "/api/projects/:projectId/calculation-models/:calculationModelId/plots/:plotItemId/file/:fileId",
  apiGetPlotItemFile: "/api/projects/:projectId/calculation-models/:calculationModelId/plots/:plotItemId/file/:fileId",
  apiDeleteProjectCalculationRule: "/api/projects/:projectId/invoice-calculation-schemes/:invoiceCalculationSchemeId/calculation-rule-groups/:calculationRuleGroupId/calculation-rules/:calculationRuleId",
  apiPostUpdateProjectCalculationRule: "/api/projects/:projectId/invoice-calculation-schemes/:invoiceCalculationSchemeId/calculation-rule-groups/:calculationRuleGroupId/calculation-rules/:calculationRuleId",
  apiDeleteProjectCalculationRuleGroup: "/api/projects/:projectId/invoice-calculation-schemes/:invoiceCalculationSchemeId/calculation-rule-groups/:calculationRuleGroupId",
  apiPostUpdateProjectCalculationRuleGroup: "/api/projects/:projectId/invoice-calculation-schemes/:invoiceCalculationSchemeId/calculation-rule-groups/:calculationRuleGroupId",
  apiDeleteProjectDatevProbisMapping: "/api/projects/:projectId/datev-probis-mapping/:mappingId",
  apiUpdateProjectDatevProbisMapping: "/api/projects/:projectId/datev-probis-mapping/:mappingId",
  apiDeleteProject: "/api/projects/:projectId",
  apiGetProject: "/api/projects/:projectId",
  apiDeleteTenantProjectInvite: "/api/tenant/delete-project-invite",
  apiDeleteProjectInvoiceCalculationScheme: "/api/projects/:projectId/invoice-calculation-schemes/:invoiceCalculationSchemeId",
  apiPostUpdateProjectInvoiceCalculationScheme: "/api/projects/:projectId/invoice-calculation-schemes/:invoiceCalculationSchemeId",
  apiDeleteProjectPermissionGroup: "/api/projects/:projectId/permission-groups/:permissionGroupId",
  apiPostUpdateProjectPermissionGroup: "/api/projects/:projectId/permission-groups/:permissionGroupId",
  apiDeleteProjectSpv: "/api/projects/:projectId/spvs/:spvId",
  apiGetProjectSpv: "/api/projects/:projectId/spvs/:spvId",
  apiDeleteProjectTemplate: "/api/project-templates/:templateId",
  apiGetProjectTemplate: "/api/project-templates/:templateId",
  apiPutProjectTemplate: "/api/project-templates/:templateId",
  apiDeleteRentDealContact: "/api/projects/:projectId/rent/deals/:dealId/contacts/:contactId",
  apiPostUpdateRentDealContact: "/api/projects/:projectId/rent/deals/:dealId/contacts/:contactId",
  apiDeleteRentDealContractItem: "/api/projects/:projectId/rent/deals/:dealId/contractItems/:contractItemId",
  apiPostUpdateRentDealContractItem: "/api/projects/:projectId/rent/deals/:dealId/contractItems/:contractItemId",
  apiDeleteRentDealCostItem: "/api/projects/:projectId/rent/deals/:dealId/costItems/:costItemId",
  apiPostUpdateRentDealCostItem: "/api/projects/:projectId/rent/deals/:dealId/costItems/:costItemId",
  apiDeleteRentDealDocument: "/api/projects/:projectId/rent/deals/:dealId/documents/:documentId",
  apiGetRentDealDocument: "/api/projects/:projectId/rent/deals/:dealId/documents/:documentId",
  apiDeleteRentDeal: "/api/projects/:projectId/rent/deals/:dealId",
  apiPostUpdateRentDeal: "/api/projects/:projectId/rent/deals/:dealId",
  apiDeleteReportDashboard: "/api/projects/:projectId/report-dashboards/:dashboardId",
  apiGetReportDashboard: "/api/projects/:projectId/report-dashboards/:dashboardId",
  apiPostUpdateReportDashboard: "/api/projects/:projectId/report-dashboards/:dashboardId",
  apiDeleteReportDashboardWidget: "/api/projects/:projectId/report-dashboards/:dashboardId/widgets/:widgetId",
  apiDeleteRiskElementDocument: "/api/projects/:projectId/calculation-models/:calculationModelId/risk-elements/:riskElementId/documents/:documentId",
  apiGetRiskElementDocument: "/api/projects/:projectId/calculation-models/:calculationModelId/risk-elements/:riskElementId/documents/:documentId",
  apiDeleteRiskElement: "/api/projects/:projectId/calculation-models/:calculationModelId/risk-elements/:id",
  apiGetRiskElement: "/api/projects/:projectId/calculation-models/:calculationModelId/risk-elements/:id",
  apiPostUpdateRiskElement: "/api/projects/:projectId/calculation-models/:calculationModelId/risk-elements/:id",
  apiDeleteSellDealContact: "/api/projects/:projectId/sell/deals/:dealId/contacts/:contactId",
  apiPostUpdateSellDealContact: "/api/projects/:projectId/sell/deals/:dealId/contacts/:contactId",
  apiDeleteSellDealContractItem: "/api/projects/:projectId/sell/deals/:dealId/contractItems/:contractItemId",
  apiPostUpdateSellDealContractItem: "/api/projects/:projectId/sell/deals/:dealId/contractItems/:contractItemId",
  apiDeleteSellDealCostItem: "/api/projects/:projectId/sell/deals/:dealId/costItems/:costItemId",
  apiPostUpdateSellDealCostItem: "/api/projects/:projectId/sell/deals/:dealId/costItems/:costItemId",
  apiDeleteSellDealDocument: "/api/projects/:projectId/sell/deals/:dealId/documents/:documentId",
  apiGetSellDealDocument: "/api/projects/:projectId/sell/deals/:dealId/documents/:documentId",
  apiDeleteSellDeal: "/api/projects/:projectId/sell/deals/:dealId",
  apiPostUpdateSellDeal: "/api/projects/:projectId/sell/deals/:dealId",
  apiDeleteTaxonomy: "/api/projects/:projectId/calculation-models/:calculationModelId/taxonomy/:taxonomyItemId",
  apiPostUpdateTaxonomy: "/api/projects/:projectId/calculation-models/:calculationModelId/taxonomy/:taxonomyItemId",
  apiDeleteTaxonomyItemFile: "/api/projects/:projectId/calculation-models/:calculationModelId/taxonomy/:taxonomyItemId/file/:fileId",
  apiGetTaxonomyItemFile: "/api/projects/:projectId/calculation-models/:calculationModelId/taxonomy/:taxonomyItemId/file/:fileId",
  apiDeleteTemplateScenario: "/api/project-templates/:templateId/scenarios/:scenarioId",
  apiPutTemplateScenario: "/api/project-templates/:templateId/scenarios/:scenarioId",
  apiDeleteTenantCalculationRule: "/api/invoice-calculation-schemes/:invoiceCalculationSchemeId/calculation-rule-groups/:calculationRuleGroupId/calculation-rules/:calculationRuleId",
  apiPostUpdateTenantCalculationRule: "/api/invoice-calculation-schemes/:invoiceCalculationSchemeId/calculation-rule-groups/:calculationRuleGroupId/calculation-rules/:calculationRuleId",
  apiDeleteTenantCalculationRuleGroup: "/api/invoice-calculation-schemes/:invoiceCalculationSchemeId/calculation-rule-groups/:calculationRuleGroupId",
  apiPostUpdateTenantCalculationRuleGroup: "/api/invoice-calculation-schemes/:invoiceCalculationSchemeId/calculation-rule-groups/:calculationRuleGroupId",
  apiDeleteTenantDatevProbisMapping: "/api/tenant/datev-access/:datevApiAccessId/datev-probis-mapping/:mappingId",
  apiUpdateTenantDatevProbisMapping: "/api/tenant/datev-access/:datevApiAccessId/datev-probis-mapping/:mappingId",
  apiDeleteTenantInvoiceCalculationScheme: "/api/invoice-calculation-schemes/:invoiceCalculationSchemeId",
  apiPostUpdateTenantInvoiceCalculationScheme: "/api/invoice-calculation-schemes/:invoiceCalculationSchemeId",
  apiDeleteTenantUserDefinedFieldDefinition: "/api/tenant/user-defined-fields-definition/:userDefinedFieldDefinitionId",
  apiPostUpdateTenantUserDefinedFieldDefinition: "/api/tenant/user-defined-fields-definition/:userDefinedFieldDefinitionId",
  apiDeleteYardiProbisProjectMapping: "/api/projects/:projectId/yardi-probis-mapping/:mappingId",
  apiUpdateYardiProbisProjectMapping: "/api/projects/:projectId/yardi-probis-mapping/:mappingId",
  apiGetDetailReport: "/api/projects/:projectId/calculation-models/:calculationModelId/reporting/detail",
  apiPostTriggerJob: "/api/job/trigger",
  apiPostDuplicateTaxonomy: "/api/projects/:projectId/calculation-models/:calculationModelId/taxonomy/:taxonomyItemId/duplicate",
  apiGetImportEarningsElementFileTemplate: "/api/projects/:projectId/import/templates/earnings-elements",
  apiAdminPostEditSubdomain: "/api/admin/tenant/subdomain",
  apiGetErrorCodes: "/api/development/errorcodes",
  apiGetCalculationModelExcel: "/api/projects/:projectId/calculation-models/:calculationModelId/excel",
  apiExportInvoicesToDatev: "/api/projects/:projectId/calculation-models/:calculationModelId/export-invoices-to-datev",
  apiPostFetchDialogs: "/api/docuware/dialogs",
  apiPostFetchFields: "/api/docuware/fields",
  apiGetFetchFields: "/api/docuware/fields",
  apiPostFetchOrganizations: "/api/docuware/organizations",
  apiGetImportFileTemplate: "/api/import/templates",
  apiPostFinancingSourcesReport: "/api/projects/:projectId/calculation-models/:calculationModelId/reporting/financing-sources",
  apiGetForecastReport: "/api/projects/:projectId/calculation-models/:calculationModelId/reporting/forecast",
  apiGetForecastExcelReport: "/api/projects/:projectId/calculation-models/:calculationModelId/reporting/forecast/excel",
  apiPostForgotPassword: "/api/auth/forgot-password",
  apiPostGenerateNextProjectObjectCode: "/api/projects/:projectId/calculation-models/:calculationModelId/codes/generate",
  apiPostGenerateNextTenantObjectCode: "/api/codes/generate",
  apiGetDatevActiveClients: "/api/tenant/datev/active-clients",
  apiGetAiEvalDocuments: "/api/projects/:projectId/aievaldocument",
  apiPostUploadAiEvalDocument: "/api/projects/:projectId/aievaldocument",
  apiAdminGetSignalRGroups: "/api/admin/signalr",
  apiGetAllTasks: "/api/projects/:projectId/calculation-models/:calculationModelId/tasks",
  apiGetAssemblyVersion: "/api/assembly-version",
  apiGetAuditLogsByKey: "/api/projects/:projectId/audit-logs/:key",
  apiGetAuditLogsByTarget: "/api/projects/:projectId/audit-logs",
  apiGetAuthPermissionDefinitions: "/api/auth/permissionDefinitions",
  apiGetAuthProjectGroups: "/api/projects/:projectId/auth/groups",
  apiGetAuthSubjects: "/api/auth/subjects",
  apiPostCalculateAutomaticTimelineDistribution: "/api/projects/:projectId/calculation-models/:calculationModelId/distribution/automatic",
  apiGetAvailableCatalogsForMapping: "/api/catalogs/:type/:catalogId",
  apiGetBenchmarkProjects: "/api/projects/:projectId/benchmark/catalogs/:catalogType/:catalogId/elements/:catalogElementId",
  apiGetBenchmarkReport: "/api/reporting/benchmark/report/:reportConfigurationId",
  apiGetCalculationModelCashFlowSettings: "/api/projects/:projectId/calculation-models/:calculationModelId/cashflow-settings",
  apiPostUpdateCalculationModelCashFlowSettings: "/api/projects/:projectId/calculation-models/:calculationModelId/cashflow-settings",
  apiGetCalculationModelCosts: "/api/projects/:projectId/calculation-models/:calculationModelId/Costs",
  apiGetCalculationModelCostsFiltered: "/api/projects/:projectId/calculation-models/:calculationModelId/costs/filtered",
  apiGetCalculationModelEarnings: "/api/projects/:projectId/calculation-models/:calculationModelId/Earnings",
  apiGetCalculationModelRisks: "/api/projects/:projectId/calculation-models/:calculationModelId/Risks",
  apiGetCalculationModelFinancing: "/api/projects/:projectId/calculation-models/:calculationModelId/Financing",
  apiGetCalculationModelRestBudget: "/api/projects/:projectId/calculation-models/:calculationModelId/Restbudget",
  apiGetCalculationModelExport: "/api/projects/:projectId/calculation-models/:calculationModelId/export",
  apiGetCalculationModelHistory: "/api/projects/:projectId/calculation-models/:calculationModelId/history",
  apiGetCalculationModelXIrr: "/api/projects/:projectId/calculation-models/:calculationModelId/xirr",
  apiGetCatalogElementCode: "/api/catalogs/code",
  apiGetCatalogElementsAvailableForMapping: "/api/catalogs/element/:type/:mappingId",
  apiGetClusterItems: "/api/portfolios/:portfolioId/cluster-items",
  apiGetClusterItemsTree: "/api/portfolios/:portfolioId/cluster-items/tree",
  apiGetCommitmentDocument: "/api/projects/:projectId/calculation-models/:calculationModelId/commitments/:commitmentId/documents/:documentId",
  apiGetCompanyLogo: "/api/companies/:companyId/logo/:fileId",
  apiGetProjectCompanyPerson: "/api/projects/:projectId/company-persons/:personId",
  apiGetContractTitleWorkflowDetail: "/api/projects/:projectId/calculation-models/:calculationModelId/contracts/contract-titles/:contractTitleId/workflow-detail",
  apiGetContractWorkflowDetail: "/api/projects/:projectId/calculation-models/:calculationModelId/contracts/:contractId/workflow-detail",
  apiGetCatalog: "/api/cost-catalogs/:id",
  apiGetCatalogs: "/api/cost-catalogs",
  apiGetCostElementsDistribution: "/api/projects/:projectId/calculation-models/:calculationModelId/cost-elements/monthly-distribution",
  apiGetCrossTenantContracts: "/api/cross-tenant/:crossTenantId/projects/:crossTenantProjectId/contracts",
  apiGetCrossTenantProjectCover: "/api/cross-tenant/:crossTenantId/projects/:crossTenantProjectId/cover/:imageId",
  apiGetCustomBenchmarkProperties: "/api/default-values/custom-benchmark-properties",
  apiGetDatevApiAccess: "/api/tenant/datev/api-access/:apiAccessId",
  apiGetDatevApiAccesses: "/api/tenant/datev-api/:datevApiId/accesses",
  apiGetDatevCache: "/api/tenant/datev-cache/:key",
  apiUpdateDatevCache: "/api/tenant/datev-cache/:key",
  apiGetDatevClient: "/api/tenant/datev/client",
  apiGetDatevClients: "/api/tenant/datev/:datevApiAccessId/clients",
  apiGetDefaultValues: "/api/default-values",
  apiGetAiEvalDocumentFile: "/api/projects/:projectId/aievaldocument/:documentId/file",
  apiPostReprocessAiEvalDocument: "/api/projects/:projectId/aievaldocument/:documentId/file",
  apiGetDocuWareAccess: "/api/docuware/:accessId",
  apiGetEarningInflationDistributionSuggestion: "/api/projects/:projectId/calculation-models/:calculationModelId/earnings-elements/inflation-suggestion",
  apiGetEarningsCatalog: "/api/earnings-catalogs/:id",
  apiGetEarningsCatalogs: "/api/earnings-catalogs",
  apiGetEffectiveTimelineDistribution: "/api/projects/:projectId/calculation-models/:calculationModelId/distribution/effective",
  apiGetEmissionYearlyDistributions: "/api/projects/:projectId/calculation-models/:calculationModelId/emission-yearly-distributions",
  apiGetEuriborRates: "/api/euribor-rates",
  apiGetFinancingCatalog: "/api/financing-catalog",
  apiGetFinancingCatalogs: "/api/financing-catalogs",
  apiGetFinancingElementsDistributionSuggestion: "/api/projects/:projectId/calculation-models/:calculationModelId/financing-elements/monthly-distribution-suggestion",
  apiGetFinancingElementsMetadata: "/api/projects/:projectId/calculation-models/:calculationModelId/financing-elements-metadata",
  apiGetFinancingPurposeOverview: "/api/projects/:projectId/calculation-models/:calculationModelId/financing-purpose/monthly-overview",
  apiGetInflationDistributionSuggestion: "/api/projects/:projectId/calculation-models/:calculationModelId/cost-elements/inflation-suggestion",
  apiGetUserInvitation: "/api/user/invitation/:tokenId",
  apiGetTenantInvite: "/api/tenant/invite/:token",
  apiGetInvoiceBudgetOverrunWarning: "/api/projects/:projectId/calculation-models/:calculationModelId/invoice/:invoiceId/budget-overrun-warning",
  apiGetInvoiceCalculationSample: "/api/invoice-calculation-schemes/:invoiceCalculationSchemeId/sample",
  apiGetInvoiceRecipientSelectCompanyBranches: "/api/projects/:projectId/invoice-recipient/companies/branches/select",
  apiGetInvoiceReport: "/api/projects/:projectId/calculation-models/:calculationModelId/invoices/:invoiceId/report",
  apiAdminGetInvoices: "/api/admin/tenants/:tenantId/projects/:projectId/calculation-models/:calculationModelId/invoices",
  apiGetInvoiceWorkflowDetail: "/api/projects/:projectId/calculation-models/:calculationModelId/invoices/:invoiceId/workflow-detail",
  apiGetJobRouterPdf: "/api/projects/:projectId/calculation-models/:calculationModelId/job_router/:invoiceId",
  apiGetLicenseFeatures: "/api/licenses/features",
  apiAdminGetLicenseFeatures: "/api/admin/licenses/features",
  apiGetLicenseGrants: "/api/licenses/grants",
  apiAdminGetLicenseGrants: "/api/admin/licenses/grants/:tenantId",
  apiGetPasswordResetToken: "/api/user/password-reset/:tokenId",
  apiGetPlainAuthGroup: "/api/auth/groups/plain",
  apiGetPlainAuthRoles: "/api/auth/roles/plain",
  apiGetPlainAuthSubjects: "/api/auth/subjects/plain",
  apiGetPlotItemThumbnail: "/api/projects/:projectId/calculation-models/:calculationModelId/plots/:plotItemId/thumbnail/:thumbnailId",
  apiGetPortfolio: "/api/portfolios/:portfolioId",
  apiGetPortfolioNextRequirementCode: "/api/portfolios/:portfolioId/code",
  apiGetPortfolios: "/api/portfolios",
  apiGetProjectCompanies: "/api/projects/:projectId/companies",
  apiGetProjectCover: "/api/projects/:projectId/cover/:imageId/:imageSize",
  apiGetProjectDashboard: "/api/projects/:projectId/calculation-models/:calculationModelId/dashboard",
  apiGetProjectDatevConnection: "/api/projects/:projectId/datev-connection",
  apiAdminGetProject: "/api/admin/project/:tenantId/:projectId",
  apiGetAdminProject: "/api/admin/tenant/:tenantId/projects/:projectId",
  apiGetProjectGroupCover: "/api/projectgroups/:groupId/cover/:imageId/:size",
  apiGetProjectGroup: "/api/projectgroups/:groupId",
  apiDeleteProjectGroup: "/api/projectgroups/:groupId",
  apiGetProjectReadableCostCatalog: "/api/projects/:projectId/cost-catalogs/:id/readable",
  apiGetProjectReadableEarningsCatalog: "/api/projects/:projectId/earnings-catalogs/:id/readable",
  apiGetProjectReadableFinancingCatalog: "/api/projects/:projectId/financing-catalogs/:id/readable",
  apiGetProjectReadableRiskCatalog: "/api/projects/:projectId/risk-catalogs/:id/readable",
  apiGetProjectSelectCompanyBranches: "/api/projects/:projectId/companies/branches/select",
  apiGetAdminProjects: "/api/admin/tenant/:tenantId/projects",
  apiProjectGetUserDefinedFieldsDefinitionByElement: "/api/projects/:projectId/user-defined-fields-definition/elements",
  apiProjectGetUserDefinedFieldsDefinition: "/api/projects/:projectId/user-defined-fields-definition",
  apiGetProjectTemplateCatalogs: "/api/project-templates/catalogs",
  apiGetProjectWritableCostCatalog: "/api/projects/:projectId/cost-catalogs/:id/writable",
  apiGetProjectWritableEarningsCatalog: "/api/projects/:projectId/earnings-catalogs/:id/writable",
  apiGetProjectWritableFinancingCatalog: "/api/projects/:projectId/financing-catalogs/:id/writable",
  apiGetProjectWritableRiskCatalog: "/api/projects/:projectId/risk-catalogs/:id/writable",
  apiGetProjectYardiConnection: "/api/projects/:projectId/yardi-connection",
  apiPostCalculateRecurringPaymentDistribution: "/api/projects/:projectId/calculation-models/:calculationModelId/distribution/recurringPayments",
  apiGetDatevCients: "/datev/clients/refresh",
  apiGetRegionEmissionFactorsByRegion: "/api/region-emission-factors",
  apiGetReportDashboardsSummary: "/api/projects/:projectId/report-dashboards/summary",
  apiGetRiskCatalog: "/api/risk-catalogs/:id",
  apiGetRiskCatalogs: "/api/risk-catalogs",
  apiGetSelectableCommitments: "/api/projects/:projectId/calculation-models/:calculationModelId/commitments/select",
  apiGetSelectableContracts: "/api/projects/:projectId/calculation-models/:calculationModelId/contracts/select",
  apiGetSelectableInvoices: "/api/projects/:projectId/calculation-models/:calculationModelId/invoices/select",
  apiGetSelectableProjects: "/api/projects/select",
  apiGetSelectableProjectTemplates: "/api/selectable-project-templates",
  apiGetRentDealAvailableTaxonomy: "/api/projects/:projectId/rent/deals/:dealId/availableTaxonomy",
  apiGetSellDealAvailableTaxonomy: "/api/projects/:projectId/sell/deals/:dealId/availableTaxonomy",
  apiGetTaxonomyItemThumbnail: "/api/projects/:projectId/calculation-models/:calculationModelId/taxonomy/:taxonomyItemId/thumbnail/:thumbnailId",
  apiGetTaxonomyWithCostItems: "/api/projects/:projectId/taxonomy/withCostItems",
  apiAdminGetTenantAuthProjects: "/api/admin/tenant/:tenantId/auth/projects",
  apiAdminGetTenantAuthSubjects: "/api/admin/tenant/:tenantId/auth/subjects",
  apiGetTenantCatalogs: "/api/catalogs",
  apiGetTenantCompanies: "/api/companies",
  apiGetTenantCompaniesPaged: "/api/companies/paged",
  apiAdminGetTenants: "/api/admin/tenants",
  apiGetTenantTrusts: "/api/tenant/trusts",
  apiGetTransmittedInvoicesStatus: "/api/projects/:projectId/calculation-models/:calculationModelId/transmitted-invoices/status",
  apiGetUserDefinedFieldsDefinitionByElement: "/api/tenant/user-defined-fields-definition/elements",
  apiGetUserTenants: "/api/user/tenants",
  apiGetUserWorkflowTasks: "/api/projects/:projectId/calculation-models/:calculationModelId/user-tasks",
  apiPostEarningsElementValuation: "/api/projects/:projectId/calculation-models/:calculationModelId/earnings-elements/valuation",
  apiGetVatReturnDistributionSuggestion: "/api/projects/:projectId/calculation-models/:calculationModelId/financing-elements/vat-return-suggestion",
  apiGetWorkflows: "/api/workflows",
  apiAdminGetTenantWorkflows: "/api/admin/elsa/workflows/tenant/:tenantId",
  apiGetYardiApiAccess: "/api/yardi-access/:accessId",
  apiGetYardiExportInvoiceEndpoint: "/api/projects/:projectId/calculation-models/:calculationModelId/export-invoices-to-yardi",
  apiPostImportTenantDatevActiveApiMapping: "/api/projects/:projectId/datev-active-api-mapping/import",
  apiPostCatalogImportFile: "/api/catalog/parse",
  apiPostCatalogImportData: "/api/catalog/import",
  apiPostImportContractFile: "/api/projects/:projectId/calculation-models/:calculationModelId/contracts/import",
  apiPostImportCostElementsFile: "/api/projects/:projectId/calculation-models/:calculationModelId/cost-elements/import",
  apiPostImportEarningsElementsFile: "/api/projects/:projectId/calculation-models/:calculationModelId/earnings-elements/import",
  apiPostImportGaebData: "/api/projects/:projectId/calculation-models/:calculationModelId/contracts/:contractId/import/gaeb",
  apiPostImportInvoiceFile: "/api/projects/:projectId/calculation-models/:calculationModelId/invoices/import",
  apiPostCompaniesImportFile: "/api/companies/parse",
  apiPostCompanyImportData: "/api/companies/import",
  apiPostImportYardiApiMappingInProject: "/api/projects/:projectId/yardi-api-mapping/import",
  apiPostImportYardiInvoices: "/api/projects/:projectId/calculation-models/:calculationModelId/yardi/import-invoices",
  apiPostInitializeTaxonomy: "/api/projects/:projectId/calculation-models/:calculationModelId/taxonomy/init",
  apiPostInviteSetEnabled: "/api/tenant/invite/set-enabled",
  apiPostInviteSignup: "/api/tenant/invite/signup",
  apiPostCreateUserInvitation: "/api/user/invitation",
  apiGetImportInvoiceFileTemplate: "/api/projects/:projectId/calculation-models/:calculationModelId/import/templates/invoices",
  apiGetInvoicesReport: "/api/projects/:projectId/calculation-models/:calculationModelId/reporting/invoices",
  apiPostLockFinancingElement: "/api/projects/:projectId/calculation-models/:calculationModelId/financing-elements/:id/lock",
  apiGetChallenge: "/api/auth/challenge",
  apiPostLogin: "/api/auth/login",
  apiGetExchange: "/api/auth/oidc/exchange/:realm",
  apiPostLogoutSessions: "/api/auth/logoutSessions",
  apiPostLogout: "/api/auth/logout",
  apiPostMoveBudgetLevel: "/api/projects/:projectId/calculation-models/:calculationModelId/movebudgetlevel",
  apiMoveCatalogElement: "/api/catalog/element/move",
  apiPostMoveProjectCalculationRule: "/api/projects/:projectId/invoice-calculation-schemes/:invoiceCalculationSchemeId/calculation-rule-groups/:calculationRuleGroupId/calculation-rules/:calculationRuleId/move",
  apiPostMoveProjectCalculationRuleGroup: "/api/projects/:projectId/invoice-calculation-schemes/:invoiceCalculationSchemeId/calculation-rule-groups/:calculationRuleGroupId/move",
  apiPostMoveTaxonomy: "/api/projects/:projectId/calculation-models/:calculationModelId/taxonomy/:taxonomyItemId/move",
  apiPostMoveTenantCalculationRule: "/api/invoice-calculation-schemes/:invoiceCalculationSchemeId/calculation-rule-groups/:calculationRuleGroupId/calculation-rules/:calculationRuleId/move",
  apiPostMoveTenantCalculationRuleGroup: "/api/invoice-calculation-schemes/:invoiceCalculationSchemeId/calculation-rule-groups/:calculationRuleGroupId/move",
  apiPostParseContractFile: "/api/projects/:projectId/calculation-models/:calculationModelId/contracts/parse",
  apiPostParseCostElementFile: "/api/projects/:projectId/calculation-models/:calculationModelId/cost-elements/parse",
  apiPostParseEarningsElementFile: "/api/projects/:projectId/calculation-models/:calculationModelId/earnings-elements/parse",
  apiPostParseGaebFile: "/api/projects/:projectId/calculation-models/:calculationModelId/contracts/:contractId/parse/gaeb",
  apiPostParseInvoiceFile: "/api/projects/:projectId/calculation-models/:calculationModelId/invoices/parse",
  apiPostParseYardiInvoiceFile: "/api/projects/:projectId/calculation-models/:calculationModelId/yardi/parse-invoice-file",
  apiPostPayContract: "/api/projects/:projectId/calculation-models/:calculationModelId/contracts/:contractId/pay",
  apiPostPayInvoice: "/api/projects/:projectId/calculation-models/:calculationModelId/invoices/:invoiceId/pay",
  apiPostHtmlToPdfExport: "/api/html2pdf",
  apiPostHtmlToImageExport: "/api/html2image",
  apiDatevClientCallBack: "/api/tenant/client/datev/callback",
  apiDatevTenantCallBack: "/api/tenant/datev/callback",
  apiPostProjectGroupCover: "/api/projectgroups/:groupId/cover",
  apiDeleteProjectGroupCover: "/api/projectgroups/:groupId/cover",
  apiPostGroupProjects: "/api/projectgroups/projects",
  apiDeleteProjectsFromGroup: "/api/projectgroups/projects",
  apiPostCreateProjectGroup: "/api/projectgroups",
  apiPatchProjectGroup: "/api/projectgroups",
  apiPostQuickCreateCompany: "/api/companies/company/quick",
  apiPostRefreshToken: "/api/auth/refresh-token",
  apiRemoveBranchFormCompanyPerson: "/api/companies/:companyId/persons/:companyPersonId/removeBranch",
  apiPostReopenContract: "/api/projects/:projectId/calculation-models/:calculationModelId/contracts/:contractId/reopen",
  apiPostReopenInvoice: "/api/projects/:projectId/calculation-models/:calculationModelId/invoices/:invoiceId/reopen",
  apiPostReopenRentDeal: "/api/projects/:projectId/rent/deals/:dealId/reopen",
  apiPostReopenSellDeal: "/api/projects/:projectId/sell/deals/:dealId/reopen",
  apiGetReportDashboardDataSource: "/api/projects/:projectId/calculation-models/:calculationModelId/datasource/dashboards/:dataSourceId",
  apiAdminGetReportDashboardDataSource: "/api/admin/reports/datasource/dashboards/:dataSourceId/:tenantId/:projectId/:calcModelId/:subdomain",
  apiGetReportDataSources: "/api/reports/datasources",
  apiAdminGetReportDataSources: "/api/admin/reports/datasources",
  apiGetReportExportDataSource: "/api/projects/:projectId/calculation-models/:calculationModelId/datasource/exports/:dataSourceId",
  apiAdminGetReportExportDataSource: "/api/admin/reports/datasource/exports/:dataSourceId/:tenantId/:projectId/:calcModelId/:subdomain",
  apiGetReportGenericDataSource: "/api/projects/:projectId/calculation-models/:calculationModelId/datasource/generics/:dataSourceId",
  apiAdminGetReportGenericDataSource: "/api/admin/reports/datasource/generics/:dataSourceId/:tenantId/:projectId/:calcModelId/:subdomain",
  apiGetReportInvoiceCoverSheetDataSource: "/api/projects/:projectId/calculation-models/:calculationModelId/datasource/invoice-cover-sheets/:dataSourceId",
  apiAdminGetReportInvoiceCoverSheetDataSource: "/api/admin/reports/datasource/invoice-cover-sheets/:dataSourceId/:tenantId/:projectId/:calcModelId/:invoiceId/:subdomain",
  apiGetReport: "/api/reports/:reportId",
  apiGetSystemReports: "/api/reports/system/:type",
  apiGetTenantReports: "/api/reports/tenant/:type",
  apiAdminGetReport: "/api/admin/reports/:reportId",
  apiAdminDeleteReport: "/api/admin/reports/:reportId",
  apiAdminGetSystemReports: "/api/admin/reports/system",
  apiAdminGetTenantReports: "/api/admin/reports/tenant",
  apiAdminPostCreateReport: "/api/admin/reports",
  apiAdminPostUpdateReportMetaData: "/api/admin/reports/metadata",
  apiAdminPostUpdateReportData: "/api/admin/reports/:reportId/reportdata",
  apiAdminExportReport: "/api/admin/reports/:reportId/export",
  apiAdminPostReprocessCoverImages: "/api/admin/reprocess-cover-images",
  apiPostResetPassword: "/api/auth/reset-password",
  apiDatevClientTokenRevoke: "/api/tenant/datev_client/:clientId/token/revoke",
  apiDatevTenantTokenRevoke: "/api/tenant/datev-access/:datevApiAccessId/token/revoke",
  apiPostSearch: "/api/search",
  apiAdminPostSignalRNotification: "/api/admin/signalr/notification",
  apiGetSentrySentry: "/api/sentry/sentry",
  apiGetSentryException: "/api/sentry/exception",
  apiPostSettleContract: "/api/projects/:projectId/calculation-models/:calculationModelId/contracts/:contractId/settle",
  apiGetSignalRCodes: "/api/development/signalr-codes",
  apiStartContractWorkflow: "/api/workflows/start-contract",
  apiPostStartDocuWareProcess: "/api/docuware/start",
  apiStartInvoiceWorkflow: "/api/workflows/start-invoice",
  apiStartSupplementWorkflow: "/api/workflows/start-supplement",
  apiPostTakeoverInvoiceByContract: "/api/projects/:projectId/calculation-models/:calculationModelId/contracts/:contractId/:originalContractId/invoices/:invoiceId/takeover",
  apiPostUpdateTenantBillingAddress: "/api/tenant/billing-address",
  apiAdminGetTenantIdpSettings: "/api/admin/tenant/:tenantId/idp",
  apiAdminPostCreateOrUpdateIdpSettings: "/api/admin/tenant/:tenantId/idp/credentials",
  apiAdminPostBulkInvite: "/api/admin/tenant/:tenantId/idp/bulk-invite",
  apiPostCreateTenantAvatar: "/api/tenant/avatar",
  apiDeleteTenantAvatar: "/api/tenant/avatar",
  apiGetTenantAvatar: "/api/tenant/avatar",
  apiPostInviteTenant: "/api/tenant/invite",
  apiPostUpdateTenantNames: "/api/tenant/names",
  apiPostUpdateTenantOwner: "/api/tenant/owner",
  apiPostReinviteTenant: "/api/tenant/reinvite",
  apiPostTestApiAccess: "/api/tenant/externalapis/test",
  apiAdminPostToggleMailService: "/api/admin/tenant/email-service",
  apiPostTriggerEmailDocumentImport: "/api/email/document/trigger-import",
  apiPostUnlockFinancingElement: "/api/projects/:projectId/calculation-models/:calculationModelId/financing-elements/:id/unlock",
  apiPostUpdateAiEvalDocumentInvoiceMetadata: "/api/projects/:projectId/aievaldocument/:documentId/invoice-metadata",
  apiPostUpdateAssignableWorkflowActivities: "/api/projects/:projectId/workflows/assignable-activities/:assignableActivityId",
  apiPostUpdateAuthSubjectMembership: "/api/auth/subjects/:subjectId/membership",
  apiPostUpdateAuthSubject: "/api/auth/subjects/:subjectId",
  apiPostUpdateAutomaticWorkflowFlag: "/api/projects/:projectId/workflows/update-automatic-workflow-flag",
  apiPostUpdateCaluclationModelFavorite: "/api/projects/:projectId/calculation-models/:calculationModelId/favorite",
  apiPostUpdateCalculationModelKpis: "/api/projects/:projectId/calculation-models/:calculationModelId/kpis",
  apiPostUpdateCalculationModelMetadata: "/api/projects/:projectId/calculation-models/:calculationModelId/metadata",
  apiPostUpdateClusterItemRequirementStatus: "/api/portfolios/:portfolioId/cluster-items/:clusterItemId/requirements/:requirementId/state",
  apiPostUpdateCompany: "/api/companies/company/:companyId",
  apiPostUpdatePrivatePerson: "/api/companies/privateperson/:personId",
  apiPostUpdateCustomDashboard: "/api/projects/:projectId/custom-dashboards/:dashboardId",
  apiUpdateDatevAccountingMonth: "/api/projects/:projectId/datev-accounting-month",
  apiUpdateDatevApiAccess: "/api/tenant/update-datev-access/:apiAccessId",
  apiPostUpdateDefaultValues: "/api/default-values/:defaultValuesId",
  apiPostUpdateDocuWareAccess: "/api/docuware/:apiId/:accessId",
  apiPostUpdateInflationCostElement: "/api/projects/:projectId/calculation-models/:calculationModelId/cost-elements/inflation/:id",
  apiPostUpdateInflationEarningElement: "/api/projects/:projectId/calculation-models/:calculationModelId/earnings-elements/inflation/:id",
  apiPostUpdateInvoiceCalculationRule: "/api/projects/:projectId/calculation-models/:calculationModelId/invoices/:invoiceId/calculation-rule-groups/:calculationRuleGroupId/calculation-rules/:calculationRuleId",
  apiPostUpdateInvoiceTitleByContractTitle: "/api/projects/:projectId/calculation-models/:calculationModelId/contracts/:contractId/invoices/:invoiceId/contract-titles/:contractTitleId",
  apiAdminUpdateKlippaConfigurations: "/api/admin/klippa-configurations/:configId",
  apiPostUpdateLicenseGrant: "/api/licenses/grants/:licenseGrantId",
  apiPostUpdateLocalLawCostElement: "/api/projects/:projectId/calculation-models/:calculationModelId/cost-elements/local-law/:id",
  postApiDataUpdateProjectById: "/api/data/updateProject/:id",
  apiUpdateProjectDatevClient: "/api/projects/:projectId/datev-client",
  apiPostUpdateProjectMetadata: "/api/projects/:projectId/metadata",
  apiPostUpdateRentDealCondition: "/api/projects/:projectId/rent/deals/:dealId/rentDealConditions",
  apiUpdateTenantDatevClient: "/api/tenant/datev-access/:datevApiAccessId/datev-client",
  apiPostUpdateVatElement: "/api/projects/:projectId/calculation-models/:calculationModelId/cost-elements/vat/:id",
  apiPostUpdateWorkflowConfigurations: "/api/projects/:projectId/workflows/configurations",
  apiPostUploadPlotFile: "/api/projects/:projectId/calculation-models/:calculationModelId/plots/:plotItemId/file",
  apiPostUploadProjectCover: "/api/projects/:projectId/cover/upload",
  apiPostUploadTaxonomyFile: "/api/projects/:projectId/calculation-models/:calculationModelId/taxonomy/:taxonomyItemId/file",
  apiPostCreateUserAvatar: "/api/user/avatar",
  apiDeleteUserAvatar: "/api/user/avatar/remove",
  apiGetUserAvatar: "/api/user/:userId/avatar",
  apiPostValidateIban: "/api/validate/iban",
  apiGetWaterfallBudgetReport: "/api/projects/:projectId/reporting/waterfall-budget"
};