import {
  CatalogMappingReadModel,
  CostCatalogElementReadModel,
  EarningsCatalogElementReadModel,
  FinancingCatalogElementReadModel,
  RiskCatalogElementReadModel,
  TenantCatalogReadModel,
  TenantCatalogType,
  useApiGetCustomBenchmarkPropertiesQuery
} from '@client/shared/api';
import {
  CatalogElementDeleteModal,
  CatalogElementEditSlideOver,
  CatalogCreateMappingSlideOver,
  CatalogElementEditMappingSlideOver, CatalogDeleteMappingModal
} from '.';
import { CatalogHeader, CatalogSection, CatalogSlideOverRow } from '..';
import { BaseSelect, BaseSelectOption, Modal, SlideOver, TrashIcon } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { CatalogElementCreateSlideOver } from './CatalogElementCreateSlideOver';

export type CatalogEditingRowsProps = {
  catalog: TenantCatalogReadModel;
  elements:
    | CostCatalogElementReadModel[]
    | FinancingCatalogElementReadModel[]
    | RiskCatalogElementReadModel[]
    | EarningsCatalogElementReadModel[];
  mappings: CatalogMappingReadModel[];
  title: string;
  icon: ReactNode;
  bgColor: string;
  type: TenantCatalogType;
  showCreateMapping: boolean;
  setShowCreateMapping: (show: boolean) => void;
  selectedMappedCatalog: string;
  setSelectedMappedCatalog: (selectedMappedCatalog: string) => void;
};
export const CatalogEditingRows = (props: CatalogEditingRowsProps) => {
  const {
    title,
    icon,
    bgColor,
    catalog,
    elements,
    mappings,
    type,
    selectedMappedCatalog,
    setSelectedMappedCatalog,
    showCreateMapping,
    setShowCreateMapping,
  } = props;
  const { t } = useTranslation();
  const [selectedElement, setSelectedElement] = useState<
    | CostCatalogElementReadModel
    | FinancingCatalogElementReadModel
    | RiskCatalogElementReadModel
    | EarningsCatalogElementReadModel
    | null
  >(null);
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showEditMapping, setShowEditMapping] = useState(false);
  const [showMappingDelete, setShowMappingDelete] = useState(false);
  const [parentElement, setParentElement] = useState<
    | CostCatalogElementReadModel
    | FinancingCatalogElementReadModel
    | RiskCatalogElementReadModel
    | EarningsCatalogElementReadModel
    | null
  >(null);

  const {data: benchmarkProperties} = useApiGetCustomBenchmarkPropertiesQuery(undefined, { skip : catalog.type === 'Finance'})

  const mappedCatalogs: BaseSelectOption[] = useMemo(() => {
    return mappings.map((fetchedCatalog) => {
      return {
        label: fetchedCatalog.targetName,
        value: fetchedCatalog.targetId,
      };
    });
  }, [mappings]);

  const isEditable = useMemo(() => {
    return catalog.isTenantCatalog || (!catalog.isTenantCatalog && catalog.isTenantMaster);
  }, [catalog.isTenantCatalog, catalog.isTenantMaster]);

  const mappedCatalogElement = useMemo(() => {
    if (selectedElement && selectedElement.mappings.length && selectedMappedCatalog) {
       return selectedElement.mappings.find((mapping) => mapping.targetCatalogId === selectedMappedCatalog);
    }
    return null;
  }, [selectedElement, selectedMappedCatalog]);

  const mappedCatalog = useMemo(() => {
    return mappings.find((mapping) => mapping.targetId === selectedMappedCatalog);
  }, [mappings, selectedMappedCatalog]);

  useEffect(() => {
    if (!mappedCatalog) {
      setSelectedMappedCatalog(mappings[0]?.targetId ?? '');
    }
  }, [mappedCatalog, mappings, setSelectedMappedCatalog]);

  return (
    <>
      <CatalogHeader title={title} icon={icon} />
      {mappedCatalogs.length > 0 && isEditable && (
        <div className="flex gap-2 mb-2 items-center">
          <BaseSelect
            className="flex-1"
            label={t('app.settingsSelectMappedCatalog')}
            value={selectedMappedCatalog}
            options={mappedCatalogs}
            onChange={setSelectedMappedCatalog}
            nullable={false}
          />
          {selectedMappedCatalog && (
            <TrashIcon className="w-5 h-5 cursor-pointer" onClick={() => {
              setShowMappingDelete(true)
            }} />
          )}
        </div>
      )}
      <CatalogSection bgColor={bgColor}>
        {!elements.length && <span className="py-2 px-3">{t('app.settingsNoCatalogItems')}</span>}
        {elements.map((catalogElement, i) => (
          <div className="ml-5" key={catalogElement.id}>
            <CatalogSlideOverRow
              catalog={catalog}
              catalogElement={catalogElement}
              handleEdit={(element) => {
                setSelectedElement(element);
                setShowEdit(true);
              }}
              handleDelete={(element) => {
                setSelectedElement(element);
                setShowDelete(true);
              }}
              key={catalogElement.id}
              level={1}
              addChild={(element) => {
                setParentElement(element);
                setShowAdd(true);
              }}
              idx={i}
              canMoveUp={i > 0}
              canMoveDown={i < elements.length - 1}
              selectedMappedCatalog={selectedMappedCatalog}
              handleEditMapping={(element) => {
                setSelectedElement(element);
                setShowEditMapping(true);
              }}
            />
          </div>
        ))}
      </CatalogSection>
      <SlideOver
        isOpen={showEdit}
        onClose={() => {
          setShowEdit(false);
        }}
        onAfterLeave={() => {
          setSelectedElement(null);
          setParentElement(null);
        }}
      >
        <CatalogElementEditSlideOver
          catalog={catalog}
          parentElement={parentElement}
          benchmarkProperties={benchmarkProperties ?? []}
          catalogElement={selectedElement}
          onClose={() => {
            setShowEdit(false);
          }}
          type={type}
        />
      </SlideOver>
      <SlideOver
        isOpen={showAdd}
        onClose={() => setShowAdd(false)}
        onAfterLeave={() => {
          setSelectedElement(null);
          setParentElement(null);
        }}
      >
        <CatalogElementCreateSlideOver
          catalogId={catalog.id}
          parentElement={parentElement}
          benchmarkProperties={benchmarkProperties ?? []}
          type={type}
          onClose={() => setShowAdd(false)}
        />
      </SlideOver>
      <Modal isOpen={showDelete} onClose={() => setShowDelete(false)}>
        {selectedElement && (
          <CatalogElementDeleteModal
            catalogId={catalog.id}
            type={type}
            catalogElement={selectedElement}
            onClose={() => setShowDelete(false)}
          />
        )}
      </Modal>
      <SlideOver isOpen={showCreateMapping} onClose={() => setShowCreateMapping(false)}>
        <CatalogCreateMappingSlideOver
          catalog={catalog}
          onClose={() => setShowCreateMapping(false)}
          type={type}
        />
      </SlideOver>
      <SlideOver
        isOpen={showEditMapping}
        onClose={() => {
          setShowEditMapping(false);
        }}
        onAfterLeave={() => {
          setSelectedElement(null);
        }}
      >
        {selectedElement && mappedCatalog && (
          <CatalogElementEditMappingSlideOver
            catalog={catalog}
            mappingId={mappedCatalog.id}
            catalogElement={selectedElement}
            mappedCatalogElement={mappedCatalogElement}
            onClose={() => {
              setShowEditMapping(false);
            }}
            type={type}
          />
        )}
      </SlideOver>
      <Modal
        isOpen={showMappingDelete}
        onClose={() => setShowMappingDelete(false)}
      >
        {mappedCatalog && catalog && (
          <CatalogDeleteMappingModal
            catalog={catalog}
            type={type}
            mappingId={mappedCatalog.id}
            onClose={() => setShowMappingDelete(false)}
          />
        )}
      </Modal>
    </>
  );
};
