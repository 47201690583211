import { useTranslation } from 'react-i18next';
import { Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import {
  Button,
  Form,
  FormField,
  FormRefHandle,
  FormWatch,
  Modal,
  ModalOnCloseProps,
  Wizard,
  wizardHandleOnSlideChangeFormValidation,
  WizardSlideContent,
  WizardSlideHeader,
} from '@client/shared/toolkit';
import { Radio, RadioGroup } from '@headlessui/react';
import classNames from 'classnames';
import * as yup from 'yup';
import { InferType } from 'yup';

export type FeatureElementType = 'inflation' | 'vat' | 'localLaw97' | 'generalEsg' | 'labourSettlements';

export const NewFeatureElementFormValidationSchema = yup.object({
  featureElement: yup.mixed<FeatureElementType>().required('validation.required'),
});

type NewFeatureElementFormValidationValues = InferType<typeof NewFeatureElementFormValidationSchema>;

export type FeatureElementItem = {
  label: string;
  subtitle: string;
  value: FeatureElementType;
  icon: ReactNode;
  disabled: boolean;
};

export interface NewFeatureElementModalProps extends ModalOnCloseProps {
  featureElementTypes: FeatureElementItem[];
  selectedFeatureElementType: FeatureElementType;
  onSelectFeatureElement: (featureElement: FeatureElementType) => void;
  selectedStep?: number;
}

export const NewFeatureElementModal = (props: NewFeatureElementModalProps) => {
  const { featureElementTypes, selectedFeatureElementType, onSelectFeatureElement, selectedStep = 0, onClose } = props;
  const { t } = useTranslation();
  const formRef = useRef<FormRefHandle<NewFeatureElementFormValidationValues>>();
  const [currentStep, setCurrentStep] = useState(selectedStep);

  const defaultFormValues = {
    featureElement: selectedFeatureElementType,
  };

  useEffect(() => {
    if (selectedFeatureElementType && formRef?.current) {
      formRef.current.setValue('featureElement', selectedFeatureElementType);
    }
  }, [selectedFeatureElementType]);

  return (
    <Form<NewFeatureElementFormValidationValues>
      validationSchema={NewFeatureElementFormValidationSchema}
      defaultValues={defaultFormValues}
      ref={formRef}
      className="h-full"
    >
      <FormWatch fieldNames={['featureElement']}>
        {({ featureElement }) => (
          <Wizard
            currentStep={currentStep}
            onSlideChange={(step) => wizardHandleOnSlideChangeFormValidation(step, formRef, setCurrentStep)}
            className="w-[740px] h-[560px]"
          >
            <Wizard.Slides>
              <Wizard.Slide centered={false}>
                <WizardSlideHeader
                  title={t('projectCalculate.featureElement')}
                  subTitle={t('projectCalculate.createFeatureElement')}
                />
                <WizardSlideContent>
                  <FormField name="featureElement">
                    {(control) => (
                      <RadioGroup
                        value={control.value}
                        onChange={control.onChange}
                        name="featureElement"
                        className="flex flex-col gap-0.5"
                      >
                        {featureElementTypes.map((featElementItem) => (
                          <Radio
                            value={featElementItem.value}
                            as={Fragment}
                            key={featElementItem.value}
                            disabled={featElementItem.disabled}
                          >
                            {({ checked }) => (
                              <div
                                className={classNames('w-full bg-white flex pl-4 py-4', {
                                  'opacity-50': featElementItem.disabled,
                                  'cursor-pointer': !featElementItem.disabled,
                                })}
                              >
                                <div className="mr-5">{featElementItem.icon}</div>
                                <div className="w-full flex flex-col justify-center">
                                  <div className="text-medium font-medium text-gray-900">{featElementItem.label}</div>
                                  <div className="font-xs text-slate-700">{featElementItem.subtitle}</div>
                                </div>
                                <div className="flex flex-col items-center justify-center pr-3">
                                  <CustomRadioCircle checked={checked} />
                                </div>
                              </div>
                            )}
                          </Radio>
                        ))}
                      </RadioGroup>
                    )}
                  </FormField>
                </WizardSlideContent>
              </Wizard.Slide>
            </Wizard.Slides>
            <Wizard.Navigation>
              {() => (
                <Modal.Controls
                  className={classNames({
                    'bg-gray-100': currentStep === 0,
                    'bg-white': currentStep !== 0,
                  })}
                >
                  {(() => {
                    if (currentStep === 0) {
                      return (
                        <>
                          <Button variant="text" onClick={() => onClose(false)}>
                            {t('common.cancel')}
                          </Button>
                          <Button
                            variant="primary"
                            onClick={() => onSelectFeatureElement(featureElement ?? selectedFeatureElementType)}
                            disabled={!featureElement}
                          >
                            {t('common.next')}
                          </Button>
                        </>
                      );
                    }
                  })()}
                </Modal.Controls>
              )}
            </Wizard.Navigation>
          </Wizard>
        )}
      </FormWatch>
    </Form>
  );
};

const CustomRadioCircle = ({ ...props }) => (
  <div className="h-4 w-4 border-2 rounded-full border-neutral-500 focus:ring-2 focus:ring-blue-300 flex items-center justify-center">
    <div className={classNames('w-2 h-2 rounded-full', { 'bg-black': props.checked })}></div>
  </div>
);
