import { Handle, HandleProps, Position } from 'reactflow';
import cn from 'classnames';
import { CSSProperties } from 'react';

const WORKFLOW_HANDLE_STYLE: CSSProperties = {
  background: 'rgb(243 244 246)',
  width: 16,
  height: 16,
  border: 'none',
  padding: 4,
};
const WORKFLOW_HANDLE_STYLE_POS_TOP: CSSProperties = { ...WORKFLOW_HANDLE_STYLE, top: -8 }
const WORKFLOW_HANDLE_STYLE_POS_BOTTOM: CSSProperties = { ...WORKFLOW_HANDLE_STYLE, bottom: -8 }

export interface WorkflowGraphHandleProps extends HandleProps {
  bgColorClassName?: string
  className?: string
  style?: CSSProperties
  overwriteStyles?: CSSProperties
}
export const WorkflowHandle = (props: WorkflowGraphHandleProps) => {
  const {
    bgColorClassName = 'bg-slate-500',
    className,
    style,
    overwriteStyles,
    ...handleProps
  } = props
  const defaultHandleStyles = handleProps.position === Position.Bottom ? WORKFLOW_HANDLE_STYLE_POS_BOTTOM : WORKFLOW_HANDLE_STYLE_POS_TOP
  const handleStyle= overwriteStyles ? overwriteStyles : {...defaultHandleStyles, ...style}
  return (
    <Handle {...handleProps} style={handleStyle}>
      <div className={cn('w-full h-full rounded-full', bgColorClassName, className)} />
    </Handle>
  )
}
