import { ConstructionDottedIcon } from '@client/shared/toolkit';
import { FontLight } from './FontLight';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { getLocale } from '@client/shared/utilities';
import { format } from 'date-fns';
import cn from 'classnames';

interface ProjectStartEndFeedProps {
  startDate: string;
  endDate: string;
  className?: string;
}

export const ProjectStartEndFeed = (props: ProjectStartEndFeedProps) => {
  const { t, i18n } = useTranslation();
  const startHeader = t('project.start');
  const endHeader = t('project.end');

  const startDate = useMemo(() => {
    return props.startDate ? new Date(props.startDate) : new Date();
  }, [props.startDate])

  const endDate = useMemo(() => {
    return props.endDate ? new Date(props.endDate) : new Date();
  }, [props.endDate])

  const currentLocale = useMemo(() => {
    return getLocale(i18n.language ?? 'en')
  }, [i18n.language])

  const startMonth = useMemo(() => {
    return format(startDate, 'LLLL', { locale: currentLocale }).substring(0, 3)
  }, [startDate, currentLocale])

  const endMonth = useMemo(() => {
    return format(endDate, 'LLLL', { locale: currentLocale }).substring(0, 3)
  }, [endDate, currentLocale])

  return (
    <div className={cn('flex flex-row py-1 gap-2 h-20 mx-4', props.className)}>
      <div className="w-10 xl:w-14 flex items-center text-slate-500 flex-none">
        <ConstructionDottedIcon className="w-10 h-10 pl-1 text-slate-500 flex-none" />
      </div>
      <div className="flex flex-row justify-evenly divide-x-2 flex-grow">
        <div className="flex flex-col justify-center pr-2 w-full">
          <FontLight>{startHeader}</FontLight>
          <div className="title font-bold text-[22px] leading-tight capitalize">
            {startMonth}
          </div>
          <FontLight>{startDate.getFullYear() || null}</FontLight>
        </div>
        <div className="flex flex-col justify-center px-2 w-full">
          <FontLight>{endHeader}</FontLight>
          <div className="title font-bold text-[22px] leading-tight capitalize">
            {endMonth}
          </div>
          <FontLight>{endDate.getFullYear() || null}</FontLight>
        </div>
      </div>
    </div>
  );
};
