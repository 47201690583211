import { apiBase as api } from './apiBase';
import {
  ApiAdminExportReportApiArg,
  ApiGetAiEvalDocumentFileApiArg,
  ApiGetAiEvalDocumentFileApiResponse,
  ApiGetJobRouterPdfApiArg,
  ApiGetJobRouterPdfApiResponse,
  ApiGetYardiExportInvoiceEndpointApiArg,
  ApiGetYardiExportInvoiceEndpointApiResponse,
  ApiPostHtmlToPdfExportApiArg
} from './apiGenerated';
import { apiEndpointUrls } from './apiGeneratedEndpointUrls';

export type ApiAdminExportReportFileApiResponse = {
  fileName: string;
  data: string;
  statusText: string;
}

const getFileNameFromResponseHeaders = (response: Response) => {
  const contentDisposition = response.headers?.get('content-disposition');
  let fileName = '';
  if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);
    if (matches != null && matches[1]) {
      fileName = matches[1].replace(/['"]/g, '');
    }
  }
  return fileName;
}

const getBlobFile = async (response: Response, fallbackName = '') => {
  const fileName = getFileNameFromResponseHeaders(response) ?? fallbackName;
  const blobFile = await response.blob();
  const file = new File([blobFile], fileName, { type: blobFile.type })
  return URL.createObjectURL(file)
}

export const injectedRtkCustomApi = api.injectEndpoints({
  endpoints: (build) => ({
    apiGetAiEvalDocumentPdfFile: build.query<ApiGetAiEvalDocumentFileApiResponse, ApiGetAiEvalDocumentFileApiArg>({
      query: (queryArg) => {
        return {
          url: apiEndpointUrls.apiGetAiEvalDocumentFile.replace(':projectId', queryArg.projectId).replace(':documentId', queryArg.documentId),
          method: 'GET',
          responseHandler: async (response) => {
            if (response.ok) {
              return getBlobFile(response, 'invoice_document.pdf');
            } else {
              return response.statusText;
            }
          },
          cache: 'no-cache'
        }
      },
    }),
    apiGetJobRouterPdfFile: build.query<ApiGetJobRouterPdfApiResponse, ApiGetJobRouterPdfApiArg>({
      query: (queryArg) => {
        return {
          url: apiEndpointUrls.apiGetJobRouterPdf.replace(':projectId', queryArg.projectId).replace(':calculationModelId', queryArg.calculationModelId).replace(':invoiceId', queryArg.invoiceId),
          method: 'GET',
          responseHandler: async (response) => {
            if (response.ok) {
              return getBlobFile(response, 'invoice_document.pdf');
            } else {
              return null; // response.statusText;
            }
          },
          cache: 'no-cache'
        }
      },
    }),
    apiAdminExportReportAsFile: build.query<ApiAdminExportReportFileApiResponse, ApiAdminExportReportApiArg>({
      query: (queryArg) => ({
        url: apiEndpointUrls.apiAdminExportReport.replace(':reportId', queryArg.reportId),
        method: 'GET',
        responseHandler: async (response) => {
          let fileName = '';
          let data = '';
          if (response.ok) {
            fileName = getFileNameFromResponseHeaders(response) ?? 'report.json';
            data = await getBlobFile(response, fileName);
          }
          return {
            fileName: fileName,
            data: data,
            statusText: response.statusText
          } as ApiAdminExportReportFileApiResponse
        }
      }),
    }),
    apiPostHtmlToPdfExportFile: build.mutation<ApiAdminExportReportFileApiResponse, ApiPostHtmlToPdfExportApiArg>({
      query: (queryArg) => {
        return {
          url: apiEndpointUrls.apiPostHtmlToPdfExport,
          method: 'POST',
          body: queryArg.body,
          responseHandler: async (response) => {
            let fileName = '';
            let data = '';
            if (response.ok) {
              fileName = getFileNameFromResponseHeaders(response) ?? 'report.json';
              data = await getBlobFile(response, fileName);
            }
            return {
              fileName: fileName,
              data: data,
              statusText: response.statusText
            } as ApiAdminExportReportFileApiResponse
          },
          cache: 'no-cache'
        }
      },
    }),
    apiPostHtmlToImageExportFile: build.mutation<ApiAdminExportReportFileApiResponse, ApiPostHtmlToPdfExportApiArg>({
      query: (queryArg) => {
        return {
          url: apiEndpointUrls.apiPostHtmlToImageExport,
          method: 'POST',
          body: queryArg.body,
          responseHandler: async (response) => {
            let fileName = '';
            let data = '';
            if (response.ok) {
              fileName = getFileNameFromResponseHeaders(response) ?? 'report.json';
              data = await getBlobFile(response, fileName);
            }
            return {
              fileName: fileName,
              data: data,
              statusText: response.statusText
            } as ApiAdminExportReportFileApiResponse
          },
          cache: 'no-cache'
        }
      },
    }),
    apiPostYardiInvoiceExportFile: build.mutation<ApiGetYardiExportInvoiceEndpointApiResponse, ApiGetYardiExportInvoiceEndpointApiArg>({
      query: (queryArg) => {
        return {
          url: apiEndpointUrls.apiGetYardiExportInvoiceEndpoint.replace(':projectId', queryArg.projectId).replace(':calculationModelId', queryArg.calculationModelId),
          method: 'POST',
          body: queryArg.body,
          responseHandler: async (response) => {
            let fileName = '';
            let data = '';
            if (response.ok) {
              fileName = getFileNameFromResponseHeaders(response) ?? 'report.json';
              data = await getBlobFile(response, fileName);

              //Generate Download link
              const link = document.createElement('a');
              link.setAttribute('download', fileName);
              link.href = data;
              document.body.appendChild(link);
              link.style.visibility = 'hidden';
              link.click();
              link.remove();

            }
            return {
              fileName: fileName,
              data: data,
              statusText: response.statusText
            } as ApiAdminExportReportFileApiResponse
          },
          cache: 'no-cache'
        }
      },
    }),    
  }),
  overrideExisting: false,
})

export const {
  useApiGetAiEvalDocumentPdfFileQuery,
  useApiGetJobRouterPdfFileQuery,
  useApiAdminExportReportAsFileQuery,
  useApiPostHtmlToPdfExportFileMutation,
  useApiPostHtmlToImageExportFileMutation,
  useApiPostYardiInvoiceExportFileMutation,
} = injectedRtkCustomApi
