import classNames from 'classnames';
import { PlusIcon } from '../icons';
import React, { ReactElement, ReactNode } from 'react';

interface ComboSelectAdditionalOptionProps {
  label: string;
  onClick?: () => void;
  icon?: string | ReactNode;
  className?: string;
  disabled?: boolean;
  sticky?: boolean;
}
export const ComboSelectAdditionalOption = (props: ComboSelectAdditionalOptionProps) => {
  const { label, onClick, icon = <PlusIcon />, sticky = true, className, disabled } = props;
  return (
    <div
      className={classNames(
        'w-full h-full flex justify-between items-center py-3 px-3 text-lg bg-white',
        {
          'hover:bg-sky-100 cursor-pointer': !disabled,
          'sticky bottom-0': sticky,
        },
        className,
      )}
      onClick={() => !disabled && onClick && onClick()}
    >
      <span className="italic text-gray-400 text-sm">{label}</span>
      {React.cloneElement(icon as ReactElement, { className: 'ml-auto h-3 w-3' })}
    </div>
  );
};
