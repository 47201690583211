import { ContextMenu } from '@client/shared/toolkit';
import cn from 'classnames';
import {
  BENCHMARK_DRAG_TYPE,
  BenchmarkingDragDropItem,
  BenchmarkingDragDropItemProps,
} from './BenchmarkingDragDropItem';

export const BenchmarkingColumnHeader = (props: BenchmarkingDragDropItemProps) => {
  const { isLast = false, title, subtitle, contextMenuItems, children, isHovered, isDragDisabled } = props;
  return (
    <BenchmarkingDragDropItem
      {...props}
      className={cn(
        'h-8 flex item-center justify-between before:content-[""] before:w-[2px] before:h-full before:block before:bg-slate-300 w-[200px]',
        {
          'after:content-[""] after:w-[2px] after:h-full after:block after:bg-slate-300 mr-2': isLast,
          'bg-slate-200 shadow-inner': isHovered,
          'transition-all duration-300 cursor-move': !isDragDisabled,
        },
      )}
      type={BENCHMARK_DRAG_TYPE.COLUMN}
    >
      {contextMenuItems && <ContextMenu items={contextMenuItems} className="flex-none text-gray-600 mt-0.5" />}
      <div className="flex flex-col gap-1 text-right flex-1 overflow-hidden pr-5">
        <span className="font-bold text-[15px] truncate leading-[18px] flex-none" title={title}>
          {title}
        </span>
        <div className="text-[9px] leading-none flex-none" title={subtitle?.toString()}>
          {subtitle}
        </div>
        {children}
      </div>
    </BenchmarkingDragDropItem>
  );
};
