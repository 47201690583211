import {
  Wizard,
  Button,
  Form,
  Modal,
  FormRefHandle,
  WizardSlideHeader,
  WizardSlideContent,
  List,
  FormField,
  FormWatch,
  BaseSelectOption,
  TextInput,
  AddButton,
  LoadingIndicator,
  ComboSelect,
  DOCUWARE_LOGO_IMAGE,
} from '@client/shared/toolkit';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DocuwareWizardFormValidationSchema,
  DocuwareWizardFormValidationValues,
} from './DocuwareWizardFormValidationSchema';
import classNames from 'classnames';
import {
  FetchDialogsResponse,
  FetchOrganizationsResponse,
  useApiPostFetchDialogsMutation,
  useApiPostFetchOrganizationsMutation,
  useApiPostFetchFieldsMutation,
  ApiPostFetchFieldsApiResponse,
  useApiPostAddDocuWareAccessMutation,
  useApiGetProjectsQuery,
  DocuWareConditionPayload,
  DocuWareProjectMappingPayload,
} from '@client/shared/api';
import { DocuwareProjectMappingListItem } from './DocuwareProjectMappingListItem';
import { DocuwareConditionListItem } from './DocuwareConditionListItem';

interface DocuwareWizardProps {
  isOpen: boolean;
  onClose: () => void;
  isEdit?: boolean;
  apiId: string;
  apiAccessId?: string;
  isReset?: boolean;
}

export const DocuwareWizard = ({ isOpen, onClose, isEdit, apiId, apiAccessId, isReset }: DocuwareWizardProps) => {
  const { t } = useTranslation();

  const [fetchDocuwareOrgaizations, { isLoading: isFetchingDocuwareOrgaizations }] =
    useApiPostFetchOrganizationsMutation();
  const [fetchDocuwareDialogs, { isLoading: isFetchingDocuwareDialogs }] = useApiPostFetchDialogsMutation();
  const [fetchDocuwareFields, { isLoading: isFetchingDocuwareFields }] = useApiPostFetchFieldsMutation();
  const [addDocuwareAccess, { isLoading: isAddingDocuwareAccess }] = useApiPostAddDocuWareAccessMutation();
  const { data } = useApiGetProjectsQuery();

  const [currentStep, setCurrentStep] = useState(isEdit ? 2 : 0);
  const [allowNextStep, setAllowNextStep] = useState(true);

  const formRef = useRef<FormRefHandle<DocuwareWizardFormValidationValues>>();
  const [organizations, setOrganizations] = useState<FetchOrganizationsResponse | null>(null);
  const [dialogs, setDialogs] = useState<FetchDialogsResponse | null>(null);
  const [fields, setFields] = useState<ApiPostFetchFieldsApiResponse | null>(null);

  const [queries, setQueries] = useState<DocuWareConditionPayload[]>([]);
  const [approved, setApproved] = useState<DocuWareConditionPayload[]>([]);
  const [rejected, setRejected] = useState<DocuWareConditionPayload[]>([]);
  const [mappings, setMappings] = useState<DocuWareProjectMappingPayload[]>([]);

  const fieldOptions = useMemo(() => {
    return (
      (fields?.fields.map((field) => {
        return {
          label: field.displayName,
          value: field.label,
        };
      }) as BaseSelectOption[]) ?? []
    ).sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
  }, [fields]);

  const mappingOptions = useMemo(() => {
    return (
      (data?.projects?.map((project) => {
        return {
          label: project.name,
          value: project.id,
        };
      }) as BaseSelectOption[]) ?? []
    ).sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
  }, [data?.projects]);

  const handleFetchOrganizations = async () => {
    try {
      const values = formRef?.current?.getValues();
      const result = await fetchDocuwareOrgaizations({
        body: {
          url: values?.apiUrl ?? '',
          username: values?.userName ?? '',
          password: values?.password ?? '',
        },
      }).unwrap();
      if (result) {
        setCurrentStep(2);
        setOrganizations(result);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleFetchDialogs = async () => {
    try {
      const values = formRef?.current?.getValues();
      const result = await fetchDocuwareDialogs({
        body: {
          url: values?.apiUrl ?? '',
          username: values?.userName ?? '',
          password: values?.password ?? '',
          organization: {
            id: values?.organization ?? '',
            name: organizations?.organizations.find((org) => org.id === values?.organization)?.name ?? '',
          },
        },
      }).unwrap();
      if (result) {
        setCurrentStep(3);
        setDialogs(result);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleFetchFields = async () => {
    try {
      const values = formRef?.current?.getValues();
      const dialog = dialogs?.dialogs.find((dia) => dia.dialogId === values?.dialog);
      const result = await fetchDocuwareFields({
        body: {
          url: values?.apiUrl ?? '',
          username: values?.userName ?? '',
          password: values?.password ?? '',
          organization: {
            id: values?.organization ?? '',
            name: organizations?.organizations.find((org) => org.id === values?.organization)?.name ?? '',
          },
          dialog: {
            dialogId: values?.dialog ?? '',
            fileCabinetName: dialog?.fileCabinetName ?? '',
            fileCabinetId: dialog?.fileCabinetId ?? '',
            dialogName: dialog?.dialogName ?? '',
            color: dialog?.color ?? '',
          },
        },
      }).unwrap();
      if (result) {
        setCurrentStep(4);
        setFields(result);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleAddAccess = async () => {
    try {
      const values = formRef?.current?.getValues();
      const dialog = dialogs?.dialogs.find((dia) => dia.dialogId === values?.dialog);
      if (values === null || values === undefined || dialog === null || dialog === undefined) return;
      let conditions: DocuWareConditionPayload[] =
        approved.map((item) => {
          return {
            type: 'Approved',
            displayName: fields?.fields.find((field) => field.label === item.label)?.displayName ?? '',
            label: item.label,
            value: item.value,
          };
        }) ?? [];
      conditions = conditions.concat(
        rejected.map((item) => {
          return {
            type: 'Rejected',
            displayName: fields?.fields.find((field) => field.label === item.label)?.displayName ?? '',
            label: item.label,
            value: item.value,
          };
        }) ?? [],
      );
      conditions = conditions.concat(
        queries.map((item) => {
          return {
            type: 'Query',
            displayName: fields?.fields.find((field) => field.label === item.label)?.displayName ?? '',
            label: item.label,
            value: item.value,
          };
        }) ?? [],
      );
      const projectMappings: DocuWareProjectMappingPayload[] =
        mappings.map((mapping) => {
          return {
            probisProjectId: mapping.probisProjectId,
            probisProject: data?.projects.find((project) => project.id === mapping.probisProjectId)?.name ?? '',
            docuWareProject: mapping.docuWareProject,
          };
        }) ?? [];
      const result = await addDocuwareAccess({
        apiId: apiId,
        body: {
          apiUrl: values.apiUrl,
          userName: values.userName,
          password: values.password,
          organizationId: values.organization,
          projectIdentifier: values.projectIdentifier,
          projectIdentifierDisplayName:
            fields?.fields.find((field) => field.label === values.projectIdentifier)?.displayName ?? '',
          dialogId: values?.dialog ?? '',
          dialogLabel: dialogs?.dialogs.find((dia) => dia.dialogId === values.dialog)?.dialogName ?? '',
          fileCabinetId: dialog.fileCabinetId,
          conditions: conditions,
          projectMappings: projectMappings,
        },
      }).unwrap();
      if (result) {
        onClose();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const addListItem = (type: 'Query' | 'Approved' | 'Rejected') => {
    if (type === 'Query') {
      setQueries([...queries, { type: type, displayName: '', label: '', value: '' }]);
    } else if (type === 'Approved') {
      setApproved([...approved, { type: type, displayName: '', label: '', value: '' }]);
    } else if (type === 'Rejected') {
      setRejected([...rejected, { type: type, displayName: '', label: '', value: '' }]);
    }
  };

  const validateSteps = useCallback(() => {
    if (formRef.current) {
      const values = formRef?.current?.getValues();
      switch (currentStep) {
        case 1: {
          if (values.apiUrl !== '' && values.userName !== '' && values.password !== '') {
            setAllowNextStep(true);
          } else {
            setAllowNextStep(false);
          }
          break;
        }

        case 2: {
          if (values.organization !== '') {
            setAllowNextStep(true);
          } else {
            setAllowNextStep(false);
          }
          break;
        }

        case 3: {
          if (values.dialog !== '') {
            setAllowNextStep(true);
          } else {
            setAllowNextStep(false);
          }
          break;
        }

        case 4: {
          if (
            values.projectIdentifier !== '' &&
            queries.length > 0 &&
            queries.every((query) => query.value !== '' && query.label !== '')
          ) {
            setAllowNextStep(true);
          } else {
            setAllowNextStep(false);
          }
          break;
        }

        case 5: {
          if (
            approved.every((item) => item.label !== '' && item.value !== '') &&
            rejected.every((item) => item.label !== '' && item.value !== '')
          )
            setAllowNextStep(true);
          else setAllowNextStep(false);
          break;
        }

        case 6: {
          setAllowNextStep(true);
          break;
        }

        default:
          setAllowNextStep(true);
          break;
      }
    }
  }, [approved, currentStep, queries, rejected, formRef]);

  const defaultFormValues: DocuwareWizardFormValidationValues = {
    isActive: false,
    apiUrl: '',
    userName: '',
    password: '',
    organization: '',
    dialog: '',
    projectIdentifier: '',
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {(isFetchingDocuwareDialogs ||
        isFetchingDocuwareOrgaizations ||
        isFetchingDocuwareFields ||
        isAddingDocuwareAccess) && <LoadingIndicator text={t('common.loading')} mode="overlay" />}
      <Form<DocuwareWizardFormValidationValues>
        validationSchema={DocuwareWizardFormValidationSchema}
        defaultValues={defaultFormValues}
        ref={formRef}
        className="h-full"
      >
        <FormWatch<DocuwareWizardFormValidationValues>
          onChange={() => {
            validateSteps();
          }}
          fieldNames={[
            'apiUrl',
            'userName',
            'password',
            'organization',
            'dialog',
            'projectIdentifier',
            'query',
            'approved',
            'rejected',
            'projectMappings',
          ]}
        >
          {() => (
            <Wizard
              currentStep={currentStep}
              onSlideChange={(step) => setCurrentStep(step)}
              className="w-[740px] h-[560px]"
            >
              <Wizard.Slides>
                {/* SLIDE 1 */}
                <Wizard.Slide>
                  <WizardSlideHeader
                    icon={<img src={DOCUWARE_LOGO_IMAGE} alt="Docuware Logo" />}
                    title={t('app.settingsApiDocuwareConfigureConnection')}
                  />
                  <WizardSlideContent>{t('app.settingsApiDocuwareConfigureConnectionDescription')}</WizardSlideContent>
                </Wizard.Slide>

                {/* SLIDE 2 - BASIC API AUTHENTIFICATION */}

                <Wizard.Slide>
                  <WizardSlideHeader title={t('app.settingsApiDocuwareBasicAuthentificationTitle')} />
                  <WizardSlideContent>
                    <div className="flex flex-col gap-1">
                      <div className="relative inline-block">
                        <FormField name="apiUrl">
                          {(control) => <TextInput label={t('app.ConnectApiUrl')} {...control} />}
                        </FormField>
                        <FormField name="userName">
                          {(control) => <TextInput label={t('app.ConnectUserName')} {...control} />}
                        </FormField>
                        <FormField name="password">
                          {(control) => (
                            <TextInput label={t('app.ConnectPassword')} inputType="password" {...control} />
                          )}
                        </FormField>
                      </div>
                    </div>
                  </WizardSlideContent>
                </Wizard.Slide>

                {/* SLIDE 3 - ORGANIZATIONS */}
                <Wizard.Slide>
                  <WizardSlideHeader title={t('app.settingsApiDocuwareOrganizationsTitle')} />
                  <WizardSlideContent>
                    <div className="flex flex-col gap-1">
                      <FormField name="organization">
                        {(control) => (
                          <div className="relative inline-block">
                            <ComboSelect
                              label={t('app.settingsApiDocuwareOrganization')}
                              options={
                                (organizations?.organizations.map((org) => {
                                  return {
                                    label: org.name,
                                    value: org.id,
                                  };
                                }) as BaseSelectOption[]) ?? []
                              }
                              {...control}
                            />
                          </div>
                        )}
                      </FormField>
                    </div>
                  </WizardSlideContent>
                </Wizard.Slide>

                {/* SLIDE 3 - DIALOGS */}
                <Wizard.Slide>
                  <WizardSlideHeader
                    title={t('app.settingsApiDocuwareDialogsTitle')}
                    // subTitle={t('app.settingsApiDocuwareDialogsDescription')}
                  />
                  <WizardSlideContent>
                    <div className="flex flex-col gap-1">
                      <FormField name="dialog">
                        {(control) => (
                          <div className="relative inline-block">
                            <ComboSelect
                              label={t('app.settingsApiDocuwareDialog')}
                              options={
                                (dialogs?.dialogs.map((dia) => {
                                  return {
                                    label: `${dia.fileCabinetName} - ${dia.dialogName}`,
                                    value: dia.dialogId,
                                  };
                                }) as BaseSelectOption[]) ?? []
                              }
                              {...control}
                            />
                          </div>
                        )}
                      </FormField>
                    </div>
                  </WizardSlideContent>
                </Wizard.Slide>

                {/* SLIDE 4 - FIELDS */}
                <Wizard.Slide>
                  <WizardSlideHeader
                    title={t('app.settingsApiDocuwareFieldsTitle')}
                    // subTitle={t('app.settingsApiDocuwareFieldsDescription')}
                  />
                  <WizardSlideContent>
                    <div className="flex flex-col gap-1">
                      <FormField name="projectIdentifier">
                        {(control) => (
                          <div className="relative inline-block">
                            <ComboSelect
                              label={t('app.settingsApiDocuwareProjectIdentifier')}
                              options={
                                (fields?.fields.map((field) => {
                                  return {
                                    label: field.displayName,
                                    value: field.label,
                                  };
                                }) as BaseSelectOption[]) ?? []
                              }
                              {...control}
                            />
                          </div>
                        )}
                      </FormField>
                      <List
                        className="mt-10"
                        listTitle={t('app.settingsApiDocuwareQuery')}
                        items={queries.map((item, index) => {
                          return {
                            id: `query-${index}`,
                            children: (
                              <DocuwareConditionListItem
                                id={index}
                                options={fieldOptions}
                                selected={item.label}
                                query={item.value}
                                onUpdate={(id, selected, query) => {
                                  const updatedQueries = [...queries];
                                  updatedQueries[index] = {
                                    type: 'Query',
                                    displayName: '',
                                    label: selected,
                                    value: query ?? '',
                                  };
                                  setQueries(updatedQueries);
                                }}
                                onDelete={(id) => {
                                  const updatedQueries = [...queries];
                                  updatedQueries.splice(id, 1);
                                  setQueries(updatedQueries);
                                }}
                              />
                            ),
                          };
                        })}
                        showPagination={false}
                      />
                      <div className="w-full justify-end">
                        <AddButton
                          className="float-right"
                          onClick={() => {
                            addListItem('Query');
                          }}
                        />
                      </div>
                    </div>
                  </WizardSlideContent>
                </Wizard.Slide>

                {/* SLIDE 5 - APPROVED AND REJECTED FIELDS */}
                <Wizard.Slide>
                  <WizardSlideHeader title={t('app.settingsApiDocuwareFieldsTitle')} />
                  <WizardSlideContent>
                    <div className="flex flex-col gap-1">
                      <List
                        className="mt-10"
                        listTitle={t('app.settingsApiDocuwareApproved')}
                        items={approved.map((item, index) => {
                          return {
                            id: `approved-${index}`,
                            children: (
                              <DocuwareConditionListItem
                                id={index}
                                options={fieldOptions.filter((option) =>
                                  option.value === item.label
                                    ? true
                                    : !approved.some((approved) => approved.label === option.value) &&
                                      option.value !== item.label,
                                )}
                                selected={item.label}
                                query={item.value}
                                onUpdate={(id, selected, query) => {
                                  const updatedApproved = [...approved];
                                  updatedApproved[index] = {
                                    type: 'Approved',
                                    displayName: '',
                                    label: selected,
                                    value: query,
                                  };
                                  setApproved(updatedApproved);
                                }}
                                onDelete={(id) => {
                                  const updatedApproved = [...approved];
                                  updatedApproved.splice(id, 1);
                                  setApproved(updatedApproved);
                                }}
                              />
                            ),
                          };
                        })}
                        showPagination={false}
                      />
                      {approved.length < fieldOptions.length && (
                        <div className="w-full justify-end">
                          <AddButton
                            className="float-right"
                            onClick={() => {
                              addListItem('Approved');
                            }}
                          />
                        </div>
                      )}
                      <List
                        className="mt-10"
                        listTitle={t('app.settingsApiDocuwareRejected')}
                        items={rejected.map((item, index) => {
                          return {
                            id: `rejected-${index}`,
                            children: (
                              <DocuwareConditionListItem
                                id={index}
                                options={fieldOptions.filter((option) =>
                                  option.value === item.label
                                    ? true
                                    : !rejected.some((approved) => approved.label === option.value) &&
                                      option.value !== item.label,
                                )}
                                selected={item.label}
                                query={item.value}
                                onUpdate={(id, selected, query) => {
                                  const updatedRejected = [...rejected];
                                  updatedRejected[index] = {
                                    type: 'Rejected',
                                    displayName: '',
                                    label: selected,
                                    value: query,
                                  };
                                  setRejected(updatedRejected);
                                }}
                                onDelete={(id) => {
                                  const updatedRejected = [...rejected];
                                  updatedRejected.splice(id, 1);
                                  setRejected(updatedRejected);
                                }}
                              />
                            ),
                          };
                        })}
                        showPagination={false}
                      />
                      <div className="w-full justify-end">
                        <AddButton
                          className="float-right"
                          onClick={() => {
                            addListItem('Rejected');
                          }}
                        />
                      </div>
                    </div>
                  </WizardSlideContent>
                </Wizard.Slide>

                {/* SLIDE 6 - PROJECT MAPPING */}
                <Wizard.Slide>
                  <WizardSlideHeader
                    title={t('app.settingsApiDocuwareProjectMappingsTitle')}
                    // subTitle={t('app.settingsApiDocuwareProjectMappingsDescription')}
                  />
                  <WizardSlideContent>
                    <div className="flex flex-col gap-1">
                      <List
                        className="mt-10"
                        listTitle={t('app.settingsApiDocuwareProjectMappings')}
                        items={mappings.map((item, index) => {
                          return {
                            id: `mapping-${index}`,
                            children: (
                              <DocuwareProjectMappingListItem
                                id={index}
                                options={mappingOptions.filter((option) =>
                                  option.value === item.probisProjectId
                                    ? true
                                    : !mappings.some((mapping) => mapping.probisProjectId === option.value) &&
                                      option.value !== item.probisProjectId,
                                )}
                                probisProject={item.probisProjectId}
                                docuWareProject={item.docuWareProject}
                                onUpdate={(id, probisProject, docuWareProject) => {
                                  const updatedMappings = [...mappings];
                                  updatedMappings[index] = {
                                    probisProject: '',
                                    probisProjectId: probisProject,
                                    docuWareProject: docuWareProject,
                                  };
                                  setMappings(updatedMappings);
                                }}
                                onDelete={(id) => {
                                  const updatedMappings = [...mappings];
                                  updatedMappings.splice(id, 1);
                                  setMappings(updatedMappings);
                                }}
                              />
                            ),
                          };
                        })}
                        showPagination={false}
                      />
                      {mappings.length < mappingOptions.length && (
                        <div className="w-full justify-end">
                          <AddButton
                            className="float-right"
                            onClick={() => {
                              setMappings([
                                ...mappings,
                                { probisProject: '', probisProjectId: '', docuWareProject: '' },
                              ]);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </WizardSlideContent>
                </Wizard.Slide>
              </Wizard.Slides>
              <Wizard.Navigation>
                {({ isLast, canGoNext, previous, next }) => (
                  <Modal.Controls
                    className={classNames({
                      'bg-slate-100': currentStep === 0,
                      'bg-white': currentStep !== 0,
                    })}
                  >
                    {(() => {
                      if (currentStep === 0 || isLast) {
                        return (
                          <Button variant="text" onClick={() => onClose()}>
                            {t('common.cancel')}
                          </Button>
                        );
                      } else {
                        return (
                          <Button variant="text" onClick={() => previous()}>
                            {t('common.back')}
                          </Button>
                        );
                      }
                    })()}
                    {(() => {
                      if (currentStep === 1) {
                        return (
                          <Button
                            variant="primary"
                            onClick={handleFetchOrganizations}
                            disabled={!canGoNext || !allowNextStep || isFetchingDocuwareOrgaizations}
                          >
                            {t('common.next')}
                          </Button>
                        );
                      } else if (currentStep === 2) {
                        return (
                          <Button
                            variant="primary"
                            onClick={handleFetchDialogs}
                            disabled={!canGoNext || !allowNextStep || isFetchingDocuwareDialogs}
                          >
                            {t('common.next')}
                          </Button>
                        );
                      } else if (currentStep === 3) {
                        return (
                          <Button
                            variant="primary"
                            onClick={handleFetchFields}
                            disabled={!canGoNext || !allowNextStep || isFetchingDocuwareFields}
                          >
                            {t('common.next')}
                          </Button>
                        );
                      } else if (currentStep === 6) {
                        return (
                          <Button
                            variant="primary"
                            onClick={handleAddAccess}
                            disabled={!allowNextStep || isAddingDocuwareAccess}
                          >
                            {t('common.save')}
                          </Button>
                        );
                      } else {
                        return (
                          <Button variant="primary" onClick={next} disabled={!canGoNext || !allowNextStep}>
                            {t('common.next')}
                          </Button>
                        );
                      }
                    })()}
                  </Modal.Controls>
                )}
              </Wizard.Navigation>
            </Wizard>
          )}
        </FormWatch>
      </Form>
    </Modal>
  );
};
