import {
  ContractTitleReadModel,
  useApiPostCreateContractTitleDocumentMutation,
  useApiGetContractTitleDocumentsQuery,
  useApiPostCreateContractTitleEmbedLinkDocumentMutation,
} from '@client/shared/api';
import { useMemo, useState } from 'react';
import {
  LoadingIndicator,
  SlideOverTitle,
  UploadFileTabs,
  CreateEmbeddedLinkFormValidationValues,
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { ContractTitleDocumentRow } from './ContractTitleDocumentRow';
import { safeMutation } from '@client/shared/utilities';

interface CalculateElementDocumentsProps {
  title: ContractTitleReadModel;
  canWrite: boolean;
  canDelete: boolean;
}

export const ContractTitleDocuments = ({ title, canWrite, canDelete }: CalculateElementDocumentsProps) => {
  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();
  const loadedCalculationModelId = useLoadedVariantId();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [postContractTitleDocuments, { isLoading: isLoadingContractTitleDocuments }] =
    useApiPostCreateContractTitleDocumentMutation();
  const { data: documents, isFetching } = useApiGetContractTitleDocumentsQuery({
    projectId: loadedProjectId ?? '',
    calculationModelId: loadedCalculationModelId ?? '',
    contractId: title.contractId ?? '',
    contractTitleId: title.id ?? '',
  });

  const handleSaveDocuments = async (fileList: FileList | null) => {
    if (fileList) {
      const formData = new FormData();
      Array.from(fileList).forEach((file) => formData.append('files', file));

      try {
        await safeMutation(
          postContractTitleDocuments,
          {
            projectId: loadedProjectId ?? 'unset',
            calculationModelId: loadedCalculationModelId ?? 'unset',
            contractId: title.contractId,
            contractTitleId: title.id,
            body: formData as unknown as { files: Blob[] },
          },
          isLoadingContractTitleDocuments,
        );
      } catch (e) {
        console.log(e, 'error');
      }
    }
  };

  const [postContractTitleEmbedLinkDocuments, { isLoading: isLoadingContractTitleEmbedLinkDocuments }] =
    useApiPostCreateContractTitleEmbedLinkDocumentMutation();

  const handleAddEmbedLinkDocument = async (data: CreateEmbeddedLinkFormValidationValues) => {
    try {
      await safeMutation(
        postContractTitleEmbedLinkDocuments,
        {
          projectId: loadedProjectId ?? 'unset',
          calculationModelId: loadedCalculationModelId ?? 'unset',
          contractId: title.contractId,
          contractTitleId: title.id,
          embedFileName: data.fileName,
          embedUrlLink: data.fileLink,
        },
        isLoadingContractTitleEmbedLinkDocuments,
      );
    } catch (e) {
      console.log(e, 'error');
    }
  };

  const docList = useMemo(() => {
    return documents?.filter((document) => !document.isEmbedLinkDocument);
  }, [documents]);

  const embedDocList = useMemo(() => {
    return documents?.filter((document) => document.isEmbedLinkDocument);
  }, [documents]);

  return (
    <>
      {(isFetching || isLoadingContractTitleDocuments || isLoadingContractTitleEmbedLinkDocuments) && (
        <LoadingIndicator text={t('common.uploading') ?? ''} mode="overlay" />
      )}

      <div>
        <SlideOverTitle title={t('projectCalculate.addElementDocument')} marginTop={false} />
        <div className="bg-white p-3 rounded-sm">
          <UploadFileTabs
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            acceptedFileTypes={[]}
            onAddEmbedLinkDocumentClick={handleAddEmbedLinkDocument}
            onUploadDocumentClick={handleSaveDocuments} // Upload action
            disabled={!canWrite}
          />
        </div>

        <div className="mt-4">
          <SlideOverTitle title={t('projectCalculate.elementDocument')} />
          {docList?.length === 0 ? (
            <div className="flex flex-col items-center justify-center p-5 bg-white text-gray-500">
              {t('projectCalculate.elementDocumentsEmpty')}
            </div>
          ) : (
            <>
              {docList?.map((document) => (
                <ContractTitleDocumentRow
                  title={title}
                  elementDocument={document}
                  key={document.id}
                  canDelete={canDelete}
                />
              ))}
            </>
          )}
        </div>

        <div className="mt-4">
          <SlideOverTitle title={t('projectCalculate.elementEmbedLinkDocument')} />
          {embedDocList?.length === 0 ? (
            <div className="flex flex-col items-center justify-center p-5 bg-white text-gray-500">
              {t('projectCalculate.elementEmbedLinkDocumentsEmpty')}
            </div>
          ) : (
            <>
              {embedDocList?.map((document) => (
                <ContractTitleDocumentRow
                  title={title}
                  elementDocument={document}
                  key={document.id}
                  canDelete={canDelete}
                />
              ))}
            </>
          )}
        </div>
      </div>
    </>
  );
};
