// import { TemplateScenariosList } from '../Scenarios';
import { CalculateContainer } from '@client/project/calculate';

export const ProjectTemplateCalculate = () => {
  return(
    <div className="flex-grow flex flex-col overflow-hidden">
      <CalculateContainer
        showFinance={false}
        costsFilters={[]}
        searchValue=""
        searchResults={[]}
      />
    </div>
  );
}
