import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthenticateDatevClientResult, AuthenticateTenantDatevResponse, DatevAuthUpdateData } from '@client/shared/api';
import { removeCookie } from 'typescript-cookie';
import { settings } from '@client/shared/store';
import { COOKIE_DATEV_AUTH_DATA } from '.';

export interface DatevAuthState {
  datevCacheKey?: string | undefined | null;
  datevTenantSubdomain: string;
  datevTenantAuthData: AuthenticateTenantDatevResponse | undefined;
  datevClientId: string | undefined;
  datevClientAuthData: AuthenticateDatevClientResult | undefined;
  datevUpdateData: DatevAuthUpdateData | undefined;
  datevWizardType: 'Add' | 'Reset';
  datevApiAccessId: string | undefined;
}

export const initialDatevAuthState: DatevAuthState = {
  datevCacheKey: undefined,
  datevTenantSubdomain: '',
  datevTenantAuthData: undefined,
  datevClientId: undefined,
  datevClientAuthData: undefined,
  datevUpdateData: undefined,
  datevWizardType: 'Add',
  datevApiAccessId: undefined,
};

export const datevAuthSlice = createSlice({
  name: 'datev',
  initialState: initialDatevAuthState,
  reducers: {
    setDatevAuth: (state, action: PayloadAction<DatevAuthState>) => {
      state.datevTenantSubdomain = action.payload.datevTenantSubdomain;
      state.datevTenantAuthData = action.payload.datevTenantAuthData;
      state.datevClientId = action.payload.datevClientId;
      state.datevClientAuthData = action.payload.datevClientAuthData;
      state.datevUpdateData = action.payload.datevUpdateData;
      state.datevWizardType = action.payload.datevWizardType;
      state.datevApiAccessId = action.payload.datevApiAccessId;
    },
    setDatevTenantSubdomain: (state, action: PayloadAction<string>) => {
      state.datevTenantSubdomain = action.payload;
    },
    setDatevTenantAuthData: (state, action: PayloadAction<AuthenticateTenantDatevResponse>) => {
      state.datevTenantAuthData = action.payload;
    },
    setDatevClientId: (state, action: PayloadAction<string>) => {
      state.datevClientId = action.payload;
    },
    setDatevClientAuthData: (state, action: PayloadAction<AuthenticateDatevClientResult>) => {
      state.datevClientAuthData = action.payload;
    },
    setDatevUpdateData: (state, action: PayloadAction<DatevAuthUpdateData>) => {
      state.datevUpdateData = action.payload;
    },
    setDatevWizardType: (state, action: PayloadAction<'Add' | 'Reset'>) => {
      state.datevWizardType = action.payload;
    },
    setDatevApiAccessId: (state, action: PayloadAction<string>) => {
      state.datevApiAccessId = action.payload;
    },
    setDatevKeyAndSubdomain: (state, action: PayloadAction<{ datevCacheKey: string, datevTenantSubdomain: string }>) => {
      state.datevCacheKey = action.payload.datevCacheKey;
      state.datevTenantSubdomain = action.payload.datevTenantSubdomain;
    },
    removeDatevData: (state, action: PayloadAction) => {
      removeCookie(COOKIE_DATEV_AUTH_DATA, { path: '/', domain: settings.mainDomain });
      state = initialDatevAuthState;
    },
  },
});

export const {
  setDatevAuth,
  setDatevTenantSubdomain,
  setDatevTenantAuthData,
  setDatevClientId,
  setDatevClientAuthData,
  setDatevUpdateData,
  setDatevWizardType,
  setDatevApiAccessId,
  setDatevKeyAndSubdomain,
  removeDatevData,
} = datevAuthSlice.actions;
