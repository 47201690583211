import {
  AgreementDottedIcon,
  BusinessBuildingDottedIcon,
  CardPaymentDottedIcon,
  CheckMark2DottedIcon,
  CityBuildingsDottedIcon,
  CustomerDottedIcon,
  DistributionTypeCustomIcon,
  ExchangeDollarDottedIcon,
  LoadingIndicator,
  ProjectPhaseCustomIcon,
  ProjectTypeCustomIcon,
  QuickModeOnDottedIcon,
  RoomDottedIcon,
  USDollarCircleDottedIcon,
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { DashboardWidgetType, useProjectDashboardData, Widget, WidgetDashboard } from '@client/project/shared';

export const DashboardCarestone = () => {
  const { t } = useTranslation();
  const { isFetching } = useProjectDashboardData();

  const defaultLayout: Widget[] = [
    {
      layout: {
        i: '1',
        x: 0,
        y: 0,
        w: 1,
        h: 1,
        static: true
      },
      widget: {
        type: DashboardWidgetType.ProjectCustomFields,
        id: '1',
        config: [
          {
            name: 'Projektphase',
            icon: <ProjectPhaseCustomIcon />,
          },
        ],
      },
    },
    {
      layout: {
        i: '3',
        x: 0,
        y: 1,
        w: 1,
        h: 3,
        static: true
      },
      widget: {
        type: DashboardWidgetType.ProjectCustomFields,
        id: '3',
        config: [
          {
            name: 'Gesellschaft',
            title: 'Projektgesellschaft',
            icon: <BusinessBuildingDottedIcon />,
          },
          {
            name: 'Projektverantwortung (PE)',
            title: 'Projektverantwortung PE',
            icon: <CustomerDottedIcon />,
          },
          {
            name: 'Projektverantwortung (PM)',
            title: 'Projektverantwortung PM',
            icon: <CustomerDottedIcon />,
          },
        ],
      },
    },
    {
      layout: {
        i: '2',
        x: 0,
        y: 4,
        w: 1,
        h: 1.5,
        static: true
      },
      widget: { type: DashboardWidgetType.ProjectMetaDataAddress, id: '2' },
    },
    {
      layout: {
        i: '4',
        x: 0,
        y: 5.5,
        w: 1,
        h: 3,
        static: true
      },
      widget: {
        type: DashboardWidgetType.Mixed,
        id: '4',
        widgets: [
          {
            type: DashboardWidgetType.ProjectMetaDataGrz,
          },
          {
            type: DashboardWidgetType.ProjectCustomFields,
            config: [
              {
                name: 'Energiestandard',
                icon: <QuickModeOnDottedIcon />,
                size: 'large',
              },
              {
                name: 'Einheiten',
                icon: <RoomDottedIcon />,
                size: 'large',
              },
            ],
          },
        ],
      },
    },
    {
      layout: {
        i: '5',
        x: 1,
        y: 0,
        w: 1,
        h: 3,
        static: true
      },
      breakpoints: {
        xs: {
          i: '5',
          x: 1,
          y: 8.5,
          w: 1,
          h: 3,
          static: true
        }
      },
      widget: {
        type: DashboardWidgetType.ProjectCustomFields,
        id: '5',
        config: [
          {
            name: 'Kaufpreiszahlung am',
            title: 'Kaufpreiszahlung am',
            icon: <CardPaymentDottedIcon />,
            size: 'large',
          },
          {
            name: 'Baugenehmigung vorhanden seit',
            title: 'Baugenehmigung seit',
            icon: <CheckMark2DottedIcon />,
            size: 'large',
          },
          {
            name: 'Beauftragung Bau',
            icon: <AgreementDottedIcon />,
            size: 'large',
          },
        ]
      },
    },
    {
      layout: {
        i: '6',
        x: 1,
        y: 3,
        w: 1,
        h: 5.5,
        static: true
      },
      breakpoints: {
        xs: {
          i: '6',
          x: 1,
          y: 11.5,
          w: 1,
          h: 5.5,
          static: true
        }
      },
      widget: { type: DashboardWidgetType.MaBVRate, id: '6' },
    },
    {
      layout: {
        i: '7',
        x: 2,
        y: 0,
        w: 2,
        h: 4,
        static: true
      },
      breakpoints: {
        sm: {
          i: '7',
          x: 2,
          y: 8.5,
          w: 2,
          h: 4,
          static: true
        }
      },
      widget: { type: DashboardWidgetType.CashFlowChart, id: '7' },
    },
    {
      layout: {
        i: '8',
        x: 2,
        y: 4,
        w: 2,
        h: 4.5,
        static: true
      },
      breakpoints: {
        sm: {
          i: '8',
          x: 2,
          y: 12.5,
          w: 2,
          h: 4.5,
          static: true
        }
      },
      widget: { type: DashboardWidgetType.IsShouldChart, id: '8' },
    },

    {
      layout: {
        i: '9',
        x: 4,
        y: 0,
        w: 2,
        h: 5.5,
        static: true
      },
      breakpoints: {
        lg: {
          i: '9',
          x: 4,
          y: 8.5,
          w: 2,
          h: 5,
        },
        md: {
          i: '9',
          x: 4,
          y: 8.5,
          w: 2,
          h: 5,
        },
        xs: {
          i: '9',
          x: 4,
          y: 25.5,
          w: 2,
          h: 5,
        },
      },
      widget: { type: DashboardWidgetType.Assets, id: '9' },
    },
    {
      layout: {
        i: '10',
        x: 4,
        y: 5.5,
        w: 1,
        h: 3,
        static: true
      },
      breakpoints: {
        lg: {
          i: '10',
          x: 1,
          y: 8.5,
          w: 1,
          h: 3,
        },
        md: {
          i: '10',
          x: 1,
          y: 8.5,
          w: 1,
          h: 3,
        }
      },
      widget: {
        type: DashboardWidgetType.ProjectCustomFields,
        id: '10',
        config: [
          {
            name: 'Betreiber',
            icon: <BusinessBuildingDottedIcon />,
            size: 'large',
          },
        ],
        widgets: [
          {
            type: DashboardWidgetType.ProjectMetaDataCustom,
            config: [
              {
                name: 'Jahresmiete (Soll)',
                icon: <USDollarCircleDottedIcon />,
                size: 'large',
              },
            ],
          },
          {
            type: DashboardWidgetType.ProjectMetaDataCustom,
            config: [
              {
                name: 'Endkundenrendite',
                icon: <ExchangeDollarDottedIcon />,
                size: 'large',
              },
            ],
          },
        ],
      },
    },
    {
      layout: {
        i: '11',
        x: 5,
        y: 5.5,
        w: 1,
        h: 3,
        static: true
      },
      breakpoints: {
        lg: {
          i: '11',
          x: 0,
          y: 8.5,
          w: 1,
          h: 3,
        },
        md: {
          i: '11',
          x: 0,
          y: 8.5,
          w: 1,
          h: 3,
        },
        sm: {
          i: '11',
          x: 0,
          y: 3,
          w: 1,
          h: 3,
        },
      },
      widget: {
        type: DashboardWidgetType.ProjectCustomFields,
        id: '11',
        title: t('dashboard.widget.title.more'),
        icon: <CityBuildingsDottedIcon />,
        colSpan: 'col-span-full h-[209px]',
        config: [
          {
            name: 'Projektart',
            icon: <ProjectTypeCustomIcon />,
            variant: 'other',
            size: 'large',
            // hideIfEmpty: true
          },
          {
            name: 'Vertriebsart',
            icon: <DistributionTypeCustomIcon />,
            variant: 'other',
            size: 'large',
          },
        ],
      },
    },
  ];

  return isFetching ? (
    <LoadingIndicator mode="overlay" />
  ) : (
    <WidgetDashboard type="carestone" dashboardId="carestone" layout={defaultLayout} isEditable={false} />
  );
};
