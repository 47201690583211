import {
  IncreaseIcon,
  ModalOnCloseProps
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { FeatureElementItem, FeatureElementType, NewFeatureElementModal } from './NewFeatureElementModal';

interface NewEarningsFeatureElementModalProps extends ModalOnCloseProps {
  onClose: (clearSelection?: boolean) => void;
  onOpenSlideOver: (featureElementType: FeatureElementType) => void;
}

export const NewEarningsFeatureElementModal = ({ onClose, onOpenSlideOver }: NewEarningsFeatureElementModalProps) => {
  const { t } = useTranslation();
  const featureElementTypes: FeatureElementItem[] = [
    {
      label: t('projectCalculate.inflationCalcModal.title'),
      subtitle: t('projectCalculate.inflationCalcModal.subtitleEarnings'),
      value: 'inflation',
      icon: <IncreaseIcon className="w-14 h-14 p-3 rounded-full" />,
      disabled: false,
    },
  ];
  return (
    <NewFeatureElementModal
      featureElementTypes={featureElementTypes}
      selectedFeatureElementType="inflation"
      onClose={onClose}
      onSelectFeatureElement={onOpenSlideOver}
    />
  );
};
