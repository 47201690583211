import {
  BaseSelect,
  BaseSelectOption,
  Button, ComboSelect,
  Form,
  FormField,
  LoadingIndicator,
  Modal,
  SlideOver,
  SlideOverOnCloseProps,
} from '@client/shared/toolkit';
import {
  CatalogElementMappingReadModel,
  CostCatalogElementReadModel,
  EarningsCatalogElementReadModel,
  FinancingCatalogElementReadModel,
  RiskCatalogElementReadModel,
  TenantCatalogReadModel,
  TenantCatalogType,
  useApiCreateCatalogElementMappingMutation,
  useApiGetCatalogElementsAvailableForMappingQuery,
} from '@client/shared/api';
import React, { useMemo, useState } from 'react';
import * as yup from 'yup';
import { InferType } from 'yup';
import { useTranslation } from 'react-i18next';
import { CatalogElementMappingDeleteModal } from './CatalogElementMappingDeleteModal';
import { safeMutation } from '@client/shared/utilities';

export const CatalogElementEditMappingValidationSchema = yup.object({
  catalogElementId: yup.string().required(),
  mappedCatalogElementId: yup.string().required(),
});

export type CatalogElementEditMappingValidationValues = InferType<typeof CatalogElementEditMappingValidationSchema>;

interface CatalogElementEditMappingSlideOverProps extends SlideOverOnCloseProps {
  catalog: TenantCatalogReadModel;
  catalogElement:
    | CostCatalogElementReadModel
    | FinancingCatalogElementReadModel
    | RiskCatalogElementReadModel
    | EarningsCatalogElementReadModel;
  mappedCatalogElement?: CatalogElementMappingReadModel | null;
  type: TenantCatalogType;
  mappingId: string;
}

export const CatalogElementEditMappingSlideOver = (props: CatalogElementEditMappingSlideOverProps) => {
  const { catalogElement, mappedCatalogElement, onClose, type, mappingId } = props;
  const { t } = useTranslation();

  const [showDelete, setShowDelete] = useState(false);
  const [createMapping, { isLoading: isCreating }] = useApiCreateCatalogElementMappingMutation();

  const { data: availableElementsForMapping, isFetching } = useApiGetCatalogElementsAvailableForMappingQuery(
    {
      mappingId: mappedCatalogElement?.mappingId ?? mappingId ?? '',
      type: type,
    },
    {
      skip: !mappedCatalogElement?.mappingId && !mappingId,
    },
  );

  const handleSubmit = async (data: CatalogElementEditMappingValidationValues) => {
    try {
      await safeMutation(
        createMapping,
        {
          body: {
            catalogType: type,
            mappingId: mappingId,
            sourceElementId: data.catalogElementId,
            targetElementId: data.mappedCatalogElementId,
          },
        },
        isCreating,
      );
      onClose(false);
    } catch (e) {
      console.log(e);
    }
  };

  const mappedCatalogElementOptions: BaseSelectOption[] = useMemo(() => {
    const options: BaseSelectOption[] = [];
    if (mappedCatalogElement) {
      options.push({
        label: `${mappedCatalogElement.targetElementCode} - ${mappedCatalogElement.targetElementName}`,
        value: mappedCatalogElement.mappingId,
      });
    }

    availableElementsForMapping?.elements.forEach((element) => {
      options.push({
        label: `${element.code} - ${element.name}`,
        value: element.id,
      });
    });

    return options;
  }, [mappedCatalogElement, availableElementsForMapping?.elements]);

  const defaultFormValues = {
    catalogElementId: catalogElement.id,
    mappedCatalogElementId: mappedCatalogElement?.mappingId ?? '',
  };

  return (
    <>
      <Form<CatalogElementEditMappingValidationValues>
        onSubmit={handleSubmit}
        validationSchema={CatalogElementEditMappingValidationSchema}
        defaultValues={defaultFormValues}
        className="w-full flex flex-col justify-between h-full"
      >
        {(isCreating || isFetching) && (
          <LoadingIndicator text={isFetching ? t('common.loading') : t('common.saving')} mode="overlay" />
        )}
        <SlideOver.Header
          title={catalogElement?.description ?? t('templates.newCatalogTemplateElementMappingTitle')}
          subTitle={
            mappedCatalogElement
              ? t('templates.updateCatalogTemplateElementMappingSubTitle')
              : t('templates.newCatalogTemplateElementMappingTitle')
          }
          backgroundClassName="bg-gray-600"
          onClose={() => onClose(false)}
        />
        <SlideOver.Content>
          <div className="m-8 bg-white">
            <div className="divide-gray-100 divide-y">
              <FormField name="catalogElementId">
                {(control) => (
                  <BaseSelect
                    label={t('templates.catalogElement')}
                    options={[
                      { label: `${catalogElement.code} - ${catalogElement.description}`, value: catalogElement.id },
                    ]}
                    disabled
                    {...control}
                  />
                )}
              </FormField>
              <FormField name="mappedCatalogElementId">
                {(control) => (
                  <ComboSelect
                    nullable={false}
                    label={t('templates.mappedCatalogElement')}
                    options={mappedCatalogElementOptions}
                    pageOptions={true}
                    {...control}
                  />
                )}
              </FormField>
            </div>
          </div>
        </SlideOver.Content>
        <SlideOver.Controls>
          {catalogElement?.id && (
            <div className="flex flex-grow flex-row">
              <Button variant="warning" onClick={() => setShowDelete(true)}>
                {t('common.delete')}
              </Button>
            </div>
          )}
          <div className="flex justify-end">
            <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
              {t('common.cancel')}
            </Button>
            <Button variant="primary" formSubmit={true}>
              {t('common.save')}
            </Button>
          </div>
        </SlideOver.Controls>
      </Form>
      <Modal isOpen={showDelete} onClose={() => setShowDelete(false)}>
        {mappedCatalogElement && (
          <CatalogElementMappingDeleteModal
            mappingId={mappedCatalogElement.mappingId}
            mappingElementId={mappedCatalogElement.id}
            type={type}
            catalogElement={catalogElement}
            onClose={(val) => {
              setShowDelete(false);
              if (val) {
                onClose(false);
              }
            }}
          />
        )}
      </Modal>
    </>
  );
};
