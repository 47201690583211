import {
  BaseSelect, BaseSelectOption,
  BooleanInput,
  Button, ComboSelect, ComboSelectAdditionalOption,
  HintBox, LoadingIndicator,
  Modal, TextInput
} from '@client/shared/toolkit';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  InvoiceCalculationSchemeReadModel,
  useApiGetProjectsQuery,
  useApiGetTenantInvoiceCalculationSchemesQuery,
  useApiPostCopyProjectInvoiceCalculationSchemeMutation,
  useApiPostCopyTenantInvoiceCalculationSchemeMutation,
  useApiPostCopyTenantInvoiceCalculationSchemeToProjectMutation,
} from '@client/shared/api';
import { IcsType } from '..';
import { safeMutation } from '@client/shared/utilities';
import { NavLink, useNavigate } from 'react-router-dom';
import { ROUTES_CONFIG } from '@client/shared/permissions';

interface InvoiceCoverSheetCopyModalProps {
  onClose: () => void
  sheet?: InvoiceCalculationSchemeReadModel | null
  sheets?: InvoiceCalculationSchemeReadModel[] | null
  type: IcsType
  projectId?: string
  createCopyFromTenant?: boolean
}
export const InvoiceCoverSheetCopyModal = (props: InvoiceCoverSheetCopyModalProps) => {
  const {
    onClose,
    sheet,
    sheets,
    type,
    projectId,
    createCopyFromTenant = false
  } = props
  const { t } = useTranslation()

  const navigate = useNavigate();

  const { data: fetchedProjects, isFetching } = useApiGetProjectsQuery(undefined, {
    skip: type === 'project' || createCopyFromTenant
  });

  const { data: fetchedTenantSheets, isFetching: isFetchingICS } = useApiGetTenantInvoiceCalculationSchemesQuery(undefined, {
    skip: type === 'tenant' || !createCopyFromTenant
  })

  const [copyTenantIcs, { isLoading: isCopyingTenantIcs }] = useApiPostCopyTenantInvoiceCalculationSchemeMutation();
  const [copyProjectIcs, { isLoading: isCopyingProjectIcs }] = useApiPostCopyProjectInvoiceCalculationSchemeMutation();
  const [copyTenantIcsToProject, { isLoading: isCopyingTenantIcsToProject }] = useApiPostCopyTenantInvoiceCalculationSchemeToProjectMutation()

  const [name, setName] = useState('')
  const [isDefault, setIsDefault] = useState(false)
  const [selectedProject, setSelectedProject] = useState<string | null>(null)
  const [selectedSheet, setSelectedSheet] = useState<InvoiceCalculationSchemeReadModel | null>(sheet ?? null)

  const [isSubmitted, setIsSubmitted] = useState(false)

  const handleCopy = async () => {
    setIsSubmitted(true)
    if (!name || !selectedSheet) return false

    if (type === 'tenant') {
      if (selectedProject) {
        // copy tenant ics to project
        try {
          await safeMutation(
            copyTenantIcsToProject,
            {
              invoiceCalculationSchemeId: selectedSheet.invoiceCalculationSchemeId,
              projectId: selectedProject,
              body: {
                isDefault,
                name,
              },
            },
            isCopyingTenantIcsToProject
          )
          onClose()
        } catch (e) {
          console.error(e);
        }
      } else {
        try {
          await safeMutation(
            copyTenantIcs,
            {
              invoiceCalculationSchemeId: selectedSheet.invoiceCalculationSchemeId,
              body: {
                isDefault,
                name,
              },
            },
            isCopyingTenantIcs
          )
          onClose()
        } catch (e) {
          console.error(e);
        }
      }
    } else if (projectId) {
      if (createCopyFromTenant) {
        // copy tenant ics to project
        try {
          await safeMutation(
            copyTenantIcsToProject,
            {
              invoiceCalculationSchemeId: selectedSheet.invoiceCalculationSchemeId,
              projectId: projectId,
              body: {
                isDefault,
                name,
              },
            },
            isCopyingTenantIcsToProject
          )
          onClose()
        } catch (e) {
          console.error(e);
        }
      } else {
        try {
          await safeMutation(
            copyProjectIcs,
            {
              invoiceCalculationSchemeId: selectedSheet.invoiceCalculationSchemeId,
              projectId: projectId,
              body: {
                isDefault,
                name,
              },
            },
            isCopyingProjectIcs
          )
          onClose()
        } catch (e) {
          console.error(e);
        }
      }
    }
  }

  const availableSheets = useMemo(() => {
    let selectedSheets = sheets ? [...sheets] : []
    if (createCopyFromTenant && fetchedTenantSheets) {
      selectedSheets = [...fetchedTenantSheets]
    }
    return selectedSheets
  }, [sheets, fetchedTenantSheets, createCopyFromTenant])

  const sheetOptions = useMemo(() => {
    const options: BaseSelectOption[] = []

    if (availableSheets.length) {
      availableSheets.forEach((sheet) => {
        options.push({
          label: sheet.name,
          value: sheet.invoiceCalculationSchemeId
        })
      })

      if (!sheet) {
        setSelectedSheet(availableSheets[0])
        setName(t('app.copyCopyOf', { name: availableSheets[0].name }));
      }
    }

    return options
  }, [availableSheets, sheet, t])

  useEffect(() => {
    if (sheet && sheet.invoiceCalculationSchemeId) {
      setSelectedSheet(sheet)
      setName(t('app.copyCopyOf', { name: sheet.name }));
    }
  }, [sheet, t]);

  const updateSheet = useCallback((sheetId: string) => {
    if (sheetId) {
      const foundSheet = availableSheets?.find((s) => s.invoiceCalculationSchemeId === sheetId)
      if (foundSheet) {
        setName(t('app.copyCopyOf', { name: foundSheet.name }));
        setSelectedSheet(foundSheet)
      }
    }
  }, [availableSheets, t])

  const projectOptions = useMemo(() => {
    const options: BaseSelectOption[] = []

    if (type === 'tenant' && fetchedProjects?.projects) {
      const projects = fetchedProjects.projects
      projects.forEach((project) => {
        options.push({
          label: project.name,
          value: project.id
        })
      })
    }

    return options
  }, [type, fetchedProjects?.projects])

  const isNameValid = useMemo(() => {
    return !isSubmitted || (isSubmitted && !!name && !!selectedSheet)
  }, [isSubmitted, name, selectedSheet])

  const nameHelperText = useMemo(() => {
    if (!isNameValid) {
      if (!name) {
        return t('ics.nameIsRequired')
      }
      else {
        return t('ics.nameMustBeUnique')
      }
    }
    return undefined
  }, [isNameValid, t, name])

  return (
    <>
      {(isCopyingProjectIcs || isCopyingTenantIcs || isCopyingTenantIcsToProject) && (
        <LoadingIndicator text={t('ics.copyingIcsLoadingIndicator')} mode="overlay-window" />
      )}
      {(isFetchingICS || isFetching) && <LoadingIndicator text={t('app.settingsLoadingInvoiceCoverSheets')} />}
      <Modal.Header title={t('ics.copyInvoiceCoverSheet')} />
      <Modal.Content className="space-y-1 pb-6">
        <HintBox hintType="info" className="mb-2">{type === 'tenant' ? t('ics.copyInvoiceCoverSheetTenant') : createCopyFromTenant ? t('ics.copyInvoiceCoverSheetProjectFromTenant') : t('ics.copyInvoiceCoverSheetProject')}</HintBox>
        {sheetOptions.length > 0 ? (
          <>
            <ComboSelect
              label={t('ics.selectInvoiceCoverSheetToCopy')}
              value={selectedSheet?.invoiceCalculationSchemeId ?? ''}
              options={sheetOptions}
              onChange={(val) => updateSheet(val ?? '')}
              additionalOption={projectId ? 
                <ComboSelectAdditionalOption 
                  label={t('app.settingsCreateInvoiceCoverSheetTitle')}
                  onClick={() => navigate(ROUTES_CONFIG.SETTINGS_ICS.path)}
                /> : undefined}
              nullable={false}
            />
            <BooleanInput
              label={t('app.settingsInvoiceCoverSheetDefaultFlag')}
              value={isDefault}
              onChange={(val) => setIsDefault(val)}
            />
            <TextInput
              label={t('ics.nameOfInvoiceCoverSheetCopy')}
              value={name}
              showValidation={!isNameValid}
              helperText={nameHelperText}
              isValidationValid={isNameValid}
              onChange={(val) => {
                setName(val)
                setIsSubmitted(true)
              }}
            />
            {type === 'tenant' &&(
              <BaseSelect
                label={t('ics.selectProjectToCopyTo')}
                value={selectedProject ?? ''}
                options={projectOptions}
                onChange={(id: string) => setSelectedProject(id)}
              />
            )}
          </>
        ) : (
          <div>
            {t('ics.noIcsToCopyAvailable')}&nbsp;
            <NavLink to={ROUTES_CONFIG.SETTINGS_ICS.path} className="underline">
              {t('ics.goToTenantSettingsLink')}
            </NavLink>
          </div>
        )}
      </Modal.Content>
      <Modal.Controls className="bg-white">
        <Button variant="secondary" onClick={onClose} className="mr-2">
          {t('common.cancel')}
        </Button>
        <Button variant="primary" onClick={handleCopy} disabled={!isNameValid || !selectedSheet}>
          {t('app.copyCreateCopy')}
        </Button>
      </Modal.Controls>
    </>
  )
}
