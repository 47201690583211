import { useState, useEffect, useMemo, useRef } from 'react';
import {
  EuriborPeriod,
  FinancingElementPayload,
  FinancingPaybackPlanElementReadModel,
  FinancingPaymentPlanElementReadModel,
  FinancingType,
  InterestPeriod,
  InterestRateMethod,
  InterestRatePayload,
  InterestRateType,
  LoanTypes,
  PaymentPlanType,
  RepaymentMethod,
  useApiGetFinancingElementQuery,
  useApiPostCreateFinancingElementMutation,
  useApiPostUpdateFinancingElementMutation,
  FinancingFeePayload,
  useApiGetCatalogQuery,
  FinancingElementReadModel,
  useApiGetProjectWritableFinancingCatalogQuery,
  useApiGetFinancingElementsMetadataQuery,
  api,
  ElementTimelineReadModel,
  FinancingError,
} from '@client/shared/api';
import {
  ComboSelect,
  SlideOver,
  Button,
  TextInput,
  NumberInput,
  BaseSelect,
  Modal,
  SlideOverOnCloseProps,
  DatePicker,
  BaseSelectOption,
  BooleanInput,
  MultiSelect,
  HintBox,
  UnavailableIcon,
  TransactionIcon,
  TagWindowIcon,
  StocksIcon,
  ReceiveCashIcon,
  PercentageIcon,
  EditFilledIcon,
  InitialStateIcon,
  CashIcon,
  MaintenanceDateIcon,
  TearOffCalenderIcon,
  EditNodeIcon,
  ContactUsIcon,
  InitiateMoneyTransferIcon,
  MastercardCreditCardIcon,
  EuroMoneyIcon,
  ExchangeIcon,
  BankBuildingIcon,
  IrrSmallIcon,
  SlideOverTitle,
  LoadingIndicator,
  FAKE_CALCULATE_ELEMENT_COMMENT_IMAGE,
  FAKE_CALCULATE_ELEMENT_HISTORY_DE_IMAGE, FAKE_CALCULATE_ELEMENT_HISTORY_EN_IMAGE,
} from '@client/shared/toolkit';
import classNames from 'classnames';
import { FinancingElementDeleteModal } from './FinancingElementDeleteModal';
import {
  FinancingCustomPayoutModal,
  FinancingCustomPaybackModal,
  FinancingDistribution,
  FinancingDistributionType,
} from './Distribution';
import { useTranslation } from 'react-i18next';
import { i18n, formatNumber, safeMutation, getLanguageAndLocale, formatDateOnly } from '@client/shared/utilities';
import { FinancingFeesModal } from './FinancingFeeModal';
import { useLoadedProjectId, useLoadedVariant } from '@client/project/store';
import { DecoratedElement, FinancingElement } from '../hooks';
import { useFeatureFlags } from '@client/shared/store';
import { useValidateCanWriteCatalogElement, useValidateProjectPermission } from '@client/shared/permissions'
import { useDispatch } from 'react-redux';
import { EuriborRatesModal } from './EuriborRatesModal';
import { FinancingPurposeOverviewModal } from './FinancingPurposeOverviewModal';
import { SlideOverTotalSection } from './SlideOverTotalSection';
import { CalculateElementDocuments } from './Documents';
import { useFinancingElementErrors } from '../hooks';
import { FinancingGrantTimeLineInput } from './Distribution';
import { FormattedCurrency, TimeLineElementInput, timelineReadModelToPayload } from '@client/project/shared';

interface FinancingElementSlideOverProps extends SlideOverOnCloseProps {
  variantId?: string;
  selectedElement?: DecoratedElement<FinancingElement>;
  disabled?: boolean;
  catalogId?: string;
  financingCatalogId?: string;
  errors: FinancingError[];
  onShowTransactions?: (element: FinancingElementReadModel) => void;
  preselectedGroupId?: string | null;
}

type NestedModal = 'None' | 'Delete';
type SubArea = 'Values' | 'Documents' | 'History' | 'Comments';

const getFinancingTypeOptions = () => {
  return [
    {
      label: i18n.t('projectCalculate.financingType.OwnCapital'),
      value: 'OwnCapital' as FinancingType,
    },
    {
      label: i18n.t('projectCalculate.financingType.Grant'),
      value: 'Grant' as FinancingType,
    },
    {
      label: i18n.t('projectCalculate.financingType.Fund'),
      value: 'Fund' as FinancingType,
    },
    {
      label: i18n.t('projectCalculate.financingType.Credit'),
      value: 'Credit' as FinancingType,
    },
  ];
};

const getLoanTypeOptions = () => {
  return [
    {
      label: i18n.t('projectCalculate.loanTypes.MoneyMarketCredit'),
      value: 'MoneyMarketCredit' as LoanTypes,
    },
    {
      label: i18n.t('projectCalculate.loanTypes.AccountCredit'),
      value: 'CurrentAccountCredit' as LoanTypes,
    },
    {
      label: i18n.t('projectCalculate.loanTypes.AvalCredit'),
      value: 'Avalkredit' as LoanTypes,
    },
    {
      label: i18n.t('projectCalculate.loanTypes.CashAccount'),
      value: 'CashAccount' as LoanTypes,
    },
  ];
};

const getInterestRateMethodOptions = () => [
  {
    label: i18n.t('projectCalculate.interestRateMethod.Days30360'),
    value: 'Days30360' as InterestRateMethod,
  },
  {
    label: i18n.t('projectCalculate.interestRateMethod.Act360'),
    value: 'Act360' as InterestRateMethod,
  },
  {
    label: i18n.t('projectCalculate.interestRateMethod.Act365'),
    value: 'Act365' as InterestRateMethod,
  },
];

const getInterestPeriodOptions = () => [
  {
    label: i18n.t('projectCalculate.interestPeriod.Monthly'),
    value: 'Monthly' as InterestPeriod,
  },
  {
    label: i18n.t('projectCalculate.interestPeriod.Quarterly'),
    value: 'Quarterly' as InterestPeriod,
  },
  {
    label: i18n.t('projectCalculate.interestPeriod.Yearly'),
    value: 'Yearly' as InterestPeriod,
  },
  {
    label: i18n.t('projectCalculate.interestPeriod.Biannual'),
    value: 'Biannual' as InterestPeriod,
  },
  {
    label: i18n.t('projectCalculate.interestPeriod.End'),
    value: 'End' as InterestPeriod,
  },
];

const getInterestTypes = () => [
  {
    label: i18n.t('projectCalculate.interestRateTypes.Fixed'),
    value: 'Fixed' as InterestRateType,
  },
  {
    label: i18n.t('projectCalculate.interestRateTypes.Euribor'),
    value: 'Euribor' as InterestRateType,
  },
  // {
  //   label: i18n.t('projectCalculate.interestRateTypes.Variable'),
  //   value: 'Variable' as InterestRateType,
  // },
];

const getEuriborPeriods = () => [
  {
    label: i18n.t('projectCalculate.euriborPeriods.OneMonth'),
    value: 'OneMonth' as EuriborPeriod,
  },
  {
    label: i18n.t('projectCalculate.euriborPeriods.ThreeMonths'),
    value: 'ThreeMonths' as EuriborPeriod,
  },
  {
    label: i18n.t('projectCalculate.euriborPeriods.SixMonths'),
    value: 'SixMonths' as EuriborPeriod,
  },
  {
    label: i18n.t('projectCalculate.euriborPeriods.TwelveMonths'),
    value: 'TwelveMonths' as EuriborPeriod,
  },
];

export const FinancingElementSlideOver = ({
  disabled,
  selectedElement,
  catalogId,
  onClose,
  variantId,
  financingCatalogId,
  onShowTransactions,
  errors,
  preselectedGroupId,
}: FinancingElementSlideOverProps) => {
  const { t } = useTranslation();
  const submitRef = useRef<HTMLButtonElement>(null);

  const { fakeUi: showFakeUi } = useFeatureFlags();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch = useDispatch<any>();
  const [subarea, setSubarea] = useState<SubArea>('Values');

  const currentLanguage = getLanguageAndLocale().language;

  const projectId = useLoadedProjectId();

  const catalogElementId = selectedElement?.element.group
    ? selectedElement?.element.group?.groupId
    : selectedElement?.element.financingElement?.financingCatalogElementId;
  const canDeleteFinancing = useValidateProjectPermission(['FINANCING_DELETE'], projectId ?? '');
  const readOnly = !useValidateCanWriteCatalogElement(projectId ?? '', catalogId, catalogElementId) || disabled;

  const elementId = selectedElement?.element.financingElement?.id;
  const isLocked = selectedElement?.element.financingElement?.isLocked ?? false;

  const isEditing = !!elementId;
  const hasCatalog = !!financingCatalogId;

  const { data: financingCatalog, isFetching: isFetchingFinancingCatalog } = useApiGetProjectWritableFinancingCatalogQuery(
    {
      projectId: projectId ?? '',
      id: financingCatalogId ?? 'unset',
    },
    {
      skip: !financingCatalogId,
    }
  );

  const [postElement, { isLoading: isCreating }] = useApiPostCreateFinancingElementMutation();
  const [putElement, { isLoading: isUpdating }] = useApiPostUpdateFinancingElementMutation();

  const [isDeleting, setIsDeleting] = useState(false)

  const { data: dataFinancingElement, isFetching: isFetchingFinancingElement } = useApiGetFinancingElementQuery(
    {
      projectId: projectId ?? 'unset',
      calculationModelId: variantId ?? 'unset',
      id: elementId ?? '',
    },
    { skip: !isEditing || !elementId || isDeleting }
  );

  const [financeAllCosts, setFinanceAllCosts] = useState(false);
  const [purposeFinanceElementIds, setPurposeFinanceElementIds] = useState<string[]>([]);
  const [effectiveCostCatalogIds, setEffectiveCostCatalogIds] = useState<string[]>([]);
  const [financingPurposeId, setFinancingPurposeId] = useState<string | null | undefined>(null);
  const onSelectFinanceElement = (financeElementId: string[]) => {
    setPurposeFinanceElementIds(financeElementId);
  };

  const {data:financingMetadata, isFetching: isLoadingMetadata} = useApiGetFinancingElementsMetadataQuery({
    projectId: projectId ?? 'unset',
    calculationModelId: variantId ?? '',
  });

  const filteredFinanceElements = useMemo(() => {
    return (
      financingMetadata?.readModel.elements
        .map((e) => ({ label: `${e.code} - ${e.description}`, value: e.id }))
        .filter((e) => e.value !== elementId) || []
    );
  }, [elementId, financingMetadata]);

  // cleanup the selection of dropdown
  useEffect(() => {
    setPurposeFinanceElementIds([]);
  }, [elementId]);

  // this is the value
  // const value = calculateValue();

  const element = dataFinancingElement?.readModel;
  const { data: catalog, isFetching: isFetchingCatalog } = useApiGetCatalogQuery({ id: catalogId ?? '' });

  const [nestedModal, setNestedModal] = useState<NestedModal>('None');

  const [code, setCode] = useState(element?.code ?? '');
  const [selectedFinancingCatalogElementId, setFinancingCatalogElementId] = useState(
    element?.financingCatalogElementId
      ? element?.financingCatalogElementId
      : preselectedGroupId && preselectedGroupId !== 'unassigned'
      ? preselectedGroupId
      : null
  );
  const [title, setTitle] = useState(element?.title || element?.description || '');
  const [provider, setProvider] = useState(element?.provider ?? undefined);
  const [description, setDescription] = useState(element?.description ?? '');
  const [nominalAmount, setNominalAmount] = useState(element?.nominalAmount);
  const [financingType, setFinancingType] = useState(element?.financingType ?? ('OwnCapital' as FinancingType));
  const [payoutType, setPayoutType] = useState(element?.payoutPattern ?? ('AllInTheBeginning' as PaymentPlanType));
  const [customPayoutPlan, setCustomPayoutPlan] = useState(element?.customPayoutPlan ?? []);
  const [customPaybackPlan, setCustomPaybackPlan] = useState(element?.customPaybackPlan ?? []);
  const [grantAmount, setGrantAmount] = useState(element?.grantAmount);
  const [grantDate, setGrantDate] = useState(element?.grantDate);

  const [payoutTimeline, setPayoutTimeline] = useState(element?.payoutTimeline);
  const [paybackType, setPaybackType] = useState(element?.paybackPattern ?? ('EndOfTheProject' as PaymentPlanType));
  const [paybackTimeline, setPaybackTimeline] = useState(element?.paybackTimeline);

  const [interestRateMethod, setInterestRateMethod] = useState(element?.interestRateReadModel?.interestMethod);
  const [interestPeriod, setInterestPeriod] = useState(element?.interestRateReadModel?.interestPeriod);
  const [interestType, setInterestType] = useState(
    element?.interestRateReadModel?.interestRateType ?? ('Fixed' as InterestRateType)
  );
  const [bankMarge, setBankMarge] = useState<number>(element?.interestRateReadModel?.bankMarge ?? 0);
  const [liquidityPremium, setLiquidityPremium] = useState<number>(element?.interestRateReadModel?.liquidityPremium ?? 0);
  const [euriborPeriod, setEuriborPeriod] = useState(
    element?.interestRateReadModel?.euriborPeriod ?? ('ThreeMonths' as EuriborPeriod)
  );

  const [interestRate, setInterestRate] = useState(element?.interestRateReadModel?.interestRate);
  const [transactions, setTransactions] = useState(element?.transactions ?? []);
  const [residualDebt, setResidualDebt] = useState(element?.residualDebt);
  const [repaymentMethod, setRepaymentMethod] = useState(element?.repaymentMethod ?? ('Annuity' as RepaymentMethod));
  const [annuityPercentage, setAnnuityPercentage] = useState(element?.initialAnnuityRepaymentPercentage);
  const [interestCostCatalogId, setInterestCostCatalogId] = useState(element?.interestRateReadModel?.interestCostCatalogId);

  const [payInterestOnCalculation, setPayInterestOnCalculation] = useState(
    element?.interestRateReadModel?.payInterestOnCalculation ?? false
  );
  const [commitmentInterestRate, setCommitmentInterestRate] = useState(
    element?.interestRateReadModel?.commitmentInterestRate
  );
  const [commitmentTimeline, setCommitmentTimeline] = useState(element?.interestRateReadModel?.commitStartDateTimelineReadModel ?? undefined);
  const [partOfIrr, setPartOfIrr] = useState(element?.partOfIrr);
  const [calculatedIrr, setCalculatedIrr] = useState(element?.calculatedIrr);

  const [firstInterestTimeline, setFirstInterestTimeline] = useState(
    element?.interestRateReadModel?.firstInterestTimelineReadModel ?? undefined
  );

  const [firstEuriborDate, setFirstEuriborDate] = useState<Date | undefined>(
    element?.interestRateReadModel?.firstEuriborDate ? new Date(element?.interestRateReadModel?.firstEuriborDate) : undefined
  );

  const [isCustomPaymentsModelOpen, setIsCustomPaymentsModelOpen] = useState(false);
  const [isCustomPaybackModelOpen, setIsCustomPaybackModelOpen] = useState(false);
  const [isFeesModalOpen, setIsFeesModalOpen] = useState(false);
  const [isEuriborModalOpen, setIsEuriborModalOpen] = useState(false);
  const [isPurposeOverviewModalOpen, setIsPurposeOverviewModalOpen] = useState(false);

  const [loanType, setLoanType] = useState(element?.loanType);
  const [fees, setFees] = useState(element?.fees);

  const [financedCostCatalogIds, setFinancedCostCatalogIds] = useState(element?.financedCostCatalogElementIds ?? []);

  const isGrant = financingType === 'Grant';
  const isOwnCapital = financingType === 'OwnCapital';

  const isVariablePayout = payoutType === 'Variable';
  const isVariablePayback = paybackType === 'Variable';
  const isRecurringPayback = paybackType === 'MonthlyLinear' || paybackType === 'QuarterlyLinear';

  const isLoan = financingType === 'Credit';
  const isFixedInterestRate = interestType === 'Fixed';
  const isEuriborInterestRate = interestType === 'Euribor';

  const hasInterest = interestRate || isEuriborInterestRate;

  const { data } = useLoadedVariant();
  const catalogElements = data?.calculationModel.payload.flatCatalogElements ?? [];

  const selectableCatalogElements = useMemo(() => {
    const elements = data?.calculationModel.payload.flatCatalogElements ?? [];
    return elements
      .map((c) => ({ label: `${c.code} - ${c.description}`, value: c.id, hasParent: c.hasParent }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [data?.calculationModel.payload.flatCatalogElements]);

  const [calculationModelEndDate, setCalculationModelEndDate] = useState<Date>(new Date());

  useEffect(() => {
    if (data?.calculationModel?.modelMetadata?.calculationModelEndDate) {
      setCalculationModelEndDate(new Date(data.calculationModel.modelMetadata.calculationModelEndDate));
    }
  }, [data?.calculationModel?.modelMetadata?.calculationModelEndDate]);

  const adjustNominalAmount = () => {
    setNominalAmount((nominalAmount ?? 0) - financingDifference);
  };

  const translatedErrors = useFinancingElementErrors(errors);

  const getRequestPayload = () => {
    return {
      code: code,
      description: description,
      financingCatalogElementId: selectedFinancingCatalogElementId,
      financingType: financingType,
      name: element?.financingCatalogElementId ? null : title,
      nominalAmount: nominalAmount ?? 0,
      grantAmount: grantAmount ?? 0,
      grantDate: grantDate ?? null,
      residualDebt: isRecurringPayback ? residualDebt ?? null : 0,
      provider: provider,
      payoutPattern: payoutType,
      paybackPattern: paybackType,
      payoutTimeline: timelineReadModelToPayload(payoutTimeline ?? null),
      paybackTimeline: timelineReadModelToPayload(paybackTimeline ?? null),
      loanType: isLoan ? loanType : null,
      repaymentMethod: repaymentMethod,
      partOfIrr: partOfIrr,
      financedCostCatalogElementIds: financeAllCosts ? [] : financedCostCatalogIds,
      purposeFinancingGroupIds: purposeFinanceElementIds,
      financeAllCosts: financeAllCosts,
      interestRatePayload: hasInterest
        ? ({
            interestMethod: interestRateMethod,
            interestRate: interestRate && isFixedInterestRate ? Number(interestRate) : null,
            interestPeriod: interestPeriod,
            firstInterestTimelinePayload: interestPeriod !== 'End' && timelineReadModelToPayload(firstInterestTimeline),
            interestRateType: interestType,
            firstEuriborDate: firstEuriborDate ? formatDateOnly(firstEuriborDate) : null,
            bankMarge: isEuriborInterestRate ? Number(bankMarge) : null,
            liquidityPremium: liquidityPremium && isEuriborInterestRate ? Number(liquidityPremium) : null,
            euriborPeriod: euriborPeriod ?? ('ThreeMonths' as EuriborPeriod),
            payInterestOnCalculation: payInterestOnCalculation,
            commitmentInterestRate: commitmentInterestRate,
            commitStartDateTimelinePayload: timelineReadModelToPayload(commitmentTimeline),
            interestCostCatalogId: interestCostCatalogId,
          } as InterestRatePayload)
        : null,
      variablePayoutPlan: isVariablePayout
        ? customPayoutPlan.map((x) => {
            return {
              id: x.id === '' ? null : x.id,
              order: x.order,
              description: x.description,
              // sending only date without time
              paymentOn: x.paymentOn, //.substring(0, 10),
              amount: x.amount,
              percentage: x.percentage,
            };
          })
        : null,
      variablePaybackPlan: isVariablePayback
        ? customPaybackPlan.map((x) => {
            return {
              id: x.id === '' ? null : x.id,
              order: x.order,
              description: x.description,
              // sending only date without time
              paymentOn: x.paymentOn, //.substring(0, 10),
              amount: x.amount,
              rest: x.rest,
            };
          })
        : null,
      feesPayload: fees
        ? fees.map<FinancingFeePayload>((fee) => {
            return {
              id: fee.id,
              amount: fee.amount,
              date: fee.date,
              description: fee.description,
              costCatalogElementId: fee.costCatalogElementId,
            };
          })
        : [],
      annuityPercentage:
        isRecurringPayback && isLoan && repaymentMethod === 'AnnuityByPercentage' ? annuityPercentage : null,
    } as FinancingElementPayload;
  };

  const handleUpdate = async () => {
    try {
      await safeMutation(
        putElement,
        {
          body: getRequestPayload(),
          id: elementId ?? '-',
          calculationModelId: variantId ?? '-',
          projectId: projectId ?? 'unset',
        },
        isUpdating
      );

      // refresh all related purpose finance element ids
      purposeFinanceElementIds.forEach((id) => {
        dispatch(
          api.endpoints.apiGetFinancingElement.initiate(
            {
              projectId: projectId || '',
              calculationModelId: variantId || '',
              id,
            },
            { subscribe: false, forceRefetch: true }
          )
        );
      });

      onClose(true);
    } catch (e) {
      console.log(e);
    }
  };

  const handleCreate = async () => {
    try {
      await safeMutation(
        postElement,
        {
          body: getRequestPayload(),
          calculationModelId: variantId ?? '-',
          projectId: projectId ?? 'unset',
        },
        isCreating
      );

      onClose(true);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (isEditing) {
      setCode(element?.code ?? '');
      setFinancingCatalogElementId(
        element?.financingCatalogElementId
          ? element.financingCatalogElementId
          : preselectedGroupId && preselectedGroupId !== 'unassigned'
          ? preselectedGroupId
          : null
      );
      setDescription(element?.description ?? '');
      setTitle(
        (element?.financingCatalogElementId ? element?.description : element?.title || element?.description) ?? ''
      );
      setNominalAmount(element?.nominalAmount);
      setGrantAmount(element?.grantAmount);
      setGrantDate(element?.grantDate);
      setFinancingType(element?.financingType ?? ('OwnCapital' as FinancingType));
      setProvider(element?.provider ?? '');
      setPayoutType(element?.payoutPattern ?? ('AllInTheBeginning' as PaymentPlanType));
      setPaybackType(element?.paybackPattern ?? ('EndOfTheProject' as PaymentPlanType));
      setCustomPayoutPlan(element?.customPayoutPlan ?? []);
      setCustomPaybackPlan(element?.customPaybackPlan ?? []);
      setPayoutTimeline(element?.payoutTimeline);
      setPaybackTimeline(element?.paybackTimeline);
      setInterestRate(element?.interestRateReadModel?.interestRate);
      setInterestRateMethod(element?.interestRateReadModel?.interestMethod ?? 'Days30360');
      setInterestType(element?.interestRateReadModel?.interestRateType ?? ('Fixed' as InterestRateType));
      setEuriborPeriod(element?.interestRateReadModel?.euriborPeriod ?? ('ThreeMonths' as EuriborPeriod));
      setBankMarge(element?.interestRateReadModel?.bankMarge ?? 0);
      setLiquidityPremium(element?.interestRateReadModel?.liquidityPremium ?? 0);
      setTransactions(element?.transactions ?? []);
      setLoanType(element?.loanType);
      setResidualDebt(element?.residualDebt);
      setPayInterestOnCalculation(element?.interestRateReadModel?.payInterestOnCalculation ?? false);
      setCommitmentInterestRate(element?.interestRateReadModel?.commitmentInterestRate ?? null);
      setCommitmentTimeline(element?.interestRateReadModel?.commitStartDateTimelineReadModel ?? undefined);
      setInterestCostCatalogId(element?.interestRateReadModel?.interestCostCatalogId);
      setFees(element?.fees);
      setPartOfIrr(element?.partOfIrr);
      setCalculatedIrr(element?.calculatedIrr);
      setFirstInterestTimeline(
        element?.interestRateReadModel?.firstInterestTimelineReadModel ?? undefined);
      setFinancedCostCatalogIds(element?.financedCostCatalogElementIds ?? []);
      setPurposeFinanceElementIds(element?.purposeFinancingElementIds ?? []);
      setFinancingPurposeId(element?.financingPurposeId);
      setRepaymentMethod(element?.repaymentMethod ?? ('Annuity' as RepaymentMethod));
      setAnnuityPercentage(element?.initialAnnuityRepaymentPercentage);
      if (isLocked && translatedErrors.indexOf(t('projectCalculate.FinancingElementLocked')) === -1)
        translatedErrors.push(t('projectCalculate.FinancingElementLocked'));
    } else {
      setFinancingCatalogElementId(
        preselectedGroupId && preselectedGroupId !== 'unassigned' ? preselectedGroupId : null
      );
      setDescription(t('projectCalculate.rowMenu.newEarningElementTitle'));
      setPaybackTimeline({
        startCalculationModelDeliveryPhaseId: null,
        startCalculationModelMileStoneId: null,
        startElementTimelineId: null,
        startOffset: 0,
        startType: 'ProjectEnd',
        effectiveStartDate: formatDateOnly(calculationModelEndDate),
        duration: 0,
        endDate: formatDateOnly(calculationModelEndDate),
        endOffset: 0,
        endType: 'ProjectEnd',
      });
    }
  }, [element, isEditing, t, isLocked, translatedErrors, calculationModelEndDate, preselectedGroupId]);

  const handleCloseNestedModal = (deleted: boolean) => {
    setNestedModal('None');

    if (deleted) {
      onClose(true);
    }
  };

  function handleCustomPayoutModalClose(plan: FinancingPaymentPlanElementReadModel[] | undefined) {
    if (plan) {
      setCustomPayoutPlan(plan);
    }

    setIsCustomPaymentsModelOpen(false);
  }

  const handleCustomPaybackModalClose = (plan: FinancingPaybackPlanElementReadModel[] | undefined) => {
    if (plan) {
      setCustomPaybackPlan(plan);
    }

    setIsCustomPaybackModelOpen(false);
  };

  const handleFeesModalClose = (fees: FinancingFeePayload[] | undefined) => {
    if (fees) {
      setFees(fees);
    }

    setIsFeesModalOpen(false);
  };

  const catalogOptions: BaseSelectOption[] = useMemo(() => {
    const options =
      catalog?.costCatalog?.elements.map(({ code, description, id }) => {
        return {
          label: (code || description) ? `${code} ${description}` : t('projectCalculate.costElementNoLabel'),
          value: id ?? 'no-id',
        };
      }) ?? [];
    options.push({
      label: '-',
      value: 'no-id',
    });
    return options.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
  }, [catalog?.costCatalog.elements, t]);

  const financingCatalogOptions: BaseSelectOption[] = useMemo(() => {
    const options =
      financingCatalog?.elements.map(({ code, description, id }) => {
        return {
          label: (code || description) ? `${code} ${description}` : t('projectCalculate.costElementNoLabel'),
          value: id ?? 'no-id',
        };
      }) ?? [];
    return options.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
  }, [financingCatalog?.elements, t]);

  const [totalFinancingAmount, setTotalFinancingAmount] = useState(element?.financingPurposeResult.totalFinancing ?? 0);
  const [totalCostsToFinance, setTotalCostsToFinance] = useState(element?.financingPurposeResult.totalCosts ?? 0);

  const financingDifference = totalFinancingAmount - totalCostsToFinance;

  useEffect(() => {
    let totalFinancing = nominalAmount ?? 0;

    const matchedPurposeElements = financingMetadata?.readModel.elements.filter(
      (x) => purposeFinanceElementIds.indexOf(x.id) !== -1 && x.id !== elementId
    );

    matchedPurposeElements?.forEach((matched) => {
      totalFinancing += matched.nominalAmount;
    });

    setTotalFinancingAmount(totalFinancing);
  }, [financingMetadata, purposeFinanceElementIds, nominalAmount, elementId]);

  useEffect(() => {
    if (financeAllCosts) {
      const allRootElements = selectableCatalogElements.filter((x) => !x.hasParent).map((c) => c.value);
      setEffectiveCostCatalogIds([...new Set(allRootElements)]);
    } else {
      let result = financedCostCatalogIds;
      const matchedPurposeElements = financingMetadata?.readModel.elements.filter(
        (x) => purposeFinanceElementIds.indexOf(x.id) !== -1 && x.id !== elementId
      );

      matchedPurposeElements?.forEach((matched) => {
        result = result.concat(matched.financedCostCatalogIds);
      });

      // Remove duplicates
      setEffectiveCostCatalogIds([...new Set(result)]);
    }
  }, [
    financedCostCatalogIds,
    selectableCatalogElements,
    financeAllCosts,
    financingMetadata?.readModel.elements,
    purposeFinanceElementIds,
    elementId,
  ]);

  const getCostCatalogElementValue = (categoryId: string): number => {
    const element = catalogElements.find((c) => c.id === categoryId);
    return element?.modelValues.total ?? element?.modelValues.calculatedValue ?? 0;
  };

  useEffect(() => {
    let totalCosts = 0;
    effectiveCostCatalogIds.forEach((selectedId) => {
      totalCosts += getCostCatalogElementValue(selectedId);
    });
    setTotalCostsToFinance(totalCosts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [effectiveCostCatalogIds, catalogElements]);

  const ltcValue = useMemo(() => {
    const ltc = ((nominalAmount ?? 0) * 100) / (totalCostsToFinance ?? 0);
    return Number(ltc.toFixed(2));
  }, [nominalAmount, totalCostsToFinance]);

  const [ltcIsFocused, setLtcIsFocused] = useState(false);

  const isLoading = useMemo(() => {
    return isFetchingFinancingElement || isFetchingCatalog || isFetchingFinancingCatalog || isCreating || isUpdating || isLoadingMetadata;
  }, [isFetchingFinancingElement, isFetchingCatalog, isFetchingFinancingCatalog, isCreating, isUpdating, isLoadingMetadata]);

  const firstInterestTimeInput = useMemo(() => {
    return firstInterestTimeline? {
      duration: 0,
      variantDeliveryPhaseId: firstInterestTimeline.startCalculationModelDeliveryPhaseId,
      offset: firstInterestTimeline.startOffset ?? 0,
      offsetPosition: firstInterestTimeline.startOffsetPosition,
      offsetUnit: firstInterestTimeline.startOffsetUnit,
      fixedDate: firstInterestTimeline.startFixedStartDate ?? firstInterestTimeline.effectiveStartDate,
      elementTimelineId: firstInterestTimeline.startElementTimelineId,
      type: firstInterestTimeline.startType,
      effectiveDate: firstInterestTimeline.effectiveStartDate,
    } : undefined;
  }, [firstInterestTimeline]);

  const commitmentTimeInput = useMemo(() => {
    return commitmentTimeline? {
      variantDeliveryPhaseId: commitmentTimeline.startCalculationModelDeliveryPhaseId,
      offset: commitmentTimeline.startOffset ?? 0,
      offsetPosition: commitmentTimeline.startOffsetPosition,
      offsetUnit: commitmentTimeline.startOffsetUnit,
      fixedDate: commitmentTimeline.startFixedStartDate ?? commitmentTimeline.effectiveStartDate,
      elementTimelineId: commitmentTimeline.startElementTimelineId,
      type: commitmentTimeline.startType,
      effectiveDate: commitmentTimeline.effectiveStartDate,
    } : undefined;
  }, [commitmentTimeline]);

  return (
    <>
      {isLoading && <LoadingIndicator mode="overlay" />}
      <SlideOver.Header
        onClose={onClose}
        title={isEditing ? title : t('projectCalculate.financingNewFinancingTitle')}
        subTitle={t('projectCalculate.financingNewFinancingSubTitle')}
        backgroundClassName="bg-gray-700"
      >
        <div className="flex flex-row pt-2 pl-8 bg-gray-700 text-white">
          <button
            type="button"
            className={classNames('px-4 pb-1 text block border-l border-r', {
              'font-bold border-b-2 border-white': subarea === 'Values',
            })}
            onClick={() => setSubarea('Values')}
          >
            <div className="flex felx-row items-center">{t('common.values')}</div>
          </button>
          {isEditing && (
            <>
              <button
                type="button"
                className={classNames('px-4 pb-1 text block border-r', {
                  'font-bold border-b-2 border-white': subarea === 'Documents',
                })}
                onClick={() => setSubarea('Documents')}
              >
                <div className="flex felx-row items-center">{t('common.documents')}</div>
              </button>
              {showFakeUi && (
                <>
                  <button
                    type="button"
                    className={classNames('px-4 pb-1 text block border-l border-r', {
                      'font-bold border-b-2 border-white': subarea === 'History',
                    })}
                    onClick={() => setSubarea('History')}
                  >
                    <div className="flex felx-row items-center">{t('common.history')}</div>
                  </button>
                  <button
                    type="button"
                    className={classNames('px-4 pb-1 text block border-r', {
                      'font-bold border-b-2 border-white': subarea === 'Comments',
                    })}
                    onClick={() => setSubarea('Comments')}
                  >
                    <div className="flex felx-row items-center">{t('common.comments')}</div>
                  </button>
                </>
              )}
            </>
          )}
        </div>
      </SlideOver.Header>
      <SlideOver.Content
        onKeyEnter={() => {
          submitRef.current?.click();
        }}
      >
        {translatedErrors && translatedErrors.length > 0 && (
          <HintBox hintType="warning">{translatedErrors.join(`\n`)}</HintBox>
        )}

        {subarea === 'Comments' && (
          <div className="p-8">
            <img className="w-full h-auto" src={FAKE_CALCULATE_ELEMENT_COMMENT_IMAGE} alt="comments" />
          </div>
        )}

        {subarea === 'History' && (
          <div className="p-8">
            {currentLanguage === 'de' ? (
              <img className="w-full h-auto" src={FAKE_CALCULATE_ELEMENT_HISTORY_DE_IMAGE} alt="history" />
            ) : (
              <img className="w-full h-auto" src={FAKE_CALCULATE_ELEMENT_HISTORY_EN_IMAGE} alt="history" />
            )}
          </div>
        )}

        {subarea === 'Documents' && (
          <div className="m-8">
            <CalculateElementDocuments
              ownerId={elementId ?? ''}
              type="FinancingElement"
              canWrite={!readOnly}
              canDelete={canDeleteFinancing}
              documents={dataFinancingElement?.readModel.documents ?? []}
            />
          </div>
        )}

        {subarea === 'Values' && (
          <>
            <div className="mx-8 mt-8 bg-white">
              <div className="divide-gray-100 divide-y">
                {hasCatalog ? (
                  <ComboSelect
                    label={t('projectCalculate.groupLabelGroupSelector')}
                    options={financingCatalogOptions}
                    value={selectedFinancingCatalogElementId ?? ''}
                    disabled={readOnly || element?.financingCatalogElementId !== undefined}
                    onChange={(selected) => setFinancingCatalogElementId(selected)}
                    icon={<EditNodeIcon />}
                  />
                ) : (
                  <TextInput
                    label={t('projectCalculate.financingElementLabelTitle')}
                    value={title}
                    onChange={(value) => setTitle(value)}
                    icon={<TagWindowIcon />}
                    disabled={readOnly}
                  />
                )}
                <TextInput
                  label={t('projectCalculate.financingElementLabelPosition')}
                  value={code}
                  onChange={(value) => setCode(value)}
                  icon={<EditNodeIcon />}
                  disabled={readOnly}
                />
                <TextInput
                  label={t('projectCalculate.financingElementLabelDescription')}
                  value={description}
                  onChange={(value) => setDescription(value)}
                  icon={<EditFilledIcon />}
                  disabled={readOnly}
                />
              </div>
            </div>
            <SlideOverTitle title={t('projectCalculate.financingElementLabelFinancing')} className="px-8" />
            <div className="mx-8 bg-white">
              <div className="divide-gray-100 divide-y">
                <BaseSelect
                  label={t('projectCalculate.financingElementLabelFinancingType')}
                  options={getFinancingTypeOptions()}
                  value={financingType}
                  onChange={(value) => setFinancingType(value as FinancingType)}
                  icon={<BankBuildingIcon />}
                  disabled={readOnly}
                />
                {isOwnCapital && (
                  <TextInput
                    label={t('projectCalculate.financingElementLabelProvider')}
                    value={provider ?? ''}
                    onChange={(value) => {
                      setProvider(value);
                    }}
                    icon={<ContactUsIcon />}
                    disabled={readOnly}
                  />
                )}
                {isLoan && (
                  <BaseSelect
                    label={t('projectCalculate.financingElementLabelLoanType')}
                    options={getLoanTypeOptions()}
                    value={loanType ?? ('AmortizationLoan' as LoanTypes)}
                    onChange={(value) => setLoanType(value as LoanTypes)}
                    icon={<BankBuildingIcon />}
                    disabled={readOnly}
                  />
                )}
                <div className="relative">
                  <NumberInput
                    label={t('projectCalculate.financingElementLabelAmount')}
                    value={nominalAmount}
                    onChange={(value) => {
                      setNominalAmount(value ?? undefined);
                    }}
                    icon={<MastercardCreditCardIcon />}
                    disabled={readOnly || isLocked}
                  />
                  <div className="absolute right-0 top-0 bottom-0 pr-3 text-right text-gray-600 flex flex-col"></div>
                </div>
                <div className="relative">
                  <NumberInput
                    label={t('projectCalculate.financingElementLabelGrantAmount')}
                    value={grantAmount}
                    onChange={(value) => {
                      setGrantAmount(value ?? undefined);
                    }}
                    icon={<MastercardCreditCardIcon />}
                    disabled={readOnly || isLocked}
                  />
                  <div className="absolute right-0 top-0 bottom-0 pr-3 text-right text-gray-600 flex flex-col"></div>
                </div>

                <FinancingGrantTimeLineInput
                  timing={grantDate as ElementTimelineReadModel}
                  variantId={variantId}
                  setGrantDate={setGrantDate}
                  disabled={readOnly || isLocked}
                />

                {/* Payout Plan */}
                <FinancingDistribution
                  type={FinancingDistributionType.PAYOUT}
                  selectedPaymentPlan={payoutType}
                  disabled={readOnly}
                  variantId={variantId}
                  timing={payoutTimeline as ElementTimelineReadModel}
                  setTimeline={setPayoutTimeline}
                  setPaymentPlanType={(value) => {
                    setPayoutType(value as PaymentPlanType);
                    // if (value === 'Variable') setIsCustomPaymentsModelOpen(true);
                  }}
                  paymentPlan={customPayoutPlan}
                  nominalAmount={Number(nominalAmount ?? 0)}
                  savePaymentPlan={(plan) => {
                    setCustomPayoutPlan(plan as FinancingPaymentPlanElementReadModel[]);
                  }}
                  ltc={ltcValue}
                  costCatalogElementIds={effectiveCostCatalogIds}
                />
                {/* Payback Plan */}
                <FinancingDistribution
                  type={FinancingDistributionType.PAYBACK}
                  selectedPaymentPlan={paybackType}
                  disabled={readOnly}
                  variantId={variantId}
                  timing={paybackTimeline as ElementTimelineReadModel}
                  setTimeline={setPaybackTimeline}
                  setPaymentPlanType={(value) => {
                    setPaybackType(value as PaymentPlanType);
                    // if (value === 'Variable') setIsCustomPaybackModelOpen(true);
                  }}
                  isRecurringPayback={isRecurringPayback}
                  residualDebt={residualDebt}
                  setResidualDebt={setResidualDebt}
                  paymentPlan={customPaybackPlan}
                  savePaymentPlan={(plan) => {
                    setCustomPaybackPlan(plan as FinancingPaybackPlanElementReadModel[]);
                  }}
                  ltc={ltcValue}
                  costCatalogElementIds={effectiveCostCatalogIds}
                  isLoan={isLoan}
                  repaymentMethod={repaymentMethod}
                  setRepaymentMethod={setRepaymentMethod}
                  annuityPercentage={annuityPercentage}
                  setAnnuityPercentage={setAnnuityPercentage}
                />
                <div className="flex">
                  <div className="grow border-r border-gray-100">
                    <BooleanInput
                      icon={<IrrSmallIcon />}
                      label={t('projectCalculate.financingElementDescriptionIrr')}
                      value={partOfIrr}
                      onChange={() => {
                        setPartOfIrr(!partOfIrr);
                      }}
                      disabled={readOnly}
                    />
                  </div>
                  <div className="pl-4">
                    <div className="right-0 top-0 bottom-0 pr-3 text-right text-gray-600 flex flex-col">
                      <div className="flex flex-row justify-end">
                        <div className="text-right mt-2">{formatNumber(calculatedIrr)} %</div>
                      </div>
                      <div className="text-xxs mr-2">{t('projectCalculate.financingElementCalculatedIrr')}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-8 mb-2 text-xl font-light flex flex-row items-end justify-between">
              <SlideOverTitle title={t('projectCalculate.financingElementPurposeTitle')} />
              <div>
                {financingDifference !== 0 && (
                  <Button
                    variant="outlinePrimary"
                    className="text-xs font-bold text-gray-800 button right-0"
                    onClick={() => adjustNominalAmount()}
                  >
                    {t('projectCalculate.financingElementReconcileLoan')}
                  </Button>
                )}
              </div>
            </div>
            <div className="m-8 mt-0 mb-0 bg-white flex flex-col divide-y">
              <MultiSelect
                label={t('projectCalculate.financingElementPurposeElements')}
                placeHolder={t('projectCalculate.financingElementPurposeElements')}
                options={filteredFinanceElements}
                value={purposeFinanceElementIds}
                onChange={onSelectFinanceElement}
                icon={<BankBuildingIcon />}
              />
              <BooleanInput
                icon={<ReceiveCashIcon className="h-5 w-5" />}
                label={t('projectCalculate.financingElementFinanceAll')}
                subLabel={t('projectCalculate.financingElementFinanceAll')}
                value={financeAllCosts}
                onChange={() => {
                  setFinanceAllCosts(!financeAllCosts);
                }}
                disabled={disabled}
              />
              {!financeAllCosts && (
                <MultiSelect
                  label={t('projectCalculate.financingElementFinancedCostGroups')}
                  placeHolder={t('projectCalculate.financingElementFinancedCostGroups')}
                  options={selectableCatalogElements}
                  value={financedCostCatalogIds}
                  onChange={(x) => setFinancedCostCatalogIds(x)}
                  disabled={readOnly}
                  className="w-full"
                  icon={<InitiateMoneyTransferIcon />}
                />
              )}
              <MultiSelect
                label={t('projectCalculate.financingElementEffectiveFinancedCostGroups')}
                placeHolder={t('projectCalculate.financingElementEffectiveFinancedCostGroups')}
                options={selectableCatalogElements}
                value={effectiveCostCatalogIds}
                onChange={() => {
                  return;
                }}
                disabled={false}
                className="w-full"
                icon={<InitiateMoneyTransferIcon />}
              />
              <NumberInput
                label={
                  isOwnCapital
                    ? t('projectCalculate.financingElementEquityRation')
                    : t('projectCalculate.financingElementLtc')
                }
                value={ltcValue}
                onFocus={() => setLtcIsFocused(true)}
                onBlur={() => setLtcIsFocused(false)}
                onChange={(value) => {
                  ltcIsFocused &&
                    (totalCostsToFinance
                      ? setNominalAmount(Number((((value ?? 0) / 100) * totalCostsToFinance).toFixed(2)))
                      : setNominalAmount(0));
                }}
                icon={<PercentageIcon />}
                disabled={readOnly}
              />
            </div>
            <div className="mt-2 flex gap-5 text-right mx-8 mb-4 justify-end">
              <div className="flex flex-col h-full">
                <div className="text-xs text-gray-600 leading-5">
                  {t('projectCalculate.financingElementTotalFinancing')}
                </div>
                <div className="text-xs text-gray-600 leading-5">
                  {t('projectCalculate.financingElementTotalCostsToFinance')}
                </div>
                <div className="text-xs text-gray-600 leading-5">
                  {t('projectCalculate.financingElementFinancingDifference')}
                </div>
              </div>
              <div className="flex flex-col h-full">
                <div className="text-sm font-bold whitespace-nowrap">
                  <FormattedCurrency amount={totalFinancingAmount} options={{ maxDigits: 2, minDigits: 2 }} />
                </div>
                <div className="text-sm font-bold whitespace-nowrap">
                  <FormattedCurrency amount={totalCostsToFinance} options={{ maxDigits: 2, minDigits: 2 }} />
                </div>
                <div
                  className={classNames(
                    'text-sm font-bold whitespace-nowrap',
                    financingDifference > 0 ? 'text-green-800' : 'text-red-800'
                  )}
                >
                  <FormattedCurrency amount={financingDifference} options={{ maxDigits: 2, minDigits: 2 }} />
                </div>
              </div>
            </div>
            <div className="px-8">
              {effectiveCostCatalogIds.length > 0 && (elementId || financingPurposeId) && (
                <Button
                  variant="outlinePrimary"
                  className="text-xs block ml-auto mr-0"
                  onClick={() => setIsPurposeOverviewModalOpen(true)}
                >
                  {t('projectCalculate.financingElementShowPurposeOverview')}
                </Button>
              )}
              <Modal
                isOpen={isPurposeOverviewModalOpen}
                onClose={() => setIsPurposeOverviewModalOpen(false)}
                variant="large"
              >
                <FinancingPurposeOverviewModal
                  financingPurposeId={financingPurposeId ?? ''}
                  financingElementId={elementId ?? ''}
                  variantId={variantId ?? 'unset'}
                  onClose={() => {
                    setIsPurposeOverviewModalOpen(false);
                  }}
                />
              </Modal>
            </div>

            {!isGrant && (
              <div className="m-8 mb-0 bg-white">
                <div className="divide-gray-100 divide-y">
                  <BaseSelect
                    label={t('projectCalculate.financingElementLabelInterestType')}
                    options={getInterestTypes()}
                    value={interestType ?? ('Fixed' as InterestRateType)}
                    onChange={(value) => {
                      setInterestType(value as InterestRateType);
                    }}
                    icon={<ExchangeIcon className="h-5 w-5" />}
                    disabled={readOnly || isLocked}
                  />

                  {isFixedInterestRate && (
                    <NumberInput
                      label={t('projectCalculate.financingElementLabelInterestRate')}
                      value={interestRate}
                      onChange={(value) => {
                        setInterestRate(value ?? undefined);
                      }}
                      icon={<PercentageIcon />}
                      disabled={readOnly || isLocked}
                    />
                  )}
                  {interestType === 'Euribor' && (
                    <>
                      <Modal isOpen={isEuriborModalOpen} onClose={() => setIsEuriborModalOpen(false)} variant="large">
                        <EuriborRatesModal
                          onClose={() => {
                            setIsEuriborModalOpen(false);
                          }}
                        />
                      </Modal>
                      <div onClick={() => setIsEuriborModalOpen(true)} className="text-sm p-2 cursor-pointer">
                        {t('projectCalculate.updateEuriborRates')}
                      </div>
                      <div className="flex">
                        <BaseSelect
                          label={t('projectCalculate.financingElementLabelEuriborPeriod')}
                          options={getEuriborPeriods()}
                          value={euriborPeriod ?? ('ThreeMonths' as EuriborPeriod)}
                          onChange={(value) => {
                            setEuriborPeriod(value as EuriborPeriod);
                          }}
                          icon={<StocksIcon />}
                          disabled={readOnly || isLocked}
                        />
                        <DatePicker
                          label={t('projectCalculate.financingElementFirstEuriborDate')}
                          placeHolder={t('projectCalculate.financingElementFirstEuriborDate')}
                          value={firstEuriborDate}
                          onChange={(x) => setFirstEuriborDate(x ?? undefined)}
                          disabled={readOnly || isLocked}
                          className="w-full"
                          icon={<TearOffCalenderIcon className="h-5 w-5" />}
                        />
                      </div>
                      <div className="flex">
                        <NumberInput
                          label={t('projectCalculate.financingElementLabelLiquidityPremium') + ' %'}
                          value={liquidityPremium}
                          className="w-3/6"
                          onChange={(value) => {
                            setLiquidityPremium(value ?? 0);
                          }}
                          icon={<CashIcon />}
                          disabled={readOnly || isLocked}
                        />
                        <NumberInput
                          label={t('projectCalculate.financingElementLabelBankMarge') + ' %'}
                          value={bankMarge}
                          className="w-3/6"
                          onChange={(value) => {
                            setBankMarge(value ?? 0);
                          }}
                          icon={<EuroMoneyIcon />}
                          disabled={readOnly || isLocked}
                        />
                      </div>
                    </>
                  )}

                  {interestPeriod !== 'End' && (
                    <TimeLineElementInput
                      timeLineData={firstInterestTimeInput}
                      label={t('projectCalculate.financingElementFirstInterestDate')}
                      variantId={variantId ?? ''}
                      disabled={readOnly || isLocked}
                      onChange={(data) => {
                        const newTimeLineElement: ElementTimelineReadModel = {
                          id: element?.interestRateReadModel?.firstInterestTimelineReadModel?.id ?? null,
                          duration: 0,
                          startCalculationModelDeliveryPhaseId: data?.variantDeliveryPhaseId,
                          startCalculationModelMileStoneId: data?.variantMileStoneId,
                          startOffset: data?.offset ?? 0,
                          startOffsetPosition: data?.offsetPosition,
                          startOffsetUnit: data?.offsetUnit,
                          startFixedStartDate: data?.fixedDate,
                          startElementTimelineId: data?.elementTimelineId,
                          startType: data?.type ?? 'FixedDates',
                          endType: 'FixedDates',
                          effectiveStartDate: data?.effectiveDate,
                        };
                        setFirstInterestTimeline(newTimeLineElement)
                      }}
                      icon={<TearOffCalenderIcon className='h-5 w-5' />}
                      type="start"
                      showTimeFrameElements={false}
                    />
                  )}


                  <BooleanInput
                    icon={<ReceiveCashIcon className="h-5 w-5" />}
                    label={t('projectCalculate.financingElementDescriptionPayInterestOnCalculation')}
                    subLabel={t('projectCalculate.financingElementLabelPayInterestOnCalculation')}
                    value={payInterestOnCalculation}
                    onChange={() => {
                      setPayInterestOnCalculation(!payInterestOnCalculation);
                    }}
                    disabled={readOnly || isLocked}
                  />

                  <BaseSelect
                    label={t('projectCalculate.financingElementLabelInterestMethod')}
                    options={getInterestRateMethodOptions()}
                    value={interestRateMethod ?? ('Days30360' as InterestRateMethod)}
                    onChange={(value) => {
                      setInterestRateMethod(value as InterestRateMethod);
                    }}
                    icon={<MaintenanceDateIcon className="h-5 w-5" />}
                    disabled={readOnly || isLocked}
                  />
                  <BaseSelect
                    label={t('projectCalculate.financingElementLabelInterestPeriod')}
                    options={getInterestPeriodOptions()}
                    value={interestPeriod ?? ('Monthly' as InterestPeriod)}
                    onChange={(value) => {
                      setInterestPeriod(value as InterestPeriod);
                    }}
                    icon={<TransactionIcon className="h-5 w-5" />}
                    disabled={readOnly || isLocked}
                  />

                  {payoutType !== 'AllInTheBeginning' && (
                    <>
                      <NumberInput
                        label={t('projectCalculate.financingElementLabelCommitmentInterestRate')}
                        value={commitmentInterestRate}
                        onChange={(value) => {
                          setCommitmentInterestRate(value ?? null);
                        }}
                        icon={<InitialStateIcon className="h-5 w-5" />}
                        disabled={readOnly || isLocked}
                      />
                      <TimeLineElementInput
                        timeLineData={commitmentTimeInput}
                        label={t('projectCalculate.financingElementLabelCommitmentInterestDate')}
                        variantId={variantId ?? ''}
                        disabled={readOnly || isLocked}
                        onChange={(data) => {
                          const newTimeLineElement: ElementTimelineReadModel = {
                            id: element?.interestRateReadModel?.commitStartDateTimelineReadModel?.id ?? null,
                            duration: 0,
                            startCalculationModelDeliveryPhaseId: data?.variantDeliveryPhaseId,
                            startCalculationModelMileStoneId: data?.variantMileStoneId,
                            startOffset: data?.offset ?? 0,
                            startOffsetPosition: data?.offsetPosition,
                            startOffsetUnit: data?.offsetUnit,
                            startFixedStartDate: data?.fixedDate,
                            startElementTimelineId: data?.elementTimelineId,
                            startType: data?.type ?? 'FixedDates',
                            endType: 'FixedDates',
                            effectiveStartDate: data?.effectiveDate,
                          };
                          setCommitmentTimeline(newTimeLineElement)
                        }}
                        icon={<UnavailableIcon className="h-5 w-5" />}
                        type="start"
                        showTimeFrameElements={false}
                      />
                    </>
                  )}

                  <ComboSelect
                    disabled={readOnly}
                    label={t('projectCalculate.groupLabelInterestSelector')}
                    options={catalogOptions}
                    value={interestCostCatalogId ?? ''}
                    className="min-w-full"
                    onChange={(selected) => {
                      setInterestCostCatalogId(selected === 'no-id' ? undefined : selected);
                    }}
                    icon={<EditNodeIcon />}
                  />
                </div>
              </div>
            )}

            <div className="flex gap-2 justify-end px-8 mt-8 mb-4">
              <Button variant="outlinePrimary" className="text-xs" onClick={() => setIsFeesModalOpen(!isFeesModalOpen)}>
                {t('projectCalculate.financingFeesButton')}
              </Button>
              {onShowTransactions && element && transactions.length > 0 && (
                <Button variant="outlinePrimary" className="text-xs" onClick={() => onShowTransactions(element)}>
                  {t('projectCalculate.financingShowTransactions')}
                </Button>
              )}
            </div>
            {!!element?.totalFinancingCosts && (
              <SlideOverTotalSection
                label={t('projectCalculate.financingElementLabelTotalCosts')}
                value={<FormattedCurrency amount={element.totalFinancingCosts} />}
              />
            )}
          </>
        )}
      </SlideOver.Content>
      <SlideOver.Controls disabled={isLoading}>
        <div
          className={classNames('flex-grow flex-row flex justify-end', { 'justify-between': isEditing && !readOnly })}
        >
          {isEditing && canDeleteFinancing && !disabled && (
            <Button variant="warning" onClick={() => setNestedModal('Delete')}>
              {t('common.delete')}
            </Button>
          )}
          <div className="flex">
            <Button variant="secondary" onClick={() => onClose(false)} className="mr-2">
              {t('common.cancel')}
            </Button>
            {!readOnly && (
              <Button variant="primary" onClick={isEditing ? handleUpdate : handleCreate} innerRef={submitRef}>
                {t('common.save')}
              </Button>
            )}
          </div>
        </div>
      </SlideOver.Controls>

      <Modal isOpen={nestedModal === 'Delete'} onClose={handleCloseNestedModal}>
        <FinancingElementDeleteModal
          elementId={elementId}
          variantId={variantId}
          onClose={handleCloseNestedModal}
          setIsDeleting={setIsDeleting}
        />
      </Modal>

      <Modal isOpen={isCustomPaymentsModelOpen} onClose={() => handleCustomPayoutModalClose(undefined)} variant="large">
        <FinancingCustomPayoutModal
          onClose={(plan) => handleCustomPayoutModalClose(plan)}
          payoutPlan={customPayoutPlan}
          nominalAmount={Number(nominalAmount ?? 0)}
        />
      </Modal>

      <Modal isOpen={isCustomPaybackModelOpen} onClose={() => handleCustomPaybackModalClose(undefined)} variant="large">
        <FinancingCustomPaybackModal
          onClose={(plan) => handleCustomPaybackModalClose(plan)}
          paybackPlan={customPaybackPlan}
        />
      </Modal>

      <Modal isOpen={isFeesModalOpen} onClose={() => setIsFeesModalOpen(false)} variant="x-large">
        <FinancingFeesModal
          disabled={readOnly || isLocked}
          fees={fees}
          totalFinancingAmount={totalFinancingAmount}
          catalogId={catalogId}
          onClose={(fees) => handleFeesModalClose(fees)}
        />
      </Modal>
    </>
  );
};
