import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import {
  ApiGetDefaultValuesApiArg,
  ApiGetDefaultValuesApiResponse,
  ApiPostCreateCustomListItemApiArg,
  ApiPostCreateCustomListItemApiResponse,
  ApiPostUpdateCustomListItemApiResponse,
  ApiPostUpdateDefaultValuesApiArg,
  ApiPostUpdateDefaultValuesApiResponse,
  ApiPostUpdateCustomListItemApiArg,
  ApiDeleteCustomListItemApiResponse,
  ApiDeleteCustomListItemApiArg,
} from '../apiGenerated';
import { ApiTagTypes } from '../apiTagTypes';

export const enhancedApiDefaultValuesEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  apiGetDefaultValues: {
    providesTags: (
      _result: ApiGetDefaultValuesApiResponse,
      _error: FetchBaseQueryError,
      _args: ApiGetDefaultValuesApiArg,
    ) => [{ type: ApiTagTypes.DefaultValues, id: 'LIST' }],
  },
  /*------------------ INVALIDATES TAGS --------------------*/
  apiPostUpdateDefaultValues: {
    invalidatesTags: (
      _result: ApiPostUpdateDefaultValuesApiResponse,
      error: FetchBaseQueryError,
      _args: ApiPostUpdateDefaultValuesApiArg,
    ) => (
      error == null ? [
            { type: ApiTagTypes.DefaultValues, id: 'LIST' },
            { type: ApiTagTypes.TenantCatalog, id: 'COSTS' },
            { type: ApiTagTypes.TenantCatalog, id: 'RISKS' },
            { type: ApiTagTypes.TenantCatalog, id: 'EARNINGS' }
          ] : [ ]),
  },
  apiPostCreateCustomListItem: {
    invalidatesTags: (
      _result: ApiPostCreateCustomListItemApiResponse,
      error: FetchBaseQueryError,
      _args: ApiPostCreateCustomListItemApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.DefaultValues, id: 'LIST' },
            ApiTagTypes.Project,
            ApiTagTypes.Projects,
            ApiTagTypes.TaxonomyItem,
          ]
        : [],
  },
  apiPostUpdateCustomListItem: {
    invalidatesTags: (
      _result: ApiPostUpdateCustomListItemApiResponse,
      error: FetchBaseQueryError,
      _args: ApiPostUpdateCustomListItemApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.DefaultValues, id: 'LIST' },
            ApiTagTypes.Project,
            ApiTagTypes.Projects,
            ApiTagTypes.TaxonomyItem,
          ]
        : [],
  },
  apiDeleteCustomListItem: {
    invalidatesTags: (
      _result: ApiDeleteCustomListItemApiResponse,
      error: FetchBaseQueryError,
      _args: ApiDeleteCustomListItemApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.DefaultValues, id: 'LIST' },
            ApiTagTypes.Project,
            ApiTagTypes.Projects,
            ApiTagTypes.TaxonomyItem,
          ]
        : [],
  },
};
