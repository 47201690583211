import { BaseSelectOption, ComboSelect } from '@client/shared/toolkit';
import React, { useEffect, useMemo, useState } from 'react';
import { useFlattenCostElementRestBudgets } from '../hooks';
import { useTranslation } from 'react-i18next';
import { CatalogElementRestBudget, useApiGetCalculationModelRestBudgetMutation } from '@client/shared/api';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { safeMutation } from '@client/shared/utilities';


interface CostElementSelectProps {
  label: string
  icon?: React.ReactNode
  disabled?: boolean
  setCostElementId: (id: string) => void
  costElementId: string
  nonSelectedLabel?: string
  addNoneSelectedOption?: boolean
  handlePopoverVisibility?: (isOpen: boolean) => void
}
export const CostElementSelect = (props: CostElementSelectProps) => {
  const { t } = useTranslation();
  const {
    label,
    icon,
    disabled,
    setCostElementId,
    costElementId,
    nonSelectedLabel = '',
    addNoneSelectedOption = true,
    handlePopoverVisibility
  } = props

  const loadedVariantId = useLoadedVariantId();
  const loadedProjectId = useLoadedProjectId();
  const [postGetRestBudget, { isLoading }] = useApiGetCalculationModelRestBudgetMutation();
  const [restBudgetElements, setRestBudgetElements] = useState<CatalogElementRestBudget[]>([]);
  useEffect(() => {
    if (loadedVariantId && loadedProjectId) {
      const getRestBudget = async () => {
        const resp = await safeMutation(
          postGetRestBudget,
          {
            projectId: loadedProjectId ?? '',
            calculationModelId: loadedVariantId ?? '',
            body: [],
          },
          isLoading,
        );
        setRestBudgetElements(resp?.payload.catalogElements ?? []);
      };

      try {
        getRestBudget();
      } catch (e) {
        console.log(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const flattened = useFlattenCostElementRestBudgets(restBudgetElements);

  const costElementOptions = useMemo(() => {
    const options: BaseSelectOption[] = []
    if (addNoneSelectedOption) {
      const noneSelectedOption: BaseSelectOption = { value: 'None', label: nonSelectedLabel }
      options.push(noneSelectedOption)
    }

    if (flattened.length) {
      flattened.forEach((costGroupElement) => {
        if (costGroupElement.costElementId) {
          options.push({
            value: costGroupElement.costElementId,
            label: `${costGroupElement.code} - ${costGroupElement.name ? costGroupElement.name : t('projectCalculate.unnamedElement')}`,
            disabled: !costGroupElement.budgetingAllowed
          });
        }
        if (costGroupElement.costElements.length > 0) {
          costGroupElement.costElements.forEach((costElement) => {
            options.push({
              value: costElement.id,
              label: `${costGroupElement.code} - ${costElement.name ? costElement.name : t('projectCalculate.unnamedElement')}`,
              disabled: !costElement.budgetingAllowed
            });
          });
        }
      });
    }
    return options;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addNoneSelectedOption, flattened, nonSelectedLabel, t])

  return (
    <ComboSelect
      label={label}
      icon={icon}
      options={costElementOptions}
      value={costElementId}
      onChange={(value) => setCostElementId(value ?? 'None')}
      disabled={disabled}
      handlePopoverVisibility={handlePopoverVisibility}
      nullable
    />
  )
}
