import { ReportsRoutes } from './ReportsRoutes';
import { ReportsSideNavigation } from './components';
import { LoadingIndicator, TitleBar } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { ExportButtons } from '@client/project/shared';
import React, { useState } from 'react';
import cn from 'classnames';

export const ReportsModule = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <div className="flex h-full flex-col lg:flex-row min-w-full bg-slate-50">
      <ReportsSideNavigation />
      <div className="flex flex-grow flex-col overflow-x-hidden relative">
        <TitleBar title={t('reports.menu.title')} subTitle={t('reports.menu.subtitle')}>
          <div
            className={cn('w-full max-w-full h-full gap-2 flex flex-nowrap items-center justify-end pdf-export-hidden')}
          >
            <div className="flex-none flex">
              <ExportButtons
                setIsLoading={setIsLoading}
                showPdfDownload
                showExcelDownload={false}
                reportName="benchmarking-report"
              />
            </div>
          </div>
        </TitleBar>
        {isLoading && <LoadingIndicator mode="overlay-window" text={t('reporting.exportingLoadingIndicator')} />}
        <ReportsRoutes />
      </div>
    </div>
  );
};
