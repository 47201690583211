import cn from 'classnames';
import React, { useMemo, useState } from 'react';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ReportingTab } from '../ReportingTab';
import { settings } from '@client/shared/store';
import { ExportButtons } from '@client/project/shared';
import { LoadingIndicator } from '@client/shared/toolkit';

export const MultiReportingTabs = ({ projectId }: { projectId: string }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);

  const reportingTabs = useMemo(() => {
    return [
      {
        route: {
          routeConfig: ROUTES_CONFIG.MULTI_PROJECT_REPORTING_BENCHMARKING,
          label: t('reporting.benchmarking.title'),
        },
        export: {
          pdf: true,
          filename: 'multi-report-benchmarking',
          size: null,
        },
        active:
          location.pathname === ROUTES_CONFIG.MULTI_PROJECT_REPORTING_BENCHMARKING.path.replace(':id', projectId ?? ''),
      },
    ];
  }, [t, location.pathname, projectId]);

  const activeReport = useMemo(() => {
    return reportingTabs.find((tab) => tab.active);
  }, [reportingTabs]);

  return (
    <div className="w-full max-w-full h-full gap-2 flex flex-nowrap items-center justify-end pdf-export-hidden">
      {isLoading && <LoadingIndicator mode="overlay-window" text={t('reporting.exportingLoadingIndicator')} />}
      <div
        className={cn('flex gap-2 flex-wrap xl:flex-nowrap items-center', {
          'border-r pr-4': activeReport?.export?.pdf,
        })}
      >
        {reportingTabs.length > 0 && (
          <ul className="flex flex-wrap xl:flex-nowrap justify-center xl:justify-end gap-2 w-full">
            {reportingTabs.map((item, i) => (
              <li key={`reporting-tab-${i}`} className="reporting-tab">
                <ReportingTab routeConfig={item.route.routeConfig} projectId={projectId}>
                  {item.route.label}
                </ReportingTab>
              </li>
            ))}
          </ul>
        )}
      </div>
      {/* Export disabled for now as long as Iron PDF does not work, yet */}
      {activeReport && activeReport?.export?.pdf && settings.devMode && (
        <div className="flex-none flex">
          <ExportButtons
            setIsLoading={setIsLoading}
            showPdfDownload
            showExcelDownload={false}
            reportName={activeReport.export.filename}
            reportSize={activeReport.export.size}
          />
        </div>
      )}
    </div>
  );
};
