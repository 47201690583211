import { DecoratedCard, ICON_BAR_CHART_WATERMARK_IMAGE } from '@client/shared/toolkit';
import { formatLargeNumber, formatNumber, formatUnit } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';

interface GeneralKeyPerformanceIndicatorsProps {
    className?: string
    totalEquity: number;
    totalProfit: number;
    irr: number;
    roi: number;
}

export const GeneralKeyPerformanceIndicators = ({ className, totalEquity, totalProfit, irr, roi }: GeneralKeyPerformanceIndicatorsProps) => {
    const  { t } = useTranslation();
    const netReturn = totalProfit + totalEquity;
    const equityMultiplier = totalEquity === 0 ? 0 : netReturn / totalEquity;

    return(
        <DecoratedCard className={className}>
            <DecoratedCard.Header variant="plain">
                  {t('project.generalKeyPerformanceIndicators.title')}
            </DecoratedCard.Header>
            <DecoratedCard.Content>
                <div className="px-6 py-3 w-full h-full bg-white"
                    style={{
                        backgroundImage: `url('${ICON_BAR_CHART_WATERMARK_IMAGE}')`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPositionX: '95%',
                        backgroundPositionY: '90%'
                    }}
                >
                    <div className="grid grid-cols-2 sm:grid-cols-3 lg2:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3 gap-3 text-xl">
                        <div>
                            <div className="font-bold">{formatNumber(roi) + '%'}</div>
                            <div className="text-[11px] leading-tight text-slate-600">{t('reporting.reportForecast.roiShort')}</div>
                        </div>
                        <div>
                            <div className="font-bold">{formatUnit(irr, '% p.a.')}</div>
                            <div className="text-[11px] leading-tight text-slate-600">{t('projectReport.EquityIRR')}</div>
                        </div>
                        <div>
                            <div className="font-bold">{formatLargeNumber(netReturn, { maxDigits: 1 })}</div>
                            <div className="text-[11px] leading-tight text-slate-600">{t('projectReport.netReturn')}</div>
                        </div>
                        <div>
                            <div className="font-bold">{formatLargeNumber(totalEquity, { maxDigits: 1 })}</div>
                            <div className="text-[11px] leading-tight text-slate-600">{t('projectReport.investedEquity')}</div>
                        </div>
                        <div>
                            <div className="font-bold">{formatUnit(equityMultiplier, 'x')}</div>
                            <div className="text-[11px] leading-tight text-slate-600">{t('projectReport.equityMultiplier')}</div>
                        </div>
                        {/*<div>
                            <div className="text-lg font-bold">Test</div>
                            <div className="text-[11px] text-slate-600">Vergabegrad Baukosten</div>
                        </div>
                        <div>
                            <div className="text-lg font-bold">Test</div>
                            <div className="text-[11px] text-slate-600">Vermietungsgrad</div>
                        </div>
                        <div>
                            <div className="text-lg font-bold">{formatLargeNumber(280000000, { maxDigits: 1 })}</div>
                            <div className="text-[11px] text-slate-600">Marktwert n. Fertigstellung</div>
                        </div>
                        <div>
                            <div className="text-lg font-bold">{formatLargeNumber(300000000, { maxDigits: 1 })}</div>
                            <div className="text-[11px] text-slate-600">Marktwert (DATE)</div>
                        </div>*/}
                    </div>
                </div>
            </DecoratedCard.Content>
        </DecoratedCard>
    );
};
