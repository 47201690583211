import { setVariantId, useLoadedProjectId, useLoadedProjectVariants, useLoadedVariant } from '@client/project/store';
import {
  Badge,
  useCustomSearchParams,
  AllVariantsIcon,
  SelectedCustomIcon,
  UnselectedCustomIcon, HeaderButton
} from '@client/shared/toolkit';
import { Menu, MenuItems, MenuButton, MenuItem, Portal } from '@headlessui/react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { versionTextFromType } from '../utils';
import { ProtectedNavLink, ROUTES_CONFIG, useHasLicenseFeature } from '@client/shared/permissions';
import React from 'react';
import { usePopper } from 'react-popper';

export const VariantDropDown = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const [searchParams] = useCustomSearchParams();
  const variantLink = `${ROUTES_CONFIG.PROJECT_VARIANTS.path.replace(':id', id ?? '')}?vid=${searchParams['vid'] ?? ''}`;

  const hasCalculateLicenseFeature = useHasLicenseFeature('CALCULATE');
  const { data } = useLoadedProjectVariants();
  const { data: loadedVariant, isFetching } = useLoadedVariant();
  const projectId = useLoadedProjectId();

  const type = loadedVariant?.calculationModel?.modelMetadata?.type ?? '';

  const [targetElement, setTargetElement] = React.useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = React.useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['bottom-end', 'top-start', 'top-end'],
          rootBoundary: 'viewport',
        },
      },
      { name: 'offset', options: { offset: [0, 8] } },
    ],
  });

  const handleSelectVariant = (id: string) => {
    dispatch(setVariantId(id));
  };

  return (
    <div className="flex items-center">
      <Menu as="div" ref={setTargetElement} className="z-20 w-full relative">
        <MenuButton className={classNames('block', { 'cursor-not-allowed': !hasCalculateLicenseFeature })} disabled={!hasCalculateLicenseFeature}>
          <HeaderButton
            title={isFetching === true ? t('project.variantSelectorLoading') : t('project.variantSelectorLabel')}
            showToggle
          >
            {loadedVariant != null ? (
              <div className="flex w-full gap-2 items-center h-4 overflow-visible">
                <div className="text-left font-semibold text-sm leading-tight whitespace-nowrap text-gray-400 my-auto truncate">
                  {loadedVariant.calculationModel?.modelMetadata?.name === 'Current Version' ? t('projectVariants.sectionActiveTitle') : loadedVariant.calculationModel?.modelMetadata?.name}
                </div>
                {type === 'Version' && <Badge variant="success" text={t('project.variantSelectorActive')} className="leading-none" />}
                {type === 'Variant' && <Badge variant="info" text={t('project.variantSelectorAlternative')} className="leading-none" />}
                {type === 'ArchivedVariant' && (
                  <Badge variant="lightInfo" text={t('project.variantSelectorDeactivated')} className="leading-none" />
                )}
                {type === 'UserSnapshot' && (
                  <Badge variant="secondary" text={t('project.variantSelectorUserSnapshot')} className="leading-none" />
                )}
                {type === 'SystemSnapshot' && (
                  <Badge variant="secondary" text={t('project.variantSelectorSystemSnapshot')} className="leading-none" />
                )}
                {type === 'ArchivedVersion' && (
                  <Badge variant="lightSuccess" text={t('project.variantSelectorArchivedVersion')} className="leading-none" />
                )}
              </div>
            ) : (
              <div className="text-left font-semibold text-sm leading-tight whitespace-nowrap text-gray-400 h-4">{t('project.variantSelectorSelectVariant')}</div>
            )}
          </HeaderButton>
        </MenuButton>
        <Portal>
          <MenuItems
            className="z-40 absolute right-0 mt-2 w-96 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-y-auto max-h-96"
            as="div"
            ref={setPopperElement}
            style={{ ...styles.popper }}
            {...attributes.popper}
          >
            <div className="divide-y divide-gray-100">
              {data
                .filter((x) => x.type === 'Version' || x.type === 'Variant' || x.type === 'Scenario')
                .map((variant, idx) => (
                  <MenuItem key={idx}>
                    {({ focus }) => (
                      <div
                        onClick={() => handleSelectVariant(variant.id)}
                        className={classNames(
                          'text-gray-900 group flex rounded-none w-full py-3 px-4 text-sm cursor-pointer',
                          {
                            'bg-gray-100': focus,
                          }
                        )}
                      >
                        <div className="grow text-left w-7/12 pr-3 my-auto">
                          <div className="text-[15px] font-bold truncate">
                            {variant.name === 'Current Version' ? t('projectVariants.sectionActiveTitle') : variant.name}
                          </div>
                          <div
                            className={classNames('text-[11px]', {
                              hidden: variant.version == null,
                            })}
                          >
                            {versionTextFromType(t, variant.type, variant.version)}
                          </div>
                        </div>

                        <div className="text-right my-auto">
                          {variant.type === 'Version' && (
                            <Badge variant="success" text={t('project.variantSelectorActive')} />
                          )}
                          {variant.type === 'Variant' && (
                            <Badge variant="info" text={t('project.variantSelectorAlternative')} />
                          )}
                        </div>
                        <div className="w-12 text-right my-auto">
                          {loadedVariant?.calculationModel?.modelMetadata?.id === variant.id ? (
                            <SelectedCustomIcon className="w-5 h-5 ml-auto" />
                          ) : (
                            <UnselectedCustomIcon className="w-5 h-5 ml-auto" />
                          )}
                        </div>
                      </div>
                    )}
                  </MenuItem>
                ))}
            </div>
            <ProtectedNavLink
              path={variantLink}
              routeConfig={ROUTES_CONFIG.PROJECT_VARIANTS}
              className="cursor-pointer block text-sm hover:bg-gray-100"
              disabledClassName="cursor-not-allowed block text-sm"
              projectId={projectId}
            >
              <div className="flex items-center py-3 px-4">
                <AllVariantsIcon className="w-6 h-6 stroke-[#64748b]"  />
                <span className="ml-5 text-slate-500 font-semibold my-auto">
                {t('project.variantSelectorGoToVariants')}
              </span>
              </div>
            </ProtectedNavLink>
          </MenuItems>
        </Portal>
      </Menu>
    </div>
  )
}
