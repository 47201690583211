import { FormattedCurrency } from '@client/project/shared';
import { CashFlowGroupReportReadModel, CashFlowGroupReportSectionResponse } from '@client/shared/api';
import { Collapsible } from '@client/shared/toolkit';
import cn from 'classnames';
import { useMemo } from 'react';
import { CASHFLOW_COLUMN_MIN_WIDTH, CASHFLOW_COLUMN_QUARTER_MIN_WIDTH } from '../ReportCashflow';
import { ReportCashflowCell } from './ReportCashflowCell';
import { getMonthsBetweenDates } from '@client/shared/utilities';

interface ReportCashflowTableMonthsProps {
  data: CashFlowGroupReportSectionResponse | undefined;
  expandedElements: string[];
  startDate: string;
  endDate: string;
  sectionExpanded: boolean;
}

export const ReportCashflowTableMonths = ({
  data,
  expandedElements,
  startDate,
  endDate,
  sectionExpanded,
}: ReportCashflowTableMonthsProps) => {
  const months = useMemo(() => {
    return getMonthsBetweenDates(startDate, endDate);
  }, [startDate, endDate]);

  const elements = data?.data ?? [];

  return (
    <div className="w-full flex flex-col pr-5">
      <div className="h-8"></div>
      <div className="overflow-x-auto cashflow-columns-months">
        <div className="flex" style={{ minWidth: CASHFLOW_COLUMN_MIN_WIDTH }}>
          {months.map((month, i) => {
            const summary = data?.summary.months.find(
              (value) => value.month === month.getMonth() + 1 && value.year === month.getFullYear(),
            );

            return (
              <ReportCashflowCell
                key={`month-${month.toISOString()}`}
                header
                styles={{ minWidth: CASHFLOW_COLUMN_MIN_WIDTH }}
                className="flex-1"
              >
                <div className="flex items-center justify-end pr-2 h-11">
                  {month.getMonth() + 1}/{month.getFullYear()}
                </div>
                <div className="h-1 border-slate-300 border-b" />
                <Collapsible open={sectionExpanded}>
                  {elements.map((element) => {
                    return (
                      <ReportCashflowValueMonthCell
                        date={month}
                        element={element}
                        expandedElements={expandedElements}
                        key={`value-cell-${element.uid}`}
                      />
                    );
                  })}
                </Collapsible>

                <ReportCashflowCell
                  className="flex-1"
                  key={`footer-quarter-${month}-${i}`}
                  footer
                  styles={{ minWidth: CASHFLOW_COLUMN_MIN_WIDTH }}
                >
                  <div className="flex items-center justify-end pl-2 h-11 text-[16px] font-bold truncate border-b border-slate-200 border-dotted">
                    <FormattedCurrency amount={summary?.payed} />
                  </div>
                </ReportCashflowCell>
              </ReportCashflowCell>
            );
          })}
        </div>
      </div>
    </div>
  );
};

interface ReportCashflowValueMonthCellProps {
  date: Date;
  element: CashFlowGroupReportReadModel;
  expandedElements: string[];
  level?: number;
}

const ReportCashflowValueMonthCell = ({
  date,
  element,
  expandedElements,
  level = 0,
}: ReportCashflowValueMonthCellProps) => {
  const month = element.values?.months.find(
    (value) => value.month === date.getMonth() + 1 && value.year === date.getFullYear(),
  );

  return (
    <div>
      <div
        className={cn('hover:bg-slate-50 transition-colors flex', {
          'bg-gray-50 font-semibold text-[14px]': level === 0,
          'bg-white text-[12px]': level > 0,
        })}
      >
        <ReportCashflowCell
          className={cn('flex-1 pr-2', {
            'border-dotted': level > 0,
          })}
          key={`${element.uid}-quarter-0`}
          styles={{ minWidth: CASHFLOW_COLUMN_QUARTER_MIN_WIDTH }}
        >
          <div
            className={cn('h-10 flex items-center justify-end', {
              'text-[14px]': level === 0,
              'font-normal text-[12px]': level > 0,
            })}
          >
            <div className="h-3 -mb-1" />
            <FormattedCurrency amount={month?.payed} />
          </div>
        </ReportCashflowCell>
      </div>

      {expandedElements.includes(element.uid) &&
        element.children?.map((child) => (
          <ReportCashflowValueMonthCell
            date={date}
            element={child}
            expandedElements={expandedElements}
            level={level + 1}
            key={`value-cell-${child.uid}`}
          />
        ))}
    </div>
  );
};
