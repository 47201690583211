import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { useApiGetCalculationModelCashFlowSettingsQuery } from '@client/shared/api';
import {
  BaseSelect,
  BaseSelectOption,
  BillIcon,
  BooleanInput,
  Button,
  DatePicker,
  EditNodeIcon,
  Form,
  FormField,
  FuturesIcon,
  Modal,
  ModalOnCloseProps,
  MultiSelect,
  PositiveDynamicIcon,
  TearOffCalenderIcon,
  TimeLimitIcon,
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { CashflowFilterValidationSchema, CashFlowFilterValidationValues } from './CashflowFilterValidationSchema';

interface CashflowModalProps extends ModalOnCloseProps {
  filter: CashFlowFilterValidationValues | null;
  onCashflowFilterChange: (filter: CashFlowFilterValidationValues) => void;
}

export const CashflowModal = ({ onClose, filter, onCashflowFilterChange }: CashflowModalProps) => {
  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const { data } = useApiGetCalculationModelCashFlowSettingsQuery(
    {
      projectId: loadedProjectId ?? 'unset',
      calculationModelId: loadedVariantId ?? '',
    },
    { skip: !loadedProjectId || !loadedVariantId },
  );

  const intervalOptions: BaseSelectOption[] = [
    {
      label: t('common.month'),
      value: 'Month',
    },
    {
      label: t('common.quarter'),
      value: 'Quarter',
    },
    {
      label: t('common.year'),
      value: 'Year',
    },
  ];

  const groupsOptions: BaseSelectOption[] = [
    {
      label: t('reporting.cashflowReport.sectionCost'),
      value: 'Costs',
    },
    {
      label: t('reporting.cashflowReport.sectionRisk'),
      value: 'Risks',
    },
    {
      label: t('reporting.cashflowReport.sectionFinance'),
      value: 'Finance',
    },
    {
      label: t('reporting.cashflowReport.sectionRevenue'),
      value: 'Earnings',
    },
  ];

  const referenceDateOptions: BaseSelectOption[] = [
    {
      label: t('projectCalculate.cashFlow.settingsReferenceDateReceivedDate'),
      value: 'ReceivedDate',
    },
    {
      label: t('projectCalculate.cashFlow.settingsReferenceDateInvoiceDate'),
      value: 'InvoiceDate',
    },
    {
      label: t('projectCalculate.cashFlow.settingsReferenceDateApprovalDate'),
      value: 'ApprovalDate',
    },
    {
      label: t('projectCalculate.cashFlow.settingsReferenceDatePaymentDate'),
      value: 'PaymentDate',
    },
  ];

  const referenceValueOptions: BaseSelectOption[] = [
    {
      label: t('projectCalculate.cashFlow.settingsReferenceValueInvoice'),
      value: 'Invoice',
    },
    {
      label: t('projectCalculate.cashFlow.settingsReferenceValuePayment'),
      value: 'Payment',
    },
    {
      label: t('projectCalculate.cashFlow.settingsReferenceValueTotalPayment'),
      value: 'AbsolutePayment',
    },
  ];

  const defaultFormValues: CashFlowFilterValidationValues = {
    startPoint: filter?.startPoint ?? null,
    interval: filter?.interval ?? 'Month',
    groups: filter?.groups ?? ['Costs', 'Risks', 'Finance', 'Earnings'],
  } satisfies CashFlowFilterValidationValues;

  const handleSubmit = async (data: CashFlowFilterValidationValues) => {
    onCashflowFilterChange(data);
  };

  return (
    <>
      <Modal.Header
        title={t('reporting.cashflowReport.modalTitle')}
        description={t('reporting.cashflowReport.modalDescription')}
      />
      <Form<CashFlowFilterValidationValues>
        onSubmit={handleSubmit}
        validationSchema={CashflowFilterValidationSchema}
        defaultValues={defaultFormValues}
      >
        <Modal.Content>
          <div className="w-full px-6 py-6 flex flex-col gap-1">
            <FormField name="startPoint">
              {(control) => (
                <DatePicker
                  label={t('reporting.cashflowReport.modalFieldStartpoint')}
                  icon={<TimeLimitIcon />}
                  {...control}
                />
              )}
            </FormField>
            <FormField name="interval">
              {(control) => (
                <BaseSelect
                  label={t('reporting.cashflowReport.modalFieldInterval')}
                  options={intervalOptions}
                  icon={<PositiveDynamicIcon />}
                  {...control}
                />
              )}
            </FormField>
            <FormField name="groups">
              {(control) => (
                <MultiSelect
                  icon={<FuturesIcon />}
                  label={t('reporting.cashflowReport.modalFieldGroups')}
                  options={groupsOptions}
                  {...control}
                />
              )}
            </FormField>
            <div className="flex flex-row gap-1">
              <DatePicker
                label={t('projectCalculate.cashFlow.settingsStatusMonth')}
                icon={<TearOffCalenderIcon />}
                disabled
                className="flex-1"
                value={data?.cashFlowSettings?.statusMonth ?? ''}
                onChange={function (value?: Date | null): void {
                  // empty
                }}
              />
              <BaseSelect
                label={t('projectCalculate.cashFlow.settingsReferenceDate')}
                icon={<TearOffCalenderIcon />}
                options={referenceDateOptions}
                disabled
                className="flex-1"
                value={data?.cashFlowSettings?.referenceDate ?? 'ReceivedDate'}
                onChange={function (selected: string): void {
                  // empty
                }}
              />
            </div>
            <div className="flex flex-row gap-1">
              <BaseSelect
                label={t('projectCalculate.cashFlow.settingsReferenceValue')}
                options={referenceValueOptions}
                disabled
                className="flex-1"
                variant="light"
                value={data?.cashFlowSettings?.referenceValue ?? 'Invoice'}
                icon={<EditNodeIcon />}
                onChange={function (selected: string): void {
                  // empty
                }}
              />

              <BooleanInput
                label={t('projectCalculate.cashFlow.settingsOverridePlan')}
                disabled
                className="flex-1"
                icon={<BillIcon />}
                value={data?.cashFlowSettings?.overridePlan ?? false}
                onChange={function (value: boolean): void {
                  // empty
                }}
              />
            </div>
          </div>
        </Modal.Content>
        <Modal.Controls className="bg-white">
          <Button variant="secondary" onClick={() => onClose(false)} className="mr-2">
            {t('common.cancel')}
          </Button>
          <Button variant="primary" formSubmit>
            {t('common.next')}
          </Button>
        </Modal.Controls>
      </Form>
    </>
  );
};
